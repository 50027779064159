
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
    mcqContainer: {
        padding: '1rem',
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '2rem',
    },
    questionContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '1rem',
    },
    question: {
        fontSize: '1rem',
    },
    answerText: {
        fontSize: '1rem',
        color: 'chartreuse',
    },
}));

export default useStyle;
