const PRODUCT_DETAILS_NAVIGATION_STATUS = [
  "Summary",
  "Comparison",
  "Documents",
  "FAQs"
]

const PRODUCT_LIST = [
  {
    'id': 1,
    'name': 'Zenith',
    'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/cover-treo-plus.png',
    'comments': 32,
    'documentsLinked': 6,
    'launchType': 'launch',
  },

]

const TREO_PLUS_DEF_FAQS = [
  {
    "id": 1,
    "question": {
      "en": `<p>Q1. What is CSB Zenith</p>`,
      "hi": `<p>Q1. What is CSB Zenith</p>`,


    },
    "answer": {
      "en": `<p>CSB Zenith is a specialized banking solution offered by CSB Bank that provides customized financial services tailored to meet the needs of high-net-worth individuals (HNWIs) and premium customers.</p>`,
      "hi": `<p>CSB Zenith is a specialized banking solution offered by CSB Bank that provides customized financial services tailored to meet the needs of high-net-worth individuals (HNWIs) and premium customers.</p>`,

    }
  },
  {
    "id": 2,
    "question": {
      "en": `<p>Q2. What are the key features of CSB Zenith?</p>`,
      "hi": `<p>Q2. What are the key features of CSB Zenith?</p>`,

    },
    "answer": {
      "en": `<p>CSB Zenith offers features such as dedicated relationship managers, personalized banking services, premium debit and credit cards, higher transaction limits, preferential rates on loans and deposits, and access to exclusive lifestyle and wellness benefits.</p>`,
      "hi": `<p>CSB Zenith offers features such as dedicated relationship managers, personalized banking services, premium debit and credit cards, higher transaction limits, preferential rates on loans and deposits, and access to exclusive lifestyle and wellness benefits.</p>`,

    }
  },
  {
    "id": 3,
    "question": {
      "en": `<p>Q3. Who is eligible for CSB Zenith?</p>`,
      "hi": `<p>Q3. Who is eligible for CSB Zenith?</p>`,

    },
    "answer": {
      "en": `<p>CSB Zenith is typically available to individuals who meet specific criteria set by the bank, usually based on account balance, income level, or net worth. You can contact CSB Bank for detailed eligibility criteria.</p>`,
      "hi": `<p>CSB Zenith is typically available to individuals who meet specific criteria set by the bank, usually based on account balance, income level, or net worth. You can contact CSB Bank for detailed eligibility criteria.</p>`,

    }
  },
  {
    "id": 4,
    "question": {
      "en": `<p>Q4. How do I apply for CSB Zenith?</p>`,
      "hi": `<p>Q4. How do I apply for CSB Zenith?</p>`,

    },
    "answer": {
      "en": `<p>To apply for CSB Zenith, you can visit your nearest CSB Bank branch or contact their customer service. A dedicated relationship manager will guide you through the application process.</p>`,
      "hi": `<p>To apply for CSB Zenith, you can visit your nearest CSB Bank branch or contact their customer service. A dedicated relationship manager will guide you through the application process.</p>`,

    }
  },
  {
    "id": 5,
    "question": {
      "en": `<p>Q5. What are the benefits of having a dedicated relationship manager?</p>`,
      "hi": `<p>Q5. What are the benefits of having a dedicated relationship manager?</p>`,

    },
    "answer": {
      "en": `<p>A dedicated relationship manager provides personalized banking services, helps with financial planning, offers investment advice, and ensures that your banking needs are met efficiently.</p>`,
      "hi": `<p>A dedicated relationship manager provides personalized banking services, helps with financial planning, offers investment advice, and ensures that your banking needs are met efficiently.</p>`,

    }
  },
  {
    "id": 6,
    "question": {
      "en": `<p>Q6. What kind of debit and credit cards are available with CSB Zenith?</p>`,
      "hi": `<p>Q6. What kind of debit and credit cards are available with CSB Zenith?</p>`,

    },
    "answer": {
      "en": `<p>CSB Zenith offers premium debit and credit cards with higher transaction limits, enhanced security features, and exclusive benefits such as reward points, airport lounge access, and more.</p>`,
      "hi": `<p>CSB Zenith offers premium debit and credit cards with higher transaction limits, enhanced security features, and exclusive benefits such as reward points, airport lounge access, and more.</p>`,

    }
  },
  {
    "id": 7,
    "question": {
      "en": `<p>Q7. Are there any special offers or discounts for CSB Zenith customers?</p>`,
      "hi": `<p>Q7. Are there any special offers or discounts for CSB Zenith customers?</p>`,

    },
    "answer": {
      "en": `<p><b>Yes, CSB Zenith customers can enjoy exclusive offers, discounts, and privileges on various lifestyle, wellness, and travel services as part of their membership.</p>`,
      "hi": `<p><b>Yes, CSB Zenith customers can enjoy exclusive offers, discounts, and privileges on various lifestyle, wellness, and travel services as part of their membership.</p>`,
    }
  },
  {
    "id": 8,
    "question": {
      "en": `<p>Q8. Can I manage my CSB Zenith account online?</p>`,
      "hi": `<p>Q8. Can I manage my CSB Zenith account online?</p>`,

    },
    "answer": {
      "en": `<p>Yes, CSB Zenith customers can manage their accounts online through CSB Bank’s internet and mobile banking platforms, providing easy access to account information, transactions, and other services.</p>`,
      "hi": `<p>Yes, CSB Zenith customers can manage their accounts online through CSB Bank’s internet and mobile banking platforms, providing easy access to account information, transactions, and other services.</p>`,
    }
  },
  {
    "id": 9,
    "question": {
      "en": `<p>Q9. What preferential rates are offered under CSB Zenith?</p>`,
      "hi": `<p>Q9. What preferential rates are offered under CSB Zenith?</p>`,
    },
    "answer": {
      "en": `<p>CSB Zenith customers may receive preferential rates on various banking products such as loans, deposits, and foreign exchange services. The specific rates and terms are subject to bank policies and individual eligibility.
            </p>`,
      "hi": `<p>CSB Zenith customers may receive preferential rates on various banking products such as loans, deposits, and foreign exchange services. The specific rates and terms are subject to bank policies and individual eligibility.
            </p>`,

    }
  },
  {
    "id": 10,
    "question": {
      "en": `<p>Q10. Is there a membership fee for CSB Zenith?</p>`,
      "hi": `<p>Q10. Is there a membership fee for CSB Zenith?</p>`
    },
    "answer": {
      "en": `<p>The membership fee, if any, for CSB Zenith varies and is determined by CSB Bank based on the customer’s profile. You can inquire with your relationship manager for detailed information.</p>`,
      "hi": `<p>The membership fee, if any, for CSB Zenith varies and is determined by CSB Bank based on the customer’s profile. You can inquire with your relationship manager for detailed information.</p>`

    }
  }
]

const TREO_DEFAULT_SUMMARY = [
  {
    "en": `<p><b>Dedicated Relationship Manager:</b> Each Zenith customer is assigned a dedicated relationship manager to provide personalized banking services and financial advice.<p>`,
    "hi": `<p><b>Dedicated Relationship Manager:</b> Each Zenith customer is assigned a dedicated relationship manager to provide personalized banking services and financial advice.<p>`,

  },
  {
    "en": `<p><b>Exclusive Debit and Credit Cards:</b>  Enjoy premium debit and credit cards with higher transaction limits, enhanced security, and access to exclusive privileges like airport lounge access.<p>`,
    "hi": `<p><b>Exclusive Debit and Credit Cards:</b>  Enjoy premium debit and credit cards with higher transaction limits, enhanced security, and access to exclusive privileges like airport lounge access.<p>`,

  },
  {
    "en": `<p><b>Preferential Interest Rates:</b> Benefit from preferential interest rates on various banking products, including loans and deposits, tailored to your financial needs.<p>`,
    "hi": `<p><b>Preferential Interest Rates:</b> Benefit from preferential interest rates on various banking products, including loans and deposits, tailored to your financial needs.<p>`,

  },
  {
    "en": `<p><b>Concierge Services:</b> Access to exclusive concierge services that cater to your lifestyle, travel, and wellness needs, offering convenience and luxury.<p>`,
    "hi": `<p><b>Concierge Services:</b> Access to exclusive concierge services that cater to your lifestyle, travel, and wellness needs, offering convenience and luxury.<p>`,

  },
  {
    "en": `<p><b>Customized Wealth Management Solutions:</b> Zenith offers personalized wealth management and financial planning solutions, helping you manage and grow your wealth effectively.<p>`,
    "hi": `<p><b>Customized Wealth Management Solutions:</b> Zenith offers personalized wealth management and financial planning solutions, helping you manage and grow your wealth effectively.<p>`,

  }
]

// new change : 

const ZOR_GRAND_DEFAULT_SUMMARY = [
  {
    "summaryImage": '/images/common/csb_1.jpg',
    "en": "<p><b>High-Interest Rate:</b> </br>Earn up to 6% interest on your savings with Zenith</p>",
    "hi": "<p><b>High-Interest Rate:</b> </br>Earn up to 6% interest on your savings with Zenith</p>",

  },
  {
    "summaryImage": '/images/common/csb_2.jpg',
    "en": "<p><b>Zero Maintenance Charges:</b> </br>Enjoy zero maintenance charges with no hidden fees</p>",
    "hi": "<p><b>Zero Maintenance Charges:</b> </br>Enjoy zero maintenance charges with no hidden fees</p>",

  },
  {
    "summaryImage": '/images/common/csb_3.jpg',
    "en": "<p><b>Free ATM Withdrawals:</b></br> Unlimited free ATM withdrawals across all ATMs</p>",
    "hi": "<p><b>Free ATM Withdrawals:</b></br> Unlimited free ATM withdrawals across all ATMs</p>"
  }
]

const PRODUCT_DETAIL_LIST = [
  {
    'id': 1,
    'productId': 1,
    'name': 'TREO Plus',
    'productImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/TREO_Plus_product.png',
    'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/cover-treo-plus.png',
    'comments': 4,
    'documentsLinked': 5,
    'launchType': 'launch',
    'isVoiceOverAvailable': true,
    'summaryVoiceOverTrack': {
      'en': `https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/treo_summary_voiceover.mp3`,
      'hi': `https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/treo_summary_voiceover.mp3`
    },
    'summary': {
      'def': TREO_DEFAULT_SUMMARY,
      'fleet_owners': TREO_DEFAULT_SUMMARY,
      'single_owner': TREO_DEFAULT_SUMMARY,
      'rural_buyer': TREO_DEFAULT_SUMMARY,
      'dealer': TREO_DEFAULT_SUMMARY,
    },

    'specsStatements': {
      'compatitor_1': [
        {
          "id": 1,
          "statement": {
            "en": `1. Treo Plus has a higher top speed of 55 km / hr and a better wheel base as compared to competitor 1`,
            "hi": `1. Treo Plus has a higher top speed of 55 km / hr and a better wheel base as compared to competitor 1`,
          }
        },
        {
          "id": 2,
          "statement": {
            "en": `2. With a higher battery capacity of 1.24 kWh, Treo Plus runs for longer time`,
            "hi": `2. With a higher battery capacity of 1.24 kWh, Treo Plus runs for longer time`,
          }
        },
        {
          "id": 3,
          "statement": {
            "en": `3. Warranty of 5 years / 1.2 lakh kms ensures peace of mind and higher savings for customers`,
            "hi": `3. Warranty of 5 years / 1.2 lakh kms ensures peace of mind and higher savings for customers`,
          }
        }
      ],
      'compatitor_2': [
        {
          "id": 1,
          "statement": {
            "en": `1. Treo Plus has best in class real world driving range of 150 km/hr and a top speed of 55 km/hr delivering high performance`,
            "hi": `1. Treo Plus has best in class real world driving range of 150 km/hr and a top speed of 55 km/hr delivering high performance`,
          }
        },
        {
          "id": 2,
          "statement": {
            "en": `2. With a higher battery capacity of 1.24 kWh, Treo Plus runs for longer time`,
            "hi": `2. With a higher battery capacity of 1.24 kWh, Treo Plus runs for longer time`,

          }
        },
        {
          "id": 3,
          "statement": {
            "en": `3. Warranty of 5 years/1.2 lakh kms ensures peace of mind and higher savings for customers`,
            "hi": `3. Warranty of 5 years/1.2 lakh kms ensures peace of mind and higher savings for customers`,
          }
        }
      ],
      'compatitor_3': [
        {
          "id": 1,
          "statement": {
            "en": `1. You can rely on the trust of Mahindra brand with 800+ service centres across the country which is higher than competition`,
            "hi": `1. You can rely on the trust of Mahindra brand with 800+ service centres across the country which is higher than competition`,
          }
        },
        {
          "id": 2,
          "statement": {
            "en": `2. Charging time as low as 4 hours and 20 minutes compared to 6 hours of competition `,
            "hi": `2. Charging time as low as 4 hours and 20 minutes compared to 6 hours of competition `,
          }
        },
        {
          "id": 3,
          "statement": {
            "en": `3. With a higher battery capacity of 1.24 kWh, Treo Plus runs for longer time`,
            "hi": `3. With a higher battery capacity of 1.24 kWh, Treo Plus runs for longer time`,
          }
        },
        {
          "id": 4,
          "statement": {
            "en": `4. Longer wheelbase assures space and stability`,
            "hi": `4. Longer wheelbase assures space and stability`,
          }
        }
      ],
    },
    'specifications': {
      'compatitor_1': [
        {
          "id": 1,
          "title": {
            "en": `Top speed`,
            "hi": `उच्चतम गति`
          },
          "value": {
            "en": `<p><b>55 km/hr</b></p>`,
            "hi": `<p><b>55 कि.मी./घंटा</b></p>`
          }
        },
        {
          "id": 2,
          "title": {
            "en": `Space (Wheelbase)`,
            "hi": `व्हीलबेस`
          },
          "value": {
            "en": `<p><b>2,073 mm</b></p>`,
            "hi": `<p><b>2,073 मीमी</b></p>`
          }
        },
        {
          "id": 3,
          "title": {
            "en": `Battery Capacity`,
            "hi": `बैटरी की क्षमता`
          },
          "value": {
            "en": `<p><b>10.24 kWh</b></p>`,
            "hi": `<p><b>10.24 किलोवाट</b></p>`
          }
        },
        {
          "id": 4,
          "title": {
            "en": `Driving Range`,
            "hi": `ड्राइविंग रेंज`
          },
          "value": {
            "en": `<p><b>150 Kms</b></p>`,
            "hi": `<p><b>150 कि.मी.</b></p>`
          }
        },
        {
          "id": 5,
          "title": {
            "en": `Body Type`,
            "hi": `बॉडी का प्रकार`
          },
          "value": {
            "en": `<p>SMC Panel</p>`,
            "hi": `<p>एसएमसी पैनल</p>`
          }
        },
        {
          "id": 6,
          "title": {
            "en": `Warranty`,
            "hi": `वारंटी`
          },
          "value": {
            "en": `<p><b>5 years / 1.2 Lakh Kms.</b></p>`,
            "hi": `<p><b>5 वर्ष/1,20,000 किमी.</b></p>`
          }
        },
        {
          "id": 7,
          "title": {
            "en": `Charger`,
            "hi": `चार्जर`
          },
          "value": {
            "en": `<p>Off Board</p>`,
            "hi": `<p>ऑफबोर्ड</p>`
          }
        }
      ],
      'compatitor_2': [
        {
          "id": 1,
          "title": {
            "en": `Power`,
            "hi": `पावर`
          },
          "value": {
            "en": `<p><b>8 kW</b></p>`,
            "hi": `<p><b>8 किलोवाट</b></p>`
          }
        },
        {
          "id": 2,
          "title": {
            "en": `Torque`,
            "hi": `टॉर्क`
          },
          "value": {
            "en": `<p><b>42 Nm</b></p>`,
            "hi": `<p><b>42 एनएम</b></p>`
          }
        },
        {
          "id": 3,
          "title": {
            "en": `Top speed`,
            "hi": `उच्चतम गति`
          },
          "value": {
            "en": `<p><b>55 km/hr</b></p>`,
            "hi": `<p><b>55 कि.मी./घंटा</b></p>`
          }
        },
        {
          "id": 4,
          "title": {
            "en": `Battery Capacity`,
            "hi": `बैटरी की क्षमता`
          },
          "value": {
            "en": `<p><b>10.24 kWh</b></p>`,
            "hi": `<p><b>10.24 किलोवाट</b></p>`
          }
        },
        {
          "id": 5,
          "title": {
            "en": `Driving Range`,
            "hi": `ड्राइविंग रेंज`
          },
          "value": {
            "en": `<p><b>150 Kms</b></p>`,
            "hi": `<p><b>150 कि.मी</b></p>`
          }
        },
        {
          "id": 6,
          "title": {
            "en": `Space (Wheelbase)`,
            "hi": `व्हीलबेस`
          },
          "value": {
            "en": `<p><b>2,073 mm</b></p>`,
            "hi": `<p><b>2,073 मीमी</b></p>`
          }
        },
        {
          "id": 7,
          "title": {
            "en": `Body Type`,
            "hi": `बॉडी का प्रकार`
          },
          "value": {
            "en": `<p>SMC Panel</p>`,
            "hi": `<p>एसएमसी पैनल</p>`
          }
        },
        {
          "id": 8,
          "title": {
            "en": `Warranty`,
            "hi": `वारंटी`
          },
          "value": {
            "en": `<p><b>std 5 years / 1,20,000 Kms</b></p>`,
            "hi": `<p><b>5 वर्ष/1,20,000 कि.मी</b></p>`
          }
        }
      ],
      'compatitor_3': [
        {
          "id": 1,
          "title": {
            "en": `Battery Capacity`,
            "hi": `बैटरी की क्षमता`
          },
          "value": {
            "en": `<p><b>10.24 kWh</b></p>`,
            "hi": `<p><b>10.24 किलोवाट</b></p>`
          }
        },
        {
          "id": 2,
          "title": {
            "en": `Charging Time`,
            "hi": `चार्जिंग टाइम`
          },
          "value": {
            "en": `<p><b>4 hr 20 min</b></p>`,
            "hi": `<p><b>4 घंटा 20 मिनट</b></p>`
          }
        },
        {
          "id": 3,
          "title": {
            "en": `Driving Range`,
            "hi": `ड्राइविंग रेंज`
          },
          "value": {
            "en": `<p>150 Kms</p>`,
            "hi": `<p>150 किमी </p>`
          }
        },
        {
          "id": 4,
          "title": {
            "en": `Space (Wheelbase)`,
            "hi": `व्हीलबेस`
          },
          "value": {
            "en": `<p><b>2,073 mm</b></p>`,
            "hi": `<p><b>2,073 मिमी</b></p>`
          }
        },
        {
          "id": 5,
          "title": {
            "en": `Trust`,
            "hi": `ट्रस्ट`
          },
          "value": {
            "en": `<p><b>Mahindra 800+ service centres</b></p>`,
            "hi": `<p><b>महिंद्रा 800+ सर्विस सेंटर</b></p>`
          }
        },
        {
          "id": 6,
          "title": {
            "en": `Variant`,
            "hi": `वेरिएंट`
          },
          "value": {
            "en": `<p>2 variants: TREO Plus and TREO Auto</p>`,
            "hi": `<p>2 वेरिएंट: ट्रियो प्लस और ट्रियो ऑटो</p>`
          }
        }
      ],
    },
    'faqs': {
      'def': TREO_PLUS_DEF_FAQS,
      'fleet_owners': TREO_PLUS_DEF_FAQS,
      'single_owner': TREO_PLUS_DEF_FAQS,
      'farmer': TREO_PLUS_DEF_FAQS,
      'dealer': TREO_PLUS_DEF_FAQS,
    },
  },
  // new change : 
  {
    'id': 2,
    'productId': 2,
    'name': 'CSB Zenith',
    'productImage': '/images/common/csb_logo.jpg',
    'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/zor-grand-cover.jpg',
    'comments': 4,
    'documentsLinked': 5,
    'launchType': 'launch',
    'isVoiceOverAvailable': false,
    'summaryVoiceOverTrack': {
      'en': ``,
      'hi': ``
    },
    'summary': {
      'def': ZOR_GRAND_DEFAULT_SUMMARY,
      'fleet_owners': ZOR_GRAND_DEFAULT_SUMMARY,
      'single_owner': ZOR_GRAND_DEFAULT_SUMMARY,
      'rural_buyer': ZOR_GRAND_DEFAULT_SUMMARY,
      'dealer': ZOR_GRAND_DEFAULT_SUMMARY,
    },
    'specsStatements': {
      'compatitor_1': [
        {
          "id": 1,
          "statement": {
            "en": `CSB Zenith DV has best in industry power of 12 kW, best in class gradeability 11.5° and range (100+ kms) higher than competition`,
            "hi": `ज़ोर ग्रैंड डीवी की उद्योग में सर्वोत्तम शक्ति 12 किलोवाट, श्रेणी में सर्वोत्तम ग्रेडेबिलिटी 11.5° और रेंज (100+ किलोमीटर) प्रतिस्पर्धा से अधिक है।`
          }
        }
      ],
      'compatitor_2': [
        {
          "id": 1,
          "statement": {
            "en": `CSB Zenith DV has best in class acceleration, DV 170 Cuft 1st in industry ARAI approved and factory fitted delivery box, and best in industry power of 12 kW`,
            "hi": `ज़ोर ग्रांड डीवी के पास श्रेणी में सर्वश्रेष्ठ त्वरण है, डीवी 170 क्यूएफटी उद्योग में प्रथम है, एआरएआई अनुमोदित और फैक्ट्री फिटेड डिलीवरी बॉक्स है, और उद्योग में सर्वश्रेष्ठ 12 किलोवाट की शक्ति है।`
          }
        }
      ],
      'compatitor_3': [
      ],
    },

    'specifications': {
      'compatitor_1': [
        {
          "id": 1,
          "title": {
            "en": `Dedicated Relationship Manager`,
            "hi": `Dedicated Relationship Manager`,
          },
          "value": {
            "en": `<p>Yes</p>`,
            "hi": `<p>Yes</p>`,
          }
        },
        {
          "id": 2,
          "title": {
            "en": `Premium Cards`,
            "hi": `Premium Cards`,
          },
          "value": {
            "en": `<p>Premium debit and credit cards</p>`,
            "hi": `<p>Premium debit and credit cards</p>`,
          }
        },
        {
          "id": 3,
          "title": {
            "en": `Preferential Interest Rates`,
            "hi": `Preferential Interest Rates`,
          },
          "value": {
            "en": `<p>Yes</p>`,
            "hi": `<p>Yes</p>`,
          }
        },
        {
          "id": 4,
          "title": {
            "en": `Concierge Services`,
            "hi": `Concierge Services`,
          },
          "value": {
            "en": `<p>Yes</p>`,
            "hi": `<p>Yes</p>`,
          }
        },
        {
          "id": 5,
          "title": {
            "en": `Wealth Management Solutions`,
            "hi": `Wealth Management Solutions`,
          },
          "value": {
            "en": `<p>Customized solutions</p>`,
            "hi": `<p>Customized solutions</p>`,
          }
        },
        {
          "id": 6,
          "title": {
            "en": `Eligibility Criteria`,
            "hi": `Eligibility Criteria`,
          },
          "value": {
            "en": `<p>High Net Worth Individuals</p>`,
            "hi": `<p>High Net Worth Individuals</p>`,
          }
        },
        {
          "id": 7,
          "title": {
            "en": `Additional Benefits`,
            "hi": `Additional Benefits`,
          },
          "value": {
            "en": `<p>Lifestyle, wellness, and travel</p>`,
            "hi": `<p>Lifestyle, wellness, and travel</p>`,
          }
        },
      ]
      ,
      'compatitor_2': [
        {
          "id": 1,
          "title": {
            "en": `Dedicated Relationship Manager`,
            "hi": `Dedicated Relationship Manager`,
          },
          "value": {
            "en": `<p>Yes</p>`,
            "hi": `<p>Yes</p>`,
          }
        },
        {
          "id": 2,
          "title": {
            "en": `Premium Cards`,
            "hi": `Premium Cards`,
          },
          "value": {
            "en": `<p>Premium debit and credit cards</p>`,
            "hi": `<p>Premium debit and credit cards</p>`,
          }
        },
        {
          "id": 3,
          "title": {
            "en": `Preferential Interest Rates`,
            "hi": `Preferential Interest Rates`,
          },
          "value": {
            "en": `<p>Yes</p>`,
            "hi": `<p>Yes</p>`,
          }
        },
        {
          "id": 4,
          "title": {
            "en": `Concierge Services`,
            "hi": `Concierge Services`,
          },
          "value": {
            "en": `<p>Yes</p>`,
            "hi": `<p>Yes</p>`,
          }
        },
        {
          "id": 5,
          "title": {
            "en": `Wealth Management Solutions`,
            "hi": `Wealth Management Solutions`,
          },
          "value": {
            "en": `<p>Customized solutions</p>`,
            "hi": `<p>Customized solutions</p>`,
          }
        },
        {
          "id": 6,
          "title": {
            "en": `Eligibility Criteria`,
            "hi": `Eligibility Criteria`,
          },
          "value": {
            "en": `<p>High Net Worth Individuals</p>`,
            "hi": `<p>High Net Worth Individuals</p>`,
          }
        },
        {
          "id": 7,
          "title": {
            "en": `Additional Benefits`,
            "hi": `Additional Benefits`,
          },
          "value": {
            "en": `<p>Lifestyle, wellness, and travel</p>`,
            "hi": `<p>Lifestyle, wellness, and travel</p>`,
          }
        },
      ]
      ,
      'compatitor_3': [
        {
          "id": 1,
          "title": {
            "en": `Delivery Box`,
            "hi": `डिलिवरी बॉक्स`
          },
          "value": {
            "en": `<p><b>170 Cuft DV</b></p>`,
            "hi": `<p><b>170 Cuft DV</b></p>`
          }
        },
        {
          "id": 2,
          "title": {
            "en": `Peak Power`,
            "hi": `चरम शक्ति`
          },
          "value": {
            "en": `<p><b>12 KW</b></p>`,
            "hi": `<p><b>12 किलोवाट</b></p>`
          }
        },
        {
          "id": 3,
          "title": {
            "en": `Acceleration`,
            "hi": `त्वरण`
          },
          "value": {
            "en": `<p><b>Best in class</b></p>`,
            "hi": `<p><b>कक्षा में सबसे उत्तम</b></p>`
          }
        },
        {
          "id": 4,
          "title": {
            "en": `Gradeability`,
            "hi": `ग्रेडेबिलिटी`
          },
          "value": {
            "en": `<p><b>11.5°</b></p>`,
            "hi": `<p><b>11.5°</b></p>`
          }
        },
        {
          "id": 5,
          "title": {
            "en": `Warranty`,
            "hi": `वारंटी`
          },
          "value": {
            "en": `<p>5 yrs / 1,50,000 kms</p>`,
            "hi": `<p>5 वर्ष /1,50,000 किमी</p>`
          }
        },
        {
          "id": 6,
          "title": {
            "en": `Range`,
            "hi": `श्रेणी`
          },
          "value": {
            "en": `<p><b>100+ kms</b></p>`,
            "hi": `<p><b>100+ किमी</b></p>`
          }
        },
        {
          "id": 7,
          "title": {
            "en": `Wheelbase`,
            "hi": `व्हीलबेस`
          },
          "value": {
            "en": `<p>2200 mm</p>`,
            "hi": `<p>2200 मिमी</p>`
          }
        }
      ]
      ,
    },

    'faqs': {
      'def': TREO_PLUS_DEF_FAQS,
      'fleet_owners': TREO_PLUS_DEF_FAQS,
      'single_owner': TREO_PLUS_DEF_FAQS,
      'farmer': TREO_PLUS_DEF_FAQS,
      'dealer': TREO_PLUS_DEF_FAQS,
    },
  }
]

const PRODUCT_FILES_LIST = [
  {
    "productId": 1,
    "productName": "TREO Plus",
    "documentsData": [
      {
        'id': 1,
        'name': {
          'en': "Treo Plus USP.pdf",
          'hi': "Treo Plus USP Hindi.pdf"
        },
        'description': "Description 01",
        'findingName': 'one_pager',
        'type': "FILE",
        'filetype': 'FILE',
        'mimeType': "application/pdf",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Treo+Plus+USP+English.pdf",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Treo+Plus+USP+Hindi.pdf"
        },
      },
      {
        'id': 2,
        'name': {
          'en': "TREO Plus Introduction video.mp4",
          'hi': "TREO Plus Introduction video Hindi.mp4"
        },
        'findingName': 'video',
        'description': "Description 02",
        'type': "FILE",
        'filetype': 'video',
        'mimeType': "video/mp4",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/LINEUP+TREO+PLUS+ENG+%2B+AV+HIGH.mp4",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/TREO+Plus+Hindi++TVC.mp4"
        },
      },
      // {
      //     'id': 3,
      //     'name': "TREO Competitor comparison.pdf",
      //     'description': "Description 03",
      //     'type': "FILE",
      //     'filetype': 'FILE',
      //     'mimeType': "application/pdf",
      //     'path': {
      // 'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Treo+Plus+Flash+Cards.pdf",
      // 'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Treo+Plus+Flash+Cards.pdf"
      // },
      {
        'id': 4,
        'name': {
          'en': "TREO Plus 20 second promo video.mp4",
          'hi': "TREO Plus 20 second promo video Hindi.mp4",
        },
        'findingName': 'promo_video',
        'description': "Description 04",
        'type': "FILE",
        'filetype': 'video',
        'mimeType': "video/mp4",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/TRED+20+SEC+OPT+2.mp4",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Treo+20second-hindi.mp4"
        },
      },
      {
        'id': 5,
        'name': {
          'en': "TREO Plus brochure.pdf",
          'hi': "TREO Plus brochure Hindi.pdf",
        },
        'findingName': 'brochure',
        'description': "Description 05",
        'type': "FILE",
        'filetype': 'FILE',
        'mimeType': "application/pdf",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Treo+Plus+Broucher_June_English.pdf",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Treo+Plus+Broucher_June_English.pdf"
        },
      },
      {
        'id': 6,
        'name': {
          'en': "TREO Plus TCO english.pdf",
          'hi': "TREO Plus TCO hindi.pdf"
        },
        'description': "Description 01",
        'findingName': 'one_pager',
        'type': "FILE",
        'filetype': 'FILE',
        'mimeType': "application/pdf",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/TREO+Plus+TCO+english.pdf",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/TREO+Plus+TCO+hindi.pdf"
        },
      },
      {
        'id': 7,
        'name': {
          'en': "Technical Specs-english.pdf",
          'hi': "Technical Specs-hindi.pdf"
        },
        'description': "Description 01",
        'findingName': 'one_pager',
        'type': "FILE",
        'filetype': 'FILE',
        'mimeType': "application/pdf",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Technical+Specs-english.pdf",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Technical+Specs-hindi.pdf"
        },
      },


    ]
  },
  {
    "productId": 2,
    "productName": "CSB Zenith",
    "documentsData": [
      {
        'id': 1,
        'name': {
          'en': "WhatsApp_Banking_Intro.mp4",
        },
        'findingName': 'video',
        'description': "Description 01",
        'type': "FILE",
        'filetype': 'video',
        'mimeType': "video/mp4",
        'path': {
          'en': "/sample-documents/WhatsApp_Banking_Intro.mp4",
          'hi': "/sample-documents/WhatsApp_Banking_Intro.mp4",
        },
      },
      {
        'id': 2,
        'name': {
          'en': "Customer_Request_Form.pdf",
          'hi': "Customer_Request_Form.pdf",
        },
        'findingName': 'brochure',
        'description': "Description 02",
        'type': "FILE",
        'filetype': 'FILE',
        'mimeType': "application/pdf",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Customer_Request_Form.pdf",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Customer_Request_Form.pdf",
        },
      },
      {
        'id': 3,
        'name': {
          'en': "Service_Charges_and_Features_of_Premier_Banking.pdf",
          'hi': "Service_Charges_and_Features_of_Premier_Banking.pdf",
        },
        'findingName': 'one_pager',
        'description': "Description 04",
        'type': "FILE",
        'filetype': 'FILE',
        'mimeType': "application/pdf",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Service_Charges_and_Features_of_Premier_Banking.pdf",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Service_Charges_and_Features_of_Premier_Banking.pdf",
        },
      },
    ]
  },
  {
    'id': 6,
    'name': {
      'en': "TREO Plus TCO english.pdf",
      'hi': "TREO Plus TCO hindi.pdf"
    },
    'description': "Description 01",
    'findingName': 'one_pager',
    'type': "FILE",
    'filetype': 'FILE',
    'mimeType': "application/pdf",
    'path': {
      'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/TREO+Plus+TCO+english.pdf",
      'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/TREO+Plus+TCO+hindi.pdf"
    },
  },
  {
    'id': 7,
    'name': {
      'en': "Technical Specs-english.pdf",
      'hi': "Technical Specs-hindi.pdf"
    },
    'description': "Description 01",
    'findingName': 'one_pager',
    'type': "FILE",
    'filetype': 'FILE',
    'mimeType': "application/pdf",
    'path': {
      'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Technical+Specs-english.pdf",
      'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Technical+Specs-hindi.pdf"
    },
  },
];


const PERSONA_LIST = [
  {
    'id': 1,
    'name': 'Fleet Owners',
    'encodedName': 'fleet_owners',
    'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/fleet-owners.png',
    'documentsLinked': 5,
  },
  {
    'id': 2,
    'name': 'Single Owner',
    'encodedName': 'single_owner',
    'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/single-owner.png',
    'documentsLinked': 5,

  },
  {
    'id': 3,
    'name': 'Rural Buyer',
    'encodedName': 'rural_buyer',
    'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/rural-buyer.png',
    'documentsLinked': 5,

  },
  {
    'id': 4,
    'name': 'Dealer',
    'encodedName': 'dealer',
    'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/dealer.png',
    'documentsLinked': 5,

  },
]

const RIVAL_PRODUCT_DETAILS = [
  {
    "productId": 1,
    "productName": "TREO Plus",
    "competitorData": [
      {
        'id': 1,
        'productId': 1,
        'name': 'Competitor 1',
        'productName': 'Bajaj RE EV',
        'findingName': 'compatitor_1',
        'productImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Bajaj_ev_product_1.png',
        'orgCoverImage': 'https://cdn.bajajauto.com/-/media/assets/bajajauto/360degreeimages/3-wheelers-and-qute/re-passenger/01.png',
        'comments': 4,
        'documentsLinked': 8,
        'launchType': 'launch',
        'specifications': [
          {
            "id": 1,
            "title": {
              "en": `Top Speed`,
              "hi": `Top Speed`
            },
            "value": {
              "en": `<p>45 km/hr</p>`,
              "hi": `<p>45 किमी./घंटा</p>`
            }
          },
          {
            "id": 2,
            "title": {
              "en": `Space (Wheelbase)`,
              "hi": `व्हीलबेस`
            },
            "value": {
              "en": `<p>1.920 mm</p>`,
              "hi": `<p>1,920 मीमी</p>`
            }
          },
          {
            "id": 3,
            "title": {
              "en": `Battery Capacity`,
              "hi": `बैटरी की क्षमता`
            },
            "value": {
              "en": `<p>8.9 kWh</p>`,
              "hi": `<p>8.9 किलोवाट</p>`
            }
          },
          {
            "id": 4,
            "title": {
              "en": `Driving Range`,
              "hi": `ड्राइविंग रेंज`
            },
            "value": {
              "en": `<p>150 Kms</p>`,
              "hi": `<p>150 कि.मी.</p>`
            }
          },
          {
            "id": 5,
            "title": {
              "en": `Body Type`,
              "hi": `बॉडी का प्रकार`
            },
            "value": {
              "en": `<p>Metal Body</p>`,
              "hi": `<p>मेटल बॉडी</p>`
            }
          },
          {
            "id": 6,
            "title": {
              "en": `Warranty`,
              "hi": `वारंटी`
            },
            "value": {
              "en": `<p>3 years / 80,000 Kms.</p>`,
              "hi": `<p>3 वर्ष/80,000 कि.मी.</p>`
            }
          },
          {
            "id": 7,
            "title": {
              "en": `Charger`,
              "hi": `चार्जर`
            },
            "value": {
              "en": `<p>On board</p>`,
              "hi": `<p>ऑनबोर्ड</p>`
            }
          }
        ]

      },
      {
        'id': 2,
        'productId': 2,
        'name': 'Competitor 2',
        'productName': 'Piaggio Ape Electric',
        'findingName': 'compatitor_2',
        'productImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Piaggio_ape_product_1.png',
        'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/piaggeo-e-city-FM-MAX.png',
        'comments': 4,
        'documentsLinked': 8,
        'launchType': 'launch',
        'specifications': [
          {
            "id": 1,
            "title": {
              "en": `Power`,
              "hi": `पावर`
            },
            "value": {
              "en": `<p>5.44 kW</p>`,
              "hi": `<p>5.44 किलोवाट</p>`
            }
          },
          {
            "id": 2,
            "title": {
              "en": `Torque`,
              "hi": `टॉर्क`
            },
            "value": {
              "en": `<p>29 Nm</p>`,
              "hi": `<p>29 एनएम</p>`
            }
          },
          {
            "id": 3,
            "title": {
              "en": `Top speed`,
              "hi": `उच्चतम गति`
            },
            "value": {
              "en": `<p>50 km/hr</p>`,
              "hi": `<p>50 कि.मी./घंटा</p>`
            }
          },
          {
            "id": 4,
            "title": {
              "en": `Battery Capacity`,
              "hi": `बैटरी की क्षमता`
            },
            "value": {
              "en": `<p>8 kWh</p>`,
              "hi": `<p>8 किलोवाट</p>`
            }
          },
          {
            "id": 5,
            "title": {
              "en": `Driving Range`,
              "hi": `ड्राइविंग रेंज`
            },
            "value": {
              "en": `<p>140 Kms</p>`,
              "hi": `<p>140 कि.मी.</p>`
            }
          },
          {
            "id": 6,
            "title": {
              "en": `Space (Wheelbase)`,
              "hi": `व्हीलबेस`
            },
            "value": {
              "en": `<p>1,920 mm</p>`,
              "hi": `<p>1,920 मीमी</p>`
            }
          },
          {
            "id": 7,
            "title": {
              "en": `Body Type`,
              "hi": `बॉडी का प्रकार`
            },
            "value": {
              "en": `<p>Metal Body</p>`,
              "hi": `<p>मेटल बॉडी</p>`
            }
          },
          {
            "id": 8,
            "title": {
              "en": `Warranty`,
              "hi": `वारंटी`
            },
            "value": {
              "en": `<p>3 years / 1,75,000 Kms</p>`,
              "hi": `<p>3 वर्ष/1,75,000 कि.मी.</p>`
            }
          }
        ]
        ,
      },
      {
        'id': 3,
        'productId': 3,
        'name': 'Competitor 3',
        'productName': 'Montra',
        'findingName': 'compatitor_3',
        'productImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Montra_product.png',
        'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/montra.png',
        'comments': 4,
        'documentsLinked': 8,
        'launchType': 'launch',
        'specifications': [
          {
            "id": 1,
            "title": {
              "en": `Battery Capacity`,
              "hi": `बैटरी की क्षमता`
            },
            "value": {
              "en": `<p>7.66 kWh & 10 kWh</p>`,
              "hi": `<p>7.66 एवं 10 किलोवाट</p>`
            }
          },
          {
            "id": 2,
            "title": {
              "en": `Charging Time`,
              "hi": `Charging Time`
            },
            "value": {
              "en": `<p>6 hr</p>`,
              "hi": `<p>6 घंटा</p>`
            }
          },
          {
            "id": 3,
            "title": {
              "en": `Driving Range`,
              "hi": `ड्राइविंग रेंज`
            },
            "value": {
              "en": `<p>150+ Kms</p>`,
              "hi": `<p>150+ किमी</p>`
            }
          },
          {
            "id": 4,
            "title": {
              "en": `Space (Wheelbase)`,
              "hi": `व्हीलबेस`
            },
            "value": {
              "en": `<p>2,010 mm</p>`,
              "hi": `<p>2,010 मिमी</p>`
            }
          },
          {
            "id": 5,
            "title": {
              "en": `Trust`,
              "hi": `ट्रस्ट`
            },
            "value": {
              "en": `<p>50 service centres</p>`,
              "hi": `<p>50 सेवा केंद्र</p>`
            }
          },
          {
            "id": 6,
            "title": {
              "en": `Variant`,
              "hi": `वेरिएंट`
            },
            "value": {
              "en": `<p>3 Variants: Super ePV 2.0, Super ePV and Super ePx</p>`,
              "hi": `<p>3 वेरिएंट</p>`
            }
          }
        ]

      },
    ]
  },

  // new change : 
  {
    "productId": 2,
    "productName": "CSB Zenith",

    "competitorData": [
      {
        'id': 1,
        'productId': 1,
        'name': 'Competitor 1',
        'productName': 'Competitor 1',
        'findingName': 'compatitor_1',
        'productImage': '/images/common/hdfc_logo.jpg',
        'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/bajajzor1.png',
        'comments': 4,
        'documentsLinked': 8,
        'launchType': 'launch',
        'specifications': [
          {
            "id": 1,
            "title": {
              "en": `Dedicated Relationship Manager`,
              "hi": `Dedicated Relationship Manager`,
            },
            "value": {
              "en": `<p>Yes</p>`,
              "hi": `<p>Yes</p>`,
            }
          },
          {
            "id": 2,
            "title": {
              "en": `Premium Cards`,
              "hi": `Premium Cards`,
            },
            "value": {
              "en": `<p>Imperia Debit & Credit Cards</p>`,
              "hi": `<p>Imperia Debit & Credit Cards</p>`,
            }
          },
          {
            "id": 3,
            "title": {
              "en": `Preferential Interest Rates`,
              "hi": `Preferential Interest Rates`,
            },
            "value": {
              "en": `<p>Yes</p>`,
              "hi": `<p>Yes</p>`,
            }
          },
          {
            "id": 4,
            "title": {
              "en": `Concierge Services`,
              "hi": `Concierge Services`,
            },
            "value": {
              "en": `<p>Yes</p>`,
              "hi": `<p>Yes</p>`,
            }
          },
          {
            "id": 5,
            "title": {
              "en": `Wealth Management Solutions`,
              "hi": `Wealth Management Solutions`,
            },
            "value": {
              "en": `<p>Comprehensive wealth solutions </p>`,
              "hi": `<p>Comprehensive wealth solutions </p>`,
            }
          },
          {
            "id": 6,
            "title": {
              "en": `Eligibility Criteria`,
              "hi": `Eligibility Criteria`,
            },
            "value": {
              "en": `<p>High Net Worth Individuals</p>`,
              "hi": `<p>High Net Worth Individuals</p>`,
            }
          },
          {
            "id": 7,
            "title": {
              "en": `Additional Benefits`,
              "hi": `Additional Benefits`,
            },
            "value": {
              "en": `<p>Lifestyle benefits, lounge access </p>`,
              "hi": `<p>Lifestyle benefits, lounge access </p>`,
            }
          },
        ]

      },
      {
        'id': 2,
        'productId': 2,
        'name': 'Competitor 2',
        'productName': 'Competitor 2',
        'findingName': 'compatitor_2',
        'productImage': '/images/common/icici_logo.jpg',
        'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/piaggio.png',
        'comments': 4,
        'documentsLinked': 8,
        'launchType': 'launch',
        'specifications': [
          {
            "id": 1,
            "title": {
              "en": `Dedicated Relationship Manager`,
              "hi": `Dedicated Relationship Manager`,

            },
            "value": {
              "en": `<p>Yes</p>`,
              "hi": `<p>Yes</p>`,
            }
          },
          {
            "id": 2,
            "title": {
              "en": `Premium Cards`,
              "hi": `Premium Cards`,
            },
            "value": {
              "en": `<p>Wealth Management Debit & Credit Cards</p>`,
              "hi": `<p>Wealth Management Debit & Credit Cards</p>`,
            }
          },
          {
            "id": 3,
            "title": {
              "en": `Preferential Interest Rates`,
              "hi": `Preferential Interest Rates`,
            },
            "value": {
              "en": `<p>Yes</p>`,
              "hi": `<p>Yes</p>`,
            }
          },
          {
            "id": 4,
            "title": {
              "en": `Concierge Services`,
              "hi": `Concierge Services`,
            },
            "value": {
              "en": `<p>Yes</p>`,
              "hi": `<p>Yes</p>`,
            }
          },
          {
            "id": 5,
            "title": {
              "en": `Wealth Management Solutions`,
              "hi": `Wealth Management Solutions`,
            },
            "value": {
              "en": `<p>Tailored investment strategies</p>`,
              "hi": `<p>Tailored investment strategies</p>`,
            }
          },
          {
            "id": 6,
            "title": {
              "en": `Eligibility Criteria`,
              "hi": `Eligibility Criteria`,
            },
            "value": {
              "en": `<p>High Net Worth Individuals</p>`,
              "hi": `<p>High Net Worth Individuals</p>`,
            }
          },
          {
            "id": 7,
            "title": {
              "en": `Additional Benefits`,
              "hi": `Additional Benefits`,
            },
            "value": {
              "en": `<p>Lifestyle privileges, exclusive offers</p>`,
              "hi": `<p>Lifestyle privileges, exclusive offers</p>`,
            }
          },
        ]
        ,
      },

    ]
  }
]

export const salesEnablementDataCSB = {
  PRODUCT_DETAILS_NAVIGATION_STATUS,
  PRODUCT_LIST,
  PERSONA_LIST,
  PRODUCT_DETAIL_LIST,
  PRODUCT_FILES_LIST,
  RIVAL_PRODUCT_DETAILS
}