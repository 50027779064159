import React, { useEffect, useState, useRef, useContext } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import FreshChat from "react-freshchat";
import { useTranslation } from "react-i18next";
import { isIOS } from 'react-device-detect';


// mui
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Hidden } from "@mui/material";

// bridge
import webViewJavaScriptBridge from "webview-javascript-bridge";

// redux
import { toggleSideDrawer, setViewMicroskillByCategory, setSelectedLanguage } from "../../../redux/common/common-slice";
import { logout } from "../../../redux/auth/auth.api";

// utils
import isEmpty from "../../../utils/isEmpty";
import commonUtil from "../../../utils/commonUtil";
// import i18n from '../../../utils/i18n'

// constants
import constants from '../../../constants';

// theme
import palette from '../../../theme/palette';
import theme from "../../../theme";

// styles
import useStyles from './index.styles';
import { setToken } from "../../../redux/auth/auth-slice";
import { postUserLanguage } from "../../../redux/user-specific/userSpecific.api";
import CommonContext from "../../../context/CommonContext";
import { setChristmasGiftAnimation, setIsDarkMode } from "../../../redux/common-persist/common-persist-slice";

// lottie
import lottie from "lottie-web";
import Lottie from 'react-lottie-player';
import giftAnnouncement from '../../../lottie-assets/gift-announcement.json'
import ChristmasGiftAnimation from "../../christmas-gift";

const label = { inputProps: { 'aria-label': 'Color switch demo' } };



const LightDarkModeSwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    transform: 'translateX(5px) scale(0.8)',
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {

                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#F4511E',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    transform: 'translateX(5px) scale(0.8)',
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#F4511E',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#aab4be',
        borderRadius: 20 / 2,
    },
}));

const SideDrawer = (props) => {
    const { handleDrawerToggle, isLanguageChanging, setIsLanguageChanging, mobileOpen } = props;
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetail = useSelector(state => state?.auth?.user);
    const isDrawerCollapsed = useSelector(state => state.common.isSideDrawerCollapsed);
    const viewMicroskillByCategory = useSelector(state => state.common.viewMicroskillByCategory);
    const { isMobile, selectedLanguage } = useSelector(state => state.common)
    const { viewChristmasGiftAnimation, isDarkMode } = useSelector(state => state?.commonPersist);
    const [activePage, setActivePage] = useState('');
    const [showFreshChat, setShowFreshChat] = useState(false);
    const { setMobileOpen } = useContext(CommonContext);
    const userType = localStorage.getItem('userType') ?? constants.USER_TYPES.USER;
    const isOnlyManager = userType === constants.USER_TYPES.MANAGER;

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    const chatRef = useRef();
    var freshchatWidget;
    const { t, i18n } = useTranslation();
    const classes = useStyles(i18n);


    let toggleSwitchText1 = t('View Microskills By Status');
    let toggleSwitchText2 = t('View Microskills By Category');

    const logos = userDetail?.companyTheme?.logo;

    useEffect(() => {
        setActivePage(location.pathname)
    }, [location.pathname]);

    const handleLogout = async () => {
        const isNativeApp = localStorage.getItem("isNative");
        if (isNativeApp === 'true') {
            webViewJavaScriptBridge.sendMessage({ action: 'DarkModeSyncChannel', params: true }, function (response) {
                console.log("Dark Mode channel bridge response:", response);
            });
        }
        dispatch(setToken(''));
        setMobileOpen(false);
        navigate('/login');
        await logout();
    }

    const handleSwitchChange = (event) => {
        dispatch(setViewMicroskillByCategory(!viewMicroskillByCategory));
    };

    useEffect(() => {

        const isNativeApp = localStorage.getItem("isNative");
        console.log('Calling Dark Mode channel bridge:', isDarkMode, isNativeApp);
        if (isNativeApp === 'true') {
            webViewJavaScriptBridge.sendMessage({ action: 'DarkModeSyncChannel', params: isDarkMode }, function (response) {
                console.log("Dark Mode channel bridge response:", response);
            });
        }

    }, [isDarkMode])

    useEffect(() => {
        window.fcWidgetMessengerConfig = {
            config: {
                headerProperty: {
                    hideChatButton: true,
                }
            }
        }
        const isNative = localStorage.getItem('isNative');
        if (showFreshChat) {
            // console.log(rdd);
            document.getElementById('fc_frame').style.display = 'block';
            if (isNative === 'true') {
                webViewJavaScriptBridge.sendMessage({ action: 'disableresizeToAvoidBottomInset', params: true });
            }
            // document.getElementById('fc_frame').style.paddingTop = isIOS ? constants.HEADER_TOP_PADDING : '0';   // to resolve conflict with native header in ios devices
            // document.getElementById('fc_frame').style.paddingBottom = isIOS ? '1rem' : '0rem';
            window.fcWidget.open();
            window.fcWidget.show();
        }
        else {
            try {
                if (document.getElementById('fc_frame') === null) return;
                if (isNative === 'true') {
                    webViewJavaScriptBridge.sendMessage({ action: 'disableresizeToAvoidBottomInset', params: false });
                }
                document.getElementById('fc_frame').style.display = 'none';
                document.getElementById('fc_frame').style.paddingTop = '0rem';
                document.getElementById('fc_frame').style.paddingBottom = '0rem';
                setShowFreshChat(false);
                window.fcWidget.hide();
                window.fcWidget.close();
            } catch (err) {
                console.log(err);
            }
        }
    }, [showFreshChat, isIOS])

    const handleChatWithUs = () => {
        setShowFreshChat(true)
    }

    const handleAppLangClick = async () => {
        await sleep(300)
        setIsLanguageChanging(true)
    }

    const handlePolicy = (d) => {
        setMobileOpen(false)
        navigate({
            pathname: "/privacy-policy",
            // search: d.url.includes('privacy-policy') ? '?name=Privacy Policy' : ''
        });
    }

    const LanguagesListUi = () => {
        return (
            <List sx={{ paddingTop: '0rem' }} className={classes.langListMobile}>
                <ListItem className={`${classes.listBox}`}>
                    <div style={{ paddingLeft: '0.5rem', marginBottom: '0.5rem', fontSize: '1.2rem' }}>
                        {t("Select App Language")}
                    </div>
                </ListItem>

                {
                    constants.LANGUAGES_LIST?.map((elem => (
                        <ListItem className={classes.langListBox} style={{ paddingBottom: '0' }} key={`${elem.id}-${elem.key}`}>
                            <ListItemButton className={classes.langListItemButton}
                                onClick={async () => {
                                    await sleep(200)
                                    dispatch(setSelectedLanguage(elem.name))
                                    setIsLanguageChanging(false)
                                    i18n.changeLanguage(elem.key);
                                    await postUserLanguage({ "language": elem.name })
                                    // let resp = await postUserLanguage({"language": elem.name})
                                }}
                            >
                                <ListItemText primary={elem.name} className={classes.sideBarItemText} />
                                {
                                    selectedLanguage === elem.name &&
                                    <CheckRoundedIcon sx={{ color: palette.white, fontSize: 25 }} />
                                }
                                {/* <span><img src="images/icons/heart.png" alt="selected" width={25} /></span> */}
                            </ListItemButton>
                        </ListItem>
                    )))
                }
            </List>
        )
    }

    const handleShareMasterO = async () => {
        let masteroShareLink = 'http://onelink.to/mzra9m';
        let masteroShareText = 'I wanted to share a really useful app, called Master-O, that can improve your skills on the go through playing games & interactive content. \nYou can download Master-O using this link: ' + masteroShareLink + ' \nCheck it out!'
        let isNativeApp = localStorage.getItem("isNative");
        if (isNativeApp === 'true') {
            masteroShareText += '|Share Master-O|link';
            webViewJavaScriptBridge.sendMessage({ action: 'ShareChannel', params: masteroShareText }, (response) => {
                console.log('ShareChannel response:', response);
            });
            ;
        } else {
            window.location.href = masteroShareLink;
        }
    }

    // Define a mapping of item keys to functions
    const actionMapping = {
        logout: handleLogout,
        support_chat: handleChatWithUs,
        app_language: handleAppLangClick,
        privacy_policy: handlePolicy,
        share_mastero: handleShareMasterO
    };

    return (
        <>
            <Box sx={{
                width: '100%', maxWidth: isMobile ? '100%' : 360,
                // paddingTop: showFreshChat ? "3rem" : ""
            }}>
                <FreshChat
                    ref={chatRef}
                    token="3d0017c5-0a60-4693-bdae-3def0407cf94"
                    // widgetUuid="25380827-750a-40ae-aca5-8f2a3355f15c"
                    onInit={(widget) => {
                        freshchatWidget = widget;
                        widget.on("widget:closed", function () {
                            // console.log("widget was closed");
                            document.getElementById("fc_frame").style.display = "none";
                            document.getElementById("fc_frame").style.paddingTop = "0rem";
                            document.getElementById("fc_frame").style.paddingBottom = "0rem";
                            setShowFreshChat(false)
                        });
                    }}
                />
                <nav style={{
                    position: 'relative',
                }}>
                    <Box sx={theme => ({
                        [theme.breakpoints.up('md')]: {
                            display: 'block'
                        },
                        [theme.breakpoints.down('md')]: {
                            display: 'none'
                        },
                    })}>
                        <List sx={{ paddingTop: '0rem', overflow: 'hidden' }}>
                            <ListItem className={`${classes.listBox} ${classes.logoBox}`} sx={theme => ({
                                backgroundColor: theme.palette.headerBg,
                                paddingRight: isDrawerCollapsed ? '' : '1rem !important',
                                '& .MuiListItemButton-root': {
                                    borderRadius: '5px',
                                },
                            })}>
                                <ListItemButton className={classes.listItemButton} sx={theme => ({
                                    padding: theme.spacing(1, 0),
                                    "&:hover": {
                                        backgroundColor: 'transparent !important',
                                        // borderRadius: isDrawerCollapsed ? '50%' : '5px',
                                    },
                                })}>
                                    <div className={`${classes.centerFlex} ${classes.logoContainer}`}
                                        onClick={() => dispatch(toggleSideDrawer(!isDrawerCollapsed))}
                                    >
                                        <img src={`/images/icons/mastero_blue_loading.png`}
                                            height={35}
                                            style={{
                                                filter: isDarkMode ? 'brightness(0) invert(1)' : 'none',
                                            }}
                                        />
                                        <img src={`/images/icons/mastero_text_dark.svg`} width={100} style={{
                                            paddingLeft: '1rem',
                                            transform: 'scale(1.3)',
                                            filter: isDarkMode ? 'invert(1)' : 'none',
                                        }} />

                                    </div>
                                </ListItemButton>
                            </ListItem>

                            {
                                constants.DRAWER_MENU.map((item, index) => (
                                    <ListItem className={classes.listBox} key={`${item.id}-${index}`} sx={theme => ({
                                        paddingRight: isDrawerCollapsed ? '' : '1rem !important',
                                    })}>
                                        <ListItemButton className={classes.listItemButton} sx={theme => ({
                                            marginBottom: '0.9rem',
                                            borderRadius: isDrawerCollapsed ? '50%' : '5px',
                                            padding: theme.spacing(0),
                                            "&:hover": {
                                                backgroundColor: activePage === item.url ? 'transparent !important' : "",
                                                // borderRadius: isDrawerCollapsed ? '50%' : '5px',
                                            },
                                        })}>
                                            <a href={item.value !== 'logout' ? item.url : null} className={`${classes.anchorLink} ${classes.centerFlex}`}
                                                onClick={item.value === 'logout' ? handleLogout : item.key === 'support_chat' ? handleChatWithUs : null}
                                            >
                                                <div className={`${classes.centerFlex} ${classes.iconBox} ${activePage === item.url ? classes.highlightedIcon : null}`}>
                                                    <img src={`${item.icon_img_url}`} alt={item.value} className={classes.icon} width={100} style={{
                                                        filter: `${activePage === item.url ? 'none' : isDarkMode ? 'brightness(0) invert(1)' : 'brightness(0) invert(0.5)'}`
                                                    }} />
                                                </div>
                                                <ListItemText primary={t(item.name)} className={classes.sideBarItemText} />
                                            </a>
                                        </ListItemButton>
                                    </ListItem>
                                ))
                            }

                            {/* <div style={{ color: '#FFF', marginTop: '2rem' }} onClick={handleLogout}>
                        Logout
                    </div> */}
                        </List>
                    </Box>

                    <Box sx={theme => ({
                        [theme.breakpoints.up('md')]: {
                            display: 'none'
                        },
                        [theme.breakpoints.down('md')]: {
                            display: 'block'
                        },
                    })}>
                        {
                            isLanguageChanging ? (
                                <LanguagesListUi />
                            ) : (
                                <>
                                    {
                                        mobileOpen && <ChristmasGiftAnimation wrapperPosition={'absolute'} containerWidth={'90%'} />
                                    }
                                    <List sx={{
                                        paddingTop: '0rem',
                                        filter: viewChristmasGiftAnimation ? 'blur(5px)' : "" // christmas gift animation
                                    }} className={classes.listMobile}>
                                        <ListItem className={`${classes.listBox} ${classes.logoBox}`}>
                                            <ListItemButton className={classes.listItemButton} style={{ maxWidth: '100vw' }}>
                                                <div style={{ fontSize: i18n.language === 'ml' ? '0.9em' : '1em' }}>
                                                    {t("Logged in as")}: <span style={{ paddingLeft: i18n.language === 'ml' ? '0.3rem' : '0.5rem' }}>{commonUtil.getUserName()}</span>
                                                </div>
                                            </ListItemButton>
                                        </ListItem>

                                        {
                                            constants.HEADER_DROPDOWN.map((item, index) => {
                                                let item_name = item.key === "app_language" ? `${t(item.name)}: ${selectedLanguage}` : t(item.name);
                                                if (item.restrictedFor.includes(userType)) return null;
                                                return (
                                                    <React.Fragment key={`${index}-${item.id}-${item.key}`}>
                                                        {
                                                            item.key === 'settings' ? (
                                                                <div style={{
                                                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                                                }}>
                                                                    <ListItem className={`${classes.listBox} ${classes.sideDropDown} ${classes.bgTransparent}`}>
                                                                        <ListItemButton className={classes.listItemButton}>
                                                                            <div className={`${classes.centerFlex} ${classes.iconBoxMobile} ${activePage === item.url ? classes.highlightedIcon : null}`}>
                                                                                <img src={`${item.icon_orange}`} alt={item.value} className={classes.icon} width={50} />
                                                                            </div>
                                                                            <ListItemText primary={t(item.name)} className={classes.sideBarItemText} />
                                                                        </ListItemButton>
                                                                    </ListItem>

                                                                    <ListItem className={`${classes.listBox} ${classes.sideDropDown}  ${classes.bgTransparent}`} >
                                                                        <div className={classes.listItemButtonSwitch}>
                                                                            <span className={classes.toggleSwitchText}>{t("App Theme")}</span>
                                                                            <LightDarkModeSwitch
                                                                                checked={!isDarkMode}
                                                                                onChange={() => {
                                                                                    dispatch(setIsDarkMode(!isDarkMode))
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </ListItem>
                                                                    {!isOnlyManager && <ListItem className={`${classes.listBox} ${classes.sideDropDown}  ${classes.bgTransparent}`} >
                                                                        <div className={classes.listItemButtonSwitch}>
                                                                            <span className={classes.toggleSwitchText}>{viewMicroskillByCategory ? toggleSwitchText1 : toggleSwitchText2}</span>
                                                                            <CustomSwitch checked={viewMicroskillByCategory} onChange={handleSwitchChange} />
                                                                        </div>
                                                                    </ListItem>}
                                                                </div>
                                                            ) : (
                                                                <ListItem className={classes.listBox} key={`${item.id}-${item.key}`}>
                                                                    <ListItemButton className={classes.listItemButton}
                                                                        // onClick={item.key === 'logout' ? handleLogout : item.key === 'support_chat' ? handleChatWithUs : item.key === 'app_language' ? handleAppLangClick : item.key === 'privacy_policy' ? handlePolicy : item.key === 'share_mastero' ? handleShareMasterO : item.func}
                                                                        onClick={actionMapping[item.key] || item.func}
                                                                    >
                                                                        <div className={`${classes.centerFlex} ${classes.iconBoxMobile} ${activePage === item.url ? classes.highlightedIcon : null}`}>
                                                                            <img src={`${item.icon_orange}`} alt={item.value} className={classes.icon} width={50} />
                                                                            {/* <img src={`${activePage === item.url ? item.icon_orange : item.icon_white}`} alt={item.value} className={classes.icon} width={50} /> */}
                                                                        </div>
                                                                        <a href={item.key !== 'logout' ? item.url : null} className={classes.anchorLink}
                                                                        // onClick={item.key === 'logout' ? handleLogout : item.key === 'support_chat' ? handleChatWithUs : item.key === 'app_language' ? ()=> openLanguageChanging() : item.func}
                                                                        >
                                                                            <ListItemText primary={item_name} className={classes.sideBarItemText} />
                                                                        </a>
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            )
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }

                                        {/* <div style={{ color: '#FFF', marginTop: '2rem' }} onClick={handleLogout}>
                        Logout
                    </div> */}
                                    </List>
                                </>
                            )
                        }
                    </Box>
                </nav >
            </Box >

            {
                !isLanguageChanging && (
                    <>
                        {isDarkMode && !isEmpty(logos?.darkSideBarLogo) && (
                            <img src={logos.darkSideBarLogo}
                                alt="logo"
                                className={classes.companyBranding}
                                // style={{
                                //     opacity: isDrawerCollapsed ? '0' : '1'
                                // }}
                            />
                        )}
                        {!isDarkMode && !isEmpty(logos?.lightSideBarLogo) && (
                            <img src={logos.lightSideBarLogo}
                                alt="logo"
                                className={classes.companyBranding}
                                // style={{
                                //     opacity: isDrawerCollapsed ? '0' : '1'
                                // }}
                            />
                        )}
                    </>

                )
            }
        </>
    );
}

export default SideDrawer;