import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    completionRoot: {
        display: 'flex',
        alignItems: 'center'
    },
    completionPercentage: {
        fontFamily: 'Montserrat',
        // fontSize: '1rem',
        fontWeight: 700,
        paddingLeft: '.4rem',
        textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]: {
            // fontSize: '.8rem',
        },
    }
}))

export default useStyles