import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'


// redux
import { saveDeviceToken, setTokenState } from '../../redux/auth/auth.api';

// utils
import isEmpty from "../../utils/isEmpty"

//  component
import LoadingMicroskill from "../../components/loading-microskill";
import { useSelector } from 'react-redux';

const GooglePage = () => {
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(useLocation().search)
    const accessToken = queryParams.get("accessToken");
    const refreshToken = queryParams.get("refreshToken");
    const commonState = useSelector(state => state.common);

    useEffect(() => {
        (async () => {
            if (isEmpty(accessToken) || isEmpty(refreshToken)) navigate('/login');

            const data = {};
            data['tokens'] = {
                accessToken,
                refreshToken
            }
            // console.log(commonState)
            let isNative = localStorage.getItem('isNative');
            if (commonState?.isNativeApp || isNative === 'true') {
                const deviceToken = await saveDeviceToken()
                console.log(deviceToken)
            }
            setTokenState(data);
            navigate('/learn');
        })()

    }, [])

    return (
        <LoadingMicroskill />
    )
}

export default GooglePage