import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import ReactPlayer from "react-player";

//styles
import useStyle from "./index.styles";

const AudioPlayer = forwardRef(({ filePath, totalTrackDuration, handlePlayBtnClick, hanldeTrackEnd }, ref) => {
    //const tracks = filteredData;
    const classes = useStyle();

    //const [trackIndex, setTrackIndex] = useState(index);
    const [trackProgress, setTrackProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    // Destructure for conciseness

    // Refs
    const audioRef = useRef(new Audio(filePath));
    const intervalRef = useRef();
    const timeElapsedRef = useRef();
    const isReady = useRef(false);
    const reactAudioPlayerRef = useRef();

    // Destructure for conciseness
    const { duration } = audioRef.current;

    const currentPercentage = duration ? `${(trackProgress / duration) * 100}%` : "0%";
    const trackStyling = `-webkit-gradient(linear, 0% 0%, 100% 0%, from(#ffffff), from(#f19191b5))`;
    // const trackStyling = `-webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))`;

    useImperativeHandle(ref, () => ({
        handlePlayPause(state) {
            setIsPlaying(state);
            startTimer();
        }
    }));

    const getDisplayTimer = () => {
        let audioCurrentTime = Math.round(reactAudioPlayerRef.current.getCurrentTime());
        let timeInSeconds = audioCurrentTime % 60 > 9 ? (audioCurrentTime % 60) : '0' + (audioCurrentTime % 60);
        let timeInMinutes = parseInt(audioCurrentTime / 60);
        return (`${timeInMinutes}:${timeInSeconds}`);
    }
    const startTimer = () => {
        // Clear any timers already running
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            timeElapsedRef.current.innerHTML = getDisplayTimer();
            setTrackProgress(reactAudioPlayerRef.current.getCurrentTime());
        }, 1000);
    };

    const handleAudioEnd = () => {
        setIsPlaying(false);
        clearInterval(intervalRef.current);
        // handlePlayBtnClick(false);
        hanldeTrackEnd();
    }

    const onScrub = (value) => {
        // Clear any timers already running
        clearInterval(intervalRef.current);
        reactAudioPlayerRef.current.seekTo(value, 'seconds')
        timeElapsedRef.current.innerHTML = getDisplayTimer();
        setTrackProgress(reactAudioPlayerRef.current.getCurrentTime());
    };

    const onScrubEnd = () => {
        // If not already playing, start
        if (!isPlaying) {
            setIsPlaying(true);
            handlePlayBtnClick(false);
        }
        startTimer();
    };

    useEffect(() => {
        if (isPlaying) {
            // audioRef.current.play();
            startTimer();
        } else {
            // audioRef.current.pause();
        }
    }, [isPlaying]);


    useEffect(() => {
        // Pause and clean up on unmount
        return () => {
            // audioRef.current.pause();
            setIsPlaying(false);
            clearInterval(intervalRef.current);
        };
    }, []);


    return (
        <div className={classes.audioPlayer} >
            <div className={classes.trackSlider}>
                <input
                    type="range"
                    value={trackProgress}
                    step="1"
                    min="0"
                    max={duration ? duration : `${duration}`}
                    className={classes.slider}
                    onChange={(e) => onScrub(e.target.value)}
                    onMouseUp={onScrubEnd}
                    onTouchEnd={onScrubEnd}
                    onKeyUp={onScrubEnd}
                    style={{ background: trackStyling }}
                />
            </div>
            <div className={classes.timerBox}>
                <div className={classes.time1} ref={timeElapsedRef}> {'0.00'} </div>
                <div className={classes.time2}>{totalTrackDuration}</div>
            </div>

            <ReactPlayer
                id="reactPlayer"
                playsinline
                url={filePath}
                playing={isPlaying}
                style={{
                    opacity: 0,
                    position: 'fixed',
                    zIndex: '-1000',
                    top: '1000px',
                }}
                // className={classes.reactVideoPlayer}
                // onProgress={handleProgressCallback}
                onEnded={handleAudioEnd}
                // onClick={handleVideoPlayPause}
                // onReady={onVideoReady}
                ref={reactAudioPlayerRef}
            />
        </div>
    )
})

export default AudioPlayer;