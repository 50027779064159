import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      fontFamily: "Montserrat !important",
      justifyContent: "center",
      padding: "2rem",
      maxWidth: "400px",
      margin: "auto",
      "& video": {
        objectFit: "cover",
        borderRadius: "20px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "1rem 1rem",
      },
    },
    self: {
      fontWeight: "800",
      fontSize: "3rem",
    },
    sub: {
      marginTop: "1rem",
      fontSize: "1.1rem",
    },
    controlsContainer: {
      position: "absolute",
      border: "5px solid #F4511E",
      borderRadius: "50%",
      width: "3rem",
      height: "3rem",
      padding: "1rem",
      left: "37%",
      bottom: "4rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      fontSize: "6.5rem",
      color: "#F4511E",
    },
    button: {
      marginBottom: "1rem",
      padding: "2rem 2rem",
      fontFamily: "Montserrat",
      fontSize: "1.5rem",
      background: "#FFB300",
      color: "white",
      flex: "1",
      fontWeight: "600",
      [theme.breakpoints.up("md")]: {
        padding: "1rem 1rem",
      },
    },
    rootContainer: {
      position: "relative",
    },
    closeBtnBox: {
      position: 'relative',
      // height: '45px',
      width: '45px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      overflow: 'hidden',
      aspectRatio: '1/1'
    },
    closeBtnBg: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      backdropFilter: 'blur(15px)',
      background: '#ffffff26'
    },
    reactVideoPlayerBox: {
      height: 'calc(100% - 5rem)'
    },
    reactVideoPlayer: {
      width: "80% !important",
      height: "auto !important",
      textAlign: 'center',
      position: 'relative',
      margin: '2.5rem auto',
      display: 'flex',
      overflow: 'hidden',
      borderRadius: '12px',
      // padding: '2rem 0',
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'center',
      '& video': {
        width: '100% !important',
        borderRadius: '8px',
        height: 'auto !important'
      }
    },
    videoPlayerOverlay: {
      visibility: 'hidden',
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: '0',
      left: '0',
      backgroundImage: 'linear-gradient(#ffffff00, #000000b0)',
    },
    challengeNameBox: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '2.5rem 1.5rem',
      marginTop: '7rem'
    },
    challengeName: {
      color: '#FFF',
      fontWeight: '700',
      fontSize: '1.5rem',
    },
    backDrop: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      left: '0',
      top: '0',
      backdropFilter: 'blur(14px)',
    },
    aiDataOverlayBox: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      gridAutoRows: '20% 80%',
      alignItems: 'flex-end',
      zIndex: 2
    },
    aiDataOverlay: {
      // position: 'absolute',
      // left: '0',
      // right: '0',
      // bottom: '0',
      color: '#fff',
      padding: '1rem 5%',
      display: 'grid',
      // margin: '0 5%',
      gap: '0.8rem',
      // backgroundImage: 'linear-gradient(#89898900, #000000)',
      fontFamily: "montserrat",
      fontSize: '1rem',
      borderRadius: '8px'
    },
    userNameText: {
      fontSize: '2rem',
      fontWeight: 900,
      height: '100%',
      padding: '1rem 2rem',
      textAlign: 'left',
      color: '#FFF',
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    mainText: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontFamily: "montserrat",
    },
    value: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontFamily: "montserrat",
    },
    progressBarBox: {
      position: 'relative',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      width: '80%',
      margin: '1rem auto 0 auto',
      gap: '1.8rem',
      '& img': {
        width: '20px'
      },
      '& .MuiLinearProgress-root': {
        height: '6px !important',
        width: '100%',
        '& .MuiLinearProgress-barColorPrimary': {
          backgroundColor: '#F4511E !important'
        }
      },
      '& .MuiSlider-colorPrimary': {
        color: '#F4511E'
      }
    },
    videoDurationBox: {
      color: '#fff',
      position: 'relative',
      width: '80%',
      margin: 'auto',
      textAlign: 'right',
      marginTop: '-1rem',
    }
  }));

  export default useStyles;