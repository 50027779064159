import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";


// utils

// contstants
import constants from "../../constants";

// styles
import useStyles from "./index.styles";
import ProductDetails from "../../components/product-details";

const Product = (props) => {
    const { productId } = useParams();
    const classes = useStyles();
    const isMobile = useSelector(state => state?.common?.isMobile);
    // const {isFileViewerOpen} = useSelector(LearningAidContext);

    return (
        <div className={classes.productDetailContainer}
            style={{
                height: isMobile ? `calc(100dvh - ${constants.BOTTOM_NAV_HEIGHT})` : `calc(100vh - ${constants.HEADER_HEIGHT})`,
            }}
        >
            <ProductDetails productId={productId} />
        </div>
    )
}

export default Product;