import React from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui

// components
import CompletionProgress from "../completion-progress";

// utils
import commonUtil from "../../utils/commonUtil";

// styles
import useStyles from "./index.styles";
import isEmpty from "../../utils/isEmpty";


const MicroskillListCard = (props) => {
    const {
        message,
        data,
        handleMicroskillCardClick,
        index,
        isBlur,
        completion
    } = props;
    const isMobile = useSelector(state => state?.common?.isMobile);
    const classes = useStyles();
    const { t } = useTranslation();
    const completionPerTemp = Math.round((data.completedChallenges * 100) / data.activeChallenges);

    // const starValue = commonUtil.getStarValue(data?.Challenge?.maxScore, data.scored, 3, data?.Challenge?.type === 'SL' ? 0 : null);
    // const getCardBg = (type) => {
    //     if (type === 'SL') return "/images/challenge-list/spotlight.svg"
    //     else if (type === 'LA') return "/images/challenge-list/card.svg"
    //     else if (type === 'GBA') return "/images/challenge-list/game.svg"
    //     else if (type === 'SCORM') return "/images/challenge-list/scorm.svg"
    // }

    const getCardBgColor = (i) => {
        if (i === 0 || i % 4 === 0) return "#5487EB";
        else if (i === 1 || i % 5 === 0) return "#43A047";
        else if (i === 2 || i % 6 === 0) return "#FFB300";
        else if (i === 3 || i % 7 === 0) return "#F63B34";
    }

    const getBannerColor = (type) => {
        if (type.toLowerCase().includes('locked')) {
            return {
                backgroundColorMain: "#ffdd00",
                backgroundColorFold: "#ffb300",
                icon: '/images/icons/locked-icon.svg'
            }
        } else if (type.toLowerCase().includes('available') || type.toLowerCase().includes('unlocked')) {
            return {
                backgroundColorMain: "#00cc2e",
                backgroundColorFold: "#3d9341",
                icon: type.toLowerCase().includes('available') ? null : '/images/icons/locked-icon.svg'
            }
        } else if (type.toLowerCase().includes('new launch')) {
            return {
                backgroundColorMain: "#1e88e5",
                backgroundColorFold: "#1772c1",
                icon: '/images/icons/startup.svg'
            }
        } else if (type.toLowerCase().includes('expire')) {
            return {
                backgroundColorMain: "#f4511f",
                backgroundColorFold: "#c73f16",
                icon: type.toLowerCase() === 'expired' ? '/images/icons/expire-clock.svg' : null
            }
        } else {
            return {
                backgroundColorMain: "#00cc2e",
                backgroundColorFold: "#3d9341",
            }
        }
    }

    const Banner = () => {
        return (
            <>
                {
                    !isEmpty(message) && (
                        completion && message.toUpperCase().includes('NEW LAUNCH') ? ( // if new launch and no completion mark then show banner
                            <></>
                        ) : (
                            <div className={classes.bannerBox}>
                                <div className={classes.bannerPart1} style={{ backgroundColor: getBannerColor(message)?.backgroundColorFold }} />
                                <div className={`${classes.centerFlex} ${classes.bannerPart2}`} style={{ backgroundColor: getBannerColor(message)?.backgroundColorMain }}>
                                    {
                                        !isEmpty(getBannerColor(message)?.icon) && (
                                            <img src={getBannerColor(message)?.icon} width={12} />
                                        )
                                    }
                                    {/* <span className={classes.launchTypeText}>{message}</span> */}
                                    <span className={classes.launchTypeText}>{t(message)}</span>
                                </div>
                            </div>
                        )
                    )
                }
            </>
        )
    }

    return (
        <div className={classes.microskillListMain}>
            <Banner />
            <div className={classes.microskillListCardRoot}
                style={{
                    backgroundImage: `url(${isEmpty(data?.Microskill?.coverImage) ? '/images/sample-image/master_o_brand_icon.png' : data?.Microskill?.coverImage})`,
                    backgroundColor: getCardBgColor(index),
                    filter: isBlur ? 'blur(4px)' : null
                }}
                onClick={handleMicroskillCardClick}
            >
                <div className={classes.overlayBox}>
                    <div className={classes.cardDetailsBox}>
                        <div className={classes.completion}>
                            <CompletionProgress
                                completionPercentage={`${isNaN(completionPerTemp) ? 0 : completionPerTemp}%`}
                                fontSize={isMobile ? '0.8rem' : '0.75rem'}
                            />
                        </div>
                        <div className={classes.cardName}>{data?.Microskill?.name}</div>
                        <div className={classes.scoreBox}>
                            <div className={classes.score}> Score: {`${data.scored}/${data.maxScore}`}</div>
                        </div>
                        <div className={classes.brandImage}>
                            {
                                !isEmpty(data?.Microskill?.brandImage) && (<img src={data?.Microskill?.brandImage} alt="brandImage" height={20} />)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default MicroskillListCard