import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// mui
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Button } from "@mui/material";

// components
import MicroskillCard from "../microskill-card";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// utils
import isEmpty from "../../utils/isEmpty";

// theme
import palette from "../../theme/palette";

// styles
import useStyles from "./index.styles";
import "react-multi-carousel/lib/styles.css";
import ProductCard from "../product-card";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1400 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 1400, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 2.25
    }
};

const cardsRenderCount = 4;

const ProductsCardContainer = (props) => {
    const { data, name, checked } = props;
    const navigate = useNavigate();
    const classes = useStyles();
    const [cardsPerRender, setCardsPerRender] = useState(cardsRenderCount);
    const isMobile = useSelector(state => state?.common?.isMobile);

    const { t } = useTranslation()

    const handleMicroskillCardClick = (d) => {
        navigate(`/product/${d.id}?active=summary`); // by default open summary section
        // handleRecentlyLearned(d);
    }

    const getCategoryName = (name) => {
        return name.replace("_", " ")
    }

    const CustomRightArrow = ({ onClick, ...rest }) => {
        return (
            <Button onClick={() => onClick()} variant="contained"
                className={`${classes.customCarouselArrow} ${classes.customCarouselRightArrow}`}>
                <img src="/images/icons/right_arrow_white.svg" />
            </Button>
        )
    };

    const CustomLeftArrow = ({ onClick, ...rest }) => {
        return (
            <Button onClick={() => onClick()} variant="contained"
                className={`${classes.customCarouselArrow} ${classes.customCarouselLeftArrow}`}>
                <img src="/images/icons/left_arrow_white.svg" />
            </Button>
        )
    };
    const ProductDisplayTemplate = ({ data, categoryName }) => {
        return (
            <>
                <div className={classes.productCardContainer}>
                <div className={classes.productCardCategoryContainer}>
                    <span className={classes.productCardCategoryText}>Products</span>
                </div>
                    <Carousel
                        additionalTransfrom={0}
                        arrows
                        customLeftArrow={<CustomLeftArrow />}
                        customRightArrow={<CustomRightArrow />}
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className={classes.reactCustomClass}
                        containerClass="container"
                        dotListClass=""
                        draggable={isMobile}
                        focusOnSelect={false}
                        infinite={false}
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable={isMobile}
                        responsive={responsive}
                    >
                        {
                            data.map(item => (
                                <ProductCard
                                    width={'100%'}
                                    key={`carousel-${item?.microskillId}`}
                                    cardData={item}
                                    categoryName={categoryName}
                                    handleMicroskillCardClick={() => handleMicroskillCardClick(item)}
                                />
                            ))
                        }
                    </Carousel>
                </div>
            </>
        )
    }

    return (
        <>
            {
                !isEmpty(data) && (
                    <ProductDisplayTemplate data={data}
                        categoryName={checked ? `${t(getCategoryName(name))} (${data.length})` : (data[0]?.Microskill?.Category?.name)}
                    />
                )
            }
        </>
    )
}

export default ProductsCardContainer;