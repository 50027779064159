import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    challengeListCardRoot: {
        width: '100%',
        height: '160px',
        borderRadius: '12px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 85%',
        backgroundPosition: 'center bottom',
        display: 'grid',
        alignContent: 'space-around',
        justifyItems: 'flex-start',
        padding: '1rem 2rem',
        // fontWeight: '600',
        // fontSize: '1.5rem',
        color: '#FFF',
        margin: '1.5rem 0',
        cursor: 'pointer',
        position: 'relative',
        [theme.breakpoints.down("sm")]: {
            padding: '0.8rem 1rem 0.7rem 1rem',
            backgroundPosition: 'center bottom',
            height: '100px',
            gap: '0.4rem',
            margin: '1rem 0',
            // backgroundSize: '100% 60%',
            borderRadius: '10px',
        },
    },
    cardName: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '1.5rem',
        fontWeight: '600',
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.9rem',
        }
    },
    score: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '1.5rem',
        fontWeight: '600',
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.9rem',
        }
    },
    starBox: {
        display: 'flex',
        '& img': {
            [theme.breakpoints.down("sm")]: {
                width: '20px',
            }
        }

    },
    customBannerContainer: {
        width: '12%',
        position: 'absolute',
        top: '5%',
        right: '-0.5rem',
        zIndex: '10',
        borderRadius: '0 6px 6px 0',
        [theme.breakpoints.down("md")]: {
            width: '18%',
            right: '-0.25rem',
            top: '5%',
        }
    },
}));

export default useStyles;