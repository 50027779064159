import React, { useState, useEffect, useRef } from 'react'

//mui
import { List, ListItemButton, ListItemText, Collapse, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


//multilingual
import { useTranslation } from "react-i18next";

//common-styled
import { EmptyBox, LoadingBox, styleTableBodyCell, styleTableHeadCell } from '../common-styled'
import isEmpty from '../../../../utils/isEmpty';


const LearningReports = ({ isDarkMode, loading, learningReportList = [], fullName = "", handleManagerClick }) => {
    const { t, i18n } = useTranslation()
    const [expanded, setExpanded] = useState(true);
    const tableWrapperRef = useRef(null);

    useEffect(() => {
        if (expanded && loading) scrollToStart()
    }, [loading])


    const scrollToStart = () => {
        if (tableWrapperRef.current) {
            tableWrapperRef.current.scrollLeft = 0;
        }
    };

    const handleExpandToggle = () => setExpanded(!expanded);

    const getValue = (item, row) => {
        if (isEmpty(row[item.key])) return 'N/A';
        return row[item.key];
    }

    const getFirstCellStyle = (item, row) => {

        const style = {
            cursor: row?.designation !== 'end' && 'pointer',
            textTransform: 'capitalize',
            color: row?.designation !== 'end' && '#f4511e'
        }

        if (item.key === 'fullName') return style
    }


    let TABLE_HEADINGS = [
        { key: "fullName", value: `${fullName}${fullName ? "'s" : ''} Team` },
        { key: "designation", value: "Designation" },
        { key: "averageCompletion", value: "Average Completion" },
        { key: "averageEffectiveness", value: "Average Score" },
        { key: "averageTimeSpent", value: "Average Time Spent" },
    ]

    if (!isEmpty(learningReportList) && learningReportList[0].hasOwnProperty("designation") && learningReportList[0]["designation"] === "end") TABLE_HEADINGS = TABLE_HEADINGS.filter(ele => ele.key !== "designation");

    const showSpotlightColumns = () => {
        let show = false;
        for (let i = 0; i < learningReportList.length; i++) {
            if (learningReportList[i]['averageSpotlightDuration'] || learningReportList[i]['averageKeywordMatch'] || learningReportList[i]['averageTranscriptMatch']) {
                show = true;
                break
            }
        }

        return show;
    }

    if (showSpotlightColumns()) {
        TABLE_HEADINGS = [
            ...TABLE_HEADINGS,
            { key: "averageSpotlightDuration", value: "Average Roleplay Duration" },
            { key: "averageKeywordMatch", value: "Average Keyword Match" },
            { key: "averageTranscriptMatch", value: "Average Transcript Match" },
        ]
    }

    return (
        <>
            <ListItemButton
                sx={theme => ({
                    backgroundColor: isDarkMode ? theme.palette.darkVoilet : theme.palette.white, color: theme.palette.fontColor, padding: '0.8rem 1rem', paddingBottom: expanded ? '0.4rem' : '0.8rem', marginBottom: expanded ? 0 : '1rem',
                    '&:hover': { backgroundColor: isDarkMode ? theme.palette.darkVoilet : theme.palette.white, color: theme.palette.fontColor }
                })}
                onClick={handleExpandToggle}>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography
                            variant="body2"
                            sx={(theme) => ({
                                color: theme.palette.fontColor,
                                textAlign: 'left',
                                font: 'normal normal 800 1.2rem Montserrat',
                                letterSpacing: '1px',
                                opacity: '1',
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: '0px',
                            })}>
                            {t('Learning Report')}
                            {!expanded && loading && <CircularProgress sx={{ height: '20px !important', width: '20px !important', marginLeft: '1rem' }} />}
                            {!expanded ? <KeyboardArrowDownIcon style={{ color: '#F4511E', position: 'absolute', right: '1rem' }} /> : <KeyboardArrowUpIcon style={{ color: '#F4511E', position: 'absolute', right: '1rem' }} />}
                        </Typography>
                    }
                />
            </ListItemButton>
            <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ marginBottom: expanded ? '1rem' : 0 }}>

                <TableContainer ref={tableWrapperRef} component={Paper} sx={(theme) => ({
                    backgroundColor: theme.palette.darkVoilet,
                    boxShadow: '0px 0px 0px 0px transparent',
                    overflowX: !learningReportList.length || loading ? 'hidden' : 'auto',
                    maxHeight: 400,

                    [theme.breakpoints.down('md')]: {
                        backgroundColor: theme.palette.mobilePerfPageCardsBg,
                    }
                })}>
                    <Table sx={{
                        minWidth: 650
                    }} aria-label="simple table">
                        <TableHead sx={{ position: 'sticky', top: 0 }}>
                            <TableRow sx={(theme) => ({
                                backgroundColor: theme.palette.darkVoilet,
                                [theme.breakpoints.down('md')]: {
                                    backgroundColor: theme.palette.mobilePerfPageCardsBg,
                                }
                            })}>
                                {
                                    TABLE_HEADINGS.map((item,i) => (
                                        <TableCell key={i} align="center" sx={styleTableHeadCell}>{t(item.value)}</TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        {
                            !loading && learningReportList.length ?
                                <TableBody>
                                    {learningReportList.map((row, idx) => (
                                        <TableRow
                                            key={idx}
                                            sx={theme => ({
                                                '&:last-child td, &:last-child': { border: 0 },
                                                backgroundColor: (false ? theme.palette.voilet : theme.palette.darkVoilet),
                                                fontWeight: (false ? 'bold' : 'normal'),
                                                borderTop: (false ? '2px dashed #F4511E' : '0px'),
                                                // borderBottom: (false ? '2px dashed #F4511E' : '0px'),
                                                height: '2rem',
                                                maxHeight: '3rem',

                                                [theme.breakpoints.down('md')]: {
                                                    backgroundColor: (false ? theme.palette.headerBg : theme.palette.mobilePerfPageCardsBg),
                                                }

                                            })}
                                        >
                                            {
                                                TABLE_HEADINGS.map((item,i) => (
                                                    <TableCell key={i} align="center" onClick={() => handleManagerClick(item, row)} style={getFirstCellStyle(item, row)} sx={styleTableBodyCell}>{getValue(item, row)} </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody> : null
                        }

                    </Table>
                    {loading ? <LoadingBox isDarkMode={isDarkMode} /> : null}
                    {!loading && !learningReportList.length ? <EmptyBox isDarkMode={isDarkMode} /> : null}
                </TableContainer>

            </Collapse>
        </>
    )
}

export default LearningReports