import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    mainContainer: {
        display: 'grid',
        // gridTemplateRows: '20% 80%',
        padding: '1rem 1rem 0.5rem',
        width: '100%',
        boxShadow: '0 0 5px grey',
        [theme.breakpoints.down('md')]: {
            rowGap: '0.5rem'
        }
    },

    iconContainer: {
        justifyContent: 'flex-end',
        width: '100%',
        height: '100%',
    },

    closeIcon: {
        height: '2rem',
        width: '2rem',
        float: 'right',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            height: '1.5rem',
            width: '1.5rem',
        }
    },

    alarmIcon: {
        color: '#F4511E',
        margin: 'auto'
    },

    timerContainer: {
        columnGap: '0.2rem'
    },

    timerDiv: {
        textAlign: 'left',
        width: '80%',
        minWidth: '3.5rem',
        color: "#F4511E"
    },

    formNameDescriptionContainer: {
        width: '100%',
        flexDirection: 'column',
        // height: '75%',
        justifyContent: 'space-evenly'
    },

    formName: {
        fontSize: '1.1rem',
        fontWeight: '1000',
        textAlign: 'left',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem'
        },
    },

    formDescription: {
        width: '100%',
        textAlign: 'left',
        fontSize: '0.9rem',
        fontWeight: '500',
        marginTop: '0.5rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem'
        },
    },
}));

export default useStyles;