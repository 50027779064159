import { makeStyles } from "@mui/styles";
import constants from "../../constants";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    ScromContainer: {
        alignItems: 'flex-start !important',
        [theme.breakpoints.down("sm")]: {
            backgroundColor: theme.palette.black,
            position: 'relative'
        }
        // display: 'flex',
        // [theme.breakpoints.down("sm")]: {
        //     padding: '0rem',
        //     width: '100vh',
        //     height: "100vw"
        // }
    },
    ScromContainerBack: {
        background: 'rgb(0,0,0,0.3)',
        color: '#FFF',
        height: '3rem',
        width: '3rem',
        padding: '0.5rem',
        borderRadius: '50%',
        cursor: 'pointer',
        marginBottom: '1rem',
        marginLeft: '2rem'
    },
    iframeMobileRoot: {
        width: '101% !important', /* Set width to viewport height for a perfect square in landscape orientation */
        height: '102%', /* Set height to viewport width for a perfect square in landscape orientation */
        color: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        left: '-1%'
    },
    iframeMobileRootRotate: {
        position: 'absolute',
        width: `calc(100dvh - ${constants.HEADER_HEIGHT_MOBILE} ) !important`, /* Set width to viewport height for a perfect square in landscape orientation */
        height: 'calc(100dvw + 2rem)', /* Set height to viewport width for a perfect square in landscape orientation */
        top: `50%`,
        left: '50%',
        transform: 'translate(-52%, -52%) rotate(90deg)', /* Center and rotate the div */
        color: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iframeRoot: {
        width: '100vh', /* Set width to viewport height for a perfect square in landscape orientation */
        height: '100%', /* Set height to viewport width for a perfect square in landscape orientation */
        color: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // marginTop: '2rem'
    },
    iframe: {
        height: '100%',
        margin: 'auto',
        marginTop: '0px',
        border: "0px"
    },
    LaWinScreen: {
        padding: '2rem 8rem',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        }
    }
}))

export default useStyles;