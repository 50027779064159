import React, { useRef, useState } from "react";

// mui
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Close } from '@mui/icons-material';
import { CircularProgress } from "@mui/material";

//styles
import useStyles from "./index.styles";

//theme
import theme from "../../theme";

// utils
import isEmpty from "../../utils/isEmpty";

// axios
import axiosInstance from "../../utils/axios";

// lotties
import Lottie from "react-lottie-player";
import FORM_SUBMISSION_LOTTIE_DATA from "../../lottie-assets/Form_Submission_lottie.json";

const SHARING_MESSAGE = "Hi, \n\nI wanted to share a really useful app, called Master-O, that can improve your skills on the go through playing games & interactive content. \nYou can access Master-O using this link: https://onelink.to/n36pht \n\nCheck it out!"

const SHARING_MESSAGE_HTML = "<p>Hi, <br/><br/>I wanted to share a really useful app, called Master-O, that can improve your skills on the go through playing games & interactive content. <br/>You can access Master-O using this link: https://onelink.to/n36pht <br/><br/>Check it out!</p>"
const ShareDialogBox = (props) => {
    const {
        isOpen = false,
        handleClose
    } = props;
    // console.log("alert props:", props)

    const [email, setEmail] = useState('');
    // const [textarea, setTextarea] = useState(SHARING_MESSAGE);
    const [textCopied, setTextCopied] = useState(false);
    const [isSharing, setIsSharing] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [mailSent, setMailSent] = useState(false);

    const emailInputRef = useRef();
    const textareaInputRef = useRef();
    const classes = useStyles();

    const handleMessageCopy = () => {
        if (textCopied) return;
        navigator.clipboard.writeText(SHARING_MESSAGE).then(() => {
            setTextCopied(true);
            setTimeout(() => {
                setTextCopied(false);
            }, 1000 * 0.75)
        }).catch((err) => {
            console.log("Error in copying message", err)
        })

    }

    const handleShare = (e) => {
        if (isSharing) return;
        if (isEmpty(email)) {
            if (!isEmpty(emailInputRef.current)) {
                emailInputRef.current.style.border = `1px solid ${theme.palette.ilaInCorrect1}`
            }
            return;
        }
        if (!validEmail(email)) {
            setIsEmailValid(false);
            return;
        };
        postShareMsgData(email, SHARING_MESSAGE_HTML);
    }
    const validEmail = (email) => {
        const emailRegEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegEx.test(email);
    }

    async function postShareMsgData(email, msg) {
        setIsSharing(true);
        const data = await axiosInstance.post('/share-mastero', {
            emailIds: [email],
            body: `<p>
                ${msg}
            </p>`
        })
        if (data.status === 200 || data.status === 201) {
            setEmail('');
            setIsSharing(false);
            setMailSent(true);
            setTimeout(() => {
                handleClose();
            }, 1000 * 3)
        } else {
            setIsSharing(false);
            setMailSent(false);
        }
    }
    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
        >
            <Box className={classes.containerBox}>
                <Box className={classes.boxHeader}>
                    <Typography variant="h6" className={classes.dialogTitle}>
                        Share Master-O
                    </Typography>
                    <Typography className={classes.closeBtn} type="text" onClick={handleClose} >
                        <Close />
                    </Typography>
                </Box>
                {
                    mailSent ? (
                        <Box className={`${classes.dialogBody} ${classes.centerFlex}`} sx={{ minHeight: '5rem' }}>
                            <div className={`${classes.centerFlex}`}>
                                <Lottie
                                    animationData={FORM_SUBMISSION_LOTTIE_DATA}
                                    loop={false}
                                    play={true}
                                    style={{ width: '25%' }}
                                />
                            </div>
                        </Box>
                    ) : (
                        <>
                            <Box className={classes.dialogBody}>
                                <Box className={classes.inputWrapper} ref={emailInputRef}>
                                    <PersonOutlineOutlinedIcon sx={theme => ({ color: theme.palette.orange })} />
                                    <input
                                        type="text"
                                        className={`${classes.inputField} ${classes.emailField}`}
                                        value={email}
                                        placeholder="Enter Email Address"
                                        onChange={(e) => {
                                            setIsEmailValid(true)
                                            if (!isEmpty(emailInputRef.current)) emailInputRef.current.style.border = `1px solid rgba(0, 0, 0, 0.1)`
                                            setEmail(e.target.value)
                                        }}
                                        onFocus={() => {
                                            if (!isEmpty(emailInputRef.current)) {
                                                emailInputRef.current.style.boxShadow = `0 2px 0px 0px ${theme.palette.orange}`
                                            }
                                        }}
                                        onBlur={() => {
                                            if (!isEmpty(emailInputRef.current)) {
                                                emailInputRef.current.style = ``;
                                            }
                                        }}
                                    />
                                </Box>
                                {
                                    !isEmailValid && (<Typography className={classes.emailError}>Please enter a valid Email Address</Typography>)
                                }
                                <Box className={classes.inputWrapper} ref={textareaInputRef} sx={theme => ({ alignItems: 'flex-start' })}>
                                    <ChatOutlinedIcon sx={theme => ({ color: theme.palette.orange })} />
                                    {/* <textarea
                            type="text"
                            className={`${classes.inputField} ${classes.textareaField}`}
                            rows={6}
                            value={textarea}

                            onChange={(e) => {return}}
                            // onChange={(e) => setTextarea(e.target.value)}
                            onFocus={() => {
                                if (!isEmpty(textareaInputRef.current)) {
                                    textareaInputRef.current.style.boxShadow = `0 2px 0px 0px ${theme.palette.orange}`
                                }
                            }}
                            onBlur={() => {
                                if (!isEmpty(textareaInputRef.current)) {
                                    textareaInputRef.current.style = ``;
                                }
                            }}
                        /> */}
                                    <div
                                        className={`${classes.inputField} ${classes.textareaField}`}
                                        dangerouslySetInnerHTML={{ __html: SHARING_MESSAGE_HTML }}
                                    >
                                    </div>
                                </Box>
                            </Box>
                            <Box className={classes.dialogFooter}>
                                <Box className={classes.btnWrapper} sx={theme => ({ alignItems: 'flex-start' })}>
                                    <Button className={classes.copyBtn} onClick={handleMessageCopy}>
                                        {
                                            textCopied ? (
                                                <>
                                                    Copied Successfully!
                                                </>
                                            ) : (
                                                <>
                                                    <ContentCopyOutlinedIcon /> Copy To Clipboard
                                                </>
                                            )
                                        }
                                    </Button>

                                    <Button
                                        className={classes.sendBtn}
                                        variant="contained"
                                        onClick={handleShare}
                                    >
                                        {
                                            isSharing ? (
                                                <CircularProgress size={24} sx={{ color: 'white' }} />
                                            ) : (
                                                <><SendOutlinedIcon /> Share</>
                                            )
                                        }
                                    </Button>

                                </Box>
                            </Box>
                        </>
                    )
                }
            </Box >
        </Modal >
    )
}

export default ShareDialogBox;