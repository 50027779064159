import { makeStyles } from "@mui/styles";

const cardPadding = '0.6rem';

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    microskillCardRoot: {
        padding: cardPadding,
        position: 'relative',
    },
    cardContainer: {
        position: 'relative',
        backgroundColor: 'transparent',
        // height: '200px',
        width: '100%',
        aspectRatio: '16/9',
        borderRadius: '5px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        boxShadow: '-2px 2px 8px 0 rgb(0 0 0 / 16%)',
        overflow: 'hidden',
        cursor: 'pointer',
        userSelect: 'none',
        minHeight: '180px',
        [theme.breakpoints.down('sm')]: {
            height: '180px',
            aspectRatio: 'auto',
        },
    },
    cardGradient: {
        height: '100%',
        width: '100%',
        background: 'linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0.05) 46%)'
    },
    cardContent: {
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '0',
        height: '100%',
        padding: '0.5rem',
        display: 'grid',
        alignContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            // height: '45%',
        },
    },
    completion: {
        fontFamily: 'Montserrat',
        color: '#fff',
        justifyContent: 'flex-start !important',
    },
    nameContainer: {
        fontFamily: 'Montserrat',
        color: '#fff',
        justifyContent: 'flex-start !important',
    },
    microSkillName: {
        fontFamily: 'Montserrat',
        fontSize: '0.8rem',
        fontWeight: 700,
        width: '85%',
        // whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '1.5rem',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        display: '-webkit-box',
    },
    // microSkillNameText: {
    //     overflow: 'hidden',
    //     textOverflow: 'ellipsis',
    //     whiteSpace: 'nowrap',
    //     '-webkit-line-clamp': 2,
    //     '-webkit-box-orient': 'vertical',
    //     display: '-webkit-box',
    // },
    imgContainer: {
        width: '15%',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            maxWidth: '28px'
        }
    },
    icons: {
        fontFamily: 'Montserrat',
        color: '#fff',
        justifyContent: 'left !important'
    },
    iconText: {
        fontSize: '0.6rem',
        fontWeight: 700
    },
    watchIcon: {
        gap: '0.2rem'
    },
    heartIcon: {
        paddingLeft: '0.6em',
        gap: '0.2rem'
    },
    bannerBox: {
        position: 'absolute',
        left: '0',
        top: '10%',
        // minWidth: '40%',
        height: '30px',
        zIndex: 2,
        // [theme.breakpoints.down('sm')]: {
        //     height: '30px',
        // },
    },
    bannerPart1: {
        height: '100%',
        width: cardPadding,
        background: 'green',
        zIndex: '1',
        position: 'absolute',
        transform: 'skewY(30deg)',
        top: '10%',
        // [theme.breakpoints.down('sm')]: {
        //     top: '10%',
        //     height: '85%',
        // },
    },
    bannerPart2: {
        height: '100%',
        textTransform: 'uppercase',
        fontSize: '0.8rem',
        padding: '0.3rem',
        fontWeight: 600,
        fontFamily: 'Open sans',
        boxShadow: '0px 2px 6px -3px #000',
        // background: 'pink',
        zIndex: '2',
        position: 'absolute',
        // width: '100%',
        borderRadius: '0 2px 2px 0',
        whiteSpace: 'nowrap'
    },
    launchTypeText: {
        paddingLeft: '0.3rem',
        color: theme.palette.white,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7rem',
        },
    },
    marqueeAnimation: {
        animation: `$marqueeAnimation 10s linear infinite`
    },
    '@keyframes marqueeAnimation': {
        '0%': { transform: 'translateX(100%)' },
        '100%': { transform: 'translateX(-100%)' }
    }
}))

export default useStyles;