import React, { useMemo } from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

// Styles
import useStyles from './index.styles';

// MUI Components
import { useTheme } from '@mui/styles';
import { Button, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Rating, Slider, styled } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

// Icons
// mui-icons
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import StarIcon from '@mui/icons-material/Star';
import isEmpty from '../../../utils/isEmpty';
import dayjs from 'dayjs';


const AnswersContainer = (props) => {
    const { questionId, questionType, answers, options, handleQuestionAttemptData, attemptLevelData, handleSetAttemptLevelData } = props;

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const classes = useStyles(i18n);

    const [sliderValue, setSliderValue] = React.useState(options.minLimit);

    const marks = [
        {
            value: options.minLimit,
            label: options.minLimit
        },
        {
            value: options.maxLimit,
            label: options.maxLimit
        }
    ];

    const getSelectedValue = () => {
        const currentQuestionData = attemptLevelData.find(ques => ques.questionId === questionId);
        return currentQuestionData ? JSON.stringify(currentQuestionData.answers[0]) : null;
    };

    const handleSingleChoice = (e, value) => {
        const answer = JSON.parse(value);

        const currentQuestionAttemptData = attemptLevelData.filter((ques) => ques.questionId === questionId)[0];

        const remainingAttemptedQuestions = attemptLevelData.filter((ques) => ques.questionId !== questionId);

        currentQuestionAttemptData.answers = [answer];

        const questionData = [{
            id: questionId,
            type: questionType,
            options: options,
            answers: currentQuestionAttemptData.answers,
        }];

        handleQuestionAttemptData({ questions: questionData });

        const completeAttemptData = [...remainingAttemptedQuestions, currentQuestionAttemptData];

        completeAttemptData.sort((a, b) => a.sequence - b.sequence);

        handleSetAttemptLevelData(completeAttemptData);

    };

    const handleMultiChoice = (e, answer) => {
        const currentQuestionAttemptData = attemptLevelData.filter((ques) => ques.questionId === questionId)[0];

        const remainingAttemptedQuestions = attemptLevelData.filter((ques) => ques.questionId !== questionId);

        const currentQuestionAnswers = currentQuestionAttemptData.answers;

        const isAnswerPresent = currentQuestionAnswers.find((ans) => ans.id === answer.id);

        if (isAnswerPresent) {
            currentQuestionAnswers.splice(currentQuestionAnswers.indexOf(isAnswerPresent), 1);
        } else {
            currentQuestionAnswers.push(answer);
        }

        currentQuestionAttemptData.answers = currentQuestionAnswers;

        const questionData = [{
            id: questionId,
            type: questionType,
            options: options,
            answers: currentQuestionAttemptData.answers,
        }];

        handleQuestionAttemptData({ questions: questionData });


        const completeAttemptData = [...remainingAttemptedQuestions, currentQuestionAttemptData];

        completeAttemptData.sort((a, b) => a.sequence - b.sequence);

        handleSetAttemptLevelData(completeAttemptData);
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const debouncedHandle = useMemo(() => debounce((questionData, completeAttemptData) => {
        handleQuestionAttemptData({ questions: questionData });
        handleSetAttemptLevelData(completeAttemptData);
    }, 500), []);


    const handleOpenEnded = (e, questionId) => {

        const currentQuestionAttemptData = attemptLevelData.find((ques) => ques.questionId === questionId);

        const remainingAttemptedQuestions = attemptLevelData.filter((ques) => ques.questionId !== questionId);


        let currentQuestionAnswers = currentQuestionAttemptData.answers;

        if (e.target.value === '') {
            currentQuestionAnswers = [];
        } else {
            currentQuestionAnswers[0] = { answer: e.target.value };
        }
        currentQuestionAttemptData.answers = currentQuestionAnswers;


        const questionData = [{
            id: questionId,
            type: questionType,
            options: options,
            answers: currentQuestionAttemptData.answers,
        }];



        const completeAttemptData = [...remainingAttemptedQuestions, currentQuestionAttemptData];

        completeAttemptData.sort((a, b) => a.sequence - b.sequence);

        debouncedHandle(questionData, completeAttemptData);
    };

    const handleSliderChange = (e, value) => {
        setSliderValue(value);
        const currentQuestionAttemptData = attemptLevelData.find((ques) => ques.questionId === questionId);

        const remainingAttemptedQuestions = attemptLevelData.filter((ques) => ques.questionId !== questionId);

        currentQuestionAttemptData.answers = [{ answer: value.toString() }];

        const questionData = [{
            id: questionId,
            type: questionType,
            options: options,
            answers: currentQuestionAttemptData.answers
        }];
        const completeAttemptData = [...remainingAttemptedQuestions, currentQuestionAttemptData];

        completeAttemptData.sort((a, b) => a.sequence - b.sequence);

        handleQuestionAttemptData({ questions: questionData });
        handleSetAttemptLevelData(completeAttemptData);
    }

    const handleDateChange = (e, value) => {

        const currentQuestionAttemptData = attemptLevelData.find((ques) => ques.questionId === questionId);

        const remainingAttemptedQuestions = attemptLevelData.filter((ques) => ques.questionId !== questionId);

        currentQuestionAttemptData.answers = [{ answer: moment(e.$d).format('DD-MM-YYYY') }];

        const questionData = [{
            id: questionId,
            type: questionType,
            options: options,
            answers: currentQuestionAttemptData.answers
        }];

        handleQuestionAttemptData({ questions: questionData });

        const completeAttemptData = [...remainingAttemptedQuestions, currentQuestionAttemptData];

        completeAttemptData.sort((a, b) => a.sequence - b.sequence);

        handleSetAttemptLevelData(completeAttemptData);
    }

    const handleStarChange = (e, value) => {
        const currentQuestionAttemptData = attemptLevelData.find((ques) => ques.questionId === questionId);

        const remainingAttemptedQuestions = attemptLevelData.filter((ques) => ques.questionId !== questionId);

        if (!isEmpty(value)) {
            currentQuestionAttemptData.answers = [{ answer: value.toString() }];
        } else {
            currentQuestionAttemptData.answers = [];
        }

        const questionData = [{
            id: questionId,
            type: questionType,
            options: options,
            answers: currentQuestionAttemptData.answers
        }];

        handleQuestionAttemptData({ questions: questionData });

        const completeAttemptData = [...remainingAttemptedQuestions, currentQuestionAttemptData];

        completeAttemptData.sort((a, b) => a.sequence - b.sequence);

        handleSetAttemptLevelData(completeAttemptData);
    }

    const handleFileUpload = (e) => { }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    return (
        <div className={`${classes.mainAnswerContainer}`}>
            {
                (questionType === 'SINGLE_CHOICE_QUESTION' || questionType === 'MULTI_CHOICE_QUESTION') && (
                    <FormControl sx={{ width: '100%' }}>
                        {questionType === 'MULTI_CHOICE_QUESTION' && answers.map((ans) => {
                            return <FormControlLabel
                                key={ans.id}
                                label={ans.answer}
                                control={<Checkbox
                                    style={{ padding: '0.3rem 0.5rem 0.3rem 0rem' }}
                                />}
                                onChange={(e) => handleMultiChoice(e, ans)}
                                sx={{
                                    alignItems: 'flex-start',
                                    marginLeft: '0rem ',
                                    marginBottom: '0.5rem',
                                    marginRight: '0rem',
                                    width: '100%',
                                    '.MuiFormControlLabel-label': {
                                        width: '100%',
                                        padding: '0.3rem 0.5rem'
                                    }
                                }}
                            />
                        })}

                        {questionType === 'SINGLE_CHOICE_QUESTION' &&
                            <RadioGroup
                                value={getSelectedValue()}
                                onChange={(e, value) => handleSingleChoice(e, value)}
                            >
                                {
                                    answers.map((ans) => {
                                        const ansId = ans.id;
                                        return <FormControlLabel
                                            key={ansId}
                                            value={JSON.stringify(ans)}
                                            label={ans.answer}
                                            control={<Radio
                                                style={{ padding: '0.3rem 0.5rem 0.3rem 0rem' }}
                                            />}
                                            sx={{
                                                alignItems: 'flex-start',
                                                marginLeft: '0rem',
                                                marginBottom: '0.5rem',
                                                marginRight: '0rem',
                                                width: '100%',
                                                '.MuiFormControlLabel-label': {
                                                    width: '100%',
                                                    padding: '0.3rem 0.5rem'
                                                }
                                            }}
                                        />
                                    })
                                }
                            </RadioGroup>
                        }
                    </FormControl>
                )
            }

            {
                questionType === 'OPEN_ENDED' &&
                <div className={`${classes.centerFlex} ${classes.centerFlexLaptop}`}>
                    <textarea rows={1} className={classes.openEnded} placeholder={t('Enter your Answer')} onChange={(e) => handleOpenEnded(e, questionId)} />
                </div>
            }


            {
                questionType === 'NUMBER_RATING' &&
                <div className={`${classes.centerFlex} ${classes.centerFlexLaptop} ${classes.sliderComponent}`}>
                    <Slider size='medium' className={classes.numberRating} defaultValue={options.minLimit}
                        step={1}
                        min={options.minLimit}
                        max={options.maxLimit}
                        valueLabelDisplay='auto'
                        marks={marks}
                        onChangeCommitted={(e, value) => handleSliderChange(e, value)}
                        sx={{
                            ".MuiSlider-markLabel": {
                                width: '40%',
                                textAlign: 'center',
                                whiteSpace: 'wrap',
                                textOverflow: 'wrap',
                            },
                            [theme.breakpoints.up('md')]: {
                                ".MuiSlider-markLabel": {
                                    width: '40%',
                                }
                            }
                        }}
                    />
                </div>
            }

            {
                questionType === 'DATE' &&
                <div className={`${classes.centerFlex} ${classes.centerFlexLaptop}`}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}

                    >
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker className={classes.datePicker}
                                sx={{
                                    marginLeft: '2rem',
                                    [theme.breakpoints.down('md')]: {
                                        marginLeft: '2rem',
                                        '.MuiInputBase-root > input': {
                                            padding: '0.5rem',
                                            width: '100%'
                                        }
                                    }
                                }}
                                format='DD/MM/YYYY'
                                value={getSelectedValue() ? dayjs(getSelectedValue(), 'DD/MM/YYYY') : null}
                                onChange={(e, value) => handleDateChange(e, value)}
                                views={['year', 'month', 'day']}
                            />
                        </DemoContainer>
                    </LocalizationProvider></div>
            }


            {questionType === 'STAR_RATING' &&
                <Rating
                    className={classes.starRating}
                    size='large'
                    value={getSelectedValue()}
                    onChange={(e, value) => handleStarChange(e, value)}
                    emptyIcon={
                        <StarIcon fontSize="inherit" sx={{
                            borderRadius: '5px',
                            marginRight: '0.3rem',
                        }} />
                    }
                />
            }

            {
                questionType === 'CUSTOM' &&
                <Button className={classes.uploadFileButton} component="label" variant="contained" disableElevation startIcon={<CloudUploadIcon />} onChange={(e) => handleFileUpload(e)}>
                    {t("Upload file")}
                    <VisuallyHiddenInput type="file" />
                </Button>
            }

        </div>
    )

};

export default AnswersContainer;