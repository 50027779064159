import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// mui

// components

// redux

// utils
import isEmpty from "../../../utils/isEmpty";

// theme
import palette from "../../../theme/palette";

// constants
import constants from "../../../constants";

// styles
import useStyles from "./index.styles";

const CoverCard = (props) => {
    const { data, length, viewFullScreen } = props;
    const isMobile = useSelector(state => state?.common?.isMobile);
    const styleProps = { viewFullScreen, isMobile }
    const classes = useStyles(styleProps);

    return (
        <div className={classes.coverCardRoot}>
            <div className={classes.coverCard}
                // style={{ backgroundImage: `url(${data['IMAGE'].content})` }}
            >
                <div className={classes.bgImageCoverCard} >
                    <img src={data['IMAGE'].content} alt="cards" className={classes.bgImageCoverCardImg} />
                </div>
                <div className={classes.backdropCoverCard} />
                <div className={classes.coverCardDetailBox}>
                    <div className={`${classes.coverCardLogo} ${classes.centerFlex}`}>
                        {/* <img src={data['LOGO'].content} alt="cards" width={isMobile ? 150 : 250} /> */}
                    </div>
                    <div className={`${classes.coverCardTitle} ${classes.centerFlex}`}>
                        <div dangerouslySetInnerHTML={{ __html: data.TITLE.content }}></div>
                    </div>
                    <div className={`${classes.coverCardDetails} ${classes.centerFlex}`}>
                        <div className={`${classes.iconBox} ${classes.centerFlex}`}>
                            <img src="/images/icons/card_white_2.png" alt="cards" width={isMobile ? 30 : 40} />
                            <span className={classes.detailText}>{`${length} Cards`}</span>
                        </div>
                        <div className={`${classes.iconBox} ${classes.centerFlex}`}>
                            <img src="/images/icons/clock.png" alt="cards" width={isMobile ? 30 : 40} />

                            {/* Dynamic Timing Calculated based on Number of cards Available earlier static value is using */}
                            <span className={classes.detailText}>{`${Math.round((30 * length) / 60)} Minutes`}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CoverCard;