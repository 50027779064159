import { makeStyles } from "@mui/styles";

const logoHeight = '180';

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    splashScreenRoot: {
        // backgroundColor: '#015fab',
        backgroundImage: ({ isBajajICV }) => `url(/images/common/master_o_login_illustration${isBajajICV ? '_light' : ''}_bg.svg), ${isBajajICV ? 'linear-gradient(#015fab,  #002f55)' : 'linear-gradient(#FFF, #C1C1C1)'}`,
        // backgroundImage: 'url(/images/login-screen/login_illustration_bg.svg), linear-gradient(#46b641, #161616)',
        position: 'fixed',
        inset: '0 0 0 0',
        zIndex: '9999',
        backgroundSize: 'contain',
        display: 'grid',
        gridTemplateRows: `calc(50% + ${logoHeight / 2}px) calc(50% - ${logoHeight / 2}px)`,
    },
    companyLogoBox: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    companyLogo: {
        width: '80%',
        height: `${logoHeight}px`,
        objectFit: 'contain'
    },
    masteroLogoWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    masteroLogo: {
        width: '20%',
        display: 'block',
        '&:last-child': {
            width: '30%',
            marginTop: '-0.5rem',
        }
    },
    detailBox: {
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        alignContent: 'center',
        gap: '1rem',
        color: ({ isBajajICV, isIndigo }) => ` ${isBajajICV || isIndigo ? theme.palette.white : theme.palette.black}`
    },
    text1: {
        color: ({ isBajajICV, isIndigo }) => ` ${isBajajICV || isIndigo ? theme.palette.white : theme.palette.black}`,
        // color: ({}),
        fontWeight: 600,
    },
    text2: {
        color: ({ isBajajICV, isIndigo }) => `${isBajajICV || isIndigo ? theme.palette.white : theme.palette.black}`,
        fontWeight: 500,
    }
}))

export default useStyles;