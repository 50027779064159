import React from "react";

// mui
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from "@mui/styles";
import ErrorIcon from '@mui/icons-material/Error';

// utils
import isEmpty from "../../utils/isEmpty";

// theme
import palette from "../../theme/palette";
import Lottie from "react-lottie-player";

// lottie assets
import ERROR_LOTTIE_DATA from "../../lottie-assets/common/error.json";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    width: '90%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    border: 'none',
    padding: '0 1rem 1rem 1rem',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
}

const DialogAlert = (props) => {
    const {
        isOpen = false,
        title,
        content,
        handleConfirmBtnClick,
        confirmBtnName,
        discardBtnName,
        handleDiscardBtnClick = () => { },
        lottieFile = ERROR_LOTTIE_DATA

        // for error dialog customisation for spotlight 
    } = props;
    const classes = useStyles();
    const handleClose = () => { }
    const handleDiscard = () => {
        handleDiscardBtnClick();
    }
    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
        >
            <Box sx={style}>
                {
                    <Box sx={{
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Lottie
                            animationData={lottieFile}
                            loop
                            play
                            style={{ width: '60%', height: '60%' }}
                        />
                    </Box>

                }
                <div className={classes.typographyContainer}>
                    <Typography variant="body1" component="div" className={classes.dialogTitle} sx={{ mb: 1 }}> {title} </Typography>
                    <Typography
                        variant="body2"
                        component="div"
                        sx={{
                            fontSize: '1rem',
                            lineHeight: '1.3rem',
                            textAlign: 'center',
                            fontWeight: 600,
                            color: palette.greyText4
                        }}
                    > {content} </Typography>
                </div>
                {
                    !isEmpty(confirmBtnName) && (
                        <Button
                            onClick={handleConfirmBtnClick}
                            autoFocus
                            fullWidth
                            className={classes.confirmBtn}
                        >
                            <Typography variant="body2" component="div" sx={theme => ({
                                color: palette.white,
                                textAlign: 'center',
                                fontWeight: 600,
                                fontSize: '1.2rem',
                                padding: '0.5rem'
                            })}>{confirmBtnName}</Typography>
                        </Button>
                    )
                }
                {
                    !isEmpty(discardBtnName) && (
                        <Button onClick={handleDiscard} variant={'text'} fullWidth>
                            <Typography variant="body2" component="div" sx={{
                                color: palette.orange,
                                fontSize: '1rem',
                                fontWeight: 600,

                            }}> {discardBtnName} </Typography>
                        </Button>
                    )
                }
            </Box>
        </Modal>
    )
}

export default DialogAlert;

const useStyles = makeStyles((theme) => ({
    typographyContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        marginTop: '-1.5rem !important',
    },
    dialogTitle: {
        fontSize: '1.5rem !important',
        fontWeight: '600 !important',
        textAlign: 'center !important',
        padding: '0rem !important',
        margin: '0 !important',
    },
    alertBtn: {
        textAlign: 'right',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '1rem',
    },
    confirmBtn: {
        color: `${palette.white} !important`,
        backgroundColor: `${palette.orange} !important`,
        textAlign: 'center !important',
        fontWeight: '600 !important',
        borderRadius: '3px !important',
        letterSpacing: '1px !important',
    },
}));