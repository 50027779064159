import React, { useState } from "react";
import { useDispatch } from "react-redux";

// mui
import { Button } from "@mui/material";

// redux
import { handleSpotlightScreenState } from "../../../redux/spotlight/spotlight-slice";

// utils 

// constants
import constants from "../../../constants";

// styles
import useStyles from "./index.styles";
import { useTranslation } from "react-i18next";

const IMAGE_LIST = [
    {
        id: '1',
        // image: '/images/self-coaching/wifi.png',
        imageUrl: '/images/self-coaching/wifi_orange.png',
        desc: 'Ensure you are using WiFi or 4G while recording'
    },
    {
        id: '2',
        // image: '/images/self-coaching/no-sound.png',
        imageUrl: '/images/self-coaching/no-sound_orange.png',
        desc: 'Record in a noise free zone'
    },
    {
        id: '3',
        // image: '/images/self-coaching/speaking.png',
        imageUrl: '/images/self-coaching/speaking_orange.png',
        desc: 'Speak clearly and in a balanced speed and volume'
    },
    {
        id: '4',
        // image: '/images/self-coaching/video-call.png',
        imageUrl: '/images/self-coaching/video-call_orange.png',
        desc: 'Remember to watch the best practice video before recording'
    }
];

const SpotlightInstructions = (props) => {
    const { remainingAttempts } = props;
    // console.log("spotlightData:", spotlightData)
    const classes = useStyles();
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(0);

    const { t } = useTranslation()
    const handleArrowClick = (type) => {
        if (type === 'next') {
            setActiveIndex(prev => (prev + 1))
        } else {
            setActiveIndex(prev => (prev - 1))
        }
    }


    return (
        <div className={classes.instructionsRoot}>
            {
                activeIndex > 0 && (
                    <img src={`/images/icons/left.png`}
                        className={`${classes.arrowLeft} ${classes.arrow}`}
                        alt="arrow left"
                        onClick={() => handleArrowClick('prev')}
                    />
                )
            }

            <div className={classes.imageContainer}>
                {
                    IMAGE_LIST.map((item, index) => (
                        <div className={`${classes.imageBox} ${activeIndex === index ? (classes.fadeIn) : ('')}`}
                            key={item.id}
                            style={{ display: activeIndex === index ? ('grid') : ('none') }}
                        >
                            {/* <img src={item.image} className={classes.image} alt="desc image" /> */}
                            <img src={item.imageUrl} className={classes.image} alt={`desc-${index+1}`} />
                            <div>
                                <p className={classes.imageDesc}>{t(item.desc)}</p>
                                {
                                    index === IMAGE_LIST.length - 1 && (
                                        <div className={classes.btnContainer}>
                                            <Button
                                                fullWidth
                                                disabled={remainingAttempts === 0}
                                                sx={theme => ({
                                                    borderRadius: '4px',
                                                    fontSize: '1.4em',
                                                    fontWeight: 600,
                                                    padding: 1,
                                                    "&.Mui-disabled": {
                                                        backgroundColor: `${theme.palette.fontColor}8A`
                                                    }
                                                })}
                                                onClick={() => dispatch(handleSpotlightScreenState(constants.SPOTLIGHT_SCREEN_STATE.RECORD))}
                                                className={`fixed_ratio_20_3 ${classes.animatedBtn}`}
                                                variant="contained"
                                            >
                                                {t('Start Recording')}!
                                            </Button>
                                        </div>
                                    )
                                }
                            </div>

                        </div>
                    ))
                }
            </div>

            {
                activeIndex < IMAGE_LIST.length - 1 && (
                    <img src={`/images/icons/right.png`}
                        className={`${classes.arrowRight} ${classes.arrow}`}
                        alt="arrow right"
                        onClick={() => handleArrowClick('next')}
                    />
                )
            }
        </div>
    )
}

export default SpotlightInstructions;