import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'

// constants
import constants from '../../../constants';

// utils
import { isNativeApp } from '../../../utils/commonUtil';

//theme
import palette from '../../../theme/palette';

// styles
import useStyles from './index.styles';
import { useTranslation } from 'react-i18next';

const BottomNavigation = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [activePage, setActivePage] = useState('');
    const isDarkMode = useSelector(state => state?.commonPersist?.isDarkMode);

    const { t } = useTranslation()

    useEffect(() => {
        setActivePage(location.pathname)
        // console.log(location.path);
    }, [location.pathname]);


    const handleNavClick = (d) => {
        navigate({
            pathname: d.url,
            search: d.url.includes('content-repository') ? '?name=Library' : ''
        });

        // navigate({
        //     pathname: d.url,
        //     search: createSearchParams({
        //         name: d.url.includes('documents') ? 'Master-O Documents' : ''
        //     }).toString()
        // });

        // router.history.push({
        //     pathname: d.url,
        //     search: d.url.includes('documents') ? '?name=Master-O Documents' : ''
        // });
    }
    return (
        <div className={`${classes.bottomNavContainer}`}>
            <div className={`${classes.bottomNavBox} ${classes.centerFlex}`} style={{ paddingBottom: isNativeApp() ? '1rem' : null }}>
                {
                    constants.DRAWER_MENU.map((item, index) => (
                        item.isVisibleOnMobile && (
                            <div className={`${classes.centerGrid}`}
                                key={`bottom-nav-${item.id}`}
                                onClick={() => handleNavClick(item)}
                            >
                                <img
                                    src={item.icon_img_url}
                                    alt={item.value}
                                    className={classes.icon}
                                    width={35}
                                    style={{
                                        filter: `${activePage === item.url
                                            ? ''
                                            : activePage.includes('challenge-list') && item.url === '/learn'
                                                ? ''
                                                : activePage.includes('product') && item.url === '/content-repository'
                                                    ? '' : isDarkMode ? 'brightness(0) invert(1)' : 'brightness(0) invert(0.5)'
                                            }`
                                    }}
                                />
                                <span
                                    style={{
                                        wordBreak: 'break-all',
                                        textAlign: 'center',
                                        color: activePage === item.url
                                            ? palette.orange
                                            : activePage.includes('challenge-list') && item.url === '/learn'
                                                ? palette.orange
                                                : activePage.includes('product') && item.url === '/content-repository'
                                                    ? palette.orange : null,
                                    }}
                                >{t(item.name)}</span>
                            </div>
                        )
                    ))
                }
            </div>
        </div>
    )
}

export default BottomNavigation