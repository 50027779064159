import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import lottie from "lottie-web";

// import { toggleSideBar } from "../../../../actions/challengeListAction";
// import GbaHeader from "../../GbaHeader";

import asteroidBurst from '../../../../lottie-assets/asteroid_burst.json';

// Style
import useStyle from "./index.styles";
import isEmpty from "../../../../utils/isEmpty";
import { useNavigate } from "react-router";

const HowToPlay = ({ width, height, title, headerBg, qstnBoxBg, asset1Img, asset2Img, setIsGamePage, gameType }) => {
    const isMobile = useSelector(state => state?.common?.isMobile);
    const classes = useStyle(isMobile);
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [reload, setReload] = useState(true);
    const questionRef = useRef([]);
    const headerQstnBox = useRef(null);
    const arrowImgRef = useRef(null);
    const displayQstnRef = useRef(null);
    const dataContainerRef = useRef(null);
    const progressBarRef = useRef(null);
    const overlayRef = useRef(null);
    const qstnBoxRef = useRef(null);
    const lifeTimeRef = useRef(null);
    const progressTextRef = useRef(null);
    const assestContainerRef = useRef(null);
    const speedBarContainerRef = useRef(null);
    const asset1Ref = useRef(null);
    let instructionNo = 2;

    const challengeList = () => {
        // dispatch(toggleSideBar(true));
        // router.history.goBack();
        Navigate(-1);
    };

    const nextInstruction = (instNo, e) => {
        let targetId = e?.target?.id;

        if (instructionNo === 2) {
            headerQstnBox.current.style.zIndex = 'auto';
            displayQstnRef.current.style.animation = 'auto';
            document.getElementById('hand-asset').style.opacity = '1';
            if (!isEmpty(asset1Ref.current)) {
                asset1Ref.current.style.zIndex = '4';
                asset1Ref.current.style.display = 'flex';
            }
            setTimeout(() => {
                if (!isEmpty(asset1Ref.current)) asset1Ref.current.style.opacity = '1';
            }, 800);

            overlayRef.current.removeEventListener('onClick', nextInstruction);

        } else if (instructionNo === 3 && targetId !== '' && targetId !== 'overlay') {

            if (!isEmpty(assestContainerRef.current)) assestContainerRef.current.style.zIndex = '0';
            setTimeout(() => {
                if (!isEmpty(assestContainerRef.current)) assestContainerRef.current.style.display = 'none';
            }, 800);

            // progressBarRef.current.style.backgroundColor = '#ffd042'
            if (!isEmpty(speedBarContainerRef.current)) speedBarContainerRef.current.style.display = 'grid';
            setTimeout(() => {
                if (!isEmpty(speedBarContainerRef.current)) speedBarContainerRef.current.style.opacity = '1';
            }, 800)
        } else if (instNo === 4 && instructionNo === 4) {
            dataContainerRef.current.style.opacity = '0';
            setTimeout(() => {
                dataContainerRef.current.style.display = 'none';
                displayQstnRef.current.style.opacity = '0'
                qstnBoxRef.current.style.zIndex = "2";
                lifeTimeRef.current.style.visibility = 'visible';
            }, 800);
        } else if (instNo === 2 && instructionNo === 4 && targetId === 'overlay') {
            instructionNo = 5;
            progressTextRef.current.style.display = 'grid';
            qstnBoxRef.current.style.zIndex = "1";
            lifeTimeRef.current.style.animation = 'auto';
            displayQstnRef.current.style.opacity = "1";
        } else if (instNo === 2 && instructionNo === 5 && targetId === 'overlay') {
            setIsGamePage(true);
        }
    }

    const handleAssetClick = () => {
        let lottieBox = document.getElementById('gamelottie');
        let asset = document.getElementById('asset');
        let assetText = document.getElementById('asset-text');
        let handAsset = document.getElementById('hand-asset');

        asset.style.opacity = '0';
        assetText.style.opacity = '0';
        handAsset.style.opacity = '0';
        const lottieAnimation = lottie.loadAnimation({
            container: lottieBox,
            renderer: "svg",
            loop: false,
            autoplay: false,
            animationData: asteroidBurst,
            speed: '2'
        });
        lottieAnimation.play();
        instructionNo++;
        setTimeout(() => {
            nextInstruction(3);
        }, 1500);
    }

    const handleSpeedBarClick = () => {
        speedBarContainerRef.current.style.opacity = '0';
        dataContainerRef.current.style.display = 'grid';
        setTimeout(() => {
            speedBarContainerRef.current.style.display = 'none';
            dataContainerRef.current.style.opacity = '1';
        }, 800);
    }

    const handleRestartClick = () => {
        setReload(false);
        setTimeout(() => { setReload(true) }, 1000)
    }

    const handleQuestionBoxClick = () => {
        // console.log("handleQuestionBoxClick:",handleQuestionBoxClick);
        nextInstruction(2, null)
    }

    return (
        reload ? (
            <div className={classes.howToPlayroot}>
                <div className={classes.container}>
                    <div className={classes.overlay} ref={overlayRef} id={`overlay`}
                        onClick={(e) => nextInstruction(2, e)} >
                        <div className={classes.progressText} ref={progressTextRef} onClick={(e) => nextInstruction(2, e)}>
                            <div className={classes.arrow2Box}>
                                <img src="/images/gba/arrow2.png" className={classes.arrowImg2} alt="arrow" width={50} />
                            </div>
                            <div className={classes.completionText}>Track completion with the progress bar</div>
                        </div>

                        <div className={classes.dataContainer} ref={dataContainerRef}>
                            <div className={`${classes.centerFlex} ${classes.readyText}`}>READY TO PLAY?</div>
                            <div className={`${classes.centerFlex} ${classes.cursorPointer}`}
                                onClick={() => { instructionNo = 4; nextInstruction(4) }}>
                                <button className={classes.startBtn} id={`startGameBtn`}>START GAME</button>
                            </div>
                            <div className={`${classes.centerFlex} ${classes.restartText}`} onClick={handleRestartClick}>
                                RESTART INSTRUCTIONS
                            </div>
                        </div>
                    </div>
                    <div className={classes.headerQstnBox} ref={headerQstnBox}>
                        {/* <GbaHeader title={title} height={'40%'}
                            handleBackButtonClick={challengeList}
                            headerBg={headerBg} /> */}
                        <div className={classes.progressBarContainer} ref={progressBarRef}>
                            <div className={classes.progressBar} />
                        </div>
                        {/* QUESTION BOX */}
                        <div className={classes.qstnContainer} ref={qstnBoxRef}>
                            <div className={classes.qstnBox} style={{ backgroundColor: `${qstnBoxBg}` }}>
                                <div className={classes.lifeTime} ref={lifeTimeRef}>
                                    <div className={classes.centerFlex}>
                                        <div className={classes.assetsText}> 4 x </div>
                                        <img src='/images/icons/heart.png' className={classes.icon} alt="heart" width="10" />
                                    </div>
                                    <div className={classes.centerFlex}>
                                        {/* <img src='/popcornMatch/stopwatch.png' className={classes.icon} alt="heart" width="10" />
                                        <div className={classes.assetsText}>180 s </div> */}
                                    </div>
                                </div>
                                <div className={`${classes.qstn} ${classes.centerFlex}`}
                                    ref={displayQstnRef} onClick={handleQuestionBoxClick}>
                                    Questions will appear within this box
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={classes.assestContainer} ref={assestContainerRef}>
                        <div className={classes.assets1} ref={asset1Ref}>
                            <div className={classes.assets1Box} style={{ width: gameType === 'balloonPop' ? '32%' : '45%' }}
                                onClick={handleAssetClick}>
                                <img src={asset1Img} alt="balloon" className={classes.assetsBoxImg} id="asset" />
                                <div className={classes.assetText}
                                    style={{ top: gameType === 'balloonPop' ? '35%' : '50%' }}
                                    id="asset-text">
                                    Tap only on the correct answers
                                </div>
                                <img src="/images/gba/hand.png" alt="hand" className={classes.handImg} id="hand-asset" />
                                <div id={`gamelottie`} className={classes.correctLottie} />
                            </div>

                        </div>
                        <div className={classes.assets2}>
                            <div className={classes.assets2Box} style={{ width: gameType === 'balloonPop' ? '32%' : '45%' }}>
                                <img src={asset2Img} alt="balloon" className={classes.assetsBoxImg} />
                                <div className={classes.assetText} style={{ top: gameType === 'balloonPop' ? '35%' : '50%' }}>Tap only on the correct answers</div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.speedBarContainer} ref={speedBarContainerRef}>
                        <div className={classes.speedText}>Increase or decrease the
                            {gameType === 'balloonPop' ? ' balloon’s ' : ' rock’s '} speed
                        </div>
                        <div className={classes.speedArrow}><img src="/images/gba/down_arrow.png" alt="arrow" /></div>
                        <div className={classes.speedBarBox}><img src="/images/gba/speed_bar.png" alt="arrow" onClick={handleSpeedBarClick} /></div>
                    </div>
                </div>
            </div>
        ) : (null)

    )
};

export default HowToPlay
