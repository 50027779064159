import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";
import constants from "../../../constants";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    comingSoonBox: {
        height: '100%',
        fontSize: '3rem',
        fontWeight: 700,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    overlay: {
        position: 'absolute',
        inset: 0,
        zIndex: '-1',
        opacity: '0.5',
        borderRadius: '0.5rem',
        // backdropFilter: 'blur(10px)'
    },
    text: {
        position: 'relative',
        zIndex: '2',
        textAlign: 'center',
        padding: '1rem',
        [theme.breakpoints.down("md")]: {
            fontSize: '2rem',
        }
    },
    skipButtonBox: {
        position: 'absolute',
        bottom: '2rem',
        right: 0,
        zIndex: 10,
    },
    skipBtn: {
        border: '2px solid #fff !important',
        borderRight: 'none !important',
        color: `${palette.ilaBg} !important`,
        borderRadius: '2rem 0 0 2rem !important',
        fontWeight: '700 !important',
        fontSize: '1.2rem !important',
        fontFamily: 'Open sans !important',
        paddingLeft: '1.6rem !important',
        paddingRight: '1.6rem !important',
        backgroundColor: `#fff !important`
    }
}))

export default useStyles