import React, { useEffect, useState } from "react";
import * as RDD from "react-device-detect";

// mui
import { CircularProgress } from "@mui/material";

// constants
import constants from "../../constants";

// utils
import isEmpty from "../../utils/isEmpty";

// styles
import useStyles from "./index.styles";

// components
import FileViewer from "../../components/file-viewer";

// events or redux
import { submitUserActivityData } from "../../redux/event-tracking/event.api";
import { useSelector } from "react-redux";

const GenericDocViewer = (props) => {
    const classes = useStyles();

    const urlParams = new URLSearchParams(window.location.search);
    const docUrl = urlParams.get("docUrl");
    const sharedTo = urlParams.get("sharedTo");
    const [docData, setDocData] = useState({});
    const isMobile = useSelector(state => state?.common?.isMobile);

    useEffect(() => {

        // console.log("extractNameFromPath", extractNameFromPath(docUrl));
        // console.log("extractTypeFromPath", extractTypeFromPath(docUrl));
        // console.log("extractMimeTypeFromType", extractMimeTypeFromType(extractTypeFromPath(docUrl)));
        if (!isEmpty(docUrl)) {
            setDocData({
                path: docUrl,
                type: "FILE",
                name: extractNameFromPath(docUrl),
                mimeType: extractMimeTypeFromType(extractTypeFromPath(docUrl)),

            })
        }
    }, [docUrl])

    const extractNameFromPath = (path) => path.split("/").at(-1);
    const extractTypeFromPath = (path) => path.split(".").at(-1);

    const extractMimeTypeFromType = (type) => {
        if (constants.IMAGE_EXTENSIONS_TYPE.includes(type)) return constants.IMAGE_EXTENSIONS.filter(item => item.includes(type))[0];
        if (constants.VIDEO_EXTENSIONS_TYPE.includes(type)) return constants.VIDEO_EXTENSIONS.filter(item => item.includes(type))[0];
        if (constants.AUDIO_EXTENSIONS_TYPE.includes(type)) return constants.AUDIO_EXTENSIONS.filter(item => item.includes(type))[0];
        else {
            switch (type) {
                case "pdf":
                    return "application/pdf";
                case "doc":
                    return "application/msword";
                case "docx":
                    return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                case "xls":
                    return "application/vnd.ms-excel";
                case "xlsx":
                    return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                case "ppt":
                    return "application/vnd.ms-powerpoint";
                case "pptx":
                    return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
                default:
                    return "application/pdf";
            }
        }
    }

    useEffect(() => {

        if (docUrl.includes("microskillCertificate")) return;
        // console.log("RDD", RDD)

        const [uidCode, cidCode, tidCode] = ['users', 'compy', 'usess']

        const uid = urlParams.get('du');
        const cid = urlParams.get('dc');
        const tid = urlParams.get('dt');

        const userId = `${uidCode}_${uid}`;
        const companyId = `${cidCode}_${cid}`;
        const tokenId = `${tidCode}_${tid}`;
        const authObj = { userId, companyId, tokenId };
        // console.log("authObj", authObj);

        let userActivityPayload = {
            "oldScreen": 'DOCUMENT SHARED',
            "currentScreen": 'VIEW SHARED DOCUMENT',
            "nextScreen": '',
            "activityType": "SHARED DOCUMENT VIEWED",
            "activityData": {
                "document": extractNameFromPath(docUrl),
                "device": `${RDD.osName} ${RDD.browserName}`,
            }
        }
        if (sharedTo && !isEmpty(sharedTo)) userActivityPayload.activityData.sharedTo = sharedTo?.replaceAll("_", " ");

        // console.log("userActivityPayload", userActivityPayload);
        // return

        submitUserActivityData(userActivityPayload, authObj)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={classes.PageRoot}>
            {
                // !isEmpty(docData) && <FileViewer />
                // !isEmpty(docData) && <FileViewer file={docData} />
                isEmpty(docData) ? (
                    <div style={constants.FULL_HEIGHT_CENTER}>
                        <CircularProgress />
                    </div>
                ) : (
                    // <PDFviewerSafari docData={docData} />
                    <>
                        <div className={classes.headerBox}>
                            <div className={classes.headerTitle}>
                                {docData.name}
                            </div>
                        </div>
                        <div className={classes.fileViewerWrapper}>
                            <FileViewer
                                files={[docData]}
                                type={docData.mimeType}
                                indexVal={0}
                                isMobile={isMobile}
                                from={"GENERIC_DOC_VIEWER"}
                            />
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default GenericDocViewer;