import axiosInstance from "../../utils/axios";

export const submitUserLoginActivity = async (payload) => {
    try {
        const respData = await axiosInstance.post('/logger/userSignInLog', payload);
        return respData;
    }
    catch (err) {
        console.log('Failed to submit login activity:', err);
        return err.response;
    }
}