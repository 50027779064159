import constants from "../../constants";
import axiosInstance from "../../utils/axios";
import isEmpty from "../../utils/isEmpty";
import { jwtDecode } from "jwt-decode";
// import { getAllModules } from "./learn.actions";

export const submitUserActivityData = async (_payload = {}, authObj={}) => {
    const payload = { ..._payload };
    try {
        const access_token = localStorage.getItem('accessToken');
        const request_token = localStorage.getItem('requestToken');


        if(localStorage.getItem('userType') === constants.USER_TYPES.MANAGER) return //disabling event tracking incase of manager
        if(!isEmpty(authObj)){ // code added for generic doc viewer
            payload.companyId = authObj.companyId;
            payload.userId = authObj.userId;
            payload.tokenId = authObj.tokenId;
            // console.log("submitUserActivityData:", payload);
            // return {}
            // } else 
        } else
        if (!isEmpty(access_token) || !isEmpty(request_token)) {
            // if (localStorage.getItem('accessToken') !== null || localStorage.getItem('accessToken') !== undefined || localStorage.getItem('requestToken') !== null || localStorage.getItem('requestToken') !== undefined) {
            const tempData = isEmpty(access_token) ? jwtDecode(request_token) : jwtDecode(access_token);

            if (!isEmpty(access_token)) {
                payload.companyId = tempData.companyId;
                payload.userId = tempData.userId;
                payload.tokenId = tempData.tokenId;
            }
            else if (!isEmpty(request_token)) {
                payload.companyId = tempData.companyId;
                payload.userId = tempData.userId;
                payload.tokenId = tempData.tokenId;
            }

        }
        const respData = await axiosInstance.post(`/access/user-activity`, payload);
        return respData;
    } catch (err) {
        console.log("submitUserActivityData:", err);
    }
}
