import React from 'react'

import useStyles from './index.styles'
import { useTranslation } from 'react-i18next';

const FormCard = ({ id, form, handleFormClick, assessment }) => {


    const { t, i18n } = useTranslation()
    const styles = useStyles(i18n);
    return (
        <>
            <div className={`${styles.cardContainer}`} onClick={(e) => handleFormClick(e, id)}>
                <div className={`${styles.cardGradient} ${styles.asessmentModel}`}>
                    <div className={`${styles.centerFlex} ${styles.nameContainer}`}>
                        <div className={`${styles.centerFlex} ${styles.headingConatiner}`}>
                            <h2 className={styles.microSkillName}>{form.name}</h2>
                            <h4 className={styles.aboutText}>{form.description}</h4>
                        </div>
                        <div className={`${styles.centerFlex} ${styles.buttonContainer}`}>
                            <img src={"/images/forms/arrow.svg"} alt='arrow' style={{ height: '2.5rem' }} />
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default FormCard