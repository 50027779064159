import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    container: {

    },
}))

export default useStyles