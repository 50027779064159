import { makeStyles } from "@mui/styles";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    pdfViewerRoot: {
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
    },
    // downloadBox: {
    //     position: 'absolute',
    //     zIndex: '9999',
    //     bottom: '2rem',
    //     right: '1rem',
    //     height: '3rem',
    //     width: '3rem',
    //     borderRadius: '50%',
    //     backgroundColor: '#fff',
    //     padding: '0.2rem',
    //     boxShadow: '0px 0px 10px 0px #00000057',
    //     '& img': {
    //         height: '100%',
    //         width: '100%',
    //         objectFit: 'contain'
    //     }
    // },
    downloadbox: {
        position: 'absolute',
        zIndex: '9999',
        right: '1.5rem',
        width: '2.2rem',
        height: '2.2rem',
        backgroundColor: 'red',
        padding: '0.2rem',
        borderRadius: '50%',
        boxShadow: '0px 0px 10px 0px #00000057',
        [theme.breakpoints.down('sm')]: {
            right: '1rem',
        },
        '& span': {
            cursor: 'pointer',
        },
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'contain'
        }
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "5px",
        height: '100%',
        maxHeight: '100%',
        maxWidth: 'max-content',
        overflow: 'auto',
    },
    imagesContainer: {
        width: '100%',
        height: '100%',
        maxWidth: 'max-content',
        maxHeight: 'max-content',
        // height: '100%',
        transition: 'transform 0.2s ease-in-out',
        transformOrigin: 'top left'
    },
    imageWrapper: {
        // width: "300px",
        // height: "350px",
        border: "1px solid rgba(0,0,0,0.15)",
        borderRadius: "3px",
        boxShadow: "0 2px 5px 0 rgba(0,0,0,0.25)",
        padding: "0",
        // touchActions: 'pinch-zoom',
        // height: '100%',
        height: 'fit-content',
        padding: '1rem 0',
        marginBottom: '0.5rem',
        backgroundColor: theme.palette.white,
    },
    image: {
        width: "100%",
        height: "100%",
        margin: "0",
        border: "none",
        objectFit: 'contain'
        // touchAction: 'pinch-zoom'
    },
    pdfControls: {
        position: 'absolute',
        zIndex: '9999',
        bottom: '12rem',
        right: '0',
        // width: '3rem',
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: "flex-start",
        flexDirection: 'column',
        gap: '0.5rem',
        padding: '0.5rem 1rem',
        [theme.breakpoints.up('sm')]: {
            bottom: '6rem',
            right: '0.5rem'
        }
    },
    pdfControl: {
        // border: `1px solid ${theme.palette.orange}`,
        // backgroundColor: theme.palette.lightGrey1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '0.2rem',
        width: '2.2rem',
        height: '2.2rem',
        backgroundColor: theme.palette.white,
        padding: '0.2rem',
        borderRadius: '50%',
        boxShadow: '0px 0px 10px 0px #00000057',
    },
    pdfControlIcons: {
        color: `${theme.palette.orange} !important`,
        fontSize: '1.5rem !important',
    }
}))

export default useStyles