
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
// import GbaHeader from "../header";

// Style
import useStyle from "./index.styles";
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import constants from '../../../../constants';
import isEmpty from '../../../../utils/isEmpty';
import { CircularProgress } from '@mui/material';

const StartScreen = ({
    width,
    height,
    bg,
    gradientBg,
    gameLogo,
    setIsGamePage,
    setIsHowToPlayScreen,
    logoWidth,
    isComingSoon,
    getchallengeAsync,
    gameStatus,
    limitedEdition = false,
    isColorSplash = false,
    handleGbaState,

    startScreenGameLogoLoaded,
    setStartScreenGameLogoLoaded,
    startScreenGameBgLoaded,
    setStartScreenGameBgLoaded,
    gameBranding = null,
}) => {
    const classes = useStyle();
    const location = useLocation();
    const navigate = useNavigate();
    const isDiwaliDash = bg.includes('diwaliDash')
    const isbuildingBlocks = bg.includes('building_blocks');
    const isCrazyCars = bg.includes('crazycar')
    const isPopcornMatch = bg.includes('popcorn')


    const [gameLogoLoaded, setGameLogoLoaded] = useState(false);
    const [gameBgLoaded, setGameBgLoaded] = useState(false);

    const customBannerImg = "/images/gba/limited-edition-banner.svg"

    const { t } = useTranslation()

    const handleStartGameClick = () => {
        setIsGamePage(true);
    }
    useEffect(() => {
        if (isEmpty(bg)) setGameBgLoaded(true)
    }, [bg])
    // useEffect(() => {
    //     handleGbaState(constants.GBA_SCREEN_STATE.START)
    // }, [])
    return (
        <div className={classes.gbaStartScreenRoot} style={isPopcornMatch ? {} : { backgroundImage: `${gradientBg}` }}>
            {
                (!startScreenGameBgLoaded || !startScreenGameLogoLoaded) && (
                    <div className={`${classes.progressWrapper} ${classes.centerFlex}`}>
                        <CircularProgress />
                    </div>
                )
            }

            <div className={classes.gbaStartScreenContainer}
            // style={{ backgroundImage: `url(${bg})`, backgroundPositionY: isbuildingBlocks ? 'bottom' : 'center', backgroundSize: isbuildingBlocks || isCrazyCars ? 'contain' : 'cover' }}
            >
                {
                    !isEmpty(bg) && (
                        <img
                            src={bg}
                            alt="bg-img"
                            style={{
                                objectFit: isbuildingBlocks || isCrazyCars ? 'contain' : 'cover'
                            }}
                            className={`disableSelection disablePointerEvents ${classes.startScreenBg}`}
                            onLoad={() => {
                                setStartScreenGameBgLoaded(true);
                            }}
                            onError={(e) => {
                                setStartScreenGameBgLoaded(true);
                                e.target.style.display = 'none';
                            }}
                        />
                    )
                }

                <div className={`${classes.centerFlex} ${classes.bgImgBox}`} style={
                    isColorSplash ? {
                        backgroundImage: 'url(/images/gba/bucket_splash.png)',
                        backgroundSize: '130%',
                        backgroundPosition: 'center',
                    } : {
                        alignItems: isDiwaliDash ? 'flex-end' : 'center',
                    }
                }>
                    {
                        isDiwaliDash ? (
                            <>
                                <img src="/diwaliDash/banner.png" alt="banner" className={`disableSelection disablePointerEvents ${classes.bannerImg}`} />
                            </>
                        ) : (null)
                    }

                    {
                        limitedEdition && <div className={classes.customBannerContainer}>
                            {/* <h6 className={classes.customBannerText}>limited edition</h6> */}
                            <img src={customBannerImg} className={`disableSelection disablePointerEvents ${classes.customBannerImg}`} width="100%" alt="banner" />
                        </div>
                    }

                    <div className={classes.mainText}>
                        <img src={gameLogo} className={`disableSelection disablePointerEvents ${classes.titleImage}`} style={{ width: logoWidth }} alt="reveal"
                            onLoad={() => {
                                setStartScreenGameLogoLoaded(true);
                            }}
                            onError={() => {
                                setStartScreenGameLogoLoaded(true);
                                console.log("error occured in loading game logo")
                            }}
                        />
                    </div>
                    {!isEmpty(gameBranding) && <div className={classes.gameSubLogoAnimation}>
                        <img src={gameBranding} className={`disableSelection disablePointerEvents ${classes.titleImage}`} style={{ width: logoWidth }} alt="reveal" />
                    </div>
                    }
                </div>

                {
                    !isComingSoon ? (
                        <div className={`${classes.centerFlex} ${classes.btnBox}`}>
                            <div className={classes.btnContainer}>
                                <Button
                                    variant="contained"
                                    color="gbabtn"
                                    fullWidth
                                    size="small"
                                    className={classes.startBtn}
                                    onClick={handleStartGameClick}>
                                    {t("START GAME")}
                                </Button>
                                {/* <button className={classes.startBtn} onClick={handleStartGameClick}>START GAME</button> */}
                                {/* <button className={classes.howToPlay} onClick={setIsHowToPlayScreen}>HOW TO PLAY</button> */}
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', width: '90%', margin: 'auto', height: '90%', justifyContent: 'center' }}>
                            <div className={`${classes.centerFlex} ${classes.comingSoonText}`}>{t("Coming soon")}...</div>
                            <LoadingButton
                                variant='contained'
                                color='gbabtn'
                                fullWidth
                                size='small'
                                className={classes.startBtn}
                                style={{ width: '100%' }}
                                onClick={getchallengeAsync}
                            >
                                {t("NEXT CHALLENGE")}
                            </LoadingButton>
                        </div>
                    )
                }
            </div>
        </div >
    )
}

export default StartScreen