import React, { useState } from "react";

// mui
import { LoadingButton } from "@mui/lab";

// components

// redux

// Apis

// utils
// import isEmpty from "../../utils/isEmpty";

// styles
import useStyles from "./index.styles"

// constants

const PrivacyPolicyBanner = ({ expirationDate, handleViewClick }) => {
    const classes = useStyles();
    const [btnLoading, setBtnLoading] = useState(false);
    return (
        <div className={`${classes.centerFlex} ${classes.updatePolicy}`}>
            <span className={classes.updatePolicyText}>We have updated our privacy policy, you're requested to accept it before <span className={classes.policyDate}>{expirationDate}</span>
            </span>
            <div className={classes.viewBtn}>
                <LoadingButton
                    className={classes.viewBtn}
                    type="submit"
                    style={{ borderRadius: '0.4rem', padding: "8px 2px", backgroundColor: '#F4511E', fontWeight: 'bold', fontSize: '1rem' }}
                    fullWidth
                    onClick={handleViewClick}
                    loading={btnLoading}
                    disableElevation
                    variant="contained"
                    color="primary"
                    size="medium"
                >
                    View
                </LoadingButton>
            </div>
        </div>
    )
}

export default PrivacyPolicyBanner;