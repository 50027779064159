import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    recordVideoRoot: {
        position: 'relative',
        height: 'inherit',
        display: 'grid',
        alignItems: 'center',
        padding: '1.5rem',
        gridTemplateRows: '8% 75% 12% 5%',
        background: '#E1E1E1',
    },
    previewVideoBox: {
        height: '100%',
        position: 'relative'
    },
    icon: {
        position: 'absolute',
        // left: '0'
    },
    recordingText: {
        color: '#484848',
        textAlign: 'center',
        fontWeight: 700,
        letterSpacing: '2px',
        fontSize: '1.5em'
    },
    videoPlayer: {
        '& video': {
            borderRadius: '8px',
            objectFit: 'cover'
        }
    },
    loadingText: {
        fontSize: '2.5em',
        color: '#F4511E',
        fontFamily: "montserrat",
        fontWeight: 900,
        textAlign: 'center'
    },
    card: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '20px 20px 49px #00000029',
        borderRadius: '12px',
        padding: '2rem',
        margin: '2rem 0',
    },
    analyzeOverlayRoot: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: palette.spotlightGreyBg,
        overflowY: 'scroll',
        aspectRatio: '9/16',
        padding: '1.2rem'
    },
    btnProgressBarBox: {
        // padding: '1rem',
        // backgroundRepeat: 'no-repeat',
        backgroundSize: '0% 100%',
        // backgroundImage: 'linear-gradient(#a5a5a5, #a5a5a5)',
        borderRadius: '4px',
        width: '100%',
        textTransform: 'none',
        transition: '1s',
        height: '100%'
    },
    submitBtn: {
        // padding: '0 !important',
        // fontSize: '0.9em !important',
        fontWeight: '600 !important',
        // backgroundImage: 'linear-gradient(#a5a5a5, #a5a5a5)',
        backgroundRepeat: 'no-repeat',
        // height: '2.5rem !important'
    }
}))

export default useStyles;