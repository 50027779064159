// Local end testing
// const CLIENT_ID = process.env.CLIENT_ID || '0oa8kwp8pvvhWKzDf5d7';
// const ISSUER = process.env.ISSUER || 'https://dev-73236765.okta.com/oauth2/default';

// WEB QA ID
// const CLIENT_ID = process.env.CLIENT_ID || '0oalfw65lmSQwtjLw2p7';
// const ISSUER = process.env.ISSUER || 'https://diageo.okta.com/';

//Web-Staging ID
const CLIENT_ID = process.env.CLIENT_ID || '0oalx2x07khLpR3iL2p7';
const ISSUER = process.env.ISSUER || 'https://diageo.okta.com/';

const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = `${window.location.origin}/login/callback/okta-auth`;
// const REDIRECT_URI = `${window.location.origin}/auth/login`;

// eslint-disable-next-line
export default {
    oidc: {
        clientId: CLIENT_ID,
        issuer: ISSUER,
        redirectUri: REDIRECT_URI,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    },
    resourceServer: {
        messagesUrl: 'http://localhost:8000/api/messages',
    },
};
