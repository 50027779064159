import { makeStyles } from "@mui/styles";

import commonUtil from "../../../../utils/commonUtil";

const isHDScreen = commonUtil.getScreenResolution() === 'HD';

const useStyle = makeStyles((theme) => ({
    audioPlayer: {
        width: isHDScreen ? "90%" : "80%",
        margin: "auto",
        [theme.breakpoints.up("sm")]: {
            //width: "50%",
            //padding: "2rem 3rem",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "0.5rem",
            width: "100%"
        },
    },
    // backDrop: {
    //     height: "240px",
    //     width: "230px",
    //     margin: "auto",
    //     backgroundColor: "#4f0160",
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     borderRadius: "16px",
    //     boxShadow: "0px 0px 13px 0px rgb(0 0 0 / 50%)"
    // },
    // trackName: {
    //     color: "#fff",
    //     padding: "1rem",
    //     textAlign: "center",
    //     [theme.breakpoints.down("sm")]: {
    //         fontSize: "1.4rem",
    //         padding: "1.5rem",
    //     },
    // },
    trackSlider: {
        //padding: "2rem",
        padding: "0rem .8rem",

    },
    slider: {
        height: "8px",
        WebkitAppearance: "none",
        width: "100%",
        marginBottom: "10px",
        borderRadius: "8px",
        background: "#3b7677",
        transition: "background 0.2s ease",
        cursor: "pointer",
        '&::-webkit-slider-thumb': {
            '-webkit-appearance': 'none',
            appearance: 'none',
            width: '15px',
            height: '15px',
            background: '#ffffff',
            cursor: 'pointer',
            borderRadius: '50%',
            // background: 'url(/soundsRight/like.svg)'
        },
        '&::-moz-range-thumb': {
            width: '15px',
            height: '15px',
            background: '#ffffff',
            cursor: 'pointer',
            borderRadius: '50%',
            // background: 'url(/soundsRight/playbtn.svg)'
        }
    },

    timerBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0rem 0.8rem',
        '& > div': {
            fontSize: '0.8rem',
            fontFamily: 'montserrat',
            [theme.breakpoints.down("sm")]: {
                fontSize: '1.2rem',
            },
        }

    }
}));

export default useStyle;
