import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// mui

// components
import LoadingMicroskill from "../../components/loading-microskill";

// redux
import { signIn, logout } from "../../redux/auth/auth.api";

// utils
import isEmpty from "../../utils/isEmpty";

// constants
import constants from "../../constants";

// styles
import useStyles from "./index.styles";


const AdityaBirlaCapital = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const isMobile = useSelector(state => state?.common?.isMobile);

    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get('user_id');
    const authToken = queryParams.get('gAuthToken');

    useEffect(() => {
        if (isEmpty(userId)) {
            logout();
            return
        }

        async function signInUser() {
            const req = {
                username: `${userId}@absli`,
                password: '',
            }
            console.log("req:", req);
            // return
            const signInResp = await signIn(req);
            if (signInResp.status === 200 || signInResp.status === 201) {
                // navigate("/learn");
                window.location.href = '/learn';
            } else {
                logout();
                return
            }
        }
        signInUser();
    }, [])

    return (
        <div className={classes.abcRoot} >
            <LoadingMicroskill loadingText={false} />
        </div>
    )
}

export default AdityaBirlaCapital;