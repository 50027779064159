import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

const useStyle = makeStyles((theme) => ({
    audioPlayer: {
        //maxWidth: "340px",
        display: 'grid',
        gridTemplateRows: '50% 20% 30%',
        height: '100%',
        margin: "auto",
        [theme.breakpoints.up("sm")]: {
            width: "50%",
            //padding: "2rem 3rem",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "0 2rem",
            width: "100%"
        },
    },
    backDrop: {
        width: "75%",
        maxWidth: '270px',
        aspectRatio: '1/1',
        margin: "auto",
        backgroundColor: palette.voilet3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "16px",
        boxShadow: "0px 0px 13px 0px rgb(0 0 0 / 50%)"
    },
    trackName: {
        color: "#fff",
        padding: "2rem 1rem",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.2rem",
            padding: "2rem 1.2rem",
        },
    },
    trackSlider: {
        padding: "2rem",
        [theme.breakpoints.down("sm")]: {
            padding: "2rem 0",
        },
    },
    slider: {
        height: "11px",
        WebkitAppearance: "none",
        width: "100%",
        marginBottom: "10px",
        borderRadius: "8px",
        background: "#564273",
        transition: "background 0.2s ease",
        cursor: "pointer",
        '&::-webkit-slider-thumb': {
            '-webkit-appearance': 'none',
            appearance: 'none',
            width: '11px',
            height: '11px',
            background: '#ffffff',
            cursor: 'pointer',
            borderRadius: '50%',
            // background: 'url(/soundsRight/like.svg)'
        },
        '&::-moz-range-thumb': {
            width: '11px',
            height: '11px',
            background: '#ffffff',
            cursor: 'pointer',
            borderRadius: '50%',
            // background: 'url(/soundsRight/playbtn.svg)'
        }
    },
    timerBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0rem 0.8rem',
        '& > div': {
            fontSize: '0.8rem',
            fontFamily: 'montserrat',
            [theme.breakpoints.down("sm")]: {
                fontSize: '1.2rem',
            },
        }
    },
    downloadBox: {
        position: 'absolute',
        zIndex: '9999',
        bottom: '2rem',
        right: '1rem',
        height: '3rem',
        width: '3rem',
        borderRadius: '50%',
        backgroundColor: '#fff',
        padding: '0.2rem',
        boxShadow: '0px 0px 10px 0px #00000057',
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'contain'
        },
    },
    time: {
        color: ({ from }) => from === 'ila' ? 'rgba(0 0 0)' : theme.palette.whiteToOrange,
    }
}));

export default useStyle;
