import useStyles from "./index.styles";
import { useSelector } from "react-redux";

// import LearningAidContext from "../../context/learningAidContext";

// import { useContext } from 'react';
import { useCallback, useRef, useState } from "react";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

/**
 *@Important Some CSS is written at APP.CSS file with '.kvfysmfp' selector
 */

function ImageMagnifier(props) {
    const { 
        imageUrl, 
        classNameText = "", 
        setShareBtnBisible=(e)=>{}
    } = props;
    const isMobile = useSelector(state => state?.common?.isMobile);
    const imgRef = useRef();
    const loadingImgRef = useRef();
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    // const { fileType } = useContext(LearningAidContext);

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const classes = useStyles();

    const onUpdate = useCallback(({ x, y, scale }) => {
        const { current: img } = imgRef;
        if (img) {
            if (isMobile) {
                if (scale > 1) {
                    img.parentElement.style = "overflow: visible"
                } else {
                    img.parentElement.style = ""
                }
            }
            if (x === 0 && y > 0) {
                return;
            } else {
                const value = make3dTransformValue({ x, y, scale });
                img.style.setProperty("transform", value);
            }
        }
    }, []);

    // console.log(imageUrl, 'imageUrl')
    return (
        <div className={classes.magnifierContainer}>
            <QuickPinchZoom
                onUpdate={onUpdate}
                zoomOutFacor={3}
                zoomInFactor={5}
                doubleTapZoomOutOnMaxScale={true}
                maxZoom={3}
                minZoom={0.8}
                tapZoomFactor={isMobile ? 0 : 1}
            >
                {/* <>
                    {
                        !isImageLoaded && <>
                            <img
                                ref={loadingImgRef}
                                src="/images/sample-image/master_o_brand_icon_small.png"
                                alt='img'
                                className={classes.imageLoader}
                            />
                            <span className={classes.imageLoaderAnime}></span>
                        </>
                    } */}
                <img
                    ref={imgRef}
                    src={imageUrl}
                    alt='img'
                    onLoad={async () => {
                        // await sleep(1000 * 5)
                        setIsImageLoaded(true)
                        setShareBtnBisible(true)
                    }}
                    className={`${classNameText} ${classes.onlyImage}`}
                    loading="lazy"
                    style={{
                        filter:  !imageUrl?.includes(".gif") ? (isImageLoaded ? "none" : "blur(20px) grayscale(0.25)") : "none",
                    }}
                />
                {/* </> */}
            </QuickPinchZoom>
        </div>
    );
}

export default ImageMagnifier
