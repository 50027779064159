import React, { useRef, useEffect, useState } from "react";
// import useRouter from "../../utils/useRouter";
import { useNavigate, useParams, useLocation, createSearchParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// mui
import VisibilityIcon from '@mui/icons-material/Visibility';
import WestIcon from '@mui/icons-material/West';
import ChallengeListCard from "../../components/challenge-list-card";
import { Button, CircularProgress, Rating } from "@mui/material";
import StarIcon from '@mui/icons-material/StarOutline';

// components
import CompletionProgress from "../../components/completion-progress";
import MicroskillFeedBack from "../../components/microskill-feedback-form";
import HeaderBackButton from "../../components/layout-elements/header-back-button";
import Forms from "../../components/forms-revamp";

// redux
import { getChallengeLists, markMicroskillFeedbackDismiss } from "../../redux/microskill/microskill.api";
import { getSpotlightResultCompleted } from "../../redux/spotlight/spotlight.api";
import { notificationViewMark } from "../../redux/notifications/notification.api";

// utils
import isEmpty from "../../utils/isEmpty";
import { isNativeApp } from '../../utils/commonUtil';

// theme
import palette from "../../theme/palette";

// constants
import constants from "../../constants";

// styles
import useStyles from "./index.styles";
import webViewJavaScriptBridge from "webview-javascript-bridge";

const height = '180px';
// const BACK_BTN_EVENT = constants.HEADER_BACK_BUTTON_CLICK_EVENT;

const ChallengeList = (props) => {
    const navigate = useNavigate();
    const { microskillId } = useParams();
    let [searchParams, setSearchParamsa] = useSearchParams();

    const queryParams = new URLSearchParams(window.location.search);

    const notificationId = searchParams.get('notificationId');
    const journeyId = searchParams.get('journeyId');
    const location = useLocation();
    // const router = useRouter();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const isTablet = useSelector(state => state?.common?.isTablet);

    const challengeListRef = useRef();
    const challengeListContainerRef = useRef();
    const challengeListHeaderRef = useRef();
    const challengeListBgRef = useRef();
    const challengeListMobileHeaderRef = useRef();
    const [showModuleName, setshowModuleName] = useState(false);
    const [challengeListData, setChallengeListData] = useState();
    const [microskillData, setMicroskillData] = useState();
    const [completionPer, setCompletionPer] = useState(0);

    const [microskillFeedbackShow, setMicroskillFeedbackShow] = useState(false);
    const [microskillRating, setMicroskillRating] = useState(null)

    // FORM
    const [formId, setFormId] = useState('');
    const [openForm, setOpenForm] = useState(false);
    const [challengeId, setChallengeId] = useState('');

    const checkIcon = "/images/icons/check_green.png";
    const concentricIcon = "/images/icons/concentric_yellow.png";
    const certificateIcon = "/images/icons/medal.png";
    const lockIcon = "/images/icons/lock_white.png";

    const { t, i18n } = useTranslation()
    const classes = useStyles(i18n);
    let searchParam = isEmpty(location.search.split('?')[1]) ? '' : `&${location.search.split('?')[1]}`



    useEffect(() => {
        const formId = queryParams.get('formId');
        const challengeId = queryParams.get('challengeId');

        if (!isEmpty(formId)) {
            setFormId(formId);
            setChallengeId(challengeId);
            setOpenForm(true);
        }

    }, [queryParams])

    useEffect(() => {


        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getChallengeListData = async () => {
        const moduleData = await getChallengeLists(microskillId);
        if (moduleData.status === 404) {
            navigate('/learn');
        }
        else if (moduleData.status === 200 || moduleData.status === 201) {
            notificationMark();
            let data = moduleData?.data?.data;
            let sortedData = data?.challenges.sort((a, b) => a.sequence - b.sequence);
            setChallengeListData(sortedData);
            setMicroskillData(data?.microskill);
            if (data?.microskill.Microskill.showFeedback && !data?.microskill.feedbackSubmitted && !data?.microskill.feedbackDismissed) {
                setMicroskillFeedbackShow(true);
            }

            const { completedChallenges, totalActivechallenges } = data.microskill;

            const completionPercentage = Math.round((completedChallenges * 100) / totalActivechallenges);
            setCompletionPer(completionPercentage);

            const isNative = localStorage.getItem('isNative');
            if (isNative === 'true') {
                webViewJavaScriptBridge.sendMessage({ action: 'TurnOffNativeSplashScreenChannel', params: true })
            }



        }
    }

    useEffect(() => {
        if (!isEmpty(formId)) {
            setOpenForm(true);
        }
    }, [formId])

    useEffect(() => {
        getChallengeListData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [microskillId]);

    const notificationMark = async () => {
        if (isEmpty(notificationId)) return;
        const payload = {
            messageId: notificationId,
            status: 'VIEWED'
        };
        const markViewNotification = await notificationViewMark(payload);
        if (markViewNotification.status === 200 || markViewNotification.status === 201) {
            console.log('completed');
        }
    }

    useEffect(() => {
        if (isEmpty(microskillData)) return;
        let marqueeEl = document.getElementById(microskillData?.microskillId);
        if (isEmpty(marqueeEl)) return
        var overflowX = marqueeEl.offsetWidth < marqueeEl.scrollWidth,
            overflowY = marqueeEl.offsetHeight < marqueeEl.scrollHeight;
        // var overflowX = marqueeEl.clientWidth < marqueeEl.scrollWidth,
        //     overflowY = marqueeEl.clientHeight < marqueeEl.scrollHeight;
        // console.log("width:", [marqueeEl.clientWidth, marqueeEl.offsetWidth, marqueeEl.scrollWidth])
        // console.log("height:", [marqueeEl.clientHeight, marqueeEl.offsetHeight, marqueeEl.scrollHeight])
        if (overflowX) {
            if (!isEmpty(marqueeEl.firstChild)) {
                marqueeEl.firstChild.classList.add(classes.marqueeAnimation);
            }
        }
    }, [microskillData]);

    const handleScroll = () => {
        if (isMobile) {
            if (!isEmpty(challengeListContainerRef.current) && challengeListContainerRef.current.scrollTop > 0) {
                if (isEmpty(challengeListMobileHeaderRef.current)) return
                setshowModuleName(true);
                // challengeListMobileHeaderRef.current.style.position = 'fixed';
                challengeListMobileHeaderRef.current.style.background = '#4f0060';
                // challengeListBgRef.current.style.height = `0`;
            } else {
                if (isEmpty(challengeListMobileHeaderRef.current)) return
                setshowModuleName(false);
                // challengeListMobileHeaderRef.current.style.position = 'fixed';
                challengeListMobileHeaderRef.current.style.background = null;
                // challengeListBgRef.current.style.height = null;
            }
            return
        }
        if (!isMobile && !isEmpty(challengeListRef.current) && !isEmpty(challengeListHeaderRef.current) && !isEmpty(challengeListBgRef.current)) {
            if (challengeListRef.current.scrollTop > 0) {
                challengeListBgRef.current.style.height = `calc(${height} - 20px)`;
                challengeListHeaderRef.current.style.top = '0';
                challengeListHeaderRef.current.style.width = '100%';
            } else {
                challengeListBgRef.current.style.height = height;
                challengeListHeaderRef.current.style.top = '90px';
                challengeListHeaderRef.current.style.width = '80%';
            }
        }
    }

    const pathIcon = (data) => {
        let icon = checkIcon;
        if (data === 'certificate') {
            icon = certificateIcon;
        }
        else if (data?.completion) {
            icon = checkIcon
        } else if (data.status?.toUpperCase() === 'LOCKED' || data === 'LOCKED') {
            icon = lockIcon
        } else if (data.status?.toUpperCase() === 'CHECK') {
            icon = checkIcon
        } else {
            if ((!data?.completion && data?.inCompleteAttempts > 0 && data?.correctAttempts === 0) || data?.status?.toUpperCase() === 'ACTIVE') {
                icon = concentricIcon
            } else if (data?.status?.toUpperCase() === 'LOCKED') {
                icon = lockIcon
            }
        }
        return (
            <div className={classes.pathway}>
                <div className={`${classes.topPath} topPathBox`} />
                <img src={icon} alt="icon" className={`disableSelection disablePointerEvents ${classes.icon}`}
                    style={{
                        // filter: isBlur ? 'blur(4px)' : 'none'
                        // filter: isIconWhite ? 'brightness(2)' : 'none'
                    }}
                />
                <div className={`${classes.bottomPath} bottomPathBox`} />
            </div>
        )
    }

    const checkCardBlur = (data) => {
        return data?.status?.toUpperCase() === 'LOCKED';
    }

    const getSpotlightResult = async (challengeId) => {
        let respData = await getSpotlightResultCompleted({ microskillId, challengeId });
        if (respData.status === 200 || respData.status === 201) {
            let challengeData = respData.data.data.spotlightChallengeData;
            navigate(`/spotlight-result/${microskillId}/${challengeId}/${respData.data.data.userSpotLightResponse.id}/${respData.jobId}`, { state: { challengeData } });
        }
    }

    const handleChallengeListCardClick = (item) => {
        if (isEmpty(item?.Challenge?.type) || item?.status?.toUpperCase() === 'LOCKED') return;
        if (item.Challenge.type.toUpperCase() === 'GBA') {
            let params = `name=${item?.Challenge?.name}${searchParam}`
            return navigate({
                pathname: `/challenge-list/${microskillId}/${item.challengeId}/gba`,
                search: `?${createSearchParams(params)}`,
            });
        } else if (item.Challenge.type.toUpperCase() === 'SL' && item.completion === true) {
            getSpotlightResult(item.challengeId);
        } else if (item.Challenge.type.toUpperCase() === 'SL') {
            let params = `name=${item?.Challenge?.name}${searchParam}`
            return navigate({
                pathname: `/challenge-list/${microskillId}/${item.challengeId}/spotlight`,
                search: `?${createSearchParams(params)}`,
            });
        } else if (item.Challenge.type.toUpperCase() === 'LA') {
            let params = `${searchParam}`
            return navigate({
                pathname: `/challenge-list/${microskillId}/${item.challengeId}/learning-aid`,
                search: `?${createSearchParams(params)}`,
            });
        } else if (item.Challenge.type.toUpperCase() === 'SCORM') {
            let params = `name=${item.Challenge.name}${searchParam}`
            return navigate({
                pathname: `/challenge-list/${microskillId}/${item.challengeId}/scorm/${item.Challenge.setting.folderName}`,
                search: `?${createSearchParams(params)}`,
            });
        } else if (item.Challenge.type.toUpperCase() === 'FORM') {
            setFormId(item.Challenge.formId);
            setChallengeId(item.challengeId);

        }
    }

    const handleBackBtnClick = () => {
        if (journeyId) {
            navigate(`/learning-journey/${journeyId}`)
        } else {
            navigate('/learn');
        }

    }

    const handleCertificateClick = () => {
        // console.log("microskillData?.Microskill:", microskillData?.Microskill)
        if (!microskillData?.Microskill?.certificate) return
        navigate(`/challenge-list/${microskillId}/certificate`);
    }

    const handleFeedbackClick = () => {
        setMicroskillFeedbackShow(true);
    }

    const handleFeedbackDismiss = async () => {
        let payload = { microskillId };
        const respData = await markMicroskillFeedbackDismiss(payload);
        if (respData.status === 200 || respData.status === 201) {
            setMicroskillFeedbackShow(false);
        }
    }

    useEffect(() => {
        if (microskillData?.completion && microskillData?.Microskill.showFeedback && !microskillData?.feedbackSubmitted && !microskillData?.feedbackDismissed && !microskillFeedbackShow) {
            handleFeedbackDismiss()
        }
    }, [microskillFeedbackShow])

    return (
        <div className={classes.challengeListContainer}
            ref={challengeListContainerRef}
            onScroll={handleScroll}
            onTouchMove={handleScroll}
        >
            <div className={classes.challengeListBox}
                onScroll={handleScroll}
                onTouchMove={handleScroll}
                ref={challengeListRef}
            >
                {
                    isEmpty(challengeListData) ? (
                        typeof challengeListData === 'object' && challengeListData.length === 0 ? (
                            <div className={`${classes.centerFlex} ${classes.noChallengeText}`}>{t("No challenge found")}</div>
                        ) : (
                            <div style={constants.FULL_HEIGHT_CENTER}>
                                <CircularProgress />
                            </div>
                        )
                    ) : (
                        <>
                            <div className={classes.challengeListBg} ref={challengeListBgRef}
                                style={{ backgroundImage: `url(${isEmpty(microskillData?.Microskill?.OrgCoverImage) ? '/images/sample-image/master_o_brand_icon.png' : microskillData?.Microskill?.OrgCoverImage}) ${isMobile ? ', radial-gradient(transparent, black)' : ''}`, }}
                            >
                                {(isMobile || isTablet) && (
                                    <div className={classes.challengeListMobileHeader}
                                        ref={challengeListMobileHeaderRef}
                                        style={{ height: isNativeApp() ? '100px' : '80px' }}
                                    >
                                        <div className={`disableSelection ${classes.centerFlex} ${classes.backButton}`} style={{ marginTop: isNativeApp() ? constants.HEADER_TOP_PADDING : null }}
                                            onClick={handleBackBtnClick}>
                                            <HeaderBackButton />
                                            {/* <img src="/images/icons/left_arrow.svg" alt="icon" width={20} /> */}
                                            {/* <KeyboardBackspaceIcon sx={{ color: '#FFF' }} /> */}
                                        </div>
                                        <div className={`${classes.centerFlex} ${classes.moduleName}`}>
                                            {
                                                showModuleName && (
                                                    <span className={`${classes.moduleNameText}`}>{microskillData?.Microskill?.name}</span>
                                                )
                                            }
                                        </div>
                                    </div>
                                )}

                                <div className={classes.challengeListHeader} ref={challengeListHeaderRef}>
                                    <div className={classes.challengeListHeaderBox}>
                                        {(isMobile || isTablet) && (
                                            <>
                                                <div className={classes.headerMainText}>
                                                    {
                                                        !isEmpty(microskillData) && (
                                                            <div className={classes.marqueeText} id={`${microskillData.microskillId}`}>
                                                                <span className={classes.marqueeTextSpan} >{microskillData?.Microskill?.name}</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                                <div className={classes.perDetailContainer}>
                                                    <div className={classes.detailBox}>
                                                        <div className={classes.scoreText}>
                                                            <span style={{ color: palette.white, fontWeight: 600 }}>{t("Score")}: </span>
                                                            <span style={{ fontWeight: 600 }}>{`${microskillData?.scored} / ${microskillData?.maxScore}`}</span>
                                                        </div>
                                                        <div className={`${classes.centerFlex} ${classes.iconBox}`}>
                                                            <VisibilityIcon sx={{
                                                                fontSize: "1em"
                                                            }} />
                                                            <span className={classes.countText}>{microskillData?.Microskill?.views}</span>
                                                        </div>
                                                    </div>
                                                    <div className={classes.completionBox}>
                                                        <CompletionProgress
                                                            completionPercentage={`${completionPer}%`}
                                                            fontSize={isMobile ? '0.8em' : '1em'}
                                                            fontWeight={'600'}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {!isMobile && !isTablet && (
                                            <>
                                                <div className={classes.headerMainText}>
                                                    {!isMobile && !isTablet && (
                                                        <div className={`${classes.backIcon} ${classes.centerFlex}`} onClick={handleBackBtnClick}> <WestIcon /> </div>
                                                    )}
                                                    {
                                                        !isEmpty(microskillData) && (
                                                            <div className={classes.marqueeText} id={`${microskillData.microskillId}`}>
                                                                <span className={classes.marqueeTextSpan} > {microskillData?.Microskill?.name}</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div className={classes.headerdetails} >
                                                    <div className={`${classes.centerFlex} ${classes.viewContainer}`}>
                                                        <VisibilityIcon sx={{ fontSize: isMobile ? "0.8rem" : "1rem" }} />
                                                        <span className={classes.countText}>{microskillData?.Microskill?.views}</span>
                                                    </div>
                                                    {
                                                        microskillData?.completion && microskillData?.Microskill?.showFeedback && microskillData?.feedbackSubmitted &&
                                                        (<div className={`${classes.centerFlex} ${classes.ratingContainer}`}>
                                                            <div>{t("Your Rating")}:</div>
                                                            <div>{microskillData?.userFeedback} / 5</div>
                                                            <Rating value={microskillData?.userFeedback} emptyIcon={<StarIcon style={{ color: "#FFF" }} />} readOnly />
                                                        </div>)
                                                    }
                                                </div>
                                                <div className={classes.completionBox}>
                                                    <CompletionProgress
                                                        completionPercentage={`${completionPer}%`}
                                                        fontSize={isMobile ? '0.8rem' : '1.5rem'}
                                                        fontWeight={'600'}
                                                    />
                                                    <div className={classes.scoreText}> {t("Score")}: {`${microskillData?.scored} / ${microskillData?.maxScore}`}</div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.challengeListCardBox}>
                                <ul className={classes.stepperUl}>
                                    {
                                        challengeListData.map((item, index) => (
                                            <li className={`${classes.stepperLi}`} key={item.challengeId}>
                                                {pathIcon(item)}
                                                <ChallengeListCard
                                                    handleChallengeListCardClick={() => handleChallengeListCardClick(item)}
                                                    data={item}
                                                    index={index}
                                                    isBlur={checkCardBlur(item)}
                                                />
                                            </li>
                                        ))
                                    }
                                    {
                                        microskillData?.Microskill?.certificate && (
                                            <li className={`${classes.stepperLi}`}>
                                                {pathIcon(completionPer === 100 ? 'CHECK' : 'LOCKED')}
                                                <div className={`${classes.certificateBox}`}>
                                                    {
                                                        completionPer === 100 ? (
                                                            <Button variant="contained" className={classes.certificateBtn}
                                                                onClick={handleCertificateClick} >
                                                                {t("View Certificate")}
                                                            </Button>
                                                        ) : (
                                                            <span>{t("Earn Your Certificate")}</span>
                                                        )
                                                    }
                                                </div>
                                            </li>
                                        )
                                        // : (
                                        //     <li className={`${classes.stepperLi}`}>
                                        //         {pathIcon('LOCKED')}
                                        //         <div className={`${classes.certificateBox}`} onClick={handleCertificateClick}>
                                        //             <span>Earn your certificate</span>
                                        //         </div>
                                        //     </li>
                                        // )
                                    }

                                    {/* Certifaicate logic old code */}
                                    {/* {
                                        microskillData?.Microskill?.certificate && (
                                            <li className={`${classes.stepperLi}`}>
                                                {pathIcon('certificate')}
                                                <div className={`${classes.certificateBox}`} onClick={handleCertificateClick}>
                                                    {
                                                        microskillData?.completion ?
                                                            (<span>Earn your certificate</span>)
                                                            :
                                                            (<span style={{ cursor: 'not-allowed' }}>Complete all challenges to view certificate</span>)
                                                    }
                                                </div>
                                            </li>
                                        )
                                    } */}
                                    {/* Certifaicate logic old code */}
                                    {
                                        microskillData?.completion && microskillData?.Microskill?.showFeedback && !microskillData?.feedbackSubmitted && (
                                            <div className={classes.feedbackConatiner}>
                                                <div className={classes.feedbackHeadline}>{t("Feedback Rating")}</div>
                                                <Button variant="contained" className={classes.certificateBtn} onClick={handleFeedbackClick}
                                                >
                                                    {t("Rate this Microskill now")}!
                                                </Button>
                                            </div>
                                        )
                                    }

                                    {
                                        isMobile &&
                                        microskillData?.completion && microskillData?.Microskill?.showFeedback && microskillData?.feedbackSubmitted && (
                                            <div className={classes.feedbackConatiner}>
                                                <div className={classes.feedbackHeadline}>{t("Your Rating")}:</div>
                                                <div className={`${classes.centerFlex} ${classes.feedbackRatingConatiner}`}>
                                                    <div className={classes.feedbackRatingText}>{microskillData?.userFeedback} / 5</div>
                                                    <Rating value={microskillData?.userFeedback} emptyIcon={<StarIcon style={{ color: "#FFF" }} />} readOnly />
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        microskillData?.completion && microskillData?.Microskill?.showFeedback && !microskillData?.feedbackSubmitted && (
                                            <MicroskillFeedBack openFeedback={microskillFeedbackShow} setOpenFeedback={setMicroskillFeedbackShow} feedbackMicroskillId={microskillData?.microskillId} name={microskillData?.Microskill?.name} ratingValue={microskillRating} setRatingValue={setMicroskillRating} />
                                        )
                                    }

                                    {
                                        <Forms formId={formId} setFormId={setFormId} isDialog={true} openForm={openForm} setOpenForm={setOpenForm} isChallenge={true} isCompleted={false} microskillId={microskillId} challengeId={challengeId} />
                                    }
                                </ul>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default ChallengeList;