import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";
import constants from "../../../constants";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loaderBox: {
        height: '100%',
        width: '100%',
    },
    ilaRoot: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        transition: '0.7s',
        padding: '1rem',
        // borderRadius: '0.5rem',
        // backgroundColor: palette.ilaBg,
        // userSelect: 'none',
    },
    ilaBox: {
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: constants.ILA_GRID_INITIAL,
        transition: '500ms'
    },
    scoreBox: {
        textAlign: 'center',
        fontSize: '2rem',
        fontWeight: '900',
        display: 'grid',
        alignItems: 'center',
        alignContent: 'center',
        opacity: '0',
        transition: '500ms'
        // fontFamily: 'Montserrat'
    },
    scoreText: {
        fontSize: '3.5rem',
    },
    questionBox: {
        display: 'grid',
        gridTemplateColumns: '20% 60%'
    },
    correctIncorrectTextBox: {
        position: 'absolute',
        bottom: '-1rem'
    },
    fitbGameContainer: {
        display: 'grid',
        width: '80%',
        margin: 'auto',
        height: '100%',
        gridTemplateRows: '35% 65%',
        paddingTop: '2rem',
        alignItems: 'flex-start',
        justifyItems: 'center',
        transition: '0.3s',
        // alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            gridTemplateRows: '20% 80%',
        }
    },
    textBox: {
        border: '8px solid #6F41A7',
        borderRadius: '0.5rem',
        boxShadow: '0px 3px 6px #00000029',
        height: '100%',
        fontSize: '1.5rem',
        fontWeight: 700,
        letterSpacing: '6px',
        width: '100%'
    },
    jumbleWord: {
        display: 'flex',
        marginTop: '0.5rem',
        flexWrap: 'wrap',
        alignItems: 'stretch',
        height: '100%',
        justifyContent: 'center',
        overflow: 'auto',
        '& > *': {
            // flex: '0 1 2.8rem'
        },
        [theme.breakpoints.down("sm")]: {
            height: '80%',
        }
    },
    charBox: {
        padding: '0.5rem',
        flex: '0 1 10%',
        textAlign: 'center',
        [theme.breakpoints.down("md")]: {
            // flex: '0 1 15%',
        }
    },
    character: {
        // border: '5px solid #6F41A7',
        // boxShadow: '0px 3px 6px #00000029',
        height: 'max-content',
        // padding: '0.5rem',
        // height: '2.8em',
        // width: '2.8em',
        // aspectRatio: "1/1",
        // padding: '0.4rem 0.6rem',
        borderRadius: '0.5rem',
        // fontWeight: 700,
        textTransform: 'uppercase',
        // fontSize: '1.2em'
    },
    answerBtn: {
        height: '100%',
        // border: '5px solid #6F41A7 !important',
        boxShadow: '0px 3px 6px #00000029 !important',
        backgroundColor: '#6F41A7 !important',
        // height: 'max-content !important',
        borderRadius: '0.5rem !important',
        fontWeight: '700 !important',
        // padding: '0 0.8rem !important',
        letterSpacing: '0.3rem !important',
        textTransform: 'uppercase !important',
        // height: '2em !important',
        // fontSize: '1.2em'
    },
    charBtn: {
        padding: '0 !important',
        fontWeight: '700 !important',
        // height: '2em !important',
        // width: '100% !important',
        aspectRatio: '1/1',
        border: '3px solid #6F41A7 !important',
        backgroundColor: 'transparent !important',
        boxShadow: '0px 3px 6px #00000029 !important',
        fontSize: '1.2rem !important',
        minWidth: '2.35em !important',
        [theme.breakpoints.down("sm")]: {
            minWidth: '2.1em !important',
        }
    },
    btnBox: {
        // display: 'grid',
        // padding: '0rem',
        // gridTemplateColumns: '1fr 1fr',
        // '& >  *': {
        //     height: '100%'
        // }

        display: 'flex',
        padding: '0.5rem',
        textAlign: 'center',
        '& >  *': {
            flex: '1 1 50%',
            padding: '0 0.25rem'
        }
    },
    answerBtnBox: {
        display: 'flex'
    },
    highlight: {
        backgroundColor: '#6F41A7 !important',
    },
    jumbleWordBox: {
        // display: 'grid',
        // height: '100%',
        // alignContent: 'flex-start',
        overflow: 'hidden',
    }
}))

export default useStyles