import React from "react";

// Style
import useStyle from "./index.styles";
import GbaTypeMcq from "./gba-type-mcq";
import GbaTypeMtrr from "./gba-type-mtrr";
import GbaTypeMA from "./gba-type-ma";
import GbaTypeTF from "./gba-type-tf";
import GbaTypeSR from "./gba-type-sr";
import LaVoiceOverAudioPlayer from "../../../la-voiceover-audio-player";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";


const GbaInsights = (props) => {
  const { gameType, gameData, restartGame } = props;
  const designType = gameData?.designType;


  const { t, i18n } = useTranslation()
  const classes = useStyle(i18n);



  return (
    <div className={`${classes.answerContainer}`}>

      {(gameType === 'TF' && designType === 'TF_SoundRight') && <div className={classes.audioPlayerContainer}>
        <LaVoiceOverAudioPlayer voiceOverTrack={gameData?.Context?.context} autoPlay={true} loop={true} />
      </div>
      }

      < div className={classes.answerContainerHeader}>
        All Answers
      </div>

      {
        gameType === 'MCQ' && (
          <>
            <GbaTypeMcq gameData={gameData} />
          </>
        )
      }


      {
        gameType === 'MTRR' && (
          <>
            <GbaTypeMtrr gameData={gameData} />
          </>
        )
      }

      {
        gameType === 'MA' && (
          <>
            <GbaTypeMA gameData={gameData} />
          </>
        )
      }

      {
        (gameType === 'TF' && designType === 'TF_SoundRight') && (
          <>
            <GbaTypeSR gameData={gameData} />
          </>
        )
      }



      {
        (gameType === 'TF' && designType !== 'TF_SoundRight') && (
          <>
            <GbaTypeTF gameData={gameData} />
          </>
        )
      }

      <div className={classes.tryagainContainer}>
        <Button
          style={{ height: '3rem', fontSize: '1.5rem', marginBottom: '1rem' }}
          variant="contained"
          color="gbabtn"
          fullWidth
          size="small"
          sx={{
            fontSize: i18n.language === 'en' ? "2.5em !important" : "2em !important",
          }}
          className={`${classes.tryBtn}`}
          onClick={restartGame}>
          {t("TRY AGAIN")}
        </Button>

      </div>
    </div >

  )
}

export default GbaInsights;