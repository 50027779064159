import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

// mui
import Box from '@mui/material/Box';

// components
import Header from "../layout-elements/header";
import Header2 from "../layout-elements/header-2";
import SideDrawerContainer from "../layout-elements/side-drawer-container";
import BottomNavigation from "../layout-elements/bottom-navigation";

// redux
import { logout } from "../../redux/auth/auth.api";

// utils
import isEmpty from "../../utils/isEmpty";
import commonUtil from "../../utils/commonUtil";

// constants
import constants from "../../constants";
import ChristmasGiftAnimation from "../christmas-gift";

const Layout = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    // const isMobile = useSelector(state => state?.common?.isMobile);
    const isDrawerCollapsed = useSelector(state => state?.common?.isSideDrawerCollapsed);
    const {
        Component,
        withHeader = false,
        withHeader2 = false,
        withDrawer = false,
        withBottomNav = false,
        isProtected = false,
        showSearchHeader = true,
        showHeaderBackButton = true,
    } = props;
    const tokens = useSelector(state => state.auth.tokens);
    // const { isMobile } = useSelector(state => state.common);
    // const { isDarkMode, viewChristmasGiftAnimation } = useSelector(state => state.commonPersist);
    const isThirdPartyIntegration = useSelector(state => state.auth.isThirdPartyIntegration);
    // christmas gift animation    
    // const [showChristmasGift, setShowChristmasGift] = useState(false);

    if (
        !isEmpty(tokens?.accessToken)
        && commonUtil.validToken(tokens)
        && (location.pathname === "/" || location.pathname === "/login" || location.pathname === "/otp-login")
        && !isThirdPartyIntegration
    ) {
        const userType = localStorage.getItem('userType');
        const isOnlyManager = userType === constants.USER_TYPES.MANAGER;
        if (isOnlyManager && (location.pathname === "/" || location.pathname === "/login" || location.pathname === "/otp-login") && location.pathname !== "/performance") {
            // window.location.href = "/performance";
            navigate("/performance");
        } else {
            window.location.href = "/learn";
        }
    }
    else if (location.pathname === "/" && !commonUtil.validToken(tokens))
        window.location.href = "/login";


    // ORIGINAL CODE : 

    // if (!isEmpty(tokens?.accessToken)
    //     && commonUtil.validToken(tokens)
    //     && (location.pathname === "/" || location.pathname === "/login" || location.pathname === "/otp-login")
    //     && !isThirdPartyIntegration) {
    //     console.log("1");
    //     if (location.pathname === "/otp-login") {
    //         console.log("inside otp-login");
    //         // Ensure we do not redirect again if already on /otp-login
    //         // Do nothing here as we are already on the desired page
    //     } else {
    //         console.log("inside the else condition - learn page");
    //         // Redirect to learn page if the user is authenticated and not on otp-login page
    //         window.location.href = "/learn";
    //     }

    // } else if (location.pathname === "/" && !commonUtil.validToken(tokens)) {
    //     window.location.href = "/login";
    // }


    if (isProtected && !isThirdPartyIntegration) {
        if (isEmpty(tokens?.accessToken) || !commonUtil.validToken(tokens)) {
            dispatch(logout);
            // navigate("/login");
        }
    }

    const getDrawerStyle = () => {
        let styles = {
            marginLeft: withDrawer ? (isDrawerCollapsed ? constants.COLLAPSED_DRAWER_WIDTH : constants.DRAWER_WIDTH) : '0rem',
            // marginTop: (withHeader || withHeader2) && isMobile ? constants.HEADER_HEIGHT : '0rem',
            paddingBottom: withBottomNav ? constants.BOTTOM_NAV_HEIGHT : '0rem',
            transition: `margin-left ${constants.COLLAPSED_DRAWER_DURATION}ms ease-out`
            // paddingTop: '2rem',
        }
        if (withDrawer) {
            styles.marginLeft = { sm: `0rem`, md: `${(isDrawerCollapsed ? constants.COLLAPSED_DRAWER_WIDTH : constants.DRAWER_WIDTH)}` }
        }
        if (withBottomNav) {
            styles.paddingBottom = { sm: `${constants.BOTTOM_NAV_HEIGHT}`, xs: `${constants.BOTTOM_NAV_HEIGHT}`, md: `0rem` }
        }
        return styles
    }


    // useEffect(() => {
    //     if (window.location.href.includes("/learn")) {
    //         setTimeout(() => {
    //             setShowChristmasGift(true);
    //         }, 400)
    //     }
    // }, [window.location.href])

    return (
        <>
            {
                // !isMobile && showChristmasGift && ( // christmas gift animation    
                //     // // chrismas gift animation
                //     <ChristmasGiftAnimation wrapperPosition={'fixed'} containerWidth={"50%"} />
                // )
            }
            <div
            // style={{ // christmas gift animation    
            //     filter: !isMobile && viewChristmasGiftAnimation && showChristmasGift ? 'blur(5px)' : "",
            // }}
            >

                {withHeader && (<Header showSearchHeader={showSearchHeader} showHeaderBackButton={showHeaderBackButton} />)}
                {withHeader2 && (<Header2 showSearchHeader={showSearchHeader} showHeaderBackButton={showHeaderBackButton} />)}
                <div>
                    {withDrawer && (<SideDrawerContainer />)}
                    <Box sx={getDrawerStyle}>
                        <Component {...props} />
                    </Box>
                    {withBottomNav && (<BottomNavigation />)}
                </div>
            </div>

        </>
    )
}
export default Layout;


// useEffect(() => { // this code redirect back after visiting the page
//     const loginPages = ["/", "/login", "/otp-login"];
//     if (!isEmpty(tokens?.accessToken) && commonUtil.validToken(tokens)) {
//         if (loginPages.includes(location.pathname)) {
//             // if (location.pathname === "/" || location.pathname === "/login" || location.pathname === "/otp-login") {
//             window.location.href = "/learn";
//             return
//         }
//     } else if (location.pathname === "/" && !commonUtil.validToken(tokens)) {
//         window.location.href = "/login";
//         return
//     }
//     if (isProtected && !isThirdPartyIntegration) {
//         if (isEmpty(tokens?.accessToken) || !commonUtil.validToken(tokens)) {
//             dispatch(logout);
//             // navigate("/login");
//             return
//         }

//     }
// }, [location, tokens, isProtected, isThirdPartyIntegration]);