import { createContext, useRef, useState } from "react";

// components

// utils
import isEmpty from "../../utils/isEmpty";

const LearningAidContext = createContext();

export const LearningAidProvider = ({ children }) => {
    const [files, setFiles] = useState([]);
    const [fileType, setFileType] = useState('document');
    const [isFileViewerOpen, setIsFileViewerOpen] = useState(false);
    const [openDocInIframe, setOpenDocInIframe] = useState(false);
    const [isImageMagnify, setIsImageMagnify] = useState(false);
    // const [isVoiceoverAudioPlayer, setIsVoiceoverAudioPlayer] = useState(false) // la card voiceover audio state<play/pause>
    const scoreBoxRef = useRef();
    const scoreIncrementRef = useRef(0);
    const isClickEnabledRef = useRef(true);
    const scoreCounterTime = 800;
    const [isRoationIconView, setIsRotationIconView] = useState(false)
    const [isPdfViewRotate, setIsPdfViewRotate] = useState(false)

    const addFiles = (item) => {
        setFiles(isEmpty(item) ? [] : item);
    }

    const handleDocumentBtnClick = () => {
        if (openDocInIframe) {
            // iframe code here
            // let anchor = document.createElement("a")
            // anchor.setAttribute("href", files.path + "?external=true")
            // anchor.setAttribute("target", "_system")
            // anchor.click();

            window.open(files.path + "?external=true", "_system")
            return
        }
        setIsFileViewerOpen(true)
    }
    const values = {
        files,
        addFiles,
        isFileViewerOpen,
        setIsFileViewerOpen,
        openDocInIframe,
        setOpenDocInIframe,
        handleDocumentBtnClick,
        fileType, setFileType,
        isImageMagnify,
        setIsImageMagnify,
        scoreBoxRef,
        scoreCounterTime,
        scoreIncrementRef,
        isRoationIconView,
        setIsRotationIconView,
        isPdfViewRotate,
        setIsPdfViewRotate,
        isClickEnabledRef,
        // isVoiceoverAudioPlayer, setIsVoiceoverAudioPlayer
    }

    return (
        <LearningAidContext.Provider value={values}>
            {children}
        </LearningAidContext.Provider>
    )
}

export default LearningAidContext