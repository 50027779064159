import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: `500px !important`,
        width: '90%  !important',
        backgroundColor: 'white',
        boxShadow: '0 0 24px 5px rgba(0, 0, 0, 0.2)',
        border: 'none',
        padding: '0 1rem 1rem 1rem',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        outline: 'none !important',
    },
    boxHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem 0',
        color: theme.palette.black,
    },
    dialogTitle: {
        fontSize: '1.2rem !important',
        fontWeight: '600 !important',
        textAlign: 'center !important',
        padding: '0rem !important',
        margin: '0 !important',
    },

    dialogBody: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
    },
    inputWrapper: {
        display: 'grid',
        gridTemplateColumns: '8% 92%',
        // gridTemplateColumns: '10% 80% 10%',
        border: `1px solid rgba(0, 0, 0, 0.1)`,
        borderRadius: '5px',
        placeItems: 'center',
        padding: '0.5rem 0',
    },
    inputField: {   
        width: '100%',
        height: '100%',
        border: 'none',
        outline: 'none'
    },
    emailField: {
        padding: '0.5rem 0 0',
    },
    textareaField: {
        padding: '0.1rem 0  0.5rem 0',
        fontFamily: 'Monteserrat, sans-serif',
        fontSize: '1rem',
        color: theme.palette.black
    },
    closeBtn: {
        justifyContent: 'flex-end !important',
        padding: '0 !important',
    },
    btnWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '1rem',
    },
    copyBtn: {
        border: `1px solid ${theme.palette.orange} !important`,
        gap: '0.5rem',
        minWidth: '10rem !important',
    },
    sendBtn: {
        backgroundColor: `${theme.palette.orange} !important`,
        color: `${theme.palette.white} !important`,
        border: `1px solid ${theme.palette.orange} !important`,
        gap: '0.5rem',
        minWidth: '7rem !important',
    },
    emailError: {
        marginTop: '-1rem !important',
        color: `${theme.palette.ilaInCorrect1} !important`,
        fontSize: '0.8rem !important',
    },
    emailSentText: {
        fontSize: '1.5rem !important',
        transformOrigin: 'center center',
        animation: '$textAnimation 1s 0s 1 ease-in-out',
    },
    "@keyframes textAnimation": {
        "0%": {
            transform: 'scale(0)',
        },
        "80%": {
            transform: 'scale(1.2)',
        },
        "90%": {
            transform: 'scale(0.8)',
        },
        "100%": {
            transform: 'scale(1)',
        }
    }
    
}))

export default useStyles