import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    updatePolicy: {
        gap: '0.5em',
        padding: '0.5em',
        paddingRight: 'none',
        border: `3px solid ${palette.white}`,
        borderRadius: '0.8em',

        [theme.breakpoints.up('md')]: {
            gap: '0.5em',
            paddingRight: 'none',
            border: `2px solid ${palette.white}`,
            borderRadius: '1em',
            width: '60vw',
        },

    },
    viewBtn: {
        [theme.breakpoints.up('md')]: {
            width: '15%',
        },
    },
    updatePolicyText: {
        fontSize: '0.8em',
        fontWeight: '400',
        [theme.breakpoints.up('md')]: {
            fontSize: '1em',
            fontWeight: '600',
            width: '85%'
        },
    },
    policyDate: {
        whiteSpace: 'nowrap'
    }
}))

export default useStyles