import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { useContext } from "react";

// mui
import { CircularProgress } from "@mui/material";
import NavigationIcon from '@mui/icons-material/Navigation';

// components
import ScoreBox from "../score-box";

// redux
// import { getChallengeData } from "../../redux/microskill/microskill.api";

// utils
import isEmpty from "../../../utils/isEmpty";
// import commonUtil from "../../../utils/commonUtil";

// constants
import constants from "../../../constants";

// theme
import palette from "../../../theme/palette";

//context
import LearningAidContext from "../../../context/learningAidContext"

// styles
import useStyles from "./index.styles";
import { useTranslation } from "react-i18next";

const IlaMcqTwo = (props) => {
    const {
        data,
        prev,
        next,
        attemptedIds,
        ilaId,
        scoreListRef,
        addScore,
        maxScore,
        scoreValueRef,
        scoreRef,
        handleChallengeAttemptData,
        styles,
        SkipButton,
        isChallengeSubmitted,
        skipButtonDisplayTime,
        viewFullScreen,
        cardId
    } = props
    const classes = useStyles();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const { scoreBoxRef } = useContext(LearningAidContext)
    // const { microskillId, challengeId } = useParams();
    const [activeQindex, setActiveQindex] = useState(0);
    const [showTemplate, setShowTemplate] = useState(false);
    const [viewSkipBtn, setViewSkipBtn] = useState(false);
    const [componentMounted, setComponentMounted] = useState(true);
    const ilaRootRef = useRef();
    const ilaBoxRef = useRef();
    // const scoreBoxRef = useRef();
    const isCorrectRef = useRef(false);
    const currentTimeRef = useRef(Date.now());
    const incorrectAttemptsIdsRef = useRef([]);
    const mcqBoxRef = useRef();
    const isDragActive = useRef(true);

    const trackPadRef = useRef();
    const ballRef = useRef();
    const aimBoxRef = useRef();
    const navigationRef = useRef();
    const answerCardRef = useRef();
    const questionTextRef = useRef();
    const answerTextRef = useRef();
    const guideTextRef = useRef();

    const [ballPos, setBallPos] = useState(null)
    const [navIconPosition, setNavIconPosition] = useState(null)
    const [tailHeight, setTailHeight] = useState(100)
    const [cardFocused, setCardFocused] = useState(null)
    const [breakingText, setBreakingText] = useState(<br />)

    let [angle, setAngle] = useState(0)

    let currentX;
    let currentY;
    let initialX;
    let initialY;
    let xOffset = 0;
    let yOffset = 0;

    const { t } = useTranslation()

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));
    const bufferTime = 400

    useEffect(() => {
        return () => {
            setComponentMounted(false)
        }
    }, [])

    useEffect(() => {
        if (!isChallengeSubmitted || !componentMounted) return
        setTimeout(() => {
            setViewSkipBtn(true)
        }, skipButtonDisplayTime);
    }, [isChallengeSubmitted]);


    // <=== this is to handle ui while fullscreen ====>
    useEffect(() => {
        if (!isMobile) {
            if (viewFullScreen === true) {
                // console.log("viewFullScreen")
                aimBoxRef.current.style = `
                    width: 21rem;
                    height: 14rem;
                `

                setBreakingText('')
                setNavIconPosition('8.8rem')
                setBallPos('9rem')
                // guideTextRef.current.style = `
                //     top: 10%;
                // `
                document.getElementById("bottomNavigationBox").style = "bottom: 1%;"

            } else {
                aimBoxRef.current.style = ``
                setBreakingText(<br />)
                setNavIconPosition('4.7rem')
                setBallPos("5.3rem")
                document.getElementById("bottomNavigationBox").style = ""
            }
        } else {
            if (aimBoxRef.current) {
                setNavIconPosition('8rem')
                setBallPos('9rem')
                aimBoxRef.current.style = `
                    width: 20rem;
                    height: 12rem;
                `
            }
        }
    }, [isMobile, viewFullScreen])

    // <=== this is to handle mousedown and touchstart ====>
    function dragStart(e) {
        if (!isDragActive.current) return;

        if (e.type === "touchstart") {
            initialX = e.touches[0].clientX - xOffset;
            initialY = e.touches[0].clientY - yOffset;
            trackPadRef.current?.addEventListener('touchmove', dragging, false);
        } else {
            initialX = e.clientX - xOffset;
            initialY = e.clientY - yOffset;
            trackPadRef.current?.addEventListener('mousemove', dragging, false);
        }
    }

    // <=== this is to handle mousemove and touchmove ====>
    const dragging = useCallback((e) => {

        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        let controller_width = trackPadRef.current?.clientWidth
        let controller_height = trackPadRef.current?.clientHeight
        let client_x, client_y;

        if (e.type === "touchmove") {
            currentX = e.touches[0].clientX - initialX;
            currentY = e.touches[0].clientY - initialY;

            client_x = e.touches[0].clientX - (screenWidth - controller_width)
            client_y = e.touches[0].clientY - (screenHeight - controller_height)
        } else {
            currentX = e.clientX - initialX;
            currentY = e.clientY - initialY;

            client_x = e.clientX - (screenWidth - controller_width)
            client_y = e.clientY - (screenHeight - controller_height)
        }
        xOffset = currentX;
        yOffset = currentY;

        let center_x = controller_width / 2
        let center_y = controller_height

        // to find angle between cursor position and center point of controller
        let angle = Math.atan2(client_y - center_y, client_x - center_x) * (180 / Math.PI)

        let accurateAngle;
        if (isMobile) {
            accurateAngle = angle + 90
        } else {
            accurateAngle = angle + 110
        }

        answerCardRef.current = document.querySelectorAll('.answerCard')
        let card_width = answerCardRef.current[0].clientWidth + 16

        // //<===it will calculate how far our pointer can rotate with respect to width of answer box ===>
        let maxAngle = Math.atan2(card_width * 2, controller_height) * (180 / Math.PI)
        // console.log(maxAngle)

        setTranslate(accurateAngle, controller_height, card_width, maxAngle)
    }, [])

    // <========= dragEnd, that will trigger on mouseup and touchend =======>
    async function dragEnd(e) {

        if (e.type === "touchstart") {
            initialX = e.touches[0].clientX - xOffset;
            initialY = e.touches[0].clientY - yOffset;
            trackPadRef.current?.removeEventListener("touchmove", dragging, false)

        } else {
            initialX = e.clientX - xOffset;
            initialY = e.clientY - yOffset;
            trackPadRef.current?.removeEventListener("mousemove", dragging, false)
        }
        shootTheBall()
    }
    // <========= dragEnd ends here =======>


    // <========= setTranslate, that rotates the arrow icon and sets the height of arrow tail  =======>
    function setTranslate(accurateAngle, controller_height, card_width, maxAngle) {

        //rotate navigator on hover
        // limiting rotation of navigator
        if (accurateAngle < -1 * maxAngle || accurateAngle > maxAngle) {
            return;
        } else {
            setAngle(accurateAngle);
        }

        // get horizontal distance from top center of controller  
        let secValue = 1 / Math.cos(accurateAngle * Math.PI / 180);
        let tanValue = Math.tan(accurateAngle * Math.PI / 180)
        let distance = tanValue * controller_height

        focusTheCard(distance, card_width)

        if (secValue < 0) {
            let height = secValue * (-1) * controller_height
            setTailHeight(height)
        } else {
            let height = secValue * controller_height
            setTailHeight(height)
        }
    }
    // <========= setTranslate ends here =======>


    // <========= shootTheBall, this is to shoot the ball, after mouseup and touchend =======>
    const shootTheBall = async () => {
        if (cardFocused && cardFocused !== "out-of-range") {
            ballRef.current.style.visibility = "visible"
            setBallPos('90%')

            let cardNum = parseInt(cardFocused.split("-")[1])
            let styling
            if (isMobile) {
                styling = `
                    transform: scale(1.18, 1.11) translateY(-0.5rem);
                    z-index: 10;
                    box-shadow: ${constants.ILA_OPTION_BOX_SHADOW}    
                `
            } else {
                styling = `
                    transform: scale(1.15) translateY(-0.6rem);
                    z-index: 10;
                    box-shadow: ${constants.ILA_OPTION_BOX_SHADOW}    
                `
            }

            // if the ball shooted, trackpad will not listen any events untill next question
            trackPadRef.current.style = `pointer-events: none;`

            //move the card up when ball reaches to it
            // setTimeout(() => {
            // }, 400)
            await sleep(bufferTime);
            answerCardRef.current.forEach((element, index) => {
                element.style = ""
                if (index === cardNum - 1) {
                    element.style = styling
                    checkAnswer(element)
                    handleOptionShooted()
                }
            });
        }
    }
    // <========= shootTheBall ends here =======>


    // <========= checkAnswer, that will check the hovered answer after shoot and make it red or green =======>
    function checkAnswer(element) {
        let answer = element.getAttribute("data-iscurrect")

        // <=== makes card or red on behalf of answer ===>
        if (answer === 'true') {
            // element.style.backgroundColor = "#238817"
            element.style.backgroundColor = palette.ilaCorrect1
            updateCardResposne(t("CORRECT") + "!", 0.6, 0)

        } else {
            // element.style.backgroundColor = "#C6211B"
            element.style.backgroundColor = palette.ilaInCorrect1
            updateCardResposne(t("WRONG") + "!", 0.6, 0)
        }
    }
    // <========= checkAnswer ends here =======>


    // <====== this is to update the card with response after hit =====>
    function updateCardResposne(response, font_size, padding_top) {
        // response will be the text, font_size for answer text and padding_top for answer card
        let cardNum = parseInt(cardFocused.split("-")[1])
        answerCardRef.current.forEach((element, index) => {
            if (index === cardNum - 1) {
                element.firstElementChild.innerHTML = response
                element.lastElementChild.style = `
                    font-size: ${font_size}rem; 
                    padding-top: ${padding_top}rem;
                `
            }
        });
    }
    // <====== updateCardResponse ends here =====>


    // <====== focusTheCard, that set current focused card by determining distance from center to card to card ======>
    function focusTheCard(distance, card_width) {

        // <======= ("first card") =========>
        if (distance < (-1 * (card_width)) && distance > (-2 * card_width)) {
            setCardFocused('card-1')
        }

        // <======= ("second card") =========>
        if (distance < 0 && distance > (-1 * (card_width))) {
            setCardFocused('card-2')
        }

        // <======= ("third card") =========>
        if (distance > 0 && distance < card_width) {
            setCardFocused('card-3')
        }

        // <======= ("fourth card") =========>
        if (distance > card_width && distance < (2 * card_width)) {
            setCardFocused('card-4')
        }

        // <======= ("no card / out of range") =========>
        if ((distance < (-2 * (card_width))) || (distance > (2 * (card_width)))) {
            setCardFocused('out-of-range')
        }

    }
    // <========= focusTheCard ends here =======>


    // <========= useEffect that will focus the card based on cardSelection =======>
    useEffect(() => {
        if (cardFocused && cardFocused !== "out-of-range") {
            let styling
            if (isMobile) {
                styling = `
                    transform: scale(1.18, 1.11); 
                    outline: 2px solid #fff; 
                    box-shadow: 0px 21px 65px -15px rgba(0,0,0,0.75);
                `
            } else {
                styling = `
                    transform: scale(1.15); 
                    outline: 2px solid #fff;
                    z-index: 10;
                    box-shadow: 0px 21px 65px -15px rgba(0,0,0,0.75);
                `
            }

            let cardNum = parseInt(cardFocused.split("-")[1])
            answerCardRef.current.forEach((element, index) => {
                element.style = ""
                if (index === cardNum - 1) {
                    element.style = styling
                }
            });

        } else {
            answerCardRef?.current?.forEach(element => {
                element.style = ""
            });
        }
    }, [cardFocused])
    // <========= useEffect (actual card focusing) ends here =======>


    // <===== reset all things after a play ======>
    function reset() {
        if (!isEmpty(ballRef.current)) ballRef.current.style.visibility = 'hidden'
        if (!isEmpty(scoreBoxRef.current)) scoreBoxRef.current.style = null;
        if (!isEmpty(ilaBoxRef.current)) ilaBoxRef.current.style = null;
        if (!isEmpty(aimBoxRef.current)) aimBoxRef.current.style.visibility = "visible"

        setShowTemplate(false)
        if (isMobile) {
            setBallPos('9rem')
        } else {
            if (viewFullScreen) {
                setBallPos('9rem')
            } else {
                setBallPos('5.3rem')
            }
        }
        setAngle('0deg')
        setTailHeight(100)
        setCardFocused(null)
        answerCardRef.current.forEach(elem => {
            elem.style = ""
            elem.firstElementChild.innerHTML = ""
        })
        updateCardResposne("", 0.8, 0)
        trackPadRef.current.style = `pointer-events: all;`
    }
    // <===== reset ends here ======>


    // const handleOptionShooted = async (item, index) => {
    const handleOptionShooted = async () => {
        let showScore = data[activeQindex].score > 0;
        let item = data[activeQindex].answers[parseInt(cardFocused.split("-")[1]) - 1]

        let indexToSplice = attemptedIds.current.indexOf(ilaId);
        if (indexToSplice !== -1) {
            attemptedIds.current.splice(indexToSplice, 1);
        }

        // handle score logic
        let currentTime = Date.now();
        if (item.isCorrect) {
            // console.log("item:", data[activeQindex]);
            isCorrectRef.current = true;
            scoreListRef.current.add(`${data[activeQindex].id}-${data[activeQindex].score}`);

            handleChallengeAttemptData({
                qId: item.id,
                cardId: cardId,
                t: Math.round((currentTime - currentTimeRef.current) / 1000),
                aId: item.id,
                isCorrect: true
            });
        } else {
            isCorrectRef.current = false;
            handleChallengeAttemptData({
                qId: item.id,
                cardId: cardId,
                t: Math.round((currentTime - currentTimeRef.current) / 1000),
                aId: item.id,
                isCorrect: false
            });
        }
        currentTimeRef.current = currentTime;

        // await sleep(0.2 * bufferTime);
        ballRef.current.style.visibility = "hidden";
        await sleep(1.5 * bufferTime);
        aimBoxRef.current.style.visibility = "hidden";

        if (showScore) {
            if (isMobile) {
                ilaBoxRef.current.style.gridTemplateRows = constants.ILA_GRID_FINAL_MOBILE;
            } else {
                ilaBoxRef.current.style.gridTemplateRows = constants.ILA_GRID_FINAL;
            }
            await addScore(data[activeQindex]?.score ? data[activeQindex].score : 0, item.isCorrect);
        }

        setShowTemplate(true);

        if (data.length - 1 > activeQindex) {
            if (data[activeQindex].mandatory && !item.isCorrect) {
                setShowTemplate(true)
                await sleep(bufferTime * 5)
                reset()
            } // INCORRECT 
            else {
                await sleep(bufferTime * 5)
                setActiveQindex(prev => prev + 1)
                reset()
            }; // CORRECT
        } else {
            await sleep(bufferTime * 5)
            if (data[activeQindex].mandatory && !item.isCorrect) {
                return reset();
            }
            attemptedIds.current.push(ilaId)
            next();
        }
    }

    return (
        <>
            {
                isEmpty(data) ? (
                    <div className={`${classes.loaderBox} ${classes.centerFlex}`}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div className={classes.ilaRoot} ref={ilaRootRef}>
                        <div className={classes.ilaBox} ref={ilaBoxRef}>
                            <div className={styles.questionHead}>{`Q${data[activeQindex].questionNo}`}</div>
                            <div className={`${classes.question}  ${classes.centerFlex}`} ref={questionTextRef}>
                                {data[activeQindex].question}
                            </div>
                            <div className={classes.mcqRootContainer}>
                                <div className={classes.mcqBox} ref={mcqBoxRef}>
                                    {
                                        data[activeQindex].answers.map((item, index) => (
                                            <div className={`answerCard ${classes.cardBox}`} key={item.id} data-iscurrect={item.isCorrect}>
                                                <div className={classes.responseTypeText}></div>
                                                <div className={`${classes.card} ${classes.centerFlex} `} ref={answerTextRef}>
                                                    {
                                                        item.answer
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div
                                    /* ////////// disableSelection css is written in app.css, to prevent screen flickering while tapping on trackpad in mobile devices   ///////////  */
                                    className={`disableSelection ${classes.aimContainer} ${classes.centerFlex}`}
                                    ref={trackPadRef}
                                    onMouseDown={dragStart}
                                    onMouseUp={dragEnd}
                                    onTouchStart={dragStart}
                                    onTouchEnd={dragEnd}
                                >
                                    <div className={classes.aimBox} ref={aimBoxRef}>
                                        {/* <img className={classes.aimBoxArc} src="/images/icons/pointer-arc.png" alt="arc" /> */}
                                        <div className={classes.navigation}
                                            ref={navigationRef}
                                            style={{
                                                '--angle': `${angle}deg`,
                                                '--height': `${tailHeight}px`
                                            }}

                                        >
                                            <NavigationIcon
                                                className={classes.navigationIcon}
                                                style={{ '--posFromBottom': navIconPosition }}
                                            />
                                            <div
                                                className={classes.ball}
                                                ref={ballRef}
                                                style={{
                                                    visibility: 'hidden',
                                                    '--top': ballPos,
                                                    '--transisnTime': `${bufferTime}ms`
                                                }}
                                            ></div>
                                        </div>
                                        <p className={classes.guideText} ref={guideTextRef}>
                                            Drag &amp; aim {breakingText} to shoot
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ScoreBox scoreBoxRef={scoreBoxRef} scoreValueRef={scoreValueRef} maxScore={maxScore} score={scoreRef.current} />
                            </div>

                            {
                                viewSkipBtn && (
                                    <div> <SkipButton styling={{ paddingRight: '0.5rem' }} /> </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default IlaMcqTwo;