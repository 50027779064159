import React, { useState } from "react";
import { useSelector } from "react-redux";

// mui
import CircularProgress from '@mui/material/CircularProgress';

// utils
import isEmpty from "../../utils/isEmpty";

// constants
import constants from "../../constants";

// styles
import useStyles from "./index.styles";
import { useEffect } from "react";
// import PDFviewerSafari from "../pdf-viewer-safari";
import { isNativeApp } from "../../utils/commonUtil";

let docViewerUrl1;

if (!isNativeApp()) {
    docViewerUrl1 = `https://view.officeapps.live.com/op/embed.aspx?src=`;
} else {
    docViewerUrl1 = `https://drive.google.com/viewer?embedded=true&hl=en-US&url=`;
}

// const docViewerUrl2 = `https://drive.google.com/viewerng/viewer?url=`;
// const docViewerUrl1 = `https://view.officeapps.live.com/op/view.aspx?src=`;
// const docViewerUrl1 = `https://drive.google.com/viewer?embedded=true&hl=en-US&url=`;

const docViewerUrl2 = `https://docs.google.com/viewer?embedded=true&url=`;
// const docViewerUrl3 = `https://view.officeapps.live.com/op/embed.aspx?src=`;


const DocViewer = (props) => {
    const { docData, setShareBtnBisible } = props
    const classes = useStyles();
    const [isIframeLoaded, setIsIframeLoaded] = useState(false);
    const isMobile = useSelector(state => state?.common?.isMobile);
    const isRotatedMobile = useSelector(state => state?.common.isRotatedMobile)

    // const iframeRef = React.useRef()
    const iframeContainerRef = React.useRef()

    const handleIframeLoad = () => {
        setIsIframeLoaded(true);
        setShareBtnBisible(true)
    }

    useEffect(() => {
        // console.log(isRotatedMobile)
        if (isMobile && iframeContainerRef.current) {
            if (isRotatedMobile === true) {
                iframeContainerRef.current.style = `
                    height: 100vw;
                    display: block;
                    width: ${constants.FULL_HEIGHT_MOBILE.height};
                    transform-origin: bottom left;
                    transform: translate(0%, -100%) rotate(90deg);
                `
            } else {
                iframeContainerRef.current.style = `
                    display: ${isIframeLoaded ? 'block' : 'none'};
                    height: 100%;
                `
            }
        }

    }, [isRotatedMobile, isIframeLoaded, isMobile])

    return (
        <div className={classes.docViewerRoot}
        // style={constants.FULL_HEIGHT}
        >
            {/* {
                isIframeLoaded && from !== 'ila' && (
                    <div className={classes.downloadBox}>
                        <a href={`${docData.path}`} download target="_blank">
                            <img src={"/images/icons/download_orange.png"} alt="icon" />
                        </a>
                    </div>
                )
            } */}
            {
                !isEmpty(docData) && (
                    <div
                        ref={iframeContainerRef}
                        style={{ display: isIframeLoaded ? 'block' : 'none', height: '100%', position: 'relative' }}
                    >
                        {
                            // isMobile ? (
                            <iframe title="document" src={`${docData.path.includes(".pdf") ? docViewerUrl2 : docViewerUrl1}${docData.path}`}
                                id="doc" width='100%' frameBorder='0' style={{ height: '100%' }} onLoad={() => {
                                    console.log("iframe loaded");
                                    handleIframeLoad();
                                }}>
                            </iframe>
                            // ) : (
                            // (docData.path.includes('.doc') || docData.path.includes('.pdf')) ? (
                            //     docData.path.includes('.doc') ? (
                            //         <iframe src={`${docViewerUrl1}${docData.path}`}
                            //             // ref={iframeRef}
                            //             id="iframeDoc"
                            //             width='100%' frameBorder='0'
                            //             onLoad={handleIframeLoad}
                            //             allowFullScreen="allowFullScreen"
                            //         >
                            //         </iframe>
                            //     ) : (
                            //         <PDFviewerSafari docData={docData} from={from} onLoadHandler={handleIframeLoad} />
                            //     )
                            // ) : (
                            // <iframe src={`${docViewerUrl3}${docData.path}`}
                            //     id="doc" width='100%' frameBorder='0'
                            //     // ref={iframeRef}
                            //     onLoad={handleIframeLoad}
                            // >
                            // </iframe>
                            // )
                            // )
                        }
                        {/* {
                            isMobile && docData.path.includes('.docx') ? (
                                <iframe src={`https://docs.google.com/viewer?embedded=true&url=${docData.path}`}
                                    width='100%' frameBorder='0' style={constants.FULL_HEIGHT} onLoad={handleIframeLoad} >
                                </iframe>
                            ) : (
                                <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${docData.path}`}
                                    id="doc" width='100%' frameBorder='0' style={constants.FULL_HEIGHT} onLoad={handleIframeLoad}>
                                </iframe>
                            )
                        } */}

                        {/* {
                            docData.path.includes('.docx') && isMobile && (
                                <iframe src={`https://docs.google.com/viewer?embedded=true&url=${docData.path}`}
                                    width='100%' frameBorder='0' style={constants.FULL_HEIGHT} onLoad={handleIframeLoad} >
                                </iframe>
                            )
                        }
                        {
                            docData.path.includes('.xlsx') && !isMobile && (
                                <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${docData.path}`}
                                    id="doc" width='100%' frameBorder='0' style={constants.FULL_HEIGHT} onLoad={handleIframeLoad}>
                                </iframe>
                            )
                        } */}


                        {/* <iframe src={`https://onedrive.live-int.com/embed.aspx?src=${docData.path}`} width='100%' height='565px' frameborder='0'> </iframe> */}
                    </div>
                )
            }
            {
                !isIframeLoaded && (
                    <div style={constants.FULL_HEIGHT_CENTER}>
                        <CircularProgress />
                    </div>
                )
            }
        </div >
    )
}

export default DocViewer;