import React from "react";

// Style
import useStyle from "./index.styles";
import { useTranslation } from "react-i18next";

const GbaTypeMcq = (props) => {
    const { gameData } = props;

    const { t, i18n } = useTranslation();
    const classes = useStyle();

    return (
        <div className={`${classes.mcqContainer} scrollbar`}>

            {gameData?.questions.map((question, index) => (

                <div key={question.id} className={classes.questionContainer}>
                    <h4 className={classes.question}>
                        Q{index + 1}. {question.question}
                    </h4>

                    <div className={classes.answerContainer}>
                        {question.answers.map((answer) => (
                            answer.isCorrect ? (
                                <div key={answer.id} className={classes.answer}>
                                    <h4 className={classes.answerText}>
                                        A{index + 1}. {answer.answer}</h4>
                                </div>
                            ) : null
                        ))}
                    </div>
                </div>
            ))}

        </div>

    )
}

export default GbaTypeMcq;



