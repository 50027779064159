import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",

        "& > *": {
            cursor: "pointer",
            filter: ({ from }) => from === 'ila' ? 'brightness(0.2) saturate(50%)' : 'none',
        }
    }
}));

export default useStyle;
