import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    root: {
        position: 'relative'
    },
    learnContainer: {
        paddingTop: '1rem',
        paddingLeft: '1rem',
        color: theme.palette.fontColor,
        [theme.breakpoints.down('sm')]: {
            padding: '0.8rem',
            background: theme.palette.mobileMainContainerBg,
        },
    },
    salutationText: {
        fontWeight: 'bold',
        fontSize: '2.5rem',
        color: theme.palette.fontColor,
        margin: '2rem 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
        },
    },
    // classificationText: {
    //     fontWeight: 'bold',
    //     fontSize: '1.5rem',
    //     margin: '1rem 0',
    //     padding: '0 0.4rem',
    //     [theme.breakpoints.down('sm')]: {
    //         fontSize: '1.2rem',
    //     },
    // },
    loginBox: {
        [theme.breakpoints.up('sm')]: {
            width: '75%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    divider: {
        borderTop: '1px solid #808080a3',
        width: '98%'
    },
    toggleSwitchBox: {
        position: 'absolute',
        right: '1rem',
        marginTop: '0.9rem'
    },
    toggleSwitchText: {
        fontWeight: 'bold',
        fontSize: '1.2rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    noDataText: {
        height: '300px',
        fontWeight: 500,
        fontSize: '1.5rem'
    },

    // chrismas gift animation
    bgTransparent: {
        backgroundColor: 'transparent !important'
    },

    giftAnimeWrapper: {
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        zIndex: '100',
        // top: `-${constants.HEADER_HEIGHT}`,
        top: `0`,
        left: '0',
        transition: 'visibility 0.3s ease-in-out, transform 0.3s ease-in-out',
    },
    giftAnimeWrapperOverlay: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        zIndex: '99',
        top: '0',
        left: '0',
        backgroundColor: 'rgba(0,0,0,0.9)',
    },
    giftAnimeContainer: {
        background: theme.palette.white,
        width: '50%',
        height: 'max-content',

        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '101',

        padding: '1rem',
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '1rem',
    },
    giftAnimeTitle: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: theme.palette.errorColor,
        textAlign: 'center'
    },
    giftAnimeBody: {
        fontSize: '0.9rem',
        color: theme.palette.black,
        textAlign: 'center'
    },
    companySplashScreen: {
        position: 'fixed',
        inset: '0 0 0 0',
        zIndex: '9999',
    },
    companyLoaderImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover'
    }
}))

export default useStyles