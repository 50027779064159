import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

// styles
import "./index.styles.css";


const Shimmer = (props) => {
    const { data } = props;
    const classes = useStyles();
    const bgColor = () => {
        if (data === "1") {
            return "#fbfbdb";
        } else if (data === "2") {
            return "#efdbfb";
        } else if (data === "3") {
            return "#ffffff";
        } else if (data === "4") {
            return "#dbfbe6";
        } else {
            return "#dbfbe6";
        }
    };

    return (
        <div>
            {/* <div className={classes.heading}></div> */}
            <div className={classes.shimmerContainer}>
                <div
                    className={clsx("news-card-shimmer", "shine")}
                    style={{
                        background: bgColor(),
                        backgroundImage: `linear-gradient(to right, ${bgColor()} 0%, #ffffff 20%, ${bgColor()} 40%, ${bgColor()} 100%)`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "800px 400px",
                        position: "relative",
                    }}
                >
                    {/* <a className="news-card__card-link"></a> */}
                    <div className="news-card__logo-placeholder">
                        <img
                            src="/images/sample-image/master_o_logo_placeholder.png"
                            width="45"
                            height="45"
                            alt="placeholder"
                        />
                    </div>
                    <div className="news-card__image-shimmer"></div>
                    <div className="news-card__text-wrapper-shimmer">
                        <div className="news-card-logo-title-shimmer">
                            <p className="news-card__title-shimmer shine"></p>
                            <p className="news-card__title-shimmer-bottom shine"></p>
                        </div>
                        <div className="news-card__post-date-shimmer">
                            <p className="news-card__title-shimmer-visibility shine"></p>
                            <div style={{ width: "1%" }}></div>
                            <p className="news-card__title-shimmer-like shine"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Shimmer;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    shimmerContainer: {
        width: "100%",
        flexGrow: 1,
        [theme.breakpoints.down("md")]: {
            width: "100%",
            flexGrow: 1,
        },
    },
    heading: {
        fontWeight: "bold",
        marginTop: "0px",
        fontSize: "18px",
        color: "white",
        marginLeft: "0.5rem",
        marginBottom: "1.2rem",
        width: "45%",
        height: "10px",
        backgroundImage:
            "linear-gradient(to right,rgba(96, 96, 96, 0) 0%,#ffffff 20%,rgba(96, 96, 96, 0) 40%,rgba(96, 96, 96, 0) 100%)",
        background: "lightgrey",
        borderRadius: "1px",
        backgroundSize: "800px 400px",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down("mdDown")]: {
            marginLeft: "0.5rem",
        },
    }
}))
