import { makeStyles } from "@mui/styles";

const cardPadding = '0.6rem';

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    microskillListMain: {
        width: '100%',
        height: '160px',
        borderRadius: '12px',
        margin: '1.5rem 0',
        position: 'relative',
        padding: '0 0.6rem',
        [theme.breakpoints.down("sm")]: {
            height: '120px',
            borderRadius: '4px',
            margin: '1rem 0',
        },
    },
    microskillListCardRoot: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        borderRadius: '12px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 85%',
        backgroundPosition: 'center',
        padding: '1rem 2rem',
        color: '#FFF',
        cursor: 'pointer',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.down("sm")]: {
            padding: '0.8rem 1rem 0.7rem 1rem',
            backgroundPosition: 'center',
            height: '100%',
            gap: '0.4rem',
            borderRadius: '5px',
        },
    },
    cardName: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '1.5em',
        fontWeight: '600',
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.9em',
        }
    },
    scoreBox: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '1em',
        fontWeight: '500',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.9rem',
        }
    },
    overlayBox: {
        background: 'linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0.05) 46%)',
        inset: '0 0 0 0',
        position: 'absolute',

    },
    cardDetailsBox: {
        display: 'grid',
        alignContent: 'space-around',
        justifyItems: 'flex-start',
        height: '100%',
        width: '100%',
        padding: '1rem 1.5rem',
        color: '#FFF',
        gap: '0.2em',
        position: 'relative',
        alignContent: 'flex-end',
        [theme.breakpoints.down("sm")]: {
            padding: '0.6rem 1rem',
        }
    },
    score: {
        fontSize: '1em',
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.7em',
        }
    },
    brandImage: {
        position: 'absolute',
        bottom: '15%',
        right: '4%',
    },
    bannerBox: {
        position: 'absolute',
        left: '0',
        top: '10%',
        // minWidth: '40%',
        height: '30px',
        zIndex: 2,
        // [theme.breakpoints.down('sm')]: {
        //     height: '30px',
        // },
    },
    bannerPart1: {
        height: '100%',
        width: cardPadding,
        background: 'green',
        zIndex: '1',
        position: 'absolute',
        transform: 'skewY(30deg)',
        top: '10%',
        // [theme.breakpoints.down('sm')]: {
        //     top: '10%',
        //     height: '85%',
        // },
    },
    bannerPart2: {
        height: '100%',
        textTransform: 'uppercase',
        fontSize: '0.8rem',
        padding: '0.3rem',
        fontWeight: 600,
        fontFamily: 'Open sans',
        boxShadow: '0px 2px 6px -3px #000',
        // background: 'pink',
        zIndex: '2',
        position: 'absolute',
        // width: '100%',
        borderRadius: '0 2px 2px 0',
        whiteSpace: 'nowrap'
    },
    launchTypeText: {
        paddingLeft: '0.3rem',
        color: theme.palette.white,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7rem',
        },
    },
}));

export default useStyles;