import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    productComparisonRoot: {
        height: '100%',
    },
    productComparisonHeader: {
        backgroundColor: `${theme.palette.voilet}`,
        position: 'sticky',
        top: 0,
        left: 0,
        display: 'grid',
        alignItems: 'center',
        width: '100%',
        transition: '.6s',
        zIndex: '2',
        gridTemplateColumns: '15% 85%',
        padding: theme.spacing(0, 2),
    },
    backButton: {
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        background: 'rgba(255, 255, 255, 0.1)',
        cursor: 'pointer',
    },
    headerTitle: {
        justifyContent: 'flex-start',
        fontSize: '1rem',
        fontWeight: '600',
    },

    statementBox: {
        padding: theme.spacing(2, 1),
        fontSize: '1rem',
        fontWeight: 600,
        listStyle: 'none',
    },

    statementText: {
        display: 'inline-block',
        width: '100%',
        color: theme.palette.fontColor,
        fontWeight: 400,
        lineHeight: '1.8',
    },

    productComparisonBody: {
        backgroundColor: theme.palette.darkVoilet2,
        width: '100vw',
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
    },
    container: {
        height: '100%',
        maxHeight: '100%',
        width: '100%',
        maxWidth: '100%',
        overflow: 'auto',
    }
}))

export default useStyles;