import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";
import constants from "../../../constants";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loaderBox: {
        height: '100%',
        width: '100%',
    },
    ilaRoot: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        transition: '0.7s',
        padding: '1rem 0 0 0',
    },
    ilaBox: {
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: constants.ILA_GRID_INITIAL,
        transition: '500ms',
        [theme.breakpoints.down("md")]: {
            gridTemplateRows: constants.ILA_GRID_INITIAL_MOBILE,
        },
    },
    questionHead: {
        marginBottom: '0.5rem',
    },
    question: {
        position: 'relative',
        fontSize: '1.8em',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '0 1rem',

        [theme.breakpoints.down("md")]: {
            fontSize: '1.1rem',
            fontWeight: '600',
        }
    },
    mcqRootContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        position: 'relative',
        fontFamily: 'Roboto, sans-serif',
    },
    mcqBox: {

        margin: 'auto',
        position: 'relative',

        [theme.breakpoints.up("md")]: {
            height: '90%',
            aspectRatio: '1/1',
        },
        [theme.breakpoints.down("md")]: {
            // aspectRatio: '1/1',
            // height: 'auto',
            height: '100%',
            width: '100%'
        },

    },
    answersContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    cardBox: {
        overflow: 'hidden',
        transition: 'all 0.3s linear',
        position: 'absolute',
        zIndex: '5',
        fontSize: '0.8rem',
        // wordBreak: 'break-word',
        // hyphens: 'auto',
        textAlign: 'center',

        overflowWrap: 'anywhere',
        // hyphens: 'auto',

        '-webkit-user-select': 'none', /* Safari */
        '-ms-user-select': 'none', /* IE 10 and IE 11 */
        userSelect: 'none', /* Standard syntax */

        cursor: 'pointer',
        [theme.breakpoints.up("md")]: {
            '&:hover': {
                color: '#EEF600'
            },
        },

        '&:nth-child(1)': {
            //top option
            top: '0',
            left: '50%',
            transform: 'translateX(-50%)',
            width: 'calc(100% - 40%)',
            [theme.breakpoints.down("md")]: {
                width: 'calc(100% - 50%)',
                height: 'calc((100% - 50% - 5% ) / 2)',
            },
        },
        '&:nth-child(2)': {
            //right option
            top: '50%',
            right: '0%',
            width: 'calc(100% - 50%)',
            [theme.breakpoints.down("md")]: {
                transform: 'translateY(-50%)',
                width: 'calc((100% - 50% - 5%)/2)',
                transformOrigin: 'center right',
            },
            [theme.breakpoints.up("md")]: {
                transform: 'translate(70%, -50%)',
            },
        },
        '&:nth-child(3)': {
            //bottom option
            left: '50%',
            bottom: '0%',
            transform: 'translateX(-50%)',
            width: 'calc(100% - 40%)',
            [theme.breakpoints.down("md")]: {
                width: 'calc(100% - 50% - 2%)',
                height: 'calc((100% - 50% - 5% ) / 2)',

            },
        },
        '&:nth-child(4)': {
            //left option
            left: '0%',
            top: '50%',
            width: 'calc(100% - 50%)',
            [theme.breakpoints.down("md")]: {
                transform: 'translateY(-50%)',
                width: 'calc((100% - 50% - 5%)/2)',
                transformOrigin: 'center left',
            },

            [theme.breakpoints.up("md")]: {
                transform: 'translate(-70%, -50%)',
            },
        },
    },
    centeredText: {
        width: '60%',
        // height: '60%',
        borderRadius: '50%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transition: 'all 0.3s linear',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        zIndex: '10',
        fontSize: '0.8rem',
        textAlign: 'center',
        aspectRatio: '1/1',
        boxShadow: '0px 0px 65px -15px rgba(0,0,0,0.75)',

        '-webkit-user-select': 'none', /* Safari */
        '-ms-user-select': 'none', /* IE 10 and IE 11 */
        userSelect: 'none', /* Standard syntax */

        overflowWrap: 'anywhere',
        // hyphens: '',

        '& > p': {
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            fontFamily: 'Roboto',
            fontWeight: '600'
        },
        [theme.breakpoints.down("md")]: {
            width: '50%',
        }
        // background: 'red'

    },
    aimContainer: {
        // height: '100%',
        // '--angle': '0deg',
        width: '60%',
        position: 'absolute',
        left: '50%',
        top: '50%',
        borderRadius: '50%',
        transformOrigin: 'center center',
        transform: 'translate(-50%, -50%) rotate(var(--angle))',
        cursor: 'pointer',
        // touchAction: 'none',
        zIndex: '11',
        backgroundColor: 'transparent',
        aspectRatio: '1/1',
        transition: 'none',

        '-webkit-user-select': 'none', /* Safari */
        '-ms-user-select': 'none', /* IE 10 and IE 11 */
        userSelect: 'none', /* Standard syntax */

        // boxShadow: constants.ILA_OPTION_BOX_SHADOW,
        [theme.breakpoints.down("md")]: {
            width: '50%',
        }
    },
    navigation: {

        position: 'absolute',
        top: '0',
        height: '',
        left: '50%',
        width: '1px',
        transform: 'translateX(-50%)',
        // backgroundColor: 'white',
        backgroundColor: 'transparent',
        transformOrigin: 'center bottom',
        transform: ''
    },
    navigationIcon: {

        position: 'absolute',
        bottom: '',
        left: '50%',
        transform: 'translateX(-50%)',
    },

    correctIncorrectTextBox: {
        position: 'absolute',
        fontFamily: 'Montserrat',
        top: '0',
        // left: '50%',
        width: '100%',
        textAlign: 'center',
        // transform: 'translate(-50%, 0)',
        [theme.breakpoints.down("md")]: {
            top: '-2.5rem',
            // width: '100%',
            // textAlign: 'center'
            
        }
    },
}))

export default useStyles