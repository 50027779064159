import React from "react";

// mui

// components

// redux

// utils

// constants

// styles
import useStyles from "./index.styles";

const HeaderBackButton = (props) => {
    const classes = useStyles();
    const { onClick, imgWidth = 20, width, background = '#fff' } = props;
    return (
        <>
            <div className={`disableSelection ${classes.centerFlex} ${classes.backButton}`} style={{ width: width, background }}
                onClick={onClick}>
                <img src="/images/icons/left_arrow.svg" alt="icon" width={imgWidth} className={`disableSelection disablePointerEvents`} />
            </div>
        </>
    )
}

export default HeaderBackButton;