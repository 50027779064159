import { makeStyles } from "@mui/styles";
import commonUtil from "../../../utils/commonUtil";
const isHDScreen = commonUtil.getScreenResolution() === 'HD';

const ROAD_COLOR = '#100736'

const useStyle = makeStyles((theme) => ({

    CrazyCarsRoot: {
        backgroundSize: 'contain',
        height: '100%',
        width: '100%',
        color: "#fff",
        boxSizing: "border-box",
        //background: "linear-gradient(#f7591f, #fcb340)",
        position: "relative",
        transition: "0.7s",
        userSelect: 'none',
        transition: 'background 1s'
    },

    startPage: {
        height: "100%",
        // backgroundImage: "url(/popcornMatch/popcorn_match_overlay.png)"
    },
    centerFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    bgImgBox: {
        textAlign: "center",
        height: "40%"
    },
    mainText: {
        animation: "$zoomInOut 1s ease 0s infinite normal forwards running",
    },
    titleImage: {
        width: "85%"
    },
    btnBox: {
        textAlign: "center",
        height: "60%"
    },
    btnContainer: {
        width: "100%",
    },
    howToPlay: {
        width: "85%",
        fontFamily: "MotionControl",
        fontSize: "1.8em",
        border: "none",
        padding: "0.6rem",
        cursor: "pointer",
        borderRadius: "5px",
        background: "none",
        marginBottom: "30px",
        color: "#fff",
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '2.5em',
        },
    },
    startBtn: {
        width: "85%",
        fontFamily: "MotionControl",
        fontSize: "2em",
        background: "#ffe66e",
        border: "none",
        padding: "0.6rem",
        cursor: "pointer",
        borderRadius: "5px",
        boxShadow: '0 2px 7px -1px #000',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '3em',
        },
    },

    "@keyframes animateOpacity": {
        "0%": { opacity: "0" },
        "100%": { opacity: "1" },
    },
    "@keyframes shakeObj": {
        "0%": { transform: "rotate(3deg)" },
        "10%": { transform: "rotate(-3deg)" },
        "20%": { transform: "rotate(3deg)" },
        "30%": { transform: "rotate(-3deg)" },
        "40%": { transform: "rotate(3deg)" },
        "50%": { transform: "rotate(-3deg)" },
        "60%": { transform: "rotate(3deg)" },
        "70%": { transform: "rotate(-3deg)" },
        "80%": { transform: "rotate(3deg)" },
        "90%": { transform: "rotate(-3deg)" },
        "95%": { transform: "rotate(3deg)" },
        "100%": { transform: "rotate(0deg)" },
    },
    "@keyframes slideFromBottom": {
        "0%": { transform: "translateY(250%)" },
        "100%": { transform: "translateY(0)" },
    },
    "@keyframes zoomInOut": {
        "0%": { transform: "scale(1)" },
        "50%": { transform: "scale(0.95)" },
        "100%": { transform: "scale(1)" },
    },


    // questionBox container
    qstnWrapper: {
        position: 'absolute',
        width: '100%',
        height: 'calc(25% + 1.25%)', // 25% for question box and 1.25% for progress bar
        opacity: '1',
        display: 'grid',
        zIndex: '999',
        gridAutoRows: `calc(1.25% * 3.81) calc(25% * 3.81)`
        // !important
        // this wrapper is 25% + 1.25% of the screen height, 
        // 3.81 is factor to adjust height of progressBar & qstnContainer according to wrapper height
    },
    qstnContainer: {
        height: "100%",
        width: "100%",
        // padding: "0.5rem 0.5rem 0 0.5rem",
        transform: "translateY(-300px)",
        zIndex: '999',
        position: 'relative',
        opacity: '0.9',
        transition: 'transform 1s ease 0s',
        [theme.breakpoints.up('sm')]: {
            paddingTop: '0rem'
        }
    },
    // questionAnimation: {
    //     animation: "$translateQstnContainer 1s ease 0s 1 normal forwards running",
    // },
    // "@keyframes translateQstnContainer": {
    //     "0%": { transform: "translateY(-300px)" },
    //     "100%": { transform: "translateY(0)" }
    // },
    qstnBox: {
        backgroundColor: "#000000",
        // borderRadius: "10px",
        height: "100%",
        fontSize: "1.2em",
        boxShadow: "0px 10px 20px #0000004D",
        display: 'grid',
        gridTemplateRows: '28% 72%'
        // padding: '0 0.8rem'
    },
    qstnBoxBoxShadow: {
        transform: "translateY(0px)",
        animation: '$wrongAnsAnimation 1.5s ease-in-out 0s infinite normal forwards running'
    },
    "@keyframes wrongAnsAnimation": {
        "0%": { boxShadow: "0px 10px 20px #0000004D" },
        "50%": { boxShadow: "0px 0px 20px -1px #F63B34E8" },
        "100%": { boxShadow: "0px 10px 20px #0000004D" }
    },
    assetBox: {
        display: "grid",
        // gridTemplateColumns: "1fr 1fr",
        gridTemplateColumns: "1fr 1fr 1fr",
        padding: "12px",
        paddingBottom: '0px',
        alignItems: 'center'
    },
    lifeText: {
        display: "flex",
        alignItems: "center",
        padding: isHDScreen ? '0' : "0"
    },
    timerText: {
        display: "flex",
        alignItems: "center",
        padding: "0 15px",
        //justifyContent: "right",
        justifySelf: "end"
    },
    icon: {
        width: '1em',
        height: '1em',
        margin: "0 5px"
    },
    life: {
        fontWeight: '600',
        fontSize: isHDScreen ? '1em' : '1em',
    },
    timerIcon: {
        width: "13px",
        height: "12px",
        margin: "0 6px"
    },
    qstn: {
        textAlign: "center",
        transition: '.5s',
        fontFamily: 'Open Sans',
        fontSize: isHDScreen ? '0.6em' : '1em',
        opacity: '1',
        fontSize: '1em',
        padding: '2%',
        height: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        [theme.breakpoints.down("sm")]: {
            fontSize: isHDScreen ? '0.75em' : '1em',
            // fontSize: 'clamp(1.2rem, 1.2vw, 1.5rem)',
        },
    },

    //game play
    gameContainer: {
        display: "grid",
        height: "100%",
        width: "100%",
        gridTemplateRows: "100%",
        overflow: 'hidden',
        touchAction: 'none',
        opacity: '0',
        animation: '$animateGameLandingPage 2s ease 0s 1 normal forwards running',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    "@keyframes animateGameLandingPage": {
        "0%": { opacity: '0' },
        "100%": { opacity: '1' },
    },
    questionBox: {
        height: "60%",
        padding: "0.7rem"
    },
    qBox: {
        backgroundColor: "#000",
        height: "100%",
        padding: "1rem",
        borderRadius: "5px",
    },
    dragCardContainer: {
        display: "grid",
        height: "100%",
        gridAutoRows: "50% 50%",
        transition: '.5s'
    },
    answerContainer: {
        display: "flex",
        padding: "3rem 0",
        flexWrap: "wrap",
    },
    // answer: {
    //     flex: "1 1 33%",
    //     display: "flex",
    //     //overflow: 'hidden',
    //     padding: '0 1.2rem',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     transition: '.4s'
    // },
    anwserImgBox: {
        position: 'relative',
        height: '100px',
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        transition: '0.4s'
    },
    // answerText: {
    //     position: 'absolute',
    //     top: '100%',
    //     left: '50%',
    //     transform: 'translateX(-50%)',
    //     fontSize: '0.7rem',
    //     textAlign: 'center',
    //     width: '14ch',
    //     // whiteSpace: 'normal',
    //     // wordBreak: 'break-word',
    //     fontFamily: 'Montserrat',
    //     [theme.breakpoints.down("sm")]: {
    //         // mobile
    //         fontSize: '1rem',
    //     },
    // },
    answerImg: {
        height: '60px',
    },

    option: {
        flex: "1 1 33%",
        display: "flex",
        padding: '0 1.2rem',
        alignItems: 'center',
        justifyContent: 'center',
    },
    qstnImgBox: {
        position: 'relative',
        height: '90px',
        width: '90px',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    },
    qstnText: {
        position: 'absolute',
        top: '108%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '0.7em',
        textAlign: 'center',
        width: '14ch',
        // whiteSpace: 'normal',
        // wordBreak: 'break-word',
        fontFamily: 'Montserrat',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1em',
        },
    },
    qstnImg: {
        height: '60px',
    },
    // optionContainer: {
    //     display: "flex",
    //     padding: "3rem 0",
    //     flexWrap: "wrap",
    //     justifyContent: "center",
    //     transition: "transform 1s",
    // },
    optionCard: {
        flex: "0 1 33%",
        display: "flex",
        justifyContent: "center",
    },
    progressBarContainer: {
        position: "relative",
        height: "100%",
        zIndex: "2",
        width: '100%',
        borderRadius: '0px',
        margin: 'auto',
        overflow: 'hidden',
        backgroundColor: theme.palette.white,
        [theme.breakpoints.down("md")]: {
            backgroundColor: theme.palette.progressBarBg,
        },
        // marginTop: '4px',
    },
    progressBar: {
        height: "100%",
        backgroundColor: "#ffd042",
        width: '0',
        transition: '.4s',
    },
    boxShadowBreathingAnimation: {
        transition: '2s',
        animation: '$boxShadowBreathingAnimation 1s ease 0s infinite normal forwards running '
    },
    dragItemAction: {
        fontFamily: 'MotionControl',
        fontSize: '1.6em',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.8em',
        },
    },
    '@keyframes boxShadowBreathingAnimation': {
        '0%': { boxShadow: '0 0 15px -2px red' },
        '10%': { boxShadow: '0 0 16px -2px red' },
        '20%': { boxShadow: '0 0 17px -1px red' },
        '30%': { boxShadow: '0 0 18px -1px red' },
        '40%': { boxShadow: '0 0 19px 0px red' },
        '50%': { boxShadow: '0 0 20px 0px red' },
        '60%': { boxShadow: '0 0 19px -1px red' },
        '70%': { boxShadow: '0 0 18px -1px red' },
        '80%': { boxShadow: '0 0 17px -2px red' },
        '90%': { boxShadow: '0 0 16px -2px red' },
        '100%': { boxShadow: '0 0 15px -2px red' },
    },


    gameplayContainer: {
        width: '100%',
        display: 'grid',
        gridAutoRows: '90% 10%',
        alignItems: 'center',
        // justifyContent: 'center',
        backgroundSize: 'cover',
    },
    scrollBox: {
        height: '100%',
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: '20% 60% 20%',
        // backgroundImage: 'url(/images/gba/tasty-truth/tasty_truth_grid.svg)',
        width: '100%',
        backgroundPosition: '6px 0',
        backgroundSize: 'contain',
        [theme.breakpoints.down("sm")]: {
            // mobile
            width: '100vw',
        },
    },
    gameplayBox: {
        // overflow: 'auto',
        // transform: 'translateY(-700px)',
        transistion: '0.3s',

        // transition: 'transform 330ms ease-in-out',
        // transition: '.2s',
        // position: 'absolute',
        //animationName: '$assetsAnimation',
        //animationDuration: '2s',
        //animationIterationCount: 'infinite',
        // animationTimingFunction: 'linear',
        // transform: 'translateY(-50vh)',
        width: '100%',
        left: '0',
        right: '0',
        top: '100%',
        height: '100%',
        '& > *': {
            display: 'flex',
            alignItems: 'center',
        },
        '& > img': {

        },
        '& div:nth-child(odd)': {
            justifyContent: 'flex-end',
        },
        '& div:nth-child(even)': {
            justifyContent: 'left',
        }
    },

    '@keyframes assetsAnimation': {
        '100%': {
            transform: 'translateY(0)',
        }
    },
    answerTxt: {
        position: 'absolute',
        cursor: 'pointer',
        zIndex: '3',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#fff',
        width: '90%',
        padding: '0.6rem',
        height: '80%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center !important',
        textAlign: 'center',
        fontSize: isHDScreen ? '0.6em' : '0.9em',
        fontWeight: isHDScreen ? '700' : 'bold',
        [theme.breakpoints.up("sm")]: {
            // mobile
            fontSize: isHDScreen ? '0.6em' : '0.9em',
        }
    },
    rockImageBox: {

    },
    rockBox: {
        position: 'relative',
        width: '50%',
        margin: '0 1rem'
    },
    rockImage: {
        position: 'relative',
        zIndex: '2',
        width: '100%'
    },
    correctLottie: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        width: '100%',
    },
    wrongLottie: {
        position: 'absolute',
        top: '20%',
        left: '50%',
        transform: 'translate(-50%, -100%)',
        height: '30%',
        aspectRatio: '1 / 1',
        zIndex: '2'
    },
    incorrectGradientOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        backgroundImage: 'linear-gradient(#800E31, #F63B34)',
        opacity: '0',
        transition: '.5s ease'
    },
    speedBarBox: {
        height: '100%',
        background: ROAD_COLOR,
        zIndex: '990',
        display: 'grid',
        gridTemplateColumns: '20% 60% 20%',
        alignItems: "center",
        justifyContent: "center",
        boxShadow: '0 0 15px 2px #000'
    },
    speedBarRectBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: '100%',
        '& > *': {
            width: '0.5em',
            [theme.breakpoints.down('sm')]: {
                width: '5px'
            },
            height: '40%',
            margin: isHDScreen ? '0 .20rem' : '0 .25rem',
            background: '#eff774',
        }
    },
    speedDecrement: {
        fontSize: '2em',
        marginRight: isHDScreen ? '0.5rem' : '1rem',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%',
        '&:active': {
            transform: 'scale(0.85)', // Scale down on press
            transition: 'transform 0.1s ease-in-out' // Smooth transition
        },
        '& > img': {
            width: '60%',
            [theme.breakpoints.down('sm')]: {
                width: '60%'
            }
        }
    },
    speedIncrement: {
        fontSize: '2em',
        marginLeft: isHDScreen ? '0.5rem' : '1rem',
        cursor: 'pointer',
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        height: '100%',
        '&:active': {
            transform: 'scale(0.85)', // Scale down on press
            transition: 'transform 0.1s ease-in-out', // Smooth transition
        },
        '& > img': {
            width: '60%',
            [theme.breakpoints.down('sm')]: {
                width: '60%'
            }
        }
    },
    incorrectGradientOverlayAnimationInfinite: {
        animation: '$incorrectGradientOverlayAnimationInfinite 1.5s ease-in-out 0s infinite normal forwards running'
    },
    '@keyframes incorrectGradientOverlayAnimationInfinite': {
        '0%': { background: 'transparent' },
        "50%": { background: "#FF5757" },
        "100%": { background: 'transparent' }
    },
    wrongAnsAnimation: {
        animation: '$incorrectGradientOverlayAnimationInfinite 1.5s ease-in-out 0s 2 normal forwards running'
    },
    BlankOption: {
        display: 'grid',
        gridTemplateColumns: '20% 60% 20%'
    },
    road: {
        border: '3px solid #ddd',
        borderRight: '2px solid #ddd',
        display: 'grid',
        gridTemplateColumns: '47.5% 5% 47.5%',
        position: 'relative',
        // boxShadow: '0px 0px 8px 4px #00DCFF',
        boxShadow: '0px 0px 16px 8px #00FFEB',
        // border: '0px 0px 16px 8px #00FFEB',
        background: ROAD_COLOR,
        borderBottom: '2px solid #00DCFF',
        borderTop: '2px solid #00DCFF',
        zIndex: 2,

        height: '200dvh',
        transformOrigin: 'center top'
    },
    horizontalRoad: {
        width: '300dvh',
        height: '15%',
        position: 'absolute',
        top: '0%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: ROAD_COLOR,
        // borderBottom: 'none',
        // boxShadow: '0px -12px 15px -5px #00FFEB',
        boxShadow: '0px 0px 16px 8px #00FFEB',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '15rem',
        borderTop: '2px solid #ddd',
        borderBottom: '2px solid #ddd',
        // "&::before": {
        //     content: '""',
        //     width: 'calc(50% - 7.5rem - 2px)',
        //     height: '1px',
        //     position: 'absolute',
        //     left: '0px',
        //     bottom: '0',
        //     borderBottom: '2px solid #ddd',
        //     boxShadow: '-5px 7px 15px 6px #00FFEB',
        // },
        // "&::after": {
        //     content: '""',
        //     width: 'calc(50% - 7.5rem - 2px)',
        //     height: '1px',
        //     position: 'absolute',
        //     right: '0px',
        //     bottom: '0',
        //     borderBottom: '2px solid #ddd',
        //     boxShadow: '5px 7px 15px 6px #00FFEB',
        // }
    },
    horizontalRoadStrips: {
        width: '200dvh',
        height: '1px',
        position: 'absolute',
        top: 'calc(50% + 6px)',
        left: 'calc(50% + 2px)',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        borderTop: '15px dotted #F5D03C', // monster food
        // transition: 'height 100ms linear'
    },
    roadStrips: {
        height: '100%',
        width: '100%',
        // borderLeft: '15px dotted #F5D03C', // monster food
        position: 'relative',
    },
    yellowDotsContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
    },
    yellowDot: {
        width: '6px',
        aspectRatio: '1/1',
        display: 'block',
        margin: '1rem auto',
        borderRadius: '50%',
        backgroundColor: '#F5D03C',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 10,
    },
    '@keyframes roadAnimation': {
        to: {
            transform: 'translateY(0)',
        }
    },
    optionContainer: {
        display: 'flex',
        width: '4.5em',
        height: '4.5em',
        // height: '4.5em',
        // margin: 'auto',
        justifyContent: 'center',
        transition: '0s',
        // transition: 'transform 0.0s',
        zIndex: 2,
        position: 'absolute',
        top: '50%',

    },
    leftOptionContainer: {
        left: 'calc(50% - 6.5%)',
        transform: 'translate(-50%, -50%)'
    },
    rightOptionContainer: {
        left: 'calc(50% + 6.5%)',
        transform: 'translate(-50%, -50%)'
        // transform: 'translate(100px, -200px)'
    },
    leftWall: {
        position: 'absolute',
        left: 'calc(50% - 6.5%)',
        height: '100%',
        border: '1px solid black',
        width: '2px',
        visibility: 'hidden',
    },
    rightWall: {
        position: 'absolute',
        left: 'calc(50% + 6.5%)',
        height: '100%',
        border: '1px solid black',
        width: '2px',
        visibility: 'hidden',
    },
    // options: {
    //     width: '100%',
    //     height: '100%',
    //     fontSize: '0.4em',
    //     fontWeight: '600',
    //     borderRadius: '50%',
    //     textAlign: 'center',
    //     padding: '0.5rem',
    //     justifyContent: 'center',
    //     // background: '#FFB300',
    //     margin: 'auto',
    //     display: 'flex',
    //     alignItems: 'center',
    //     [theme.breakpoints.down("sm")]: {
    //         fontSize: '1rem'
    //     }
    // },

    options: {
        width: '100%',
        aspectRatio: '1/1',
        borderRadius: '50%',
        objectFit: 'contain',
        transition: 'transform 0.5s linear',
    },
    optionsResult: {
        width: '100%',
        aspectRatio: '1/1',
        borderRadius: '50%',
        objectFit: 'contain',
        transform: 'scale(0)',
        opacity: '0',
    },
    optionLeft: {
        boxShadow: '0px 0px 0px 0px #FBE522',
    },
    optionRight: {
        boxShadow: '0px 0px 6px 2px #2313ab',
    },
    carImg: {
        position: 'absolute',
        zIndex: "980",
        top: '92%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '6.5em',
        animation: '$carBlinkAnimation 1s ease-in-out 0s 3 normal forwards running',
        // animationDelay: '2500ms'
    },
    resultImg: {
        position: 'absolute',
        zIndex: "980",
        top: '75%',
        // top: '92%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '6.5em',
    },
    DotEatingShield: {
        position: 'absolute',
        zIndex: "975",
        top: 'calc(92% + (6.5em / 2))',

        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '6em',
        height: '7em',
        backgroundColor: ROAD_COLOR,

        // animation: '$carBlinkAnimation 1s ease-in-out 3 normal forwards running',
        // animationDelay: '2500ms'
    },
    dotEating: {
        width: '20px',
        height: '12.5rem',
        backgroundColor: ROAD_COLOR,
        position: 'absolute',
        bottom: '0%',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        zIndex: '5',
    },
    horizontalDotEating: {
        width: '0px',
        height: '20px',
        backgroundColor: ROAD_COLOR,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '5',
    },

    '@keyframes carBlinkAnimation': {
        '0%': { opacity: '1' },
        '50%': { opacity: '0' },
        '100%': { opacity: '1' }
    },
    missedContainer: {
        position: 'absolute',
        top: '-10%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontWeight: '900',
        fontSize: '2em',
        opacity: '0',
        fontFamily: 'MotionControl'
    },
    optionAnimation: {
        animation: '$optionEncounter 2s ease-in-out 0s 1 normal forwards running',
    },
    "@keyframes optionEncounter": {
        "0%": { top: '79%', opacity: '1' },
        "50%": { top: '76%', opacity: '0.5' },
        "100%": { top: '73%', opacity: '0' }
    },
    answer: {
        // position: 'absolute',
        top: '79%',
        // transform: 'translate(10px, -50%)',
        fontSize: '2em',
        opacity: '0',
        fontWeight: '900',
        height: 'max-content',
        textAlign: 'center',
        fontFamily: 'MotionControl'
    },
    leftSideImg: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        zIndex: 1
    },
    rightSideImg: {
        position: 'relative',
        height: '100%',

        overflow: 'hidden',
        zIndex: 1
    },
    assetImg: {
        display: 'grid',
        alignItems: 'flex-end',
        padding: '15px 15px',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            padding: '15px 15px',
            width: '100%',
        }
    },
    context: {
        textAlign: 'center',
        color: '#FFBB00',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50%',
        padding: '2%',
        transition: 'all 0.2s',
        fontSize: '1em',
        [theme.breakpoints.down("sm")]: {
            fontSize: '1em',
            // fontSize: 'clamp(1.2rem, 1.2vw, 1.5rem)',
        },
    },
    blinkAnimationEffect: {
        animation: '$blinkAnimation 1.5s ease-in-out 0s infinite normal forwards running',
    },
    '@keyframes blinkAnimation': {
        '0%': { opacity: 0 },
        '50%': { opacity: 1 },
        '100%': { opacity: 0 }
    },
    roadSideImg: {
        width: '100%',
        position: 'sticky',
        bottom: '100px',
        // opacity: '0',
    },
    imgBox: {
        position: 'absolute',
        bottom: 0,
    },
    rightRoadSideImg: {
        transform: 'rotate(180deg)'
    },
    answerBox: {
        position: 'absolute',
        height: '25%',
        bottom: 0,
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '50% 50%'
    },
    optionContainerBox: {
        position: 'relative',
        zIndex: 1,
    },
    questionNo: {
        textAlign: 'center',
        fontWeight: 600,
        color: '#F5CF38',
        letterSpacing: '0.2rem'
    },
    speedBar: {
        borderRadius: '10px',
    },

    initialGameAnimation: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '99999',
        background: 'url(/images/gba/tasty-truth/bg.png), linear-gradient(rgba(0, 0, 0, 1), rgba(0,0,0,1))',
        overflow: 'hidden',
        backgroundSize: 'cover',
        transition: 'transform 0.05s linear',
    },
    horzYellowDotsContainer: {
        width: '100%',
        height: '10px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'absolute',
        overflow: 'hidden',
        top: '50%',
        left: '50%',
        transform: 'translate(-50.5%, -50%)',
    },
    horzYellowDot: {
        width: '6px',
        aspectRatio: '1/1',
        display: 'block',
        borderRadius: '50%',
        backgroundColor: '#F5D03C',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 10,
    },
    animeTrackImg: {
        width: '100%',
        transformOrigin: '48% 80%',
        animation: '$initialTrackAnimation 2500ms 500ms 1 linear',

    },
    '@keyframes initialTrackAnimation': {
        '0%': {
            transform: 'scale(1)',
            opacity: 1
        },
        '100%': {
            transform: 'scale(8)',
            opacity: 0,
        }
    },

    finalGameAnimation: {
        width: '100%',
        transformOrigin: '98% 70% !important',
        transform: 'scale(10)',
        animation: '$finalTrackAnimation 3000ms 500ms 1 linear !important',
    },
    '@keyframes finalTrackAnimation': {
        '0%': { transform: 'scale(10)' },
        '90%, 100%': { transform: 'scale(1)' }
    },
    selectedOption: {
        boxShadow: '0px 0px 8px 8px #fff',
    },
    wrapBoxShadow: {
        position: 'absolute',
        bottom: '-35px',
        height: '50px',
        background: '#100736'
    }

}));

export default useStyle;
