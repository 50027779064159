import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    voiceoverPlayerRoot: {
        height: '2.5rem',
        width: '2.5rem',
        borderRadius: '50%',
        backgroundColor: theme.palette.white
    },
}));

export default useStyle;
