import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import HeaderBackButton from '../layout-elements/header-back-button';
import constants from '../../constants';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { isAndroid } from 'react-device-detect';

// mui
import { styled } from '@mui/system';
import { Input, Button, CircularProgress } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

// utils
import isEmpty from '../../utils/isEmpty';
import commonUtil from '../../utils/commonUtil';
import { getHeaderHeight, isNativeApp } from '../../utils/commonUtil';

// styles
import useStyles from "./index.styles";
import palette from '../../theme/palette';

// react i18next
import { useTranslation } from 'react-i18next';
import { genericFileUpload } from '../../redux/microskill/microskill.api';
import { submitUserActivityData } from '../../redux/event-tracking/event.api';
import createSharingUrl from '../../utils/createSharingUrl';
import webViewJavaScriptBridge from 'webview-javascript-bridge';

const StyledInput = styled(Input)(({ theme }) => ({
    width: '100%',
    padding: '0.5rem 1rem',
    backgroundColor: theme.palette.white,
    borderRadius: theme.spacing(1),
    border: 'none',
    color: theme.palette.greyText1,
    outline: 'none',
    fontWeight: 500,
    '&:before': {
        display: 'none'
    },
    '&:after': {
        display: 'none'
    }
}));

const SavingsCalculator = (props) => {
    const { headerText, handleBackBtnCompClick } = props;
    const userActivityData = useSelector(state => state?.event);
    const { userProfileData } = useSelector(state => state?.auth);
    const { HEADER_TOP_PADDING } = constants;

    const vehicleICE = 'ICE';
    const vehicleEV = 'EV';
    const batterySize = 10.24;
    const usableBattery = 90 / 100;

    const pdfMainRef = useRef();
    const pdfRef = useRef();
    const pdf = new jsPDF();
    // const pdf = new jsPDF("l", "pt", "a4", true);
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [tcoPdfData, setTcoPdfData] = useState(null);
    const { t } = useTranslation();
    const [showCalcResults, setShowCalcResults] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const [costSavingsPerDay, setCostSavingsPerDay] = useState(0);
    const [costSavingsPerMonth, setCostSavingsPerMonth] = useState(0);
    const [costSavingsNext5Years, setCostSavingsNext5Years] = useState(0);
    const [runningCostPerKmICE, setRunningCostPerKmICE] = useState(0);
    const [runningCostPerKmEV, setRunningCostPerKmEV] = useState(0);

    const [dailyRun, setDailyRun] = useState({
        ICE: '175',
        EV: '175'
    });
    const [averageWorkDays, setAverageWorkDays] = useState({
        ICE: '28',
        EV: '28'
    });
    const [rwup, setRwup] = useState({
        ICE: '27.5',
        EV: '150'
    });
    const [chargingRate, setChargingRate] = useState({
        ICE: '90',
        EV: '6.5'
    });

    const [calculationBtnDisabled, setCalculationBtnDisabled] = useState(true);
    const [shareBtnDisabled, setShareBtnDisabled] = useState(true);
    const [customerName, setCustomerName] = useState('');
    const [vehicleName, setVehicleName] = useState('');
    const [yourName, setYourName] = useState(`${userProfileData?.firstName} ${userProfileData?.lastName}` || '');
    const [contactNumber, setContactNumber] = useState(userProfileData?.mobile || '');
    // console.log("userProfileData:", userProfileData);
    // styling classes
    const classes = useStyles({ showPopup });

    useEffect(() => {
        if (
            isEmpty(dailyRun[vehicleICE]) ||
            isEmpty(rwup[vehicleICE]) ||
            isEmpty(chargingRate[vehicleICE]) ||
            isEmpty(averageWorkDays[vehicleICE])
        ) {
            setCalculationBtnDisabled(true);
            return
        } else {
            setCalculationBtnDisabled(false);
        }
        if (!isEmpty(tcoPdfData)) {
            setTcoPdfData(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dailyRun, rwup, chargingRate, averageWorkDays])

    useEffect(() => {
        if (
            isEmpty(customerName) ||
            isEmpty(vehicleName) ||
            isEmpty(yourName) ||
            isEmpty(contactNumber) ||
            contactNumber.trim().length !== 10
        ) {
            setShareBtnDisabled(true);
            return
        } else {
            setShareBtnDisabled(false);
        }
        if (!isEmpty(tcoPdfData)) {
            setTcoPdfData(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerName, vehicleName, yourName, contactNumber]);

    function calculateTotalCost() {
        const nullChecker = (val) => isEmpty(val) ? '0' : val;
        const denominatorChecker = (val) => isEmpty(val) ? '1' : val;

        let dailyRun_EV = dailyRun[vehicleEV];
        let rwup_EV = rwup[vehicleEV];
        let chargingRate_EV = chargingRate[vehicleEV];
        let averageWorkDays_EV = averageWorkDays[vehicleEV];
        const kWhPerKmEV = (batterySize * usableBattery) / rwup_EV;

        let dailyRun_ICE = dailyRun[vehicleICE];
        let rwup_ICE = rwup[vehicleICE];
        let fuelCost_ICE = chargingRate[vehicleICE];
        let averageWorkDays_ICE = averageWorkDays[vehicleICE];

        let perKmCost_EV = parseFloat(nullChecker(chargingRate_EV)) * kWhPerKmEV;
        let perKmCost_ICE = parseFloat(nullChecker(fuelCost_ICE)) / parseFloat(denominatorChecker(rwup_ICE));

        let dailyChargingCost_EV = parseFloat(nullChecker(dailyRun_EV))
            * parseFloat(nullChecker(chargingRate_EV))
            * kWhPerKmEV;

        let dailyRunningCost_ICE = (parseFloat(nullChecker(dailyRun_ICE)) / parseFloat(denominatorChecker(rwup_ICE)))
            * parseFloat(nullChecker(fuelCost_ICE));

        let monthlyChargingCost_EV = dailyChargingCost_EV * parseFloat(nullChecker(averageWorkDays_EV));
        let monthlyRunningCost_ICE = dailyRunningCost_ICE * parseFloat(nullChecker(averageWorkDays_ICE));

        let year5ChargingCost_EV = monthlyChargingCost_EV * 60;
        let year5RunningCost_ICE = monthlyRunningCost_ICE * 60;

        setRunningCostPerKmEV(perKmCost_EV.toFixed(2));
        setRunningCostPerKmICE(perKmCost_ICE.toFixed(2));
        setCostSavingsPerDay((dailyRunningCost_ICE - dailyChargingCost_EV).toFixed(2));
        setCostSavingsPerMonth((monthlyRunningCost_ICE - monthlyChargingCost_EV).toFixed(2));
        setCostSavingsNext5Years((year5RunningCost_ICE - year5ChargingCost_EV).toFixed(2));
        setShowCalcResults(true)
    }

    const handleSharePdf = async () => {
        setLoadingPdf(true);
        var pdfDoc = new jsPDF({
            compress: true,
            orientation: 'p',  // 'p' for portrait, 'l' for landscape
            unit: 'mm',        // units: mm, cm, in
            format: 'a4',      // format: 'a3', 'a4', 'a5', etc.
        });

        pdfRef.current.style.borderRadius = "0px";
        pdfRef.current.style.fontSize = "0.5rem";
        pdfMainRef.current.style.height = "inherit";
        html2canvas(document.querySelector("#pdfRef"), {
            scale: 4,
        }).then(async (canvas) => {
            pdfDoc.addImage(
                canvas.toDataURL("image/png"),
                "png",
                0,
                0,
                pageWidth,
                pageHeight,
                'MEDIUM'
            )
            const tcoPdf = pdfDoc.output('blob');
            const tcoFormData = new FormData();
            tcoFormData.append('mediaFile', tcoPdf, `savings-calculation.pdf`);
            tcoFormData.append('folderName', "savings");
            // const blobUrl = URL.createObjectURL(tcoPdf)
            // window.open(blobUrl, '_blank');
            // return
            const respData = await genericFileUpload(tcoFormData);
            if (respData?.status === 200) {
                let { location, mimetype } = respData?.data?.data[0];
                setTcoPdfData({ location, mimetype })
            }
            setLoadingPdf(false);
            pdfRef.current.style.paddingTop = "none";
        })
    }

    const handleCloseClick = () => {
        if (!isEmpty(pdfRef.current)) pdfRef.current.style.fontSize = null;
        if (!isEmpty(pdfMainRef.current)) pdfMainRef.current.style.height = null;
    }

    const handleShare = async () => {
        const { location, mimetype } = tcoPdfData;

        const createdUrl = `${createSharingUrl(location)}&sharedTo=${customerName.split(" ").join("_")}`
        try {
            // Check if the Web Share API is supported

            submitUserActivityData({
                oldScreen: userActivityData.currentScreen,
                currentScreen: "SALES ENABLEMENT PRODUCT DETAILS",
                nextSlide: '',
                activityType: `DOCUMENT SHARED`,
                activityData: {
                    screen: `TCO Calculator shared`,
                    sharedWith: customerName,
                    sharedBy: commonUtil.getUserName(),
                    sharedByID: commonUtil.getUserId()
                }
            });


            const isNativeApp = localStorage.getItem('isNative');
            if (isNativeApp === 'true') {
                webViewJavaScriptBridge.sendMessage({ action: 'ShareChannel', params: `'${location}|saving-calculation.pdf'` }, (response) => {
                    console.log('ShareChannel response:', response);
                });
            }
            else if (navigator.share) {
                await navigator.share({
                    title: `Total Cost of Ownership`,
                    text: `Dear ${customerName}, Thank you for your interest in ${vehicleName}. \nI'm sharing the total cost of ownership for your reference. \nThank You. \n${commonUtil.getUserName()} \n`,
                    // url: location,
                    url: createdUrl, // url to share
                })
                    .then(() => console.log('Successful share'))
                    .catch((error) => {
                        window.location.replace(location);
                        console.log('Error sharing generated content', error);
                    });

            } else {
                if (isNativeApp()) {
                    if (isAndroid) {
                        window.location.replace(`${location}&share=true&filetype=${mimetype}`)
                        return
                    }
                }
                window.location.replace(location)
            }
        } catch (error) {
            console.error('Error sharing:', error.message);
        }
    }

    const Header = () => {
        return (
            <div className={classes.emiCalcHeader}
                style={{ height: getHeaderHeight(), paddingTop: isNativeApp() ? HEADER_TOP_PADDING : '' }} >
                <div className={`${classes.centerFlex} ${classes.backButton}`}
                    onClick={() => {
                        handleCloseClick();
                        if (showCalcResults) return setShowCalcResults(false);
                        handleBackBtnCompClick(false);
                    }}
                >
                    <HeaderBackButton />
                </div>
                <div className={`${classes.centerFlex} ${classes.headerTitle}`}>
                    {t(headerText)}
                </div>
            </div>
        )
    }

    return (
        <div className={classes.emiCalcRoot}>
            <Header />
            <div className={`${classes.emiCalcBody} scrollbar-hidden`}
                style={{ height: `calc(100% - ${getHeaderHeight()})` }}
                id="tcoScrollDiv">
                <div className={classes.tcoCalculator}>
                    {!showCalcResults ? (
                        <>
                            <form className={classes.form}>
                                {/* <==== Daily running ====> */}
                                <div className={classes.formControl}>
                                    <div className={classes.formControlLabel}>{t('Daily running')} *</div>
                                    <StyledInput
                                        type="number"
                                        value={dailyRun[vehicleICE]}
                                        placeholder='Enter kilometers'
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            if (/^[0-9.+]*$/.test(value)) {
                                                setDailyRun(prev => ({ ...prev, [vehicleICE]: value, [vehicleEV]: value }));
                                            }
                                        }}
                                    />
                                </div>

                                {/* <==== Average Working Days Per Month ====> */}
                                <div className={classes.formControl}>
                                    <div className={classes.formControlLabel}>
                                        {t('Average Working Days Per Month')}*
                                    </div>
                                    <StyledInput
                                        type="number"
                                        value={averageWorkDays[vehicleICE]}
                                        placeholder='Enter work days'
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            if (value > 31) return;
                                            if (/^[0-9.+]*$/.test(value)) {
                                                setAverageWorkDays(prev => ({ ...prev, [vehicleICE]: value, [vehicleEV]: value }));
                                            }
                                        }}
                                    />
                                </div>

                                {/* <==== RWUP Km/Charge ====> */}
                                <div className={classes.formControl}>
                                    <div className={classes.formControlLabel}>
                                        {t('Fuel efficiency/Mileage')}
                                        <span className={classes.units}> {t('(km/kg or km/ltr)')} </span>
                                        *
                                    </div>
                                    <StyledInput
                                        type="number"
                                        value={rwup[vehicleICE]}
                                        placeholder='km/Kg or km/ltr'
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            if (/^[0-9.+]*$/.test(value)) {
                                                setRwup(prev => ({ ...prev, [vehicleICE]: value }));
                                            }
                                        }}
                                    />
                                </div>

                                {/* <==== Charging Rate(INR/kWh)/ Fuel Cost  ====> */}
                                <div className={classes.formControl}>
                                    <div className={classes.formControlLabel}>
                                        {t('Fuel cost ')}
                                        <span className={classes.units}> {t('(INR/kg) (INR/ltr)')} </span>
                                        *
                                    </div>
                                    <StyledInput
                                        type="number"
                                        value={chargingRate[vehicleICE]}
                                        placeholder='INR/kg or INR/ltr'
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            if (/^[0-9.+]*$/.test(value)) {
                                                setChargingRate(prev => ({ ...prev, [vehicleICE]: value }))
                                            }
                                        }}
                                    />
                                </div>
                            </form>

                            {/* <==== Calculate btn ====> */}
                            <div className={`${classes.formControl} ${classes.centerFlex}`}>
                                <Button variant='contained' className={classes.tcoButtons}
                                    onClick={() => {
                                        calculateTotalCost();
                                    }}
                                    disabled={calculationBtnDisabled}
                                >
                                    {t('Calculate Savings')}
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div ref={pdfMainRef} >
                                <div id="pdfRef" ref={pdfRef} className={classes.pdfMainContainer}
                                    style={{ backgroundImage: showPopup ? `url(/images/documents/tco_pdf_bg.png)` : 'none', }} >

                                    {showPopup && (
                                        <>
                                            <img
                                                src={`/images/documents/mahindra_treo_clubbed.png`}
                                                alt=" logo"
                                                className={classes.companyBranding}
                                            />
                                            <div className={classes.userDetails} style={{
                                                // paddingBottom: isEmpty(customerName) && isEmpty(vehicleName) ? '1.5rem' : '0',
                                            }}>
                                                {
                                                    !isEmpty(customerName) && (
                                                        <>
                                                            <div className={classes.pdfHeadingsText}>{`Customer Name: ${customerName}`}</div>
                                                            <div className={classes.pdfHeadingsText}>{`Vehicle Name: ${vehicleName}`}</div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </>
                                    )}

                                    {/* <==== Total saving for 1 day ====> */}
                                    <div className={classes.textContainer}>
                                        <div className={classes.resultsHeading}>  {t('Running cost savings per day:')}  </div>
                                        <div className={classes.resultsHighlightedText}>
                                            INR {parseFloat(costSavingsPerDay).toLocaleString('en-IN')}
                                        </div>
                                    </div>

                                    {/* <==== Total savings per month ====> */}
                                    <div className={classes.textContainer}>
                                        <div className={classes.resultsHeading}>  {t('Running cost savings per month:')} </div>
                                        <div className={classes.resultsHighlightedText}>
                                            INR {parseFloat(costSavingsPerMonth).toLocaleString('en-IN')}
                                        </div>
                                    </div>

                                    {/* <==== Total savings next 5 years ====> */}
                                    <div className={classes.textContainer}>
                                        <div className={classes.resultsHeading}>  {t('Running cost savings for next 5 years:')} </div>
                                        <div className={classes.resultsHighlightedText}>
                                            INR {parseFloat(costSavingsNext5Years).toLocaleString('en-IN')}
                                        </div>
                                    </div>

                                    {/* <==== Running cost savings per KM ====> */}
                                    <div className={classes.textContainer}>
                                        <div className={classes.resultsHeading}>
                                            {t('Running cost savings per KM')}:
                                        </div>

                                        <div className={classes.runningCostContainer}>
                                            <div className={classes.resultsHighlightedText}>
                                                INR {parseFloat(runningCostPerKmICE - runningCostPerKmEV).toLocaleString('en-IN')}
                                                {/* <CurrencyRupeeIcon className={classes.currencyIcon} /> {parseFloat(runningCostPerKM.ICE - runningCostPerKM.EV).toLocaleString('en-IN')} */}
                                            </div>

                                            <div className={classes.resultsHeading}>
                                                or
                                            </div>

                                            <div className={classes.resultsHighlightedText}>
                                                {parseInt(((runningCostPerKmICE - runningCostPerKmEV) / runningCostPerKmICE) * 100)}%
                                            </div>
                                        </div>
                                    </div>

                                    {/* <==== Running cost per KM ====> */}
                                    <div className={classes.textContainer}>
                                        <div className={classes.resultsHeading}>
                                            {t('Running cost per KM')}:
                                        </div>

                                        <div className={classes.runningCostContainer}>
                                            <div>
                                                <div className={classes.resultsHeading} style={{
                                                    color: showPopup ? palette.white : palette.orange
                                                }}>
                                                    {t('For EV')}:
                                                </div>
                                                <div className={classes.resultsHighlightedText}>
                                                    INR {parseFloat(runningCostPerKmEV).toLocaleString('en-IN')}
                                                </div>
                                            </div>
                                            <div>
                                                <div className={classes.resultsHeading} style={{
                                                    color: showPopup ? palette.white : palette.orange
                                                }}>
                                                    {t('For ICE')}:
                                                </div>
                                                <div className={classes.resultsHighlightedText}>
                                                    INR {parseFloat(runningCostPerKmICE).toLocaleString('en-IN')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        showPopup && (
                                            <div className={classes.userDetails}
                                                style={{ paddingTop: '1.5rem' }}>
                                                {
                                                    !isEmpty(yourName) && (
                                                        <div className={classes.pdfHeadingsText}>{t('FSC Name')}: {yourName}</div>
                                                    )
                                                }
                                                {
                                                    !isEmpty(contactNumber) && (
                                                        <div className={classes.pdfHeadingsText}>{t('Contact Number')}: {contactNumber}</div>
                                                        // })}>Contact Number: <a href={`tel:${contactNumber}`}>{contactNumber}</a></Typography>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            {/* btn to share pdf that wont be in pdf */}
                            <div className={`${classes.formControl} ${classes.centerFlex}`}>
                                <Button variant='contained' className={classes.tcoButtons}
                                    onClick={() => {
                                        setShowPopup(true);
                                        handleCloseClick();
                                    }}
                                >
                                    {t('Share Savings')}
                                </Button>
                            </div>
                        </>
                    )}
                </div >
            </div >

            {/* <==== custom dialog box ====> */}
            {
                showPopup && <div className={`${classes.customDialog} `}>
                    <div className={classes.dialogHeader}>
                        <div className={classes.popupLabel}>
                            {t('Savings Document')}
                        </div>
                        <div
                            className={`${classes.centerFlex} ${classes.backButton}`}
                            onClick={() => setShowPopup(false)}
                        >
                            <CloseRoundedIcon sx={theme => ({ fontSize: '2rem', color: theme.palette.orange })} />
                        </div>
                    </div>
                    <div className={classes.dialogBody}>
                        <div className={classes.formWrapper}>
                            {/* <==== Customer Name ====> */}
                            <div className={classes.formControl}>
                                <div className={classes.popupLabel}>{t('Enter Customer Name')} *</div>
                                <StyledInput
                                    type="text"
                                    value={customerName}
                                    placeholder='Enter name'
                                    onChange={(e) => setCustomerName(e.target.value)}
                                />
                            </div>

                            {/* <==== Vehicle Name ====> */}
                            <div className={classes.formControl}>
                                <div className={classes.popupLabel}>{t('Vehicle Name')} *</div>
                                <StyledInput
                                    type="text"
                                    value={vehicleName}
                                    placeholder='Enter vehicle name'
                                    onChange={(e) => setVehicleName(e.target.value)}
                                />
                            </div>

                            {/* <==== Enter Your name ====> */}
                            <div className={classes.formControl}>
                                <div className={classes.popupLabel}>{t('Your Name')} *</div>
                                <StyledInput
                                    type="text"
                                    value={yourName}
                                    placeholder='Enter your name'
                                    onChange={(e) => setYourName(e.target.value)}
                                />
                            </div>

                            {/* <==== Enter Your Contact Number ====> */}
                            <div className={classes.formControl}>
                                <div className={classes.popupLabel}>{t('Your Contact Number')} *</div>
                                <StyledInput
                                    type="number"
                                    value={contactNumber}
                                    placeholder='Enter phone number'
                                    onChange={(e) => setContactNumber(e.target.value)}
                                />
                            </div>

                            <div className={`${classes.formControl} ${classes.centerFlex}`}>
                                {
                                    loadingPdf ? (
                                        <div className={`${classes.pdfGenerateLoader} ${classes.centerFlex}`}>
                                            <CircularProgress size={30} />
                                            <span>Generating</span>
                                        </div>
                                    ) : (
                                        <Button variant='contained' className={classes.tcoButtons}
                                            onClick={() => {
                                                isEmpty(tcoPdfData) ? handleSharePdf() : handleShare();
                                                handleCloseClick();
                                            }}
                                            disabled={shareBtnDisabled}
                                        >
                                            {isEmpty(tcoPdfData) ? t('Generate PDF') : t('Share Savings PDF')}
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                </div>
            }
        </div >
    )
}

export default SavingsCalculator;