export const eventActionTypes = {
    LOGIN_PAGE: 'LOGIN_PAGE',
    LOGIN_BUTTON_CLICK: "LOGIN_BUTTON_CLICK",
    LOGIN_OTP: "LOGIN_OTP",
    SUBMIT_OTP: "SUBMIT_OTP",
    SCREEN_CHANGE: "SCREEN_CHANGE",
    LEARN_PAGE: "LEARN_PAGE",
    LOGGED_IN: "LOGGED_IN",
    MICROSKILLS_LOADED: "MICROSKILLS_LOADED",
    DOCUMENTS_PAGE: "DOCUMENTS_PAGE",
    SALES_ENABLEMENT_PAGE: "SALES_ENABLEMENT_PAGE",
    DOCUMENTS_LOADED: "DOCUMENTS_LOADED",
    PERFORMANCE_PAGE: "PERFORMANCE_PAGE"
}