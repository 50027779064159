import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

// mui
import { Button, CircularProgress } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';

// components
import ScoreBox from "../score-box";

// redux
// import { getChallengeData } from "../../redux/microskill/microskill.api";

// utils
import isEmpty from "../../../utils/isEmpty";
import commonUtil from "../../../utils/commonUtil";

// constants
import constants from "../../../constants";

// theme
import palette from "../../../theme/palette";

// styles
import useStyles from "./index.styles";

const textBoxRefStyle = {
    border: 'none',
    borderRadius: '2px',
    width: 'auto',
    height: 'auto',
    padding: '0.7rem 1rem',
    boxShadow: constants.ILA_OPTION_BOX_SHADOW,
}

const IlaFitbTwo = (props) => {
    const {
        data,
        prev,
        next,
        attemptedIds,
        ilaId,
        scoreListRef,
        addScore,
        maxScore,
        scoreValueRef,
        handleChallengeAttemptData,
        styles,
        CorrectTemplate,
        IncorrectTemplate,
        SkipButton,
        isChallengeSubmitted,
        skipButtonDisplayTime,
        cardId
    } = props
    // console.log("IlaFitbTwo:", data)
    const classes = useStyles();
    // const { microskillId, challengeId } = useParams();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const [activeQindex, setActiveQindex] = useState(0);
    const [showTemplate, setShowTemplate] = useState(false);
    const [viewSkipBtn, setViewSkipBtn] = useState(false);
    const [componentMounted, setComponentMounted] = useState(true);
    const ilaRootRef = useRef();
    const ilaBoxRef = useRef();
    const jumbleWordRef = useRef();
    const answeredTextRef = useRef();
    const scoreBoxRef = useRef();
    const fitbGameContainerRef = useRef();
    const isCorrectRef = useRef(false);
    const currentTimeRef = useRef(Date.now());
    const answeredTextArrRef = useRef([]);
    const [jumbledWordArray, setJumbledWordArray] = useState([]);
    const [answeredText, setAnsweredText] = useState('');
    const [showButtonType, setShowButtonType] = useState(false);
    const isCharClickAllowed = useRef(true);
    const answer = data[activeQindex].answers[0].answer;
    let noOfRandomChar = isMobile ? 0 : 0;

    useEffect(() => {
        return () => {
            setComponentMounted(false)
        }
    }, []);

    useEffect(() => {
        if (!isChallengeSubmitted || !componentMounted) return
        setTimeout(() => {
            setViewSkipBtn(true)
        }, skipButtonDisplayTime);
    }, [isChallengeSubmitted]);

    useEffect(() => {
        if (isEmpty(data)) return
        let tempCharObjArr = [];
        let answer = data[activeQindex].answers[0].answer;
        let charArray = [...answer.replace(" ", "").split(""), ...Array.from(Array(noOfRandomChar), (e, i) => (commonUtil.getRandomAlphabet()))];
        charArray.sort(() => Math.random() - 0.5);
        charArray.forEach((char, index) => {
            let obj = {
                char: char.toUpperCase(),
                isClicked: false,
                id: `${Date.now()}_${index}`,
                seq: index + 1
            };
            tempCharObjArr.push(obj);
        });
        // console.log("jumbledWordArray:", tempCharObjArr);
        setJumbledWordArray(tempCharObjArr)
    }, [data, activeQindex]);

    useEffect(() => {
        if (jumbledWordArray.length === 0) return
        setShowButtonType(!isMobile && jumbledWordArray.length <= 5)
    }, [jumbledWordArray]);

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    const handleCharClick = (charDetails, i) => {
        if (!isCharClickAllowed.current) return
        let jumbledWordArrayCopy = [...jumbledWordArray];
        jumbledWordArrayCopy[i].isClicked = !charDetails.isClicked;
        setJumbledWordArray([...jumbledWordArrayCopy]);
        let charMatchingIndex = answeredTextArrRef.current.findIndex(d => d.id === charDetails.id);
        let answeredTextArrCopy = [...answeredTextArrRef.current];
        if (charMatchingIndex !== -1) answeredTextArrCopy.splice(charMatchingIndex, 1)
        else answeredTextArrCopy.push(charDetails);
        answeredTextArrRef.current = answeredTextArrCopy;
        setAnsweredText(answeredTextArrRef.current.reduce((all, item) => (all + (item.isClicked ? item.char : '')), ''))
    }

    const handleAnswerBtnClick = async () => {
        if (isEmpty(answeredText)) return
        let showScore = data[activeQindex].score > 0;
        const answerItem = data[activeQindex];
        let indexToSplice = attemptedIds.current.indexOf(ilaId);
        if (indexToSplice !== -1) {
            attemptedIds.current.splice(indexToSplice, 1);
        }

        isCharClickAllowed.current = false;
        const answeredTextElement = answeredTextRef.current;
        const answerStatus = answeredText.toUpperCase() === answer.toUpperCase();

        // handle score logic
        if (answerStatus) scoreListRef.current.add(`${answerItem.id}-${answerItem.score}`);

        let currentTime = Date.now();
        handleChallengeAttemptData({
            qId: answerItem.answers[0].questionId,
            cardId: cardId,
            t: Math.round((currentTime - currentTimeRef.current) / 1000),
            aId: answerItem.answers[0].id,
            isCorrect: answerStatus
        });
        currentTimeRef.current = currentTime;

        async function handleAnsweredStyle() {
            isCorrectRef.current = answerStatus;

            ilaBoxRef.current.style.gridTemplateRows = '15% 30% 30% 25%';
            fitbGameContainerRef.current.style.gridTemplateRows = '100% 0%';
            if (showScore) {
                await addScore(data[activeQindex]?.score ? data[activeQindex].score : 0, answerStatus);
            }
            // addScore();
            // scoreBoxRef.current.style.opacity = '1';
            jumbleWordRef.current.style.opacity = '0';
            Object.assign(answeredTextElement.style, { ...textBoxRefStyle, backgroundColor: answerStatus ? palette.ilaCorrect1 : palette.ilaInCorrect1 });
            setShowTemplate(true);
        }

        function handleReset() {
            ilaBoxRef.current.style = null;
            fitbGameContainerRef.current.style = null;
            // scoreBoxRef.current.style = null;
            jumbleWordRef.current.style = null;
            answeredTextRef.current.style = null;
            setShowTemplate(false);
        }

        await sleep(200);
        handleAnsweredStyle(answerStatus); // set style
        await sleep(2000);
        handleReset()  // reset style

        // reset values
        handleRefreshBtnClick();

        ilaBoxRef.current.style = null;
        isCharClickAllowed.current = true;
        // return
        if (data.length - 1 > activeQindex) {
            if (answerItem.mandatory && !answerStatus) return; // INCORRECT 
            else setActiveQindex(prev => prev + 1); // CORRECT
        } else {
            if (answerItem.mandatory && !answerStatus) return
            attemptedIds.current.push(ilaId);
            next();
        }
    }

    const handleRefreshBtnClick = () => {
        let charArray = jumbledWordArray.map(m => {
            m.isClicked = false;
            return m
        });
        setJumbledWordArray(charArray);
        answeredTextArrRef.current = [];
        setAnsweredText([]);
    }

    const Buttons = () => {
        return (
            <div className={classes.btnBox}>
                <>
                    <div className={classes.charBox}>
                        <Button variant="contained"
                            className={`${classes.answerBtn} ${classes.centerFlex}`}
                            onClick={handleAnswerBtnClick}>
                            Answer
                        </Button>
                    </div>
                    <div className={classes.charBox}>
                        <Button variant="contained"
                            className={`${classes.answerBtn} ${classes.centerFlex}`}
                            onClick={handleRefreshBtnClick}>
                            <RefreshIcon sx={{ fontWeight: 800 }} />
                        </Button>
                    </div>
                </>
            </div>
        )
    }

    return (
        <>
            {
                isEmpty(data) ? (
                    <div className={`${classes.loaderBox} ${classes.centerFlex}`}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div className={classes.ilaRoot} ref={ilaRootRef}>
                        <div className={classes.ilaBox} ref={ilaBoxRef}>
                            {/* <div className={classes.scoreBox}>{`40 / 120`}</div> */}
                            {/* <div className={classes.questionBox}> */}
                            <div className={styles.questionHead}>
                                {`Q${data[activeQindex].questionNo}`}
                            </div>
                            <div className={`${styles.question} ${classes.centerFlex}`}>
                                {data[activeQindex].question}
                                {
                                    showTemplate && (
                                        <div className={classes.correctIncorrectTextBox}>
                                            {isCorrectRef.current ? (<CorrectTemplate />) : (<IncorrectTemplate text={data[activeQindex].mandatory && 'TRY AGAIN!'} />)}
                                        </div>
                                    )
                                }
                            </div>

                            <div className={classes.fitbGameContainer} ref={fitbGameContainerRef}>
                                <div className={`${classes.textBox} ${classes.centerFlex}`} ref={answeredTextRef}>{answeredText}</div>
                                <div className={`${classes.jumbleWordBox} `}>
                                    <div className={`${classes.jumbleWord} scrollbar`} ref={jumbleWordRef}>
                                        {
                                            jumbledWordArray.map((item, index) => (
                                                <div className={classes.charBox} key={item.id}>
                                                    <Button variant="contained"
                                                        className={`${classes.charBtn} ${item.isClicked ? classes.highlight : ''}`}
                                                        onClick={() => handleCharClick(item, index)}
                                                    > {item.char}
                                                    </Button>
                                                </div>
                                            ))
                                        }
                                        {
                                            !showButtonType && (
                                                <Buttons />
                                            )
                                        }
                                    </div>
                                    {
                                        showButtonType && (
                                            <Buttons />
                                        )
                                    }

                                </div>

                            </div>

                            <ScoreBox scoreBoxRef={scoreBoxRef} scoreValueRef={scoreValueRef} maxScore={maxScore} />
                            {
                                viewSkipBtn && (<div> <SkipButton /> </div>)
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default IlaFitbTwo;