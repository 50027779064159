import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    otpBoxDesktop: {
        display: 'flex',
        gap: '0.5rem',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& input': {
            width: '3rem',
            height: '3rem',
            padding: '0.5rem',
            textAlign: 'center',
            fontSize: '2rem',
            fontWeight: '600',
            color: palette.greyText2
        }
    }
}))

export default useStyles