import React from "react";
import OtpLogin from "../../components/otp-login";

const OtpLoginPage = () => {
    return (
        <div>
            <OtpLogin />
        </div>
    )
}

export default OtpLoginPage;