import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lottie from 'react-lottie-player';

// mui
import { Button } from "@mui/material";

// components
import wrongAnswerLottie from '../../../../lottie-assets/life_exhaust_game_over.json';
import timeOutLottie from '../../../../lottie-assets/timeout_game_over.json';

// utils
import isEmpty from "../../../../utils/isEmpty";

// Style
import useStyle from "./index.styles";
import { useTranslation } from "react-i18next";
import GbaInsights from "../gba-insights";

const GbaGameOverScreen = (props) => {
    const {
        type,
        gameType,
        gameData,
        incorrectAttempt,
        bg,
        restartGame,
        bgGradient = 'linear-gradient(transparent 25%, #f63b34)',
        // bgGradient = 'linear-gradient(#726868, #f63b34)',
        gameScore,
        questions
    } = props;


    // const { setting } = gameData;
    const [isShowAnswerBtn, setIsShowAnswerBtn] = useState(false);
    const [showData, setShowData] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);

    useEffect(() => {
        let timeout;
        if (!showData) {
            timeout = setTimeout(() => {
                setShowData(true);
            }, 2500)
        }
        return () => {
            if (!isEmpty(timeout)) clearTimeout(timeout);
        }
    }, [])
    const sessionStartTime = '';
    let animationData = type === 'time' ? timeOutLottie : wrongAnswerLottie;
    let mainText = type === 'time' ? "TIME OUT" : 'GAME OVER';
    let subText = `You ran out of ${type === 'time' ? 'time' : 'lives'}`;

    const { t, i18n } = useTranslation()
    const classes = useStyle(i18n);

    const handleTryBtnClick = () => {
        restartGame();
    }

    const handleShowAllAnswers = () => {
        setShowAnswer(true);
    };

    useEffect(() => {
        if (type !== 'gameover') return;
        if (isEmpty(gameData?.setting)) return;
        if (isEmpty(gameData?.setting?.activateAnswerAttempt)) return;
        if (isEmpty(incorrectAttempt)) return;

        if (incorrectAttempt >= gameData?.setting?.activateAnswerAttempt) {
            setIsShowAnswerBtn(true);
        }
    }, [gameData?.setting, type, incorrectAttempt]);

    return (

        <>
            <div className={classes.gameOverRoot} style={{
                background: `${bgGradient} ${!isEmpty(bg) ? `, url(${bg})` : ''}`,
                backgroundSize: bg.includes('tasty-truth') ? 'cover' : 'contain',
                filter: showAnswer ? 'blur(25px)' : 'none',
                position: 'relative',
                zIndex: '1'
            }}>


                {/* <GbaHeader title={gameName} height={'11%'} headerBg={headerBg} handleBackButtonClick={challengeList} /> */}
                <div className={classes.gameOverScreenContainer} >
                    {
                        showData && (
                            <>
                                <div className={classes.lottieBox}>
                                    <Lottie
                                        loop={false}
                                        animationData={animationData}
                                        play
                                        style={{ height: '100%' }}
                                    />
                                </div>

                                <div className={classes.typeText}>
                                    <div className={classes.typeMainText} style={{ fontSize: i18n.language === 'en' ? "6em" : "5em" }}>{t(mainText)}!</div>
                                    <div className={classes.typeSubText}>{t(subText)}.</div>
                                </div>

                                <div className={classes.tryBtnBox}>
                                    <Button
                                        style={{ height: '3rem', fontSize: '1.5rem', marginBottom: '1rem' }}
                                        variant="contained"
                                        color="gbabtn"
                                        fullWidth
                                        size="small"
                                        sx={{
                                            fontSize: i18n.language === 'en' ? "2.5em !important" : "2em !important",
                                        }}
                                        className={`${classes.tryBtn}`}
                                        onClick={handleTryBtnClick}>
                                        {t("TRY AGAIN")}
                                    </Button>


                                    {
                                        isShowAnswerBtn && <Button
                                            style={{ height: '3rem', fontSize: '1.5rem', color: '#fff', fontFamily: 'motionControl' }}
                                            fullWidth
                                            size="small"
                                            sx={{
                                                fontSize: i18n.language === 'en' ? "2.5em !important" : "2em !important",
                                            }}
                                            className={`${classes.tryBtn}`}
                                            onClick={handleShowAllAnswers}>
                                            {t("SHOW ANSWERS")}
                                        </Button>
                                    }

                                </div>
                            </>
                        )}
                </div>
            </div>

            {showAnswer &&

                <GbaInsights
                    gameType={gameType}
                    gameData={gameData}
                    restartGame={restartGame}
                />
            }
        </>
    )
}

export default GbaGameOverScreen;