import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userName: null,
  screen: 'LOGIN PAGE',
  device: null,
  details: {},
};

const loginEventSlice = createSlice({
  name: 'loginEvent',
  initialState,
  reducers: {
    loginPage: (state) => {
      // No state changes for loginPage action
    },
    loginUserName: (state, action) => {
      state.userName = action.payload.userName;
    },
    loginPassword: (state, action) => {
      // Assuming password is part of details
      state.details.password = action.payload.password;
    },
    loginOTP: (state, action) => {
      state.details.otp = action.payload.otp;
    },
    loginError: (state, action) => {
      state.error = action.payload.error;
    },
  },
});

export const { loginPage, loginUserName, loginPassword, loginOTP, loginError } = loginEventSlice.actions;

export default loginEventSlice.reducer;