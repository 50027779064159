import React, { useEffect, useState } from 'react'

//mui
import { List, ListItemButton, ListItemText, Collapse, Typography, Box, CircularProgress } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MuiCheckbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";

//utils
import commonUtil from '../../../../utils/commonUtil';

//multilingual
import { useTranslation } from "react-i18next";

//common-styled
import { EmptyBox, LoadingBox, listButtonStyle, listItemStyle } from '../common-styled'

//theme
import palette from '../../../../theme/palette';


const SkillAnalysis = ({ isDarkMode, loading = false, skillData = [] }) => {
    const classes = useStyles()
    const { t, i18n } = useTranslation()
    const [expanded, setExpanded] = useState(false);

    const handleExpandToggle = () => setExpanded(!expanded);

    return (
        <>
            <ListItemButton
                sx={theme => ({
                    backgroundColor: isDarkMode ? theme.palette.darkVoilet : theme.palette.white, color: theme.palette.fontColor, padding: '0.8rem 1rem', paddingBottom: expanded ? '0.4rem' : '0.8rem', marginBottom: expanded ? 0 : '1rem',
                    '&:hover': { backgroundColor: isDarkMode ? theme.palette.darkVoilet : theme.palette.white, color: theme.palette.fontColor }
                })}
                onClick={handleExpandToggle}
            >
                <ListItemText
                    disableTypography
                    primary={
                        <Typography
                            variant="body2"
                            sx={(theme) => ({
                                color: theme.palette.fontColor,
                                textAlign: 'left',
                                font: 'normal normal 800 1.2rem Montserrat',
                                letterSpacing: '1px',
                                opacity: '1',
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: '0px',
                            })}>
                            {t('Skill Analysis')}
                            {!expanded && loading && <CircularProgress sx={{ height: '20px !important', width: '20px !important', marginLeft: '1rem' }} />}
                           
                            {!expanded ? <KeyboardArrowDownIcon style={{ color: '#F4511E', position: 'absolute', right: '1rem' }} /> : <KeyboardArrowUpIcon style={{ color: '#F4511E', position: 'absolute', right: '1rem' }} />}
                        </Typography>
                    }
                />
            </ListItemButton>
            <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ marginBottom: expanded ? '1rem' : 0 }}>
                <Box sx={theme => ({
                    backgroundColor: isDarkMode ? theme.palette.darkVoilet : theme.palette.white,
                    color: theme.palette.fontColor,
                    padding: '0.2rem 1rem',
                    display: 'flex',
                    gap: '1.5rem',
                    '&:hover': {
                        backgroundColor: isDarkMode ? theme.palette.darkVoilet : theme.palette.white,
                        color: theme.palette.fontColor
                    }
                })}>
                    <div className={classes.legend}>
                        <div style={{ backgroundColor: palette.lightGreen }}></div>
                        <div>Strong Skill</div>
                    </div>
                    <div className={classes.legend}>
                        <div style={{ backgroundColor: palette.errorColor }}></div>
                        <div>Weak Skill</div>
                    </div>
                </Box>
                {loading ?
                    <LoadingBox isDarkMode={isDarkMode} /> :

                    <List sx={(theme) => ({
                        display: 'flex',
                        flexDirection: 'column',
                        p: 0,
                        backgroundColor: isDarkMode ? theme.palette.darkVoilet : theme.palette.white,
                        padding: '0.5rem 1rem',
                        gap: '0.5rem',
                        maxHeight: '30rem',
                        overflow: 'auto',
                    })}>

                        {
                            !skillData.length ? <EmptyBox isDarkMode={isDarkMode} /> :
                                skillData.map((ele, idx) => (
                                    <Box key={idx} sx={(theme) => ({
                                        bgcolor: isDarkMode ? 'rgba(56,13,65,255)' : theme.palette.lightGrey1,
                                        padding: '0.7rem 0.5rem',
                                        display: 'flex',
                                        borderRadius: '3px'

                                    })}>
                                        <Typography
                                            variant="body2"
                                            sx={(theme) => ({
                                                color: theme.palette.fontColor,
                                                font: 'normal normal 700 0.8rem Montserrat',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexBasis: '50%',
                                                textTransform: 'capitalize',
                                                // padding: '0 0.5rem',
                                                // wordBreak:'break-word'
                                            })}>
                                            {ele.username}
                                        </Typography>
                                        <Box sx={(theme) => ({
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '0.6rem',
                                            flexBasis: '50%',
                                            alignItems: 'flex-end'
                                        })}>
                                            <Typography
                                                variant="body2"
                                                sx={(theme) => ({
                                                    color: theme.palette.white,
                                                    bgcolor: theme.palette.lightGreen,
                                                    font: 'normal normal 700 0.7rem Montserrat',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    flexBasis: '50%',
                                                    p: '0.6rem 0.4rem',
                                                    minWidth: '100%',
                                                    borderRadius: '2px',
                                                    minHeight: '2rem'
                                                })}>
                                                {ele?.strong || "N/A"}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={(theme) => ({
                                                    color: theme.palette.white,
                                                    bgcolor: theme.palette.errorColor,
                                                    font: 'normal normal 700 0.7rem Montserrat',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    flexBasis: '50%',
                                                    p: '0.6rem 0.4rem',
                                                    minWidth: '100%',
                                                    borderRadius: '2px',
                                                    minHeight: '2rem'
                                                })}>
                                                {ele?.weak || "N/A"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))
                        }

                    </List>
                }


            </Collapse>
        </>
    )
}

export default SkillAnalysis;




const useStyles = makeStyles((theme) => ({

    legend: {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        '& > div:first-child': {
            borderRadius: 2,
            height: '12px',
            width: '12px',
        },
        '& > div': {
            font: 'normal normal 500 0.7rem Montserrat',
            color: theme.palette.fontColor,
        }

    },
    empty: {
        textAlign: 'center',
        font: 'normal normal 500 0.8rem Montserrat',
        padding: '1rem 0'
    }

}))
