import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";
import constants from "../../constants";
import { fontSize } from "@mui/system";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    pageContainer: {
        backgroundColor: theme.palette.mobilePerfPageBg,
        // minHeight: `calc(${constants.FULL_HEIGHT_VALUE_NATIVE})`,
        minHeight: `calc(100dvh - (${constants.HEADER_HEIGHT} - 1rem))`,
        [theme.breakpoints.up('md')]: {
            backgroundColor: theme.palette.voilet,
            paddingLeft: '2rem',
            // height: '100vh',
        }
    },
    headerContainer: {
        height: '4.2rem',
        display: 'flex',
        padding: '1rem',
        position: 'relative',
    },
    headerText: {
        color: theme.palette.fontColor,
        fontSize: '1.3rem',
        fontWeight: 700
    }

}))


// const useStyles = makeStyles((theme) => ({
//     centerFlex: {
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//     },
//     cursorPointer: {
//         cursor: 'pointer'
//     },
//     pageContainer: {
//         backgroundColor: theme.palette.mobilePerfPageBg,
//         minHeight: `calc(${constants.FULL_HEIGHT_VALUE_NATIVE})`,
//         [theme.breakpoints.up('md')]: {
//             backgroundColor: theme.palette.voilet,
//             paddingLeft: '2rem',
//             height: '100vh',
//         }
//     },
//     nameContainer: {
//         display: "flex",
//         alignItems: "center",
//         justifyContent: 'flex-start',
//         paddingTop: '2rem',
//         paddingLeft: '1rem',
//         color: theme.palette.fontColor,
//         flexWrap: (isMobile) => {
//             return isMobile ? "wrap" : 'no-wrap'
//         },
//         [theme.breakpoints.up('md')]: {
//             width: '100%',
//             height: '10%',
//             margin: '2rem 0rem ',
//             flexWrap: 'no-wrap',
//             padding: '0',
//         }
//     },
//     userName: {
//         fontSize: '1.5rem',
//         width: '100%',
//         [theme.breakpoints.up('md')]: {
//             float: 'left',
//             fontSize: '2.6vw',
//             width: '40%'
//         }
//     },
//     leaderboardSection: {
//         backgroundColor: theme.palette.darkVoilet,
//         paddingTop: '1rem',

//         [theme.breakpoints.down('md')]: {
//             backgroundColor: theme.palette.mode === 'dark' ? theme.palette.darkVoilet : theme.palette.white,

//         },
//         [theme.breakpoints.up('md')]: {
//             width: '50%',
//             float: 'left',
//             // marginTop: '16px',
//             height: '85%',
//             borderRadius: '10px'
//         }
//     },
//     leaderboardHeading: {
//         marginLeft: '1rem',
//         fontSize: '2.2rem',
//         fontWeight: '900',
//         color: theme.palette.fontColor,
//         [theme.breakpoints.down('sm')]: {
//             fontSize: '1.5rem'
//         }
//     },
//     microskillContainerParent: {
//         [theme.breakpoints.down('md')]: {
//             width: '100%',
//             display: 'flex',
//             justifyContent: 'space-evenly'
//         }
//     },
//     microskillContainer: {
//         border: `2px solid ${theme.palette.white}`,
//         backgroundColor: theme.palette.voilet,
//         borderRadius: '10px',
//         textAlign: 'center',
//         position: 'relative',
//         width: '50%',
//         display: 'flex',
//         margin: '4% 2% 4% 0%',
//         padding: '0.5rem',
//         fontSize: '0.8rem',
//         alignItems: 'center',
//         [theme.breakpoints.up('md')]: {
//             width: '30%',
//             float: 'left',
//             margin: '0',
//             marginLeft: '2rem',
//             border: `2px solid ${theme.palette.fontColor}`,
//             width: (i18n) => i18n.language === 'en' ? '200px' : '250px',
//             fontSize: '1.5rem',
//             fontWeight: '500',
//         }
//     },
//     mcHeading: {
//         width: '55%',
//         fontSize: '2rem',
//         [theme.breakpoints.up('md')]: {
//             width: '52%',
//             float: 'left',
//             fontSize: '2.5rem'
//         }
//     },
//     mcHeading2: {
//         fontSize: '1rem',
//         [theme.breakpoints.down('md')]: {
//             fontSize: (i18n) => i18n.language === 'en' ? '1rem' : '0.8rem'
//         }
//     },
//     microskillListItem: {
//         color: '#FFFFFF',
//         textAlign: 'left',
//         font: 'normal normal bold 1.2rem Montserrat',
//         letterSpacing: '0px',
//         opacity: '1'
//     },
//     cardHeading: {
//         fontSize: '1.5rem',
//         fontWeight: '900',
//         marginBottom: '2rem',
//         paddingTop: '0.5rem',
//         [theme.breakpoints.up('md')]: {
//             fontSize: '2vw',
//             marginBottom: '3vw',
//             marginTop: '0.5vw'
//         }
//     },
//     cardSubHeading: {
//         fontSize: '0.5rem',
//         textTransform: "uppercase",
//         fontWeight: 'bold',
//         margin: '0.6rem 0rem',
//         [theme.breakpoints.up('md')]: {
//             fontSize: '0.8vw',
//             marginTop: '1.5rem',
//             marginBottom: '1.5rem'
//         }
//     },
//     cardValue: {
//         fontSize: '1.8em',
//         fontWeight: '900',
//         marginTop: '1rem',
//         [theme.breakpoints.up('md')]: {
//             fontSize: '2.7em',
//             marginTop: '2vw',
//             fontWeight: '900',
//             lineHeight: '1'
//         }
//     },
//     cardValue2: {
//         fontSize: '1.8em',
//         fontWeight: '900',
//         marginTop: '1rem',
//         [theme.breakpoints.up('md')]: {
//             fontSize: '2vw',
//             marginTop: '2vw',
//             fontWeight: '900'
//         }
//     },
//     cardFooter: {
//         fontSize: '0.6rem',
//         marginTop: '1rem',
//         [theme.breakpoints.up('md')]: {
//             marginTop: '3.5vw',
//             fontSize: '0.8vw'
//         }
//     },
//     cardBar: {
//         textAlign: 'center',
//         padding: '2px',
//         backgroundColor: '#F4511E',
//         width: '30%',
//         borderRadius: '10px',
//         position: 'relative',
//         left: '35%',
//         [theme.breakpoints.up('md')]: {
//             width: '30%',
//             left: '35%',
//             marginTop: '0.2vw',
//             marginBottom: '2.5vw',
//             padding: '5px'
//         }
//     },
//     gridItemCustom: {
//         [theme.breakpoints.up('md')]: {
//             padding: '32px 0',
//             '& p': {
//                 padding: '0 32px'
//             }
//         }

//     },
//     cardSpacing: {
//         padding: '1rem 0rem',
//         // backgroundColor: 'red',
//         // backgroundColor: theme.palette.headerBg,
//         // [theme.breakpoints.down('sm')]: {
//         // }
//     },
//     spotlightContainer: {
//         backgroundImage: 'linear-gradient(250deg, #F4511E -10%, #2D0037 55%)',
//         float: 'left',
//         width: '100%',
//         marginTop: '1rem',
//         padding: '1rem 2rem',
//         marginBottom: '20%',
//         borderRadius: '0px',
//         [theme.breakpoints.up('sm')]: {
//             borderRadius: '10px',
//             width: '98%',
//             marginTop: '3%',
//             marginBottom: '3%',
//         }

//     },
//     spotlightHeading: {
//         fontSize: '1.5rem', fontWeight: '900', [theme.breakpoints.up('sm')]: {
//             fontSize: '2.2rem'
//         }
//     },
//     slMicroskillCont: {
//         position: 'absolute',
//         fontWeight: '500',
//         fontSize: '1.1rem',
//         display: 'none',
//         overflow: 'auto',
//         backgroundColor: 'rgb(79, 0, 96)',
//         width: '88%',
//         zIndex: '1001',
//         padding: '4% 2%',
//         maxHeight: '50vw',
//         borderRadius: '10px',
//         '&::-webkit-scrollbar': {
//             width: '5px',

//         },
//         '&::-webkit-scrollbar-track': {
//             background: 'rgba(0,0,0,0.2)',
//             margin: '5px'
//         },
//         '&::-webkit-scrollbar-thumb': {
//             background: '#F4511E',
//             borderRadius: '10px'
//         },
//         [theme.breakpoints.up('sm')]: {
//             width: '30%',
//             padding: '1.2rem 1rem',
//             maxHeight: '12vw'
//         }
//     },
//     slMicroskillList: {
//         listStyle: 'none',
//     },
//     slSelectedMicroskill: {
//         cursor: 'pointer'
//     },
//     microListEl: {
//         paddingBottom: '3%',
//         borderBottom: '1px solid #999',
//         padding: '1%',
//         paddingTop: '3%',
//         cursor: 'pointer',
//         '&:hover': {
//             backgroundColor: 'rgba(0,0,0,0.4)',
//             borderRadius: '10px'
//         }
//     },
//     reactCustomClass: {
//         '& .react-multiple-carousel__arrow--right': {
//             right: 'calc(0% + 1px)'
//         },
//         '& .react-multiple-carousel__arrow--left': {
//             left: 'calc(0% + 1px)',
//         },
//         '& .react-multiple-carousel__arrow': {
//             backgroundColor: palette.orange,
//             '& ::before': {

//             }
//         }
//     },
//     spotlightCardContainer: {
//         marginTop: '2%'

//     },
//     spotlightVideo: {
//         marginRight: '1rem',
//         height: '5rem',
//         borderRadius: '5px',
//         marginTop: '1rem',
//         backgroundImage: 'linear-gradient(180deg, #FFFFFF00 0%, #000000 100%)',
//         [theme.breakpoints.up('sm')]: {
//             marginRight: '2rem',
//             height: '14rem',
//             border: '1px solid #fff',
//         }
//     },
//     spotlightName: {
//         position: 'absolute',
//         top: '60%',
//         marginLeft: '5%',
//         [theme.breakpoints.up('sm')]: {
//             top: '70%',
//         }
//     },
//     spotlightPoints: {
//         position: 'absolute',
//         top: '70%',
//         marginLeft: '5%',
//         [theme.breakpoints.up('sm')]: {
//             top: '80%',
//         }
//     },

//     // no microskill hoc css
//     textContainer: {
//         flexDirection: 'column',
//         justifyContent: 'flex-start',
//         alignItems: 'baseline',
//         rowGap: '2rem',
//         height: '100%',
//         width: '50%',
//         marginTop: '2rem',
//         [theme.breakpoints.down('sm')]: {
//             width: '100%',
//             padding: '0 1rem'
//         }
//     },
//     NoMicroskillText: {
//         fontSize: '3rem',
//         fontWeight: 'bold',
//         [theme.breakpoints.down('sm')]: {
//             fontSize: '2rem',
//         }
//     },
//     displayText: {
//         fontSize: '1.5rem',
//         [theme.breakpoints.down('sm')]: {
//             fontSize: '1rem',
//         }
//     },

// }))

export default useStyles