import { createSlice } from '@reduxjs/toolkit';
import { eventActionTypes } from './event.types';

const initialState = {
  oldScreen: '',
  currentScreen: 'LOGIN',
  nextScreen: '',
  activityType: '',
  activityData: {},
};

const eventTrackingSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    LoginPage: (state, action) => {
      state.oldScreen = state.currentScreen;
      state.currentScreen = action.payload.currentScreen;
      state.nextScreen = action.payload.nextScreen;
      state.activityData = action.payload.activityData;
      state.activityType = eventActionTypes.LOGIN_PAGE;
    },
    LoginButtonClick: (state, action) => {
      state.oldScreen = state.currentScreen;
      state.currentScreen = action.payload.currentScreen;
      state.nextScreen = action.payload.nextScreen;
      state.activityData = action.payload.activityData;
      state.activityType = eventActionTypes.LOGIN_BUTTON_CLICK;
    },
    LoginOtp: (state, action) => {
      state.oldScreen = state.currentScreen;
      state.currentScreen = action.payload.currentScreen;
      state.nextScreen = action.payload.nextScreen;
      state.activityData = action.payload.activityData;
      state.activityType = eventActionTypes.LOGIN_OTP;
    },
    ScreenChange: (state, action) => {
      state.oldScreen = state.currentScreen;
      state.currentScreen = action.payload.currentScreen;
      state.nextScreen = action.payload.nextScreen;
      state.activityData = action.payload.activityData;
      state.activityType = eventActionTypes.SCREEN_CHANGE;
    },
    LearnPage: (state, action) => {
      state.oldScreen = state.currentScreen;
      state.currentScreen = action.payload.currentScreen;
      state.nextScreen = action.payload.nextScreen;
      state.activityData = action.payload.activityData;
      state.activityType = eventActionTypes.LEARN_PAGE;
    },
    LoggedIn: (state, action) => {
      state.oldScreen = state.currentScreen;
      state.currentScreen = action.payload.currentScreen;
      state.nextScreen = action.payload.nextScreen;
      state.activityData = action.payload.activityData;
      state.activityType = eventActionTypes.LOGGED_IN;
    },
    MicroskillsLoaded: (state, action) => {
      state.oldScreen = state.currentScreen;
      state.currentScreen = action.payload.currentScreen;
      state.nextScreen = action.payload.nextScreen;
      state.activityData = action.payload.activityData;
      state.activityType = eventActionTypes.MICROSKILLS_LOADED;
    },
    DocumentPage: (state, action) => {
      state.oldScreen = state.currentScreen;
      state.currentScreen = action.payload.currentScreen;
      state.nextScreen = action.payload.nextScreen;
      state.activityData = action.payload.activityData;
      state.activityType = eventActionTypes.DOCUMENTS_PAGE;
    },
    DocumentsLoaded: (state, action) => {
      state.oldScreen = state.currentScreen;
      state.currentScreen = action.payload.currentScreen;
      state.nextScreen = action.payload.nextScreen;
      state.activityData = action.payload.activityData;
      state.activityType = eventActionTypes.DOCUMENTS_LOADED;
    },
    PerformancePage: (state, action) => {
      state.oldScreen = state.currentScreen;
      state.currentScreen = action.payload.currentScreen;
      state.nextScreen = action.payload.nextScreen;
      state.activityData = action.payload.activityData;
      state.activityType = eventActionTypes.PERFORMANCE_PAGE;
    },
    SalesEnablementPage: (state, action) => {
      state.oldScreen = state.currentScreen;
      state.currentScreen = action.payload.currentScreen;
      state.nextScreen = action.payload.nextScreen;
      state.activityData = action.payload.activityData;
      state.activityType = eventActionTypes.SALES_ENABLEMENT_PAGE;
    },
  },
});

export const {
  LoginPage,
  LoginButtonClick,
  LoginOtp,
  ScreenChange,
  LearnPage,
  LoggedIn,
  MicroskillsLoaded,
  DocumentPage,
  DocumentsLoaded,
  PerformancePage,
  SalesEnablementPage,
} = eventTrackingSlice.actions;

export default eventTrackingSlice.reducer;