
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
    mcqContainer: {
        padding: '1rem',
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '2rem',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '1rem',
    },
    correctAnswerText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1rem',
        fontWeight: '800',

    },
    optionContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    question: {
        fontSize: '1rem',
    },
    contextText: {
        fontSize: '1rem',
        paddingBottom: '1.8rem',
        color: theme.palette.yellow,
    },
    answerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '0.5rem 0',
        gap: '0.3rem',
        marginLeft: '1rem',
    },
    answerText: {
        margin: 0,
        fontSize: '1rem',
        color: 'chartreuse',
        fontWeight: '500',
    },
}));

export default useStyle;
