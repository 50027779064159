import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';

// jwt-decode
import { jwtDecode } from 'jwt-decode';

//  Okta
import { useOktaAuth } from '@okta/okta-react';

//  utils
import isEmpty from "../../utils/isEmpty"

//  redux
import { signIn } from '../../redux/auth/auth.api';

//  components
import LoadingMicroskill from '../../components/loading-microskill';

const OktaPage = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const oktaRequestToken = useSelector(state => state.auth.oktaRequestToken);

    useEffect(() => {
        (async function () {
            if (!isEmpty(authState) && authState.isAuthenticated) {
                const value = jwtDecode(authState.accessToken.accessToken).sub;
                const req = {
                    username: value,
                    password: ""
                }
                req["requestToken"] = oktaRequestToken;
                const respData = await signIn(req);
                if (respData.status === 200 || respData.status === 201) {
                    window.location.href = "/learn";
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState, oktaAuth])

    return (
        <LoadingMicroskill />
    )
}

export default OktaPage