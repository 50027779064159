import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    errorPageRoot: {
        display: 'grid',
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        gridTemplateRows: '70% 30%'
    },
    returnTextBox: {
        marginTop: '2rem'
    },
    returnText: {
        fontSize: '2rem',
        fontWeight: '600',
        textAlign: 'center',
        color: '#fff'
    },
    errorImage: {
        width: '100%'
    }
}))

export default useStyles