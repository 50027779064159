import React from "react";

// mui
import CircularProgress from '@mui/material/CircularProgress'

//styles
import useStyle from "./index.styles";


const AudioControls = ({ isPlaying, isReady, onPlayPauseClick, onPrevClick, onNextClick, from, isDarkMode }) => {
    console.log(isDarkMode, "isDarkMode")
    const classes = useStyle({ from });
    return (
        <div className={classes.root}>
            <img src={`/images/documents/prev_orange.svg`} onClick={onPrevClick} width={40} height={40} style={{filter: isDarkMode ? 'brightness(0) invert(1)' : 'none'}} />
            {
                isReady ? (
                    isPlaying ? (
                        <img src={`/images/documents/pause-1x_orange.png`} onClick={() => onPlayPauseClick(false)} width={40} height={40} style={{filter: isDarkMode ? 'brightness(0) invert(1)' : 'none'}} />

                    ) : (
                        <img src={`/images/documents/play_orange.svg`} onClick={() => onPlayPauseClick(true)} width={40} height={40} style={{filter: isDarkMode ? 'brightness(0) invert(1)' : 'none'}} />

                    )
                ) : (<CircularProgress />)

            }
            <img src={`/images/documents/next_orange.svg`} onClick={onNextClick} width={40} height={40} style={{filter: isDarkMode ? 'brightness(0) invert(1)' : 'none'}} />
        </div>
    )
};

export default AudioControls;
