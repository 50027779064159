import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    coverCardRoot: {
        height: '100%',
        width: '100%',
        padding: ({ viewFullScreen, isMobile }) => {
            return viewFullScreen || isMobile ? 0 : '1rem'
        },
        backgroundColor: '#FFF',
        borderRadius: ({ viewFullScreen, isMobile }) => {
            return viewFullScreen || isMobile ? 0 : '0.5rem'
        },
        overflow: 'hidden',
        [theme.breakpoints.down("sm")]: {
            padding: '0',
            borderRadius: '0',
        }
    },
    coverCard: {
        position: 'relative',
        height: '100%',
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        // [theme.breakpoints.down("md")]: {
        //     gridTemplateRows: '20% 20% 60%',
        // }
    },

    coverCardDetailBox: {
        position: 'relative',
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: '20% 50% 30%',
        color: theme.palette.white,
        [theme.breakpoints.down("md")]: {
            gridTemplateRows: '20% 20% 60%',
        }
    },
    backdropCoverCard: {
        position: 'absolute',
        inset: 0,
        background: 'black',
        opacity: '0.5',
    },

    // container to animate cover card image
    bgImageCoverCard: {
        position: 'absolute',
        inset: 0,
        overflow: 'hidden',
        // background: 'black',
        // opacity: '0.5',
    },
    bgImageCoverCardImg: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        OAnimation: '$bgZoomIn 60s 0s infinite linear',
        MozAnimation: '$bgZoomIn 60s 0s infinite linear',
        WebkitAnimation: '$bgZoomIn 60s 0s infinite linear',
        animation: '$bgZoomIn 60s 0s infinite linear',
    },
    // container to animate cover card image

    coverCardTitle: {
        fontSize: '4rem',
        // textTransform: 'uppercase',
        fontWeight: 800,
        textAlign: 'center',
        padding: '0 0.5rem',
        [theme.breakpoints.down("md")]: {
            fontSize: '2.5rem',
            fontWeight: 700,
        }
    },
    coverCardDetails: {
        gap: '2.5rem',
        [theme.breakpoints.down("md")]: {
            alignItems: 'flex-end',
            paddingBottom: '2rem'
        }
    },
    iconBox: {
        gap: '1rem'
    },
    detailText: {
        fontSize: '1.5rem',
        fontWeight: 600,
        fontFamily: 'Open Sans',
        [theme.breakpoints.down("md")]: {
            fontSize: '1rem',
        }
    },

    '@keyframes bgZoomIn': {
        '0%, 100%': {
            transform: 'scale(1)'
        },
        '50%': {
            transform: 'scale(1.5)'
        }
    },
}))

export default useStyles