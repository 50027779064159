import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    recommendedVideoPreviewRoot: {
        display: 'grid',
        height: '100%',
        gridTemplateRows: '10% 70%, 20%'
    },
    header: {
        display: 'grid',
        justifyContent: 'space-between',
        gridTemplateColumns: '85% 15%'
    },
    microskillName: {
        fontSize: '1rem',
        fontWeight: 600
    },
    closeBtnBox: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        background: '#ffffff59'
    },
    modalBody: {
        // paddingTop: '2rem'
        height: '100%',
    },
    videoBox: {
        width: '100%',
        position: 'relative',
        borderRadius: '10px'
    },
    videoBodyBox: {
        position: 'relative'
    },
    learnerName: {
        fontWeight: 700
    },
    videoParameters: {
        position: 'absolute',
        inset: '0 0 0 0',
        left: 0,
        top: 0,
        display: 'grid',
        padding: '1rem',
        alignContent: 'space-between'
    },
    param: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    paramText: {
        fontSize: '0.7em',
        fontWeight: 800
    },
    paramValue: {
        fontSize: '0.7em',
        fontWeight: 800,
        textTransform: 'uppercase'
    },
    paramBox: {
        display: 'grid',
        gap: '0.7rem'
    },
    videoScrubber: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        padding: '1rem'
    },
    scrubberBox: {
        width: '100%',
        position: 'relative'
    },
    scrubber: {
        width: '100%',
        height: '5px',
        borderRadius: '10px',
        backgroundColor: '#fff',
        overflow: 'hidden',
        position: 'relative'
    },
    timeElapsed: {
        backgroundColor: palette.orange,
        height: '100%',
        width: '0%',
        position: 'absolute',
        left: 0,
        top: 0
    },
    videoTime: {
        position: 'absolute',
        textAlign: 'right',
        right: 0,
        fontWeight: 700,
        fontSize: '0.8rem',
        paddingTop: '0.5rem'
    },
    reactPlayerClass: {
        '& video': {
            borderRadius: '10px',
        }
    },
}))

export default useStyles;