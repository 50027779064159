const eventBus = {
    on(event, callback) {
        // console.log("on:", callback)
        document.addEventListener(event, callback, false);
        // document.addEventListener(event, (e) => callback(e.detail), false);
    },
    dispatch(event, data) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }, false));
    },
    remove(event, callback) {
        // console.log("removing:", event)
        document.removeEventListener(event, callback, false);
    },
};

export default eventBus;