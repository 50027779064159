import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

// mui
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// components
import FileViewer from "../../components/file-viewer";
import SalesEnablementMobileView from "../../components/sales-enablement-mobile-view";


// redux
import { getAllContentRepoData } from "../../redux/content-repo/content-repo.api";
import { submitUserActivityData } from "../../redux/event-tracking/event.api";
import { DocumentPage, SalesEnablementPage } from "../../redux/event-tracking/event-tracking-slice";
import { setIsDocViewing, setRotateMobile } from "../../redux/common/common-slice";

// utils
import isEmpty from "../../utils/isEmpty";

// contants
import constants from "../../constants";

// styles
import useStyles, { HEADER2_HEIGHT_MOBILE } from "./index.styles";
import { useTranslation } from "react-i18next";
import commonUtil from "../../utils/commonUtil";

const IMAGE_EXTENSIONS = constants.IMAGE_EXTENSIONS
const VIDEO_EXTENSIONS = constants.VIDEO_EXTENSIONS;
const AUDIO_EXTENSIONS = constants.AUDIO_EXTENSIONS;
const PDF_EXTENSIONS = constants.PDF_EXTENSIONS;
const { BOTTOM_NAV_HEIGHT } = constants

const FULL_HEIGHT_MOBILE_TEMP = {
    height: `calc(100dvh - ${HEADER2_HEIGHT_MOBILE} - ${BOTTOM_NAV_HEIGHT})`
}

const Documents = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const userActivityData = useSelector(state => state?.event);
    const headerSearchText = useSelector(state => state?.common?.headerSearchText);
    const isDarkMode = useSelector(state => state?.commonPersist?.isDarkMode);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [folderPath, setFolderPath] = useState('');
    const [folderData, setFolderData] = useState([]);
    const [files, setFiles] = useState([]);
    const [indexVal, setIndexVal] = useState();
    const firstUpdate = useRef(true);
    const queryParams = new URLSearchParams(window.location.search);
    const repoId = queryParams.get('id');
    const type = queryParams.get('type');

    const firstRenderRef = useRef(true)


    const backBtnBoxRootRef = useRef()
    const isDocViewingRef = useRef(false)

    // const isDemoCompQA = commonUtil.getCompanyId() === process.env.REACT_APP_DEMO_COMP_QA_COMPANY_ID;
    // const isDemoCompPROD = commonUtil.getCompanyId() === process.env.REACT_APP_DEMO_COMP_PROD_COMPANY_ID;
    const isMahindraLMM = commonUtil.getCompanyId() === process.env.REACT_APP_MAHINDRA_LMM_COMPANY_ID;
    const isCsbBank = commonUtil.getCompanyId() === process.env.REACT_APP_CSB_BANK_COMPANY_ID;

    const { t } = useTranslation();

    const getContentRepoData = async (data) => {
        let { search } = data
        let params = ``;
        params += isEmpty(search) ? '' : `search=${search}&`;
        params += isEmpty(repoId) ? '' : `ids=${repoId}`;
        // let params = `?search=${isEmpty(search) ? '' : search}&ids=${isEmpty(repoId) ? '' : repoId}`
        setIsDataLoaded(false);
        const respData = await getAllContentRepoData(params);

        // code to hide deleted files and folders
        let activeData = respData.data?.data?.filter(i => (i.Repository.active === true))
        setFolderData(activeData)
        setIsDataLoaded(true);
    }

    useEffect(() => {
        getContentRepoData({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [folderPath, repoId]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return
        }
        getContentRepoData({ search: headerSearchText });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headerSearchText])

    useEffect(() => {
        if (firstRenderRef.current) {
            if (isMahindraLMM || isCsbBank) {
                dispatch(SalesEnablementPage({
                    oldScreen: userActivityData.currentScreen,
                    currentScreen: "SALES ENABLEMENT",
                    nextSlide: '',
                    activityData: { screen: "Sales Enablement page screen" }
                }))
            } else {
                dispatch(DocumentPage({
                    oldScreen: userActivityData.currentScreen,
                    currentScreen: "DOCUMENTS",
                    nextSlide: '',
                    activityData: { screen: "Documents page screen" }
                }));

            }
            firstRenderRef.current = !firstRenderRef.current;
        }
        else {
            submitUserActivityData(userActivityData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userActivityData, isMahindraLMM, isCsbBank])


    useEffect(() => {
        // redirect to treo page if user is on mobile and is from Mahindra LMM
        if (isMobile && isCsbBank) {
            navigate('/product/2')
        }

        if (isMobile && isMahindraLMM) {
            navigate('/product/1')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMahindraLMM, isMobile, isCsbBank])

    const getImageUrl = (type, extension) => {
        let baseImgUrl = "/images/documents";
        if (type.toLowerCase() === 'folder') {
            return `${baseImgUrl}/folder.png`;
        } else {
            if (IMAGE_EXTENSIONS.includes(extension)) return `${baseImgUrl}/image_orange_thumb.svg`;
            else if (AUDIO_EXTENSIONS.includes(extension)) return `${baseImgUrl}/audio_orange_thumb.svg`;
            else if (VIDEO_EXTENSIONS.includes(extension)) return `${baseImgUrl}/video_orange_thumb.svg`;
            else if (PDF_EXTENSIONS.includes(extension)) return `${baseImgUrl}/pdf_orange_thumb.svg`;
            else if (extension === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') return `${baseImgUrl}/default_orange_thumb.svg`;
            return `${baseImgUrl}/default_orange_thumb.svg`;
        }
    }

    const handleFolderClick = (d) => {
        let repoName = d.Repository.name.replaceAll(' ', '-');
        let mimeType = d.Repository.mimeType;
        if (d.Repository.type.toLowerCase() === 'folder') {
            let searchParam = `?name=${repoName}&id=${d.repositoryId}`;
            if (searchParam === location.search) return
            setFolderPath(d.repositoryId);
            navigate({
                pathname: '/content-repository',
                search: searchParam
            });
        } else {
            if (IMAGE_EXTENSIONS.includes(mimeType) || AUDIO_EXTENSIONS.includes(mimeType)) {
                let filteredFiles = folderData.filter(i => (i.Repository.mimeType === mimeType));
                let index = filteredFiles.findIndex(a => a.repositoryId === d.repositoryId);
                setIndexVal(index);

                setFiles(filteredFiles)
            } else {
                setFiles([d.Repository]);
            }
            navigate({
                pathname: '/content-repository',
                search: `?name=${repoName}&id=${isEmpty(repoId) ? '' : repoId}&type=${mimeType}`
            });
        }
    }

    const handleBackButtonClick = () => {
        navigate(-1)
    }

    useEffect(() => {
        let allFilesTypes = [
            ...constants.IMAGE_EXTENSIONS_TYPE,
            ...constants.AUDIO_EXTENSIONS_TYPE,
            ...constants.VIDEO_EXTENSIONS_TYPE,
            ...constants.PDF_EXTENSIONS_TYPE,
            ...constants.DOC_EXTENSIONS_TYPE
        ]
        // constants.IMAGE_EXTENSIONS

        for (let elem = 0; elem < allFilesTypes.length; elem++) {
            if (window.location.href.toLowerCase()?.includes("." + allFilesTypes[elem])) {
                isDocViewingRef.current = true;
                dispatch(setIsDocViewing(true))
                backBtnBoxRootStyling(`padding: 0; position: absolute; top: -4rem; left: 2rem; z-index: 1199; `, 'visible')
                break
            } else {
                isDocViewingRef.current = false;
                dispatch(setIsDocViewing(false));
                backBtnBoxRootStyling(``, 'hidden')
                dispatch(setRotateMobile(false))
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.href])

    const backBtnBoxRootStyling = (backBtnStyle, parentElemStyle) => {
        if (backBtnBoxRootRef.current) {
            backBtnBoxRootRef.current.parentElement.style.overflow = parentElemStyle
            backBtnBoxRootRef.current.style = backBtnStyle
        }
    }

    return (
        <div className={`${classes.documentRoot}`} style={isMobile ? FULL_HEIGHT_MOBILE_TEMP : constants.FULL_HEIGHT}>
            {
                !isMobile && (
                    <div className={`${classes.backBtnBoxRoot} ${classes.centerFlex}`} ref={backBtnBoxRootRef}>
                        <div className={`${classes.backBtnBox} ${classes.centerFlex}`} onClick={handleBackButtonClick}>
                            <KeyboardBackspaceIcon />
                        </div>
                    </div>
                )
            }
            {
                // isMobile && (isDemoCompQA || isDemoCompPROD) ? (
                ((isMobile && isMahindraLMM) || (isMobile && isCsbBank)) ? (

                    <SalesEnablementMobileView />
                ) : (
                    !isDataLoaded ? (
                        <div style={constants.FULL_HEIGHT_CENTER}>
                            <CircularProgress />
                        </div>
                    ) : (
                        !isEmpty(folderData) ? (
                            isEmpty(type) ? (
                                <div className={`${classes.documentFolder} scrollbar`}>
                                    {
                                        folderData.map(item => {
                                            return (
                                                <div className={classes.folderBox} key={item.Repository.id}
                                                    onClick={() => handleFolderClick(item)}
                                                >
                                                    <img src={getImageUrl(item.Repository.type, item.Repository.mimeType)} width={80} style={{ filter: isDarkMode ? item.Repository.type.toLowerCase() === 'folder' ? 'none' : 'brightness(0) invert(1)' : 'none' }} alt="file-folder" />
                                                    <div className={classes.folderName}>{item.Repository.name}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ) : (
                                <FileViewer
                                    files={files}
                                    type={type}
                                    indexVal={indexVal}
                                    isMobile={isMobile}
                                />
                            )
                        ) : (
                            <div className={`${classes.centerFlex} ${classes.emptyFolderText}`}>{t("No files found")}</div>
                        )
                    )
                )
            }
        </div>
    )
}

export default Documents;