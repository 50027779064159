import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// mui
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Drawer from '@mui/material/Drawer';

// components
import SideDrawer from '../side-drawer';

// constants
import constants from '../../../constants';
import { useEffect } from 'react';



const SideDrawerContainer = () => {
    const isDrawerCollapsed = useSelector(state => state.common.isSideDrawerCollapsed)
    const [state, setState] = useState({
        top: false,
        left: true,
        bottom: false,
        right: false,
    });

    const [drawerWidth, setDrawerWidth] = useState(constants.DRAWER_WIDTH);

    // console.log("drawerStyleTemp:", drawerStyleTemp['& .MuiDrawer-paper'])

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    useEffect(() => {
        if(isDrawerCollapsed)  setDrawerWidth(constants.COLLAPSED_DRAWER_WIDTH) 
        else setDrawerWidth(constants.DRAWER_WIDTH);
    }, [isDrawerCollapsed])

    const toggleSideDrawer = () => {
    }


    return (
        <Drawer
            variant="permanent"
            sx={theme => ({
                display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'block'
                },
                // width: drawerWidth,
                '& .MuiDrawer-paper': {
                    // width: drawerWidth,
                    boxSizing: 'border-box',
                    // backgroundColor: 'pink !important',
                    // paddingTop: constants.HEADER_HEIGHT,
                    color: '#FFF',
                    border: 0,
                    width: drawerWidth,
                    backgroundColor: ` ${theme.palette.headerBg} !important`,
                    transition: `width ${constants.COLLAPSED_DRAWER_DURATION}ms ease-out`
                }
            })}
            // sx={drawerStyle}
            open
        >
            <SideDrawer />
        </Drawer>
    );
}

export default SideDrawerContainer;
