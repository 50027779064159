import React from 'react'

import useStyles from './feedbackCard.styles'
import { useTranslation } from 'react-i18next'

const FeedbackCard = ({ id, handleFeebackClick, name }) => {
    const { t, i18n } = useTranslation()

    const styles = useStyles(i18n);
    return (
        <>
            <div className={`${styles.cardContainer}`} onClick={(e) => handleFeebackClick(e, id, name)}>
                <div className={`${styles.cardGradient} ${styles.asessmentModel}`}>
                    <div className={`${styles.centerFlex} ${styles.nameContainer}`}>
                        <div className={`${styles.centerFlex} ${styles.headingConatiner}`}>
                            <h2 className={styles.microSkillName}>{t("Rate your learning experience")}</h2>
                            <h4 className={styles.aboutText}>{t("Share your rating & experience on the last completed Microskill")} </h4>
                        </div>
                        <div className={`${styles.centerFlex} ${styles.buttonContainer}`}>
                            <img src={"/images/forms/arrow.svg"} alt='arrow' style={{ height: '2.5rem' }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeedbackCard