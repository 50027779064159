
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
    gameOverRoot: {
        height: 'inherit',
        padding: '1rem',
        opacity: '0',
        animation: '$fadIn 1s ease 0.1s 1 normal forwards running',
    },
    '@keyframes fadIn': {

        '0%': {
            opacity: '0'
        },
        '100%': {
            opacity: '1'
        },
    },
    headerBox: {
        height: "11%",
        backgroundColor: "#a34735",
        display: "grid",
        gridTemplateColumns: "1fr 6fr",
        alignItems: "center",
        justifyItems: "left",
        position: "relative",
        zIndex: "2",
        color: '#fff'
    },
    backBtn: {
        height: "24px",
        paddingLeft: "20px",
        cursor: "pointer"
    },
    gameOverScreenContainer: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundSize: 'cover',
        height: '100%',
        backgroundPosition: 'top center',
        display: 'grid',
        gridAutoRows: "40% 40% 20%",
        animation: '$fadeInBg 3s ease 0s 1 normal forwards running',
        // transition: '2s'
    },
    '@keyframes fadeInBg': {
        '0%': { opacity: '0.1' },
        '100%': { opacity: '1' }
    },
    typeText: {
        color: 'white',
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        transform: 'translateY(500%)',
        animation: '$slideUp 1s ease 2s 1 normal forwards running',
        "& :nth-child(1)": {
            //transform: 'translateY(100%)',
            fontFamily: "MotionControl",

            textAlign: 'center',
            [theme.breakpoints.down("sm")]: {
                // mobile
                // fontSize: '5em',
                // fontSize: (i18n)=> i18n.language === 'en' ? "6em" : "5em",
            },
        },
        "& :nth-child(2)": {
            fontFamily: "montserrat",
            fontSize: "2em",
            textAlign: 'center',
            [theme.breakpoints.down("sm")]: {
                // mobile
                // fontSize: '1.5em',
            },
        }
    },
    '@keyframes slideUp': {
        '0%': { transform: 'translateY(100%)' },
        '100%': { transform: 'translateY(0)' }
    },
    tryBtnBox: {
        textAlign: 'center',
        padding: '3rem 0',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    tryBtn: {
        padding: "0 !important",
        lineHeight: (i18n) => i18n.language === 'en' ? '1.2 !important' : '',
        width: '90%',
        marginTop: '1rem',
        transform: 'scale(0)',
        animation: '$bounceAnimation 1s ease 2s 1 normal forwards running',
        [theme.breakpoints.down("sm")]: {
            // fontSize: "3em",
            padding: "0.8rem",
        }


        // backgroundColor: '#ffd042',
        // width: '80%',
        // borderRadius: '3px',
        // border: 'none',
        // fontFamily: "MotionControl",
        // fontSize: '2rem',
        // padding: '.5rem 0',
        // boxShadow: '0 0 5px -1px #000',
        // //transform: 'scale(0)',
        // cursor: 'pointer',
        // transform: 'scale(0)',
        // animation: '$bounceAnimation 1s ease 2s 1 normal forwards running',
        // [theme.breakpoints.down("sm")]: {
        //     // mobile
        //     fontSize: '3rem',
        // },
    },
    "@keyframes bounceAnimation": {
        "0%": { transform: 'scale(1)' },
        "20%": { transform: 'scale(0.9)' },
        "40%": { transform: 'scale(1)' },
        "60%": { transform: 'scale(0.9)' },
        "80%": { transform: 'scale(1)' },
        "100%": { transform: 'scale(1)' },
    }


}));

export default useStyle;
