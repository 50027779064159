import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    sampleRecordingRoot: {
        position: 'relative',
        height: 'inherit',
    },
    previewVideoBox: {
        height: '100%',
        position: 'relative'
    },
    icon: {
        position: 'absolute '
    },
    reactPlayerClass: {
        '& video': {
            objectFit: 'contain'
        }
    },
    viewTranscriptBtnBox: {
        position: 'absolute',
        bottom: '7%',
        width: '100%',
        textAlign: 'center'
    },
    viewTranscriptBtn: {
        borderRadius: '4px !important',
        width: 'calc(100% - 3rem) !important',
        color: '#FFF !important',
        fontSize: '0.9em !important',
        borderWidth: '2px !important',
        fontWeight: '600 !important',
        // padding: '14px !important',
    },
    transcriptBox: {
        position: 'absolute',
        left: '0',
        right: '0',
        top: '0',
        bottom: '0',
        display: 'grid',
        gridTemplateRows: '85% 15%',
        padding: '2.5rem 1.5rem 1.5rem 1.5rem',
        overflow: 'hidden',
        alignItems: 'self-start',
        justifyItems: 'center'
    },
    transcriptTextBox: {
        "abvc": ({ viewSampleRecording }) => `${viewSampleRecording}`,
        // color: ({viewSampleRecording, viewTranscript}) => !viewSampleRecording && viewTranscript && theme.palette.mode === 'dark' ? '#FFF' : theme.palette.fontColor,
        color: ({ viewSampleRecording, viewTranscript }) => !viewSampleRecording && viewTranscript ? '#FFF' : theme.palette.fontColor,
        overflow: 'auto',
        fontSize: '1.5em',
        height: '99%',
        // paddingBottom: '1rem',
    },
    overlay: {
        position: 'absolute',
        left: '0',
        right: '0',
        top: '0',
        bottom: '0',
        display: 'grid',
        gap: '2rem',
        // padding: '3rem',
        alignContent: 'center',
        width: '50%',
        margin: 'auto',
        zIndex: 2
    },
    overlayBtn: {
        backgroundColor: 'white !important',
        color: '#878787 !important',
        borderRadius: '4px !important',
        fontWeight: '700 !important'
    },
    viewTranscriptOverlay: {
        position: 'absolute',
        height: '10.5em',
        width: '100%',
        display: 'grid',
        alignContent: 'flex-start',
        gap: '1rem',
        padding: '1em',
        bottom: 0,

    },
    btnContainer: {
        // paddingTop: '1em',
        display: 'grid',
        gap: '1em',
        // justifyItems: 'center',
        textAlign: 'center',
        alignItems: 'center',
    },

    closeTranscriptBtn: {
        // border: '3px solid rgba(244, 81, 30) !important',
        color: '#fff !important',
        fontWeight: '600 !important',
        alignSelf: 'center',
        // paddingTop: '0.7rem !important',
        // paddingBottom: '0.7rem !important',
        fontSize: '0.9rem !important'
    },
    skipBtn: {
        cursor: 'pointer',
        backgroundColor: `rgba(0, 0, 0, 0.75) !important`,
        border: `1px solid ${theme.palette.white} !important`,
        color: `${theme.palette.white} !important`,
        fontWeight: '600 !important',
        textAlign: 'center',
        fontSize: '1rem',
        padding: '0 !important'
    }
}))

export default useStyles;