import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// mui
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Button } from "@mui/material";

// components
import MicroskillCard from "../microskill-card";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// utils
import isEmpty from "../../utils/isEmpty";

// theme
import palette from "../../theme/palette";

// styles
import useStyles from "./index.styles";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1400 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 1400, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 2
    }
};

const cardsRenderCount = 4;

const MicroskillCategoryContainer = (props) => {
    const { data, name, handleRecentlyLearned, isRecentlyLearned = false, checked } = props;
    const navigate = useNavigate();
    const classes = useStyles();
    const [cardsPerRender, setCardsPerRender] = useState(cardsRenderCount);
    const isMobile = useSelector(state => state?.common?.isMobile);

    const { t } = useTranslation()

    const handleExpandClick = () => {
        if (cardsRenderCount === cardsPerRender) {
            setCardsPerRender(data.length)
        } else {
            setCardsPerRender(cardsRenderCount);
        }
    }

    const handleMicroskillCardClick = (d) => {
        console.log("handleMicroskillCardClick ",d)
        if (!d.status?.toUpperCase().includes('ACTIVE')) return; // if microskill is not active then do nothing (schedule launch)
        navigate(`/challenge-list/${d.microskillId}`);
        handleRecentlyLearned(d);

        // console.log("recentlyLearnedIdsSet:", recentlyLearnedIdsSet)
        // console.log("recentlyLearnedIds:", recentlyLearnedIds)
        // navigate({
        //     pathname: '/documents',
        //     search: `?name=${d.folderName}&path=${isEmpty(path) ? '' : path}&type=${d.extension}`
        // });
    }

    const getCategoryName = (name) => {
        return name.replace("_", " ")
    }

    const CustomRightArrow = ({ onClick, ...rest }) => {
        // const {
        //   onMove,
        //   carouselState: { currentSlide, deviceType }
        // } = rest;
        // onMove means if dragging or swiping in progress.
        return (
            <Button onClick={() => onClick()} variant="contained"
                className={`${classes.customCarouselArrow} ${classes.customCarouselRightArrow}`}>
                <img src="/images/icons/right_arrow_white.svg" />
            </Button>
        )
    };

    const CustomLeftArrow = ({ onClick, ...rest }) => {
        return (
            <Button onClick={() => onClick()} variant="contained"
                className={`${classes.customCarouselArrow} ${classes.customCarouselLeftArrow}`}>
                <img src="/images/icons/left_arrow_white.svg" />
            </Button>
        )
    };

    const MicroskillDisplayTemplate = ({ data, categoryName }) => {
        return (
            <>
                <div className={classes.classificationText}>
                    {/* {isRecentlyLearned ? 'Recently Learned' : t(categoryName.split(" (")[0]) + " (" + categoryName.split(" (")[1]} */}
                    {isRecentlyLearned ? 'Recently Learned' : categoryName}
                </div>
                <div className={classes.microskillCardContainer}>
                    {
                        !isMobile ? (
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                customLeftArrow={<CustomLeftArrow />}
                                customRightArrow={<CustomRightArrow />}
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className={classes.reactCustomClass}
                                containerClass="container"
                                dotListClass=""
                                draggable={isMobile}
                                focusOnSelect={false}
                                infinite={false}
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable={isMobile}
                                responsive={responsive}
                            >
                                {
                                    data.map(item => (
                                        <MicroskillCard
                                            isRecentlyLearned={isRecentlyLearned}
                                            width={'100%'}
                                            key={`carousel-${item.microskillId}`}
                                            cardData={item}
                                            categoryName={categoryName}
                                            handleMicroskillCardClick={() => handleMicroskillCardClick(item)}
                                        />
                                    ))
                                }
                            </Carousel>
                        ) : (
                            <>
                                <div className={classes.mobileMicroskillContainer}>
                                    {
                                        data.slice(0, cardsPerRender).map((item, i) => (
                                            <MicroskillCard
                                                isRecentlyLearned={isRecentlyLearned}
                                                width={'100%'}
                                                key={`none-carousel${item.microskillId}`}
                                                cardData={item}
                                                categoryName={categoryName}
                                                handleMicroskillCardClick={() => handleMicroskillCardClick(item)}
                                            />
                                        ))
                                    }
                                </div>
                                {
                                    data.length > cardsRenderCount ? (
                                        cardsPerRender === cardsRenderCount ? (
                                            <div className={classes.expandBox} onClick={handleExpandClick}>
                                                <span>{t("Show More")}</span>
                                                <ExpandMoreIcon sx={{ color: palette.orange }} />
                                            </div>
                                        ) : (
                                            <div className={classes.expandBox} onClick={handleExpandClick}>
                                                <ExpandLessIcon sx={{ color: palette.orange }} />
                                                <span>{t("Show Less")}</span>
                                            </div>
                                        )
                                    ) : (null)
                                }
                            </>
                        )
                    }
                </div>
            </>
        )
    }

    return (
        <>
            {
                !isEmpty(data) && (
                    <MicroskillDisplayTemplate data={data}
                        categoryName={checked ? `${t(getCategoryName(name))} (${data.length})` : (data[0]?.Microskill?.Category?.name)}
                    />
                )
            }
        </>
    )
}

export default MicroskillCategoryContainer;