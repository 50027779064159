
const inputBorderRadius = 2;
const buttonBorderRadius = 6;
const paddingLarge = 12;
const components = {
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                // height: inputheight
            },
            notchedOutline: {
                borderRadius: inputBorderRadius,
                // borderColor: 'green'
            }
        }
    },
    MuiLoadingButton: {
        styleOverrides: {
            root: {
                borderRadius: buttonBorderRadius,
            },
        }
    },
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: buttonBorderRadius,
                textTransform: 'inherit',
                "&.Mui-disabled": {
                    backgroundColor: "#f3f3f3",
                },
            },
            sizeLarge: {
                padding: paddingLarge,
            },
            textSizeLarge: {
                fontSize: '1.2em'
            },
            containedSizeLarge: {
                fontSize: '1.2em'
            },
            // iconSizeMedium: {
            //     '& svg': {
            //         fontSize: '1.2em !important'
            //     }
            // },
            iconSizeLarge: {
                '& svg': {
                    fontSize: '1.8em'
                }
            },
            containedGbabtn: {
                fontFamily: "MotionControl",
                // lineHeight: '2em'
            }
        }
    },
}

export default components