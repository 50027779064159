import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import lottie from "lottie-web";

// import { toggleSideBar } from "../../../../actions/challengeListAction";
// import GbaHeader from "../../GbaHeader";
import RokectCorrect from '../../../../lottie-assets/rocketcorrect.json';

// Style
import useStyle from "./index.styles";
import isEmpty from "../../../../utils/isEmpty";

const HowToPlay = ({ width, height, title, headerBg, qstnBoxBg, qstnImg, qstnImgAnimation, answerImg, animationImg, setIsGamePage, gameType }) => {
    const isMobile = useSelector(state => state?.common?.isMobile);
    const classes = useStyle(isMobile);
    const dispatch = useDispatch();
    const [reload, setReload] = useState(true);
    const questionRef = useRef([]);
    const containerRef = useRef(null);
    const headerQstnBox = useRef(null);
    const arrowImgRef = useRef(null);
    const displayQstnRef = useRef(null);
    const dataContainerRef = useRef(null);
    const progressBarRef = useRef(null);
    const overlayRef = useRef(null);
    const qstnBoxRef = useRef(null);
    const lifeTimeRef = useRef(null);
    const progressTextRef = useRef(null);

    const optionArray = ["option 1", "option 2", "option 3"];
    const statementArray = ["statement 1", "statement 2"];

    let container = null;
    let dragItem = null;
    let active = false;
    let currentX;
    let currentY;
    let initialX;
    let initialY;
    let xOffset = 0;
    let yOffset = 0;
    let instructionNo = 2;
    let dragText = gameType !== 'popcornMatch' ? 'drag and match with the trash cans' : 'drag and match with the bucket';

    useEffect(() => {
        container = document.getElementById("popcorn-container-howToPlay");
        if (container === null) return

        container.addEventListener("touchstart", dragStart, false);
        container.addEventListener("touchend", dragEnd, false);
        container.addEventListener("touchmove", drag, false);

        container.addEventListener("mousedown", dragStart, false);
        container.addEventListener("mouseup", dragEnd, false);
        container.addEventListener("mousemove", drag, false);
    })

    const nextInstruction = (instNo, e) => {
        let targetId = e?.target?.id;

        if (instructionNo === 2) {
            headerQstnBox.current.style.zIndex = 'auto';
            arrowImgRef.current.style.display = 'flex';
            let answerEl = document.getElementById('answer-0');
            let statementEl = document.getElementById('statement-0');
            answerEl.style.zIndex = '4';
            statementEl.style.zIndex = '4';
            if (!statementEl.hasChildNodes()) return
            displayQstnRef.current.style.animation = 'auto';
            answerEl.firstChild.innerHTML = "Option 1";
            statementEl.firstChild.innerHTML = "Statement 1";
            overlayRef.current.removeEventListener('onClick', nextInstruction);

        } else if (instructionNo === 3) {
            // remove all listeners
            var old_element = document.getElementById("popcorn-container-howToPlay");
            var new_element = old_element.cloneNode(true);
            old_element.parentNode.replaceChild(new_element, old_element);

            let answerEl = document.getElementById('answer-0');
            let statementEl = document.getElementById('statement-0');
            answerEl.style.zIndex = '0';
            statementEl.style.zIndex = '0';
            progressBarRef.current.style.backgroundColor = '#ffd042';
            dataContainerRef.current.style.display = 'grid';
            setTimeout(() => {
                if (!isEmpty(dataContainerRef.current)) dataContainerRef.current.style.opacity = '1';
            }, 800)
        } else if (instNo === 4 && instructionNo === 4) {
            //  instructionNo++;
            if (!isEmpty(dataContainerRef.current)) dataContainerRef.current.style.opacity = '0';
            setTimeout(() => {
                dataContainerRef.current.style.display = 'none';
                displayQstnRef.current.style.opacity = '0'
                qstnBoxRef.current.style.zIndex = "2";
                lifeTimeRef.current.style.visibility = 'visible';
            }, 800);
        } else if (instNo === 2 && instructionNo === 4 && targetId === 'overlay') {
            instructionNo = 5;
            progressTextRef.current.style.display = 'grid';
            qstnBoxRef.current.style.zIndex = "1";
            lifeTimeRef.current.style.animation = 'auto';
            displayQstnRef.current.style.opacity = "1";
        } else if (instNo === 2 && instructionNo === 5 && targetId === 'overlay') {
            setIsGamePage(true);
        }
    }

    const dragStart = (e) => {
        let targetEl = !isEmpty(e?.target?.id) ? document.getElementById(e?.target?.id) : document.getElementById(e?.target?.parentElement.id);
        if (isEmpty(targetEl)) return;
        if (targetEl.getAttribute('drag') !== 'true') return;

        dragItem = targetEl;
        //dragItem = document.getElementById(e.target.id);
        dragItem.style.transition = "0s";

        arrowImgRef.current.style.display = 'none';
        let statement0 = document.getElementById('statement-0');
        if (statement0.hasChildNodes()) {
            // statement0.firstChild.innerHTML = "";
        }

        if (e.type === "touchstart") {
            initialX = e.touches[0].clientX - xOffset;
            initialY = e.touches[0].clientY - yOffset;
        } else {
            initialX = e.clientX - xOffset;
            initialY = e.clientY - yOffset;
        }
        active = true;
        if (e.target.id === dragItem.id) {
        }
    }

    const drag = (e) => {
        if (dragItem == null) return;
        if (!active) return;

        e.preventDefault();
        if (e.type === "touchmove") {
            currentX = e.touches[0].clientX - initialX;
            currentY = e.touches[0].clientY - initialY;
        } else {
            currentX = e.clientX - initialX;
            currentY = e.clientY - initialY;
        }
        xOffset = currentX;
        yOffset = currentY;

        setTranslate(currentX, currentY, dragItem, true);

        let answerEl = document.getElementById('answer-0');

        // console.log("drag:", [dragItem, answerEl]);
        console.log("isOverlapping:", isOverlapping(dragItem, answerEl));
        if (isOverlapping(dragItem, answerEl)) {
            answerEl.style.scale = '1.2';
        } else {
            answerEl.style.scale = '1';
        }
    }

    const dragEnd = async (event) => {
        if (dragItem == null) return
        xOffset = yOffset = 0;
        active = false;
        dragItem.style.transition = "all .2s";
        let answerEl = document.getElementById('answer-0');
        let answerText = answerEl.innerHTML;


        //Match answer
        if (isOverlapping(dragItem, answerEl)) {

            if (gameType === 'trashTrivia') {
                answerEl.innerHTML = '';
                answerEl.style.backgroundImage = `url(${qstnImgAnimation})`;
                dragItem.style.transform = 'scale(0)';
            } else if (gameType === 'popcornMatch') {
                answerEl.style.scale = '1';
                answerEl.style.backgroundImage = `url(${animationImg})`;
                dragItem.style.opacity = '0';
            } else if (gameType === 'diwaliDash') {
                answerEl.style.backgroundImage = ``;
                answerEl.firstChild.innerHTML = ``;
                const lottieAnimation = lottie.loadAnimation({
                    container: answerEl,
                    renderer: "svg",
                    loop: false,
                    autoplay: false,
                    animationData: RokectCorrect,
                    speed: '1'
                });
                setTimeout(() => {
                    console.log("play")
                    lottieAnimation.play();
                }, 1000);
                // dragItem.style.backgroundImage = `url(${animationImg})`;
            }
            setTimeout(() => {
                setTranslate(0, 0, dragItem, false);
                if (gameType !== 'diwaliDash' && gameType !== 'popcornMatch') {
                    answerEl.innerHTML = answerText;
                    answerEl.style.backgroundImage = `url(${qstnImg})`;
                    dragItem.style.transform = 'scale(1)';
                    dragItem.style.backgroundImage = `url(${answerImg})`;
                }

                instructionNo++;
                setTimeout(() => { nextInstruction(3) }, 1000)
            }, 1000);
        } else {
            setTranslate(0, 0, dragItem, false);
        }
    }

    const setTranslate = (xPos, yPos, el) => {
        el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
    }

    const isOverlapping = (e1, e2) => {
        // console.log("isOverlapping:", [e1, e2])
        if (isEmpty(e1) || isEmpty(e2)) return;
        if (e1.length && e1.length > 1) {
            e1 = e1[0];
        }
        if (e2.length && e2.length > 1) {
            e2 = e2[0];
        }
        const rect1 = e1 instanceof Element ? e1.getBoundingClientRect() : false;
        const rect2 = e2 instanceof Element ? e2.getBoundingClientRect() : false;

        let overlap = false;
        if (rect1 && rect2) {
            let ratio = !isMobile ? 1.07 : 1.3;
            overlap = !(
                (rect1.right / ratio) < rect2.left ||
                rect1.left * ratio > rect2.right ||
                rect1.bottom / ratio < rect2.top ||
                rect1.top * ratio > rect2.bottom
            );
            return overlap;
        }
        console.warn('Please provide valid HTMLElement object');
        return overlap;
    }

    const handleRestartClick = () => {
        setReload(false);
        setTimeout(() => { setReload(true) })
    }

    return (
        reload ? (
            <div style={{ width: `${width}px`, height: `${height}px` }} className={classes.howToPlayroot}>
                <div className={classes.container}>
                    <div className={classes.overlay} ref={overlayRef} id={`overlay`}
                        onClick={(e) => nextInstruction(2, e)} >
                        <div className={classes.progressText} ref={progressTextRef}>
                            <div className={classes.arrow2Box}>
                                <img src="/images/gba/arrow2.png" className={classes.arrowImg2} alt="arrow" width={50} />
                            </div>
                            <div className={classes.completionText}>Track completion with the progress bar</div>
                        </div>

                        <div className={classes.dataContainer} ref={dataContainerRef}>
                            <div className={`${classes.centerFlex} ${classes.readyText}`}>READY TO PLAY?</div>
                            <div className={`${classes.centerFlex} ${classes.cursorPointer}`}
                                onClick={() => { instructionNo = 4; nextInstruction(4) }}>
                                <button className={classes.startBtn} id={`startGameBtn`}>START GAME</button>
                            </div>
                            <div className={`${classes.centerFlex} ${classes.restartText}`} onClick={handleRestartClick}>
                                RESTART INSTRUCTIONS
                            </div>
                        </div>
                    </div>
                    <div className={classes.headerQstnBox} ref={headerQstnBox}>
                        {/* <GbaHeader title={title} height={'40%'}
                            handleBackButtonClick={challengeList}
                            headerBg={headerBg} /> */}
                        <div className={classes.progressBarContainer} ref={progressBarRef}>
                            <div className={classes.progressBar} />
                        </div>
                        {/* QUESTION BOX */}
                        <div className={classes.qstnContainer} ref={qstnBoxRef}>
                            <div className={classes.qstnBox} style={{ backgroundColor: `${qstnBoxBg}` }}>
                                <div className={classes.lifeTime} ref={lifeTimeRef}>
                                    <div className={classes.centerFlex}>
                                        <div className={classes.assetsText}>5 x </div>
                                        <img src='/images/icons/heart.png' className={classes.icon} alt="heart" width="10" />
                                    </div>
                                    <div className={classes.centerFlex}>
                                        <img src='/images/icons/stopwatch.png' className={classes.icon} alt="heart" width="10" />
                                        <div className={classes.assetsText}>180s</div>
                                    </div>
                                </div>
                                <div className={`${classes.qstn} ${classes.centerFlex}`} ref={displayQstnRef}>Questions will appear within this box </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.dragCardContainer} ref={containerRef} id="popcorn-container-howToPlay">
                        <div className={classes.arrow} ref={arrowImgRef}>
                            <img src="/images/gba/arrow2.png" className={classes.arrowImg} alt="arrow" />
                            <div className={classes.arrowText}>
                                {dragText}
                            </div>
                        </div>
                        {
                            <div className={classes.answerContainer}>
                                {
                                    optionArray.map((item, i) => (
                                        <div className={classes.answer} key={i}>
                                            <div className={classes.anwserImgBox} id={`answer-${i}`}
                                                style={{ backgroundImage: `url(${qstnImg})` }}>
                                                <div className={gameType === 'trashTrivia' ? classes.answerTextTrashTrivia : classes.answerText}>
                                                    {item}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        }

                        {
                            <div className={classes.optionContainer}>
                                {
                                    statementArray.map((item, i) => (
                                        <div className={classes.option} key={i} >
                                            <div className={gameType === 'trashTrivia' ? classes.qstnImgBoxTrashTrivia : classes.qstnImgBox} id={`statement-${i}`} drag={'true'}
                                                style={{ backgroundImage: `url(${answerImg})` }}>
                                                <div className={gameType === 'trashTrivia' ? classes.qstnTextTrashTrivia : classes.qstnText}>{item}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        ) : (null)

    )
};

export default HowToPlay
