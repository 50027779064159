import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    documentImageViewerRoot: {
        opacity: 0,
        height: '100%',
        width: '100%',
    },
    carouselContainer: {
        height: '100%',
        position: 'relative',
        '& .react-multi-carousel-track': {
            height: '100%',
        },
        '& .react-multi-carousel-item': {
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& img': {
                objectFit: 'contain',
                width: '100%',
                height: '100%',
            }
        }
    },
    magnifierWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transformOrigin: 'center center',
    },
    downloadBox: {
        position: 'absolute',
        zIndex: '9999',
        bottom: '2rem',
        right: '1rem',
        height: '3rem',
        width: '3rem',
        borderRadius: '50%',
        backgroundColor: '#fff',
        padding: '0.2rem',
        boxShadow: '0px 0px 10px 0px #00000057',
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'contain'
        }
    }
}))

export default useStyles