
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
    howToPlayroot: {
        color: "#fff",
        boxSizing: "border-box",
        backgroundImage: "linear-gradient(#edd6a0, #e89d15)",
        position: "relative",
        transition: "0.7s",
        height: '100%'
    },
    headerBox: {
        height: "11%",
        display: "grid",
        gridTemplateColumns: "1fr 6fr",
        alignItems: "center",
        justifyItems: "left",
        position: "relative",
        zIndex: "2",
    },
    backBtn: {
        height: "24px",
        paddingLeft: "20px",
        cursor: "pointer"
    },
    container: {
        display: "grid",
        height: "100%",
        gridAutoRows: "27% 73%",
        overflow: 'hidden',
        touchAction: 'none',
        background: '#000000d4'
    },
    overlay: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        background: '#000',
        zIndex: '2',
        opacity: '.88'
    },
    dataContainer: {
        transition: '.8s',
        display: 'none',
        opacity: '0',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '1',
        width: '100%',
        height: '100%',
        height: '60%',
        marginTop: '40%',
    },
    dataContainerBox: {
        display: 'grid',
        // marginTop: '80%'
    },
    startBtn: {
        width: '85%',
        fontFamily: 'MotionControl',
        fontSize: '2rem',
        backgroundColor: '#F5D03C',
        padding: '0.5rem 1rem',
        border: 'none',
        borderRadius: '5px',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '2.5rem',
        },
    },
    headerQstnBox: {
        position: 'relative',
        zIndex: '3',
    },
    progressBarContainer: {
        position: "relative",
        height: (isMobile) => {
            return isMobile ? "4px" : "9px";
        },
        backgroundColor: '#fff',
        zIndex: "2",
        width: (isMobile) => {
            return `calc(100% - ${isMobile ? '0px' : '6px'})`
        },
        borderRadius: (isMobile) => {
            return `${isMobile ? '0px' : '8px'}`
        },
        margin: (isMobile) => {
            return isMobile ? "auto" : "0.3rem auto"
        },
        overflow: 'hidden',
    },
    progressBar: {
        position: "absolute",
        top: "0",
        left: "0",
        height: (isMobile) => {
            return isMobile ? "4px" : "9px";
        },
        backgroundColor: "#ffd042",
        width: '0',
        transition: '.4s',
        width: '25%'
    },
    qstnContainer: {
        height: "53%",
        margin: "10px 20px 0 20px",
        position: 'relative',
    },
    qstnBox: {
        backgroundColor: "#000",
        borderRadius: "10px",
        height: "100%",
        fontSize: "16px",
        display: 'grid',
        gridAutoRows: '40% 60%',
        alignItems: 'center',
        boxShadow: '0px 0px 12px 0px #FFFFFF',
    },
    qstn: {
        animation: "$blink 1s ease 0s infinite normal forwards running",
        height: '100%',
        alignItems: 'baseline !important',
        fontFamily: 'open sans',
        fontWeight: 'bold',
        fontSize: '1rem'
    },
    "@keyframes blink": {
        "0%": { opacity: "0" },
        "30%": { opacity: "0" },
        "100%": { opacity: "1" }
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    lifeTime: {
        //opacity: '0',
        visibility: 'hidden',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 2rem',
        fontSize: '.8rem',
        animation: "$blink 1s ease 1s infinite normal forwards running",
        height: '100%',
    },
    icon: {
        margin: '0 5px',
        [theme.breakpoints.down("sm")]: {
            // mobile
            width: '11px',
        },
    },
    progressText: {
        marginTop: '1rem',
        display: 'grid',
        justifyContent: 'center',
        fontSize: '.8rem',
        display: 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        padding: '0 1rem'
    },
    arrow2Box: {
        textAlign: 'center',
    },
    arrowImg2: {
        width: '40%'
    },
    readyText: {
        fontFamily: "Montserrat",
        fontSize: '2rem',
        fontWeight: '900',
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: 'calc(3vw * 2.5)',
        },
    },
    restartText: {
        fontFamily: "MotionControl",
        fontSize: '2rem',
    },
    assetsText: {
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.2rem',
        },
    },
    completionText: {
        fontWeight: 900,
        fontSize: '1rem',
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.2rem',
        },
    },



    gameplayContainer: {
        position: 'relative',
        height: '100%',
        display: 'grid',
        gridAutoRows: '24% 24% 24% 24%',
        alignItems: 'flex-end',
        // justifyContent: 'center',
        '& .tikkiMonkeyOptionSetBox:nth-child(even)': {
            '& .tikkiMonkeyMovingAssetBox': {
                transform: 'rotateY(180deg)'
            }
        }
    },
    optionSetBox: {
        position: 'relative',
    },
    movingAssetBox: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        padding: '0 .1rem',
        opacity: '1',
        transition: 'all 1s 3s',
        marginBottom: '-0.5rem',
        zIndex: 1,
        position: 'relative',
    },
    movingAssetBoxAnimation: {
        animation: '$movingAssetBoxAnimation 1s ease 2.5s 1 normal forwards running'
    },
    '@keyframes movingAssetBoxAnimation': {
        '0%': {
            opacity: '0'
        },
        '100%': {
            opacity: '1'
        },
    },
    monkeyImg: {
        width: '100px'
    },
    bananaImg: {
        width: '35px'
    },
    pathBox: {
        position: 'relative',
        height: '54px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    },
    option: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        fontSize: '14px'
    },
    monkeyAssetBox: {
        transform: 'translateX(0px)',
    },
    incorrectGradientOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        backgroundImage: 'linear-gradient(#800e315e, #f63b348c)',
        opacity: '0',
        transition: '.5s ease',
    },
    incorrectGradientOverlayAnimation: {
        animation: '$incorrectGradientOverlayAnimation 3s ease-out 0s 1 normal forwards running'
    },
    '@keyframes incorrectGradientOverlayAnimation': {
        from: {
            // backgroundImage: 'linear-gradient(transparent, transparent), url(/tikkiMonkey/tikki_monkey_bg.jpg)',
            opacity: '1'
        },
        to: {
            // backgroundImage: 'linear-gradient(#800e315e, #f63b348c), url(/tikkiMonkey/tikki_monkey_bg.jpg)',
            opacity: '0'
        }
    },
    answerLottieBox: {
        position: 'absolute',
        left: '50%',
        top: 0,
        bottom: 0,
        position: 'absolute',
        width: '50%',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    handImg: {
        position: 'absolute',
        left: '50%',
        top: '70%',
        transform: 'translateX(-50%)',
        opacity: '0',
    },
    blinkAnimation: {
        animation: "$blink 1s ease 0s infinite normal forwards running",
    }
}));

export default useStyle;
