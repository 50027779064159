import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from 'react-lottie-player'

// mui
import CircularProgress from '@mui/material/CircularProgress';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

//lottie :

import CAR_NITRO from "../../../lottie-assets/crazy_car_nitro.json";
import CAR_SMOKE from "../../../lottie-assets/crazy_car_smoke.json";

// components
import StartScreen from '../common-screens/start-screen';
import GbaGameOverScreen from '../common-screens/game-over-screen';
import GbaWinScreen from '../common-screens/win-screen';

// redux
import { startChallengeAttempt } from '../../../redux/microskill/microskill.api';
import { submitChallengeAttemptData, submitEndChallengeAttempt } from '../../../redux/gba/gba.api';
import { handleGbaGameState } from '../../../redux/gba/gba-slice';

// utils
import isEmpty from '../../../utils/isEmpty';
import commonUtil from '../../../utils/commonUtil';

// constants
import constants from '../../../constants';

// styles
import useStyle from './index.styles';
import { useTranslation } from 'react-i18next';

// animation

import HOW_TO_PLAY_LOTTIE from "../../../lottie-assets/how-to-play-crazy-cars.json"
import { Button } from '@mui/material';

const GBA_SCREEN_STATE = constants.GBA_SCREEN_STATE;

const CAR_LIST = [
    '/images/gba/crazy_car_1.png',
    '/images/gba/crazy_car_2.png',
    '/images/gba/crazy_car_3.png',
    '/images/gba/crazy_car_4.png',
];

const randomCar = commonUtil.randomNo(0, 3, []);
const CrazyCars = (props) => {
    const { gameData, handleGbaState, isPauseOverlay, defaultGradient, userMicroskillChallenge = { totalAttempts: 0 }, setAddPause } = props;

    const gbaGameData = gameData.questions;
    const { microskillId, challengeId } = useParams();
    const contextData = gameData.contexts;
    const dispatch = useDispatch();

    let maxLifeGba = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxLife;
    let maxQuestion = commonUtil.getQuestionAndLifeForGba({ gbaType: gameData.designType }).maxQuestion;
    let totalQuestionLength = gameData.questions.length;
    let questionsInGba = totalQuestionLength;
    let maxLife = commonUtil.getMaxLifeForGba({
        gbaType: gameData.designType,
        questionsInGba: questionsInGba,
        maxQuestion: maxQuestion,
        maxLife: maxLifeGba,
        // maxLife: 1,
    });

    let mxmScore = 120;
    let marksPerQuestion = mxmScore / totalQuestionLength;
    let leftMovement = 12;
    let rightMovement = 88;

    let roadSpeed1 = 0.4;
    let roadSpeed2 = roadSpeed1 + 0.2;
    let roadSpeed3 = roadSpeed1 + 0.8;

    let carFlowSpeed1 = 15;
    let carFlowSpeed2 = carFlowSpeed1 / 2;
    let carFlowSpeed3 = carFlowSpeed1 / 4;
    let lottieMovedPercentagePerSec = .25; //25% -> provided by dinesh (1800px total lenth of lottie asset and moves 450px in 1sec)
    const speedBarLength = 16;

    const isMobile = useSelector(state => state?.common?.isMobile);
    const [isGamePage, setIsGamePage] = useState(false);
    const [isGameComplete, setIsGameComplete] = useState(false);
    const [isHowToPlayScreen, setIsHowToPlayScreen] = useState(false);
    const [gameEndType, setGameEndType] = useState('');
    const [life, setLife] = useState(maxLife);
    const [speedLevel, setSpeedLevel] = useState(roadSpeed2);
    const [roadSpeedLevel, setRoadSpeedLevel] = useState(roadSpeed2);
    const [attemptData, setAttemptData] = useState({})
    const [isGuideLottiePlaying, setIsGuideLottiePlaying] = useState(true);
    const [incorrectData, setIncorrectData] = useState();

    const [fSize, setFSize] = useState(1);
    const [isCarRotate, setIsCarRotate] = useState(false);
    const [lastMovement, setLastMovement] = useState("LEFT");

    // states to mane start screen assts preloading
    const [startScreenGameLogoLoaded, setStartScreenGameLogoLoaded] = useState(false);
    const [startScreenGameBgLoaded, setStartScreenGameBgLoaded] = useState(false);

    const CrazyCarsRef = useRef();
    const totalScoreRef = useRef(0);
    const incorrectGradientOverlayRef = useRef();
    const progressBarRef = useRef();
    const lifeTextRef = useRef();
    const lifeRef = useRef();
    const heartRef = useRef();
    const questionRef = useRef();
    const gamePlayContainerRef = useRef();
    const leftSideImgRef = useRef();
    const rightSideImgRef = useRef();
    const roadStripsRef = useRef();
    const missedRef = useRef();
    const leftRef = useRef();
    const rightRef = useRef();
    const carImgRef = useRef();
    const leftSideTFRef = useRef();
    const rightSideTFRef = useRef();
    const speedBoxRef = useRef();
    const speedBarRectBoxRef = useRef();
    const leftKeyboardMovementRef = useRef();
    const rightKeyboardMovementRef = useRef();
    const leftPartRoadRef = useRef();
    const rightPartRoadRef = useRef();
    const questionContainerRef = useRef();
    const collisionRef = useRef(false);
    const questionVisible = useRef(false);
    const tfScrollIntervalRef = useRef();
    const checkOverlapIntervalRef = useRef();
    const activeIndexRef = useRef(0);
    const speedLevelRef = useRef(2);
    const finalChallengeDataRef = useRef(null);
    const attemptRef = useRef();
    const contextRef = useRef();
    const currentTimeRef = useRef();
    const translatedTFRef = useRef(-100);
    // const translatedTFRef = useRef();
    const rightRoadImgRef = useRef();
    const leftRoadImgRef = useRef();
    const sideImgHeight = useRef();
    const roadStripImgRef = useRef();
    const assestsIntervalRef = useRef();
    const assestsScrollValueRef = useRef(0);
    const questionNoRef = useRef();
    const roadRef = useRef();
    const isGamePausedRef = useRef(isPauseOverlay);
    const scrollBoxRef = useRef();
    const pausedTimeRef = useRef(0);

    const carSmokeOneRef = useRef(null);
    const carSmokeTwoRef = useRef(null);
    const carSmokeThreeRef = useRef(null);
    const carSmokeFourRef = useRef(null);
    const allCarsSmokeRef = useRef([carSmokeOneRef, carSmokeTwoRef, carSmokeThreeRef, carSmokeFourRef])
    const currentSmockRef = useRef(0)
    const carCanMove = useRef(false);

    const isOptionClicked = useRef(null);
    const isNitro = useRef(null);

    const classes = useStyle();

    const totalTimeIntervalRef = useRef();
    const totalGameTimeRef = useRef(0);

    const { t } = useTranslation()
    let bgGradientDefault = defaultGradient;
    let bgGradientLoss = 'linear-gradient(#800E31, #F63B34)';
    const speedBarGradient = 'transparent linear-gradient(1deg, #790909 0%, #F11212 100%)';

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    useEffect(() => {
        if (isGameComplete) {
            collisionRef.current = true;
            clearAllIntervals();
        }
    }, [isGameComplete]);

    useEffect(() => {
        // if (isGamePausedRef.current) {
        if (isGuideLottiePlaying) return;

        if (isPauseOverlay) {
            clearInterval(tfScrollIntervalRef.current);
            clearInterval(assestsIntervalRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionVisible.current, questionVisible, isPauseOverlay]);

    useEffect(() => {
        if (isEmpty(attemptData)) getChallengeDataAsync();
        if (isEmpty(currentTimeRef.current)) currentTimeRef.current = Date.now();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clearAllIntervals = () => {
        clearInterval(checkOverlapIntervalRef.current);
        clearInterval(tfScrollIntervalRef.current);
        clearInterval(assestsIntervalRef.current);
        clearInterval(rightKeyboardMovementRef.current);
        clearInterval(leftKeyboardMovementRef.current);
    }

    useEffect(() => {
        return () => {
            if (!isEmpty(speedBoxRef.current)) {
                speedBoxRef.current.removeEventListener('wheel', handleSpeedBarAction);
                speedBoxRef.current.removeEventListener('touchstart', handleSpeedBarAction);
                speedBoxRef.current.removeEventListener('touchend', handleSpeedBarAction);
                speedBoxRef.current.removeEventListener('touchmove', handleSpeedBarAction);
                speedBoxRef.current.removeEventListener('mousedown', handleSpeedBarAction);
                speedBoxRef.current.removeEventListener('mousemove', handleSpeedBarAction);
                speedBoxRef.current.removeEventListener('mouseup', handleSpeedBarAction);
            }

            // gameContainer
            if (!isEmpty(leftPartRoadRef.current)) {
                leftPartRoadRef.current.removeEventListener('touchstart', leftKeyBoardMovementStart);
                leftPartRoadRef.current.removeEventListener('touchend', leftKeyboardMovementStop);
                rightPartRoadRef.current.removeEventListener('touchstart', rightKeyBoardMovementStart);
                rightPartRoadRef.current.removeEventListener('touchend', rightKeyboardMovementStop);
                leftSideImgRef.current.removeEventListener('touchstart', leftKeyBoardMovementStart);
                leftSideImgRef.current.removeEventListener('touchend', leftKeyboardMovementStop);
                rightSideImgRef.current.removeEventListener('touchstart', rightKeyBoardMovementStart);
                rightSideImgRef.current.removeEventListener('touchend', rightKeyboardMovementStop);
            }
            clearAllIntervals();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTransformValueFromStyle = (styleValue) => {
        // example translateY(40px)
        return styleValue ? parseInt(styleValue.split('(')[1]?.split(')')[0]?.split('px')[0]) : undefined
        // return styleValue ? parseInt(styleValue.split('(')[1]?.split(',')[1]?.split('px')[0]) : undefined
    }

    const handleProgressBar = () => {
        var progressBarWidth = ((activeIndexRef.current) / totalQuestionLength) * 100;
        progressBarRef.current.style.width = progressBarWidth + '%';
    }

    const handleChangeQuestion = (i) => {

        handleProgressBar();

        if (i >= totalQuestionLength) {
            clearInterval(checkOverlapIntervalRef.current);
            clearInterval(totalTimeIntervalRef.current);
            let currentTime = Date.now();

            // WIN SCREEN

            submitEndChallengeAttempt({
                ...finalChallengeDataRef.current,
                attemptId: attemptRef.current.id,
                microskillId: attemptRef.current.microskillId,
                challengeId: attemptRef.current.challengeId,
                scored: Math.ceil(totalScoreRef.current),
                timespent: totalGameTimeRef.current,
                status: constants.GBA_ILA_STATUS.completed,
                completion: true
            });

            setTimeout(() => {
                handleGbaState(GBA_SCREEN_STATE.WIN);
                setIsGameComplete(true);

                if (!isEmpty(rightKeyboardMovementRef.current)) clearInterval(rightKeyboardMovementRef.current);
                if (!isEmpty(leftKeyboardMovementRef.current)) clearInterval(leftKeyboardMovementRef.current);

                setGameEndType('win');
            }, 3000)
        } else {
            if (activeIndexRef.current < totalQuestionLength) {
                questionNoRef.current.innerHTML = activeIndexRef.current + 1;
            }
            questionRef.current.textContent = gameData.questions[i].question;
            let context = gameData.questions[i].contextId !== null ? contextData.filter(element => element.id === gameData.questions[i].contextId) : [''];
            contextRef.current.textContent = context[0].context;
            setTimeout(() => {
                if (i < totalQuestionLength) {
                    contextRef.current.style.transform = 'scale(1)';
                    questionRef.current.style.transform = 'scale(1)';
                }
            }, [1000])

            leftSideTFRef.current.isCorrect = gbaGameData[i].answers[0].isCorrect;
            leftSideTFRef.current.children[0].textContent = gbaGameData[i].answers[0].answer === "TRUE" ? t("True") : t("False");
            rightSideTFRef.current.isCorrect = gbaGameData[i].answers[1].isCorrect;
            rightSideTFRef.current.children[0].textContent = gbaGameData[i].answers[1].answer === "TRUE" ? t("True") : t("False");

            setTimeout(() => {
                resetCollision();
            }, [2000]);
        }
    }

    const checkRightOverlap = () => {
        if (activeIndexRef.current < totalQuestionLength && gameEndType === '' && !collisionRef.current && !isEmpty(checkOverlapIntervalRef.current)) {
            let carBound = carImgRef.current?.getBoundingClientRect();
            let rightClient = rightSideTFRef.current?.children[0].getBoundingClientRect();

            return (carBound && rightClient) ? (
                carBound.right > rightClient.left &&
                carBound.top + 1 <= rightClient.bottom
            ) : false;
        }
    }

    const checkMissedOverlap = () => {
        if (activeIndexRef.current < totalQuestionLength && gameEndType === '' && !collisionRef.current && !isEmpty(checkOverlapIntervalRef.current)) {
            let carBound = carImgRef.current?.getBoundingClientRect();
            let leftClient = leftSideTFRef.current?.children[0].getBoundingClientRect();
            let rightClient = rightSideTFRef.current?.children[0].getBoundingClientRect();

            return carBound && leftClient && carBound.left > leftClient.right && carBound.right < rightClient.left && carBound.top + 1 <= rightClient.top
        }
    }

    const checkLeftOverlap = () => {
        if (gameEndType === '' && !collisionRef.current && !isEmpty(checkOverlapIntervalRef.current)) {

            let carBound = carImgRef.current?.getBoundingClientRect();
            let leftClient = leftSideTFRef.current?.children[0]?.getBoundingClientRect();

            return carBound && leftClient && carBound.left < leftClient.right && carBound.top + 1 <= leftClient.bottom;
        }
    }

    const resetCollision = () => {
        leftSideTFRef.current.style.transform = 'translate(-140%, -200px)';
        rightSideTFRef.current.style.transform = 'translate(40%, -200px)';
        // leftSideTFRef.current.style.transform = 'translate(-140%, -400px)';
        // rightSideTFRef.current.style.transform = 'translate(40%, -400px)';
        // leftSideTFRef.current.style.transform = 'translateY(-100px)';
        // rightSideTFRef.current.style.transform = 'translateY(-100px)';
        clearInterval(tfScrollIntervalRef.current);
        startTFScrolling(-200);

        checkOverlapIntervalRef.current = setInterval(() => {
            collisionRef.current = false;
            isOverlap();
        }, [200]);
        // setTimeout(() => {
        leftSideTFRef.current.style.display = 'flex';
        rightSideTFRef.current.style.display = 'flex';
        // }, [1000])
    }

    const missedOption = () => {
        let currentTime = Date.now();

        let tempReq = {
            qId: gameData.questions[activeIndexRef.current].questionId,
            aId: null,
            isCorrect: false,
            t: Math.round((currentTime - currentTimeRef.current) / 1000),
            skillId: gameData.questions[activeIndexRef.current].skillId,
            contextId: gameData.questions[activeIndexRef.current].contextId,
        };

        currentTimeRef.current = currentTime;
        handleAttemptedChallenge(tempReq);

        clearInterval(checkOverlapIntervalRef.current);
        clearInterval(tfScrollIntervalRef.current);
        // clearInterval(assestsIntervalRef.current);

        leftSideTFRef.current.style.display = 'none';
        rightSideTFRef.current.style.display = 'none';

        handleIncorrectAnswer();
        missedRef.current.style.opacity = '1';
        // questionContainerRef.current.style.bottomBorder = 'red';
        // missedRef.current.classList.add(`${classes.optionAnimation}`);

        setTimeout(() => {
            missedRef.current.style.opacity = '0';
            // missedRef.current.classList.remove(`${classes.optionAnimation}`);
        }, [1000]);
    }

    const optionCollid = async (container = '') => {
        clearInterval(checkOverlapIntervalRef.current);
        clearInterval(tfScrollIntervalRef.current);
        // clearInterval(assestsIntervalRef.current);

        let currentTime = Date.now();
        let tempReq = {
            qId: gameData.questions[activeIndexRef.current].questionId,
            // aId: item.answerId,
            isCorrect: false,
            t: Math.round((currentTime - currentTimeRef.current) / 1000),
            skillId: gameData.questions[activeIndexRef.current].skillId,
            contextId: gameData.questions[activeIndexRef.current].contextId,
        };

        currentTimeRef.current = currentTime;
        if (container === 'Left') {
            if (leftSideTFRef.current.isCorrect) {
                leftRef.current.textContent = "CORRECT!";

                if (isOptionClicked.current) {
                    isNitro.current.style.display = 'block';
                    await sleep(900);
                }

                leftRef.current.style.opacity = '1';
                leftRef.current.classList.add(`${classes.optionAnimation}`);
                if (activeIndexRef.current < totalQuestionLength) {
                    tempReq['aId'] = gameData.questions[activeIndexRef.current].answers[0].id;
                    tempReq['isCorrect'] = true;
                    handleAttemptedChallenge(tempReq);
                }
                handleCorrectAnswer();
            }
            else {
                leftRef.current.textContent = "WRONG!";
                leftRef.current.style.opacity = '1';
                leftRef.current.classList.add(`${classes.optionAnimation}`);

                if (activeIndexRef.current < totalQuestionLength) {
                    tempReq['aId'] = gameData.questions[activeIndexRef.current].answers[1].id;
                    tempReq['isCorrect'] = false;
                    handleAttemptedChallenge(tempReq);
                }
                handleIncorrectAnswer();
            }

            setTimeout(() => {
                if (activeIndexRef.current < totalQuestionLength && gameEndType === '') {
                    leftRef.current.style.opacity = '0';
                    leftRef.current.classList.remove(`${classes.optionAnimation}`);

                    isOptionClicked.current = false;
                    isNitro.current.style.display = 'none';
                }
            }, [1000]);
        }
        else if (container === 'Right') {
            if (rightSideTFRef.current.isCorrect) {
                rightRef.current.textContent = "CORRECT!";

                if (isOptionClicked.current) {
                    isNitro.current.style.display = 'block';
                    await sleep(900);
                }

                rightRef.current.style.opacity = '1';
                rightRef.current.classList.add(`${classes.optionAnimation}`);

                if (activeIndexRef.current < totalQuestionLength) {
                    tempReq['aId'] = gameData.questions[activeIndexRef.current].answers[1].id;
                    tempReq['isCorrect'] = true;
                    handleAttemptedChallenge(tempReq);
                }

                handleCorrectAnswer();
            }
            else {
                rightRef.current.textContent = "WRONG!";
                rightRef.current.style.opacity = '1';
                rightRef.current.classList.add(`${classes.optionAnimation}`);
                if (activeIndexRef.current < totalQuestionLength) {
                    tempReq['aId'] = gameData.questions[activeIndexRef.current].answers[0].id;
                    tempReq['isCorrect'] = false;
                    handleAttemptedChallenge(tempReq);
                }
                handleIncorrectAnswer();
            }

            setTimeout(() => {
                if (activeIndexRef.current < totalQuestionLength && gameEndType === '') {
                    rightRef.current.style.opacity = '0';
                    rightRef.current.classList.remove(`${classes.optionAnimation}`);

                    isOptionClicked.current = false;
                    isNitro.current.style.display = 'none';
                }
            }, [1000]);
        }
    }

    const handleLifeLoss = async () => {
        let lifeValue = parseInt(lifeRef.current.innerHTML) - 1;
        if (currentSmockRef.current <= 3) {
            allCarsSmokeRef.current[currentSmockRef.current].current.style.display = 'block';
        }

        setIsCarRotate(true);

        setTimeout(() => {
            setIsCarRotate(false);
            currentSmockRef.current += 1;
        }, 1500)

        setLife(lifeValue);
        activeIndexRef.current += 1;

        if (lifeValue > 1) {
            lifeTextRef.current.style.color = '#FF5757';
            heartRef.current.src = '/images/icons/heart-red.png';
            lifeTextRef.current.classList.add(`${classes.blinkAnimationEffect}`);
            if (!isEmpty(questionContainerRef.current)) questionContainerRef.current.classList.add(`${classes.qstnBoxBoxShadow}`)
            if (!isEmpty(leftSideImgRef.current)) leftSideImgRef.current.classList.add(`${classes.wrongAnsAnimation}`);
            if (!isEmpty(rightSideImgRef.current)) rightSideImgRef.current.classList.add(`${classes.wrongAnsAnimation}`);

            setTimeout(() => {
                lifeTextRef.current.style.color = '#FFFFFF';
                heartRef.current.src = '/images/icons/heart.png';
                lifeTextRef.current.classList.remove(`${classes.blinkAnimationEffect}`);
                if (!isEmpty(questionContainerRef.current)) questionContainerRef.current.classList.remove(`${classes.qstnBoxBoxShadow}`);
                if (!isEmpty(leftSideImgRef.current)) leftSideImgRef.current.classList.remove(`${classes.wrongAnsAnimation}`);
                if (!isEmpty(rightSideImgRef.current)) rightSideImgRef.current.classList.remove(`${classes.wrongAnsAnimation}`);
            }, [3000])
        }
        else if (lifeValue === 1) {
            lifeTextRef.current.style.color = '#FF5757';
            heartRef.current.src = '/images/icons/heart-red.png';
            lifeTextRef.current.classList.add(`${classes.blinkAnimationEffect}`);
            // questionContainerRef.current.classList.add(`${classes.questionAnimation}`);
            questionContainerRef.current.classList.add(`${classes.qstnBoxBoxShadow}`)

            if (!isEmpty(leftSideImgRef.current)) leftSideImgRef.current.classList.remove(`${classes.wrongAnsAnimation}`);
            if (!isEmpty(rightSideImgRef.current)) rightSideImgRef.current.classList.remove(`${classes.wrongAnsAnimation}`);

            leftSideImgRef.current.classList.add(`${classes.incorrectGradientOverlayAnimationInfinite}`);
            rightSideImgRef.current.classList.add(`${classes.incorrectGradientOverlayAnimationInfinite}`);

            // setTimeout(() => {
            //     lifeTextRef.current.style.color = '#FFFFFF';
            //     heartRef.current.src = '/images/icons/heart.png';
            // }, [3000])
        }

        if (lifeValue > 0) {
            lifeRef.current.innerHTML = lifeValue + ' x';
            handleChangeQuestion(activeIndexRef.current);
        }
        else {
            if (lifeValue === 0) {
                // setIsCarRotate(true);
                lifeRef.current.innerHTML = lifeValue + ' x';
                clearInterval(checkOverlapIntervalRef.current);
                clearInterval(totalTimeIntervalRef.current);
                let currentTime = Date.now();

                const submitEndChallengeAttemptData = await submitEndChallengeAttempt({
                    ...finalChallengeDataRef.current,
                    attemptId: attemptRef.current.id,
                    microskillId: attemptRef.current.microskillId,
                    challengeId: attemptRef.current.challengeId,
                    scored: Math.ceil(totalScoreRef.current),
                    timespent: totalGameTimeRef.current,
                    status: constants.GBA_ILA_STATUS.lifeLose,
                    completion: false
                });



                setIncorrectData(submitEndChallengeAttemptData?.data?.data?.consecutiveIncorrectAttempt);
                setTimeout(() => {
                    handleGbaState(GBA_SCREEN_STATE.LOOSE);
                    // carImgRef.current.style.left = '50%';
                    setIsGameComplete(true);
                    // setIsCarRotate(false);

                    if (!isEmpty(rightKeyboardMovementRef.current)) clearInterval(rightKeyboardMovementRef.current);
                    if (!isEmpty(leftKeyboardMovementRef.current)) clearInterval(leftKeyboardMovementRef.current);

                    setGameEndType('loose');

                }, [3000])
            }
        }
    }

    const handleCorrectAnswer = () => {

        const previousSpeed = speedLevelRef.current;
        speedLevelRef.current = 6;

        startTFScrolling(translatedTFRef.current);

        isNitro.current.style.display = 'block';

        setTimeout(() => {
            speedLevelRef.current = previousSpeed;
        }, 250);

        activeIndexRef.current += 1;
        totalScoreRef.current += marksPerQuestion;
        handleChangeQuestion(activeIndexRef.current);
    }

    const handleIncorrectAnswer = () => {
        if (activeIndexRef.current < totalQuestionLength) {
            handleLifeLoss();
        }
    }

    const setInitialParameters = () => {
        if (!isEmpty(leftSideTFRef.current)) leftSideTFRef.current.style.display = 'none';
        if (!isEmpty(rightSideTFRef.current)) rightSideTFRef.current.style.display = 'none';

        let scrollValue = -100;
        clearInterval(tfScrollIntervalRef.current);
        if (!isEmpty(carImgRef.current)) carImgRef.current.classList.add(`${classes.blinkAnimation}`)
        startTFScrolling(scrollValue, true);

        setTimeout(() => {
            if (!isEmpty(carImgRef.current)) carImgRef.current.classList.remove(`${classes.blinkAnimation}`)
            carCanMove.current = true;
        }, 3000)
        setTimeout(() => {
            questionVisible.current = true;
        }, [2000]);
    }

    const isOverlap = async () => {

        await sleep(1000);
        if (!collisionRef.current) {

            let leftCollid = checkLeftOverlap();
            let rightCollid = checkRightOverlap();
            let missedCollid = checkMissedOverlap();

            if (leftCollid) {
                collisionRef.current = true;
                if (activeIndexRef.current <= 9) {
                    leftSideTFRef.current.style.display = 'none';
                    rightSideTFRef.current.style.display = 'none';
                }
                questionRef.current.style.transform = 'scale(0)';
                contextRef.current.style.transform = 'scale(0)';
                optionCollid('Left');
            } else if (rightCollid) {
                collisionRef.current = true;
                if (activeIndexRef.current <= 9) {
                    leftSideTFRef.current.style.display = 'none';
                    rightSideTFRef.current.style.display = 'none';

                }
                questionRef.current.style.transform = 'scale(0)';
                contextRef.current.style.transform = 'scale(0)';
                optionCollid('Right');
            } else if (missedCollid) {
                collisionRef.current = true;
                if (activeIndexRef.current <= 9) {
                    leftSideTFRef.current.style.display = 'none';
                    rightSideTFRef.current.style.display = 'none';
                }
                questionRef.current.style.transform = 'scale(0)';
                contextRef.current.style.transform = 'scale(0)';
                missedOption();
            }
        }
    }

    const restartGame = () => {
        setIsGamePage(true);
        setIsGameComplete(false);
        handleGbaState(GBA_SCREEN_STATE.GAME);
        questionVisible.current = false;

        speedBoxRef.current = null;
        speedBarRectBoxRef.current = null;

        getChallengeDataAsync()
        setTimeout(() => {
            startGame();
        }, [1100]);

        totalScoreRef.current = 0;
        setSpeedLevel(roadSpeed2);
        setRoadSpeedLevel(roadSpeed2);
        speedLevelRef.current = 2;
        setLife(maxLife);
        activeIndexRef.current = 0;
        setGameEndType('');
        currentTimeRef.current = Date.now();
        finalChallengeDataRef.current = null;

        carSmokeOneRef.current = null;
        carSmokeTwoRef.current = null;
        carSmokeThreeRef.current = null;
        carSmokeFourRef.current = null;
        currentSmockRef.current = 0;
        allCarsSmokeRef.current = ([carSmokeOneRef, carSmokeTwoRef, carSmokeThreeRef, carSmokeFourRef]);

        clearInterval(tfScrollIntervalRef.current);
        clearInterval(checkOverlapIntervalRef.current);
        clearInterval(rightKeyboardMovementRef.current);
        clearInterval(leftKeyboardMovementRef.current);
        clearInterval(assestsIntervalRef.current);
    }

    const handleBackButton = () => {

        clearInterval(tfScrollIntervalRef.current);
        clearInterval(checkOverlapIntervalRef.current);
        clearInterval(totalTimeIntervalRef.current);
        let currentTime = Date.now();
        let timeSpent = totalGameTimeRef.current;

        dispatch(handleGbaGameState({
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: isEmpty(totalScoreRef.current) ? 0 : Math.ceil(totalScoreRef.current),
            timespent: timeSpent
        }));
    }

    const handleResumeButton = () => {
        if (isGamePage) {
            totalTimeIntervalRef.current = setInterval(() => {
                setAddPause(true);
                totalGameTimeRef.current += 1;
            }, [1000]);
            setAddPause(true);
            startTFScrolling(translatedTFRef.current);
            checkOverlapIntervalRef.current = setInterval(() => {
                isOverlap();
            }, [200]);
        }
    }

    const setSpeedBar = (elArray, upto, gradient) => {
        for (let i = 0; i < elArray.length; i++) {
            if (i < upto) {
                elArray[i].style.background = gradient;
            } else {
                elArray[i].style.background = "#fff";
            }
        }
    }

    const setSpeed = (interval, barLengthRatio) => {
        let speedBarChildren = speedBarRectBoxRef.current.children;
        let ratio = 1;
        if (barLengthRatio === 'one') {
            // leftLoadAnimation.setSpeed(1);
            speedLevelRef.current = 1;
            ratio = 1;
        } else if (barLengthRatio === 'half') {
            // leftLoadAnimation.setSpeed(2);
            speedLevelRef.current = 2;
            ratio = speedBarChildren.length / 2;
        } else if (barLengthRatio === 'all') {
            // leftLoadAnimation.setSpeed(3);
            speedLevelRef.current = 3;
            ratio = speedBarChildren.length;
        }
        // let currentTFY = parseInt(getTransformValueFromStyle(leftSideImgRef.current.style.transform));
        // clearInterval(tfScrollIntervalRef.current);
        // clearInterval(assestsIntervalRef.current);
        startTFScrolling(translatedTFRef.current);
        setSpeedBar(speedBarChildren, ratio, speedBarGradient);
    }

    const handleSpeedBarAction = (e, type) => {

        e.preventDefault();
        e.stopPropagation();

        if (e.key === 'ArrowDown' || e?.target?.id === 'speedDecrement' || e?.target?.parentElement?.id === 'speedDecrement' && (e.type === 'touchend' || e.type === 'mousedown')) {

            setSpeedLevel(prevState => {
                if (prevState === roadSpeed1) return (prevState)
                if (prevState === roadSpeed2) {
                    setSpeed(carFlowSpeed1, 'one');
                    setRoadSpeedLevel(roadSpeed1);
                    return (roadSpeed1);
                } else {
                    setSpeed(carFlowSpeed2, 'half');
                    setRoadSpeedLevel(roadSpeed2);
                    return (roadSpeed2);
                }
            })
        } else if (e.key === 'ArrowUp' || e?.target?.id === 'speedIncrement' || e?.target?.parentElement?.id === 'speedIncrement' && (e.type === 'touchend' || e.type === 'mousedown')) {
            setSpeedLevel(prevState => {
                if (prevState === roadSpeed1) {
                    setSpeed(carFlowSpeed2, 'half');
                    setRoadSpeedLevel(roadSpeed2);
                    return (roadSpeed2);
                } else {
                    setSpeed(carFlowSpeed3, 'all');
                    setRoadSpeedLevel(roadSpeed3);
                    return (roadSpeed3);
                }
            })
        }
    }

    const getIntervalFromRoadSpeed = () => {
        if (isEmpty(leftSideImgRef.current)) return;
        let lottieAssestHeight = leftSideImgRef.current.getBoundingClientRect().height;


        switch (speedLevelRef.current) {
            case 1:
                return Math.round(1000 / (lottieAssestHeight * (lottieMovedPercentagePerSec * roadSpeed1)));

            case 2:
                return Math.round(1000 / (lottieAssestHeight * (lottieMovedPercentagePerSec * roadSpeed2)));

            case 6:
                return parseInt(1000 / (lottieAssestHeight * (lottieMovedPercentagePerSec * roadSpeed3 * 99)));

            default:
                return Math.round(1000 / (lottieAssestHeight * (lottieMovedPercentagePerSec * roadSpeed3)));
        }
    }

    const startTFScrolling = (scrollValue, scrollSidesOnly = false) => {
        // setGameEndType('win');
        // setIsGameComplete(true);

        if (isGamePausedRef.current) {
            translatedTFRef.current = scrollValue;
            handleBackButton();
            return;
        }

        function createEl(type) {
            const newElement = document.createElement('img');
            newElement.src = type === 'road' ? "/images/gba/road-stripes-long.png" :
                (type === 'left' ? "/images/gba/left-side-road-long.png" : "/images/gba/right-side-road-long.png");

            // here some css is written in app.css to prevent mui issues
            const StripsClasses = `disableSelection road_side_img`;
            const leftSideClasses = `disableSelection road_side_img`;
            const rightSideClasses = `disableSelection road_side_img rotate_180`

            newElement.className = type === 'road' ? StripsClasses : (type === 'left' ? leftSideClasses : rightSideClasses)
            newElement.id = type === 'road' ? "road" : (type === 'left' ? "leftRoadImg" : "rightRoadImg");
            newElement.draggable = false;
            newElement.alt = type === 'road' ? "long-road" : (type === 'left' ? "left-road" : "right-road");

            return newElement;
        }

        // reset tf button and side roads assets scrolling
        clearInterval(tfScrollIntervalRef.current);
        clearInterval(assestsIntervalRef.current);
        // reset tf button and side roads assets scrolling

        if (questionVisible.current && !scrollSidesOnly) {
            tfScrollIntervalRef.current = setInterval(() => {
                scrollValue += 1;
                translatedTFRef.current = scrollValue;
                if (!isEmpty(leftSideTFRef.current)) leftSideTFRef.current.style.transform = `translate(-140%, ${scrollValue}px)`;
                if (!isEmpty(rightSideTFRef.current)) rightSideTFRef.current.style.transform = `translate(40%, ${scrollValue}px)`;
            }, getIntervalFromRoadSpeed());
        }


        assestsIntervalRef.current = setInterval(() => {
            let movedPixel = 0;
            if (!isEmpty(scrollBoxRef?.current?.offsetHeight)) {
                movedPixel = scrollBoxRef.current.offsetHeight + getTransformValueFromStyle(leftRoadImgRef.current.style.transform);
            }

            // assestsScrollValueRef.current += 1;
            assestsScrollValueRef.current += 1;

            if (!isEmpty(leftRoadImgRef.current) && !isEmpty(rightRoadImgRef.current) && !isEmpty(roadStripImgRef.current)) {
                leftRoadImgRef.current.style.transform = `translateY(${assestsScrollValueRef.current}px)`;
                rightRoadImgRef.current.style.transform = `translateY(${assestsScrollValueRef.current}px)`;
                roadStripImgRef.current.style.transform = `translateY(${assestsScrollValueRef.current}px)`;
                if (movedPixel >= sideImgHeight.current && leftRoadImgRef.current.children.length < 2) {
                    leftRoadImgRef.current.insertBefore(createEl('left'), leftRoadImgRef.current.firstChild);
                    rightRoadImgRef.current.insertBefore(createEl('right'), rightRoadImgRef.current.firstChild);
                    roadStripImgRef.current.insertBefore(createEl('road'), roadStripImgRef.current.firstChild);
                }

                if (movedPixel >= (sideImgHeight.current * 2) && leftRoadImgRef.current.children.length < 3) {
                    leftRoadImgRef.current.insertBefore(createEl('left'), leftRoadImgRef.current.firstChild);
                    rightRoadImgRef.current.insertBefore(createEl('right'), rightRoadImgRef.current.firstChild);
                    roadStripImgRef.current.insertBefore(createEl('road'), roadStripImgRef.current.firstChild);
                }

            }
        }, getIntervalFromRoadSpeed());
    }

    const moveToLeft = (value = null) => {
        if (!carCanMove.current) return;

        setLastMovement("LEFT");
        if (!leftKeyboardMovementRef.current) {
            if (!isEmpty(value)) {
                carImgRef.current.style.left = value;
                return;
            }
            leftKeyboardMovementRef.current = setInterval(() => {
                let car = carImgRef.current.style.left;
                car = car.replace('%', '');
                car = Number(car);
                car = car <= leftMovement ? leftMovement : car - 0.1 * speedLevelRef.current;
                carImgRef.current.style.left = `${car}%`;
            }, 1)
        }
    }

    const moveToRight = (value = null) => {
        if (!carCanMove.current) return;

        setLastMovement("RIGHT");
        if (!rightKeyboardMovementRef.current) {
            if (!isEmpty(value)) {
                carImgRef.current.style.left = value;
                return
            }
            rightKeyboardMovementRef.current = setInterval(() => {
                let car = carImgRef.current?.style.left;
                car = car.replace('%', '');
                car = Number(car);
                car = car >= rightMovement ? rightMovement : (car < leftMovement ? leftMovement : car + 0.1 * speedLevelRef.current);
                carImgRef.current.style.left = `${car}%`;
            }, 1)
        }
    }

    const handleCarMovement = (e) => {
        const containerLeft = roadRef.current.getBoundingClientRect().left;
        let clickX = e.clientX - containerLeft;
        if (e.type === "touchstart") {
            clickX = e.touches[0].clientX - containerLeft;
        }
        const smallBoxLeft = parseFloat(window.getComputedStyle(carImgRef.current).left);
        const isLeftSide = clickX <= smallBoxLeft;
        if (isLeftSide) {
            moveToLeft();
        } else {
            moveToRight();
        }
    }

    const leftKeyBoardMovementStart = (e, isKeyBoardPressed = false) => {
        // e.preventDefault();
        // e.stopPropagation();
        if (isKeyBoardPressed) {
            moveToLeft();
            return
        }
        handleCarMovement(e);
    }

    const rightKeyBoardMovementStart = (e, isKeyBoardPressed = false) => {
        e.preventDefault();
        e.stopPropagation();
        if (isKeyBoardPressed) {
            moveToRight();
            return
        }
        handleCarMovement(e);
    }

    const stopCarMovement = () => {
        if (leftKeyboardMovementRef.current) {
            clearInterval(leftKeyboardMovementRef.current);
            leftKeyboardMovementRef.current = false;
        }
        if (rightKeyboardMovementRef.current) {
            clearInterval(rightKeyboardMovementRef.current);
            rightKeyboardMovementRef.current = false;
        }
    }

    const leftKeyboardMovementStop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        stopCarMovement();
    }

    const rightKeyboardMovementStop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        stopCarMovement();
    }

    const handleAttemptedChallenge = (params) => {
        let { qId, aId, isCorrect, t, skillId, contextId } = params;

        let questionObj = {
            questionId: gameData.questions[activeIndexRef.current].id,
            contextId: gameData.questions[activeIndexRef.current].contextId,
            skillId: gameData.questions[activeIndexRef.current].skillId,
            answers: [{
                answerId: aId,
                correct: isCorrect
            }],
            timespent: t
        };
        let req = {
            microskillId: microskillId,
            challengeId: challengeId,
            attemptId: attemptRef.current.id,
            questions: [questionObj]
        }

        submitChallengeAttemptData(req);
        handleEndChallengeAttempt(questionObj, req, skillId, contextId)
    }

    const getChallengeDataAsync = async () => {
        const attemptResp = await startChallengeAttempt({ microskillId, challengeId });
        setAttemptData(attemptResp.data.data);
        attemptRef.current = attemptResp.data.data;
    };

    useEffect(() => {
        if (isEmpty(attemptData)) return;
        // set initial state

        let tempData = {
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: isEmpty(totalScoreRef.current) ? 0 : Math.ceil(totalScoreRef.current),
            status: constants.GBA_ILA_STATUS.exited,
        }

        dispatch(handleGbaGameState(tempData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attemptData])

    // const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
    //     let tempQuestionObj = {
    //         ...questionObj,
    //         skillId: skillId,
    //         contextId: contextId,
    //     }
    //     let totalTimeSpent = (isEmpty(finalChallengeDataRef.current) ? 0 : finalChallengeDataRef.current.timespent) + tempQuestionObj.timespent;
    //     if (isEmpty(finalChallengeDataRef.current)) {
    //         finalChallengeDataRef.current = {
    //             ...req,
    //             questions: [tempQuestionObj],
    //             completion: true,
    //             timespent: totalTimeSpent,
    //             scored: 0,
    //             fallbackSave: true,
    //         }

    //     } else {
    //         finalChallengeDataRef.current.questions.push(tempQuestionObj)
    //         finalChallengeDataRef.current = {
    //             ...finalChallengeDataRef.current,
    //             timespent: totalTimeSpent,
    //         }
    //     }
    // }


    const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
        const tempQuestionObj = {
            ...questionObj,
            skillId,
            contextId,
        };

        const currentChallengeData = finalChallengeDataRef.current || {};
        const totalTimeSpent = (currentChallengeData.timespent || 0) + tempQuestionObj.timespent;

        if (isEmpty(currentChallengeData)) {
            finalChallengeDataRef.current = {
                ...req,
                questions: [tempQuestionObj],
                completion: true,
                timespent: totalTimeSpent,
                scored: 0,
                fallbackSave: true,
            };
        } else {
            // Check if questions exist and create a new array accordingly
            finalChallengeDataRef.current = {
                ...currentChallengeData,
                questions: [
                    ...(currentChallengeData.questions || []), // Use an empty array if questions is undefined
                    tempQuestionObj,
                ],
                timespent: totalTimeSpent,
            };
        }
    };



    useEffect(() => {
        if (isEmpty(leftSideTFRef.current)) return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leftSideTFRef.current]);

    const startGame = async () => {
        clearInterval(totalTimeIntervalRef.current);
        totalTimeIntervalRef.current = setInterval(() => {
            setAddPause(true);
            totalGameTimeRef.current += 1;
        }, 1000);

        if (!isEmpty(carImgRef.current)) carImgRef.current.style.opacity = 0;
        if (!isEmpty(leftSideTFRef.current)) leftSideTFRef.current.style.transform = 'translate(-140%, -200px)';
        if (!isEmpty(rightSideTFRef.current)) rightSideTFRef.current.style.transform = 'translate(40%, -200px)';
        // leftSideTFRef.current.style.transform = 'translateY(-200px)';
        // rightSideTFRef.current.style.transform = 'translateY(-200px)';
        handleGbaState(GBA_SCREEN_STATE.GAME);
        totalScoreRef.current = 0;
        assestsScrollValueRef.current = 0;

        //for speedBar
        let scrollElement2 = speedBoxRef.current;
        scrollElement2.addEventListener('wheel', handleSpeedBarAction, { passive: false });
        scrollElement2.addEventListener('touchstart', handleSpeedBarAction, { passive: false });
        scrollElement2.addEventListener('touchend', handleSpeedBarAction, { passive: false });
        // scrollElement2.addEventListener('touchmove', handleSpeedBarAction, { passive: false });
        scrollElement2.addEventListener('mousedown', handleSpeedBarAction, { passive: false });
        // scrollElement2.addEventListener('mousemove', handleSpeedBarAction, { passive: false });
        scrollElement2.addEventListener('mouseup', handleSpeedBarAction, { passive: false });


        // gameContainer
        leftPartRoadRef.current.addEventListener('touchstart', leftKeyBoardMovementStart);
        // leftPartRoadRef.current.addEventListener('touchstart', leftKeyBoardMovementStart, { passive: false });
        leftPartRoadRef.current.addEventListener('touchend', leftKeyboardMovementStop);
        rightPartRoadRef.current.addEventListener('touchstart', rightKeyBoardMovementStart);
        rightPartRoadRef.current.addEventListener('touchend', rightKeyboardMovementStop);

        leftSideImgRef.current.addEventListener('touchstart', leftKeyBoardMovementStart, { passive: false });
        leftSideImgRef.current.addEventListener('touchend', leftKeyboardMovementStop, { passive: false });
        rightSideImgRef.current.addEventListener('touchstart', rightKeyBoardMovementStart, { passive: false });
        rightSideImgRef.current.addEventListener('touchend', rightKeyboardMovementStop, { passive: false });

        collisionRef.current = false;
        setInitialParameters();
        questionContainerRef.current.style.transform = 'translateY(0px)';

        setTimeout(() => {
            carImgRef.current.style.opacity = 1;
            carImgRef.current.style.left = '50%';
            // setInitialParameters();
            handleChangeQuestion(0);
        }, [3500])

        checkOverlapIntervalRef.current = setInterval(async function () {
            if (activeIndexRef.current < totalQuestionLength) {
                isOverlap();
            }
        }, 200);
    }

    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }

    document.onkeydown = (e) => {
        if (isGamePage && !isGameComplete) {
            switch (e.key) {
                case 'ArrowLeft':
                    leftKeyBoardMovementStart(e, true);
                    break;
                case 'ArrowRight':
                    rightKeyBoardMovementStart(e, true);
                    break;
                case 'ArrowUp':
                    handleSpeedBarAction(e, 'one');
                    break;
                case 'ArrowDown':
                    handleSpeedBarAction(e, 'two');
                    break;
                default:
                    break;
            }
        }
    }

    document.onkeyup = (e) => {
        if (isGamePage && !isGameComplete) {
            switch (e.key) {
                case 'ArrowLeft':
                    leftKeyboardMovementStop(e);
                    break;
                case 'ArrowRight':
                    rightKeyboardMovementStop(e);
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {

        isGamePausedRef.current = isPauseOverlay;
        if (isPauseOverlay) {
            handleBackButton();
            pausedTimeRef.current = Date.now();
        } else if (isGamePage) {
            pausedTimeRef.current = Date.now() - pausedTimeRef.current;
            currentTimeRef.current += pausedTimeRef.current;
            handleResumeButton();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPauseOverlay]);

    useEffect(() => {
        if (isGamePage) {
            const leftRoadImg = document.getElementById('leftRoadImg');
            // const rightRoadImg = document.getElementById('rightRoadImg');

            // Wait for the image to load
            if (leftRoadImg) {
                leftRoadImg.onload = function () {
                    sideImgHeight.current = leftRoadImg.height;
                    if (isGuideLottiePlaying) return
                    startGame();
                    // currentTimeRef.current = Date.now()

                };
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGamePage])

    useEffect(() => {
        setFSize(window.innerHeight / 961);
    }, [])

    window.addEventListener("resize", resFont);

    const handleGuideLottieComplete = async () => {
        await sleep(1000 * 1);
        setIsGuideLottiePlaying(false);
        startGame();
    }

    const handleSkipButtonClick = () => {
        setIsGuideLottiePlaying(false);
        startGame();
    }

    const handleOptionClick = async (event, side) => {

        event.stopPropagation();
        isOptionClicked.current = true;

        const previousSpeed = speedLevelRef.current;
        speedLevelRef.current = 6;

        startTFScrolling(translatedTFRef.current);

        // let optionContainerHeight = leftPartRoadRef.current.getBoundingClientRect()?.height;
        // let carImgRect = carImgRef.current.getBoundingClientRect();
        // let scrollVal = Math.floor(optionContainerHeight - (2.25 * carImgRect?.height));

        // assestsScrollValueRef.current = scrollVal;

        if (side === 'LEFT') {

            moveToLeft('25%');
        }

        if (side === 'RIGHT') {
            moveToRight('75%');
        }

        // leftSideTFRef.current.style.transition = `transform ${scrollVal / 2}ms ease`;
        // leftSideImgRef.current.style.transition = `transform ${scrollVal / 2}ms ease`;
        // rightSideTFRef.current.style.transition = `transform ${scrollVal / 2}ms ease`;
        // rightSideImgRef.current.style.transition = `transform ${scrollVal / 2}ms ease`;

        // startTFScrolling(scrollVal, true);

        await sleep(200);
        leftSideTFRef.current.style.transition = `transform 0s ease`;
        rightSideTFRef.current.style.transition = `transform 0s ease`;
        leftSideImgRef.current.style.transition = `transform 0s ease`;
        rightSideImgRef.current.style.transition = `transform 0s ease`;

        speedLevelRef.current = previousSpeed;
    }

    const getLottieContainer = () => {
        if (userMicroskillChallenge.totalAttempts > 1) {
            setIsGuideLottiePlaying(false);
            // startGame();
            return null;
        } else {
            return (
                <div className={classes.howToPlayContainer}>
                    <Lottie
                        animationData={HOW_TO_PLAY_LOTTIE}
                        style={{ width: '100%', height: '100%' }}
                        // ref={correctLottieRefsArray.current[i]}
                        loop={false}
                        play={true}
                        onComplete={handleGuideLottieComplete}
                    />

                    <div
                        style={{
                            display: !isGuideLottiePlaying ? 'none' : 'block',
                            paddingRight: '1.6rem',
                            left: !isMobile ? '70%' : '75%',
                        }}
                        className={classes.skipBtnContainer}
                    >
                        <div className={classes.skipButtonBox} onClick={handleSkipButtonClick}>
                            <Button variant="outlined" className={classes.skipBtn} style={{ background: '#fff', color: '#000' }}>
                                {t("Skip")} <ChevronRightIcon style={{ color: '#000' }} />
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div style={(startScreenGameLogoLoaded && startScreenGameBgLoaded) ? { backgroundImage: !isGamePage ? bgGradientDefault : bgGradientDefault, fontSize: `${fSize}rem`, height: '100%' } : { backgroundImage: 'linear-gradient(#000, #000)' }}
            className={classes.CrazyCarsRoot} ref={CrazyCarsRef}>

            {
                isEmpty(attemptData) ? (
                    <div style={constants.FULL_HEIGHT_CENTER}>
                        <CircularProgress />
                    </div>
                ) : (
                    !isGamePage ? (
                        !isHowToPlayScreen ? (
                            <>
                                <StartScreen
                                    setIsGamePage={setIsGamePage}
                                    setIsHowToPlayScreen={setIsHowToPlayScreen}
                                    bg={"/images/gba/crazycar_road.svg"}
                                    logoWidth={'85%'}
                                    gameLogo={"/images/gba/crazycar_logo.svg"}
                                    handleGbaState={handleGbaState}

                                    startScreenGameLogoLoaded={startScreenGameLogoLoaded}
                                    setStartScreenGameLogoLoaded={setStartScreenGameLogoLoaded}
                                    startScreenGameBgLoaded={startScreenGameBgLoaded}
                                    setStartScreenGameBgLoaded={setStartScreenGameBgLoaded}
                                />
                            </>
                        ) : (
                            <div> How to play of crazy cars</div>
                        )
                    ) : (
                        !isGameComplete ? (
                            <>
                                {isGuideLottiePlaying && getLottieContainer()}
                                <div className={classes.incorrectGradientOverlay} ref={incorrectGradientOverlayRef} />
                                <div className={classes.gameContainer} id="gameContainer">
                                    <div className={classes.qstnWrapper}>

                                        <div className={classes.progressBarContainer}>
                                            <div className={classes.progressBar} ref={progressBarRef} />
                                        </div>

                                        {/* Question Container */}

                                        <div className={classes.qstnContainer} ref={questionContainerRef}>
                                            <div className={classes.qstnBox}>
                                                <div className={classes.assetBox}>
                                                    <div className={classes.lifeText} ref={lifeTextRef}>
                                                        <div className={classes.life} ref={lifeRef}>{life} x</div>
                                                        <img src='/images/icons/heart.png' className={`disableSelection disablePointerEvents ${classes.icon}`} ref={heartRef} alt="heart" />
                                                    </div>

                                                    <div className={classes.questionNo}>Q<span ref={questionNoRef}>{activeIndexRef.current >= totalQuestionLength ? totalQuestionLength : activeIndexRef.current + 1}</span>/{totalQuestionLength}</div>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', height: '90%' }}>
                                                    <div className={classes.context} ref={contextRef}></div>
                                                    <div className={classes.qstn} ref={questionRef}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Game Play Container */}
                                    <div className={classes.gameplayContainer} ref={gamePlayContainerRef}>

                                        <div className={classes.scrollBox} ref={scrollBoxRef}>

                                            <div className={classes.leftSideImg} ref={leftSideImgRef}>
                                                <div style={{ pointerEvents: 'none' }} className={classes.imgBox} ref={leftRoadImgRef}>
                                                    <img src="/images/gba/left-side-road-long.png" draggable='false' alt='left-road' className={`disableSelection disablePointerEvents ${classes.roadSideImg}`} id="leftRoadImg" />
                                                </div>
                                                {/* <Lottie options={leftLottieOptions}
                                                    height={'100%'}
                                                    width={'90%'}
                                                    speed={speedLevel}
                                                    isClickToPauseDisabled={true}
                                                /> */}
                                            </div>

                                            <div className={classes.road}
                                                style={{ background: `${bgGradientDefault}` }}
                                                ref={roadRef}>

                                                {/* True Container */}

                                                <div className={classes.optionContainerBox} ref={leftPartRoadRef} >

                                                </div>

                                                <div className={`${classes.optionContainer} ${classes.optionContainerLeft}`} ref={leftSideTFRef} style={{
                                                    transform: 'translate(-140%, -100px)'
                                                }} >
                                                    <div className={classes.optionWrapper} onClick={(e) => handleOptionClick(e, 'LEFT')}>
                                                        <div className={classes.options}>
                                                            {t("True")}
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Road Strips */}

                                                <div className={classes.roadStrips} ref={roadStripsRef}>
                                                    <div className={classes.imgBox} ref={roadStripImgRef}>
                                                        <img src="/images/gba/road-stripes-long.png" alt="long-road" className={`disableSelection disablePointerEvents ${classes.roadStripsImg}`} id="road" />
                                                    </div>
                                                    {/* <Lottie options={roadLottieOptions}
                                                        height={'100%'}
                                                        width={'100%'}
                                                        speed={roadSpeedLevel}
                                                        isClickToPauseDisabled={true}
                                                    /> */}
                                                </div>

                                                {/* <div className={classes.missedContainer} ref={missedRef}>MISSED!</div>
                                                <div className={classes.answer} ref={leftRef}>InCORRECT!</div>
                                                <div className={classes.answer} ref={rightRef}>CORRECT!</div> */}

                                                {/* Answer Box */}

                                                <div className={classes.answerBox}>
                                                    <div className={classes.missedContainer} ref={missedRef}>MISSED!</div>
                                                    <div className={classes.answer} ref={leftRef}>InCORRECT!</div>
                                                    <div className={classes.answer} ref={rightRef}>CORRECT!</div>
                                                </div>

                                                {/* Random Car */}
                                                {!isGuideLottiePlaying && (

                                                    <div div className={`${classes.carContainer} ${isCarRotate ? (lastMovement === "LEFT" ? classes.carRotationLeft : classes.carRotationRight) : ""}`} ref={carImgRef}>

                                                        <img src={CAR_LIST[randomCar]} alt="car" draggable="false" className={`disableSelection disablePointerEvents ${classes.carImg}`} ref={carImgRef} />

                                                        <span className={classes.carNitro}
                                                            ref={isNitro}
                                                        >
                                                            <Lottie
                                                                animationData={CAR_NITRO}
                                                                loop={false}
                                                                play={true}
                                                            />
                                                        </span>


                                                        {
                                                            Array.from(Array(4), (e, i) => (
                                                                <span key={i}
                                                                    className={`${classes.carSmoke} ${classes[`carSmoke${i + 1}`]}`}
                                                                    ref={allCarsSmokeRef.current[i]}
                                                                >
                                                                    <Lottie
                                                                        animationData={CAR_SMOKE}
                                                                        loop={true}
                                                                        play={true}
                                                                    />
                                                                </span>
                                                            ))
                                                        }
                                                    </div>
                                                )}

                                                {/* False Container */}

                                                <div className={classes.optionContainerBox} ref={rightPartRoadRef} >
                                                </div>

                                                <div className={`${classes.optionContainer} ${classes.optionContainerRight}`} ref={rightSideTFRef} style={{
                                                    transform: 'translate(40%, -100px)'
                                                }}>
                                                    <div className={classes.optionWrapper} onClick={(e) => handleOptionClick(e, 'RIGHT')}>
                                                        <div className={classes.options}>
                                                            {t("False")}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className={classes.rightSideImg} ref={rightSideImgRef}>
                                                <div className={classes.imgBox} ref={rightRoadImgRef}>
                                                    <img src="/images/gba/left-side-road-long.png" alt='right-road' draggable='false'
                                                        className={`disableSelection disablePointerEvents ${classes.roadSideImg} ${classes.rightRoadSideImg}`} id="rightRoadImg" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={classes.speedBarBox} ref={speedBoxRef}>
                                            <div className={classes.speedDecrement} id='speedDecrement'>
                                                <img src="/images/icons/minus.png" alt="left_arrow" className={`disableSelection disablePointerEvents`} />

                                            </div>
                                            <div className={classes.speedBarRectBox} ref={speedBarRectBoxRef}>
                                                {
                                                    Array.from(Array(speedBarLength), (e, i) => (
                                                        <div key={i} style={{ background: i < speedBarLength / 2 ? speedBarGradient : 'auto' }} />
                                                    ))
                                                }
                                            </div>
                                            <div className={classes.speedIncrement} id='speedIncrement'>
                                                {/* <img src="/images/icons/right_arrow.png" alt="left_arrow" />
                                                <img src="/images/icons/right_arrow.png" alt="left_arrow" /> */}
                                                <img src="/images/icons/plus.png" alt="left_arrow" className={`disableSelection disablePointerEvents`} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {
                                    gameEndType === 'win' && (
                                        <GbaWinScreen
                                            maxScore={mxmScore}
                                            bg={""}
                                            obtainedScore={Math.ceil(totalScoreRef.current)} />
                                    )
                                }
                                {
                                    gameEndType === 'loose' && (
                                        <GbaGameOverScreen
                                            type={"gameover"}
                                            gameType={gameData?.gameType}
                                            gameData={gameData}
                                            incorrectAttempt={incorrectData}
                                            bg={""}
                                            // bgGradient={bgGradientLoss}
                                            restartGame={restartGame} />
                                    )
                                }
                            </>
                        )
                    )
                )}
        </div>
    )
}

export default CrazyCars