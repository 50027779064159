import React from "react";
import { useSelector } from "react-redux";

// mui

// components
import IlaMcqOne from "./mcq-1";
import IlaMcqTwo from "./mcq-2";
import IlaTf from "./tf-1-2";
import IlaFitbOne from "./fitb-1";
import IlaFitbTwo from "./fitb-2";
import IlaMtrrOne from "./mtrr-1";
import IlaMtrrTwo from "./mtrr-2";
import IlaSequenceOne from "./sequence-1";
import ComingSoonIlas from "./temporary-coming-soon-screen"

// redux
// import { getChallengeData } from "../../redux/microskill/microskill.api";

// utils
// import isEmpty from "../../utils/isEmpty";

// constants
// import constants from "../../constants";

// styles
import useStyles from "./index.styles";

let skipButtonDisplayTime = 2000;

const Ila = (props) => {
    // const { designType, Questions } = props.data;
    const {
        prev,
        next,
        attemptedIds,
        scoreListRef,
        maxScore,
        addScore,
        scoreValueRef,
        scoreRef,
        handleChallengeAttemptData,
        data: { designType, Questions, idMod, id },
        CorrectTemplate,
        IncorrectTemplate,
        SkipButton,
        viewFullScreen,
        isChallengeSubmitted
    } = props;
    // console.log("designType:", designType);
    const isMobile = useSelector(state => state?.common?.isMobile);
    const classes = useStyles({ viewFullScreen, isMobile });

    return (
        <div className={classes.ilaRootMain}>
            {
                (designType === 'ILA_MCQ_1') && (
                    <IlaMcqOne data={Questions}
                        prev={prev}
                        next={next}
                        attemptedIds={attemptedIds}
                        ilaId={idMod}
                        scoreListRef={scoreListRef}
                        maxScore={maxScore}
                        addScore={addScore}
                        scoreValueRef={scoreValueRef}
                        scoreRef={scoreRef}
                        handleChallengeAttemptData={handleChallengeAttemptData}
                        styles={classes}
                        CorrectTemplate={CorrectTemplate}
                        IncorrectTemplate={IncorrectTemplate}
                        SkipButton={SkipButton}
                        isChallengeSubmitted={isChallengeSubmitted}
                        skipButtonDisplayTime={skipButtonDisplayTime}
                        cardId={id}
                    />
                )
            }

            {
                (designType === 'ILA_MCQ_2') && (
                    <IlaMcqTwo data={Questions}
                        prev={prev}
                        next={next}
                        attemptedIds={attemptedIds}
                        ilaId={idMod}
                        scoreListRef={scoreListRef}
                        maxScore={maxScore}
                        addScore={addScore}
                        scoreValueRef={scoreValueRef}
                        scoreRef={scoreRef}
                        handleChallengeAttemptData={handleChallengeAttemptData}
                        styles={classes}
                        CorrectTemplate={CorrectTemplate}
                        IncorrectTemplate={IncorrectTemplate}
                        SkipButton={SkipButton}
                        isChallengeSubmitted={isChallengeSubmitted}
                        skipButtonDisplayTime={skipButtonDisplayTime}
                        viewFullScreen={viewFullScreen}
                        cardId={id}
                    />
                )
            }

            {
                (designType === 'ILA_FITB_1') && (
                    <>
                        <IlaFitbOne data={Questions}
                            prev={prev}
                            next={next}
                            attemptedIds={attemptedIds}
                            ilaId={idMod}
                            scoreListRef={scoreListRef}
                            maxScore={maxScore}
                            addScore={addScore}
                            scoreValueRef={scoreValueRef}
                            scoreRef={scoreRef}
                            handleChallengeAttemptData={handleChallengeAttemptData}
                            styles={classes}
                            CorrectTemplate={CorrectTemplate}
                            IncorrectTemplate={IncorrectTemplate}
                            SkipButton={SkipButton}
                            isChallengeSubmitted={isChallengeSubmitted}
                            skipButtonDisplayTime={skipButtonDisplayTime}
                            cardId={id}
                        />
                    </>
                )
            }

            {
                (designType === 'ILA_FITB_2') && (
                    <>
                        <IlaFitbTwo data={Questions}
                            prev={prev}
                            next={next}
                            attemptedIds={attemptedIds}
                            ilaId={idMod}
                            scoreListRef={scoreListRef}
                            maxScore={maxScore}
                            addScore={addScore}
                            scoreValueRef={scoreValueRef}
                            scoreRef={scoreRef}
                            handleChallengeAttemptData={handleChallengeAttemptData}
                            styles={classes}
                            CorrectTemplate={CorrectTemplate}
                            IncorrectTemplate={IncorrectTemplate}
                            SkipButton={SkipButton}
                            isChallengeSubmitted={isChallengeSubmitted}
                            skipButtonDisplayTime={skipButtonDisplayTime}
                            cardId={id}
                        />
                    </>
                )
            }

            {
                (designType === 'ILA_TF_1' || designType === 'ILA_TF_2') && (
                    <>
                        <IlaTf data={Questions}
                            prev={prev}
                            next={next}
                            attemptedIds={attemptedIds}
                            ilaId={idMod}
                            scoreListRef={scoreListRef}
                            type={designType}
                            maxScore={maxScore}
                            addScore={addScore}
                            scoreValueRef={scoreValueRef}
                            scoreRef={scoreRef}
                            handleChallengeAttemptData={handleChallengeAttemptData}
                            styles={classes}
                            CorrectTemplate={CorrectTemplate}
                            IncorrectTemplate={IncorrectTemplate}
                            SkipButton={SkipButton}
                            isChallengeSubmitted={isChallengeSubmitted}
                            skipButtonDisplayTime={skipButtonDisplayTime}
                            cardId={id}
                        />
                    </>
                )
            }
            {
                (designType === 'ILA_MTRR_1') && (
                    <>
                        {/* <IlaMcqThree */}
                        <IlaMtrrOne
                            data={Questions}
                            prev={prev}
                            next={next}
                            attemptedIds={attemptedIds}
                            ilaId={idMod}
                            scoreListRef={scoreListRef}
                            type={designType}
                            maxScore={maxScore}
                            addScore={addScore}
                            scoreValueRef={scoreValueRef}
                            scoreRef={scoreRef}
                            handleChallengeAttemptData={handleChallengeAttemptData}
                            styles={classes}
                            CorrectTemplate={CorrectTemplate}
                            IncorrectTemplate={IncorrectTemplate}
                            SkipButton={SkipButton}
                            isChallengeSubmitted={isChallengeSubmitted}
                            skipButtonDisplayTime={skipButtonDisplayTime}
                            cardId={id}
                        />
                    </>
                )
            }

            {
                (designType === 'ILA_MTRR_2') && (
                    <>
                        <IlaMtrrTwo
                            data={Questions}
                            prev={prev}
                            next={next}
                            attemptedIds={attemptedIds}
                            ilaId={idMod}
                            scoreListRef={scoreListRef}
                            type={designType}
                            maxScore={maxScore}
                            addScore={addScore}
                            scoreValueRef={scoreValueRef}
                            scoreRef={scoreRef}
                            handleChallengeAttemptData={handleChallengeAttemptData}
                            styles={classes}
                            CorrectTemplate={CorrectTemplate}
                            IncorrectTemplate={IncorrectTemplate}
                            SkipButton={SkipButton}
                            isChallengeSubmitted={isChallengeSubmitted}
                            skipButtonDisplayTime={skipButtonDisplayTime}
                            viewFullScreen={viewFullScreen}
                            cardId={id}
                        />
                    </>
                )
            }

            {
                (designType === 'ILA_SEQUENTIAL') && (
                    <>
                        <IlaSequenceOne
                            data={Questions}
                            prev={prev}
                            next={next}
                            attemptedIds={attemptedIds}
                            ilaId={idMod}
                            scoreListRef={scoreListRef}
                            type={designType}
                            maxScore={maxScore}
                            addScore={addScore}
                            scoreValueRef={scoreValueRef}
                            scoreRef={scoreRef}
                            handleChallengeAttemptData={handleChallengeAttemptData}
                            styles={classes}
                            CorrectTemplate={CorrectTemplate}
                            IncorrectTemplate={IncorrectTemplate}
                            SkipButton={SkipButton}
                            isChallengeSubmitted={isChallengeSubmitted}
                            skipButtonDisplayTime={skipButtonDisplayTime}
                            viewFullScreen={viewFullScreen}
                            cardId={id}
                        />
                    </>
                )
            }

            {
                designType !== 'ILA_MTRR_1' && designType !== 'ILA_MTRR_2' && designType !== 'ILA_FITB_2' && designType !== 'ILA_MCQ_1' && designType !== 'ILA_MCQ_2' && designType !== 'ILA_FITB_1' && designType !== 'ILA_TF_1' && designType !== 'ILA_TF_2' &&
                designType !== 'ILA_SEQUENTIAL' &&
                (
                    <ComingSoonIlas
                        data={Questions}
                        ilaType={designType}
                        next={next}
                        scoreListRef={scoreListRef}
                        addScore={addScore}
                    />
                )
            }
        </div>
    )
}

export default Ila;