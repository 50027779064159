
import { makeStyles } from "@mui/styles";
import commonUtil from "../../../../utils/commonUtil";

const zoom = commonUtil.browserZoom();

const useStyle = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    winScreenRoot: {
        position: 'relative',
        height: '100%',
        padding: '1rem',
        opacity: '0',
        animation: '$animateGameLandingPage 1s ease 0.1s 1 normal forwards running',
    },
    "@keyframes animateGameLandingPage": {
        "0%": { opacity: '0' },
        "100%": { opacity: '1' },
    },
    headerBox: {
        height: "11%",
        backgroundColor: "#a34735",
        display: "grid",
        gridTemplateColumns: "1fr 6fr",
        alignItems: "center",
        justifyItems: "left",
        position: "relative",
        zIndex: "2",
    },
    backBtn: {
        height: "24px",
        paddingLeft: "20px",
        cursor: "pointer"
    },
    winScreenContainer: {
        //backgroundImage: 'url(./assets/winScreen/check.png)',
        position: 'relative',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%',
        backgroundPosition: 'top center',
        display: 'grid',
        gridAutoRows: "55% 45%",
        [theme.breakpoints.down("sm")]: {
            gridAutoRows: "45% 55%",
        },
        animation: '$fadeIn 3s ease 0s 1 normal forwards running'
    },
    '@keyframes fadeIn': {
        '0%': { opacity: '0.5' },
        '100%': { opacity: '1' }
    },
    checkImgBox: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    checkImg: {
        transform: 'scale(0.5)',
        marginTop: '2rem',
        animation: '$zoomIn 1s ease 0s 1 normal forwards running'
    },
    '@keyframes zoomIn': {
        '0%': { transform: 'scale(0.5)' },
        '100%': { transform: 'scale(1.4)' }
    },
    section1: {
        height: "100%",
        transition: '1s',
        transform: 'translateY(100%)',
    },
    section1Animation: {
        backgroundImage: 'url(/images/gba/check_green.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'scale(0)',
        animation: '$bounce 0.6s ease 0s 1 normal forwards running'
    },
    '@keyframes bounce': {
        '0%': { transform: 'scale(0)' },
        '90%': { transform: 'scale(1)' },
        '100%': { transform: 'scale(0.9)' }
    },
    section1Container: {
        width: '100%',
        opacity: '0',
        animation: '1s ease',
        transform: 'translateY(100%)',
    },
    section1ContainerAnimation: {
        animation: '$revealFromBottom 0.8s ease 0.8s 1 normal forwards running'
    },
    '@keyframes revealFromBottom': {
        '0%': { transform: 'translateY(100%)', opacity: '0', },
        '100%': { transform: 'translateY(0)', opacity: '1', }
    },
    section2: {
        height: "100%",
        display: 'grid',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
    },
    starBox: {
        // height: "35%",
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        "& :nth-child(2)": {
            marginBottom: '1.2rem',
            width: '25%',
        },
        "& > :nth-child(1)": {
            width: '20%',
        },
        "& > :nth-child(3)": {
            width: '20%',
        }
    },

    star1: {
        transform: 'scale(0)',
        width: '20%',
        animation: "$rotateStars .8s ease 0s 1 normal forwards running"
    },
    star2: {
        transform: 'scale(0)',
        width: '25%',
        animation: "$rotateStars .8s ease 0s 1 normal forwards running"
    },
    star3: {
        transform: 'scale(0)',
        width: '20%',
        animation: "$rotateStars .8s ease 0s 1 normal forwards running"
    },
    star: {
        width: '25%',
    },
    '@keyframes rotateStars': {
        '0%': { transform: 'rotate(0deg) scale(0) ', },
        '100%': { transform: 'rotate(720deg) scale(1)', },
    },
    textBox: {
        display: 'grid',
        height: '65%',
        gridRowGap: "1rem",
        margin: '1rem 0rem'
    },
    challengeComplete: {
        color: 'white',
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
        fontSize: (i18n) => i18n.language === 'en' ? '2em' : '1.5em',
        fontFamily: "MotionControl",
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '2em',
        },
    },
    totalScoreTxt: {
        display: 'flex',
        color: 'white',
        alignItems: 'end',
        justifyContent: 'center',
        fontSize: (i18n) => i18n.language === 'en' ? '4em' : '2em',
        fontFamily: "MotionControl",
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: (i18n) => i18n.language === 'en' ? '4em' : '3em',
        },
    },
    totalScore: {
        color: 'white',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        fontSize: '6em',
        // fontSize: (i18n) => i18n.language === 'en' ? '5em' : '3em',
        fontFamily: "MotionControl",
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '4em',
        },
    },
    achivementText: {
        color: 'white',
        display: 'flex',
        alignItems: 'flex-end',
        alignItems: "center",
        padding: '0.3rem 1rem',
        justifyContent: 'space-between',
        opacity: '0',
    },
    text: {
        fontSize: '1.5em',
        fontFamily: "MotionControl",
        paddingBottom: '0.2rem',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.5rem',
        },
    },
    trophyBox: {
        textAlign: 'right',
        width: '15%',
    },
    trophyIcon: {
        width: '40%'
    },
    rectBox: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 1rem',
        justifyContent: 'space-between',
        width: '100%',
        opacity: '0'
    },
    rect1: {
        width: '64%',
        height: '7px',
        borderRadius: '5px',
        backgroundColor: '#eee',
        display: 'flex',
        '& > *': {
            height: '7px',

            backgroundColor: '#ffd042'
        }
    },
    rect2: {
        width: '35%',
        height: '7px',
        borderRadius: '5px',
        backgroundColor: '#eee',

    },
    rect1Child1: {
        borderRadius: '5px 0 0 5px',
        transition: '0.6s',
        width: '0%',

    },
    rect1Child2: {
        borderRadius: '0 5px 5px 0',
        transition: '0.6s',
        width: '0%',
    },
    rect2Child1: {
        transition: '1.2s',
        width: '0%',
        height: '7px',
        borderRadius: '5px',
        backgroundColor: '#ffd042'
    },
    compltTextBox: {
        transition: '.8s',
        transform: 'translateY(1000%)',
        // marginTop: '1rem',
        paddingTop: '1rem',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: '1rem',
        opacity: '0',
        [theme.breakpoints.down("sm")]: {
            paddingTop: '2rem',
            // marginTop: '2rem',
        }
    },
    compltTextBoxAnimation: {
        animation: '$animatecompltTextBox .8s ease 0s 1 normal forwards running'
    },
    '@keyframes animatecompltTextBox': {
        '0%': { transform: 'translateY(1000%)', opacity: '0' },
        '100%': { transform: 'translateY(0)', opacity: '1' },
    },
    compltText1: {
        color: 'white',
        fontSize: (i18n) => i18n.language === 'en' ? '2em' : '3em',
        fontFamily: 'montserrat',
        fontWeight: 900,
        [theme.breakpoints.down("sm")]: {
            // mobile
            // fontSize: '1.5em',
            fontSize: '2em !important',
        },
    },
    compltText2: {
        color: 'white',
        fontSize: (i18n) => i18n.language === 'en' ? '1.2em' : '1.2em',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.5em !important',
        },
    },
    challengeListBtnBox: {
        textAlign: 'center',
        // height: '50%',
        paddingTop: '1rem',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            // marginTop: (i18n) => i18n.language === 'en' ? "1.5em" : "0em",
        }
    },
    challengeListBtn: {
        padding: "0 !important",
        width: '90%',
        transform: 'scale(1) ',
        lineHeight: (i18n) => i18n.language === 'en' ? '1.2 !important' : '',
        [theme.breakpoints.down("sm")]: {
            // fontSize: "3em !important",
            padding: "0.8rem",
            // marginTop: '4em',
        }
    },
    // actionButton: {
    //     padding: "0 !important",
    //     width: '90%',
    //     transform: 'scale(1) ',
    //     lineHeight: (i18n) => i18n.language === 'en' ? '1.2 !important' : '',
    //     [theme.breakpoints.down("sm")]: {
    //         // fontSize: "3em !important",
    //         padding: "0.8rem",
    //         // marginTop: '4em',
    //     }
    // },

    bouncingAnimation: {
        animation: '$bounceAnimation 1s ease 1s 1 normal forwards running'
    },

    "@keyframes bounceAnimation": {
        "0%": { transform: 'scale(1)' },
        "20%": { transform: 'scale(0.9)' },
        "40%": { transform: 'scale(1)' },
        "60%": { transform: 'scale(0.9)' },
        "80%": { transform: 'scale(1)' },
        "100%": { transform: 'scale(1)' },
    },

    countUp: {
        fontFamily: "MotionControl"
    },
    gradientOverlay: {
        // backgroundImage: `linear-gradient(#4b514b, #04A404)`,
        opacity: '0',
        transition: '1s ease'
    },
    gradientOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        backgroundImage: 'linear-gradient(#182418, #04A404)',
        opacity: '0',
        transition: '1s ease',
    },
    gradientOverlayAnimation: {
        animation: '$gradientOverlayAnimation 1s ease 1s 1 normal forwards running',
    },
    '@keyframes gradientOverlayAnimation': {
        '0%': { opacity: '0' },
        '100%': { opacity: '1' },
    },

    // // audioPlayer
    audioPlayerContainer: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: 1
    },
    scoreContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        placeItems: 'center',
        fontFamily: 'MotionControl',
    },
    btnContainer: {
        padding: '1rem 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1rem',
        cursor: 'pointer',
        width: '100%',
        fontSize: 'medium',
    },
    nextChallengeBtnContainer: {
        width: "100%",
        margin: 'auto'
    },
    showMyCertificateBtn: {
        width: "100%",
        margin: 'auto'
    },
    actionButton: {
        fontSize: '2rem',
        fontWeight: 900,
        textTransform: 'capitalize !important',
        fontFamily: 'MotionControl',
        [theme.breakpoints.down("md")]: {
            fontSize: '1.6em',
            fontWeight: 700,
        }
    },


}));

export default useStyle;
