import { makeStyles } from "@mui/styles";
// import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    section1: {
        '& > *': {
            flex: '1 1 50%'
        }
    },
    section2Box: {
        marginTop: '4rem'
    },
    heading: {
        // marginTop: '2rem',
        fontWeight: "bold",
        // marginTop: "0px",
        fontSize: "18px",
        color: "white",
        marginLeft: "0.5rem",
        marginBottom: "1.2rem",
        width: "45%",
        height: "10px",
        backgroundImage:
            "linear-gradient(to right,rgba(96, 96, 96, 0) 0%,#ffffff 20%,rgba(96, 96, 96, 0) 40%,rgba(96, 96, 96, 0) 100%)",
        background: "lightgrey",
        borderRadius: "1px",
        backgroundSize: "800px 400px",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down("mdDown")]: {
            marginLeft: "0.5rem",
        },
    }
}))

export default useStyles