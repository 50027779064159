/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useStyle from "./index.styles";
import isEmpty from "../../../../../utils/isEmpty";

const GbaTypeTF = (props) => {
  const { gameData } = props;

  const [questionData, setQuestionData] = useState([]);

  const { t, i18n } = useTranslation();
  const classes = useStyle();


  useEffect(() => {

    const contextData = gameData?.contexts;
    const questions = gameData?.questions;

    if (isEmpty(questions) && isEmpty(contextData)) {
      return;
    }

    const mergedContextData = questions?.map((question, index) => {
      const questionContext = contextData.filter(context => question.contextId === context.id)[0]
      return {
        ...question, context: questionContext?.context
      }
    })

    setQuestionData(mergedContextData);

  }, [gameData]);

  return (
    <div className={`${classes.mcqContainer} scrollbar`}>

      {questionData?.map((item, index) => (

        <div key={index} className={classes.wrapper}>

          <div className={classes.contextContainer}>
            <h4 className={classes.contextText}>{item?.context}</h4>
          </div>

          <div className={classes.questionAnswerContainer}>
            <div className={classes.questionContainer}>
              <h4 className={classes.question}>
                Q{index + 1}. {item?.question}
              </h4>
            </div>

            <div className={classes.answerContainer}>
              {item?.answers.map((answer) => (
                answer?.isCorrect ? (
                  <div key={answer.id} className={classes.answer}>
                    <h4 className={classes.answerText}>
                      A{index + 1}. {answer.answer}</h4>
                  </div>
                ) : null
              ))}
            </div>
          </div>
        </div>
      ))}

    </div>
  );
};

export default GbaTypeTF;
