import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationBN from "../locale/bn/translation.json"
import translationEN from "../locale/en/translation.json"
import translationES from "../locale/es/translation.json"
import translationHI from "../locale/hi/translation.json"
import translationKN from "../locale/kn/translation.json"
import translationML from "../locale/ml/translation.json"
import translationMR from "../locale/mr/translation.json"
import translationTA from "../locale/ta/translation.json"
import translationTE from "../locale/te/translation.json"
import translationTR from "../locale/tr/translation.json"
import translationGU from "../locale/gu/translation.json"
import translationOR from "../locale/or/translation.json"
// import translationUR from "../locale/ur/translation.json"

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: { // English
                translation: translationEN
            },
            hi: { // Hindi
                translation: translationHI
            },
            mr: { // Marathi
                translation: translationMR
            },
            ta: { // Tamil
                translation: translationTA
            },
            te: { // Telugu
                translation: translationTE
            },
            ml: { // Malayalam
                translation: translationML
            },
            kn: { // Kannada
                translation: translationKN
            },
            bn: { // Bengali
                translation: translationBN
            },
            es: { // Spanish
                translation: translationES
            },
            tr: { // Turkish
                translation: translationTR
            },
            gu: { // Gujarati
                translation: translationGU
            },
            or: {
                translation: translationOR
            },
            // ur: {
            //     translation: translationUR
            // },

        },
        lng: "en",
        // debug: true,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n