import { jwtDecode } from "jwt-decode";

const createSharingUrl = (docPath) => {

    const windowOrigin = () => {
        let originUrl = window.location.origin;
        if (originUrl.includes("web-staging")) {
            return originUrl.replace("web-staging", "web");
        }
        return originUrl;
    }

    const reqToken = localStorage.getItem('accessToken');
    const tokenData = jwtDecode(reqToken)
    const { userId, companyId, tokenId } = tokenData

    let uid = userId.split("_").at(-1);
    let cid = companyId.split("_").at(-1);
    let tid = tokenId.split("_").at(-1);

    const createdUrl = `${windowOrigin()}/generic-doc-viewer?docUrl=${docPath}&du=${uid}&dc=${cid}&dt=${tid}`
    return createdUrl
}

export default createSharingUrl;
