import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

// components
import LOGO_OUTER_BREATH from "../../lottie-assets/loading-screen/logo-master-O-outer-breath.json"

// apis

// utils
import isEmpty from "../../utils/isEmpty"

// styles
import useStyles from "./index.styles"
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100vw',
    height: '100vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
};

const LoadingScreen = (props) => {
    const {
        isOpen = true,
        handleClose,
        loadingText = true,
    } = props;
    const classes = useStyles();
    const dotAnimeIntrvlRef = React.useRef(null)

    const { t } = useTranslation()
    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    // useEffect(() => {
    //     var dots = document.getElementsByClassName("dots");
    //     dotAnimeIntrvlRef.current = setInterval(async function () {
    //         for (var j = 0; j < 3; j++) {
    //             await sleep(500)
    //             dots[j].style.opacity = 1;
    //         }
    //         await sleep(300)
    //         for (var j = 0; j < 3; j++) {
    //             dots[j].style.opacity = 0;
    //         } 
    //     }, 2000)

    //     return () => {
    //         clearInterval(dotAnimeIntrvlRef.current)
    //     }
    // }, [])

    // useEffect(() => {
    //     if( !isOpen && !isEmpty(dotAnimeIntrvlRef.current) ) {
    //         clearInterval(dotAnimeIntrvlRef.current)
    //     }
    // }, [isOpen])

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            className={classes.loadingMicroskillRoot}
        >
            <Box sx={style} className={classes.modalBox}>
                <div className={classes.modalBoxContainer} style={{ gridTemplateRows: !loadingText ? '100%' : null }}>
                    <div className={classes.modalContainer}>
                        <div className={classes.loaderContainer}>
                            <span className={classes.logoOuter} >
                                <Lottie
                                    loop
                                    animationData={LOGO_OUTER_BREATH}
                                    play
                                />
                            </span>
                            {/* <img src="/images/common/master_o_logo_outer_1.svg" className={classes.logoOuter} /> */}
                            {/* <img src="/images/common/master_o_logo_inner_1.svg" className={classes.logoInner} /> */}
                        </div>
                    </div>
                    <div className={classes.centerFlex}>
                        <img src="/images/icons/mastero_text_dark.svg" className={classes.textBasedLogo} />
                    </div>
                    {
                        loadingText && (
                            <div className={classes.centerFlex}>
                                <div className={classes.loadingText}>
                                    {t("Loading")}&nbsp;
                                    <span className={`${classes.dot} dots`}>.</span>
                                    <span className={`${classes.dot} dots`}>.</span>
                                    <span className={`${classes.dot} dots`}>.</span>
                                </div>
                                {/* <div className={classes.subTextContainer}>
                                    <div className={classes.subText1}>{t("Taking too long")}?</div>
                                    <div className={classes.subText2}>{t("Tap here for support")}</div>
                                </div> */}
                            </div>
                        )
                    }
                </div>
            </Box>
        </Modal>
    )
}

export default LoadingScreen;