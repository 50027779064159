import { makeStyles } from "@mui/styles";
import constants from "../../constants";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    PageRoot: {
        backgroundColor: theme.palette.white,
        color: theme.palette.black,
        width: '100dvw',
        height: '100dvh',
    },
    headerBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '0px 20px',
        height: constants.HEADER_HEIGHT,
        backgroundColor: theme.palette.white,
        position: 'sticky',
        top: 0,
        zIndex: 100,
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
        [theme.breakpoints.down('sm')]: {
            height: constants.HEADER_HEIGHT_MOBILE,
        }
    },

    headerTitle: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: theme.palette.greyText4,
    },

    fileViewerWrapper: {
        width: '100%',
        height: `calc(100% - ${constants.HEADER_HEIGHT})`,
        padding: '1rem 0 1rem 1rem',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            height: `calc(100% - ${constants.HEADER_HEIGHT_MOBILE})`,
        }
    }
}))

export default useStyles