import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loginContainer: {
        backgroundColor: palette.bgThemeColor,
        color: palette.greyText4,
        height: '100vh',
        width: '100vw',
        maxHeight: '100vh',
        overflow: 'hidden',
        transition: 'height 0.5s ease-in-out',
        position: 'fixed'
    },
    loginBox: {
        height: '80%',
        width: '88%',
        flexWrap: 'wrap',
        borderRadius: '5px',
        boxShadow: '0 22px 16px 0 rgb(0 0 0 / 23%)',
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            width: '75%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    side1: {
        flex: '1 1 55%',
        backgroundImage: palette.linearGradientPrimary,
        display: 'grid',
        gridAutoRows: '60% 40%',
        height: '100%',
        padding: '2rem 1.8rem'
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        // "& :nth-child(1)": {
        //     width: '65%',
        //     height: '100%',
        //     objectFit: 'contain'
        // },
    },
    coverImage: {
        width: '50%',
        height: '100%',
        objectFit: 'contain'
    },
    loginContentBox: {
        color: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    welcomeText: {
        fontWeight: 'bold',
        fontSize: '1.5em',
        fontFamily: 'Montserrat',
    },
    divider: {
        width: '15%',
        height: '.4rem',
        backgroundColor: palette.orange,
        marginBottom: '12px',
    },
    logoText: {
        fontFamily: 'Montserrat',
        fontSize: '3.1em',
        marginTop: '0px',
        fontWeight: 'bold',
        // marginBottom: '12px',
    },
    mainTextDesc: {
        fontSize: '1.2em',
    },
    side2: {
        flex: '1 1 45%',
        backgroundColor: '#fff',
        height: '100%',
        padding: '2.5rem',
        display: 'grid'
    },
    signInMainText: {
        // fontFamily: 'Montserrat',
        fontSize: '2.5em',
        fontWeight: 'bold',
        // color: COLORS.textLight,
        // marginBottom: '1rem',
        color: palette.greyText1
    },
    signInDetails: {
        fontSize: '1em',
        // color: COLORS.textLight,
        lineHeight: '2em',
        color: palette.greyText1
    },
    form: {
        paddingBottom: '0.8em'
    },
    labelClass: {
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        fontSize: '0.9em',
        margin: '1em 0',
        display: 'block',
        color: palette.greyText1
    },
    muiBtn: {
        width: '100%',
        fontSize: '0.9em !important',
        fontWeight: 'bold !important',
        fontFamily: 'Montserrat !important',
        margin: '1rem 0 !important'
    },
    loadingBtn: {
        backgroundColor: '#F4511E',
        borderRadius: '5px',
        '& .MuiLoadingButton-loadingIndicator': {
            color: '#FFF'
        }
    },
    checkBox: {
        // color: COLORS.primaryOrange
    },
    checkBoxContainer: {
        // margin: '1rem 0'
    },
    rememberMeText: {
        visibility: 'hidden',
        fontSize: '0.75em',
        // color: COLORS.textLight,
        // padding: '1rem',
        color: palette.greyText1
    },
    iconBox: {
        width: '37%',
        display: 'grid'
    },
    circleLogoBox: {
        width: '100%',
        textAlign: 'end'
    },
    circleLogoImage: {
        width: '50%'
    },
    iconTextBox: {
        color: '#ffffff',
        display: 'grid',
        alignContent: 'center',
        justifyItems: 'center'
    },
    logoMaintext: {
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
    },
    logoSubText: {
        fontSize: '0.45em',
        fontFamily: 'Montserrat',
    },
    otpLoadingButton: {
        backgroundColor: '#fff !important',
        fontWeight: 'bold',
        padding: '4px',
        color: '#F4511E !important',
        border: '1px solid #F4511E !important',
        borderRadius: '0px',
        '&:hover': {
            backgroundColor: '#fff'
        }
    },
    mobileLoginContainer: {
        backgroundImage: 'linear-gradient(#4F0060, #4F0060)',
        // backgroundImage: 'linear-gradient(rgb(79, 0, 96), rgb(8, 0, 55))',
        height: '100%',
        width: '100vw',
    },
    mobileLoginBox: {
        backgroundImage: 'url(/images/login-screen/login_illustration_bg.svg)',
        height: '100%',
        width: '100vw',
        backgroundRepeat: 'repeat-y',
        backgroundSize: 'contain',
        display: 'grid',
        // gridTemplateRows: '17% 11% 72%',
        // gridTemplateRows: '25% 75%'
    },
    privacyPolicyWarning: {
        backgroundColor: 'red',
        color: palette.white,
        fontSize: '1em',
        borderRadius: '0.2rem',
        margin: `20px 60px`,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    mobileLogoContainer: {
        width: '70%',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            width: '100%'
        }
    },
    mobileLoginForm: {
        width: '90%',
        margin: 'auto',
        color: '#FFF',
        height: '100%',
        display: 'grid',
        gridAutoRows: '60% 40%'
    },
    loginFormBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.8rem !important',
    },
    mobileLoginInputField: {
        fontFamily: "Montserrat",
        // height: "52px",
        borderRadius: "5px",
        fontSize: "14px",
        boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.4)",
        background: `${palette.white}`,
        // background: `${palette.voilet}`,
        // color: 'rgba(255, 255, 255, 0.6)',
        // color: "white",
        fontWeight: "500",
        '& .MuiInputBase-root': {
            paddingLeft: "0"
        },
        '& .MuiOutlinedInput-input': {
            color: `${palette.greyText4} !important`,
            // color: "#FFF !important",
            letterSpacing: "0.1rem",
            // boxShadow: `transparent`,
            borderRadius: '0 !important',
            padding: '10px 14px 12px 0 !important',
            '-webkit-text-fill-color': `${palette.greyText4} !important`,
            // '-webkit-text-fill-color': '#FFF !important'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: "transparent !important",
        }
    },
    textBoxMobile: {
        padding: '1rem',
        display: 'grid',
        gridTemplateRows: '25% 25% 25% 25%',
        alignItems: 'center',
        textAlign: 'center'
    },
    text: {
        fontSize: '0.7em',
        cursor: 'pointer',
        textAlign: "center"
    },
    checkboxContainer: {
        display: 'flex',
        // gap: '1rem',
        alignItems: 'center'
    },
    rememberText: {
        fontSize: '0.8rem'
    },
    emailInput: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0,0,0,0.15)'
        }
    },
    textBoxDesktop: {
        fontSize: '0.8em',
        padding: '2.5rem 1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: palette.orange,
        cursor: 'pointer'
    },
    desktopPwdBox: {
        margin: '0.8rem 0'
    },
    aboutContainer: {
        display: "flex",
        flexDirection: "column",
        rowGap: "1.5rem",
        height: "100%",
        justifyContent: "center"
    },
    chatWithUs: {
        fontSize: "1.7em",
        textAlign: "center",
        fontWeight: "700"
    },
    loginIssuesText: {
        fontSize: "0.9em",
        fontWeight: "500",
        textAlign: "center",
        cursor: "pointer"
    },
    mobileOtpLoadingButton: {
        borderRadius: '0.1rem',
        padding: "8px 22px",
        color: "#FFF !important",
        fontSize: "1rem",
        border: "1px solid #F4511E !important",
        fontWeight: 'bold',
        backgroundColor: 'transparent !important',
        '& .MuiLoadingButton-loadingIndicator': {
            color: '#F4511E',
            backgroundColor: 'transparent',
        }
    }
}))

export default useStyles