import React from 'react'
import { useSelector } from 'react-redux';

//utils
import isEmpty from '../../utils/isEmpty';
import commonUtil from '../../utils/commonUtil';

// theme

// styles
import useStyle from "./index.styles";

export default function SplashScreen() {
    const userDetail = useSelector(state => state?.auth?.user);

    let companyId = commonUtil.getCompanyId();
    // const isIndiamart = companyId === process.env.REACT_APP_INDIAMART_COMPANY_ID;
    const isIndiabulls = companyId === process.env.REACT_APP_INDIABULLS_COMPANY_ID;
    const isBajajICV = companyId === process.env.REACT_APP_BAJAJ_ICV_COMPANY_ID;
    const isMahindraLMM = companyId === process.env.REACT_APP_MAHINDRA_LMM_COMPANY_ID;
    // const isHaleon = companyId === process.env.REACT_APP_HALEON_COMPANY_ID;
    const isIndigo = companyId === process.env.REACT_APP_INDIGO_COMPANY_ID;

    const classes = useStyle({ isBajajICV, isIndiabulls, isMahindraLMM, isIndigo });

    const { isDarkMode } = useSelector(state => state?.commonPersist);
    const logos = userDetail?.companyTheme?.logo;

    // console.log("splash screen userdetails:", userDetail);
    // console.log("theme:", theme.palette.mode);

    return (
        <div className={classes.splashScreenRoot}
            style={{
                backgroundImage: isIndigo ? `url(/images/common/master_o_login_illustration_bg.svg), linear-gradient(#001b94, #000)` : null,
            }}>
            <div className={classes.companyLogoBox}>
                {
                    !isEmpty(logos) && (
                        <img src={isDarkMode ? logos?.darkSplashScreenLogo : logos?.lightSplashScreenLogo}
                            alt="logo"
                            className={classes.companyLogo}
                            style={{
                                filter: isBajajICV ? "brightness(0) invert(1)" : "none"
                            }} />
                    )
                }

                {/* <img src={ !isEmpty(userDetail?.companyLogo) && (isIndigo ? userDetail?.companyTheme?.logo?.splashScreenLogo : userDetail.companyLogo)}
                    alt="logo" 
                    className={classes.companyLogo}
                    style={{
                        filter: isBajajICV ? "brightness(0) invert(1)" : "none"
                    }} /> */}
            </div>
            <div className={classes.detailBox}>
                <div className={classes.text1}>Powered By</div>
                <div className={classes.masteroLogoWrapper}>
                    <img src="./images/common/master_o_logo_loader.gif" alt="logo" className={classes.masteroLogo} style={{
                        filter: isBajajICV || isIndigo ? "brightness(0) invert(1)" : "none"
                    }} />
                    <img src="./images/icons/mastero_text_dark.svg" alt="logo" className={classes.masteroLogo} style={{
                        filter: isBajajICV || isIndigo ? "brightness(0) invert(1)" : "none"
                    }} />
                </div>
                <div className={classes.text2}>Loading Your Microskill</div>
            </div>
        </div>
    )
}
