import React, { useLayoutEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";


//mui
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

//components
import Performance from '../../components/performance'
import ManagerDashboard from '../../components/manager-dashboard';

//theme
import theme from '../../theme';

//styles
import useStyles from './index.styles';
import constants from '../../constants';

const TOGGLE_OPTIONS = {
    ME: 'ME',
    MY_TEAM: 'MY_TEAM'
}



const PerformanceAndManagerDashboard = () => {
    const classes = useStyles();
    const userType = localStorage.getItem('userType') ?? constants.USER_TYPES.USER;
    const isOnlyManager = userType === constants.USER_TYPES.MANAGER;
    let [searchParams] = useSearchParams();
    let nestedView = searchParams.get('manager');
    const defaultToggleValue = userType === constants.USER_TYPES.USER ? TOGGLE_OPTIONS.ME : TOGGLE_OPTIONS.MY_TEAM;
    const [toggleBtnValue, setToggleBtnValue] = useState(defaultToggleValue);//change to ME


    const getToggleBtnStyle = (name) => {
        return {
            color: '#ffffff',
            backgroundColor: toggleBtnValue === TOGGLE_OPTIONS[name] ? theme.palette.orange : 'transparent',
            border: toggleBtnValue === TOGGLE_OPTIONS[name] ? `2px solid ${theme.palette.orange}` : '2px solid transparent',
            borderRadius: '20px',//'5px 0px 0px 5px',
            fontWeight: 600,
            fontSize: '0.8rem',
            textTransform: 'math-auto',
            padding: '0 0.5rem',
            transition: 'all 0.3s ease-in-out 0s'
        }
    }

    const isHeaderContainerVisible = () => {
        if (nestedView || userType === constants.USER_TYPES.USER) return false;
        return true;
    }

    const isToggleBtnVisible = () => {
        if (isOnlyManager) return false;
        return true;
    }


    return (
        <div className={`${classes.pageContainer}`} >
            {
                isHeaderContainerVisible() ?
                    <div className={classes.headerContainer}>
                        <div className={classes.headerText}>Dashboard</div>
                        {
                            isToggleBtnVisible() ?
                                <ToggleButtonGroup
                                    value={toggleBtnValue}
                                    exclusive
                                    onChange={(e) => {
                                        const newValue = e.currentTarget.value;
                                        setToggleBtnValue(newValue);
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        right: '1rem',
                                        borderRadius: '20px', overflow: 'hidden', height: '2.2rem', gap: '0.3rem',
                                        backgroundColor: '#00000080',
                                        padding: '0.2rem'
                                    }}
                                >
                                    <ToggleButton value={TOGGLE_OPTIONS.ME} style={getToggleBtnStyle('ME')} >Me</ToggleButton>
                                    <ToggleButton value={TOGGLE_OPTIONS.MY_TEAM} style={getToggleBtnStyle('MY_TEAM')}>My team</ToggleButton>
                                </ToggleButtonGroup> : null
                        }

                    </div> : null
            }


            {toggleBtnValue === TOGGLE_OPTIONS.ME ? <Performance /> : null}
            {toggleBtnValue === TOGGLE_OPTIONS.MY_TEAM ? <ManagerDashboard userType={userType} isOnlyManager={isOnlyManager} /> : null}


        </div>
    )


}

export default PerformanceAndManagerDashboard