import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    productDetailContainer: {
        padding: '0 2rem',
        userSelect: 'none',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
    }
}))

export default useStyles