import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// utils
import isEmpty from '../../utils/isEmpty';

// styles
import useStyles from "./index.styles";

const LearningJourneyCard = ({ cardData }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const isMobile = useSelector(state => state?.common?.isMobile);
    let cardToShow = isMobile ? 2 : 4;
    const cardWidth = (200 / (3 * cardToShow - 1)).toFixed(2);
    const completionPercentage = cardData.completionPercentage;
    // const completionPercentage = Math.round((cardData.completedMicroskills / cardData.totalMicroskills) * 100);
    const numbers = Array.from({ length: cardToShow }, (_, index) => index + 1);
    const dividingNumber = isMobile ? 2.5 : 2;
    let gridColumn = '';
    numbers.forEach(number => {
        if (number === numbers.length) {
            gridColumn += `${cardWidth}%`;
        } else {
            gridColumn += `${cardWidth}% ${cardWidth / dividingNumber}% `;
        }
    });

    // console.log("card:", cardData);

    let backgroundGradient1 = 'linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0.05) 70%)';
    let backgroundGradient2 = 'linear-gradient(to top, rgb(0 0 0 / 80%), rgb(0 0 0 / 80%) 100%)';

    const handleLearningJourneyClick = () => {
        navigate(`/learning-journey/${cardData.journeyId}`)
    }

    const CircleCard = ({ data, notification, blur = false }) => {
        return (
            <div className={classes.circleMainContainer} onClick={handleLearningJourneyClick}>
                <div className={classes.circle}
                    style={{
                        backgroundImage: `${!blur ? backgroundGradient1 : backgroundGradient2}, url(${isEmpty(data?.Microskill?.OrgCoverImage) ? '/images/sample-image/master_o_brand_icon.png' : data?.Microskill?.OrgCoverImage})`,
                        filter: blur ? 'blur(4px)' : 'inherit'
                    }}
                >
                    {!isEmpty(notification) && !blur && (
                        <div className={`${classes.completion} ${classes.centerFlex}`}>{`${data?.Microskill?.completionPercentage}%`}</div>
                    )}
                    {!blur && (<div className={classes.journeyName} title={data?.Microskill?.name}>{data?.Microskill?.name}</div>)}
                </div>



                {blur && (
                    <div className={`${classes.microskillCount} ${classes.centerFlex}`}>
                        +{cardData?.microskills?.length - cardToShow + 1}
                    </div>
                )}
            </div>
        )
    }
    return (
        !isEmpty(cardData.microskills) ? (
            <div className={classes.cardContainer} style={{ fontSize: `calc(1.1rem - ${1 * (cardToShow / 10)}rem)` }}>
                <div className={classes.headerText}>{cardData.journeyName}</div>
                <div className={classes.cardBox} style={{ gridTemplateColumns: gridColumn }}>
                    <div className={classes.circleContainer}>
                        <CircleCard data={cardData.microskills[0]} notification={'0%'} />
                    </div>
                    {
                        isMobile ? (
                            <>
                                <div className={classes.horizontalDividerBox}>
                                    <div className={classes.horizontalDivider}></div>
                                </div>
                                <div className={`${classes.addOnCircle}`}>
                                    {
                                        cardData?.microskills?.length === cardToShow ? (
                                            <CircleCard data={cardData.microskills[1]} notification={"0%"} />
                                        ) : (
                                            <CircleCard data={cardData.microskills[1]} blur={true} notification={"0%"} />
                                        )
                                    }
                                </div>
                            </>
                        ) : (cardData.microskills.slice(1, cardToShow).map((item, index) => (
                            <React.Fragment key={item.id}>
                                <div className={classes.horizontalDividerBox}>
                                    <div className={classes.horizontalDivider}></div>
                                </div>
                                <div className={classes.circleContainer}>
                                    {
                                        cardData?.microskills?.length === cardToShow ? (
                                            <CircleCard data={item} notification={"0%"} />
                                            // <CircleCard data={cardData.microskills[1]} />
                                        ) : (
                                            // <CircleCard data={cardData.microskills[1]} blur={true} />
                                            <CircleCard data={item} blur={index === cardToShow - 2} notification={"0%"} />
                                        )
                                    }
                                </div>
                            </React.Fragment>
                        )))
                    }
                </div>
            </div>
        ) : (<></>)
    )
}

export default LearningJourneyCard