import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Msal Brower
import { useMsal } from "@azure/msal-react";


//  redux
import { signIn } from '../../redux/auth/auth.api';

//  components
import LoadingMicroskill from '../../components/loading-microskill';
// import { submitUserLoginActivity } from '../../redux/events/events.api';

// utils
import isEmpty from '../../utils/isEmpty';
import commonUtils from '../../utils/commonUtil';

const CompanyIntegartionMsal = () => {
    const { accounts, inProgress, instance } = useMsal();
    const navigate = useNavigate();
    const msalRequestToken = useSelector(state => state?.auth?.msalRequestToken);
    // const commontState = useSelector(state => state?.common);
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    // useEffect(() => {console.log(instance.getActiveAccount(), "instance getActiveAccount")}, [instance])
    useEffect(() => {
        console.log("msal account Details = ", { accounts, inProgress, msalRequestToken });
        async function func() {
            // console.log("loginUserName = ", getLoginUserName())
            // const device = window.isNative || commontState.isNativeApp ? commontState.deviceDetails?.os : 'WEB';
            // const details = {
            //     platformDetails: window.isNative || commontState.isNativeApp ? commontState.deviceDetails?.os : 'WEB',
            //     accounts: accounts
            // }


            const req = {
                username: accounts[0].username,
                password: "",
            }

            let tempReqToken

            if (isEmpty(msalRequestToken)) { // temporary solution for diageo login issue
                const tempSignIn = await signIn(req);
                if (tempSignIn.status === 200 || tempSignIn.status === 201) {
                    // console.log("Logged In Successfully")
                    tempReqToken = tempSignIn?.data?.data?.tokens?.requestToken; // asking again for request token if not available
                }
                else {
                    console.log("Error in Login", tempSignIn);
                    // await sleep(3 * 1000) // wait for 3 seconds then redirect to login
                    navigate('/login')
                    return
                }
            }

            // console.log("sign request payload from MSAL = ", req);

            req["requestToken"] = isEmpty(msalRequestToken) ? tempReqToken : msalRequestToken;

            const respData = await signIn(req);
            if (respData.status === 200 || respData.status === 201) {
                console.log("Logged In MSAL Successfully")
                navigate('/learn');
            }
            else {
                console.log("Error in MSAL Login", respData);
                // await sleep(3 * 1000) // wait for 3 seconds then redirect to login
                navigate('/login')
                // details = {
                //     platformDetails: window.isNative === undefined ? window.navigator.userAgentData : window.deviceDetails,
                //     errors: respData?.data?.errors
                // }
                // submitUserLoginActivity({ username: username, device: window.isNative === undefined ? window.navigator.userAgentData.platform : window.deviceDetails.os, screen: 'LOGIN_MSAL_MASTERO_AUTH', details });
            }
        }

        if (accounts.length > 0) {
            func();
        }
    }, [accounts, inProgress, msalRequestToken])

    return (
        <LoadingMicroskill />
    )
}

export default CompanyIntegartionMsal;