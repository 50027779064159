import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
    howToPlayRoot: {
        color: "#fff",
        boxSizing: "border-box",
        backgroundImage: "linear-gradient(#edd6a0, #e89d15)",
        position: "relative",
        transition: "0.7s",
        height: '100%'
    },
    headerBox: {
        height: "11%",
        display: "grid",
        gridTemplateColumns: "1fr 6fr",
        alignItems: "center",
        justifyItems: "left",
        position: "relative",
        zIndex: "2",
    },
    backBtn: {
        height: "24px",
        paddingLeft: "20px",
        cursor: "pointer"
    },
    container: {
        display: "grid",
        height: "100%",
        gridAutoRows: "27% 73%",
        overflow: 'hidden',
        touchAction: 'none',
        backgroundImage: "linear-gradient(#5B0F8E, #E65151)",
    },
    overlay: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        background: '#000',
        zIndex: '2',
        opacity: '.88'
    },
    dataContainer: {
        transition: '.8s',
        display: 'none',
        opacity: '0',
        height: '60%',
        marginTop: '40%',
    },
    startBtn: {
        width: '85%',
        fontFamily: 'MotionControl',
        fontSize: '2rem',
        backgroundColor: '#F5D03C',
        padding: '0.5rem 1rem',
        border: 'none',
        borderRadius: '5px',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '3rem',
            padding: '1rem',
        },
    },
    headerQstnBox: {
        position: 'relative',
        zIndex: '3',
    },
    progressBarContainer: {
        position: "relative",
        height: (isMobile) => {
            return isMobile ? "4px" : "9px";
        },
        backgroundColor: '#fff',
        zIndex: "2",
        width: (isMobile) => {
            return `calc(100% - ${isMobile ? '0px' : '6px'})`
        },
        borderRadius: (isMobile) => {
            return `${isMobile ? '0px' : '8px'}`
        },
        margin: (isMobile) => {
            return isMobile ? "auto" : "0.3rem auto";
        },
        overflow: 'hidden',
    },
    progressBar: {
        position: "absolute",
        top: "0",
        left: "0",
        height: (isMobile) => {
            return isMobile ? "4px" : "9px";
        },
        backgroundColor: "#ffd042",
        width: '0',
        transition: '.4s',
    },
    qstnContainer: {
        height: "53%",
        margin: "10px 20px 0 20px",
        position: 'relative',
    },
    qstnBox: {
        backgroundColor: "#000",
        borderRadius: "10px",
        height: "100%",
        fontSize: "16px",
        display: 'grid',
        gridAutoRows: '40% 60%',
        alignItems: 'flex-start',
        boxShadow: '0px 0px 8px -3px #eee',
        padding: '0.5rem'
    },
    qstn: {
        animation: "$blink 1s ease 0s infinite normal forwards running",
        fontFamily: 'Roboto, sans-serif',
        fontSize: '0.8rem'

    },
    "@keyframes blink": {
        "0%": { opacity: "0" },
        "30%": { opacity: "0" },
        "100%": { opacity: "1" }
    },

    cursorPointer: {
        cursor: 'pointer'
    },
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    lifeTime: {
        //opacity: '0',
        visibility: 'hidden',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 2rem',
        fontSize: '.8rem',
        animation: "$blink 1s ease 1s infinite normal forwards running"
    },
    icon: {
        margin: '0 0.3rem',
        [theme.breakpoints.down("sm")]: {
            // mobile
            width: '14px',
        },
    },
    progressText: {
        marginTop: '13vh',
        display: 'grid',
        justifyContent: 'center',
        fontSize: '.8rem',
        display: 'none'
    },
    arrow2Box: {
        textAlign: 'center',
    },
    arrowImg2: {
        width: '40%'
    },
    readyText: {
        cursor: 'inherit',
        fontFamily: "Montserrat",
        fontSize: '2rem',
        fontWeight: '900',
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '2.1rem',
        },
    },
    restartText: {
        cursor: 'pointer',
        fontFamily: "MotionControl",
        fontSize: '2rem',
    },
    assetsText: {
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.2rem',
        },
    },
    completionText: {
        fontWeight: 900,
        fontSize: '1rem',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.2rem',
        },
    },
    dropCircleContainer: {
        position: 'relative',
        "& div:last-child": {
            border: '3px dashed #ffffff',
            animation: 'auto',
        },
        "& > div": {
            position: 'absolute',
            top: '50%',
            left: '50%',
            height: '200px',
            width: '200px',
            border: '2px dashed #cdcdcd',
            borderRadius: '50%',
            transform: 'translate(-50%, -50%)',
            boxShadow: '0 0 60px -40px #000000',
            backgroundColor: '#fcf804',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: '.8s'
        },
    },
    bottomContainer: {
        display: 'grid',
        gridAutoRows: '80% 20%',
    },
    playBtn: {
        position: 'absolute',
        cursor: 'pointer',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',

        [theme.breakpoints.up("sm")]: {
            // mobile
            width: '9% !important',
        },
    },
    playBox: {
        position: 'absolute',
        left: '50%',
        top: (isMobile) => {
            return isMobile ? '16%' : "22%"
        },
        transform: 'translateX(-50%)',
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        opacity: '0'
    },
    playText: {
        fontSize: '1.5rem',
        fontFamily: "Montserrat",
        fontWeight: 'bold',
        [theme.breakpoints.up("sm")]: {
            fontSize: '0.8rem',
        },
    },
    arrow: {
        width: '40%',
        [theme.breakpoints.up("sm")]: {
            width: '35%',
        },
    },
    player: {

    },
    track: {
        position: 'relative',
        width: '90%',
        borderRadius: '40px',
        height: '6px',
        backgroundColor: '#595959',
        margin: 'auto'
    },
    thumb: {
        position: 'absolute',
        height: '15px',
        width: '15px',
        borderRadius: '50%',
        backgroundColor: '#fff',
        left: '0%',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    timer: {
        width: '90%',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1rem 0'
    },
    dragDropText: {
        display: 'none',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none !important',
        fontSize: '1.2rem',
        textAlign: 'center',
        width: '100px',
        color: "#000",
        textShadow: '0px 0px 3px #000000',
        [theme.breakpoints.up("sm")]: {
            fontSize: '0.8rem',
        },
    },
    optionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        opacity: '1',
        transition: '.8s',
        fontSize: '0.8rem',
        '& > *': {
            cursor: 'pointer',
            padding: '0.2rem 0.6rem',
            border: 'none',
            background: '#D6619F',
            margin: '0 1rem',
            color: '#fff',
            borderRadius: '5px',
            width: '40%',
            minHeight: '40px',
            fontSize: '14px'
        }
    },
}));

export default useStyle;
