import React, { useEffect, useState, useRef, useCallback } from 'react'

//mui
import MuiCheckbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";
import Menu from '@mui/material/Menu';
import { List, ListItemButton, ListItemText, CircularProgress, Typography, Box } from "@mui/material";
import { Close } from "@mui/icons-material";


//services
import { getMicroskillList } from '../../../../redux/manager-dashboard/manager-dashboard.api';


//styles
import useStyles from "./index.styles";
// import isEmpty from '../../../../utils/isEmpty';


//multilingual
// import { useTranslation } from "react-i18next";

//utils
import commonUtil from '../../../../utils/commonUtil';


const MicroskillFilter = ({
    microskills,
    selectedMicroskills = [],
    setSelectedMicroskills,
    isDarkMode,
    t,
    manager,
    msPagination,
    setLimit,
    bottomLoader
}) => {
    const styles = useStyles();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [list, setList] = useState([]);
    const [selected, setSelected] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const debounceSearch = useRef();
    const observer = useRef();
    const firstRender = useRef(true);
    const bottomLoaderRef = useRef(null);

    useEffect(() => {
        if (open) {
            setList(microskills);
            setSelected(selectedMicroskills);
        }
    }, [open])

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return
        }
        setList(microskills);
    }, [microskills])

    useEffect(() => {
        if (bottomLoader) {
            scrollToBottom()
        }
    }, [bottomLoader])

    const scrollToBottom = () => {
        if (bottomLoaderRef.current) {
            bottomLoaderRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        return
      }

    const lastElementRef = useCallback((node) => {
        if (bottomLoader) return;
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver((entries) => {
            // console.log('entries[0] = ', entries[0]);
            if (entries[0].isIntersecting) {
                let newLimit = msPagination.limit + 50;
                const temp = parseInt(msPagination.count) - msPagination.limit;
                if(newLimit > parseInt(msPagination.count) && temp < newLimit) newLimit = msPagination.limit + temp;
                if (!searchValue.length && newLimit <= parseInt(msPagination.count)) setLimit(newLimit)
            }
        });
        if (node) observer.current.observe(node);
    }, [bottomLoader]);

    const fetchMicroskills = async (searchText) => {
        const payload = {
            managerUsername: manager,
            searchText,
            limit: 50,
            startIndex: 0
        }
        const response = await getMicroskillList(payload);
        if (response && response.status === 200) {
            setList(response.data.data);
        }
    }

    const handleMicroskillSearch = (e) => {
        e.preventDefault();
        clearTimeout(debounceSearch.current);
        let value = e.target.value;
        setSearchValue(value);
        let delay = 1000;
        if (!value.length) delay = 0;
        debounceSearch.current = setTimeout(() => {
            fetchMicroskills(value);
            clearTimeout(debounceSearch.current);
        }, delay)
    }


    const selectMicroskill = (item) => {
        const id = item.id;
        if (selected.includes(id)) {
            const filtered = selected.filter(ele => ele !== id);
            setSelected(filtered)
        } else {
            setSelected([...selected, id]);
        }
    }


    const handleClose = () => {
        setOpen(false);
        if (searchValue.length) setSearchValue("");
    }

    const handleSave = () => {
        setOpen(false);
        setSelectedMicroskills(selected);
        if (searchValue.length) setSearchValue("");
    }

    // const handleReset = () => {}

    const selectAllMicroskils = () => {
        let isSelectAll = list.length === selected.length;

        if (isSelectAll) {
            setSelected([]);
            return
        }
        let all = microskills.map(ele => ele.id);
        setSelected(all);

    }

    const getBgColor = (theme) => {
        if (isDarkMode) return theme.palette.darkVoilet;
        return '#f6f6f6';
    }

    const getBtnText = () => {
        if (microskills.length && selectedMicroskills.length === microskills.length) return "All Microskills Selected";
        if (selectedMicroskills.length) return `${selectedMicroskills.length} Microskills Selected`;

        return "Microskill Filter"
    }

    return (
        <>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                size="small"
                // className={`${classes.btn}`}
                sx={{
                    minWidth: '10rem',
                    width: 'fit-content',
                    borderRadius: '2px',
                    boxShadow: 'none',
                    fontSize: '0.69rem',
                    fontWeight: 700,
                    padding: '0.4rem',
                    backgroundColor: (theme) => isDarkMode ? theme.palette.darkVoilet : theme.palette.darkVoilet,
                    color: (theme) => theme.palette.whiteToOrange,
                    border: '1px solid #fff',
                    borderColor: (theme) => theme.palette.whiteToOrange,
                    display: 'flex',
                    gap: '0.5rem',
                    '&:hover': {
                        backgroundColor: (theme) => isDarkMode ? theme.palette.darkVoilet : theme.palette.darkVoilet,
                        color: (theme) => theme.palette.whiteToOrange,
                        borderColor: (theme) => theme.palette.whiteToOrange,
                    }
                }}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setOpen(true);
                }}
            >
                {t(`${getBtnText()}`)}
                <img src='/images/icons/filter-icon.svg' alt='filter-icon' />
            </Button>

            {open && <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                sx={(theme) => ({
                    color: theme.palette.fontColor,
                    textAlign: 'left',
                    font: 'normal normal 800 1.2rem Montserrat',
                    letterSpacing: '1px',
                    opacity: '1',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '0px',
                    '& > .MuiBackdrop-root': {
                        backdropFilter: 'blur(4px)',
                    },
                    '& > .MuiPaper-root': {
                        backgroundColor: isDarkMode ? theme.palette.darkVoilet : '#f6f6f6',
                        left: '0 !important',
                        top: '2rem !important',
                        minWidth: '100vw !important',
                        maxHeight: 'unset',
                        '& > .MuiList-root': {
                            backgroundColor: isDarkMode ? theme.palette.darkVoilet : '#f6f6f6',
                            padding: '0.3rem 0 !important',
                        }
                    }
                })}
                open={open}
                onClose={handleClose}>
                <Typography
                    variant="body2"
                    sx={(theme) => ({
                        color: theme.palette.fontColor,
                        textAlign: 'left',
                        font: 'normal normal 800 1.2rem Montserrat',
                        letterSpacing: '1px',
                        opacity: '1',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0.7rem',
                        position: 'relative'
                    })}>
                    {t('Select Microskills')}
                    <Close sx={(theme => ({
                        color: theme.palette.orange,
                        position: 'absolute',
                        right: '1rem',
                        fontSize: '1.8rem'
                    }))}
                        onClick={handleClose} />
                </Typography>
                <Box className={styles.searchInputBox}>
                    <input placeholder="Search" type="text" value={searchValue} onChange={handleMicroskillSearch} />
                    <i className="fa-solid fa-magnifying-glass"></i>
                </Box>
                <List className={styles.scrollBar} sx={{ maxHeight: '64vh', overflow: 'auto', minHeight: '64vh' }}>
                    {
                        list.length && !searchValue.length ?
                            <ListItemButton
                                sx={theme => ({
                                    backgroundColor: getBgColor(theme),
                                    color: theme.palette.fontColor,
                                    padding: '0.3rem 1rem',
                                    marginBottom: 0,
                                    gap: '0.5rem',
                                    '&:hover': {
                                        backgroundColor: getBgColor(theme),
                                        color: theme.palette.fontColor
                                    }
                                })}
                                onClick={selectAllMicroskils}
                            >
                                <MuiCheckbox
                                    checked={list.length === selected.length}
                                    sx={(theme) => ({
                                        padding: '0.3rem',
                                        "& .MuiSvgIcon-root": {
                                            fontSize: "1.5rem",
                                            color: false ? theme.palette.checkBox.checked : theme.palette.checkBox.default,
                                            padding: "0",
                                            margin: "0"
                                        }
                                    })}
                                />
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Typography
                                            variant="body2"
                                            sx={(theme) => ({
                                                color: theme.palette.orange,
                                                textAlign: 'left',
                                                font: 'normal normal 700 0.9rem Montserrat',
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingLeft: '0px',
                                            })}>
                                            {t(`Select All`)}
                                        </Typography>
                                    }
                                />
                                {/* {expanded !== item.id ? <ExpandLess style={{ color: 'transparent' }} /> : <ExpandMore style={{ color: 'transparent' }} />} */}
                            </ListItemButton>
                            : null
                    }
                    {
                        list.length ?
                            list.map((item, i) => (
                                <React.Fragment key={i}>
                                    <ListItemButton
                                        ref={i == microskills.length - 1 ? lastElementRef : null}
                                        sx={theme => ({
                                            backgroundColor: getBgColor(theme),
                                            color: theme.palette.fontColor,
                                            padding: '0.3rem 1rem',
                                            marginBottom: 0,
                                            gap: '0.5rem',
                                            '&:hover': {
                                                backgroundColor: getBgColor(theme),
                                                color: theme.palette.fontColor
                                            }
                                        })}
                                        onClick={() => selectMicroskill(item)}
                                    >
                                        <MuiCheckbox
                                            checked={selected.includes(item.id)}
                                            sx={(theme) => ({
                                                padding: '0.3rem',
                                                "& .MuiSvgIcon-root": {
                                                    fontSize: "1.5rem",
                                                    color: selected.includes(item.id) ? theme.palette.checkBox.checked : theme.palette.checkBox.default,
                                                    padding: "0",
                                                    margin: "0"
                                                }
                                            })}
                                        />
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography
                                                    variant="body2"
                                                    sx={(theme) => ({
                                                        color: theme.palette.fontColor,
                                                        textAlign: 'left',
                                                        font: 'normal normal 700 0.9rem Montserrat',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        paddingLeft: '0px',
                                                    })}>
                                                    {t(`${item.name}`)}
                                                    {/* {t(`${commonUtil.truncateString(item.name)}`)} */}
                                                </Typography>
                                            }
                                        />
                                        {/* {expanded !== item.id ? <ExpandLess style={{ color: 'transparent' }} /> : <ExpandMore style={{ color: 'transparent' }} />} */}
                                    </ListItemButton>

                                </React.Fragment>
                            )) : <Typography
                                variant="body2"
                                sx={(theme) => ({
                                    color: theme.palette.fontColor,
                                    textAlign: 'center',
                                    font: 'normal normal 600 1rem Montserrat',
                                    letterSpacing: '0.05px',
                                    padding: '1rem'

                                })}>
                                No Microskills Available
                            </Typography>

                    }

                    {bottomLoader &&
                        <div ref={bottomLoaderRef} style={{ textAlign: 'center', paddingTop: '0.5rem' }}>
                            <CircularProgress sx={{ height: '25px !important', width: '25px !important' }} />
                        </div>
                    }


                </List>


                <div className={styles.btnBox}>
                    <Button variant='contained' onClick={handleSave}>
                        SAVE FILTERS
                    </Button>
                    {/* <Button onClick={handleReset}>
                        Reset
                    </Button> */}

                </div>
            </Menu>
            }
        </>
    )

}

export default MicroskillFilter;