
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({

    pauseOverlay: {
        // backgroundSize: 'contain',
        // backgroundSize: 'cover',
        fontFamily: 'MotionControl !important',
        position: 'absolute',
        zIndex: '3',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'inherit',
        width: '100%',
        left: '0',
        top: '0',
        opacity: '1'
    },
    btnContainer: {
        display: 'flex',
        gap: '1rem',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // transform: 'translateY(-2rem)'
    },
    btn: {
        fontFamily: 'MotionControl',
        width: '90%',
        border: 'none',
        color: '#fff',
        backgroundColor: 'transparent',
        padding: '0',
        borderRadius: '5px',
        fontSize: '2.5em !important',
        cursor: 'pointer',
        marginTop: '1.5rem',
        textTransform: 'uppercase',
        [theme.breakpoints.down("sm")]: {

            // mobile
            width: '200px',
            fontSize: '3em !important',
        },
    },
    resumeBtn: {
        fontFamily: 'MotionControl !important',
        fontSize: '2.5em !important',
        lineHeight: (i18n) => i18n.language === 'en' ? '1.2 !important' : '',
        width: '90% !important',
        background: '#F5D03C !important',
        color: '#000 !important',
        padding: '0 !important',
        margin: '1rem 0rem !important',
        [theme.breakpoints.down("sm")]: {
            fontSize: '3em !important'
        }
    }

}));

export default useStyle;
