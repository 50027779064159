import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// mui
import CircularProgress from '@mui/material/CircularProgress';

// components
import StartScreen from "../common-screens/start-screen";
import HowToPlay from "../how-to-play/mrr-how-to-play";
import GbaGameOverScreen from "../common-screens/game-over-screen";
import GbaWinScreen from "../common-screens/win-screen";

// utils
import commonUtil from "../../../utils/commonUtil";
import isEmpty from "../../../utils/isEmpty";

// redux
import { submitChallengeAttemptData, submitEndChallengeAttempt } from "../../../redux/gba/gba.api";
import { startChallengeAttempt } from "../../../redux/microskill/microskill.api";
import { handleGbaGameState } from "../../../redux/gba/gba-slice";

// constants
import constants from "../../../constants";

// theme
import palette from "../../../theme/palette";

// Style
import useStyle from "./index.styles";

const GBA_SCREEN_STATE = constants.GBA_SCREEN_STATE;

const IMG_TRASH_CAN_CORRECT = '/images/gba/trash_trivia_can_correct.png';
const IMG_TRASH_CAN_INCORRECT = '/images/gba/trash_trivia_can_incorrect.png';
const IMG_TRASH_CAN_CLOSED = '/images/gba/trash_trivia_can_closed.png';
const IMG_TRASH_CAN_OPEN = '/images/gba/trash_trivia_can_open.png';
const IMG_CRUMPLED_PAPER_BALL = '/images/gba/crumpled_paper_ball.png';
const IMG_PAPER = '/images/gba/wastebin_paper_success.png';
const IMG_PAPER_PLANE = '/images/gba/wastebin_paper_success.png';

const TrashTrivia = (props) => {
    const { gameData, handleGbaState, isPauseOverlay, defaultGradient, totalQuestions, setAddPause } = props;
    const { questionData, designType, gameType } = gameData;
    const [activeIndex, setActiveIndex] = useState(0);
    const isTrueFalse = questionData[activeIndex].options.length === 2;
    let topCardCount = isTrueFalse ? 2 : 3;
    let bottomCardCount = isTrueFalse ? 2 : 2;
    questionData[activeIndex].totalOptions = isTrueFalse ? questionData[activeIndex].totalOptions : questionData[activeIndex].totalOptions;

    const questionsLength = isEmpty(questionData[0]) ? 0 : questionData[0].length;
    const { microskillId, challengeId } = useParams();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const classes = useStyle(isMobile);
    const dispatch = useDispatch();
    const [isGamePage, setIsGamePage] = useState(false);
    const [isHowToPlayScreen, setIsHowToPlayScreen] = useState(false);

    const [isGameComplete, setIsGameComplete] = useState(false);
    const [showCard, setShowCard] = useState(false);
    const [gameEndType, setGameEndType] = useState('');
    const [attemptData, setAttemptData] = useState('');
    const [incorrectData, setIncorrectData] = useState();

    // states to mane start screen assts preloading
    const [startScreenGameLogoLoaded, setStartScreenGameLogoLoaded] = useState(false);
    const [startScreenGameBgLoaded, setStartScreenGameBgLoaded] = useState(false);

    const progressBarRef = useRef();
    const popcornMatchRootRef = useRef();
    const questionRef = useRef();
    const qstnBoxRef = useRef();
    const containerRef = useRef();
    const totalScoreRef = useRef(0);
    const isDragActive = useRef(true);
    const dragItemElementRef = useRef(null);
    const dragItemRef = useRef(null);
    const topCardRef = useRef(null);
    const attemptCountRef = useRef(0);
    const topCardArrayRef = useRef();
    const bottomCardArrayRef = useRef();
    const remainingTopCardArrayRef = useRef();
    const timeRefText = useRef();
    const lifeRef = useRef();

    const lifeRefImg = useRef();
    const intervalRef = useRef();
    const isGamePausedRef = useRef(false);
    const firstUpdate = useRef(true);

    const finalChallengeDataRef = useRef();
    const allAttempCountRef = useRef(0);
    const attemptDataRef = useRef();
    const gradientRef = useRef();
    const lifeBoxRef = useRef();
    const questionNoRef = useRef();
    const currentIndex = useRef(0);
    let maxLifeGba = commonUtil.getQuestionAndLifeForGba({ gbaType: designType }).maxLife;
    let maxQuestion = commonUtil.getQuestionAndLifeForGba({ gbaType: designType }).maxQuestion;
    let maxLife = commonUtil.getMaxLifeForGba({
        gbaType: designType,
        questionsInGba: totalQuestions,
        maxQuestion: maxQuestion,
        maxLife: maxLifeGba,
    });

    const maxTime = 180;
    const mxmScore = 120;
    let marksPerAnswer = mxmScore / totalQuestions;
    let bgGradientDefault = defaultGradient;
    let currentX;
    let currentY;
    let initialX;
    let initialY;
    let xOffset = 0;
    let yOffset = 0;
    let transitionTimeForCorrectAnswer = 500;
    let bufferTime = 300;
    const lifeRefText = useRef(maxLife);
    const prevAnsTimeRef = useRef(maxTime);

    const totalTimespentCompleteGBAIntervalRef = useRef();
    const totalTimespentCompleteGBA = useRef(0);

    useEffect(() => {
        if (isEmpty(attemptData)) getChallengeDataAsync();
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
            clearInterval(totalTimespentCompleteGBAIntervalRef.current);
        }
    }, []);

    useEffect(() => {
        if (isPauseOverlay) {
            isGamePausedRef.current = true;
            if (!firstUpdate.current) {
                handleBackButtonClick();
            }
        } else {
            if (firstUpdate.current) {
                firstUpdate.current = false;
                return
            }
            handleResumeClick();
        }
    }, [isPauseOverlay]);

    const handleBackButtonClick = () => {
        let timeSpent = totalTimespentCompleteGBA.current;
        dispatch(handleGbaGameState({
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: Math.round(totalScoreRef.current),
            timespent: timeSpent
        }))
        clearInterval(intervalRef.current);
        clearInterval(totalTimespentCompleteGBAIntervalRef.current);
    }

    const handleResumeClick = () => {
        setAddPause(true);
        startTimer();
    }

    useEffect(() => {
        if (isGamePage) {
            handleGbaState(GBA_SCREEN_STATE.GAME);
            startTimer();
        }
    }, [isGamePage]);

    useEffect(() => {
        (async () => {
            setShowCard(false);
            topCardArrayRef.current = commonUtil.getSlicedArray(topCardCount, 0, questionData[activeIndex].totalOptions);
            bottomCardArrayRef.current = commonUtil.getSlicedArray(bottomCardCount, 0, commonUtil.shuffleArray(topCardArrayRef.current));
            remainingTopCardArrayRef.current = commonUtil.getSlicedArray(questionData[activeIndex].totalOptions.length - topCardCount, topCardCount, questionData[activeIndex].totalOptions);
            attemptCountRef.current = 0;
            await sleep(100)
            setShowCard(true);

            // topCardArrayRef.current = commonUtil.getSlicedArray(topCardCount, 0, questionData[activeIndex].totalOptions);
            // bottomCardArrayRef.current = commonUtil.getSlicedArray(bottomCardCount, 0, commonUtil.shuffleArray(topCardArrayRef.current));
            // remainingTopCardArrayRef.current = commonUtil.getSlicedArray(questionData[activeIndex].totalOptions.length - topCardCount, topCardCount, questionData[activeIndex].totalOptions);
            // attemptCountRef.current = 0;
            // setShowCard(true);

            if (activeIndex > 0 && !isEmpty(questionRef.current)) questionRef.current.style.scale = '1';

            // let bottomCardOptions = isTrueFalse ? questionData[activeIndex].options : questionData[activeIndex].totalOptions;
            // topCardArrayRef.current = commonUtil.getSlicedArray(topCardCount, 0, questionData[activeIndex].totalOptions);
            // bottomCardArrayRef.current = commonUtil.getSlicedArray(bottomCardCount, 0, commonUtil.shuffleArray(bottomCardOptions));
            // remainingTopCardArrayRef.current = commonUtil.getSlicedArray(questionData[activeIndex].totalOptions.length - topCardCount, topCardCount, questionData[activeIndex].totalOptions);
            // attemptCountRef.current = 0;
            // setShowCard(true);
        })()
    }, [activeIndex, questionData]);

    const getChallengeDataAsync = async () => {
        const attemptResp = await startChallengeAttempt({ microskillId, challengeId });
        setAttemptData(attemptResp.data.data);
        attemptDataRef.current = attemptResp.data.data
    };

    useEffect(() => {
        if (isEmpty(attemptData)) return
        // set initial state
        let tempData = {
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: isEmpty(totalScoreRef.current) ? 0 : Math.ceil(totalScoreRef.current),
            status: constants.GBA_ILA_STATUS.exited,
        }
        dispatch(handleGbaGameState(tempData));
    }, [attemptData])

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    const startTimer = async () => {
        clearInterval(intervalRef.current);
        clearInterval(totalTimespentCompleteGBAIntervalRef.current);

        totalTimespentCompleteGBAIntervalRef.current = setInterval(() => {
            if (isEmpty(timeRefText.current)) {
                clearInterval(totalTimespentCompleteGBAIntervalRef.current);
                return
            }
            setAddPause(true);
            totalTimespentCompleteGBA.current += 1;
        }, 1000);

        intervalRef.current = setInterval(async () => {
            if (isEmpty(timeRefText.current)) {
                clearInterval(intervalRef.current);
                return
            }
            let time = parseInt(timeRefText.current.innerHTML) - 1;
            timeRefText.current.innerHTML = time;
            if (time === 0) {
                isDragActive.current = false;
                clearInterval(intervalRef.current);
                // setTimeout(() => {
                submitEndChallengeAttempt({
                    ...finalChallengeDataRef.current,
                    microskillId: attemptDataRef.current.microskillId,
                    attemptId: attemptDataRef.current.id,
                    challengeId: attemptDataRef.current.challengeId,
                    scored: Math.round(totalScoreRef.current),
                    completion: false,
                    status: "TIMERLOSE",
                    timespent: maxTime // if timer loss, it means user spends max time at gba
                });
                await sleep(2 * bufferTime);
                setShowCard(false);
                await sleep(bufferTime);
                setIsGameComplete(true);
                setGameEndType('timeup');
                handleGbaState(GBA_SCREEN_STATE.LOOSE);
                if (!isEmpty(gradientRef.current)) {
                    gradientRef.current.style.zIndex = -1;
                    gradientRef.current.classList.remove(classes.opacityAnimation);
                }
                // }, 1000);
                return
            }
        }, 1000);
    }

    const getIdFromElement = (el) => {
        return el.getAttribute('id').split('-')[1];
    }

    const dragStart = (e) => {
        if (parseInt(timeRefText.current.innerHTML) === 0 || e.target.getAttribute('drag') !== 'true' || !isDragActive.current) return;
        dragItemElementRef.current = e.target;
        // dragItemElementRef.current.style.scale = '0.8'
        if (e.type === "touchstart") {
            initialX = e.touches[0].clientX - xOffset;
            initialY = e.touches[0].clientY - yOffset;
        } else {
            initialX = e.clientX - xOffset;
            initialY = e.clientY - yOffset;
        }
    }

    const drag = (e) => {
        if (isEmpty(dragItemElementRef.current) || !isDragActive.current || parseInt(timeRefText.current.innerHTML) === 0) return;
        // e.preventDefault();
        if (e.type === "touchmove") {
            currentX = e.touches[0].clientX - initialX;
            currentY = e.touches[0].clientY - initialY;
        } else {
            currentX = e.clientX - initialX;
            currentY = e.clientY - initialY;
        }
        xOffset = currentX;
        yOffset = currentY;

        setTranslate(currentX, currentY, dragItemElementRef.current);
        let dragItemCardId = getIdFromElement(dragItemElementRef.current);
        let dragItemAnswerObj = bottomCardArrayRef.current.find(d => d.questionId === dragItemCardId);
        dragItemRef.current = { dragItemAnswerObj, dragItemEl: dragItemElementRef.current }

        // dragItemElementRef.current.querySelector(`#bottom-text-${dragItemCardId}`).style.opacity = 0;
        topCardArrayRef.current.forEach((item, i) => {
            let topCardEl = document.getElementById(`top-${item.questionId}-${i}`);
            if (isEmpty(dragItemElementRef.current) || isEmpty(topCardEl)) return
            if (isOverlapping(dragItemElementRef.current, topCardEl)) {
                let topCardId = getIdFromElement(topCardEl);
                let topCardObj = topCardArrayRef.current.find(d => d.questionId === topCardId);
                // topCardEl.style.scale = '1.2';
                topCardEl.style.backgroundImage = `url(${IMG_TRASH_CAN_OPEN})`;
                topCardRef.current = { topCardObj, topCardEl }
            } else {
                // topCardEl.style.scale = '1';
                topCardEl.style.backgroundImage = `url(${IMG_TRASH_CAN_CLOSED})`;
            }
        })
    }

    const setDrag = (time = 0) => {
        setTimeout(() => {
            isDragActive.current = true;
        }, 500);
    }

    const dragEnd = async (e) => {
        if (parseInt(timeRefText.current.innerHTML) === 0 || !isDragActive.current) return;
        isDragActive.current = false;
        xOffset = 0;
        yOffset = 0;

        // change the opacity to 1 for the dragged card
        let dragItemCardId = null;
        let dragItemChildEl = null;
        if (!isEmpty(dragItemElementRef.current)) {
            dragItemElementRef.current.style.scale = '1';
            dragItemCardId = dragItemElementRef.current.getAttribute('id').split('-')[1];
            dragItemChildEl = dragItemElementRef.current.innerHTML;
        }

        if (isEmpty(dragItemRef.current) || isEmpty(topCardRef.current)) {
            setTranslate(0, 0, dragItemElementRef.current, true);
            dragItemElementRef.current = null;
            isDragActive.current = true;
            return
        }
        // dragItemChildEl.style.opacity = 1;
        let dragElement = dragItemElementRef.current;
        let topCardElement = topCardRef.current.topCardEl;

        // CHECK FOR CORRECT ANSWER
        if (isOverlapping(dragElement, topCardElement)) {
            currentIndex.current += 1;

            let topCardId = getIdFromElement(topCardRef.current.topCardEl);
            let bottomCardId = getIdFromElement(dragItemElementRef.current);

            let topCardIndex = topCardArrayRef.current.findIndex(d => d.questionId === topCardId);
            let topCardSelectedData = topCardArrayRef.current.find(d => d.questionId === topCardId);
            let bottomCardIndex = bottomCardArrayRef.current.findIndex(d => d.questionId === bottomCardId);
            attemptCountRef.current = attemptCountRef.current + 1;
            allAttempCountRef.current = allAttempCountRef.current + 1;
            handleProgressBar();

            // hide dragged card
            dragItemElementRef.current.style.opacity = 0;

            let currentTime = parseInt(timeRefText.current.innerHTML);
            if (dragItemRef.current.dragItemAnswerObj.answer.trim().toUpperCase() === topCardRef.current.topCardObj.answer.trim().toUpperCase()) {
                //CORRECT
                // add to the score
                totalScoreRef.current += marksPerAnswer;

                // set analytics data
                handleChallengeAttemptData({
                    qId: topCardId,
                    aId: topCardRef.current.topCardObj.answerId,
                    isCorrect: true,
                    t: prevAnsTimeRef.current - currentTime,
                    skillId: topCardRef.current.topCardObj.skillId,
                    contextId: topCardRef.current.topCardObj.contextId,
                });
                prevAnsTimeRef.current = currentTime;

                // add correct trash can on top card
                topCardElement.style.backgroundImage = `url(${IMG_TRASH_CAN_CORRECT})`;

                // add transition to bottom card
                // dragElement.style.transition = transitionTimeForCorrectAnswer + 'ms';
                // dragElement.style.backgroundImage = `url(${IMG_PAPER_PLANE})`;
                // let bottomCardElementChildEl = dragElement.querySelector(`#bottom-text-${bottomCardId}`);
                // let bottomCardElementChildEl = dragElement.innerHTML;
                // bottomCardElementChildEl = 'CORRECT!';
                // dragElement.style.fontFamily = 'MotionControl';
                // dragElement.style.fontSize = '1.3rem';

                // wait for correct animation and translate to original position
                await sleep(transitionTimeForCorrectAnswer + bufferTime);
                setTranslate(0, 0, dragItemElementRef.current, true);

                // wait for buffer time
                await sleep(2 * bufferTime);

                // hide dragged card
                dragElement.style.opacity = 0;
                dragElement.style.backgroundImage = IMG_CRUMPLED_PAPER_BALL;

                // hide top card
                topCardElement.style.opacity = 0;
                topCardElement.style.backgroundImage = `url(${IMG_TRASH_CAN_CLOSED})`;

                // wait for buffer time and then show new cards
                await sleep(2 * bufferTime);

                // topCardElement.style.opacity = 1;
                // dragElement.style.opacity = 1;

                if (isTrueFalse) {

                    // update bottom card NEW
                    handleBottomCardUpdateArray(dragElement, bottomCardId, bottomCardIndex, true);
                    handleTopCardDomUpdate(topCardElement, topCardSelectedData, topCardId, topCardIndex)
                } else {
                    // update top card
                    handleTopCardUpdateArray(topCardIndex, topCardElement, topCardId, true);

                    // update bottom card
                    handleBottomCardUpdateArray(dragElement, bottomCardId, bottomCardIndex, true);
                }
            } else {
                gradientRef.current.classList.add(classes.opacityAnimation);
                gradientRef.current.style.zIndex = 0;
                // set analytics data
                handleChallengeAttemptData({
                    qId: topCardId,
                    aId: topCardRef.current.topCardObj.answerId,
                    isCorrect: false,
                    t: prevAnsTimeRef.current - currentTime,
                    skillId: topCardRef.current.topCardObj.skillId,
                    contextId: topCardRef.current.topCardObj.contextId,
                });
                prevAnsTimeRef.current = currentTime;

                // add incorrect trash can on top card
                topCardElement.style.backgroundImage = `url(${IMG_TRASH_CAN_INCORRECT})`;

                let life = parseInt(lifeRefText.current.innerHTML) - 1;
                if (life === 1) {
                    lifeBoxRef.current.classList.add(classes.mainText);
                }
                handleLifeLoss(life)
                // containerRef.current.style.background = incorrectBgGradient;

                // add transition to bottom card
                // dragElement.style.transition = transitionTimeForCorrectAnswer + 'ms';
                // let bottomCardElementChildEl = dragElement.querySelector(`#bottom-text-${bottomCardId}`);
                // bottomCardElementChildEl.innerHTML = 'WRONG!';
                // bottomCardElementChildEl.style.fontFamily = 'MotionControl';
                // bottomCardElementChildEl.style.fontSize = '1.3rem';

                if (life === 0) return // if life becomes zero stop further code execution

                // wait for incorrect animation and translate to original position
                await sleep(transitionTimeForCorrectAnswer + bufferTime);
                setTranslate(0, 0, dragItemElementRef.current, true);

                // wait for buffer time
                await sleep(2 * bufferTime);

                // hide dragged card
                dragElement.style.opacity = 0;
                containerRef.current.style.background = null;
                // dragElement.style.backgroundImage = IMG_CRUMPLED_PAPER_BALL;

                // hide top card
                topCardElement.style.opacity = 0;
                topCardElement.style.backgroundImage = `url(${IMG_TRASH_CAN_CLOSED})`;

                // wait for buffer time and then show new cards
                await sleep(2 * bufferTime);

                topCardElement.style.opacity = 1;
                dragElement.style.opacity = 1;

                if (isTrueFalse) {
                    // update bottom card NEW
                    handleBottomCardUpdateArray(dragElement, bottomCardId, bottomCardIndex, false);
                } else {
                    // update top card NEW
                    handleTopCardUpdateArray(topCardIndex, topCardElement, topCardId, false);

                    // update bottom card NEW
                    handleBottomCardUpdateArray(dragElement, bottomCardId, bottomCardIndex, false);
                }
                if (life !== 1) {
                    gradientRef.current.style.zIndex = -1;
                    gradientRef.current.classList.remove(classes.opacityAnimation);
                }
            }

            dragItemElementRef.current = null;
            // isDragActive.current = true;
            setDrag(100);
            if (attemptCountRef.current === 5) {
                questionRef.current.style.scale = '0'
                await sleep(2 * bufferTime);
                setShowCard(false);
                await sleep(bufferTime);

                // check if all questions are displayed
                let indexValue = activeIndex + 1;
                if (indexValue === questionData.length) {
                    submitEndChallengeAttempt({ ...finalChallengeDataRef.current, attemptId: attemptDataRef.current.id, microskillId: attemptDataRef.current.microskillId, challengeId: attemptDataRef.current.challengeId, scored: Math.ceil(totalScoreRef.current), completion: true, status: "COMPLETED", timespent: maxTime - parseInt(timeRefText.current.innerHTML) });
                    handleGbaState(GBA_SCREEN_STATE.WIN);
                    clearInterval(intervalRef.current);
                    setIsGameComplete(true);
                    setGameEndType('win');
                    return
                }
                setActiveIndex(indexValue);
            }
        } else {
            setTranslate(0, 0, dragItemElementRef.current, true);
            dragItemElementRef.current = null;
            setDrag(500);
            // isDragActive.current = true;
        }

        topCardArrayRef.current.forEach((item, i) => {
            let topCardEl = document.getElementById(`top-${item.questionId}-${i}`);
            if (isEmpty(topCardEl)) return
            topCardEl.style.scale = '1'
        });
    }

    const getNewCardDataFromRemianingArrayRef = (cardIndex, randomNoForTopCard, changeTopCard) => {
        let newCardData = null;
        newCardData = remainingTopCardArrayRef.current[randomNoForTopCard];
        newCardData.isViewed = false;
        if (changeTopCard) topCardArrayRef.current[cardIndex] = newCardData;
        return newCardData
    }

    const handleTopCardUpdateArray = (topCardIndex, topCardElement, topCardId, forCorrectAnswer) => {
        // update the flag
        topCardArrayRef.current[topCardIndex].isViewed = true;
        let newTopCardData = null;

        if (remainingTopCardArrayRef.current.length > 0) {
            // update top card if there is data in remaining array
            let randomNoForTopCard = commonUtil.randomNo(0, remainingTopCardArrayRef.current.length - 1);
            newTopCardData = getNewCardDataFromRemianingArrayRef(topCardIndex, randomNoForTopCard, true)

            // let randomNoForTopCard = commonUtil.randomNo(0, remainingTopCardArrayRef.current.length - 1);
            // newTopCardData = remainingTopCardArrayRef.current[randomNoForTopCard];
            // newTopCardData.isViewed = false;
            // topCardArrayRef.current[topCardIndex] = newTopCardData;
            handleTopCardDomUpdate(topCardElement, newTopCardData, topCardId, topCardIndex);

            // update the remainingTopCardArray
            if (forCorrectAnswer) remainingTopCardArrayRef.current.splice(randomNoForTopCard, 1);
            else remainingTopCardArrayRef.current[randomNoForTopCard] = topCardRef.current.topCardObj;
        } else {
            // if the remaining array is empty just throw random card data

            // check if bottom card has only card then it should have options above to match
            if (attemptCountRef.current === questionData[activeIndex].options.length - 1) {
                // return

                let isCardMatch = false;
                let bottomCardShowing = bottomCardArrayRef.current.filter(d => !d.isViewed);
                topCardArrayRef.current.filter(d => !d.isViewed).forEach((top) => {
                    let i = bottomCardShowing.findIndex(d => d.questionId === top.questionId);
                    if (i != -1) {
                        isCardMatch = true;
                    }
                });

                if (!isCardMatch) {
                    // show the top card that matches the last bottom card;
                    newTopCardData = bottomCardShowing[bottomCardShowing.length - 1];
                    newTopCardData.isViewed = false;
                    topCardArrayRef.current[topCardIndex] = newTopCardData;
                    handleTopCardDomUpdate(topCardElement, newTopCardData, topCardId, topCardIndex);
                    return
                }
            }

            let splicedTopCardArray = [...topCardArrayRef.current];
            splicedTopCardArray.splice(topCardIndex, 1)
            let excludedTopIndices = [];

            splicedTopCardArray.forEach((top) => {
                let i = questionData[activeIndex].options.findIndex(d => d.questionId === top.questionId);
                if (i != -1) {
                    excludedTopIndices.push(i);
                }
            });
            let randomNoForTopCard = commonUtil.randomNo(0, questionData[activeIndex].options.length - 1, excludedTopIndices);
            newTopCardData = questionData[activeIndex].options[randomNoForTopCard];
            newTopCardData.isViewed = false;
            topCardArrayRef.current[topCardIndex] = newTopCardData;
            handleTopCardDomUpdate(topCardElement, newTopCardData, topCardId, topCardIndex);
        }
    }

    const handleTopCardDomUpdate = async (topCardEl, newDataToUpdate, topCardId, topCardIndex) => {
        // topCardEl.style.backgroundImage = `url(${IMG_TRASH_CAN_CLOSED})`;
        await sleep(200);
        let topCardElementChildEl = topCardEl.querySelector(`#top-text-${topCardId}`);
        topCardElementChildEl.innerHTML = newDataToUpdate.answer;
        // topCardElementChildEl.innerHTML = newDataToUpdate.question;
        topCardEl.setAttribute('id', `top-${newDataToUpdate.questionId}-${topCardIndex}`);
        topCardElementChildEl.setAttribute('id', `top-text-${newDataToUpdate.questionId}`);
        topCardEl.style.scale = 1;
        topCardEl.style.opacity = 1;
    }

    const handleBottomCardUpdateArray = (dragElement, bottomCardId, bottomCardIndex, forCorrectAnswer) => {
        if (attemptCountRef.current <= questionData[activeIndex].totalOptions.length - bottomCardCount && !isTrueFalse) {
            // update the flag
            bottomCardArrayRef.current[bottomCardIndex].isViewed = true;

            let topCardArrayCopy = [...topCardArrayRef.current];
            let bottomCardArraySplicedCopy = [...bottomCardArrayRef.current];
            bottomCardArraySplicedCopy.splice(bottomCardIndex, 1);

            // find the index from top card which matches bottom card
            let excludedBottomIndices = [];
            topCardArrayCopy.forEach((top, index) => {
                let i = bottomCardArraySplicedCopy.findIndex(d => d.questionId === top.questionId);
                if (i != -1) {
                    excludedBottomIndices.push(index);
                }
            });
            let randomNoForBottomCard = commonUtil.randomNo(0, topCardCount - 1, excludedBottomIndices);
            let newBottomCardData = topCardArrayCopy[randomNoForBottomCard];
            newBottomCardData.isViewed = false;
            bottomCardArrayRef.current[bottomCardIndex] = newBottomCardData;
            handleBottomCardDomUpdate(dragElement, newBottomCardData, bottomCardId, bottomCardIndex);
        } else {
            if (remainingTopCardArrayRef.current.length > 0) {
                let randomNoForTopCard = commonUtil.randomNo(0, remainingTopCardArrayRef.current.length - 1);
                let newBottomCardData = getNewCardDataFromRemianingArrayRef(bottomCardIndex, randomNoForTopCard);
                newBottomCardData.isViewed = false;
                bottomCardArrayRef.current[bottomCardIndex] = newBottomCardData;
                handleBottomCardDomUpdate(dragElement, newBottomCardData, bottomCardId, bottomCardIndex);

                // update the remainingTopCardArray
                if (forCorrectAnswer) {
                    remainingTopCardArrayRef.current.splice(randomNoForTopCard, 1);
                }
                else {
                    remainingTopCardArrayRef.current.splice(randomNoForTopCard, 1);
                    // remainingTopCardArrayRef.current[randomNoForTopCard] = topCardRef.current.topCardObj;
                }
            }
        }
    }

    const handleBottomCardDomUpdate = async (dragElement, newBottomCardData, bottomCardId, bottomCardIndex) => {
        await sleep(200);
        dragElement.innerHTML = newBottomCardData.question;
        dragElement.setAttribute('id', `bottom-${newBottomCardData.questionId}-${bottomCardIndex}`);
        dragElement.style.opacity = 1;
    }

    const setTranslate = (xPos, yPos, el, isTransition = false) => {
        if (el === null) return
        el.style.transition = isTransition ? '0.4s' : '0s'
        el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
    }

    const isOverlapping = (e1, e2) => {
        if (isEmpty(e1) || isEmpty(e2)) return
        if (e1.length && e1.length > 1) {
            e1 = e1[0];
        }
        if (e2.length && e2.length > 1) {
            e2 = e2[0];
        }
        const rect1 = e1 instanceof Element ? e1.getBoundingClientRect() : false;
        const rect2 = e2 instanceof Element ? e2.getBoundingClientRect() : false;

        // Calculate the area of the rectangles
        const area1 = rect1.width * rect1.height;
        const area2 = rect2.width * rect2.height;

        // Calculate the overlapping rectangle
        const overlapLeft = Math.max(rect1.left, rect2.left);
        const overlapTop = Math.max(rect1.top, rect2.top);
        const overlapRight = Math.min(rect1.right, rect2.right);
        const overlapBottom = Math.min(rect1.bottom, rect2.bottom);

        // Calculate the area of the overlapping rectangle
        const overlapArea = Math.max(0, overlapRight - overlapLeft) * Math.max(0, overlapBottom - overlapTop);

        // Calculate the percentage of overlap
        const overlapPercentage = (overlapArea / Math.min(area1, area2)) * 100;

        // Check if overlap is at least 50%
        const isOverlap = overlapPercentage >= 50;

        // Return true if there is overlap, false otherwise
        return isOverlap;
    }

    // const isOverlapping = (e1, e2) => {
    //     if (isEmpty(e1) || isEmpty(e2)) return
    //     if (e1.length && e1.length > 1) {
    //         e1 = e1[0];
    //     }
    //     if (e2.length && e2.length > 1) {
    //         e2 = e2[0];
    //     }
    //     const rect1 = e1 instanceof Element ? e1.getBoundingClientRect() : false;
    //     const rect2 = e2 instanceof Element ? e2.getBoundingClientRect() : false;

    //     let overlap = false;
    //     if (rect1 && rect2) {
    //         let ratio = !isMobile ? 1.07 : 1.2;
    //         overlap = !(
    //             (rect1.right / ratio) < rect2.left ||
    //             rect1.left * ratio > rect2.right ||
    //             rect1.bottom / ratio < rect2.top ||
    //             rect1.top * ratio > rect2.bottom
    //         );
    //         return overlap;
    //     }
    //     console.warn('Please provide valid HTMLElement object');
    //     return overlap;
    // }

    const restartGame = async () => {
        isDragActive.current = true;
        attemptCountRef.current = 0;
        allAttempCountRef.current = 0;
        totalScoreRef.current = 0;
        dragItemElementRef.current = null;
        currentIndex.current = 0;
        prevAnsTimeRef.current = maxTime; // to avoid negative time sending in payload
        clearInterval(totalTimespentCompleteGBAIntervalRef.current);
        totalTimespentCompleteGBA.current = 0;
        getChallengeDataAsync()
        startTimer();
        setShowCard(true);
        setIsGameComplete(false);
        setActiveIndex(0);
        setIsGamePage(false);
        await sleep(10);
        setIsGamePage(true);

        clearInterval(intervalRef.current);
        attemptCountRef.current = 0;
        finalChallengeDataRef.current = null;
    }

    const handleChallengeAttemptData = (params) => {
        const { qId, aId, isCorrect, t, skillId, contextId } = params;

        // Create a new question object
        const questionObj = {
            questionId: qId,
            skillId: skillId,
            contextId: contextId,
            answers: [{
                answerId: aId,
                correct: isCorrect
            }],
            timespent: t
        };

        // Create the request object
        const req = {
            microskillId: microskillId,
            challengeId: challengeId,
            attemptId: attemptData.id,
            questions: [questionObj]
        };

        // Submit the challenge attempt data
        submitChallengeAttemptData(req);

        // Update the end challenge attempt data
        handleEndChallengeAttempt(questionObj, req, skillId, contextId);
    }

    const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
        const tempQuestionObj = {
            ...questionObj,
            skillId: skillId,
            contextId: contextId,
        };

        // Ensure current challenge data is initialized
        const currentChallengeData = finalChallengeDataRef.current || {};

        // Calculate total time spent
        const totalTimeSpent = (isEmpty(currentChallengeData) ? 0 : (currentChallengeData.timespent || 0)) + tempQuestionObj.timespent;

        if (isEmpty(currentChallengeData)) {
            // Initialize finalChallengeDataRef.current
            finalChallengeDataRef.current = {
                ...req,
                questions: [tempQuestionObj],
                completion: true,
                status: "COMPLETED",
                timespent: totalTimeSpent,
                scored: Math.round(totalScoreRef.current),
                fallbackSave: true,
            };
        } else {
            // Use a new array for questions to avoid mutation
            const updatedQuestions = [...(currentChallengeData.questions || []), tempQuestionObj];
            finalChallengeDataRef.current = {
                ...currentChallengeData,
                questions: updatedQuestions,
                timespent: totalTimeSpent,
            };
        }
    };

    // const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
    //     let tempQuestionObj = {
    //         ...questionObj,
    //         skillId: skillId,
    //         contextId: contextId,
    //     }
    //     let totalTimeSpent = (isEmpty(finalChallengeDataRef.current) ? 0 : finalChallengeDataRef.current.timespent) + tempQuestionObj.timespent;
    //     if (isEmpty(finalChallengeDataRef.current)) {
    //         finalChallengeDataRef.current = {
    //             ...req,
    //             questions: [tempQuestionObj],
    //             completion: true,
    //             status: "COMPLETED",
    //             timespent: totalTimeSpent,
    //             scored: Math.round(totalScoreRef.current),
    //             fallbackSave: true,
    //         }
    //     } else {
    //         finalChallengeDataRef?.current?.questions?.push(tempQuestionObj)
    //         finalChallengeDataRef.current = {
    //             ...finalChallengeDataRef.current,
    //             timespent: totalTimeSpent,
    //         }
    //     }
    // }




    const handleProgressBar = () => {
        let width = (parseInt(allAttempCountRef.current) / questionsLength) * 100;
        progressBarRef.current.style.width = `${width}%`;
        // if (questionsLength >= currentIndex.current) {
        //     questionNoRef.current.innerHTML = currentIndex.current;
        // }
    }

    const handleLifeLoss = async (life) => {
        if (life === 0) {
            const submitEndChallengeAttemptData = await submitEndChallengeAttempt({ ...finalChallengeDataRef.current, attemptId: attemptDataRef.current.id, microskillId: attemptDataRef.current.microskillId, challengeId: attemptDataRef.current.challengeId, scored: Math.round(totalScoreRef.current), completion: false, status: "LIFELOSE", timespent: maxTime - parseInt(timeRefText.current.innerHTML) });
            setIncorrectData(submitEndChallengeAttemptData?.data?.data?.consecutiveIncorrectAttempt);
            handleGbaState(GBA_SCREEN_STATE.LOOSE);
            clearInterval(intervalRef.current);
            await sleep(2 * bufferTime);
            setShowCard(false);
            await sleep(bufferTime);
            setIsGameComplete(true);
            setGameEndType('loose');
            if (!isEmpty(gradientRef.current)) {
                gradientRef.current.style.zIndex = -1;
                gradientRef.current.classList.remove(classes.opacityAnimation);
            }
            return
        }
        lifeRefText.current.innerHTML = life;
        if (life === 1) {
            lifeRef.current.style.color = palette.errorColor;
            lifeRefImg.current.src = "/images/icons/heart-red.png"
        }
    }

    const [fSize, setFSize] = useState(1);

    useEffect(() => {
        setFSize(window.innerHeight / 961);
    }, []);
    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }

    window.addEventListener("resize", resFont);

    return (
        <div style={(startScreenGameLogoLoaded && startScreenGameBgLoaded) ? { background: !isGamePage ? bgGradientDefault : bgGradientDefault, fontSize: `${fSize}rem` } : { backgroundImage: 'linear-gradient(#000, #000)' }}
            className={classes.popcornMatchRoot} ref={popcornMatchRootRef}>
            <div className={classes.gradientBox} ref={gradientRef} />
            {
                isEmpty(attemptData) ? (
                    <div style={constants.FULL_HEIGHT_CENTER}>
                        <CircularProgress />
                    </div>
                ) : (
                    !isGamePage ? (
                        !isHowToPlayScreen ? (
                            <div className={classes.startPage}>
                                <StartScreen
                                    setIsGamePage={setIsGamePage}
                                    setIsHowToPlayScreen={setIsHowToPlayScreen}
                                    gradientBg={bgGradientDefault}
                                    bg={"/images/gba/trash_trivia_overlay.png"}
                                    gameLogo="/images/gba/trash_trivia_title.png"
                                    handleGbaState={handleGbaState}

                                    startScreenGameLogoLoaded={startScreenGameLogoLoaded}
                                    setStartScreenGameLogoLoaded={setStartScreenGameLogoLoaded}
                                    startScreenGameBgLoaded={startScreenGameBgLoaded}
                                    setStartScreenGameBgLoaded={setStartScreenGameBgLoaded}
                                />

                            </div>
                        ) : (
                            <HowToPlay
                                // headerBg={headerBg}
                                qstnBoxBg={'#000000'}
                                answerImg={IMG_CRUMPLED_PAPER_BALL}
                                animationImg={IMG_PAPER_PLANE}
                                qstnImg={IMG_TRASH_CAN_CLOSED}
                                qstnImgAnimation={IMG_TRASH_CAN_CORRECT}
                                headerColor='#a34735'
                                setIsGamePage={setIsGamePage}
                                gameType='trashTrivia' />
                        )
                    ) : (
                        !isGameComplete ? (
                            <div className={classes.container} ref={containerRef}>
                                <div className={classes.progressBarContainer}>
                                    <div className={classes.progressBar} ref={progressBarRef} />
                                </div>
                                {/* QUESTION BOX */}
                                <div className={classes.qstnContainer}>
                                    <div className={classes.qstnBox} ref={qstnBoxRef}>
                                        <div className={classes.assetBox}>
                                            <div className={classes.lifeText} ref={lifeBoxRef}>
                                                <div className={classes.life} ref={lifeRef}>
                                                    <span ref={lifeRefText}>{maxLife}</span> x
                                                </div>
                                                <img src='/images/icons/heart.png' className={`disableSelection disablePointerEvents ${classes.icon}`} alt="heart" ref={lifeRefImg} />
                                            </div>
                                            <div className={classes.questionNo}>Q<span ref={questionNoRef}>{activeIndex + 1}</span>/{questionData.length}</div>
                                            <div className={classes.timerText}>
                                                <img src='/images/icons/stopwatch.png' className={`disableSelection disablePointerEvents ${classes.timerIcon}`} alt="timer" />
                                                <div className={classes.time}><span ref={timeRefText}>{maxTime}</span>s</div>
                                            </div>
                                        </div>
                                        <div className={classes.qstn} ref={questionRef}>{questionData[activeIndex].context}</div>
                                    </div>
                                </div>

                                <div className={classes.dragCardContainer} >
                                    {
                                        !isEmpty(topCardArrayRef.current) && !isEmpty(bottomCardArrayRef.current) && showCard && (
                                            <>
                                                <div className={classes.answerContainer}>
                                                    {
                                                        topCardArrayRef.current.map((item, i) => (
                                                            <div className={`disableSelection ${classes.answer} `} key={item.questionId} >
                                                                <div className={`${classes.topCard}`}
                                                                    id={`top-${item.questionId}-${i}`}
                                                                    drag={'false'}
                                                                    style={{ backgroundImage: `url(${IMG_TRASH_CAN_CLOSED})` }}>
                                                                    <div className={classes.answerText}
                                                                        id={`top-text-${item.questionId}`}
                                                                    >
                                                                        {item.answer}
                                                                        {/* {item.question} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>

                                                <div className={classes.answerContainer}>
                                                    {
                                                        bottomCardArrayRef.current.map((item, i) => (
                                                            <div className={`disableSelection ${classes.answer}`} key={item.questionId} >
                                                                <div className={`${classes.bottomCard}`}
                                                                    id={`bottom-${item.questionId}-${i}`}
                                                                    drag={'true'}
                                                                    onMouseDown={dragStart}
                                                                    onMouseMove={drag}
                                                                    onMouseUp={dragEnd}
                                                                    onTouchStart={dragStart}
                                                                    onTouchMove={drag}
                                                                    onTouchEnd={dragEnd}
                                                                    style={{
                                                                        backgroundImage: `url(${IMG_CRUMPLED_PAPER_BALL})`,
                                                                        fontSize: item.question.length > 32 ? (isMobile ? '' : '0.9 em') : null
                                                                    }}>
                                                                    {/* {item.answer} */}
                                                                    {item.question}
                                                                </div>

                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        ) : (
                            <>
                                {
                                    gameEndType === 'win' && (
                                        <GbaWinScreen
                                            maxScore={mxmScore}
                                            bg={""}
                                            obtainedScore={Math.round(totalScoreRef.current)} />
                                    )
                                }
                                {
                                    gameEndType === 'loose' && (
                                        <GbaGameOverScreen
                                            type={"gameover"}
                                            gameType={gameType}
                                            gameData={gameData}
                                            incorrectAttempt={incorrectData}
                                            bg={""}
                                            restartGame={restartGame}
                                        />
                                    )
                                }
                                {
                                    gameEndType === 'timeup' && (
                                        <GbaGameOverScreen
                                            type={"time"}
                                            bg={""}
                                            restartGame={restartGame}
                                        />
                                    )
                                }
                            </>
                        )

                    )
                )
            }
        </div>
    )
};

export default TrashTrivia;
