import React, { useEffect, useState } from "react";
// Style
import useStyle from "./index.styles";
import Button from '@mui/material/Button'
import { useTranslation } from "react-i18next";

const GbaPauseOverlay = ({ height, width, gradient, handleResumeClick, handleChallengeListClick }) => {
    const { t, i18n } = useTranslation()

    const classes = useStyle(i18n);
    const [styling, setStyling] = useState({
        width: `${width}px`,
        height: `${height}px`
    })
    useEffect(() => {
        if (gradient.includes('tasty-truth')) {
            setStyling(prev => ({
                ...prev,
                backgroundImage: gradient,
                backgroundSize: 'cover',
                backgroundColor: 'rgb(0,0,0)',
            }))
        } else {
            setStyling(prev => ({
                ...prev,
                backgroundImage: gradient
            }))
        }
    }, [gradient])

    return (
        <div className={classes.pauseOverlay}
            style={styling}>
            <div className={classes.btnContainer}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Button variant="contained" className={classes.resumeBtn}
                        onClick={handleResumeClick}>
                        {t("RESUME")}
                    </Button>
                    <button className={classes.btn} onClick={handleChallengeListClick}>{t("Exit")}</button>
                </div>
            </div>
        </div>
    )
}

export default GbaPauseOverlay;
