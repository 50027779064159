import React from "react";
import { useTranslation } from "react-i18next";
import useStyle from "./index.styles";

const GbaTypeMtrr = (props) => {
  const { gameData } = props;
  const { t, i18n } = useTranslation();
  const classes = useStyle();

  return (
    <div className={`${classes.mcqContainer} scrollbar`}>

      {gameData?.questionData?.map((item, index) => (

        <div key={index} className={classes.wrapper}>

          <div className={classes.contextContainer}>
            <h4 className={classes.contextText}>{item?.context}</h4>
          </div>

          {item?.options?.map((option, optionIndex) => (

            <div key={option.optionId} className={classes.optionContainer}>
             
              <div className={classes.questionContainer}>
                <h4 className={classes.question}>
                  Q{optionIndex + 1}. {option?.question}
                </h4>
              </div>
              
              <div className={classes.answerContainer}>
                <div key={option.answerId} className={classes.answer}>
                  <h4 className={classes.answerText}>
                    A{optionIndex + 1}. {option?.answer}
                  </h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default GbaTypeMtrr;
