import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    spotlightResultRoot: {
        // overflow: 'hidden',
        background: '#000',
        fontSize: '1em',
        [theme.breakpoints.up('sm')]: {
            position: 'relative',
        }
    },
    SpotlightResultBox: {
        aspectRatio: (isMobile) => {
            return isMobile ? 'auto' : '9/16'
        },
        margin: 'auto',
        padding: '1.2em',
        backgroundColor: palette.spotlightGreyBg,
        borderRadius: (isMobile) => {
            return isMobile ? 'inherit' : '8px'
        },
        overflow: 'auto',
        position: 'relative'

    },
    spotlightResult: {
        height: '100%',
        userSelect: 'none',
        position: 'relative',
    },
    SpotlightResultContainer: {
        height: 'inherit',
        position: 'relative'
    },
    videoBox: {
        // height: 'max-content',
        // maxHeight: '40%',
        height: '40% !important',
        width: 'max-content',
        margin: 'auto',
        maxWidth: '100%',
        '& video': {
            borderRadius: '8px',
        }
    },
    cardContainer: {
        display: 'grid',
        gap: '1em',
        padding: '1.5em 0'
    },
    card: {
        backgroundColor: '#FFF',
        borderRadius: '6px',
        color: palette.greyText1,
        padding: '1em',
        boxShadow: '0px 0px 20px -11px rgb(0 0 0 / 75%)',
        overflow: 'auto'
    },
    cardHeader: {
        // display: 'grid',
        // gridTemplateColumns: '25% 40% 35%',
        // alignItems: 'flex-start',

        display: 'flex',
        gap: '0.5em',
        justifyContent: 'space-between',
        marginBottom: '1em'
    },
    cardHeaderInner: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: '0.5rem'
    },
    cardName: {
        textTransform: 'uppercase',
        fontSize: '1em',
        fontWeight: 700,
        color: palette.greyText2,
        textAlign: 'right',
        width: 'min-content',
    },
    valueText: {
        fontWeight: 900,
        color: palette.orange,
        fontSize: '3em',
        lineHeight: '1.5rem',
        textTransform: 'uppercase'
    },
    valueSubText: {
        color: palette.orange,
        fontSize: '0.5em',
        textTransform: 'uppercase',
        fontWeight: 700,
    },
    valueBox: {
        display: 'flex',
        gap: '0.2em',
        alignItems: 'flex-start'
    },
    timeText: {
        fontWeight: 700,
    },
    valueString: {
        fontSize: '1.8em',
        lineHeight: '1'
    },
    cardDetailBox: {
        margin: '1em 0 0.5em 0'
    },
    labelDetailBox: {
        display: 'grid',
        gap: '0.2em',
        // gridTemplateColumns: '50% 50%',
    },
    labelBox: {
        justifyContent: 'flex-end',
        gap: '0.6em'
    },
    labelName: {
        // color: palette.greyText1,
        fontSize: '0.6em',
        fontWeight: 'bold'
    },
    labelColor: {
        width: '1em',
        height: '1em',
        borderRadius: '4px'
    },
    transcriptCircle: {
        borderRadius: '50%'
    },
    keywordTabContainer: {
        display: 'grid',
        gridTemplateColumns: '33.33% 33.33% 33.33%',
        overflow: 'hidden',
        '& > *': {
            padding: '0.2em',
        }
    },
    keywordTab: {
        borderRadius: '2px',
        backgroundColor: 'green',
        width: '100%',
        height: '100%',
        color: '#FFF',
        fontWeight: '600',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.6em',
        textAlign: 'center',
        fontSize: '0.8em',
        // wordBreak: 'break-all',
        [theme.breakpoints.up("sm")]: {
            // desktop
            fontSize: '0.7em',
        },
    },
    showMore: {
        fontWeight: 'bold',
        fontSize: '0.85em',
        textAlign: 'center',
        cursor: 'pointer',
        marginTop: '1em'
    },
    scoreBox: {
        display: 'grid',
        justifyItems: 'center',
        gap: '1em',
        paddingBottom: '4em',
        '& .dv-star-rating': {
            '& label': {
                padding: '0 0.2em'
            }
        }
    },
    scoreText: {
        color: palette.greyText2,
        fontSize: '0.9em',
        fontWeight: 700,
        letterSpacing: '0.3em'
    },
    formattedHtml: {
        fontSize: '1.2em',
        lineHeight: '1.5',
        padding: '0 0.5em'
    },



    // recommended videos kundan
    videoCardDetailBox: {
        margin: '1em 0 0.5em 0',
        display: 'flex',
        gap: '1rem'
    },
    cardHeaderVideos: {
        fontSize: '1.3em',
        fontWeight: 900
    },
    recommendedVideoBox: {
        overflow: 'hidden',
        width: '60%',
        position: 'relative',
        flex: '0 0 auto',
        cursor: 'pointer'
    },
    videoTag: {
        borderRadius: '0.6rem',
        width: '100%'
    },
    videoOverlay: {
        position: 'absolute',
        inset: '0 0 0 0',
        background: 'linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)',
        borderRadius: '0 0 0.6rem 0.6rem'
    },
    videoOverlayText: {
        position: 'absolute',
        bottom: '0',
        padding: '0.5rem'
    },
    learnerName: {
        // fontSize: '1rem',
        color: '#fff',
        fontWeight: '700'
    },


    durationBox: {
        height: '2em',
        marginBottom: '1em'
    },
    maxDuration: {
        height: 'inherit',
        backgroundColor: '#D0B3D6',
        borderRadius: '1em',
        position: 'relative'
    },
    recommendedDuration: {
        width: '65%',
        height: 'inherit',
        borderRadius: '1em',
        backgroundColor: palette.voilet3,
        // fontSize: '0.5em',
        // color: '#FFF',
        // fontWeight: 500,
    },
    actualDuration: {
        position: 'absolute',
        height: 'inherit',
        aspectRatio: '1/1',
        backgroundColor: palette.orange,
        borderRadius: '50%',
        top: '0',
        left: '75%',
        transform: 'translateX(-50%)'
        // transitionDuration: '1s',
        // transitionDelay: '1s'
    },
    yourDurationText: {
        position: 'absolute',
        fontSize: '0.65em',
        fontWeight: 700,
        bottom: '-60%',
        whiteSpace: 'nowrap',
        left: '50%',
        transform: 'translateX(-50%)',

    },
    recommendedDurationText: {
        height: 'inherit',
        position: 'absolute',
        fontSize: '0.75em',
        color: '#FFFFFF7A',
        fontWeight: 500,
        top: '0',
        padding: '0 0.8em'
    },
    // duration style

    graphContainer: {
        position: 'relative'
    },
    graphTextOverlay: {
        width: '35%',
        height: '1rem',
        background: '#FFF',
        position: 'absolute',
        bottom: '0',
        right: '0',
        zIndex: '99'
    },

    backBtnBox: {
        position: 'absolute',
        left: '2rem',
        top: '2rem',
        height: '2rem',
        border: '2px solid #FFF',
        aspectRatio: '1/1',
        borderRadius: '50%',
        cursor: 'pointer'
    },
    recommendedVideoScore: {
        color: '#fff',
        fontSize: '0.8em',
        // fontWeight: '700'
    },
    modalDiv: {
        aspectRatio: (isMobile) => {
            return isMobile ? 'auto' : '9/16'
        },
        margin: 'auto',
        position: 'fixed',
        zIndex: 10,
        inset: '0 0 0 0',
        background: '#00000096',
        backdropFilter: 'blur(6px)',
        padding: '2rem',
        top: '4rem',
        [theme.breakpoints.up('sm')]: {
            top: 0
        }
    },

    // additional css classes for transcript match box
    transcriptMatchCard: {
        padding: '0em',
    },
    transcriptMatchCardHeader: {
        padding: '1em'
    },
    transcriptLableDetailBox: {
        padding: '0 1em',
    },
    transcriptCardDetailBox:{
        padding: '1em',
        maxHeight: '37em',
        overflowY: 'auto'
    },
    lotieCard: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '20px 20px 49px #00000029',
        borderRadius: '12px',
        padding: '2rem',
        margin: '0',
    },
    scoreCard: {
        minHeight: '5em',
        padding: '1em',
        margin: '1em 0',
        
    },
    RolePlayTextWrapper: {
        paddingBottom: '1em',
    },
    RolePlayText: {
        color: theme.palette.orange,
        fontWeight: 900,
        fontSize: '2em',

    }
}))

export default useStyles