import React, { useContext } from "react";

// mui

// components
// import LearningAidCardsContainer from "../../components/learning-aid-cards-container";

// redux
// import { getChallengeData } from "../../redux/microskill/microskill.api";

// context
import LearningAidContext from "../../../context/learningAidContext";

// utils
// import isEmpty from "../../../utils/isEmpty";

// constants
// import constants from "../../../constants";

// theme
// import palette from "../../../theme/palette";

// styles
import useStyles from "./index.styles";
import { useTranslation } from "react-i18next";

const ScoreBox = (props) => {
    const { scoreValueRef, maxScore, styling={} } = props;
    const { scoreBoxRef } = useContext(LearningAidContext)
    const classes = useStyles();

    const { t } = useTranslation()
    return (
        <div className={classes.scoreBox} ref={scoreBoxRef} style={styling}>
            <>
                <span className={classes.scoreText}>{t("Score")}</span>
                <div className={classes.centerFlex}>
                    {
                        maxScore > 0 && (
                            <>
                                {/* <span className={classes.scoreValueText} ref={scoreValueRef}>{score ? score : 0}</span> */}
                                <span className={classes.scoreValueText} ref={scoreValueRef}></span>
                                {/* <span className={classes.scoreValueText} ref={scoreValueRef}>
                                    <CountUp
                                        // className={classes.countUp}
                                        delay={3.5}
                                        start={startScore}
                                        end={score}
                                        duration={scoreCounterTime / 1000}
                                    />
                                </span> */}
                                <span>{` /`} {maxScore}</span>
                            </>
                        )
                    }
                </div>
            </>
        </div>
    )
}

export default ScoreBox;