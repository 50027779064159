import React, { useEffect } from "react";
import { t } from "i18next";

// MUI
import { Button, CircularProgress } from "@mui/material";

// Utils
import isEmpty from "../../../utils/isEmpty";
import commonUtils from "../../../utils/commonUtil";

// Styles
import useStyles from "./index.styles";

// lotties
import timeupFormSubmissionLottieData from "../../../lottie-assets/timeout_forms.json";
import Lottie from "react-lottie-player";


const FormsAssesmentLooseScreen = (props) => {

    const { name, formData, attemptData, handleCloseForm, isChallenge, handleTryAgain, isTimeUp } = props;

    const [loading, setLoading] = React.useState(false);

    // let [contextWiseAccuracy, setContextWiseAccuracy] = React.useState({});
    // let totalAccuracy = useRef(0);
    // let totalScore = useRef(0);
    // let totalCorrectScore = useRef(0);

    const classes = useStyles();


    // useEffect(() => {
    //     // calculate context wise accuracy
    //     if (!isEmpty(formData) && !isEmpty(attemptData) && isEmpty(contextWiseAccuracy)) {

    //         let contextWiseAccuracy = {};
    //         formData.contexts.forEach(context => {

    //             let correctAnswerCount = 0;

    //             context.questions.forEach(question => {
    //                 let correctCount = question.answers.filter(answer => answer.isCorrect === true).length;
    //                 correctAnswerCount += correctCount;
    //             })


    //             let contextAttemptData = attemptData.filter(data => data.contextId === context.contextId);
    //             let correctAttemptedAnswerCount = 0;
    //             contextAttemptData.forEach(data => {
    //                 let countOfCorrectAns = data.answers.filter(answer => answer.isCorrect === true).length;
    //                 correctAttemptedAnswerCount += countOfCorrectAns;
    //             });

    //             totalScore.current += correctAttemptedAnswerCount;
    //             totalCorrectScore.current += correctAnswerCount;

    //             let accuracy = (correctAttemptedAnswerCount / correctAnswerCount) * 100;
    //             contextWiseAccuracy[context.contextId] = accuracy;
    //         });

    //         setContextWiseAccuracy(contextWiseAccuracy);

    //         totalAccuracy.current = (totalScore.current / totalCorrectScore.current) * 100;
    //     }

    //     if (!isEmpty(contextWiseAccuracy)) {
    //         setLoading(false);
    //     }

    // }, [formData, attemptData, contextWiseAccuracy]);

    useEffect(() => {
        if (!isEmpty(formData) && !isEmpty(attemptData)) {
            setLoading(false);
        }
    }, [formData, attemptData])
    return (
        <>
            {
                loading ?
                    (<div className={`${classes.centerFlex} ${classes.loadingForm}`}>
                        <CircularProgress />
                    </div>) :
                    (
                        <div className={classes.mainContainer}>
                            <div className={classes.infoContainer} style={{
                                width: () => {
                                    return isChallenge ? "95%" : "100%";
                                }
                            }}>
                                <div className={`${classes.closeIconContainer} ${classes.centerFlex}`}>
                                    {
                                        !isChallenge && (<img src='/images/icons/form-close-icon.svg' alt="form-close" className={classes.closeIcon} onClick={() => handleCloseForm()} />)
                                    }
                                </div>

                                <div className={classes.formName} style={{
                                    padding: () => {
                                        return isChallenge ? '0' : '1rem';
                                    }
                                }}>{name}</div>

                                <div className={`${classes.thanksContainer} ${classes.centerFlex}`} style={{
                                    padding: () => {
                                        return isChallenge ? '0' : '1rem';
                                    }
                                }}>
                                    {
                                        isTimeUp ? <div className={classes.centerFlex}>
                                            <Lottie
                                                animationData={timeupFormSubmissionLottieData}
                                                loop={false}
                                                play={true}
                                                style={{ width: '40%' }}
                                            />
                                        </div> : <div>
                                            <img src='/images/icons/form-cross.png' alt="form-close" className={classes.imgIcon} />
                                        </div>
                                    }
                                    <div className={classes.tryAgainText}>
                                        <div className={classes.greetingText}>{isTimeUp ? "TIME's UP" : "TRY AGAIN"}</div>
                                        <div className={classes.usernameText}>
                                            {commonUtils.getUserName() === null || commonUtils.getUserName() === undefined || commonUtils.getUserName() === '' ? commonUtils.getLoginUserName() : `${commonUtils.getUserName()} (${commonUtils.getLoginUserName()})`}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.msgText}>
                                    {t("You did not pass the assessment.")}
                                </div>
                            </div>


                            <div className={`${classes.container} ${classes.scoringContainer}`}>
                                {/* {showResult && (
                                    <div className={classes.sectionsWrapper}>
                                        {Object.keys(contextWiseAccuracy).map((contextId, index) => {
                                            return (
                                                <div key={index} className={classes.container}>
                                                    <div className={classes.sectionText}>Section {index + 1}:</div>
                                                    <div className={classes.sectionValue}>{contextWiseAccuracy[contextId]}%</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )} */}
                                {/* {showResult && (
                                    <div className={`${classes.centerFlex} ${classes.totalScoreContainer}`}>
                                        <div className={classes.scoreText}>{t("TOTAL SCORE")}:</div>
                                        <div className={classes.scoreValue}>{score} / {maxScore}</div>
                                    </div>
                                )} */}
                            </div>

                            {isChallenge && (
                                <div className={`${classes.centerFlex} ${classes.buttonContainer}`}>
                                    <Button
                                        variant="contained"
                                        className={classes.exitButton}
                                        onClick={() => handleTryAgain()}
                                    >Try Again</Button>
                                    <Button
                                        variant="contained"
                                        className={classes.exitButton}
                                        onClick={() => handleCloseForm()}
                                    >Challenge List</Button>
                                </div>)
                            }

                            {!isChallenge && (
                                <div className={`${classes.centerFlex} ${classes.buttonContainer}`}>
                                    <Button
                                        variant="contained"
                                        className={classes.exitButton}
                                        onClick={() => handleTryAgain()}
                                    >Try Again</Button>
                                    <Button
                                        variant="contained"
                                        className={classes.exitButton}
                                        onClick={() => handleCloseForm()}
                                    >Exit</Button>
                                </div>
                            )}
                        </div>
                    )
            }
        </>
    )
}

export default FormsAssesmentLooseScreen;