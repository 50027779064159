import axiosInstance from "../../utils/axios";


export const getUserProfile = async () => {
    try {
        const respData = await axiosInstance.get(`/access/user-profile`);
        // console.log("respData:", respData)
        return respData;
    } catch (err) {
        // console.log("getAllModulesAsync:", err);
        return err.response
        // dispatch(fetchFail(err));
    }
}

export const postUserLanguage = async (req) => {
    try {
        const respData = await axiosInstance.post(`/access/set-user-language`, req)
        return respData
    } catch(err) {
        return err.response
    }
}