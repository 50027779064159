import React from "react";

// mui
import { Button } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import useScrollTrigger from '@mui/material/useScrollTrigger';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import WestIcon from '@mui/icons-material/West';
// import ChallengeListCard from "../../components/challenge-list-card";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// components

// redux

// utils
import isEmpty from "../../../utils/isEmpty";

// constants

// theme

// styles
import useStyles from "./index.styles";
import { useTranslation } from "react-i18next";


const ComingSoonIlas = (props) => {
    const {
        data, ilaType, next, scoreListRef, addScore
    } = props;
    const classes = useStyles(props);

    const {t} = useTranslation()

    let imageName = !isEmpty(ilaType) ? `/images/learning-aid/${ilaType}.png` : `/images/learning-aid/ILA_TF_1.png`;

    const handleSkipClick = () => {
        console.log("data::", data);

        data.forEach(d => {
            scoreListRef.current.add(`${d.id}-${d.score}`);
        });
        console.log("scoreListRef.current:", scoreListRef.current);
        addScore();
        next();
    }

    return (
        <div className={`${classes.centerFlex} ${classes.comingSoonBox}`}
            style={{ backgroundImage: `url(${imageName})` }}
        >
            <div className={classes.overlay} />
            <div className={classes.text}>{t("This interaction is coming soon")}</div>
            <div className={classes.skipButtonBox} onClick={handleSkipClick}>
                <Button variant="outlined" className={classes.skipBtn}>Skip <ChevronRightIcon /></Button>
            </div>
        </div>
    )
}

export default ComingSoonIlas;