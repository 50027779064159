import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";
export const HEADER2_HEIGHT_MOBILE = '4rem'

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    fileViewerRoot: {
        // marginTop: '1rem',
        height: '100%',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0rem'
        },

    },
    pdfViewer: {
        padding: '1rem'
    },
    iframe: {
        // width: '100% !important',
        // minHeight: '100vh'
    },
    videoBox: {
        width: 'inherit',
        height: '100%',
    },
    documentStyle: {
        // display: 'flex',
        width: '100%',
        // alignItems: 'center',
        // justifyContent: 'center',
    },

    downloadBox: {
        position: 'fixed',
        // position: 'absolute',
        zIndex: '9999',
        right: '1.5rem',
        width: '2.2rem',
        height: '2.2rem',
        backgroundColor: '#fff',
        padding: '0.2rem',
        borderRadius: '50%',
        boxShadow: '0px 0px 10px 0px #00000057',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            right: '1rem',
        },
        '& span': {
            cursor: 'pointer',
        },
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'contain'
        }
    },
}))

export default useStyles