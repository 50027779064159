const PRODUCT_DETAILS_NAVIGATION_STATUS = [
  "Summary",
  "Comparison",
  "Documents",
  "FAQs"
]

const PRODUCT_LIST = [
  {
    'id': 1,
    'name': 'TREO Plus',
    'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/cover-treo-plus.png',
    'comments': 32,
    'documentsLinked': 6,
    'launchType': 'launch',
  },
  {
    'id': 2,
    'name': 'Zor GRAND',
    'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/zor-grand-cover.jpg',
    'comments': 0,
    'documentsLinked': 3,
    'launchType': 'launch',
  },

]

const TREO_PLUS_DEF_FAQS = [
  {
    "id": 1,
    "question": {
      "en": `<p>Q1. What is the driving range of Mahindra Treo Plus on a single charge?</p>`,
      "hi": `<p>Q1. एक बार चार्ज हो जाने बाद महिंद्रा ट्रियो प्लस की ड्राइविंग रेंज कितनी है?</p>`
    },
    "answer": {
      "en": `<p>The Mahindra Treo Plus offers a <b>driving range of 150 Kms on a full charge</b>, making it suitable for various urban and commercial application with ample coverage for daily use.</p>`,
      "hi": `<p>एक बार फुल चार्ज हो जाने के बाद महिंद्रा ट्रियो प्लस <b>150 किलोमीटर की ड्राइविंग रेंज</b> प्रदान करता है, जो दैनिक उपयोग के लिए पर्याप्त कवरेज के साथ विभिन्न शहरी और वाणिज्यिक अनुप्रयोगों के लिए इसे उपयुक्त बनाता है।</p>`
    }
  },
  {
    "id": 2,
    "question": {
      "en": `<p>Q2. How long does it take to charge the Mahindra Treo Plus Li-ion battery?</p>`,
      "hi": `<p>Q2. महिंद्रा ट्रियो प्लस ली-आयन बैटरी को चार्ज होने में कितना समय लगता है?</p>`
    },
    "answer": {
      "en": `<p>Mahindra Treo Plus has a relatively fast <b>charging time of 4 hours and 20 minutes</b>, ensuring quick turnarounds and minimal downtime during daily operations.</p>`,
      "hi": `<p>महिंद्रा ट्रियो प्लस में <b>4 घंटे और 20 मिनट का अपेक्षाकृत तेज़ चार्जिंग समय है</b>, जो दैनिक संचालन के दौरान त्वरित टर्नअराउंड और न्यूनतम डाउनटाइम सुनिश्चित करता है।</p>`
    }
  },
  {
    "id": 3,
    "question": {
      "en": `<p>Q3. What is the warranty period for Mahindra Treo Plus?</p>`,
      "hi": `<p>Q3. महिंद्रा ट्रियो प्लस के लिए वारंटी अवधि क्या है?</p>`
    },
    "answer": {
      "en": `<p>The Mahindra Treo Plus comes with a warranty of <b>5 Years or 1.2 Lakh Kms</b>.
            <br />
            <br />
            (Std Warranty: 3 Years/80,000 Kms,
            <br />
            Extended Warranty: 2 Years/40,000 Kms at a cost of Rs 9,289+GST))
            <br />
            This provides a sense of relief and assures the customer of the vehicle’s reliability and durability.</p>`,
      "hi": `<p>महिंद्रा ट्रियो प्लस की  वारंटी <b>5 वर्ष या 1,20,000 किलोमीटर है<b>।
            <br />
            <br />
            (स्टैण्डर्ड वारंटी: 3 वर्ष/80,000 किलोमीटर,
            <br />
            विस्तारित (एक्सटेंडेड) वारंटी: 9,289 रुपये+जीएसटी ली लगत पर 2 वर्ष/40,000 किमी)
            <br />
            इससे ग्राहकों को राहत मिलती है और ग्राहक को वाहन की विश्वसनीयता और टिकाऊपन का आश्वासन मिलता है।</p>`
    }
  },
  {
    "id": 4,
    "question": {
      "en": `<p>Q4. Does Treo Plus offer any safety features while driving on flyovers/hills?</p>`,
      "hi": `<p>Q4. क्या ट्रियो प्लस को फ्लाईओवर/हिल्स पर चलाते समय कोई सुरक्षा संबंधी सुविधाएँ प्रदान करता है?</p>`
    },
    "answer": {
      "en": `<p>Yes, the Treo Plus is equipped with <b>“Hill Hold Assist”</b> that provides an additional safety feature to prevent the <b>vehicle from rolling backward on inclines for up to 8 seconds</b>. This enhances safety during stops and starts on hills/flyovers.</p>`,
      "hi": `<p>हाँ, ट्रियो प्लस में <b>"हिल होल्ड असिस्ट"</b> लगा हुआ होता है जो वाहन को 8 सेकंड तक ढलान पर पीछे की ओर लुढ़कने से रोकने के लिए एक अतिरिक्त सुरक्षा की सुविधा प्रदान करता है, इससे पहाड़ियों/फ्लाईओवर पर रुकने और स्टार्ट होने के दौरान इसकी सुरक्षा बढ़ जाती है।</p>`
    }
  },
  {
    "id": 5,
    "question": {
      "en": `<p>Q5. How does Treo Plus perform in hilly terrains?</p>`,
      "hi": `<p>Q5. पहाड़ी इलाकों में ट्रियो प्लस कैसा परफॉरमेंस करता है?</p>`
    },
    "answer": {
      "en": `<p>Mahindra Treo Plus has a <b>gradeability of 22%</b> making it well suited for navigating on hilly terrains.
            The high gradeability ensures smooth and efficient operation in challenging uphill conditions. </p>`,
      "hi": `<p>महिंद्रा ट्रियो प्लस की <b>ग्रेडेबिलिटी 22% है</b>, जो इसे पहाड़ी इलाकों में नेविगेट करने के लिए उपयुक्त बनाती है। उच्च ग्रेडेबिलिटी चुनौतीपूर्ण कठिन परिस्थितियों में सुचारू और कुशल संचालन सुनिश्चित करती है।</p>`
    }
  },
  {
    "id": 6,
    "question": {
      "en": `<p>Q6. Can we charge the Treo Plus Li-ion battery home and how much amp switch board is required?</p>`,
      "hi": `<p>Q6. क्या हम ट्रियो प्लस ली-आयन बैटरी को घर पर चार्ज कर सकते हैं और कितने एम्पियर स्विचबोर्ड की आवश्यकता होती है?</p>`
    },
    "answer": {
      "en": `<p>Yes, you can charge the battery at home. The power source should have a <b>16 Amp, 3 pin socket 
            (Ensure a 220V AC supply has an earthing provision)</b>.</p>`,
      "hi": `<p>हां, आप घर पर बैटरी चार्ज कर सकते हैं। पावर स्रोत में <b>16 एम्पियर, 3 पिन सॉकेट होना चाहिए
      (सुनिश्चित करें कि 220V एसी आपूर्ति में अर्थिंग का प्रावधान हो)</b>।</p>`
    }
  },
  {
    "id": 7,
    "question": {
      "en": `<p>Q7. Can we charge the Treo Plus Li-ion battery at public places?</p>`,
      "hi": `<p>Q7. क्या हम सार्वजनिक स्थानों पर ट्रियो प्लस ली-आयन बैटरी को चार्ज कर सकते हैं?</p>`
    },
    "answer": {
      "en": `<p><b>Yes</b>, you can charge the battery wherever the <b>16 Amp, 3 pin connector facility is available.</b></p>`,
      "hi": `<p><b>हां</b>, आप बैटरी को वहां चार्ज कर सकते हैं जहां <b>16 एम्पियर, 3 पिन कनेक्टर की सुविधा उपलब्ध हो।</b></p>`
    }
  },
  {
    "id": 8,
    "question": {
      "en": `<p>Q8. Do you have an onboard fast charging facility in Treo Plus? </p>`,
      "hi": `<p>Q8.  क्या आपके पास ट्रियो प्लस में ऑनबोर्ड फास्ट चार्जिंग की सुविधा उपलब्ध है?</p>`
    },
    "answer": {
      "en": `<p><b>No, there’s no fast-charging facility available in Treo Plus.</b></p>`,
      "hi": `<p><b>नहीं, ट्रियो प्लस में कोई फास्ट चार्जिंग की सुविधा उपलब्ध नहीं है।</b></p>`
    }
  },
  {
    "id": 9,
    "question": {
      "en": `<p>Q9. How do I get a better range and better life of Treo Plus Li-ion battery? What is battery cell balancing?</p>`,
      "hi": `<p>Q9. मैं ट्रियो प्लस ली-आयन बैटरी के लिए बेहतर रेंज और बेहतर लाइफ कैसे प्राप्त कर सकता हूं? और बैटरी सेल बैलेंसिंग क्या है?</p>`
    },
    "answer": {
      "en": `<p>Daily charging of the battery up to <b>4 hours 20 minutes</b> on recommended home charging board ensuring proper earthing.
            <br />
            Cell Balancing means fully charging the Li-ion battery overnight once in three days, allowing the battery cells to completely charge at equal optimum level of 3.6 to 3.8 Volt. This will ensure 100% battery charging, best in class driving range and 5 years/1,50,000 Kms which is the expected life of Li-ion battery. 
            </p>`,
      "hi": `<p>उचित अर्थिंग सुनिश्चित करते हुए अनुशंसित होम चार्जिंग बोर्ड पर दैनिक बैटरी <b>4 घंटे 20 मिनट</b> तक चार्ज होती है।
            <br />
            सेल बैलेंसिंग में ली-आयन बैटरी को तीन दिन में एक बार रात भर में पूरी तरह से चार्ज किया जाता है, जिससे बैटरी सेल 3.6 से 3.8 वोल्ट के बराबर इष्टतम स्तर पर पूरी तरह से चार्ज हो जाती है। इससे 100% बैटरी चार्जिंग, सर्वोत्तम श्रेणी की ड्राइविंग रेंज और 5 साल/1,50,000 किलोमीटर की दूरी सुनिश्चित होगी, जो ली-आयन बैटरी की अपेक्षित लाइफ पीरियड है। 
            </p>`
    }
  },
  {
    "id": 10,
    "question": {
      "en": `<p>Q10. What is the maximum speed of the Treo Plus in boost mode and economy mode @GVW?</p>`,
      "hi": `<p>Q10. बूस्ट मोड और इकोनॉमी मोड @ जीवीडब्ल्यू में ट्रियो प्लस की अधिकतम गति क्या है?</p>`
    },
    "answer": {
      "en": `<p><b>Boost mode: 50 km/h; Economy mode: 37 km/h.</b></p>`,
      "hi": `<p><b>बूस्ट मोड: 50 किमी/घंटा; इकॉनोमी मोड: 37 किमी/घंटा।</b></p>`
    }
  },
  {
    "id": 11,
    "question": {
      "en": `<p>Q11. Where can I get the service done for my Treo Plus?</p>`,
      "hi": `<p>Q11. मैं अपने ट्रियो प्लस के लिए सर्विस कहां से प्राप्त कर सकता हूं?</p>`
    },
    "answer": {
      "en": `<p>You can get the service at Mahindra widest Service network of <b>300+ Dealerships & 1100+ Service touchpoints</b> known for trust & reliability.</p>`,
      "hi": `<p>महिंद्रा के <b>300+ डीलरशिप और एक्सटेंसिक सर्विस के सबसे व्यापक सर्विस नेटवर्क</b> पर आप इसकी सेवा प्राप्त कर सकते हैं। विश्वास और विश्वसनीयता के लिए जाने जाने वाले टच पॉइंट्स।</p>`
    }
  },
  {
    "id": 12,
    "question": {
      "en": `<p>Q12. What is the warranty period of the vehicle auxiliary battery?</p>`,
      "hi": `<p>Q12. वाहन की सहायक बैटरी की वारंटी अवधि क्या है?</p>`
    },
    "answer": {
      "en": `<p><b>12 months from date of sale of vehicle</b></p>`,
      "hi": `<p><b>वाहन की बिक्री की तिथि से 12 महीने की अवधि तक।</b></p>`
    }
  },
  {
    "id": 13,
    "question": {
      "en": `<p>Q13. What is the minimum life of Lithium-ion battery?</p>`,
      "hi": `<p>Q13. लिथियम-आयन बैटरी का न्यूनतम जीवन काल (लाइफ) क्या है?</p>`
    },
    "answer": {
      "en": `<p><b>5 Years/1,50,000 Kms</b></p>`,
      "hi": `<p><b>5 वर्ष/1,50,000 किलोमीटर।</b></p>`
    }
  },
  {
    "id": 14,
    "question": {
      "en": `<p>Q14. What is the cost of each Lithium battery cell replacement?</p>`,
      "hi": `<p>Q14. प्रत्येक लिथियम बैटरी सेल रिप्लेसमेंट की लागत क्या है?</p>`
    },
    "answer": {
      "en": `<p><b>Approx. Rs 7,500+GST.</b></p>`,
      "hi": `<p><b>लगभग. 7,500 रुपये + जीएसटी।</b></p>`
    }
  },
  {
    "id": 15,
    "question": {
      "en": `<p>Q15. What is the battery capacity of Treo Plus?</p>`,
      "hi": `<p>Q15. ट्रियो प्लस की बैटरी क्षमता कितनी है?</p>`
    },
    "answer": {
      "en": `<p><b>10.24 kWh, 48 V & 200 Ah</b></p>`,
      "hi": `<p><b>10.24 किलोवाट, 48 V और 200 Ah है।</b></p>`
    }
  },
  {
    "id": 16,
    "question": {
      "en": `<p>Q16. Can I fit the local battery?</p>`,
      "hi": `<p>Q16. क्या मैं लोकल बैटरी फिट कर सकता हूँ?</p>`
    },
    "answer": {
      "en": `<p><b>No, it’s recommended you don’t.</b></p>`,
      "hi": `<p><b>नहीं, यह अनुशंसा की जाती है कि आप ऐसा न करें।</b></p>`
    }
  },
  {
    "id": 17,
    "question": {
      "en": `<p>Q17. How many free services are offered & what is the service interval? </p>`,
      "hi": `<p>Q17. कितनी निःशुल्क सर्विस दी जाती हैं और सर्विस इंटरवल क्या है? </p>`
    },
    "answer": {
      "en": `<p><b>2 free services</b>, 1st Service Interval at 2,000 kms, 2nd at 10,000kms and then paid service at every 10,000 Kms.</p>`,
      "hi": `<p><b>2 फ्री सर्विस, सर्विस इंटरवल</b>: पहली बार 2,000 किलोमीटर पर, दूसरी बार 10,000 किलोमीटर पर और फिर हर 10,000 किलोमीटर पर भुगतान के साथ सर्विस।</p>`
    }
  },
  {
    "id": 18,
    "question": {
      "en": `<p>Q18. What is the life span of a 3 phase AC Induction motor one can expect?</p>`,
      "hi": `<p>Q18. 3 फेज एसी इंडक्शन मोटर का जीवनकाल (लाइफ) कितना हो सकता है?</p>`
    },
    "answer": {
      "en": `<p><b>1.5 Lakh Kms</b></p>`,
      "hi": `<p><b>1.5 लाख किमी।</b></p>`
    }
  },
  {
    "id": 19,
    "question": {
      "en": `<p>Q19. What is the cost of the battery charger?</p>`,
      "hi": `<p>Q19. बैटरी चार्जर की कीमत क्या है?</p>`
    },
    "answer": {
      "en": `<p><b>Rs. 18,000</b></p>`,
      "hi": `<p><b>18,000 रु।</b></p>`
    }
  },
  {
    "id": 20,
    "question": {
      "en": `<p>Q20. What is the length of the battery charger cable?</p>`,
      "hi": `<p>Q20. बैटरी चार्जर केबल की लंबाई कितनी होती है?</p>`
    },
    "answer": {
      "en": `<p><b>1.8 meter Length</b></p>`,
      "hi": `<p><b>1.8 मीटर लंबाई।</b></p>`
    }
  },
  {
    "id": 21,
    "question": {
      "en": `<p>Q21. What is the specification of the Treo Plus battery charger?</p>`,
      "hi": `<p>Q21. ट्रियो प्लस बैटरी चार्जर की स्पेसिफिकेशन क्या है?</p>`
    },
    "answer": {
      "en": `<p><b>2.2 kW</b></p>`,
      "hi": `<p><b>2.2 किलोवाट।</b></p>`
    }
  },
  {
    "id": 22,
    "question": {
      "en": `<p>Q22. Can I use a local charger?</p>`,
      "hi": `<p>Q22. क्या मैं स्थानीय चार्जर का उपयोग कर सकता हूँ?</p>`
    },
    "answer": {
      "en": `<p><b>No</b>, local charger usage is not recommended for Li-ion battery's health and safety.</p>`,
      "hi": `<p><b>नहीं</b>, ली-आयन बैटरी की हेल्थ और सेफ्टी को ध्यान में रखते हुए स्थानीय चार्जर के उपयोग की अनुशंसा नहीं की जाती है।</p>`
    }
  },
  {
    "id": 23,
    "question": {
      "en": `<p>Q23. Do you have provision for the fitment of a music system in Treo Plus?</p>`,
      "hi": `<p>Q23. क्या आपके पास ट्रियो प्लस में म्यूजिक सिस्टम फिट करने की व्यवस्था है?</p>`
    },
    "answer": {
      "en": `<p><b>Yes, music system provision is given at the front right-hand side and is available in our recommended accessories at dealerships.</b></p>`,
      "hi": `<p><b>हां, म्यूजिक सिस्टम की व्यवस्था सामने दाईं ओर दिया गया है और डीलरशिप पर हमारे अनुशंसित एक्सेसरीज में उपलब्ध है।</b></p>`
    }
  },
  {
    "id": 24,
    "question": {
      "en": `<p>Q24. Do you supply the jack assembly along with the vehicle?</p>`,
      "hi": `<p>Q24. क्या आप वाहन के साथ जैक असेंबली की सप्लाई करते हैं?</p>`
    },
    "answer": {
      "en": `<p><b>No, we don’t supply jack assembly along with the vehicle but</b> it is available in our dealership accessories’ kitty at a reasonable price.</p>`,
      "hi": `<p><b>नहीं, हम वाहन के साथ जैक असेंबली की सप्लाई नहीं करते हैं, लेकिन हमारे डीलरशिप एक्सेसरीज़ किटी के पास उचित मूल्य पर यह उपलब्ध है।</b></p>`
    }
  },
  {
    "id": 25,
    "question": {
      "en": `<p>Q25. Which financial institutions are approved for Treo Plus financing?</p>`,
      "hi": `<p>Q25. ट्रियो प्लस वित्तपोषण के लिए कौन से वित्तीय संस्थान स्वीकृत हैं?</p>`
    },
    "answer": {
      "en": `<p><b>All major NBFCs</b> such as MMFSL, Chola, Sriram finance, IDFC First etc. and nationalized banks such as SBI, Union Bank of India, Bank of Baroda, Bank of India etc. are funding for Treo Plus along with new age fintech organizations such Vidyut Tech, GoPik etc.</p>`,
      "hi": `<p><b>सभी प्रमुख एनबीएफसी</b> जैसे एमएमएफएसएल, चोल, श्रीराम फाइनेंस, आईडीएफसी फर्स्ट आदि और नए युग के फिनटेक संगठनों जैसे विद्युत टेक, गोपिक आदि के साथ-साथ राष्ट्रीयकृत बैंक जैसे एसबीआई, यूनियन बैंक ऑफ इंडिया, बैंक ऑफ बड़ौदा, बैंक ऑफ इंडिया आदि ट्रियो प्लस के लिए फंडिंग कर रहे हैं।</p>`
    }
  },
  {
    "id": 26,
    "question": {
      "en": `<p>Q26. What is the lowest down payment and EMI I can opt for?</p>`,
      "hi": `<p>Q26. सबसे कम डाउन पेमेंट और ईएमआई क्या है जिसे मैं चुन सकता हूं?</p>`
    },
    "answer": {
      "en": `<p>All Banks and NBFCs have their exclusive finance policies offering up-to 95% LTV (Loan to Value) on 
        Ex-Showroom Price or up-to 90% LTV of On-Road Price. Basis the finance scheme chosen by the customer, the EMI comes as low as INR 10,000 per month. </p>`,
      "hi": `<p>सभी बैंकों और एनबीएफसी की अपनी विशेष वित्त नीतियां हैं जो एक्स-शोरूम कीमत पर 95% एलटीवी (मूल्य पर ऋण) या ऑन-रोड कीमत पर 90% एलटीवी तक की पेशकश करती हैं। ग्राहक द्वारा चुनी गई वित्त योजना के आधार पर, ईएमआई प्रति माह 10,000 रुपये से कम आती है।</p>`
    }
  },
  {
    "id": 27,
    "question": {
      "en": `<p>Q27. What are the basic documents required for availing the vehicle loan?</p>`,
      "hi": `<p>Q27. वाहन ऋण प्राप्त करने के लिए आवश्यक बुनियादी दस्तावेज क्या हैं?</p>`
    },
    "answer": {
      "en": `<p>Basic documents required to avail a loan are <b>Identity proof, PAN, Aadhar card, Driving license/own house proof, house tax, electricity bill, bank statement, cheques, passport size photo.</b> In case there is additional proof required, the same will be communicated by the Financier on a case-to-case basis.</p>`,
      "hi": `<p>ऋण प्राप्त करने के लिए आवश्यक बुनियादी दस्तावेजों में - पहचान प्रमाण पत्र, पैन कार्ड, आधार कार्ड, ड्राइविंग लाइसेंस/खुद के घर का प्रमाण, हाउस टैक्स, बिजली बिल, बैंक स्टेटमेंट, चेक, पासपोर्ट साइज फोटो हैं। यदि अतिरिक्त प्रूफ की आवश्यकता है, तो उसे फाइनेंसर द्वारा केस-टू-केस आधार पर सूचित किया जाएगा।</p>`
    }
  },
  {
    "id": 28,
    "question": {
      "en": `<p>Q28. Can I subscribe the Treo Plus vehicle?</p>`,
      "hi": `<p>Q28. क्या मैं ट्रियो प्लस वाहन को सब्सक्राइब कर सकता हूं?</p>`
    },
    "answer": {
      "en": `<p><b>Yes</b>, you can <b>subscribe with Vidyut tech.</b> Customized subscription options are also available to suit every customer’s needs.</p>`,
      "hi": `<p><b>हां</b>, आप <b>विद्युत तकनीक को सब्सक्राइब</b> कर सकते हैं। प्रत्येक ग्राहक की आवश्यकताओं के अनुरूप अनुकूलित सब्सक्रिप्शन का विकल्प भी उपलब्ध हैं।</p>`
    }
  },
  {
    "id": 29,
    "question": {
      "en": `<p>Q29. What is the maximum tenure and interest rate I can avail from SBI?</p>`,
      "hi": `<p>Q29. मैं एसबीआई से अधिकतम कितनी अवधि और ब्याज दर का लाभ उठा सकता हूं?</p>`
    },
    "answer": {
      "en": `<p><b>Maximum tenure is 48 months</b> & internal rate of return is as low as <b>12.50% by State Bank of India.</b></p>`,
      "hi": `<p><b>भारतीय स्टेट बैंक द्वारा अधिकतम अवधि 48 महीने की है और रिटर्न की आंतरिक दर न्यूनतम 12.50% है।</b></p>`
    }
  },
  {
    "id": 30,
    "question": {
      "en": `<p>Q30. Can I pre-close / pre-pay my vehicle loan?</p>`,
      "hi": `<p>Q30. क्या मैं अपने वाहन ऋण को समय से पहले बंद/भुगतान कर सकता हूँ?</p>`
    },
    "answer": {
      "en": `<p><b>Yes, a customer may opt to pre-close / pre-pay the vehicle loan.</b> All banks and financial institutions revise their terms and policies at regular intervals. It is suggested to visit the nearest branch or contact the financier’s customer care for appropriate information.</p>`,
      "hi": `<p><b>हां, ग्राहक वाहन ऋण को समय से पहले बंद करने/समय से पहले भुगतान करने का विकल्प चुन सकता है।</b> सभी बैंक और वित्तीय संस्थान नियमित अंतराल पर अपनी शर्तों और नीतियों को संशोधित करते रहते हैं। उचित जानकारी के लिए नजदीकी शाखा में जाने या फाइनेंसर की ग्राहक सेवा से संपर्क करने का सुझाव दिया जाता है।</p>`
    }
  },
  {
    "id": 31,
    "question": {
      "en": `<p>Q31. What are the processing charges for availing the vehicle loan?</p>`,
      "hi": `<p>Q31. वाहन ऋण प्राप्त करने के लिए प्रोसेसिंग शुल्क क्या हैं?</p>`
    },
    "answer": {
      "en": `<p><b>All Banks and NBFCs</b> have exclusive finance policies, therefore, <b>processing charges ranges from 1% ~ 2%</b> as applicable on the total loan value.</p>`,
      "hi": `<p><b>सभी बैंकों और एनबीएफसी</b> के पास विशेष वित्त नीतियां होती हैं, इसलिए कुल ऋण मूल्य पर लागू <b>प्रोसेसिंग शुल्क 1% - 2%</b> तक होता है।</p>`
    }
  },
  {
    "id": 32,
    "question": {
      "en": `<p>Q32. Do you have the vehicle exchange scheme for Treo Plus?</p>`,
      "hi": `<p>Q32. क्या आपके पास ट्रियो प्लस के लिए व्हीकल एक्सचेंज स्कीम है?</p>`
    },
    "answer": {
      "en": `<p><b>Yes,</b> we do have a vehicle exchange scheme.</p>`,
      "hi": `<p><b>हां</b>, हमारे पास व्हीकल एक्सचेंज स्कीम की सुविधा उपलब्ध है।</p>`
    }
  }
]

const TREO_DEFAULT_SUMMARY = [
  {
    "summaryImage": '/images/common/technology_mahindra.png',
    "en": `<p><b>Technology+:</b> Battery Capacity of 10.24 kWh and charging time of 4hrs 20 min<p>`,
    "hi": `<p><b>टेक्नोलॉजी+:</b> बैटरी की क्षमता 10.24 किलोवाट और चार्जिंग समय 4 घंटे 20 मिनट।<p>`
  },
  {
    "summaryImage": '/images/common/range_mahindra.png',
    "en": `<p><b>Range+:</b>  Best in class real world driving Range of 150 kms per charge<p>`,
    "hi": `<p><b>रेंज+:</b> प्रति चार्ज 150 किमी की श्रेणी में सर्वश्रेष्ठ रेंज प्रदान करता है।<p>`
  },
  {
    "summaryImage": '/images/common/easy_ride_mahindra.png',
    "en": `<p><b>Easy Ride+: </b><li>1. Best in class Legroom space, 6" (inches) of Knee space higher than competition for passenger comfort & safety</li><li>2. Longer Wheelbase of 2,073 mm, assured space and stability</li><p>`,
    "hi": `<p><b>आसान राइड+: </b><li>1. लेगरूम स्पेस, घुटनों के लिए 6 इंच की जगह की श्रेणी में सर्वश्रेष्ठ, यात्री के कम्फर्ट और सुरक्षा के मामले में प्रतिस्पर्धी से कहीं बेहतर है।</li><li>2.	2073 मिमी का व्हीलबेस, सुनिश्चित स्थान और स्थिरता की श्रेणी में सर्वश्रेष्ठ है।</li><p>`
  },
  {
    "summaryImage": '/images/common/opeco_mahindra.png',
    "en": `<p><b>OPECO+:</b> Offers Best in class warranty period of 1.2 Lakh kms/5 years, providing peace of mind and higher savings to customers<p>`,
    "hi": `<p><b>ओपेको (ओपीईसीओ)+:</b> ग्राहकों को इससे मानसिक शांति मिलती है और अधिक बचत होता है तथा इसमें 1.2 लाख किमी/5 वर्ष की अवधि की श्रेणी में सर्वश्रेष्ठ वारंटी प्रदान किया जाता है।<p>`
  },
  {
    "summaryImage": '/images/common/performance_mahindra.png',
    "en": `<p><b>Performance+:</b> Delivers high performance with a peak Power of 8 kW and peak Torque of 42 Nm<p>`,
    "hi": `<p><b>परफॉरमेंस +:</b> 8 किलोवाट की पीक पॉवर और 42 एनएम के पीक टॉर्क के साथ बेहतर प्रदर्शन प्रदान करता है।<p>`
  }
]

// new change : 

const ZOR_GRAND_DEFAULT_SUMMARY = [
  {
    "en": `<p><b>ZORdaar Performance</b> </br>
    1. Best-in-Industry Power 12kW at 2920 Rpm </br>
    2. Excellent Torque – 50Nm </br>
    3. Faster turnaround time leading to more trips and higher earnings<p>`,
    "hi": `<p><b>ज़ोरदार प्रदर्शन:</b> उद्योग में सर्वश्रेष्ठ पावर 12 किलोवाट 2920 आरपीएम पर, उत्कृष्ट टॉर्क - 50 एनएम, तेज़ टर्नअराउंड समय जिसके कारण अधिक यात्राएं होती हैं और अधिक कमाई होती है।<p>`
  },
  {
    "en": `<p> <b>ZORdaar Savings </b> </br>
    1. Save upto INR 6 lakhs in 5 years compared to Diesel</br>
    2. Save upto INR 3 lakhs in 5 years compared to CNG<p>`,
    "hi": `<p><b>ज़ोरदार बचत:</b> डीजल की तुलना में 5 वर्षों में 6 लाख रुपये तक और सीएनजी की तुलना में 5 वर्षों में 3 लाख रुपये तक की बचत करें।<p>`
  },
  {
    "en": `<p><b>Gradeability (20.35%)</b> </br> Drive effortlessly on higher gradient slopes, flyovers upto 11.5° steep<p>`,
    "hi": `<p><b>ग्रेडेबिलिटी (20.35%):</b> उच्च ढाल वाले ढलानों, 11.5° तक के फ्लाईओवर पर आसानी से ड्राइव करें।<p>`
  },
  {
    "en": `<p><b>Range</b> </br>
    1. Best in class real world driving range of 90+ kms per charge </br>
    2. Bigger capacity Lithium-ion Phosphate battery of 10.24 kWh </br>
    3. Charging time – 4hours 30minutes<p>`,
    "hi": `<p><b>रेंज:</b> प्रति चार्ज 90+ किलोमीटर की सर्वोत्तम वास्तविक दुनिया की ड्राइविंग रेंज, 10.24 kWh की बड़ी क्षमता वाली लिथियम-आयन फॉस्फेट बैटरी। चार्जिंग समय - 4 घंटे 30 मिनट।<p>`
  },
  {
    "en": `<p><b>Assurance</b> </br>
    1. Widest service network </br>
    2. Standard Warranty: 3 Years/ 80,000 kms (whichever is earlier) + Extended Warranty: 2 Years/ 40,000 kms<p>`,
    "hi": `<p><b>आश्वासन:</b> 800+ टचप्वाइंट का सबसे बड़ा सेवा नेटवर्क, बैटरी पर 5 साल/1.5 लाख किमी की वारंटी, वाहन पर 3 साल/80,000 किमी की वारंटी।<p>`
  },
  {
    "en": `<p><b>NEMO</b> </br> Drivers can track their savings, navigate efficiently, stay connected to vehicle with NEMO app<p>`,
    "hi": `<p><b>NEMO:</b> ड्राइवर NEMO ऐप से अपनी बचत को ट्रैक कर सकते हैं, कुशलता से नेविगेट कर सकते हैं, वाहन से जुड़े रह सकते हैं।<p>`
  },
  {
    "en": `<p><b>Durability</b> </br> Full metal body, factory fitted ARAI approved strong metal DV box<p>`,
    "hi": `<p><b>टिकाऊपन:</b> फुल मेटल बॉडी, फैक्ट्री फिटेड एआरएआई अनुमोदित डीवी बॉक्स और मन की शांति के लिए उच्च ड्राइविंग रेंज।<p>`
  }
]

const PRODUCT_DETAIL_LIST = [
  {
    'id': 1,
    'productId': 1,
    'name': 'TREO Plus',
    'productImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/TREO_Plus_product.png',
    'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/cover-treo-plus.png',
    'comments': 4,
    'documentsLinked': 5,
    'launchType': 'launch',
    'isVoiceOverAvailable': true,
    'summaryVoiceOverTrack': {
      'en': `https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/treo_summary_voiceover.mp3`,
      'hi': `https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/treo_summary_voiceover.mp3`
    },
    'summary': {
      'def': TREO_DEFAULT_SUMMARY,
      'fleet_owners': TREO_DEFAULT_SUMMARY,
      'single_owner': TREO_DEFAULT_SUMMARY,
      'rural_buyer': TREO_DEFAULT_SUMMARY,
      'dealer': TREO_DEFAULT_SUMMARY,
    },

    'specsStatements': {
      'compatitor_1': [
        {
          "id": 1,
          "statement": {
            "en": `1. Treo Plus has a higher top speed of 55 km / hr and a better wheel base as compared to competitor 1`,
            "hi": ``
          }
        },
        {
          "id": 2,
          "statement": {
            "en": `2. With a higher battery capacity of 1.24 kWh, Treo Plus runs for longer time`,
            "hi": ``
          }
        },
        {
          "id": 3,
          "statement": {
            "en": `3. Warranty of 5 years / 1.2 lakh kms ensures peace of mind and higher savings for customers`,
            "hi": ``
          }
        }
      ],
      'compatitor_2': [
        {
          "id": 1,
          "statement": {
            "en": `1. Treo Plus has best in class real world driving range of 150 km/hr and a top speed of 55 km/hr delivering high performance`,
            "hi": ``
          }
        },
        {
          "id": 2,
          "statement": {
            "en": `2. With a higher battery capacity of 1.24 kWh, Treo Plus runs for longer time`,
            "hi": ``
          }
        },
        {
          "id": 3,
          "statement": {
            "en": `3. Warranty of 5 years/1.2 lakh kms ensures peace of mind and higher savings for customers`,
            "hi": ``
          }
        }
      ],
      'compatitor_3': [
        {
          "id": 1,
          "statement": {
            "en": `1. You can rely on the trust of Mahindra brand with 800+ service centres across the country which is higher than competition`,
            "hi": ``
          }
        },
        {
          "id": 2,
          "statement": {
            "en": `2. Charging time as low as 4 hours and 20 minutes compared to 6 hours of competition `,
            "hi": ``
          }
        },
        {
          "id": 3,
          "statement": {
            "en": `3. With a higher battery capacity of 1.24 kWh, Treo Plus runs for longer time`,
            "hi": ``
          }
        },
        {
          "id": 4,
          "statement": {
            "en": `4. Longer wheelbase assures space and stability`,
            "hi": ``
          }
        }
      ],
    },
    'specifications': {
      'compatitor_1': [
        {
          "id": 1,
          "title": {
            "en": `Top speed`,
            "hi": `उच्चतम गति`
          },
          "value": {
            "en": `<p><b>55 km/hr</b></p>`,
            "hi": `<p><b>55 कि.मी./घंटा</b></p>`
          }
        },
        {
          "id": 2,
          "title": {
            "en": `Space (Wheelbase)`,
            "hi": `व्हीलबेस`
          },
          "value": {
            "en": `<p><b>2,073 mm</b></p>`,
            "hi": `<p><b>2,073 मीमी</b></p>`
          }
        },
        {
          "id": 3,
          "title": {
            "en": `Battery Capacity`,
            "hi": `बैटरी की क्षमता`
          },
          "value": {
            "en": `<p><b>10.24 kWh</b></p>`,
            "hi": `<p><b>10.24 किलोवाट</b></p>`
          }
        },
        {
          "id": 4,
          "title": {
            "en": `Driving Range`,
            "hi": `ड्राइविंग रेंज`
          },
          "value": {
            "en": `<p><b>150 Kms</b></p>`,
            "hi": `<p><b>150 कि.मी.</b></p>`
          }
        },
        {
          "id": 5,
          "title": {
            "en": `Body Type`,
            "hi": `बॉडी का प्रकार`
          },
          "value": {
            "en": `<p>SMC Panel</p>`,
            "hi": `<p>एसएमसी पैनल</p>`
          }
        },
        {
          "id": 6,
          "title": {
            "en": `Warranty`,
            "hi": `वारंटी`
          },
          "value": {
            "en": `<p><b>5 years / 1.2 Lakh Kms.</b></p>`,
            "hi": `<p><b>5 वर्ष/1,20,000 किमी.</b></p>`
          }
        },
        {
          "id": 7,
          "title": {
            "en": `Charger`,
            "hi": `चार्जर`
          },
          "value": {
            "en": `<p>Off Board</p>`,
            "hi": `<p>ऑफबोर्ड</p>`
          }
        }
      ],
      'compatitor_2': [
        {
          "id": 1,
          "title": {
            "en": `Power`,
            "hi": `पावर`
          },
          "value": {
            "en": `<p><b>8 kW</b></p>`,
            "hi": `<p><b>8 किलोवाट</b></p>`
          }
        },
        {
          "id": 2,
          "title": {
            "en": `Torque`,
            "hi": `टॉर्क`
          },
          "value": {
            "en": `<p><b>42 Nm</b></p>`,
            "hi": `<p><b>42 एनएम</b></p>`
          }
        },
        {
          "id": 3,
          "title": {
            "en": `Top speed`,
            "hi": `उच्चतम गति`
          },
          "value": {
            "en": `<p><b>55 km/hr</b></p>`,
            "hi": `<p><b>55 कि.मी./घंटा</b></p>`
          }
        },
        {
          "id": 4,
          "title": {
            "en": `Battery Capacity`,
            "hi": `बैटरी की क्षमता`
          },
          "value": {
            "en": `<p><b>10.24 kWh</b></p>`,
            "hi": `<p><b>10.24 किलोवाट</b></p>`
          }
        },
        {
          "id": 5,
          "title": {
            "en": `Driving Range`,
            "hi": `ड्राइविंग रेंज`
          },
          "value": {
            "en": `<p><b>150 Kms</b></p>`,
            "hi": `<p><b>150 कि.मी</b></p>`
          }
        },
        {
          "id": 6,
          "title": {
            "en": `Space (Wheelbase)`,
            "hi": `व्हीलबेस`
          },
          "value": {
            "en": `<p><b>2,073 mm</b></p>`,
            "hi": `<p><b>2,073 मीमी</b></p>`
          }
        },
        {
          "id": 7,
          "title": {
            "en": `Body Type`,
            "hi": `बॉडी का प्रकार`
          },
          "value": {
            "en": `<p>SMC Panel</p>`,
            "hi": `<p>एसएमसी पैनल</p>`
          }
        },
        {
          "id": 8,
          "title": {
            "en": `Warranty`,
            "hi": `वारंटी`
          },
          "value": {
            "en": `<p><b>std 5 years / 1,20,000 Kms</b></p>`,
            "hi": `<p><b>5 वर्ष/1,20,000 कि.मी</b></p>`
          }
        }
      ],
      'compatitor_3': [
        {
          "id": 1,
          "title": {
            "en": `Battery Capacity`,
            "hi": `बैटरी की क्षमता`
          },
          "value": {
            "en": `<p><b>10.24 kWh</b></p>`,
            "hi": `<p><b>10.24 किलोवाट</b></p>`
          }
        },
        {
          "id": 2,
          "title": {
            "en": `Charging Time`,
            "hi": `चार्जिंग टाइम`
          },
          "value": {
            "en": `<p><b>4 hr 20 min</b></p>`,
            "hi": `<p><b>4 घंटा 20 मिनट</b></p>`
          }
        },
        {
          "id": 3,
          "title": {
            "en": `Driving Range`,
            "hi": `ड्राइविंग रेंज`
          },
          "value": {
            "en": `<p>150 Kms</p>`,
            "hi": `<p>150 किमी </p>`
          }
        },
        {
          "id": 4,
          "title": {
            "en": `Space (Wheelbase)`,
            "hi": `व्हीलबेस`
          },
          "value": {
            "en": `<p><b>2,073 mm</b></p>`,
            "hi": `<p><b>2,073 मिमी</b></p>`
          }
        },
        {
          "id": 5,
          "title": {
            "en": `Trust`,
            "hi": `ट्रस्ट`
          },
          "value": {
            "en": `<p><b>Mahindra 800+ service centres</b></p>`,
            "hi": `<p><b>महिंद्रा 800+ सर्विस सेंटर</b></p>`
          }
        },
        {
          "id": 6,
          "title": {
            "en": `Variant`,
            "hi": `वेरिएंट`
          },
          "value": {
            "en": `<p>2 variants: TREO Plus and TREO Auto</p>`,
            "hi": `<p>2 वेरिएंट: ट्रियो प्लस और ट्रियो ऑटो</p>`
          }
        }
      ],
    },
    'faqs': {
      'def': TREO_PLUS_DEF_FAQS,
      'fleet_owners': TREO_PLUS_DEF_FAQS,
      'single_owner': TREO_PLUS_DEF_FAQS,
      'farmer': TREO_PLUS_DEF_FAQS,
      'dealer': TREO_PLUS_DEF_FAQS,
    },
  },
  // new change : 
  {
    'id': 2,
    'productId': 2,
    'name': 'Zor GRAND',
    'productImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/zor-grand-dv-transparent.png',
    'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/zor-grand-cover.jpg',
    'comments': 4,
    'documentsLinked': 5,
    'launchType': 'launch',
    'isVoiceOverAvailable': false,
    'summaryVoiceOverTrack': {
      'en': ``,
      'hi': ``
    },
    'summary': {
      'def': ZOR_GRAND_DEFAULT_SUMMARY,
      'fleet_owners': ZOR_GRAND_DEFAULT_SUMMARY,
      'single_owner': ZOR_GRAND_DEFAULT_SUMMARY,
      'rural_buyer': ZOR_GRAND_DEFAULT_SUMMARY,
      'dealer': ZOR_GRAND_DEFAULT_SUMMARY,
    },
    'specsStatements': {
      'compatitor_1': [
        {
          "id": 1,
          "statement": {
            "en": `Zor GRAND DV has best in industry power of 12 kW, best in class gradeability 11.5° and range (100+ kms) higher than competition`,
            "hi": `ज़ोर ग्रैंड डीवी की उद्योग में सर्वोत्तम शक्ति 12 किलोवाट, श्रेणी में सर्वोत्तम ग्रेडेबिलिटी 11.5° और रेंज (100+ किलोमीटर) प्रतिस्पर्धा से अधिक है।`
          }
        }
      ],
      'compatitor_2': [
        {
          "id": 1,
          "statement": {
            "en": `Zor GRAND DV has best in class acceleration, DV 170 Cuft 1st in industry ARAI approved and factory fitted delivery box, and best in industry power of 12 kW`,
            "hi": `ज़ोर ग्रांड डीवी के पास श्रेणी में सर्वश्रेष्ठ त्वरण है, डीवी 170 क्यूएफटी उद्योग में प्रथम है, एआरएआई अनुमोदित और फैक्ट्री फिटेड डिलीवरी बॉक्स है, और उद्योग में सर्वश्रेष्ठ 12 किलोवाट की शक्ति है।`
          }
        }
      ],
      'compatitor_3': [
      ],
    },
    'specifications': {
      'compatitor_1': [
        {
          "id": 1,
          "title": {
            "en": `Delivery Box`,
            "hi": `डिलिवरी बॉक्स`
          },
          "value": {
            "en": `<p><b>170 Cuft DV</b></p>`,
            "hi": `<p><b>170 Cuft DV</b></p>`
          }
        },
        {
          "id": 2,
          "title": {
            "en": `Peak Power`,
            "hi": `चरम शक्ति`
          },
          "value": {
            "en": `<p><b>12 KW</b></p>`,
            "hi": `<p><b>12 किलोवाट</b></p>`
          }
        },
        {
          "id": 3,
          "title": {
            "en": `Acceleration`,
            "hi": `त्वरण`
          },
          "value": {
            "en": `<p><b>Best in class</b></p>`,
            "hi": `<p><b>कक्षा में सबसे उत्तम</b></p>`
          }
        },
        {
          "id": 4,
          "title": {
            "en": `Gradeability`,
            "hi": `ग्रेडेबिलिटी`
          },
          "value": {
            "en": `<p><b>11.5°</b></p>`,
            "hi": `<p><b>11.5°</b></p>`
          }
        },
        {
          "id": 5,
          "title": {
            "en": `Warranty`,
            "hi": `वारंटी`
          },
          "value": {
            "en": `<p>5 yrs / 1,50,000 kms</p>`,
            "hi": `<p>5 वर्ष /1,50,000 किमी</p>`
          }
        },
        {
          "id": 6,
          "title": {
            "en": `Range`,
            "hi": `श्रेणी`
          },
          "value": {
            "en": `<p><b>100+ kms</b></p>`,
            "hi": `<p><b>100+ किमी</b></p>`
          }
        },
        {
          "id": 7,
          "title": {
            "en": `Wheelbase`,
            "hi": `व्हीलबेस`
          },
          "value": {
            "en": `<p>2200 mm</p>`,
            "hi": `<p>2200 मिमी</p>`
          }
        }
      ]
      ,
      'compatitor_2': [
        {
          "id": 1,
          "title": {
            "en": `Delivery Box`,
            "hi": `डिलिवरी बॉक्स`
          },
          "value": {
            "en": `<p><b>170 Cuft DV</b></p>`,
            "hi": `<p><b>170 Cuft DV</b></p>`
          }
        },
        {
          "id": 2,
          "title": {
            "en": `Peak Power`,
            "hi": `चरम शक्ति`
          },
          "value": {
            "en": `<p><b>12 KW</b></p>`,
            "hi": `<p><b>12 किलोवाट</b></p>`
          }
        },
        {
          "id": 3,
          "title": {
            "en": `Acceleration`,
            "hi": `त्वरण`
          },
          "value": {
            "en": `<p><b>Best in class</b></p>`,
            "hi": `<p><b>कक्षा में सबसे उत्तम</b></p>`
          }
        },
        {
          "id": 4,
          "title": {
            "en": `Gradeability`,
            "hi": `ग्रेडेबिलिटी`
          },
          "value": {
            "en": `<p><b>11.5°</b></p>`,
            "hi": `<p><b>11.5°</b></p>`
          }
        },
        {
          "id": 5,
          "title": {
            "en": `Warranty`,
            "hi": `वारंटी`
          },
          "value": {
            "en": `<p>5 yrs / 1,50,000 kms</p>`,
            "hi": `<p>5 वर्ष /1,50,000 किमी</p>`
          }
        },
        {
          "id": 6,
          "title": {
            "en": `Range`,
            "hi": `श्रेणी`
          },
          "value": {
            "en": `<p><b>100+ kms</b></p>`,
            "hi": `<p><b>100+ किमी</b></p>`
          }
        },
        {
          "id": 7,
          "title": {
            "en": `Wheelbase`,
            "hi": `व्हीलबेस`
          },
          "value": {
            "en": `<p>2200 mm</p>`,
            "hi": `<p>2200 मिमी</p>`
          }
        }
      ]
      ,
      'compatitor_3': [
        {
          "id": 1,
          "title": {
            "en": `Delivery Box`,
            "hi": `डिलिवरी बॉक्स`
          },
          "value": {
            "en": `<p><b>170 Cuft DV</b></p>`,
            "hi": `<p><b>170 Cuft DV</b></p>`
          }
        },
        {
          "id": 2,
          "title": {
            "en": `Peak Power`,
            "hi": `चरम शक्ति`
          },
          "value": {
            "en": `<p><b>12 KW</b></p>`,
            "hi": `<p><b>12 किलोवाट</b></p>`
          }
        },
        {
          "id": 3,
          "title": {
            "en": `Acceleration`,
            "hi": `त्वरण`
          },
          "value": {
            "en": `<p><b>Best in class</b></p>`,
            "hi": `<p><b>कक्षा में सबसे उत्तम</b></p>`
          }
        },
        {
          "id": 4,
          "title": {
            "en": `Gradeability`,
            "hi": `ग्रेडेबिलिटी`
          },
          "value": {
            "en": `<p><b>11.5°</b></p>`,
            "hi": `<p><b>11.5°</b></p>`
          }
        },
        {
          "id": 5,
          "title": {
            "en": `Warranty`,
            "hi": `वारंटी`
          },
          "value": {
            "en": `<p>5 yrs / 1,50,000 kms</p>`,
            "hi": `<p>5 वर्ष /1,50,000 किमी</p>`
          }
        },
        {
          "id": 6,
          "title": {
            "en": `Range`,
            "hi": `श्रेणी`
          },
          "value": {
            "en": `<p><b>100+ kms</b></p>`,
            "hi": `<p><b>100+ किमी</b></p>`
          }
        },
        {
          "id": 7,
          "title": {
            "en": `Wheelbase`,
            "hi": `व्हीलबेस`
          },
          "value": {
            "en": `<p>2200 mm</p>`,
            "hi": `<p>2200 मिमी</p>`
          }
        }
      ]
      ,
    },
    'faqs': {
      // 'def': TREO_PLUS_FAQS,
      'def': [],
      'fleet_owners': [],
      'single_owner': [],
      'farmer': [],
      'dealer': [],
    },
  }
]

const PRODUCT_FILES_LIST = [
  {
    "productId": 1,
    "productName": "TREO Plus",
    "documentsData": [
      {
        'id': 1,
        'name': {
          'en': "Treo Plus USP.pdf",
          'hi': "Treo Plus USP Hindi.pdf"
        },
        'description': "Description 01",
        'findingName': 'one_pager',
        'type': "FILE",
        'filetype': 'FILE',
        'mimeType': "application/pdf",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Treo+Plus+USP+English.pdf",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Treo+Plus+USP+Hindi.pdf"
        },
      },
      {
        'id': 2,
        'name': {
          'en': "TREO Plus Introduction video.mp4",
          'hi': "TREO Plus Introduction video Hindi.mp4"
        },
        'findingName': 'video',
        'description': "Description 02",
        'type': "FILE",
        'filetype': 'video',
        'mimeType': "video/mp4",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/LINEUP+TREO+PLUS+ENG+%2B+AV+HIGH.mp4",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/TREO+Plus+Hindi++TVC.mp4"
        },
      },
      // {
      //     'id': 3,
      //     'name': "TREO Competitor comparison.pdf",
      //     'description': "Description 03",
      //     'type': "FILE",
      //     'filetype': 'FILE',
      //     'mimeType': "application/pdf",
      //     'path': {
      // 'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Treo+Plus+Flash+Cards.pdf",
      // 'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Treo+Plus+Flash+Cards.pdf"
      // },
      {
        'id': 4,
        'name': {
          'en': "TREO Plus 20 second promo video.mp4",
          'hi': "TREO Plus 20 second promo video Hindi.mp4",
        },
        'findingName': 'promo_video',
        'description': "Description 04",
        'type': "FILE",
        'filetype': 'video',
        'mimeType': "video/mp4",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/TRED+20+SEC+OPT+2.mp4",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Treo+20second-hindi.mp4"
        },
      },
      {
        'id': 5,
        'name': {
          'en': "TREO Plus brochure.pdf",
          'hi': "TREO Plus brochure Hindi.pdf",
        },
        'findingName': 'brochure',
        'description': "Description 05",
        'type': "FILE",
        'filetype': 'FILE',
        'mimeType': "application/pdf",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Treo+Plus+Broucher_June_English.pdf",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Treo+Plus+Broucher_June_English.pdf"
        },
      },
      {
        'id': 6,
        'name': {
          'en': "TREO Plus TCO english.pdf",
          'hi': "TREO Plus TCO hindi.pdf"
        },
        'description': "Description 01",
        'findingName': 'one_pager',
        'type': "FILE",
        'filetype': 'FILE',
        'mimeType': "application/pdf",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/TREO+Plus+TCO+english.pdf",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/TREO+Plus+TCO+hindi.pdf"
        },
      },
      {
        'id': 7,
        'name': {
          'en': "Technical Specs-english.pdf",
          'hi': "Technical Specs-hindi.pdf"
        },
        'description': "Description 01",
        'findingName': 'one_pager',
        'type': "FILE",
        'filetype': 'FILE',
        'mimeType': "application/pdf",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Technical+Specs-english.pdf",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Technical+Specs-hindi.pdf"
        },
      },


    ]
  },
  {
    "productId": 2,
    "productName": "Zor GRAND",
    "documentsData": [
      {
        'id': 1,
        'name': {
          'en': "Zor GRAND Teaser ENG.mp4",
          'hi': "Zor GRAND Teaser HIN.mp4"
        },
        'findingName': 'video',
        'description': "Description 01",
        'type': "FILE",
        'filetype': 'video',
        'mimeType': "video/mp4",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Zor+Grand+Teaser+ENG.mp4",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Zor+Grand+Teaser+HIN.mp4"
        },
      },
      {
        'id': 2,
        'name': {
          'en': "Zor GRAND Brochure ENG.pdf",
          'hi': "Zor GRAND Brochure ENG.pdf"
        },
        'findingName': 'brochure',
        'description': "Description 02",
        'type': "FILE",
        'filetype': 'FILE',
        'mimeType': "application/pdf",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Zor+Grand+Brochure+-+ENG.pdf",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Zor+Grand+Brochure+-+ENG.pdf"
        },
      },
      {
        'id': 3,
        'name': {
          'en': "Zor GRAND Leaflet ENG.pdf",
          'hi': "Zor GRAND Leaflet ENG.pdf",
        },
        'findingName': 'one_pager',
        'description': "Description 04",
        'type': "FILE",
        'filetype': 'FILE',
        'mimeType': "application/pdf",
        'path': {
          'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Zor+Grand+Leaflet-+ENG.pdf",
          'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Zor+Grand+Leaflet-+HIN.pdf"
        },
      },
    ]
  },
  {
    'id': 6,
    'name': {
      'en': "TREO Plus TCO english.pdf",
      'hi': "TREO Plus TCO hindi.pdf"
    },
    'description': "Description 01",
    'findingName': 'one_pager',
    'type': "FILE",
    'filetype': 'FILE',
    'mimeType': "application/pdf",
    'path': {
      'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/TREO+Plus+TCO+english.pdf",
      'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/TREO+Plus+TCO+hindi.pdf"
    },
  },
  {
    'id': 7,
    'name': {
      'en': "Technical Specs-english.pdf",
      'hi': "Technical Specs-hindi.pdf"
    },
    'description': "Description 01",
    'findingName': 'one_pager',
    'type': "FILE",
    'filetype': 'FILE',
    'mimeType': "application/pdf",
    'path': {
      'en': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Technical+Specs-english.pdf",
      'hi': "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Technical+Specs-hindi.pdf"
    },
  },
];


const PERSONA_LIST = [
  {
    'id': 1,
    'name': 'Fleet Owners',
    'encodedName': 'fleet_owners',
    'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/fleet-owners.png',
    'documentsLinked': 5,
  },
  {
    'id': 2,
    'name': 'Single Owner',
    'encodedName': 'single_owner',
    'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/single-owner.png',
    'documentsLinked': 5,

  },
  {
    'id': 3,
    'name': 'Rural Buyer',
    'encodedName': 'rural_buyer',
    'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/rural-buyer.png',
    'documentsLinked': 5,

  },
  {
    'id': 4,
    'name': 'Dealer',
    'encodedName': 'dealer',
    'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/dealer.png',
    'documentsLinked': 5,

  },
]

const RIVAL_PRODUCT_DETAILS = [
  {
    "productId": 1,
    "productName": "TREO Plus",
    "competitorData": [
      {
        'id': 1,
        'productId': 1,
        'name': 'Competitor 1',
        'productName': 'Bajaj RE EV',
        'findingName': 'compatitor_1',
        'productImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Bajaj_ev_product_1.png',
        'orgCoverImage': 'https://cdn.bajajauto.com/-/media/assets/bajajauto/360degreeimages/3-wheelers-and-qute/re-passenger/01.png',
        'comments': 4,
        'documentsLinked': 8,
        'launchType': 'launch',
        'specifications': [
          {
            "id": 1,
            "title": {
              "en": `Top Speed`,
              "hi": `Top Speed`
            },
            "value": {
              "en": `<p>45 km/hr</p>`,
              "hi": `<p>45 किमी./घंटा</p>`
            }
          },
          {
            "id": 2,
            "title": {
              "en": `Space (Wheelbase)`,
              "hi": `व्हीलबेस`
            },
            "value": {
              "en": `<p>1.920 mm</p>`,
              "hi": `<p>1,920 मीमी</p>`
            }
          },
          {
            "id": 3,
            "title": {
              "en": `Battery Capacity`,
              "hi": `बैटरी की क्षमता`
            },
            "value": {
              "en": `<p>8.9 kWh</p>`,
              "hi": `<p>8.9 किलोवाट</p>`
            }
          },
          {
            "id": 4,
            "title": {
              "en": `Driving Range`,
              "hi": `ड्राइविंग रेंज`
            },
            "value": {
              "en": `<p>150 Kms</p>`,
              "hi": `<p>150 कि.मी.</p>`
            }
          },
          {
            "id": 5,
            "title": {
              "en": `Body Type`,
              "hi": `बॉडी का प्रकार`
            },
            "value": {
              "en": `<p>Metal Body</p>`,
              "hi": `<p>मेटल बॉडी</p>`
            }
          },
          {
            "id": 6,
            "title": {
              "en": `Warranty`,
              "hi": `वारंटी`
            },
            "value": {
              "en": `<p>3 years / 80,000 Kms.</p>`,
              "hi": `<p>3 वर्ष/80,000 कि.मी.</p>`
            }
          },
          {
            "id": 7,
            "title": {
              "en": `Charger`,
              "hi": `चार्जर`
            },
            "value": {
              "en": `<p>On board</p>`,
              "hi": `<p>ऑनबोर्ड</p>`
            }
          }
        ]

      },
      {
        'id': 2,
        'productId': 2,
        'name': 'Competitor 2',
        'productName': 'Piaggio Ape Electric',
        'findingName': 'compatitor_2',
        'productImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Piaggio_ape_product_1.png',
        'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/piaggeo-e-city-FM-MAX.png',
        'comments': 4,
        'documentsLinked': 8,
        'launchType': 'launch',
        'specifications': [
          {
            "id": 1,
            "title": {
              "en": `Power`,
              "hi": `पावर`
            },
            "value": {
              "en": `<p>5.44 kW</p>`,
              "hi": `<p>5.44 किलोवाट</p>`
            }
          },
          {
            "id": 2,
            "title": {
              "en": `Torque`,
              "hi": `टॉर्क`
            },
            "value": {
              "en": `<p>29 Nm</p>`,
              "hi": `<p>29 एनएम</p>`
            }
          },
          {
            "id": 3,
            "title": {
              "en": `Top speed`,
              "hi": `उच्चतम गति`
            },
            "value": {
              "en": `<p>50 km/hr</p>`,
              "hi": `<p>50 कि.मी./घंटा</p>`
            }
          },
          {
            "id": 4,
            "title": {
              "en": `Battery Capacity`,
              "hi": `बैटरी की क्षमता`
            },
            "value": {
              "en": `<p>8 kWh</p>`,
              "hi": `<p>8 किलोवाट</p>`
            }
          },
          {
            "id": 5,
            "title": {
              "en": `Driving Range`,
              "hi": `ड्राइविंग रेंज`
            },
            "value": {
              "en": `<p>140 Kms</p>`,
              "hi": `<p>140 कि.मी.</p>`
            }
          },
          {
            "id": 6,
            "title": {
              "en": `Space (Wheelbase)`,
              "hi": `व्हीलबेस`
            },
            "value": {
              "en": `<p>1,920 mm</p>`,
              "hi": `<p>1,920 मीमी</p>`
            }
          },
          {
            "id": 7,
            "title": {
              "en": `Body Type`,
              "hi": `बॉडी का प्रकार`
            },
            "value": {
              "en": `<p>Metal Body</p>`,
              "hi": `<p>मेटल बॉडी</p>`
            }
          },
          {
            "id": 8,
            "title": {
              "en": `Warranty`,
              "hi": `वारंटी`
            },
            "value": {
              "en": `<p>3 years / 1,75,000 Kms</p>`,
              "hi": `<p>3 वर्ष/1,75,000 कि.मी.</p>`
            }
          }
        ]
        ,
      },
      {
        'id': 3,
        'productId': 3,
        'name': 'Competitor 3',
        'productName': 'Montra',
        'findingName': 'compatitor_3',
        'productImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/Montra_product.png',
        'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/montra.png',
        'comments': 4,
        'documentsLinked': 8,
        'launchType': 'launch',
        'specifications': [
          {
            "id": 1,
            "title": {
              "en": `Battery Capacity`,
              "hi": `बैटरी की क्षमता`
            },
            "value": {
              "en": `<p>7.66 kWh & 10 kWh</p>`,
              "hi": `<p>7.66 एवं 10 किलोवाट</p>`
            }
          },
          {
            "id": 2,
            "title": {
              "en": `Charging Time`,
              "hi": `Charging Time`
            },
            "value": {
              "en": `<p>6 hr</p>`,
              "hi": `<p>6 घंटा</p>`
            }
          },
          {
            "id": 3,
            "title": {
              "en": `Driving Range`,
              "hi": `ड्राइविंग रेंज`
            },
            "value": {
              "en": `<p>150+ Kms</p>`,
              "hi": `<p>150+ किमी</p>`
            }
          },
          {
            "id": 4,
            "title": {
              "en": `Space (Wheelbase)`,
              "hi": `व्हीलबेस`
            },
            "value": {
              "en": `<p>2,010 mm</p>`,
              "hi": `<p>2,010 मिमी</p>`
            }
          },
          {
            "id": 5,
            "title": {
              "en": `Trust`,
              "hi": `ट्रस्ट`
            },
            "value": {
              "en": `<p>50 service centres</p>`,
              "hi": `<p>50 सेवा केंद्र</p>`
            }
          },
          {
            "id": 6,
            "title": {
              "en": `Variant`,
              "hi": `वेरिएंट`
            },
            "value": {
              "en": `<p>3 Variants: Super ePV 2.0, Super ePV and Super ePx</p>`,
              "hi": `<p>3 वेरिएंट</p>`
            }
          }
        ]

      },
    ]
  },

  // new change : 
  {
    "productId": 2,
    "productName": "Zor GRAND",
    "competitorData": [
      {
        'id': 1,
        'productId': 1,
        'name': 'Competitor 1',
        'productName': 'Bajaj Zor',
        'findingName': 'compatitor_1',
        'productImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/bajajzor1.png',
        'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/bajajzor1.png',
        'comments': 4,
        'documentsLinked': 8,
        'launchType': 'launch',
        'specifications': [
          {
            "id": 1,
            "title": {
              "en": `Delivery Box`,
              "hi": `डिलिवरी बॉक्स`
            },
            "value": {
              "en": `<p>Not available</p>`,
              "hi": `<p>उपलब्ध नहीं है</p>`
            }
          },
          {
            "id": 2,
            "title": {
              "en": `Peak Power`,
              "hi": `चरम शक्ति`
            },
            "value": {
              "en": `<p>9.55 KW</p>`,
              "hi": `<p>9.55 किलोवाट</p>`
            }
          },
          {
            "id": 3,
            "title": {
              "en": `Acceleration`,
              "hi": `त्वरण`
            },
            "value": {
              "en": `<p>Good</p>`,
              "hi": `<p>अच्छा</p>`
            }
          },
          {
            "id": 4,
            "title": {
              "en": `Gradeability`,
              "hi": `ग्रेडेबिलिटी`
            },
            "value": {
              "en": `<p>9.65°</p>`,
              "hi": `<p>9.65°</p>`
            }
          },
          {
            "id": 5,
            "title": {
              "en": `Warranty`,
              "hi": `गारंटी`
            },
            "value": {
              "en": `<p>3 yrs / 1,00,000 kms</p>`,
              "hi": `<p>3 वर्ष /1,00,000 किमी</p>`
            }
          },
          {
            "id": 6,
            "title": {
              "en": `Range`,
              "hi": `श्रेणी`
            },
            "value": {
              "en": `<p>90 kms</p>`,
              "hi": `<p>90 कि.मी</p>`
            }
          },
          {
            "id": 7,
            "title": {
              "en": `Wheelbase`,
              "hi": `व्हीलबेस`
            },
            "value": {
              "en": `<p>2100 mm</p>`,
              "hi": `<p>2100 मिमी</p>`
            }
          }
        ]

      },
      {
        'id': 2,
        'productId': 2,
        'name': 'Competitor 2',
        'productName': 'Piaggio',
        'findingName': 'compatitor_2',
        'productImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/piaggio.png',
        'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/piaggio.png',
        'comments': 4,
        'documentsLinked': 8,
        'launchType': 'launch',
        'specifications': [
          {
            "id": 1,
            "title": {
              "en": `Delivery Box`,
              "hi": `डिलिवरी बॉक्स`
            },
            "value": {
              "en": `<p>Not available</p>`,
              "hi": `<p>उपलब्ध नहीं है</p>`
            }
          },
          {
            "id": 2,
            "title": {
              "en": `Peak Power`,
              "hi": `चरम शक्ति`
            },
            "value": {
              "en": `<p>8.25 KW</p>`,
              "hi": `<p>8.25 किलोवाट</p>`
            }
          },
          {
            "id": 3,
            "title": {
              "en": `Acceleration`,
              "hi": `त्वरण`
            },
            "value": {
              "en": `<p>Average</p>`,
              "hi": `<p>औसत</p>`
            }
          },
          {
            "id": 4,
            "title": {
              "en": `Gradeability`,
              "hi": `ग्रेडेबिलिटी`
            },
            "value": {
              "en": `<p>9.65°</p>`,
              "hi": `<p>9.65°</p>`
            }
          },
          {
            "id": 5,
            "title": {
              "en": `Warranty`,
              "hi": `गारंटी`
            },
            "value": {
              "en": `<p>3 yrs / 1,00,000 kms</p>`,
              "hi": `<p>3 वर्ष /1,00,000 किमी</p>`
            }
          },
          {
            "id": 6,
            "title": {
              "en": `Range`,
              "hi": `श्रेणी`
            },
            "value": {
              "en": `<p>100 kms</p>`,
              "hi": `<p>100 कि.मी</p>`
            }
          },
          {
            "id": 7,
            "title": {
              "en": `Wheelbase`,
              "hi": `व्हीलबेस`
            },
            "value": {
              "en": `<p>2140 mm</p>`,
              "hi": `<p>2140 मिमी</p>`
            }
          }
        ]
        ,
      },
      {
        'id': 3,
        'productId': 3,
        'name': 'Competitor 3',
        'productName': 'Altigreen',
        'findingName': 'compatitor_3',
        'productImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/altigreen.png',
        'orgCoverImage': 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/static-files/altigreen.png',
        'comments': 4,
        'documentsLinked': 8,
        'launchType': 'launch',
        'specifications': [
          {
            "id": 1,
            "title": {
              "en": `Delivery Box`,
              "hi": `डिलिवरी बॉक्स`
            },
            "value": {
              "en": `<p>Not available</p>`,
              "hi": `<p>उपलब्ध नहीं है</p>`
            }
          },
          {
            "id": 2,
            "title": {
              "en": `Peak Power`,
              "hi": `चरम शक्ति`
            },
            "value": {
              "en": `<p>10 KW</p>`,
              "hi": `<p>10 किलोवाट</p>`
            }
          },
          {
            "id": 3,
            "title": {
              "en": `Acceleration`,
              "hi": `त्वरण`
            },
            "value": {
              "en": `<p>Good</p>`,
              "hi": `<p>अच्छा</p>`
            }
          },
          {
            "id": 4,
            "title": {
              "en": `Gradeability`,
              "hi": `ग्रेडेबिलिटी`
            },
            "value": {
              "en": `<p>16°</p>`,
              "hi": `<p>16°</p>`
            }
          },
          {
            "id": 5,
            "title": {
              "en": `Warranty`,
              "hi": `गारंटी`
            },
            "value": {
              "en": `<p>5 yrs / 1,20,000 kms</p>`,
              "hi": `<p>5 वर्ष /1,20,000 किमी</p>`
            }
          },
          {
            "id": 6,
            "title": {
              "en": `Range`,
              "hi": `श्रेणी`
            },
            "value": {
              "en": `<p>130 kms</p>`,
              "hi": `<p>130 कि.मी</p>`
            }
          },
          {
            "id": 7,
            "title": {
              "en": `Wheelbase`,
              "hi": `व्हीलबेस`
            },
            "value": {
              "en": `<p>2274 mm</p>`,
              "hi": `<p>2274 मिमी</p>`
            }
          }
        ]

      },
    ]
  }
]

export const salesEnablementDataMahindra = {
  PRODUCT_DETAILS_NAVIGATION_STATUS,
  PRODUCT_LIST,
  PERSONA_LIST,
  PRODUCT_DETAIL_LIST,
  PRODUCT_FILES_LIST,
  RIVAL_PRODUCT_DETAILS
}