import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// mui
import CircularProgress from '@mui/material/CircularProgress';
import { Zoom, Slide } from '@mui/material';

// components
import StartScreen from "../common-screens/start-screen";
import HowToPlay from "../how-to-play/mrr-how-to-play";
import GbaGameOverScreen from "../common-screens/game-over-screen";
import GbaWinScreen from "../common-screens/win-screen";

// utils
import commonUtil from "../../../utils/commonUtil";
import isEmpty from "../../../utils/isEmpty";

// reduxreserstgrt
import { submitChallengeAttemptData, submitEndChallengeAttempt } from "../../../redux/gba/gba.api";
import { startChallengeAttempt } from "../../../redux/microskill/microskill.api";
import { handleGbaGameState } from "../../../redux/gba/gba-slice";

// constants
import constants from "../../../constants";

// theme
// import palette from "../../../theme/palette";

// Style
import useStyle from "./index.styles";

// const isHDScreen = commonUtil.getScreenResolution() === 'HD';
const GBA_SCREEN_STATE = constants.GBA_SCREEN_STATE;
const IMG_BUCKET_OUTLINE = '/images/gba/Popcorn_Match_Bucket_Outline.png';
const IMG_BUCKET_EMPTY = '/images/gba/Popcorn_Match_Bucket_Empty.png';
const IMG_BUCKET_FULL = '/images/gba/Popcorn_Match_Bucket_Full.png';

const doubleDownTapTime = 3;
const ballHeight = 10;
const spiralRotationTimeForBall = 6;

const LearnoCasino = (props) => {
    const { gameData, handleGbaState, isPauseOverlay, defaultGradient, totalQuestions, setAddPause } = props;
    const { questionData, designType, gameType } = gameData;
    const [activeIndex, setActiveIndex] = useState(0);
    const isTrueFalse = questionData[activeIndex].options.length === 2;
    const [questionIndex, setQuestionIndex] = useState(0);

    questionData[activeIndex].totalOptions = isTrueFalse ? questionData[activeIndex].totalOptions : questionData[activeIndex].totalOptions;
    const questionsLength = isEmpty(questionData[0]) ? 0 : questionData[0].length;
    const { microskillId, challengeId } = useParams();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const classes = useStyle(isMobile);
    const dispatch = useDispatch();
    const [isGamePage, setIsGamePage] = useState(false);
    const [isHowToPlayScreen, setIsHowToPlayScreen] = useState(false);
    const [animateChip, setAnimateChip] = useState(true);
    const [showMessage, setShowMessage] = useState(false);

    const [isGameComplete, setIsGameComplete] = useState(false);
    const [gameEndType, setGameEndType] = useState('');
    const [attemptData, setAttemptData] = useState('');
    const [showDealer1, setShowDealer1] = useState(false);
    const [showDealer2, setShowDealer2] = useState(false);
    const [incorrectData, setIncorrectData] = useState();

    // states to mane start screen assts preloading
    const [startScreenGameLogoLoaded, setStartScreenGameLogoLoaded] = useState(false);
    const [startScreenGameBgLoaded, setStartScreenGameBgLoaded] = useState(false);

    const progressBarRef = useRef();
    const learnoCasinoRootRef = useRef();
    const questionRef = useRef();
    const qstnBoxRef = useRef();
    const containerRef = useRef();
    const totalScoreRef = useRef(0);
    const timeRefText = useRef();
    const questionIndexRef = useRef(0);
    const lifeRef = useRef();
    const doubleDownTimer = useRef([]);
    const doubleDownTimerInerval = useRef();

    const lifeRefImg = useRef();
    const intervalRef = useRef();
    const isGamePausedRef = useRef(false);
    const firstUpdate = useRef(true);

    const finalChallengeDataRef = useRef();
    const allAttempCountRef = useRef(0);
    const attemptDataRef = useRef();
    const gradientRef = useRef();
    const lifeBoxRef = useRef();
    const questionNoRef = useRef();
    const circleContainerRef = useRef();
    const circleBoxWidthRef = useRef();
    const firstRollerRef = useRef();
    const secondRollerRef = useRef();
    const centerCircleRef = useRef();
    const isOptionClickAllowedRef = useRef(false);
    const ballRefList = useRef([]);
    const ballImageRef = useRef([]);
    const checkMarkRef = useRef();
    const crossMarkRef = useRef();
    const centerQuestionRef = useRef();
    const selectedItemRef = useRef();
    const selectedOptionIndexRef = useRef(0);
    const stopWatchRef = useRef();
    const divTimerRef = useRef();
    const gradientOverlayRef = useRef();
    const isTimerZeroRef = useRef(false);
    const isDoubleDownTapRef = useRef(false);

    let maxLifeGba = commonUtil.getQuestionAndLifeForGba({ gbaType: designType }).maxLife;
    let maxQuestion = commonUtil.getQuestionAndLifeForGba({ gbaType: designType }).maxQuestion;
    let maxLife = commonUtil.getMaxLifeForGba({
        gbaType: designType,
        questionsInGba: totalQuestions,
        maxQuestion: maxQuestion,
        maxLife: maxLifeGba,
    });

    // let maxLife = 6;
    const maxTime = 12;
    const mxmScore = 120;
    const chipSize = 27;

    let marksPerAnswer = mxmScore / totalQuestions;
    let bgGradientDefault = defaultGradient;
    const [fSize, setFSize] = useState(1);
    const lifeRefText = useRef(maxLife);

    const totalGBATimespentIntervalRef = useRef();
    const totalGBATimespent = useRef(0);

    let circleOffset = 110;
    const chipImageList = [
        {
            id: 'chip-1',
            imageUrl: '/images/gba/learno_casino_blue_chip.png',
            classNames: `${classes.chip} ${classes.chip1}`,
            classNamesBall: `${classes.ball} ${classes.ball1}`,
        },
        {
            id: 'chip-2',
            imageUrl: '/images/gba/learno_casino_pink_chip.png',
            classNames: `${classes.chip} ${classes.chip2}`,
            classNamesBall: `${classes.ball} ${classes.ball2}`,
        },
        {
            id: 'chip-3',
            imageUrl: '/images/gba/learno_casino_green_chip.png',
            classNames: `${classes.chip} ${classes.chip3}`,
            classNamesBall: `${classes.ball} ${classes.ball3}`,
        },
        {
            id: 'chip-4',
            imageUrl: '/images/gba/learno_casino_yellow_chip.png',
            classNames: `${classes.chip} ${classes.chip4}`,
            classNamesBall: `${classes.ball} ${classes.ball4}`,
        },
    ]

    useEffect(() => {
        setFSize(window.innerHeight / 961);
    }, []);

    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }
    window.addEventListener("resize", resFont);

    useEffect(() => {
        if (isEmpty(attemptData)) getChallengeDataAsync();
        return () => {
            clearInterval(intervalRef.current);
            clearInterval(totalGBATimespentIntervalRef.current);
        }
    }, [])

    useEffect(() => {
        if (isPauseOverlay) {
            isGamePausedRef.current = true;
            if (!firstUpdate.current) {
                handleBackButtonClick();
            }
        } else {
            if (firstUpdate.current) {
                firstUpdate.current = false;
                return
            }
            handleResumeClick();
        }
    }, [isPauseOverlay]);

    const handleBackButtonClick = () => {
        handleTimerPause();

        let timeSpent = totalGBATimespent.current;
        dispatch(handleGbaGameState({
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: Math.ceil(totalScoreRef.current),
            timespent: timeSpent
        }));
    }

    const handleTimerPause = () => {
        if (isEmpty(lifeRefText.current)) return
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
    }

    const handleResumeClick = () => {
        isGamePausedRef.current = false;
        setAddPause(true);
        startTimer();
    }

    useEffect(() => {
        if (isGamePage) {
            setAddPause(true);
            handleGbaState(GBA_SCREEN_STATE.GAME);
            startTimer();
            let width = circleContainerRef.current.clientWidth;
            let height = circleContainerRef.current.clientHeight;
            if (isEmpty(circleContainerRef.current)) return
            circleBoxWidthRef.current = width < height ? width : height;
            setTimeout(() => {
                firstRollerRef.current.classList.add(classes.rotateFirstRoller);
                secondRollerRef.current.classList.add(classes.rotateFirstRoller);
                setShowDealer1(true);
            }, 1000);

            setTimeout(() => {
                setShowDealer1(false);
            }, 5000);
        }
    }, [isGamePage]);

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    const getChallengeDataAsync = async () => {
        const attemptResp = await startChallengeAttempt({ microskillId, challengeId });
        setAttemptData(attemptResp.data.data);
        attemptDataRef.current = attemptResp.data.data;
    };

    useEffect(() => {
        if (isEmpty(attemptData)) return
        // set initial state
        let tempData = {
            ...finalChallengeDataRef.current,
            microskillId: attemptData.microskillId,
            challengeId: attemptData.challengeId,
            attemptId: attemptData.id,
            scored: isEmpty(totalScoreRef.current) ? 0 : Math.ceil(totalScoreRef.current),
            status: constants.GBA_ILA_STATUS.exited,
        }
        dispatch(handleGbaGameState(tempData));
    }, [attemptData])

    const startTimer = () => {
        clearInterval(intervalRef.current);
        let timer = new Date();

        clearInterval(totalGBATimespentIntervalRef.current);
        totalGBATimespentIntervalRef.current = setInterval(() => {
            if (isEmpty(timeRefText.current) || isGamePausedRef.current) {
                clearInterval(totalGBATimespentIntervalRef.current);
                return
            }
            setAddPause(true);
            totalGBATimespent.current += 1;
        }, 1000);

        intervalRef.current = setInterval(async () => {
            if (isEmpty(timeRefText.current) || isGamePausedRef.current) return
            let tempTimeValue = parseInt(timeRefText.current.innerHTML);
            isOptionClickAllowedRef.current = true;

            if (tempTimeValue === 0) {
                clearInterval(intervalRef.current);
                isOptionClickAllowedRef.current = false;
                isTimerZeroRef.current = true;
                handleTimerZero();
                // setShowMessage(true);
                return
            }

            timeRefText.current.innerHTML = tempTimeValue - 1;
            let runningTime = timeRefText.current.innerHTML;

            if (runningTime <= 5) {
                if (!isEmpty(divTimerRef.current)) divTimerRef.current.style.color = '#FF5757';
                if (!isEmpty(stopWatchRef.current)) stopWatchRef.current.src = "/images/icons/stopwatch-red.png";
            } else {
                if (!isEmpty(divTimerRef.current)) divTimerRef.current.style = null;
                if (!isEmpty(stopWatchRef.current)) stopWatchRef.current.src = "/images/icons/stopwatch.png";
            }
        }, 1000);
    }

    const handleOptionClick = async (item, optionIndex) => {
        if (!isOptionClickAllowedRef.current) return;
        isOptionClickAllowedRef.current = false;
        clearInterval(intervalRef.current) // stop timer
        selectedItemRef.current = item;
        selectedOptionIndexRef.current = optionIndex;
        setShowDealer2(true);

        // add to the attempt
        allAttempCountRef.current += 1;

        // highlighting selected chip
        let chipEl = document.getElementById(chipImageList[optionIndex].id);
        chipEl.style.filter = 'drop-shadow(0px 0px 4px #fff)';

        // highlighting selected main chip
        let mainChipEl = document.getElementById(`main-chip-${chipImageList[selectedOptionIndexRef.current].id}`);
        mainChipEl.style.filter = 'drop-shadow(0px 0px 4px #fff)';

        // hiding not selected chip
        chipImageList.forEach(i => {
            if (i.id !== chipImageList[optionIndex].id) {
                // if (!isEmpty(document.getElementById(i.id))) {
                document.getElementById(i.id).style.display = 'none';
                // }
            }
        })

        // show double down timer
        let doubleDownEl = document.getElementById(`double-down-${item.questionId}`);
        await sleep(100);
        doubleDownEl.style.display = 'grid';
        doubleDownTimerInerval.current = setInterval(async () => {
            let timeValue = parseInt(doubleDownTimer.current[optionIndex].innerHTML) - 1;
            doubleDownTimer.current[optionIndex].innerHTML = timeValue;
            if (timeValue === 0) clearInterval(doubleDownTimerInerval.current)
        }, 1000);
        await sleep(doubleDownTapTime * 1000);
        doubleDownEl.style = null;
        setTimeout(() => {
            setShowDealer2(false);
        }, 1000);
        handleTransition(item, optionIndex);
    }

    const handleTransition = (item, optionIndex) => {
        // selectedIndexRef.current = optionIndex;
        // rotate tha ball in spiral and rotate the rollers
        firstRollerRef.current.classList.add(classes.rotateFirstRoller);
        secondRollerRef.current.classList.add(classes.rotateFirstRoller);
        firstRollerRef.current.style.animationDuration = '1.8s';
        secondRollerRef.current.style.animationDuration = '1.8s';
        setTimeout(() => {
            if (!isEmpty(firstRollerRef.current)) firstRollerRef.current.style.animationDuration = '3s';
            if (!isEmpty(secondRollerRef.current)) secondRollerRef.current.style.animationDuration = '3s';
            // firstRollerRef.current.classList.add(classes.rotateSecondRoller);
            // secondRollerRef.current.classList.add(classes.rotateSecondRoller);
        }, 2000);
        ballImageRef.current.classList.add(classes.spiralRotation);

        // let secondRollerHeight = secondRollerRef.current.clientHeight;
        let firstRollerHeight = firstRollerRef.current.clientHeight;
        let centerCircleHeight = centerCircleRef.current.clientHeight;

        const styleElement = document.createElement('style');
        document.head.appendChild(styleElement);

        let isCorrectAnswer = item.answer.trim().toLowerCase() === questionData[activeIndex].options[questionIndex].answer.trim().toLowerCase()
        let yTranslateAtZero = 'translateY(0)';
        let xTranslateAtZero = 'translatex(0)';
        let yTranslateAtHundred = 'translateY(0)';
        let xTranslateAtHundred = 'translateX(0)';
        let randomNo = null;
        if (!isCorrectAnswer) randomNo = commonUtil.randomNo(0, 3, [selectedOptionIndexRef.current]);
        else randomNo = selectedOptionIndexRef.current;

        if (randomNo % 2 === 0) {
            // 0  2
            let isNegativeTranslate = randomNo === 0 ? '-' : '+';
            yTranslateAtZero = `translateY(${isNegativeTranslate}${firstRollerHeight / 2}px)`;
            yTranslateAtHundred = `translateY(${isNegativeTranslate}${(centerCircleHeight / 2) + (ballHeight)}px)`;
        } else {
            // 1  3
            let isNegativeTranslate = randomNo === 1 ? '+' : '-';
            xTranslateAtZero = `translateX(${isNegativeTranslate}${firstRollerHeight / 2}px)`;
            xTranslateAtHundred = `translateX(${isNegativeTranslate}${(centerCircleHeight / 2) + (ballHeight)}px)`;
        }

        const keyframes = `@-webkit-keyframes spiralRotationAnimation {
            0% {  transform: translate(-50%, -50%) rotate(0deg) ${xTranslateAtZero} ${yTranslateAtZero};  }
            100% { transform: translate(-50%, -50%) rotate(2160deg) ${xTranslateAtHundred} ${yTranslateAtHundred}; }
        }`;

        styleElement.appendChild(document.createTextNode(keyframes));

        setTimeout(() => {
            if (!isEmpty(firstRollerRef.current)) firstRollerRef.current.classList.remove(classes.rotateFirstRoller);
            if (!isEmpty(secondRollerRef.current)) secondRollerRef.current.classList.remove(classes.rotateFirstRoller);
            if (!isEmpty(firstRollerRef.current)) firstRollerRef.current.classList.remove(classes.rotateSecondRoller);
            if (!isEmpty(secondRollerRef.current)) secondRollerRef.current.classList.remove(classes.rotateSecondRoller);
        }, (spiralRotationTimeForBall + 0.5) * 1000);
        ballImageRef.current.addEventListener("animationstart", ballRollingStarted, false);
        ballImageRef.current.addEventListener("animationend", ballRollingEnded, false);
        // ballImageRef.current.addEventListener("animationiteration", ballRollingIteration, false);
        ballImageRef.current.style.animation = `spiralRotationAnimation ${spiralRotationTimeForBall}s forwards`;
    }

    const ballRollingStarted = () => {
    }

    const ballRollingEnded = async () => {
        let item = selectedItemRef.current;
        // let optionIndex = selectedIndexRef.current;
        centerQuestionRef.current.style.opacity = '0';

        let tempReq = {
            qId: questionData[activeIndex].options[questionIndex].questionId,
            aId: item.answerId,
            isCorrect: false,
            t: maxTime - parseInt(timeRefText.current.innerHTML),
            skillId: questionData[activeIndex].options[questionIndex].skillId,
            contextId: questionData[activeIndex].options[questionIndex].contextId,
        };

        let textEl = document.getElementById(`answerType-${chipImageList[selectedOptionIndexRef.current].id}`);
        //show text

        let el = document.getElementById(`chip-box-${selectedItemRef.current.questionId}`);
        let imagTag = `<img src='${chipImageList[selectedOptionIndexRef.current].imageUrl}'
            width="${chipSize}"
            class="disableSelection disablePointerEvents"
            id="${chipImageList[selectedOptionIndexRef.current].id}"/>`
        el.innerHTML = `${imagTag}`;

        //hide chip
        document.getElementById(chipImageList[selectedOptionIndexRef.current].id).style.display = 'none';

        if (item.answer.trim().toLowerCase() === questionData[activeIndex].options[questionIndex].answer.trim().toLowerCase()) {
            totalScoreRef.current += marksPerAnswer;
            centerCircleRef.current.style.backgroundColor = 'green'
            checkMarkRef.current.style.opacity = '1';

            // add correct text to selected chip
            textEl.innerHTML = "CORRECT!";
            textEl.style.display = "flex";
            // document.getElementById(`chip-box-${item.questionId}`).style.display = 'CORRECT!';

            // analytics
            tempReq.isCorrect = true;
            handleChallengeAttemptData(tempReq);
        } else {
            handleLifeLoss()
            centerCircleRef.current.style.backgroundColor = 'red'
            crossMarkRef.current.style.opacity = '1';

            // add correct text to selected chip
            textEl.innerHTML = "WRONG!";
            textEl.style.display = "flex";

            // analytics
            tempReq.isCorrect = false;
            handleChallengeAttemptData(tempReq);
        }
        resetAllVariables(selectedOptionIndexRef.current);
        // ballImageRef.current.removeEventListener("animationiteration", ballRollingIteration, false);
    }

    const handleDoubleDownTap = async (e) => {
        let doubleDownEl = document.getElementById(`${e.target.parentNode.id}`);
        if (isEmpty(doubleDownEl)) return
        await sleep(100);
        isDoubleDownTapRef.current = true;
        doubleDownEl.style.display = 'none';
        let el = document.getElementById(`chip-box-${selectedItemRef.current.questionId}`);
        let imagTag = `<img src='${chipImageList[selectedOptionIndexRef.current].imageUrl}'
            width="${chipSize}"
            class="disableSelection disablePointerEvents"
            style="filter :drop-shadow(0px 0px 4px #fff) ";/>`
        el.innerHTML = `${imagTag} ${imagTag}`;
    }

    const resetAllVariables = async (optionIndex) => {
        await sleep(1000);
        setAnimateChip(false);
        setShowMessage(false);
        await sleep(1000);
        centerCircleRef.current.style = null;
        removeListners();
        resetStyle(selectedOptionIndexRef.current);
        setAnimateChip(true);
        centerQuestionRef.current.style = null;
        handleNextQuestion();
        isDoubleDownTapRef.current = false; // to fix double life loss after correct attempt on double down
        isOptionClickAllowedRef.current = true;
    }

    const resetStyle = () => {
        let chipEl = document.getElementById(chipImageList[selectedOptionIndexRef.current].id);
        if (!isEmpty(chipEl)) chipEl.style = null;
        if (!isEmpty(crossMarkRef.current)) crossMarkRef.current.style = null;
        if (!isEmpty(checkMarkRef.current)) checkMarkRef.current.style = null;
        if (!isEmpty(centerCircleRef.current)) centerCircleRef.current.style = null;
        if (!isEmpty(ballImageRef.current)) ballImageRef.current.classList.remove(classes.spiralRotation);
        if (!isEmpty(centerQuestionRef.current)) {
            centerQuestionRef.current.style.opacity = '1';
        }

        let mainChipEl = document.getElementById(`main-chip-${chipImageList[selectedOptionIndexRef.current]?.id}`);
        if (!isEmpty(mainChipEl)) mainChipEl.style = null;

        chipImageList.forEach(i => {
            let mainChipEl = document.getElementById(`main-chip-${i.id}`);
            if (!isEmpty(mainChipEl)) mainChipEl.style = null;
            if (!isEmpty(document.getElementById(`${i.id}`))) document.getElementById(`${i.id}`).style.display = 'block';
            if (!isEmpty(document.getElementById(i.id))) document.getElementById(i.id).style = null;

            let textEl = document.getElementById(`answerType-${i.id}`);
            if (!isEmpty(textEl)) textEl.style.display = 'none';
            if (document.getElementById(i.id)) document.getElementById(i.id).style.display = 'block';
            // if (i.id != chipImageList[selectedOptionIndexRef.current].id) {
            //     // if (!isEmpty(document.getElementById(i.id))) document.getElementById(i.id).style.display = 'block';
            // } else {
            //     let el = document.getElementById(`chip-box-${selectedItemRef.current?.questionId}`);
            //     if (isEmpty(el)) return
            //     el.innerHTML = `<img src='${chipImageList[selectedOptionIndexRef.current].imageUrl}' width="${chipSize}" id='${chipImageList[selectedOptionIndexRef.current].id}' />`
            // }
        });

        if (!isEmpty(firstRollerRef.current)) firstRollerRef.current.classList.add(classes.rotateFirstRoller);
        if (!isEmpty(secondRollerRef.current)) secondRollerRef.current.classList.add(classes.rotateFirstRoller);
        if (!isEmpty(ballImageRef.current)) {
            ballImageRef.current.classList.remove(classes.spiralRotation);
            ballImageRef.current.style = null;
        }
        if (!isEmpty(doubleDownTimer.current[selectedOptionIndexRef.current])) {
            doubleDownTimer.current[selectedOptionIndexRef.current].innerHTML = doubleDownTapTime;
        }
    }

    const removeListners = () => {
        if (isEmpty(ballImageRef.current)) return
        ballImageRef.current.removeEventListener("animationstart", ballRollingStarted, false);
        ballImageRef.current.removeEventListener("animationend", ballRollingEnded, false);
    }

    const handleNextQuestion = () => {
        // setQuestionIndex(questionIndex + 1);
        // return
        if (!isEmpty(divTimerRef.current)) divTimerRef.current.style = null;
        if (!isEmpty(stopWatchRef.current)) stopWatchRef.current.src = "/images/icons/stopwatch.png";
        if (parseInt(lifeRefText.current.innerHTML) === 0) {
            handleEndOfGamesDueToLifeLoss();
            return
        } else if (activeIndex === questionData.length - 1) {

        }

        let acIndex = activeIndex;
        if (questionIndexRef.current === questionData[activeIndex].options.length - 1) {
            if (activeIndex === questionData.length - 1) {
                handleEndOfQuestions();
                return
            }
            timeRefText.current.innerHTML = maxTime;
            startTimer();
            acIndex = activeIndex + 1;
            setQuestionIndex(0);
        } else if (questionIndexRef.current < questionData[activeIndex].options.length - 1) {
            timeRefText.current.innerHTML = maxTime;
            startTimer();
            setQuestionIndex(prev => prev + 1);
        }
        setActiveIndex(acIndex);
    }

    useEffect(() => {
        questionIndexRef.current = questionIndex;
    }, [questionIndex])

    useEffect(() => {
        if (allAttempCountRef.current === 0) return
        handleProgressBar();
    }, [allAttempCountRef.current])

    const handleLifeLoss = () => {
        if (parseInt(lifeRefText.current.innerHTML) === 0) return
        lifeBoxRef.current.classList.add(`${classes.blinkAnimationEffect}`);
        setTimeout(() => {
            lifeBoxRef.current.classList.remove(`${classes.blinkAnimationEffect}`);
        }, 750)

        let lifeValue = parseInt(lifeRefText.current.innerHTML) - (isDoubleDownTapRef.current ? 2 : 1);
        // let lifeValue = parseInt(lifeRefText.current.innerHTML) - 1
        if (lifeValue < 0) lifeValue = 0;
        lifeRefText.current.innerHTML = lifeValue;
        isDoubleDownTapRef.current = false;
        if (lifeValue === 1) {
            // make the life red
            lifeRef.current.style.color = '#FF5757';
            lifeRefImg.current.src = '/images/icons/heart-red.png';
            lifeBoxRef.current.classList.add(`${classes.blinkAnimationEffect}`);
            gradientRef.current.classList.add(`${classes.blinkAnimationEffect}`);
            qstnBoxRef.current.classList.add(`${classes.redBorderBlink}`);
            // learnoCasinoRootRef.current.classList.add(classes.wrongAnswerAnimation);
        }
        return lifeValue;
    }

    const handleTimerZero = async () => {
        centerQuestionRef.current.innerHTML = "<img src='/images/gba/learno_casino_timeout.png' style='height:100%' class=`disableSelection disablePointerEvents` />";
        centerCircleRef.current.style.backgroundColor = 'red';
        // centerQuestionRef.current.style.fontFamily = "MotionControl";
        // centerQuestionRef.current.style.fontSize = '3.5em';
        handleLifeLoss();
        let tempReq = {
            qId: questionData[activeIndex].options[questionIndex].questionId,
            aId: null,
            isCorrect: false,
            t: maxTime - parseInt(timeRefText.current.innerHTML),
            skillId: questionData[activeIndex].options[questionIndex].skillId,
            contextId: questionData[activeIndex].options[questionIndex].contextId,
        };
        handleChallengeAttemptData(tempReq);
        allAttempCountRef.current += 1;

        await sleep(1000);
        resetAllVariables(1);
    }

    const handleEndOfGamesDueToLifeLoss = async () => {
        isOptionClickAllowedRef.current = false;
        totalScoreRef.current = Math.ceil(totalScoreRef.current)
        handleGbaState(GBA_SCREEN_STATE.LOOSE);
        setGameEndType('loose');
        setIsGameComplete(true);
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);

        // analytics
        const submitEndChallengeAttemptData = await submitEndChallengeAttempt({
            ...finalChallengeDataRef.current,
            attemptId: attemptData.id,
            scored: totalScoreRef.current,
            completion: false,
            status: "LIFELOSE",
            timespent: totalGBATimespent.current
        });
        setIncorrectData(submitEndChallengeAttemptData?.data?.data?.consecutiveIncorrectAttempt);
    }

    const handleEndOfQuestions = () => {
        totalScoreRef.current = Math.ceil(totalScoreRef.current);
        handleGbaState(GBA_SCREEN_STATE.WIN);
        setGameEndType('win');
        setIsGameComplete(true);
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);

        // analytics
        submitEndChallengeAttempt({ ...finalChallengeDataRef.current, attemptId: attemptData.id, scored: totalScoreRef.current, completion: true, status: "COMPLETED", timespent: totalGBATimespent.current })
    }

    useEffect(() => {
        if (isGameComplete) {
            gradientRef.current.classList.remove(`${classes.blinkAnimationEffect}`);
        }
    }, [isGameComplete])

    const restartGame = async () => {
        clearInterval(intervalRef.current);
        clearInterval(totalGBATimespentIntervalRef.current);
        setGameEndType();
        setActiveIndex(0);
        setQuestionIndex(0);
        totalScoreRef.current = 0;
        totalGBATimespent.current = 0;
        setIsGameComplete(false);
        getChallengeDataAsync();
        isOptionClickAllowedRef.current = false;
        resetStyle();
        setIsGamePage(false);
        allAttempCountRef.current = 0;
        await sleep(10);
        setIsGamePage(true);
    }

    const handleChallengeAttemptData = (params) => {
        let { qId, aId, isCorrect, t, skillId, contextId } = params;
        let questionObj = {
            questionId: qId,
            skillId: skillId,
            contextId: contextId,
            answers: [{
                answerId: aId,
                correct: isCorrect
            }],
            timespent: t
        };
        let req = {
            microskillId: microskillId,
            challengeId: challengeId,
            attemptId: attemptData.id,
            questions: [questionObj]
        }
        submitChallengeAttemptData(req);
        handleEndChallengeAttempt(questionObj, req, skillId, contextId)
    }

    // const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
    //     let tempQuestionObj = {
    //         ...questionObj,
    //         skillId: skillId,
    //         contextId: contextId,
    //     }
    //     let totalTimeSpent = (isEmpty(finalChallengeDataRef.current) ? 0 : finalChallengeDataRef.current.timespent) + tempQuestionObj.timespent;
    //     if (isEmpty(finalChallengeDataRef.current)) {
    //         finalChallengeDataRef.current = {
    //             ...req,
    //             questions: [tempQuestionObj],
    //             completion: true,
    //             status: constants.GBA_ILA_STATUS.completed,
    //             timespent: totalTimeSpent,
    //             scored: Math.ceil(totalScoreRef.current),
    //             fallbackSave: true,
    //         }
    //     } else {
    //         finalChallengeDataRef.current.questions.push(tempQuestionObj)
    //         finalChallengeDataRef.current = {
    //             ...finalChallengeDataRef.current,
    //             timespent: totalTimeSpent,
    //         }
    //     }
    // }

    const handleEndChallengeAttempt = (questionObj, req, skillId, contextId) => {
        const tempQuestionObj = {
            ...questionObj,
            skillId: skillId,
            contextId: contextId,
        };

        // Ensure current challenge data is initialized
        const currentChallengeData = finalChallengeDataRef.current || {};

        // Calculate total time spent
        const totalTimeSpent = (isEmpty(currentChallengeData) ? 0 : (currentChallengeData.timespent || 0)) + tempQuestionObj.timespent;

        if (isEmpty(currentChallengeData)) {
            // Initialize finalChallengeDataRef.current
            finalChallengeDataRef.current = {
                ...req,
                questions: [tempQuestionObj],
                completion: true,
                status: "COMPLETED",
                timespent: totalTimeSpent,
                scored: Math.round(totalScoreRef.current),
                fallbackSave: true,
            };
        } else {
            // Use a new array for questions to avoid mutation
            const updatedQuestions = [...(currentChallengeData.questions || []), tempQuestionObj];
            finalChallengeDataRef.current = {
                ...currentChallengeData,
                questions: updatedQuestions,
                timespent: totalTimeSpent,
            };
        }
    }

    const handleProgressBar = () => {
        let width = (parseInt(allAttempCountRef.current) / questionsLength) * 100;
        if (isEmpty(progressBarRef.current)) return
        progressBarRef.current.style.width = `${width}%`;
        // if (questionsLength >= currentIndex.current) {
        //     questionNoRef.current.innerHTML = currentIndex.current;
        // }
    }

    return (
        <div style={(startScreenGameLogoLoaded && startScreenGameBgLoaded) ? { fontSize: `${fSize}rem` } : { backgroundImage: 'linear-gradient(#000, #000)' }} className={classes.learnoCasinoRoot} ref={learnoCasinoRootRef}>
            <div className={classes.gradientBox} ref={gradientRef} />
            {
                isEmpty(attemptData) ? (
                    <div style={constants.FULL_HEIGHT_CENTER}>
                        <CircularProgress />
                    </div>
                ) : (
                    !isGamePage ? (
                        !isHowToPlayScreen ? (
                            <div className={classes.startPage}>
                                <StartScreen
                                    setIsGamePage={setIsGamePage}
                                    setIsHowToPlayScreen={setIsHowToPlayScreen}
                                    gradientBg={bgGradientDefault}
                                    bg={"/images/gba/learno_casino_bg.png"}
                                    gameLogo="/images/gba/learno_casino_logo.png"
                                    handleGbaState={handleGbaState}

                                    startScreenGameLogoLoaded={startScreenGameLogoLoaded}
                                    setStartScreenGameLogoLoaded={setStartScreenGameLogoLoaded}
                                    startScreenGameBgLoaded={startScreenGameBgLoaded}
                                    setStartScreenGameBgLoaded={setStartScreenGameBgLoaded}
                                />
                            </div>
                        ) : (
                            <HowToPlay
                                // headerBg={headerBg}
                                qstnBoxBg={'#000000'}
                                qstnImg={'/images/gba/popcorn_perfo_rd.png'}
                                answerImg={IMG_BUCKET_EMPTY}
                                animationImg={IMG_BUCKET_FULL}
                                headerColor='#a34735'
                                setIsGamePage={setIsGamePage}
                                gameType='popcornMatch' />
                        )
                    ) : (
                        !isGameComplete ? (
                            <>
                                <div className={classes.incorrectGradientOverlay} ref={gradientOverlayRef} />
                                <div className={classes.container} ref={containerRef}>
                                    <div className={classes.progressBarContainer}>
                                        <div className={classes.progressBar} ref={progressBarRef} />
                                    </div>

                                    {/* QUESTION BOX */}
                                    <div className={classes.qstnContainer}>
                                        <div className={classes.qstnBox} ref={qstnBoxRef}>
                                            <div className={classes.assetBox}>
                                                <div className={classes.lifeText} ref={lifeBoxRef}>
                                                    <div className={classes.life} ref={lifeRef}>
                                                        <span ref={lifeRefText}>{maxLife}</span> x
                                                    </div>
                                                    <img src='/images/icons/heart.png' className={`disableSelection disablePointerEvents ${classes.icon}`} alt="heart" ref={lifeRefImg} />
                                                </div>
                                                <div className={classes.questionNo}>Q<span ref={questionNoRef}>{activeIndex + 1}</span>/{questionData.length}</div>
                                                <div className={classes.timerText}>
                                                    <img src='/images/icons/stopwatch.png' className={`disableSelection disablePointerEvents ${classes.timerIcon}`} alt="timer" ref={stopWatchRef} />
                                                    <div className={classes.time} ref={divTimerRef}><span ref={timeRefText}>{maxTime}</span>s</div>
                                                </div>
                                            </div>
                                            <div className={classes.qstn} ref={questionRef}>
                                                <div>{questionData[activeIndex].context}</div>
                                                {/* <Zoom in={animateChip} timeout={{ enter: 800, exit: 400 }}>
                                                    <div className={classes.questionText}>{questionIndex}-{questionData[activeIndex].options[questionIndex].question}</div>
                                                </Zoom> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.gameContanier}>
                                        <Slide direction="right" in={showDealer1} timeout={{ enter: 400, exit: 1000 }}>
                                            <img src="/images/gba/learno_casino_dealer_new_1.png" alt="circle" className={`disableSelection disablePointerEvents ${classes.dealerImage}`} />
                                        </Slide>
                                        <Slide direction="right" in={showDealer2} timeout={{ enter: 400, exit: 1000 }}>
                                            <img src="/images/gba/learno_casino_dealer_new_2.png" alt="circle" className={`disableSelection disablePointerEvents ${classes.dealerImage}`} />
                                        </Slide>

                                        <div className={`${classes.circleContainer} ${classes.centerFlex}`} ref={circleContainerRef}>
                                            <div className={classes.circleBox}
                                                style={{
                                                    // width: `calc(${circleBoxWidthRef.current - circleOffset}px - 10%)`,
                                                    // height: `calc(${circleBoxWidthRef.current - circleOffset}px -10%)`
                                                    width: `${circleBoxWidthRef.current - circleOffset}px`,
                                                    height: `${circleBoxWidthRef.current - circleOffset}px`
                                                }}
                                            >
                                                <div className={classes.rollerContainer}>
                                                    <img src="/images/gba/learno_casino_first_roller.png" alt="circle"
                                                        className={`disableSelection disablePointerEvents ${classes.firstRoller} ${classes.roller}`}
                                                        ref={firstRollerRef}
                                                    />
                                                    <img src="/images/gba/learno_casino_second_roller.png" alt="circle"
                                                        className={`disableSelection disablePointerEvents ${classes.secondRoller} ${classes.roller} `}
                                                        ref={secondRollerRef}
                                                    />
                                                </div>
                                                <div className={`${classes.centerCircle} ${classes.centerFlex}`} ref={centerCircleRef}>
                                                    <Zoom in={animateChip} timeout={{ enter: 800, exit: 400 }}>
                                                        <div ref={centerQuestionRef} className={classes.centerQText}>
                                                            {questionData[activeIndex].options[questionIndex].question}
                                                        </div>
                                                    </Zoom>
                                                    <img src="/images/gba/check.png" alt="check" className={`disableSelection disablePointerEvents ${classes.checkMark}`} ref={checkMarkRef} />
                                                    <img src="/images/gba/cross.png" alt="cross" className={`disableSelection disablePointerEvents ${classes.checkMark}`} ref={crossMarkRef} />
                                                </div>
                                                <div className={classes.ballContainer}>
                                                    {
                                                        chipImageList.map((item, index) => (
                                                            <div key={`ball-${item.id}`} className={`${item.classNamesBall}`}
                                                                ref={ref => (ballRefList.current[index] = ref)} style={{ height: `${ballHeight}px`, width: `${ballHeight}px` }} />
                                                        ))
                                                    }
                                                    <img src="/images/gba/learno_casino_rotating_ball.png" alt="ball icon" className={`disableSelection disablePointerEvents ${classes.ballImage}`} width={ballHeight} ref={ballImageRef} />
                                                </div>
                                                <div className={classes.chipContainer}>
                                                    {
                                                        chipImageList.map(item => (
                                                            <img key={item.id}
                                                                id={`main-chip-${item.id}`}
                                                                src={item.imageUrl}
                                                                width={chipSize}
                                                                className={`disableSelection disablePointerEvents ${item.classNames}`}
                                                            />
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.optionContainer}
                                            style={{ gridTemplateColumns: `repeat(${questionData[activeIndex].options.length}, 1fr)` }}>
                                            {
                                                questionData[activeIndex].options.map((item, index) => (
                                                    <div className={classes.optionBox}
                                                        key={item.questionId}
                                                        id={`option-box-${item.questionId}`}
                                                        onClick={() => handleOptionClick(item, index)}
                                                    >
                                                        <div className={`${classes.chipBox} ${classes.centerFlex}`}>
                                                            {
                                                                !isEmpty(chipImageList[index]) && (
                                                                    <>
                                                                        <Zoom in={animateChip} timeout={{ enter: 800, exit: 400 }}>
                                                                            <div className={classes.childChipBox} id={`chip-box-${item.questionId}`}>
                                                                                <img src={chipImageList[index].imageUrl} width={27}
                                                                                    id={chipImageList[index].id}
                                                                                    className={`disableSelection disablePointerEvents`}
                                                                                />
                                                                            </div>
                                                                        </Zoom>
                                                                        <div className={classes.answerType}
                                                                            id={`answerType-${chipImageList[index].id}`}>
                                                                            CORRECT !
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                        <div className={`${classes.optionText} ${classes.centerFlex}`} >
                                                            {item.answer}
                                                        </div>
                                                        {!isEmpty(chipImageList[index]) && (
                                                            // <DoubleDown id={`double-down-${item.questionId}`} index={index} />
                                                            <div id={`double-down-${item.questionId}`}
                                                                className={`${classes.doubleDownTextBox}`}
                                                                style={{ display: 'none' }}>
                                                                <div className={classes.doubleDownText}>DOUBLE DOWN?</div>
                                                                <div className={classes.doubleDownCount} ref={ref => (doubleDownTimer.current[index] = ref)}>{doubleDownTapTime}</div>
                                                                <div className={classes.doubleDownText2}>TAP NOW! </div>
                                                                <div className={classes.overlayBox}
                                                                    onClick={handleDoubleDownTap} />
                                                            </div>
                                                        )}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {
                                    gameEndType === 'win' && (
                                        <GbaWinScreen
                                            maxScore={mxmScore}
                                            bg={""}
                                            obtainedScore={Math.ceil(totalScoreRef.current)} />
                                    )
                                }
                                {
                                    gameEndType === 'loose' && (
                                        <GbaGameOverScreen
                                            type={"gameover"}
                                            gameType={gameType}
                                            gameData={gameData}
                                            incorrectAttempt={incorrectData}
                                            bg={""}
                                            restartGame={restartGame}
                                        />
                                    )
                                }
                                {
                                    gameEndType === 'timeup' && (
                                        <GbaGameOverScreen
                                            type={"time"}
                                            bg={""}
                                            restartGame={restartGame}
                                        />
                                    )
                                }
                            </>
                        )
                    )
                )
            }

            {
                showMessage && (
                    <Zoom in={showMessage} timeout={{ enter: 400, exit: 400 }}>
                        <div className={`${classes.showMessageRoot} ${classes.centerFlex}`}>
                            TIME UP
                        </div>
                    </Zoom>
                )
            }
        </div >
    )
};

export default LearnoCasino;
