import React from 'react';

// mui
import EmojiEventsSharpIcon from '@mui/icons-material/EmojiEventsSharp';

// styles
import useStyles from "./index.styles"
import { useTranslation } from 'react-i18next';

const CompletionProgress = ({ completionPercentage, fontSize = '0.6rem', fontWeight = '700' }) => {
    const classes = useStyles();
    const { t } = useTranslation()
    return (
        <div className={classes.completionRoot}>
            <div><EmojiEventsSharpIcon sx={{ color: '#ffb330', fontSize: `calc(${fontSize} + 0.3rem)` }} /></div>
            <div className={`${classes.centerFlex} ${classes.completionPercentage}`}
                style={{ fontSize: fontSize, fontWeight: fontWeight }}
            >
                {completionPercentage} {t("complete")}
            </div>
        </div>
    )
}

export default CompletionProgress