import React, { useEffect, useState, useRef, useContext } from "react";
import { useSelector } from "react-redux";

// context
import LearningAidContext from "../../../context/learningAidContext";

// mui
import { Button, CircularProgress } from "@mui/material";

// components
import ScoreBox from "../score-box";

// redux
// import { getChallengeData } from "../../redux/microskill/microskill.api";

// utils
import isEmpty from "../../../utils/isEmpty";
// import commonUtil from "../../../utils/commonUtil";

// constants
import constants from "../../../constants";

// theme
import palette from "../../../theme/palette";

// styles
import useStyles from "./index.styles";


// lottie

const serialAlpha = ['A', 'B', 'C', 'D']

const IlaMcqOne = (props) => {
    const {
        data,
        prev,
        next,
        attemptedIds,
        ilaId,
        scoreListRef,
        addScore,
        maxScore,
        scoreValueRef,
        scoreRef,
        handleChallengeAttemptData,
        styles,
        CorrectTemplate,
        IncorrectTemplate,
        SkipButton,
        isChallengeSubmitted,
        skipButtonDisplayTime,
        cardId
    } = props
    // console.log("IlaMcqOne:", JSON.stringify(data))s
    const { isClickEnabledRef } = useContext(LearningAidContext)
    const classes = useStyles();
    const isMobile = useSelector(state => state?.common?.isMobile);
    // const { microskillId, challengeId } = useParams();
    const [activeQindex, setActiveQindex] = useState(0);
    const [showTemplate, setShowTemplate] = useState(false);
    const [viewSkipBtn, setViewSkipBtn] = useState(false);
    const [componentMounted, setComponentMounted] = useState(true);
    const ilaRootRef = useRef();
    const ilaBoxRef = useRef();
    const scoreBoxRef = useRef();
    const isCorrectRef = useRef(false);
    const currentTimeRef = useRef(Date.now());
    const optionsBoxRef = useRef();
    let tansitionMs = 1000;

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    useEffect(() => {
        return () => {
            setComponentMounted(false)
        }
    }, []);

    useEffect(() => {
        isClickEnabledRef.current = true;
    }, [activeQindex])

    useEffect(() => {
        if (!isChallengeSubmitted || !componentMounted) return
        setTimeout(() => {
            setViewSkipBtn(true)
        }, skipButtonDisplayTime);
    }, [isChallengeSubmitted]);

    const handleOptionClick = async (item, index) => {
        if (!isClickEnabledRef.current) return
        isClickEnabledRef.current = false;
        let showScore = data[activeQindex].score > 0;

        // check if the user is again answering the same ILA, then remove that attempted ila id
        let indexToSplice = attemptedIds.current.indexOf(ilaId);
        if (indexToSplice !== -1) {
            attemptedIds.current.splice(indexToSplice, 1);
        }

        // handle score logic
        let currentTime = Date.now();
        if (item.isCorrect) {
            isCorrectRef.current = true;
            scoreListRef.current.add(`${data[activeQindex].id}-${data[activeQindex].score}`);

            handleChallengeAttemptData({
                qId: item.id,
                cardId: cardId,
                t: Math.round((currentTime - currentTimeRef.current) / 1000),
                aId: item.id,
                isCorrect: true
            });
        } else {
            isCorrectRef.current = false;
            handleChallengeAttemptData({
                qId: item.id,
                cardId: cardId,
                t: Math.round((currentTime - currentTimeRef.current) / 1000),
                aId: item.id,
                isCorrect: false
            });
        }
        currentTimeRef.current = currentTime;
        data[activeQindex].answers.forEach(async (d) => {
            if (item.id !== d.id) {
                let el = document.getElementById(`option-${d.id}`);
                el.style.transition = `${tansitionMs}ms`;
                el.style.opacity = 0;
                el.style.zIndex = '-1';

                // waiting time for hiding all unselected options
                await sleep(tansitionMs / 2);
            }
        })

        // waiting time for selected option before adding different animation to it
        await sleep(tansitionMs / 3);

        let correctOptionEl = document.getElementById(`option-${item.id}`);
        let buttonTag = correctOptionEl.getElementsByTagName("button")[0];
        let btnDimension = {
            width: correctOptionEl.getBoundingClientRect().width,
            height: correctOptionEl.getBoundingClientRect().height,
        }

        correctOptionEl.style.transition = `${tansitionMs}ms`;
        correctOptionEl.style.top = '0';
        correctOptionEl.style.left = '0';

        const childDiv = correctOptionEl;
        const parentDiv = optionsBoxRef.current;
        const childRect = childDiv.getBoundingClientRect();
        const parentRect = parentDiv.getBoundingClientRect();

        const correctBoxElY = isMobile ? index * childRect.height : (index <= 1 ? 0 : 1 * childRect.height);
        const centerPointY = parentRect.height / 2;
        const correctElBoxFinalPositionY = (centerPointY - correctBoxElY) - (childRect.height / 2);

        const correctBoxElX = parentRect.x - childRect.x;
        const centerPointX = parentRect.width / 2;
        const correctElBoxFinalPositionX = isMobile ? 0 : correctBoxElX === 0 ? centerPointX / 2 : -centerPointX / 2;

        correctOptionEl.style.top = `${correctElBoxFinalPositionY}px`;
        correctOptionEl.style.left = `${correctElBoxFinalPositionX}px`;

        correctOptionEl.style.width = `${btnDimension.width}px`;
        correctOptionEl.style.zIndex = '2';
        let btnOne = document.getElementById(`btn-1-${item.id}`);
        let btnTwo = document.getElementById(`btn-2-${item.id}`)
        // ilaRootRef.current.style.backgroundColor = item.isCorrect ? palette.ilaCorrect1 : palette.ilaInCorrect1;
        btnOne.style.backgroundColor = item.isCorrect ? palette.ilaCorrect2 : palette.ilaInCorrect2;
        btnTwo.style.backgroundColor = item.isCorrect ? palette.ilaCorrect1 : palette.ilaInCorrect1;
        await sleep(tansitionMs / 2);
        buttonTag.style.boxShadow = constants.ILA_OPTION_BOX_SHADOW;

        if (showScore) {
            await sleep(tansitionMs / 1);
            ilaBoxRef.current.style.gridTemplateRows = constants.ILA_GRID_FINAL;
            await addScore(data[activeQindex]?.score ? data[activeQindex].score : 0, item.isCorrect);
        }
        setShowTemplate(true);

        // return

        // waiting time for hiding the completed card components
        await sleep(tansitionMs * 2);
        ilaBoxRef.current.style.opacity = 0;
        setShowTemplate(false);

        // reset all style
        await sleep(tansitionMs / 3);
        btnOne.style = null;
        btnTwo.style = null;
        ilaRootRef.current.style = null;

        // waiting time before throwing next question
        await sleep(tansitionMs / 2);

        function handleIncorrectOption() {
            correctOptionEl.style = null;
            buttonTag.style = null;
            data[activeQindex].answers.forEach(async (d) => {
                if (item.id !== d.id) {
                    let el = document.getElementById(`option-${d.id}`);
                    el.style = null;
                }
            });
            isClickEnabledRef.current = true;
        }

        if (data.length - 1 > activeQindex) {
            ilaBoxRef.current.style = null;
            if (data[activeQindex].mandatory && !item.isCorrect) handleIncorrectOption(); // INCORRECT 
            else setActiveQindex(prev => prev + 1); // CORRECT
        } else {
            if (data[activeQindex].mandatory && !item.isCorrect) {
                ilaBoxRef.current.style = null;
                return handleIncorrectOption();
            }
            attemptedIds.current.push(ilaId);
            next();
        }
    }

    return (
        <>
            {
                isEmpty(data) ? (
                    <div className={`${classes.loaderBox} ${classes.centerFlex}`}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div className={classes.ilaRoot} ref={ilaRootRef}>
                        <div className={classes.ilaBox} ref={ilaBoxRef}>
                            <div className={styles.questionHead}>{`Q${data[activeQindex].questionNo}`}</div>
                            <div className={`${styles.question} ${classes.centerFlex}`}>
                                {
                                    showTemplate && (
                                        <div className={classes.correctIncorrectTextBox}>
                                            {isCorrectRef.current ? (<CorrectTemplate />) : (<IncorrectTemplate text={data[activeQindex].mandatory && 'TRY AGAIN!'} />)}
                                        </div>
                                    )
                                }
                                {data[activeQindex].question}
                            </div>
                            <div className={classes.optionsContainer}>
                                <div className={classes.optionsBox} ref={optionsBoxRef}>
                                    {
                                        data[activeQindex].answers.map((item, index) => (
                                            <div key={item.id} className={`${classes.option} ${classes.centerFlex}`} id={`option-${item.id}`}>
                                                <Button fullWidth className={classes.optionBtn} onClick={() => handleOptionClick(item, index)}>
                                                    <div className={`${classes.indexBtn} ${classes.centerFlex}`} id={`btn-1-${item.id}`}>{`${serialAlpha[index]}.`}</div>
                                                    <div className={classes.answerBtn} id={`btn-2-${item.id}`}>{item.answer}</div>
                                                </Button>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div>
                                <ScoreBox scoreBoxRef={scoreBoxRef} scoreValueRef={scoreValueRef} maxScore={maxScore} score={scoreRef.current} />
                            </div>

                            {
                                viewSkipBtn && (
                                    <div> <SkipButton /> </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default IlaMcqOne;