import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";
import constants from "../../../constants";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loaderBox: {
        height: '100%',
        width: '100%',
    },
    ilaRoot: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        transition: '0.7s',
        padding: '1rem',
        // borderRadius: '0.5rem',
        // backgroundColor: palette.ilaBg,
        // userSelect: 'none',
    },
    ilaBox: {
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: constants.ILA_GRID_INITIAL,
        transition: '500ms'
    },
    scoreBox: {
        textAlign: 'center',
        fontSize: '2rem',
        fontWeight: '900',
        display: 'grid',
        alignItems: 'center',
        alignContent: 'center',
        opacity: '0',
        transition: '500ms'
        // fontFamily: 'Montserrat'
    },
    scoreText: {
        fontSize: '1.5rem',
    },
    questionBox: {
        display: 'grid',
        gridTemplateColumns: '20% 60%'
    },

    optionsContainer: {
        display: 'grid',
        alignItems: 'center',
        transition: '0.7s'
    },
    optionsBox: {
        width: '60%',
        margin: 'auto',
        position: 'relative',
        // display: 'grid',
        // gridTemplateColumns: '50% 50%',
        // alignContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // padding: '0 3rem'
        // gap: '1.5rem',
        [theme.breakpoints.down("md")]: {
            width: '90%',
        }
    },
    option: {
        minHeight: '60px',
        wordBreak: 'break-word',
        width: '100%',
        marginBottom: '1.5rem',
        padding: '0 1rem',
        cursor: 'pointer',
        position: 'relative',
        transition: 'position 0.7s',
        '-webkit-tap-highlight-color': 'transparent',
        tapHighlightColor: 'transparent',
    },
    optionBtn: {
        height: '100% !important',
        padding: '0 !important',
        color: '#FFF !important'
    },
    indexBtn: {
        backgroundColor: '#00000033',
        padding: '1rem',
        fontSize: '1.2rem',
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        height: '100%',
        aspectRatio: '1/1',
        overflow: 'hidden',
        borderRadius: '0.3rem 0 0 0.3rem'
    },
    answerBtn: {
        backgroundColor: palette.ilaCardBlue,
        padding: '1rem',
        fontSize: '1.1rem',
        height: '100%',
        borderRadius: '0.3rem',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        transition: '0.7s',
        textAlign: 'center'
    },
    answerTxt: {
        transition: '500ms'
    },
    correctIncorrectTextBox: {
        position: 'absolute',
        top: '20%'
    }
}))

export default useStyles