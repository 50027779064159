import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";


import Lottie from 'react-lottie-player';
import FreshChat from "react-freshchat";


// constants
import constants from "../../constants";

// Context
import CommonContext from '../../context/CommonContext';

import noMicroskillAnimation from "../../lottie-assets/noMicroskillAnimation.json";

import useStyles from './index.styles';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { isIOS } from 'react-device-detect';

const { FULL_HEIGHT_DESKTOP, FULL_HEIGHT_MOBILE } = constants;

const NoMicroskill = (props) => {
    const { isMobile, selectedLanguage } = useSelector(state => state?.common);
    const styles = useStyles();

    const [showFreshChat, setShowFreshChat] = useState(false);


    const chatRef = useRef();
    var freshchatWidget;

    const { setLanguageChanging, setMobileOpen, setAnchorElUser } = useContext(CommonContext)

    const { isDarkMode } = useSelector(state => state?.commonPersist);
    const { t } = useTranslation()

    useEffect(() => {
        window.fcWidgetMessengerConfig = {
            config: {
                headerProperty: {
                    hideChatButton: true,
                }
            }
        }
        if (showFreshChat) {
            console.log("OPened");
            document.getElementById('fc_frame').style.display = 'block'
            // document.getElementById('fc_frame').style.paddingTop = isIOS ? constants.HEADER_TOP_PADDING : '0'   // to resolve conflict with native header in ios devices

            window.fcWidget.open();
            window.fcWidget.show();
        }
        else {
            // console.log("close");
            try {
                if (document.getElementById('fc_frame') === null) return
                document.getElementById('fc_frame').style.display = 'none'
                // document.getElementById('fc_frame').style.paddingTop = '0rem'
                setShowFreshChat(false);
                window.fcWidget.hide();
                window.fcWidget.close();
            } catch (err) {
                console.log(err);
            }
        }
    }, [showFreshChat])

    const handleChatWithUs = () => {
        // console.log("True is marked");
        setShowFreshChat(true)
    }
    const handleChangeLanguage = (e) => {
        if (isMobile) {
            setMobileOpen(true)
        } else {
            let userAvatar = document.querySelector("#userAvatar")
            setAnchorElUser(userAvatar)
        }
        setLanguageChanging(true)

    }

    return (
        <div className={styles.centerFlex} style={{ height: isMobile ? `calc(${FULL_HEIGHT_MOBILE} - 2rem)` : `calc(${FULL_HEIGHT_DESKTOP} - 1rem)`, touchAction: 'none' }}>
            <FreshChat
                ref={chatRef}
                token="3d0017c5-0a60-4693-bdae-3def0407cf94"
                // widgetUuid="25380827-750a-40ae-aca5-8f2a3355f15c"
                onInit={(widget) => {
                    freshchatWidget = widget;
                    widget.on("widget:closed", function () {
                        // console.log("widget was closed");
                        document.getElementById("fc_frame").style.display = "none";
                        document.getElementById("fc_frame").style.paddingTop = "0rem";
                        document.getElementById("fc_frame").style.paddingBottom = "0rem";
                        setShowFreshChat(false)
                    });
                }}
            />

            <div className={`${styles.centerFlex} ${styles.container}`} style={!isMobile ? {} : selectedLanguage === "Default" ? { gridTemplateRows: '35% 65%' } : { gridTemplateRows: '50% 50%' }}>
                <div className={`${styles.centerFlex} ${styles.lottieBox}`}>
                    <Lottie
                        loop={true}
                        style={{ width: isMobile ? '100%' : '80%', height: '100%' }}
                        play
                        animationData={noMicroskillAnimation}
                    />
                </div>

                {
                    selectedLanguage === "Default" ? (
                        <div className={`${styles.textContainer} ${styles.centerFlex}`} >
                            <div className={styles.NoMicroskillText}>{t("No Microskills Assigned")}</div>
                            <div className={styles.displayText}>{t("Please contact your company administrator")}</div>
                            <div className={styles.displayText}>{t("You will see Microskills here, once they are launched for you")}</div>
                            <div className={`${styles.displayText} ${styles.launchText}`}>{t("Launched but not visible?")}</div>
                            <div className={`${styles.chatWithUsContainer}`} onClick={handleChatWithUs}>
                                <img className={`${styles.chatWithUsImage}`} src={isDarkMode ? "/images/icons/chatWithUs.png" : "/images/icons/chatWithUs_orange.png"} />
                            </div>
                        </div>
                    ) : (
                        <div className={`${styles.textContainer} ${styles.centerFlex} ${styles.textContainerLang}`} >
                            <div className={styles.NoMicroskillTextLang} style={{ fontSize: isMobile && (selectedLanguage === 'Malayalam' || selectedLanguage === 'Tamil') ? "1.2rem" : '' }}>{t("No Microskills are available in this language.")}</div>
                            <div className={styles.displayTextLang} style={{ fontSize: isMobile && (selectedLanguage === 'Malayalam' || selectedLanguage === 'Tamil') ? "0.8rem" : '' }}>{t(`Please select another language using the button below or contact your supervisor if you think Microskills are missing`)}</div>
                            <div className={styles.changeLanguageBtn}>
                                <LoadingButton
                                    style={{ backgroundColor: '#F4511E', padding: '0.5rem', fontWeight: '600' }}
                                    type="submit"
                                    fullWidth
                                    onClick={handleChangeLanguage}
                                    loading={false}
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                >
                                    {t("Change Language")}
                                </LoadingButton>
                            </div>

                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default NoMicroskill
