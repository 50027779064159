import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";
import constants from "../../../constants";
import commonUtil from "../../../utils/commonUtil";
const isHDScreen = commonUtil.getScreenResolution() === 'HD';
// console.log("isHdScreen:", isHDScreen)

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loaderBox: {
        height: '100%',
        width: '100%',
    },
    ilaRoot: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        transition: '0.7s',
        padding: '1rem',
        // padding: '1px',
        // paddingTop: '1rem',
    },
    ilaBox: {
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: constants.ILA_GRID_INITIAL,
        transition: '500ms',
        [theme.breakpoints.down("sm")]: {
            gridTemplateRows: constants.ILA_GRID_INITIAL_MOBILE,
        }
    },
    // questionBox: {
    //     display: 'grid',
    //     gridTemplateColumns: '20% 60%'
    // },
    correctIncorrectTextBox: {
        position: 'absolute',
        bottom: '-2rem'
    },
    questionBox: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        // gap: '1rem',
        // height: '50%',
        width: '90%',
        margin: 'auto',
        flexWrap: 'wrap',
        '& > *': {
            flex: '0 1 33%'
        },
        [theme.breakpoints.down("sm")]: {
            display: 'grid',
            width: '100%',
            height: '100%',
            gridTemplateRows: 'repeat(3, 1fr)',
            justifyContent: 'inherit',
        }
    },
    answerBox: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-evenly',
        // gap: '1rem',
        // height: '50%',
        width: '90%',
        margin: 'auto',
        flexWrap: 'wrap',
        '& > *': {
            flex: '0 1 33%'
        },
        [theme.breakpoints.down("sm")]: {
            display: 'grid',
            width: '100%',
            gridTemplateRows: '33% 33%',
            alignContent: 'center',
            height: '100%',
            justifyContent: 'inherit',
        }
    },
    mtrrCardBox: {
        position: 'relative',
        paddingTop: '1rem',
        overflow: 'hidden',
        display: 'grid',
        [theme.breakpoints.down("sm")]: {
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            paddingTop: '0rem',
        }
    },
    mtrrCard: {
        position: 'relative',
        padding: '0.5rem 1rem',
        // height: '90%',
        [theme.breakpoints.down("sm")]: {
            padding: '1rem',
            width: '100%',
            margin: 'auto',
            height: '100%',
        }
    },
    mtrrCardInner: {
        // height: '110px',
        // aspectRatio: '16/9',
        // width: '150px',
        borderRadius: '0.52rem',
        overflow: 'hidden',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        fontFamily: 'Open Sans',
        fontSize: '0.9rem',
        fontWeight: 500,
        textAlign: 'center',
        padding: '0.5rem',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
        justifyItems: 'center',
        margin: 'auto',
        maxWidth: '200px',
        aspectRatio: '16/9',
        [theme.breakpoints.down("sm")]: {
            height: '100%',
            aspectRatio: 'auto',
            borderRadius: '0.22rem',
            boxShadow: 'none',
            width: 'auto',
        }
    },
    questionCard: {
        backgroundColor: palette.ilaCardBlue,
        transition: '0.2s',
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.8rem',
            // width: '70%'
        }
    },
    answerCard: {
        backgroundColor: '#703FAA',
        position: 'relative',
        // width: '100%',
        transformOrigin: 'center center', 
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.8rem',
            // width: '70%'
        }
    },
    answerTypeText: {
        fontFamily: 'Montserrat',
        fontWeight: 800,
        fontSize: '1.2rem',
        paddingBottom: '1rem',
        textTransform: 'uppercase'
    },
    answerContainer: {
        position: 'relative'
    },
    responseTypeText: {
        fontFamily: 'Montserrat',
        fontSize: '1.1rem',
        fontWeight: 800,
        paddingBottom: '0.5rem',
        [theme.breakpoints.down("sm")]: {
            fontSize: '1rem',
            // width: '70%'
        }
    }
}))

export default useStyles