
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({

    headerBox: {
        height: "11%",
        backgroundColor: "#a34735",
        display: "grid",
        alignItems: "end",
        position: "relative",
        zIndex: "2",
        color: '#fff',
        zIndex: '5'
    },
    headContainer: {
        display: 'grid',
        // gridTemplateColumns: '1fr 6fr',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '1rem',
    },
    title: {
        fontFamily: "Montserrat !important",
        fontWeight: '600',
        fontSize: '18px',
    },
    backBtn: {
        // height: "24px",
        paddingLeft: "20px",
        cursor: "pointer"
    },

}));

export default useStyle;
