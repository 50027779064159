import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";
import constants from "../../constants";
import commonUtil from "../../utils/commonUtil";
const isHDScreen = commonUtil.getScreenResolution() === 'HD';

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loaderBox: {
        height: '100%',
        width: '100%',
    },
    ilaRootMain: {
        fontSize: isHDScreen ? '0.9rem' : '1rem',
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.ilaBg,
        color: theme.palette.white,
        overflow: 'hidden',
        borderRadius: ({ viewFullScreen, isMobile }) => viewFullScreen || isMobile ? 0 : '0.5rem',
        // padding: '1rem',
        userSelect: 'none',
        [theme.breakpoints.down("md")]: {
            // padding: '1rem',
            borderRadius: '0',
        }
    },
    questionHead: {
        // font: 'normal normal bold 120px/247px Montserrat',
        position: 'relative',
        fontSize: isHDScreen ? '3.5em' : '4.5em',
        fontWeight: '800',
        color: '#FFFFFF1a',
        textAlign: 'center',
        '@media (max-width: 1300px)': {
            fontSize: '4em',
        },
        [theme.breakpoints.down("md")]: {
            fontSize: '2rem',
        }
    },
    question: {
        position: 'relative',
        fontSize: '1.7em',
        '@media (max-width: 1300px)': {
            fontSize: '1.2em',
        },

        fontWeight: 'bold',
        // marginTop: '3rem',
        textAlign: 'center',
        padding: '0 1rem',
        [theme.breakpoints.down("md")]: {
            fontSize: '1.1rem',
            fontWeight: '600',
        }
    },
}))

export default useStyles