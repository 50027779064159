import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import { toggleSideBar } from "../../../../actions/challengeListAction";
import GbaHeader from "../../common-screens/header";

// Style
import useStyle from "./index.styles";
import isEmpty from "../../../../utils/isEmpty";
import { useTranslation } from "react-i18next";

const HowToPlay = ({ width, height, title, headerBg, qstnBoxBg, qstnImg, qstnImgAnimation, answerImg, animationImg, setIsGamePage, gameType }) => {
    const isMobile = useSelector(state => state?.common?.isMobile);
    const classes = useStyle(isMobile);
    const dispatch = useDispatch();
    // const [instructionNo, setInstructionNo] = useState(0);
    const [reload, setReload] = useState(true);
    const optionSetRef = useRef([]);
    const handAssetRef = useRef();
    const questionRef = useRef([]);
    const containerRef = useRef(null);
    const headerQstnBox = useRef(null);
    const arrowImgRef = useRef(null);
    const displayQstnRef = useRef(null);
    const dataContainerRef = useRef(null);
    const progressBarRef = useRef(null);
    const overlayRef = useRef(null);
    const qstnBoxRef = useRef(null);
    const lifeTimeRef = useRef(null);
    const progressTextRef = useRef(null);
    const gameplayContainerRef = useRef(null);
    let instructionNo = 0;
    const widthMonkey = 120;
    const widthBanana = 35;
    let lottieBox = "translateX(-50%)";
    let rotatedLottieBox = "rotateY(180deg) translateX(50%)";
    let correctOptionGradient = "linear-gradient(#45a836a1, #45a836a1)";
    let correctOptionShadow = "0px 0px 7px 0px #fff";
    let assetBoxId = "option-set-box-1-47216";
    let assetPathBoxId = "option-path-box-1-47216";

    const {t} = useTranslation()

    let gameData = [
        {
            "optionId": 47217,
            "options": "Option A"
        },
        {
            "optionId": 47216,
            "options": "Option B"
        },
        {
            "optionId": 47219,
            "options": "Option C"
        },
        {
            "optionId": 47218,
            "options": "Option D"
        }
    ];

    const challengeList = () => {
        // dispatch(toggleSideBar(true));
        // navigate(-1);
    };

    const nextInstruction = (instNo, e) => {
        // console.log("instNo:", [instNo, instructionNo, e])
        let assetBoxEl = document.getElementById(assetBoxId);
        let assetpathBoxEl = document.getElementById(assetPathBoxId);

        if (instNo === 1 && instructionNo === 0) {
            // console.log("1")
            instructionNo = 1;
            // console.log("optionSetRef:",optionSetRef.current[1]);
            headerQstnBox.current.style.zIndex = 'auto';
            displayQstnRef.current.style.animation = 'auto';
            assetBoxEl.style.zIndex = 2;
            assetBoxEl.lastElementChild.firstElementChild.innerHTML = t("Tap on the correct answer");
            handAssetRef.current.style.opacity = 1;
            handAssetRef.current.classList.add(classes.blinkAnimation);
            overlayRef.current.removeEventListener('click', nextInstruction);
        } else if (instNo === 2) {
            // console.log("2")
            instructionNo = 2;
            assetBoxEl.style.transition = '3s';
            assetBoxEl.style.transform = 'scale(0.2) rotateZ(720deg)';
            assetBoxEl.style.opacity = '0';

            let backgroundImageProperty = assetpathBoxEl.style.backgroundImage;
            assetpathBoxEl.style.backgroundImage = `${correctOptionGradient} , ${backgroundImageProperty}`;
            assetpathBoxEl.style.boxShadow = `${correctOptionShadow}`;
            gameplayContainerRef.current.style.zIndex = 1;

            setTimeout(() => {
                dataContainerRef.current.style.display = 'grid';
                setTimeout(() => {
                    dataContainerRef.current.style.opacity = '1';
                    dataContainerRef.current.style.zIndex = '2';
                }, 200);
            }, 2800);
        } else if (instNo === 3) {
            // console.log("3")
            instructionNo = 3;
            dataContainerRef.current.style.display = 'none';
            displayQstnRef.current.style.display = 'none';
            headerQstnBox.current.style.zIndex = '3';
            lifeTimeRef.current.style.visibility = 'visible';
            // progressTextRef.current.style.display = 'block';
            // progressTextRef.current.style.zIndex = '2';
        } else if (instNo === 1 && instructionNo === 3) {
            // console.log("4")
            instructionNo = 4;
            lifeTimeRef.current.style.visibility = 'hidden';
            headerQstnBox.current.style.zIndex = 'auto';
            progressTextRef.current.style.display = 'block';
            progressTextRef.current.style.zIndex = '2';
        } else if (instNo === 1 && (instructionNo === 4 || instructionNo === 1) && progressTextRef.current.style.display === 'block') {
            // console.log("5")
            assetBoxEl.style = null;
            instructionNo = 0;
            setIsGamePage(true);
        }
    }

    const handleRestartClick = () => {
        setReload(false);
        setTimeout(() => { setReload(true) }, 100)
    }

    return (
        reload ? (
            <div className={classes.howToPlayroot}>
                <div className={classes.container}>
                    <div className={classes.overlay} ref={overlayRef} id={`overlay`}
                        onClick={(e) => nextInstruction(1, e)}
                    >
                        <div className={classes.progressText} ref={progressTextRef}>
                            <div className={classes.arrow2Box}>
                                <img src="/images/gba/arrow2.png" className={classes.arrowImg2} alt="arrow" width={50} />

                            </div>
                            <div className={classes.completionText}>{t("Track completion with the progress bar")}</div>
                        </div>

                        {/* <div className={classes.dataContainer} ref={dataContainerRef}>
                            <div className={`${classes.centerFlex} ${classes.readyText}`}>READY TO PLAY?</div>
                            <div className={`${classes.centerFlex} ${classes.cursorPointer}`}
                                onClick={(e) => { nextInstruction(3, e) }}>
                                <button className={classes.startBtn} id={`startGameBtn`}>START GAME</button>
                            </div>
                            <div className={`${classes.centerFlex} ${classes.restartText}`} onClick={handleRestartClick}>
                                RESTART INSTRUCTIONS
                            </div>
                        </div> */}
                    </div>
                    <div className={classes.headerQstnBox} ref={headerQstnBox}>
                        <div className={classes.progressBarContainer} ref={progressBarRef}>
                            <div className={classes.progressBar} />
                        </div>

                        {/* QUESTION BOX */}
                        <div className={classes.qstnContainer} ref={qstnBoxRef}>
                            <div className={classes.qstnBox} style={{ backgroundColor: `${qstnBoxBg}` }}>
                                <div className={classes.lifeTime} ref={lifeTimeRef}>
                                    <div className={classes.centerFlex}>
                                        <div className={classes.assetsText}>4 x </div>
                                        <img src='/images/icons/heart.png' className={classes.icon} alt="heart" width="10" />
                                    </div>
                                    <div className={classes.centerFlex}>
                                        <img src='/images/icons/stopwatch.png' className={classes.icon} alt="heart" width="10" />
                                        <div className={classes.assetsText}>18s</div>
                                    </div>
                                </div>
                                <div className={`${classes.qstn} ${classes.centerFlex}`} ref={displayQstnRef}>{t("Questions will appear inside this box")} </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.gameplayContainer} ref={gameplayContainerRef}>
                        {
                            gameData.map((item, index) => (
                                <div className={`${classes.optionSetBox} tikkiMonkeyOptionSetBox`} onClick={(e) => nextInstruction(2, e)}
                                    key={item.optionId} id={`option-set-box-${index}-${item.optionId}`} ref={e => optionSetRef.current.push(e)}>

                                    <div className={`${classes.movingAssetBox} tikkiMonkeyMovingAssetBox`}
                                        id={`moving-asset-box-${index}-${item.optionId}`}>

                                        <div className={`${classes.answerLottieBox}`}
                                            id={`answer-lottie-box-${index}-${item.optionId}`}
                                        // style={{transform: index % 2 === 0 ? (lottieBox): (rotatedLottieBox)}}    
                                        />
                                        <div className={`${classes.monkeyAssetBox}`}
                                            style={{ width: `${widthMonkey}px` }} id={`monkey-${index}-${item.optionId}`}>
                                            <img src="/images/gba/monkey.png" alt="path" className={classes.monkeyImg} />
                                        </div>
                                        <div style={{ width: `${widthBanana}px` }} id={`banana-${index}-${item.optionId}`}>
                                            <img src="/images/gba/bananas.svg" alt="path" className={classes.bananaImg} />
                                        </div>
                                    </div>
                                    <div className={`${classes.pathBox} tikkiMonkeyPathtBox`}
                                        // onClick={() => handleOptionClick(index, item.optionId)}
                                        id={`option-path-box-${index}-${item.optionId}`}
                                        style={{ backgroundImage: 'url(/images/gba/path.png)', width: width }}
                                    >
                                        {/* <img src="/images/gba/path.png" alt="path" width={width}/> */}
                                        <div className={classes.option}>{item.options}</div>
                                        {
                                            index === 1 ? (
                                                <img src="/images/gba/hand.png" alt="hand" ref={handAssetRef}
                                                    className={classes.handImg} id="hand-asset" width={50}
                                                />
                                            ) : (null)
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <div className={classes.dataContainer} ref={dataContainerRef}>
                        <div className={classes.dataContainerBox} >
                            <div className={`${classes.centerFlex} ${classes.readyText}`}>{t("READY TO PLAY")}?</div>
                            <div className={`${classes.centerFlex} ${classes.cursorPointer}`}
                                onClick={(e) => { nextInstruction(3, e) }}>
                                <button className={classes.startBtn} id={`startGameBtn`}>{t("START GAME")}</button>
                            </div>
                            <div className={`${classes.centerFlex} ${classes.restartText}`} onClick={handleRestartClick}>
                                {t("RESTART INSTRUCTIONS")}
                            </div>
                        </div>
                    </div>

                    {/* <div className={classes.progressText} ref={progressTextRef} onClick={() => { nextInstruction(5) }}>
                        <div className={classes.arrow2Box}>
                            <img src="/images/gba/arrow2.png" className={classes.arrowImg2} alt="arrow" width={50} />
                        </div>
                        <div className={classes.completionText}>Track completion with the progress bar</div>
                    </div> */}

                </div>
            </div>
        ) : (null)

    )
};

export default HowToPlay
