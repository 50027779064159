import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    documentModalRoot: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: '50%',
        left: '50%',
        zIndex: '10',
        // backgroundColor: 'rgb(0 0 0 / 50%)',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 0 1px 10px rgb(0 0 0 / 50%)'
        // [theme.breakpoints.up("sm")]: {
        //     backgroundColor: '#fff',
        // }
    },
    boxStyle: {
        display: 'grid',
        gridTemplateRows: '8% 84% 8%',
        borderRadius: '0.5rem',
        alignItems: 'center',
        zIndex: '1000',
        '&:focus-visible': {
            outline: 'none'
        },
        [theme.breakpoints.up("sm")]: {
            gridTemplateRows: '12% 88% 0%',
        }
    },
    header: {
        display: 'grid',
        gridTemplateColumns: '90% 8%',
        gap: '2%'
    },
    nameBox: {
        justifyContent: 'flex-start',
        gap: '0.5rem',
        // background: '#f8f8f824',
        padding: '0.5rem 0.75rem',
        borderRadius: '3rem',
        display: 'grid',
        gridTemplateColumns: '10% 90%',
        // backgroundColor: 'rgba(192 190 190)',
        // backgroundColor: ({ fileType }) => fileType !== 'document' ? 'rgba(192 190 190)' : '#f8f8f824',
        [theme.breakpoints.up("sm")]: {
            // backgroundColor: 'rgba(192 190 190)',
            padding: '0.5rem 1rem',
            gridTemplateColumns: '5% 95%',
            alignContent: 'center',
            height: '70%',
        }
    },
    closeBtn: {
        backgroundColor: 'rgba(192 190 190)',
        // backgroundColor: ({ fileType }) => fileType !== 'document' ? 'rgba(192 190 190)' : '#f8f8f824',
        borderRadius: '50%',
        aspectRatio: '1/1',
        height: '100%',
        cursor: 'pointer',
        [theme.breakpoints.up("sm")]: {
            // height: '2.5rem',
            height: '70%',
            backgroundColor: 'rgba(192 190 190)',
        }
    },
    nameDiv: {
        overflow: 'hidden',
        width: '96%',
        display: 'flex',
        fontWeight: 600,
        alignItems: 'center',
        color: 'rgba(0 0 0)',
        // color: ({ fileType }) => fileType !== 'document' ? 'rgba(0 0 0)' : '#fff',
        [theme.breakpoints.up("sm")]: {
            // color: '#000',
        }
        // gridTemplateColumns: '75% 25%',
    },
    nameTxt: {
        // width: '75%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    ext: {

    }
}))

export default useStyles