import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    scoreBox: {
        textAlign: 'center',
        fontSize: '2rem',
        fontWeight: '900',
        display: 'grid',
        alignItems: 'center',
        alignContent: 'center',
        opacity: '0',
        transition: '500ms'
        // fontFamily: 'Montserrat'
    },
    scoreText: {
        fontSize: '1.5rem',
        textTransform: 'uppercase'
    },
    scoreValueText: {
        // opacity: '0',
        transition: '0.5s',
        paddingRight: '0.5rem'
    }
}))

export default useStyles