import { AES, enc } from 'crypto-js';

export const encrypt = (text) => {
    if (!process.env.REACT_APP_ENCRYPTION_SECRET_KEY) throw new Error('REACT_APP_ENCRYPTION_SECRET_KEY must be defined');
    const cipherText = AES.encrypt(text, process.env.REACT_APP_ENCRYPTION_SECRET_KEY);
    const encrypted = cipherText.toString();
    return encrypted;
};

export const decrypt = (text) => {
    if (!process.env.REACT_APP_ENCRYPTION_SECRET_KEY) throw new Error('REACT_APP_ENCRYPTION_SECRET_KEY must be defined');
    let bytes = AES.decrypt(text, process.env.REACT_APP_ENCRYPTION_SECRET_KEY);
    const decrypted = bytes.toString(enc.Utf8);
    return decrypted;
};
export const encryptWithParams = (text, encryption) => {
    if (encryption.function === 'crypto') {
        if (encryption.algorithm === 'aes-256-cbc') {
            const { secretKey, secretIV } = encryption;
            const encryptCipher = crypto.createCipheriv('aes-256-cbc', secretKey, secretIV);
            let encryptedText = encryptCipher.update(text, 'utf8', 'base64') + encryptCipher.final('base64');
            return encryptedText;
        } else if (encryption.algorithm === 'aes-128-cbc') {
            const { secretKey, secretIV } = encryption;
            const encryptCipher = crypto.createCipheriv('aes-128-cbc', secretKey, secretIV);
            const encryptedText = encryptCipher.update(text, 'utf8', 'hex') + encryptCipher.final('hex');
            return encryptedText;
        }
    } else if (encryption.function === 'cryptoJs') {
        const { secretKey } = encryption;
        const encryptCipher = AES.encrypt(text, secretKey);
        const encryptedText = encryptCipher.toString();
        return encryptedText;
    }
};