import React from "react";

// mui
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// components

// utils
// import axiosInstance from "../../utils/axios"

// apis


// utils
// import isEmpty from "../../utils/isEmpty"

// styles
// import useStyles from "./index.styles"

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Toast = (props) => {
    const {
        isOpen,
        toastDuration = 2000,
        toastSeverity,
        toastMessage,
        handleToastClose,
    } = props;
    // const classes = useStyles();
    // const navigate = useNavigate();

    const state = {
        open: false,
        vertical: 'bottom',
        horizontal: 'right',
    };
    const { vertical, horizontal } = state;

    return (
        <div>
            <Snackbar open={isOpen} autoHideDuration={toastDuration} onClose={handleToastClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={handleToastClose} severity={toastSeverity} sx={{ width: '100%' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
        </div >
    )
}

export default Toast;