import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    backButton: {
        width: '40px',
        aspectRatio: '1 / 1',
        borderRadius: '50%',
        background: 'rgba(255, 255, 255)',
        cursor: 'pointer',
    },
}))

export default useStyles