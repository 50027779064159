import React, { useEffect } from 'react';

//  components
import LoadingMicroskill from '../../components/loading-microskill';
import { useMsal } from '@azure/msal-react';

const MsalPage = () => {
    const { accounts, inProgress } = useMsal();
    useEffect(() => { 
        console.log('color:#fff;background:#ee6f57;padding:3px;border-radius:2px', accounts, inProgress)
    }, [accounts, inProgress])

    return (
        <LoadingMicroskill />
    )
}

export default MsalPage;