import React, { useState, useRef, useEffect } from "react";
import useStyles from "./index.styles";
import ReactPlayer from "react-player";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import { Slider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

const getMinutesFromSeconds = (time) => {
    let roundedTime = Math.round(time)
    let timeValue = roundedTime > 0 ? roundedTime : -1 * roundedTime;
    let sec = timeValue % 60;
    let min = Math.floor(timeValue / 60);
  
    let minValue = min.toString().length !== 1 ? min.toString() : `0${min}`;
    let secValue = sec.toString().length !== 1 ? sec.toString() : `0${sec}`;
    return minValue + ':' + secValue;
}

const VideoPlayer = React.forwardRef(({ currVideo, setOpenModal, videoData = {} }, ref) => {
  // console.log("currVideo:", currVideo) 
  const classes = useStyles();
  const aiData = videoData;
  const [playerState, setPlayerState] = useState(true);
  const [videoDurationElapsedPer, setVideoDurationElapsedPer] = useState(0);
  const [videoDurationElapsed, setVideoDurationElapsed] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const selfCoachingVideoRef = useRef(null);

  const {t} = useTranslation()

  
    const handleVideoPlayPause = () => {
      setPlayerState(prev => {
        if (prev) handlePause();
        else handlePlay();
        return prev
      });
    }
  
    const handlePlay = () => {
      setPlayerState(true);
    };
    const handlePause = () => setPlayerState(false);
  
    const getAiTemplateForVideos = () => {
      let reactPlayerEl = document.getElementById('reactPlayer');
      let aiDataOverlay = document.getElementById('aiDataOverlay');
      let closeClone = aiDataOverlay.cloneNode(true);
      closeClone.style.display = 'grid'
      reactPlayerEl.appendChild(closeClone);
    }
  
    useEffect(() => {
      setTimeout(() => {
          getAiTemplateForVideos();
            let reactPlayerEl = document.getElementById('reactPlayer');
            let videoPlayerOverlayEl = document.getElementById('videoPlayerOverlay');
            let videoPlayerOverlay = videoPlayerOverlayEl.cloneNode(true);
            // videoPlayerOverlay.onclick = () => setOpenModal(false);
            videoPlayerOverlay.style.visibility = 'inherit';
            reactPlayerEl.appendChild(videoPlayerOverlay);
      }, 1000)
    }, []);
  
    const handleProgressCallback = (progressData) => {
      setVideoDurationElapsed(progressData.playedSeconds);
      setVideoDurationElapsedPer(progressData.played * 100);
    }
  
    const handleVideoSeekBar = (e, percentage) => {
      selfCoachingVideoRef.current.seekTo(percentage);
      setVideoDurationElapsedPer(percentage);
    }
  
    const onVideoReady = (v) => {
      console.log(v.getDuration());
      setVideoDuration(videoData?.timespent);
    }
  
    const Header = () => {
      return (
        <AppBar
          style={{
            height: `4rem`,
            paddingBottom: "",
            background: "#4f0060",
            transition: "background 1s, boxShadow 1s",
          }}
          position="fixed"
          elevation={2}
        >
          <Toolbar
          // style={{
          //   minHeight: isPadding ? "7rem" : "",
          //   paddingTop: isPadding ? "2.5rem" : "",
          // }}
          >
            <IconButton
              onClick={() => setOpenModal(false)}
              edge="start"
              style={{ color: "white" }}
              color="inherit"
              aria-label="menu"
            >
              <img src="/images/self-coaching/back-orange.png" height="13px" />
            </IconButton>
            <Typography
              variant="h6"
              style={{
                color: "white",
                transition: "color 1s ",
              }}
            >
              {t('Your Performance')}
            </Typography>
          </Toolbar>
        </AppBar>
      )
    }
  
    return (
      <div
        style={{
          display: 'flex',
          // alignItems: 'center',
          justifyContent: 'center',
          borderRadius: "8px",
          height: "100%",
          position: "relative",
        }}
      >
        <div className={classes.backDrop} />
        <div className={classes.reactVideoPlayerBox}>
            {/* <Header /> */}
            <div className={classes.videoPlayerOverlay} id={`videoPlayerOverlay`} />
            <div className={classes.challengeNameBox}>
                <div className={classes.challengeName}>
                    {currVideo.challengeName}
                </div>
                <div className={classes.closeBtnBox} id="close-button">
                    <div className={classes.closeBtnBg} />
                    <IconButton
                        className="close-button"
                        aria-label="close"
                        // style={{ position: "absolute", right: "0", zIndex: '99' }}
                        onClick={() => setOpenModal(false)}
                    >
                        <CloseIcon
                        onClick={() => setOpenModal(false)}
                        style={{ color: "#FFF" }}
                        />
                    </IconButton>
                </div>
            </div>

          <ReactPlayer
              id="reactPlayer"
              playsinline
              url={currVideo?.media}
              playing={playerState}
              className={classes.reactVideoPlayer}
              onProgress={handleProgressCallback}
              onEnded={() => setPlayerState(false)}
              onClick={handleVideoPlayPause}
              onReady={onVideoReady}
              ref={selfCoachingVideoRef}
          />

          <div className={classes.progressBarBox}>
              {
              !playerState ? (
                  <img src={`images/self-coaching/play-button-white.svg`} alt="icon" onClick={handleVideoPlayPause} />
              ) : (
                  <img src={`images/self-coaching/pause-button-white.svg`} alt="icon" onClick={handleVideoPlayPause} />
              )
              }
              {/* <LinearProgress variant="determinate" value={videoDurationElapsedPer} /> */}
              <Slider
              aria-label="Volume"
              value={videoDurationElapsedPer}
              onChange={handleVideoSeekBar}
              />
          </div>

          <div className={classes.videoDurationBox}>
              {getMinutesFromSeconds(videoDurationElapsed)} - {getMinutesFromSeconds(videoDuration)}
          </div>
      </div>

      <div id={`aiDataOverlay`} className={classes.aiDataOverlayBox} style={{ display: 'none' }}>
        <div className={classes.userNameText}>{`${currVideo.first_name ? currVideo.first_name : ''} ${currVideo.last_name ? currVideo.last_name : '' }`}</div>
        <div className={classes.aiDataOverlay}>
          {
            aiData.duration && (
              <div className={classes.box}>
                <div className={classes.mainText}>{t("DURATION")}</div>
                <div className={classes.value}>{aiData.duration.qualitativeFeedback}</div>
              </div>
            )
          }
          {
            aiData.speechRate && (
              <div className={classes.box}>
                <div className={classes.mainText}>{t("SPEECH RATE")}</div>
                <div className={classes.value}>{aiData.speechRate.qualitativeFeedback}</div>
              </div>
            )
          }
          {
            aiData.keywordMatch && (
              <div className={classes.box}>
                <div className={classes.mainText}>{t("KEYWORD MATCH")}</div>
                <div className={classes.value}>{aiData.keywordMatch.qualitativeFeedback}</div>
              </div>
            )
          }
          {
            aiData.transcriptMatch && (
              <div className={classes.box}>
                <div className={classes.mainText}>{t("TRANSCRIPT")}</div>
                <div className={classes.value}>{aiData.transcriptMatch.qualitativeFeedback}</div>
              </div>
            )
          }
          {
            aiData.tone && (
              <div className={classes.box}>
                <div className={classes.mainText}>{t("TONE")}</div>
                <div className={classes.value}>{aiData.tone.qualitativeFeedback}</div>
              </div>
            )
          }
          {
            aiData.clarity && (
              <div className={classes.box}>
                <div className={classes.mainText}>{t("CLARITY")}</div>
                <div className={classes.value}>{aiData.clarity.qualitativeFeedback}</div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
})

export default VideoPlayer;