import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    lottieBox: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%'
        }
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            display: 'grid',
            height: '100%',
        }
    },
    textContainer: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        rowGap: '2rem',
        height: '100%',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            justifyContent: 'flex-start'
        }
    },
    NoMicroskillText: {
        fontSize: '4rem',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.7rem',
        }
    },
    displayText: {
        fontSize: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        }
    },
    NoMicroskillTextLang: {
        fontSize: '3rem',
        fontWeight: 'bold',
        wordBreak: 'break-word',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
        }
    },
    displayTextLang: {
        fontSize: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        }
    },
    launchText: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            width: '100%'
        }
    },
    chatWithUsContainer: {
        width: '50%',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
        }
    },
    chatWithUsImage: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        }
    },
    textContainerLang: {
        // width: '50%',
        [theme.breakpoints.down('sm')]: {
            // width: '100%',
            alignItems: 'flex-end !important'
        }
    },
    changeLanguageBtn: {
        fontSize: '1.2rem',
        background: palette.orange,
        padding: '0rem 1rem',
        borderRadius: '5px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            width: '100%',
            textAlign: 'center',
        }
        
    },
}))

export default useStyles;