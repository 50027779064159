import React, { useEffect, useState, useRef, useContext } from "react";

// context
import LearningAidContext from "../../../context/learningAidContext";

// mui
import { Button, CircularProgress } from "@mui/material";

// components
import ScoreBox from "../score-box";

// redux
// import { getChallengeData } from "../../redux/microskill/microskill.api";

// utils
import isEmpty from "../../../utils/isEmpty";

// constants
import constants from "../../../constants";

// theme
import palette from "../../../theme/palette";

// styles
import useStyles from "./index.styles";

// lottie

const serialAlpha = ['A', 'B', 'C', 'D'];
const underscore = `_________`;

const IlaFitbOne = (props) => {
    const {
        data,
        prev,
        next,
        attemptedIds,
        ilaId,
        scoreListRef,
        addScore,
        maxScore,
        scoreValueRef,
        handleChallengeAttemptData,
        styles,
        CorrectTemplate,
        IncorrectTemplate,
        SkipButton,
        isChallengeSubmitted,
        skipButtonDisplayTime,
        cardId
    } = props
    // console.log("data:", data)
    const classes = useStyles();
    const { isClickEnabledRef } = useContext(LearningAidContext)
    // const { microskillId, challengeId } = useParams();
    const [activeQindex, setActiveQindex] = useState(0);
    const [showTemplate, setShowTemplate] = useState(false);
    const [viewSkipBtn, setViewSkipBtn] = useState(false);
    const [componentMounted, setComponentMounted] = useState(true);
    const ilaRootRef = useRef();
    const ilaBoxRef = useRef();
    const scoreBoxRef = useRef();
    const optionsRef = useRef();
    const selectedAnswerRef = useRef();
    const incorrectAttemptsIdsRef = useRef([]);
    const isCorrectRef = useRef(false);
    const currentTimeRef = useRef(Date.now());
    // const splitQuestionArray = data[activeQindex].question.split("[MBlank]");
    let tansitionMs = 1000;

    useEffect(() => {
        return () => {
            setComponentMounted(false)
        }
    }, []);

    useEffect(() => {
        if (!isChallengeSubmitted || !componentMounted) return
        setTimeout(() => {
            setViewSkipBtn(true)
        }, skipButtonDisplayTime);
    }, [isChallengeSubmitted]);

    useEffect(() => {
        isClickEnabledRef.current = true;
    }, [activeQindex])

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    const handleOptionClick = async (item, index) => {

        if (!isClickEnabledRef.current) return
        isClickEnabledRef.current = false;

        let showScore = data[activeQindex].score > 0;
        // check if the user is again answering the same ILA, then remove that attempted ila id
        let indexToSplice = attemptedIds.current.indexOf(ilaId);
        if (indexToSplice !== -1) {
            attemptedIds.current.splice(indexToSplice, 1);
        }

        // handle score logic
        let currentTime = Date.now();
        if (item.isCorrect) {
            isCorrectRef.current = true;
            scoreListRef.current.add(`${data[activeQindex].id}-${data[activeQindex].score}`);
            console.log("sending");
            handleChallengeAttemptData({
                qId: item.id,
                cardId: cardId,
                t: Math.round((currentTime - currentTimeRef.current) / 1000),
                aId: item.id,
                isCorrect: true
            });
        } else {
            isCorrectRef.current = false;
            handleChallengeAttemptData({
                qId: item.id,
                cardId: cardId,
                t: Math.round((currentTime - currentTimeRef.current) / 1000),
                aId: item.id,
                isCorrect: false
            });
        }
        currentTimeRef.current = currentTime;

        // hide options
        optionsRef.current.style.opacity = 0;
        await sleep(tansitionMs / 2);

        ilaRootRef.current.style.backgroundColor = item.isCorrect ? palette.ilaCorrect1 : palette.ilaInCorrect1;
        await sleep(tansitionMs / 2);
        selectedAnswerRef.current.style.opacity = '0';
        await sleep(tansitionMs / 2);
        selectedAnswerRef.current.innerHTML = item.answer;
        selectedAnswerRef.current.style.textDecoration = item.isCorrect ? 'underline' : 'line-through';
        selectedAnswerRef.current.style.opacity = '1';

        ilaBoxRef.current.style.gridTemplateRows = '15% 60% 0% 25%';
        if (showScore) {
            await sleep(tansitionMs / 1);
            await addScore(data[activeQindex]?.score ? data[activeQindex].score : 0, item.isCorrect);
        }

        // scoreBoxRef.current.style.opacity = '1';
        setShowTemplate(true);

        // return

        // waiting time for hiding the complete card components
        await sleep(tansitionMs * 2);
        if (!isEmpty(ilaBoxRef.current)) ilaBoxRef.current.style.opacity = 0;
        setShowTemplate(false);

        // reset all style
        await sleep(tansitionMs / 3);
        document.getElementById(`btn-2-${item.id}`).style = null;
        ilaRootRef.current.style = null;
        optionsRef.current.style = null;
        selectedAnswerRef.current.innerHTML = underscore;

        // waiting time before throwing next question
        await sleep(tansitionMs / 2);
        // return

        function handleIncorrectOption() {
            ilaBoxRef.current.style = null;
            selectedAnswerRef.current.style = null;
            isClickEnabledRef.current = true;
        }
        if (data.length - 1 > activeQindex) {
            ilaBoxRef.current.style = null;
            selectedAnswerRef.current.style = null;
            if (data[activeQindex].mandatory && !item.isCorrect) handleIncorrectOption();
            else setActiveQindex(prev => prev + 1);
        } else {
            if (data[activeQindex].mandatory && !item.isCorrect) return handleIncorrectOption();
            attemptedIds.current.push(ilaId)
            next();
        }
    }

    return (
        <>
            {
                isEmpty(data) ? (
                    <div className={`${classes.loaderBox} ${classes.centerFlex}`}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div className={classes.ilaRoot} ref={ilaRootRef}>
                        <div className={classes.ilaBox} ref={ilaBoxRef}>
                            {/* <div className={classes.scoreBox}>{`40 / 120`}</div> */}
                            {/* <div className={classes.questionBox}> */}
                            <div className={styles.questionHead}>
                                {`Q${data[activeQindex].questionNo}`}
                            </div>
                            <div className={`${styles.question} ${classes.centerFlex}`}>
                                {
                                    showTemplate && (
                                        <div className={classes.correctIncorrectTextBox}>
                                            {isCorrectRef.current ? (<CorrectTemplate />) : (<IncorrectTemplate text={data[activeQindex].mandatory && 'TRY AGAIN!'} />)}
                                        </div>
                                    )
                                }
                                <span>
                                    <span>{data[activeQindex].question.split("[MBlank]")[0]}</span>
                                    <span ref={selectedAnswerRef} className={classes.answerTxt}> {underscore} </span>
                                    <span>{data[activeQindex].question.split("[MBlank]")[1]}</span>
                                </span>
                            </div>
                            {/* </div> */}
                            <div className={classes.optionsContainer} ref={optionsRef}>
                                <div className={classes.optionsBox}>
                                    {
                                        data[activeQindex].answers.map((item, index) => (
                                            <div key={item.id} className={`${classes.option} ${classes.centerFlex}`} id={`option-${item.id}`}>
                                                <Button fullWidth className={classes.optionBtn} onClick={() => handleOptionClick(item, index)}>
                                                    <div className={classes.answerBtn} id={`btn-2-${item.id}`}>{item.answer}</div>
                                                </Button>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <ScoreBox scoreBoxRef={scoreBoxRef} scoreValueRef={scoreValueRef} maxScore={maxScore} />
                            {
                                viewSkipBtn && (
                                    <div> <SkipButton /> </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default IlaFitbOne;