import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

// mui
import { Button } from "@mui/material";

// components
import CustomReactPlayer from "../../custom-react-player";

// redux
import { handleSpotlightScreenState } from "../../../redux/spotlight/spotlight-slice";

// utils 
// import isEmpty from "../../../utils/isEmpty";
// import eventBus from "../../../utils/eventBus";

// constants

// styles
import useStyles from "./index.styles";
import { useTranslation } from "react-i18next";

// const GBA_SCREEN_STATE = constants.GBA_SCREEN_STATE;
// const BACK_BTN_EVENT = constants.HEADER_BACK_BUTTON_CLICK_EVENT;

const SampleRecording = (props) => {
    const {
        screenStateList,
        spotlightData,
        setIsStartrecording,
        viewTranscript,
        setViewTranscript,
        viewSampleRecording,
        setViewSampleRecording,
        setIsOverlay,
        isVideoPlaying,
        setIsVideoPlaying
    } = props;
    // console.log("sample recording:", props);
    const classes = useStyles({ viewSampleRecording, viewTranscript });
    const { t } = useTranslation()
    const dispatch = useDispatch();
    // const [viewTranscript, setViewTranscript] = useState(spotlightData.isTranscript);
    // const [viewTranscript, setViewTranscript] = useState(false);


    useEffect(() => {
        return () => {
            setIsVideoPlaying(false);
            setIsOverlay(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleViewTranscript = (type) => {
        // console.log("View");
        setViewSampleRecording(!type);
        if (!type) {
            setViewSampleRecording(false);
            setViewTranscript(false);
            setIsStartrecording(true)
            dispatch(handleSpotlightScreenState(screenStateList.SCENARIO));
        }
    }
    const handleOverlayBtnClick = (type) => {
        // console.log("handleOverlayBtnClick")
        if (type === 'resume') {
            setIsVideoPlaying(true);
            setIsOverlay(false);
        }
        else if (type === 'back') {
            dispatch(handleSpotlightScreenState(screenStateList.SCENARIO));
        }
        else if (type === 'skip') {
            setViewSampleRecording(false);
            setViewTranscript(false);
            setIsStartrecording(true)
            dispatch(handleSpotlightScreenState(screenStateList.SCENARIO));

            // setIsStartrecording(true);
            // // setViewTranscript(false);
            // dispatch(handleSpotlightScreenState(screenStateList.SCENARIO));
        };
    }

    const handleOnPause = () => {
        setIsVideoPlaying(false);
        // setIsOverlay(true);
    }
    const handleOnPlay = () => {
        setIsVideoPlaying(true)
    }

    return (
        <div className={classes.sampleRecordingRoot}>


            <div className={`${classes.previewVideoBox} ${classes.centerFlex}`}
                style={{
                    filter: viewSampleRecording ? 'none' : 'blur(8px) brightness(0.25)',
                    // zIndex: isOverlay ? '0' : '1'
                }}
            >

                <CustomReactPlayer
                    mediaUrl={spotlightData.mediaPath}
                    muted={false}
                    borderRadius={'0px'}
                    onPause={handleOnPause}
                    onPlay={handleOnPlay}
                    isPlaying={isVideoPlaying}
                    videoStyle={{ objectFit: 'contain' }}
                />

                {
                    <div className={classes.viewTranscriptOverlay}>
                        <div className={classes.btnContainer}>
                            {
                                viewTranscript && (
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        size="large"
                                        className={`fixed_ratio_20_3`}
                                        sx={theme => ({
                                            color: '#fff',
                                            fontWeight: '600',
                                            fontSize: '1.2em',
                                            padding: '0.5em'
                                        })}
                                        onClick={() => handleViewTranscript(true)}
                                    >
                                        {t('View Transcript')}
                                    </Button>
                                )
                            }
                            <Button
                                size="large"
                                variant="contained"
                                fullWidth
                                disableRipple
                                className={`fixed_ratio_20_3 ${classes.skipBtn}`}
                                onClick={() => handleOverlayBtnClick('skip')}
                            >
                                <u>{t("SKIP")}</u>
                            </Button>
                        </div>
                    </div>
                }
            </div>



            {
                !viewSampleRecording && viewTranscript && (
                    <div className={classes.transcriptBox}
                        style={{
                            backgroundColor: viewTranscript ? '#000000cf' : 'none'
                        }}
                    >
                        {
                            <>
                                <div className={`${classes.transcriptTextBox} scrollbar-hidden`}
                                    dangerouslySetInnerHTML={{
                                        __html: spotlightData.formatted
                                    }}
                                >
                                </div>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    size="large"
                                    className={`fixed_ratio_20_3 ${classes.closeTranscriptBtn}`}
                                    onClick={() => handleViewTranscript(false)}
                                // sx={{ borderRadius: 1, fontSize: '0.9rem', borderWidth: 2 }}
                                >
                                    {t('Close Transcript')}
                                </Button>
                            </>

                        }


                        {/* {
                            isStartrecording && (
                                <div className={classes.viewTranscriptBtnBox}
                                    style={{
                                        zIndex: viewTranscript ? '2' : '1',
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        size="large"
                                        className={classes.viewTranscriptBtn}
                                        onClick={handleViewTranscript}
                                    // sx={{ borderRadius: 1, fontSize: '0.9rem', borderWidth: 2 }}
                                    >
                                        {viewTranscript ? t('Close Transcript') : t('View Transcript')}
                                    </Button>
                                </div>
                            )
                        } */}

                    </div>
                )
            }

            {/* {
                isOverlay && (
                    <div className={classes.overlay}>
                        <Button
                            variant="contained"
                            size="medium"
                            className={classes.overlayBtn}
                            onClick={() => handleOverlayBtnClick('resume')}
                        >
                            {t("RESUME")}
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            className={classes.overlayBtn}
                            onClick={() => handleOverlayBtnClick('back')}
                        >
                            {t("BACK")}
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            className={classes.overlayBtn}
                            onClick={() => handleOverlayBtnClick('skip')}
                        >
                            {t("SKIP")}
                        </Button>
                    </div>
                )
            } */}
        </div>
    )
}

export default SampleRecording;