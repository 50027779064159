import React from "react";
import { useTranslation } from "react-i18next";
import useStyle from "./index.styles";

const GbaTypeMA = (props) => {
    const { gameData } = props;

    console.log("gameData", gameData);

    const { t, i18n } = useTranslation();
    const classes = useStyle();

    return (
        <div className={`${classes.mcqContainer} scrollbar`}>

            {gameData?.questions?.map((item, index) => (

                <div key={index} className={classes.wrapper}>

                    <div className={classes.questionContainer}>
                        <h4 className={classes.question}>
                            Q{index + 1}. {item?.question}
                        </h4>
                    </div>

                    <div className={classes.correctAnswerText}> Correct Answers </div>

                    <div className={classes.answerContainer}>
                        {
                            item?.answers?.filter(item => item?.isCorrect)?.map((option, index) => (
                                <div key={option.id}>
                                    <div key={option.answerId} className={classes.optionContainer}>
                                        <h4 className={classes.answerText}>
                                            A{index + 1}. {option?.answer}
                                        </h4>
                                    </div>
                                </div>
                            ))}
                    </div>

                </div>
            ))}
        </div>
    );
};

export default GbaTypeMA;
