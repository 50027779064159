import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import DraggableList from "react-draggable-list"


// mui
import { Button, CircularProgress } from "@mui/material";

// components
import ScoreBox from "../score-box";

// utils
import isEmpty from "../../../utils/isEmpty";

// constants
import constants from "../../../constants";

// theme
import palette from "../../../theme/palette";

// styles
import useStyles from "./index.styles";

const IlaSequenceOne = (props) => {
    const {
        data,
        prev,
        next,
        attemptedIds,
        ilaId,
        scoreListRef,
        addScore,
        maxScore,
        scoreValueRef,
        scoreRef,
        handleChallengeAttemptData,
        styles,
        CorrectTemplate,
        IncorrectTemplate,
        SkipButton,
        isChallengeSubmitted,
        skipButtonDisplayTime,
        viewFullScreen
    } = props

    const classes = useStyles();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const [activeQindex, setActiveQindex] = useState(0);
    // const [showTemplate, setShowTemplate] = useState(false);

    const [viewSkipBtn, setViewSkipBtn] = useState(false);
    const [componentMounted, setComponentMounted] = useState(true);

    const [fSize, setFSize] = useState(1);

    const [answerList, setAnswerList] = useState([]);
    const [isSequenced, setIsSequenced] = useState(null);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const ilaRootRef = useRef();
    const ilaBoxRef = useRef();
    const scoreBoxRef = useRef();
    const isCorrectRef = useRef(false);
    const currentTimeRef = useRef(Date.now());
    const incorrectAttemptsIdsRef = useRef([]);
    const optionsBoxRef = useRef();
    const optionRef = useRef();
    const answersResponseRef = useRef([])

    const bufferTime = 400;
    const transitionMs = 1000;

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }
    useEffect(() => {
        window.addEventListener("resize", resFont);
        return window.removeEventListener("resize", resFont);
    }, [])

    useEffect(() => {
        return () => {
            setComponentMounted(false)
        }
    }, [])

    useEffect(() => {
        if (!isChallengeSubmitted || !componentMounted) return
        setTimeout(() => {
            setViewSkipBtn(true)
        }, skipButtonDisplayTime);
    }, [isChallengeSubmitted]);

    const setOrderToAnswers = () => {
        const newData = data[activeQindex].answers
        const newAnswers = newData.map((elem, index) => {
            elem["newOrder"] = index + 1

            return elem
        })
        setAnswerList(newAnswers)
    }
    useEffect(() => {
        setOrderToAnswers()
    }, [activeQindex])

    // submit button handler
    const handleSubmitBtnClick = async () => {
        // console.log("submit btn clicked", answerList)

        // handle score logic
        setIsTransitioning(true)
        let currentTime = Date.now();
        let item = data[activeQindex];
        let showScore = data[activeQindex].score > 0;

        let indexToSplice = attemptedIds.current.indexOf(ilaId);
        if (indexToSplice !== -1) {
            attemptedIds.current.splice(indexToSplice, 1);
        }

        let allCorrect = [];
        let draggable_items = document.querySelectorAll(".draggable-item");

        // using while loop to add some effects
        let index = 0;
        while (index < answerList.length) {
            let elem = answerList[index]
            if (elem.sequence === elem.newOrder) {
                draggable_items[index].style = `
                    background: ${palette.ilaCorrect1};
                `
                allCorrect.push(true)
            } else {
                draggable_items[index].style = `
                    background: ${palette.ilaInCorrect1};
                `
                allCorrect.push(false)
            }
            await sleep(bufferTime)
            index++
        }

        await sleep(bufferTime)

        // Handle Incorrect attempt
        if (allCorrect.includes(false)) {
            // console.log("something incorrect")
            isCorrectRef.current = false;

            handleChallengeAttemptData({
                qId: item.id,
                cardId: item.id,
                t: Math.round((currentTime - currentTimeRef.current) / 1000),
                aId: item.id,
                isCorrect: false
            });


        }
        // Handle Correct attempt
        else {
            // console.log("all correct")
            setIsSequenced(true)

            isCorrectRef.current = true;
            handleChallengeAttemptData({
                qId: item.id,
                cardId: item.id,
                t: Math.round((currentTime - currentTimeRef.current) / 1000),
                aId: item.id,
                isCorrect: true
            });
        }
        currentTimeRef.current = currentTime;

        if (showScore && !allCorrect.includes(false)) {
            // waiting to display score
            await sleep(bufferTime);

            if (isMobile) {
                ilaBoxRef.current.style.gridTemplateRows = constants.ILA_GRID_FINAL_MOBILE;
            } else {
                ilaBoxRef.current.style.gridTemplateRows = constants.ILA_GRID_FINAL;
                if (window.innerWidth < 1300 && window.innerWidth > 1024 && !viewFullScreen) {
                    optionsBoxRef.current.style.marginTop = '-0.5rem';
                }
            }
            scoreListRef.current.add(`${data[activeQindex].id}-${data[activeQindex].score}`);
            await addScore(data[activeQindex]?.score ? data[activeQindex].score : 0, true);

            await sleep(bufferTime * 2) // wait for score animation
        }
        // setShowTemplate(true);

        if (data.length - 1 > activeQindex) {
            if (data[activeQindex].mandatory && allCorrect.includes(false)) {
                // mandatory and incorrect
                setIsSequenced(false)

                // for try again template
                await sleep(transitionMs * 1.5)
                // setIsSequenced(null)
                handleRetryBtnClick()
                return;
            }
            else {
                // CORRECT
                await sleep(bufferTime * 3)
                setActiveQindex(prev => prev + 1)
            };
        } else {
            await sleep(bufferTime * 3)
            if (data[activeQindex].mandatory && allCorrect.includes(false)) {
                // mandatory and incorrect
                setIsSequenced(false)
                
                // for try again template
                await sleep(transitionMs * 1.5)
                // setIsSequenced(null)
                handleRetryBtnClick()
                return
            }
            attemptedIds.current.push(ilaId)
            next();
        }

        // // reset all style
        await sleep(bufferTime);

        isCorrectRef.current = null;
        setIsSequenced(null);
        setIsTransitioning(false)
        if (!isEmpty(optionsBoxRef.current)) optionsBoxRef.current.style.marginTop = '0';
        if (!isEmpty(ilaBoxRef.current)) {
            if (isMobile) {
                ilaBoxRef.current.style.gridTemplateRows = constants.ILA_GRID_INITIAL_MOBILE
            } else {
                ilaBoxRef.current.style.gridTemplateRows = constants.ILA_GRID_INITIAL
            }
        }
    }

    // Component for submit button
    const SubmitButton = () => {
        return (
            <div className={classes.btnBox}>
                <Button variant="contained"
                    className={`${classes.submitBtn}`}
                    onClick={handleSubmitBtnClick}>
                    Submit
                </Button>
            </div>
        )
    }

    // // Component for retry button
    const RetryButton = () => {
        return (
            // <div className={classes.btnBox}>
            //     <Button variant="contained"
            //         className={`${classes.submitBtn} ${classes.centerFlex}`}
            //         onClick={handleRetryBtnClick}>
            //         Try Again!
            //     </Button>
            // </div>
            <div
                // className={classes.correctIncorrectTextBox}
            >
                <IncorrectTemplate text={data[activeQindex].mandatory && 'TRY AGAIN!'} />
            </div>
        )
    }

    const handleRetryBtnClick = async () => {
        // setAnswerList(data[activeQindex].answers)
        setOrderToAnswers()
        document.querySelectorAll(".draggable-item").forEach(elem => elem.style = "")
        setIsSequenced("")
        await sleep(bufferTime * 3/4)
        setIsSequenced(null)
        setIsTransitioning(false)
        // setAnswersResponse([])
        answersResponseRef.current = []
    }


    const _onListChange = (newList) => {
        let newSeqList = newList.map((elem, index) => {
            elem["newOrder"] = index + 1
            return elem
        })
        setAnswerList(newSeqList);
    };

    const nodeRef = React.useRef()
    const DraggableItem = useMemo(() => {
        // useMemo used to prevent unnecessery re-rendering of this component
        // console.clear()

        return ({ item, itemSelected, dragHandleProps }) => {
            const { onMouseDown, onTouchStart } = dragHandleProps;
            // console.log({item});

            return (
                <div
                    className={classes.draggbleItem}
                    ref={nodeRef}
                >
                    <div
                        className="draggable-item"
                        // style={{
                        //     width: "100%",
                        //     height: "100%",
                        // }}
                        draggable={false}
                        onTouchStart={(event) => {
                            event.preventDefault();
                            // console.log("touchStart", event.target);
                            event.target.parentElement.style = `
                                box-shadow: ${constants.ILA_OPTION_BOX_SHADOW};
                                background-color: ${palette.linearGradientPrimary}
                            `
                            onTouchStart(event);
                        }}
                        onMouseDown={(event) => {
                            // console.log("mouseDown", event);
                            // event.target.style.boxShadow = constants.ILA_OPTION_BOX_SHADOW
                            event.target.parentElement.style = `
                                box-shadow: ${constants.ILA_OPTION_BOX_SHADOW};
                            `
                            onMouseDown(event);
                        }}
                        onTouchEnd={(event) => {
                            event.target.parentElement.style = ""
                            // event.target.style.boxShadow = ""
                        }}
                        onMouseUp={(event) => {
                            // event.target.style.boxShadow = ""
                            event.target.parentElement.style = ""
                        }}
                    >
                        {/* {item} */}
                        <div
                            className={`${classes.option} ${classes.centerFlex}`}
                        >
                            <div className={classes.answerBtn} id={`btn-2-${item.id}`}>{item.answer}</div>
                            <div className={`${classes.indexBtn} ${classes.centerFlex}`} id={`btn-1-${item.id}`}>
                                <img width="100%" height={isMobile ? "70%" : "50%"} src="/images/icons/drag-icon.svg" alt="drag" />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }, [])

    return (
        <>
            {
                isEmpty(data) ? (
                    <div className={`${classes.loaderBox} ${classes.centerFlex}`}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div className={classes.ilaRoot} ref={ilaRootRef} style={{
                        fontSize: `${fSize}rem`
                    }}>
                        <div className={classes.ilaBox} ref={ilaBoxRef}>
                            <div className={styles.questionHead}>{`Q${data[activeQindex].questionNo}`}</div>
                            <div className={`${styles.question} ${classes.centerFlex}`}>
                                {data[activeQindex].question}
                            </div>
                            <div className={classes.optionsContainer} >
                                <div className={classes.optionsBox} ref={optionsBoxRef}
                                    style={{ touchAction: "pan-y" }}
                                >
                                    {
                                        isTransitioning && <div className={classes.optionContainerOverlay}></div>
                                    }
                                    <DraggableList
                                        itemKey="id"
                                        template={DraggableItem}
                                        list={answerList}
                                        onMoveEnd={(newList) => _onListChange(newList)}
                                        container={() => optionsBoxRef.current}
                                        constrainDrag={true}
                                    />
                                </div>
                                {
                                    isCorrectRef.current !== true && <div
                                        className={classes.submitBtnContainer}
                                        style={{
                                            alignSelf: viewFullScreen || isMobile ? 'flex-start' : '',
                                            paddingTop: isMobile ? '0.5rem': ''
                                        }}
                                    >
                                        {
                                            isSequenced === null
                                                ? !isTransitioning && <SubmitButton /> // if transitioning is running, hide submit button
                                                : isSequenced === false && <RetryButton />
                                        }
                                    </div>
                                }
                            </div>
                            {
                                // additional styling optional rendering logic is writting to overcome ui merging conflicts
                                !isEmpty(isSequenced) && isSequenced === true && <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'flex-end',
                                }}>
                                    <ScoreBox scoreBoxRef={scoreBoxRef} scoreValueRef={scoreValueRef} maxScore={maxScore} score={scoreRef.current} styling={{ alignContent: 'flex-end' }} />
                                </div>
                            }

                            {
                                viewSkipBtn && (
                                    <div> <SkipButton /> </div>
                                )
                            }
                        </div>
                    </div >
                )
            }
        </>
    )
}

export default IlaSequenceOne;
