import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  MODULE_LIST: null,
  recentlyLearnedIds: [],
};

const microskillSlice = createSlice({
  name: 'microskill',
  initialState,
  reducers: {
    getAllModules: (state, action) => {
      state.MODULE_LIST = action.payload;
    },
    handleRecentlyLearned: (state, action) => {
      state.recentlyLearnedIds = action.payload;
    },
  },
});

export const { getAllModules, handleRecentlyLearned } = microskillSlice.actions;

export default microskillSlice.reducer;