import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";
import constants from "../../../constants";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    sideBarItemText: {
        whiteSpace: 'nowrap !important',
        '& span': {
            color: `${theme.palette.fontColor} !important`,
            paddingLeft: '1rem'
        }
    },
    listBox: {
        // width: 'max-content !important',
        paddingTop: `${theme.spacing(0.5)} !important`,
        paddingBottom: `${theme.spacing(0.5)} !important`,
        paddingLeft: '0.8rem !important',
        paddingRight: '0rem !important',
    },
    logoBox: {
        paddingLeft: '1.3rem !important',
        height: constants.HEADER_HEIGHT,
        marginBottom: '1.2rem',
    },
    anchorLink: {
        textDecoration: 'none',
    },
    iconBox: {
        height: '50px',
        width: '50px',
        borderRadius: '50%',
        padding: '0.12rem',
        // padding: '0.55rem',
    },
    iconBoxMobile: {
        height: '30px',
        width: '30px',
        borderRadius: '50%',
        padding: '0.12rem',
        // padding: '0.55rem',
    },
    highlightedIcon: {
        backgroundColor: `${theme.palette.fontColor}1A`,
    },
    icon: {
        // maxWidth: '28px',
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
    // langListBox: {
    //     width: '90%',
    // },
    langListMobile: {
        width: '100%',
        color: theme.palette.fontColor

    },
    langListItemButton: {
        paddingLeft: '0rem !important',
        width: '80% !important',
        // display: 'flex',
        justifyContent: 'space-between !important'
    },
    listItemButton: {
    },
    logoContainer: {
        gap: '1rem'
    },
    listMobile: {
        color: theme.palette.fontColor
    },
    sideDropDown: {
        backgroundColor: theme.palette.voilet,
        width: '100% !important'
    },
    listItemButtonSwitch: {
        paddingLeft: '3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },


    bgTransparent: {
        backgroundColor: 'transparent !important'
    },
    mainContainer: {
        display: 'grid',
        gridTemplateRows: '90% 10%'
    },
    companyBranding: {
        objectFit: 'contain',
        objectPosition: 'left',
        padding: '0 1rem 2rem',
        marginTop: 'auto',
        maxHeight: '100px',
        maxWidth: '60%',
        [theme.breakpoints.up('md')]: {
            // maxWidth: '90%',
            display: 'none'
        }
        // filter: theme.palette.mode === 'dark' ? 'brightness(0) invert(1)' : 'none',
    }
}))


export default useStyles