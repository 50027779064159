import { createSlice } from '@reduxjs/toolkit';
import constants from '../../constants';

const initialState = {
  screenState: constants.GBA_SCREEN_STATE.START,
  gameState: {},
};

const gbaSlice = createSlice({
  name: 'gba',
  initialState,
  reducers: {
    handleGbaScreenState: (state, action) => {
      state.screenState = action.payload;
    },
    handleGbaGameState: (state, action) => {
      state.gameState = action.payload;
    },
  },
});

export const { handleGbaScreenState, handleGbaGameState } = gbaSlice.actions;

export default gbaSlice.reducer;
