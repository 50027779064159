import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";
import constants from "../../../constants";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loaderBox: {
        height: '100%',
        width: '100%',
    },
    ilaRoot: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        transition: '0.7s',
        padding: '1rem',
    },
    ilaBox: {
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: constants.ILA_GRID_INITIAL,
        transition: '500ms',
        [theme.breakpoints.down("md")]: {
            gridTemplateRows: constants.ILA_GRID_INITIAL_MOBILE,
        }
    },
    questionBox: {
        display: 'grid',
        gridTemplateColumns: '20% 60%'
    },
    optionsContainer: {
        display: 'grid',
        alignItems: 'center'
    },
    optionsBox: {
        width: '80%',
        margin: 'auto',
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        alignContent: 'center',
        alignItems: 'center',
        // padding: '0 3rem'
        // gap: '1.5rem',
        [theme.breakpoints.down("md")]: {
            gridTemplateColumns: 'auto',
            gridTemplateRows: 'repeat(4, 1fr)',
            width: '95%',
        }
    },
    option: {
        // height: '60px',
        // marginBottom: '1.5rem',
        // padding: '0 1rem',
        height: '80px',
        padding: '0.75rem',
        cursor: 'pointer',
        position: 'relative',
        transition: 'position 0.7s',
        '-webkit-tap-highlight-color': 'transparent',
        tapHighlightColor: 'transparent',

    },
    optionBtn: {
        height: '100% !important',
        padding: '0 !important',
        color: '#FFF !important'
    },
    indexBtn: {
        backgroundColor: '#00000033',
        padding: '1rem',
        fontSize: '1.2rem',
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        height: '100%',
        aspectRatio: '1/1',
        overflow: 'hidden',
        borderRadius: '0.3rem 0 0 0.3rem'
    },
    answerBtn: {
        backgroundColor: palette.ilaCardBlue,
        padding: '1rem',
        fontSize: '0.9rem',
        height: '100%',
        borderRadius: '0 0.3rem  0.3rem 0',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        lineHeight: '1.1rem',
        justifyContent: 'flex-start',
        textAlign: 'left',
        [theme.breakpoints.down("sm")]: {
            fontSize: '1rem',
        }
    },
    correctIncorrectTextBox: {
        position: 'absolute',
        bottom: '-2rem'
    },
}))

export default useStyles