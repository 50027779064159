import { makeStyles } from "@mui/styles";
// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    magnifierContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    onlyImage: {
        height: '100%',
        width: '100%',
        maxHeight: 'max-content',
        objectFit: 'contain',
        borderRadius: '0.5rem',
        transition: 'filter 80ms linear',
    },
    imageLoader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: 90,
        transform: 'translate(-50%,-50%)',
        width: '100%',
        height: '100%',
    },
    imageLoaderAnime: {
        // content: '""',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: 99,
        backgroundColor: `rgba(0, 0, 0, 0.3)`,
        animation: '$loadingAnime 2s ease infinite',
    },
    '@keyframes loadingAnime': {
        '0%, 100%': {
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
        },
        '50%': {
            backgroundColor: `rgba(0, 0, 0, 0)`,
        }
    },
}))

export default useStyles