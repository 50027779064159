import axiosInstance from "../../utils/axios";

export const getUserPerformance = async (params = '') => {
    try {
        const respData = await axiosInstance.get(`/performance`);
        // console.log("respData:", respData)
        return respData;
    } catch (err) {
        console.log("getUserPerformance:", err);
        return err.response
        // dispatch(fetchFail(err));
    }
}

export const getPerformanceLeaderboard = async (microskillId) => {
    try {
        const respData = await axiosInstance.get(`/performance/leaderboard/${microskillId}`);
        return respData;
    } catch (err) {
        console.log("getPerformanceLeaderboard: ", err);
        return err.response;
    }
}

export const getSpotlightChallenges = async () => {
    try {
        const respData = await axiosInstance.get(`/performance/get-spotlight-challenges`);
        // console.log("respData:", respData)
        return respData;
    } catch (err) {
        console.log("getSpotlightChallenges:", err);
        return err.response
        // dispatch(fetchFail(err));
    }
}

export const getTopSpotlights = async (payload) => {
    try {
        const respData = await axiosInstance.post(`/performance/get-top-spotlights`, payload);
        return respData;
    } catch (err) {
        console.log('getTopSpotlights: ', err);
        return err.response;
    }
}