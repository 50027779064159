import React from "react";
import { useEffect } from "react";
import lottie from "lottie-web";

// mui

// components

// redux

// utils

// constants

// styles
import useStyles from "./index.styles";

// lottie
import durationLottie from '../../../lottie-assets/duration.json';
import speechRateLottie from '../../../lottie-assets/speech-rate.json';
import keywordMatchLottie from '../../../lottie-assets/keyword-match.json';
import clarityLottie from '../../../lottie-assets/clarity.json';
import transcriptMatchLottie from '../../../lottie-assets/transcript-match.json';
import toneLottie from '../../../lottie-assets/tone.json';
import { useTranslation } from "react-i18next";

const LOTTIE_ARRAY = [
    {
        id: 'durationLottie',
        lottie: durationLottie,
    },
    {
        id: 'speechrateLottie',
        lottie: speechRateLottie,
    },
    {
        id: 'keymatchLottie',
        lottie: keywordMatchLottie,
    },
    {
        id: 'clarityLottie',
        lottie: clarityLottie,
    },
    {
        id: 'transcriptmatchLottie',
        lottie: transcriptMatchLottie,
    },
    {
        id: 'toneLottie',
        lottie: toneLottie,
    },

]

const SpotlightResultLoader = (props) => {
    const {
        data,
    } = props;
    const classes = useStyles();
    const {t} = useTranslation()

    useEffect(() => {
        data.map(item => {
            let lottieFile = LOTTIE_ARRAY.find(d => d.id === `${item.name.toLowerCase()}Lottie`);
            lottie.loadAnimation({
                container: document.getElementById(`${item.name.toLowerCase()}Lottie`),
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: lottieFile.lottie,
                speed: 1
            })
            return item;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.lottieLoader}>
            <div className={classes.loadingText}>{t("Analyzing")}...</div>
            <div className={classes.card}>
                <div id="durationLottie" />
            </div>
            <div className={classes.card}>
                <div id="speechrateLottie" />
            </div>
            <div className={classes.card}>
                <div id="keymatchLottie" />
            </div>
            <div className={classes.card}>
                <div id="clarityLottie" />
            </div>
            <div className={classes.card}>
                <div id="transcriptmatchLottie" />
            </div>
            <div className={classes.card}>
                <div id="toneLottie" />
            </div>
        </div>
    )
}

export default SpotlightResultLoader;