import React, { useEffect, useRef, useState } from "react";

// mui
import { Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material";

// styles
import useStyle from "./index.styles";
import { useTranslation } from "react-i18next";

// actions

const StatusAccordion = (props) => {
    const { score, bgColor = "red", qualitativeFeedback, type="" } = props;
    const classes = useStyle();
    const qualitativeFeedbackRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false)
    const {t} = useTranslation()

    const handleTextChange = () => {
        if(type === 'NO_SCORE') return
        if (isExpanded) {
            // trackScreen(trackingData.userId, trackingData.moduleId, trackingData.challengeId, `${type}_CLICK`, 'Role play result page');
            qualitativeFeedbackRef.current.innerHTML = t('YOUR SCORE');
            qualitativeFeedbackRef.current.style.fontSize = '0.7rem';
        } else {
            // trackScreen(trackingData.userId, trackingData.moduleId, trackingData.challengeId, `${type}_CLICK`, 'Role play result page');
            qualitativeFeedbackRef.current.innerHTML = qualitativeFeedback;
            qualitativeFeedbackRef.current.style.fontSize = '1rem';
        }
    }
    const handleAccordionChange = (e, expanded) => { }
    const handleOnclick = (e) => {
        if(type === 'NO_SCORE') return
        setIsExpanded(!isExpanded);
    }
    useEffect(() => {
        handleTextChange()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpanded])
    return (
        <div className={classes.accordionRoot}>
            <Accordion style={{ backgroundColor: bgColor }} className={classes.accordion}
                onChange={(event, expanded) => handleAccordionChange(event, expanded)}
                onClick={handleOnclick}
                expanded={isExpanded}
            >
                <AccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography
                        className={classes.accordionHeading}
                        style={{ backgroundColor: bgColor }}
                        ref={qualitativeFeedbackRef}
                    >
                        {qualitativeFeedback}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: bgColor }}>
                    <Typography className={classes.accordionDetails} >
                        {Math.round(score)}
                        {/* {Math.floor(score)} */}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
export default StatusAccordion;