import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    microskillCardContainer: {
        marginBottom: '2rem'
    },
    salutationText: {
        fontWeight: 'bold',
        fontSize: '2.5rem',
        color: '#FFF',
        margin: '2rem 0'
    },
    classificationText: {
        fontWeight: 'bold',
        fontSize: '1.2rem',
        padding: '1rem 0',
        // margin: '3rem 0 1rem 0',
        // padding: '0 0.4rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            padding: '1rem 0',
            // margin: '1.2rem 0 1rem 0',
        },
    },
    loginBox: {
        [theme.breakpoints.up('sm')]: {
            width: '75%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    mobileMicroskillContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            flex: '0 1 50%'
        },
        [theme.breakpoints.down('sm')]: {
            '& > *': {
                flex: '1 1 50%'
            },
        },
    },
    expandBox: {
        width: '100%',
        display: 'grid',
        justifyItems: 'center',
        alignContent: 'center',
        fontSize: '0.8rem',
        marginTop: '0.8rem',
        cursor: 'pointer'
    },
    reactCustomClass: {
        '& .react-multiple-carousel__arrow--right': {
            right: 'calc(0% + 1px)'
        },
        '& .react-multiple-carousel__arrow--left': {
            left: 'calc(0% + 1px)',
        },
        '& .react-multiple-carousel__arrow': {
            backgroundColor: palette.orange,
            '& ::before': {

            }
        }
    },

    customCarouselArrow: {
        position: 'absolute !important',
        height: '43px !important',
        width: '43px !important',
        borderRadius: '50% !important',
        padding: '0 !important',
        minWidth: '43px !important',
    },
    customCarouselRightArrow: {
        right: '0',
    },
    customCarouselLeftArrow: {
        left: '0',
    }
}))

export default useStyles