import axiosInstance from "../../utils/axios";
// import { getAllModules } from "./learn.actions";

export const submitChallengeAttemptData = async (payload = {}) => {
    try {
        const respData = await axiosInstance.post(`/challenge-attempt-data`, payload);
        return respData;
    } catch (err) {
        console.log("submitChallengeAttemptData:", err)
        // dispatch(fetchFail(err));
    }
}

export const submitEndChallengeAttempt = async (payload = {}) => {
    try {
        const respData = await axiosInstance.post(`/end-challenge-attempt`, payload);
        return respData;
    } catch (err) {
        console.log("submitEndChallengeAttempt:", err)
        // dispatch(fetchFail(err));
    }
}

export const getLearnerJourneyStatus = async (payload = {}) => {
    try {
        const respData = await axiosInstance.post(`/journey-status`, payload);
        return respData;
    } catch (err) {
        console.log("getLearnerJourneyStatus:", err)
        // dispatch(fetchFail(err));
    }
}
