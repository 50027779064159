import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import { toggleSideBar } from "../../../../actions/challengeListAction";
import GbaHeader from "../../common-screens/header";

// Style
import useStyle from "./index.styles";
import isEmpty from "../../../../utils/isEmpty";
import { useTranslation } from "react-i18next";

// images
const play_btn = '/images/gba/play_btn.svg';

const HowToPlay = ({ qstnBoxBg, setIsGamePage }) => {
    const isMobile = useSelector(state => state?.common?.isMobile);
    const classes = useStyle(isMobile);
    const dispatch = useDispatch();
    const [reload, setReload] = useState(true);
    const [isDropContainerVisible, setIsDropContainerVisible] = useState(false);
    const [dropContainerWidth, setDropContainerWidth] = useState(100);
    const questionRef = useRef([]);
    const containerRef = useRef(null);
    const headerQstnBox = useRef(null);
    const arrowImgRef = useRef(null);
    const displayQstnRef = useRef(null);
    const dataContainerRef = useRef(null);
    const progressBarRef = useRef(null);
    const overlayRef = useRef(null);
    const qstnBoxRef = useRef(null);
    const lifeTimeRef = useRef(null);
    const progressTextRef = useRef(null);
    const dropContainerRef = useRef();
    const playBtnRef = useRef();
    const playBtnTextRef = useRef();
    const playerRef = useRef();
    const optionContainerRef = useRef();
    const dragDropTextRef = useRef();
    let dropCircleOpacityArray = ["0.3", "0.4", "0.5", "1"];

    const { t } = useTranslation();

    let isDragActive = true;
    let container = null;
    let dragItem = null;
    let active = false;
    let currentX;
    let currentY;
    let initialX;
    let initialY;
    let xOffset = 0;
    let yOffset = 0;
    let instructionNo = 2;
    let noOfCircles = 5;
    let circleOffset = 140;
    let containerId = 'sounds-right-container-htp';

    useEffect(() => {
        let containerWidth = dropContainerRef.current ? dropContainerRef.current.clientHeight : dropContainerWidth
        setDropContainerWidth(containerWidth);
        setTimeout(() => {
            setIsDropContainerVisible(true)
        }, 500);
    });

    const addListner = async () => {
        const sleep = (ms) => new Promise((res) => setTimeout(res, ms));
        await sleep(10);

        container = document.getElementById(containerId);
        if (container === null) return;

        container.addEventListener("touchstart", dragStart, false);
        container.addEventListener("touchend", dragEnd, false);
        container.addEventListener("touchmove", drag, false);

        container.addEventListener("mousedown", dragStart, false);
        container.addEventListener("mouseup", dragEnd, false);
        container.addEventListener("mousemove", drag, false);
    }

    const nextInstruction = (instNo, e) => {
        let targetId = e?.target?.id;

        if (instructionNo === 2 && targetId === 'overlay') {
            headerQstnBox.current.style.zIndex = 'auto';
            playBtnRef.current.style.zIndex = '3';
            playBtnTextRef.current.style.zIndex = '3';
            playBtnTextRef.current.style.opacity = '1';
            playerRef.current.style.zIndex = '3';
            overlayRef.current.removeEventListener('onclick', nextInstruction);
        }
        else if (instNo === 4 && instructionNo === 4) {
            //  instructionNo++;
            dataContainerRef.current.style.opacity = '0';
            setTimeout(() => {
                dataContainerRef.current.style.display = 'none';
                displayQstnRef.current.style.display = 'none';
                qstnBoxRef.current.style.zIndex = "2";
                lifeTimeRef.current.style.visibility = 'visible';
            }, 800);
        } else if (instNo === 2 && instructionNo === 4 && targetId === 'overlay') {
            instructionNo = 5;
            progressTextRef.current.style.display = 'grid';
            qstnBoxRef.current.style.zIndex = "1";
            lifeTimeRef.current.style.animation = 'auto';
            displayQstnRef.current.style.opacity = "1";
        } else if (instNo === 2 && instructionNo === 5 && targetId === 'overlay') {
            setIsGamePage(true);
        }
    }

    const handleRestartClick = () => {
        setReload(false);
        setTimeout(() => { setReload(true) })
    }

    const handlePlayBtnClick = () => {
        playBtnRef.current.style.zIndex = 'auto';
        playBtnRef.current.style.display = 'none';
        playBtnTextRef.current.style.zIndex = 'auto';
        playBtnTextRef.current.style.opacity = '0';
        playerRef.current.style.zIndex = 'auto';
        playerRef.current.style.display = 'none';
        dropContainerRef.current.style.zIndex = '3';
        optionContainerRef.current.style.zIndex = '3';
        dragDropTextRef.current.style.display = 'block';
        addListner();
    }

    // Drag and Drop code logic starts here.
    const dragStart = (e) => {
        dragItem = document.getElementById(e?.target?.id);
        if (!isDragActive) return
        if (isEmpty(dragItem)) return;
        if (dragItem.getAttribute('drag') !== 'true') return;
        dragItem.style.transition = "0s";
        dragItem.style.boxShadow = '0px 2px 4px -2px #000';

        if (e.type === "touchstart") {
            initialX = e.touches[0].clientX - xOffset;
            initialY = e.touches[0].clientY - yOffset;
        } else {
            initialX = e.clientX - xOffset;
            initialY = e.clientY - yOffset;
        }
        active = true;
    }

    const drag = (e) => {
        if (!isDragActive) return
        if (dragItem == null) return;
        if (!active) return;
        if (dragItem.getAttribute('drag') !== 'true') return;

        e.preventDefault();
        if (e.type === "touchmove") {
            currentX = e.touches[0].clientX - initialX;
            currentY = e.touches[0].clientY - initialY;
        } else {
            currentX = e.clientX - initialX;
            currentY = e.clientY - initialY;
        }
        xOffset = currentX;
        yOffset = currentY;
        setTranslate(currentX, currentY, dragItem, true);

        let dropEl = document.getElementById('drop-box');
        if (!isEmpty(dropEl)) {
            // check option is dragged into the center
            // if(isOverlapping(dragItem, dropEl)){
            //     if(isEmpty(dropTextRef.current)) return;
            //     dropTextRef.current.style.transform = 'scale(1)';
            // } else{
            //     dropTextRef.current.style.transform = 'scale(0)';
            // }
        }
    }

    const dragEnd = async (event) => {
        if (dragItem === null) return;
        if (dragItem.getAttribute('drag') !== 'true') return;
        isDragActive = false;
        dragItem.style.boxShadow = 'none';

        xOffset = yOffset = 0;
        active = false;
        dragItem.style.transition = "0.5s";
        let dropEl = document.getElementById('drop-box');

        if (!isEmpty(dropEl)) {
            // check option is dragged into the center
            if (isOverlapping(dragItem, dropEl)) {
                // scale down the dropped answer
                // console.log("overlapping ");
                dragItem.style.transform = "translate3d(" + currentX + "px, " + currentY + "px, 0) scale(0)";
                dragItem.style.transition = ".4s";
                dragItem.style.opacity = '0';

                console.log("next intruction");

                setTimeout(() => {
                    optionContainerRef.current.style.zIndex = 'auto';
                    dragDropTextRef.current.style.display = 'none';
                    dataContainerRef.current.style.display = 'grid';
                    dropContainerRef.current.style.zIndex = 'auto';
                    dataContainerRef.current.style.opacity = '1';
                }, 800)
            } else {
                setTranslate(0, 0, dragItem, false);
            }
        }
    }

    const setTranslate = (xPos, yPos, el, isIntitalTranslate) => {
        if (el === null) return
        if (!isIntitalTranslate) {
            el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0) scale(1)";
        } else {
            el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0) scale(1)";
        }
        isDragActive = true;
    }

    const isOverlapping = (e1, e2) => {
        if (e1.length && e1.length > 1) {
            e1 = e1[0];
        }
        if (e2.length && e2.length > 1) {
            e2 = e2[0];
        }
        const rect1 = e1 instanceof Element ? e1.getBoundingClientRect() : false;
        const rect2 = e2 instanceof Element ? e2.getBoundingClientRect() : false;

        let overlap = false;
        if (rect1 && rect2) {
            let ratio = 1.1;
            overlap = !(
                (rect1.right / ratio) < rect2.left ||
                rect1.left * ratio > rect2.right ||
                rect1.bottom / ratio < rect2.top ||
                rect1.top * ratio > rect2.bottom
            );
            return overlap;
        }
        console.warn('Please provide valid HTMLElement object');
        return overlap;
    }

    return (
        reload ? (
            <div className={classes.howToPlayRoot}>
                <div className={classes.container}>
                    <div className={classes.overlay} ref={overlayRef} id={`overlay`}
                        onClick={(e) => nextInstruction(2, e)} >
                        <div className={classes.progressText} ref={progressTextRef}>
                            <div className={classes.arrow2Box}>
                                <img src="/images/gba/arrow2.png" className={classes.arrowImg2} alt="arrow" width={50} />
                            </div>
                            <div className={classes.completionText}>Track completion with the progress bar</div>
                        </div>

                        <div className={classes.dataContainer} ref={dataContainerRef}>
                            <div className={`${classes.centerFlex} ${classes.readyText}`}>READY TO PLAY?</div>
                            <div className={`${classes.centerFlex}`}>
                                <button className={`${classes.startBtn} ${classes.cursorPointer}`} id={`startGameBtn`}
                                    onClick={() => { instructionNo = 4; nextInstruction(4) }}>
                                    START GAME
                                </button>
                            </div>
                            <div className={`${classes.centerFlex}`} >
                                <span className={`${classes.restartText} ${classes.cursorPointer}`} onClick={handleRestartClick}>RESTART INSTRUCTIONS</span>
                            </div>
                        </div>
                    </div>

                    <div className={classes.headerQstnBox} ref={headerQstnBox}>
                        {/* <GbaHeader title={title} height={'40%'}
                            handleBackButtonClick={challengeList}
                            headerBg={headerBg} /> */}
                        <div className={classes.progressBarContainer} ref={progressBarRef}>
                            <div className={classes.progressBar} />
                        </div>
                        {/* QUESTION BOX */}
                        <div className={classes.qstnContainer} ref={qstnBoxRef}>
                            <div className={classes.qstnBox} style={{ backgroundColor: `${qstnBoxBg}` }}>
                                <div className={classes.lifeTime} ref={lifeTimeRef}>
                                    <div className={classes.centerFlex}>
                                        <div className={classes.assetsText}>4 X </div>
                                        <img src='/images/icons/heart.png' className={classes.icon} alt="heart" width="10" />
                                    </div>
                                    <div className={classes.centerFlex}>
                                        <img src='/images/icons/stopwatch.png' className={classes.icon} alt="heart" width="10" />
                                        <div className={classes.assetsText}>120s</div>
                                    </div>
                                </div>
                                <div className={`${classes.qstn} ${classes.centerFlex}`} ref={displayQstnRef}> {t("Questions will appear inside this box")} </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.bottomContainer} id={containerId}>
                        <div className={classes.dropCircleContainer} ref={dropContainerRef}>
                            {
                                isDropContainerVisible ? (
                                    Array.from(Array(noOfCircles), (e, i) => {
                                        let circleWidth = dropContainerWidth - (i * 50) - circleOffset;
                                        return (
                                            (i === noOfCircles - 1) ? (
                                                <section key={i}>
                                                    <section className={classes.playBox} ref={playBtnTextRef}>
                                                        <div className={classes.playText}>{t("Tap to play audio")}</div>
                                                        <img src={'/images/gba/arrow.png'}
                                                            alt="play pause"
                                                            className={classes.arrow} />
                                                    </section>
                                                    <img src={play_btn}
                                                        alt="play pause"
                                                        style={{ width: `${circleWidth / 3.5}px` }}
                                                        className={classes.playBtn}
                                                        ref={playBtnRef}
                                                        onClick={handlePlayBtnClick} />
                                                    <div className={classes.dragDropText} ref={dragDropTextRef}>
                                                        {t("Drag & Drop Your Answer")}
                                                    </div>
                                                </section>
                                            ) : (
                                                <div key={i} id={i === noOfCircles - 2 ? 'drop-box' : `life-${i + 1}`}
                                                    style={{ width: `${circleWidth}px`, height: `${circleWidth}px`, opacity: `${dropCircleOpacityArray[i]}` }}>
                                                </div>
                                            )
                                        )
                                    })
                                ) : (null)
                            }
                        </div>
                        <div className={classes.player} ref={playerRef}>
                            <div className={classes.track}>
                                <div className={classes.thumb}></div>
                            </div>
                            <div className={classes.timer}>
                                <div className={classes.timer1}>0:00</div>
                                <div className={classes.timer2}>2:00</div>
                            </div>
                        </div>

                        <div className={classes.optionContainer} ref={optionContainerRef}>
                            <button drag="true"
                                id={`option-1`}>
                                {'Correct Answer'}
                            </button>
                            <button drag="true"
                                id={`option-2`}>
                                {'Wrong Answer'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        ) : (null)
    )
};

export default HowToPlay
