import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import FreshChat from "react-freshchat";
import { isIOS, useDeviceSelectors } from "react-device-detect";
import { useMsal } from "@azure/msal-react";

// mui
import { LoadingButton } from "@mui/lab";
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

import useMediaQuery from '@mui/material/useMediaQuery';
import theme from "../../theme";

// components
import DialogAlert from "../dialog-alert";
import LoadingMicroskill from "../loading-microskill";

// utils
import isEmpty from "../../utils/isEmpty"
import commonUtil from "../../utils/commonUtil";
// import { isNativeApp } from '../../utils/commonUtil';
import { encrypt, decrypt } from "../../utils/crypto"

// redux
import { setIsNativeApp } from "../../redux/common/common-slice";
import { submitUserActivityData } from "../../redux/event-tracking/event.api";
import { setMsalRequestToken, setToken } from "../../redux/auth/auth-slice";
import { signIn, validateThirdPartyUser } from "../../redux/auth/auth.api";
import { LoginButtonClick, LoginPage } from "../../redux/event-tracking/event-tracking-slice";
import { submitUserLoginActivity } from "../../redux/events/events.api";

// Google auth
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

// styles
import useStyles from "./index.styles"
import palette from "../../theme/palette";

// okta
import { useOktaAuth } from "@okta/okta-react"

// Msal Browser React AZURE
import MsalConfig from "../../company-integrations/msal/msalConfig";
import constants from "../../constants";
import LoadingScreen from "../loading-screen";
import webViewJavaScriptBridge from 'webview-javascript-bridge';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const GOOGLE_BASE_URI = `${process.env.REACT_APP_API_BASE_URI}/api/bypass/google-oauth`;

const Login = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userActivityData = useSelector(state => state.event);
    // const isMobile = useSelector(state => state?.common?.isMobile);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const isTablet = useMediaQuery(theme.breakpoints.down('md'))

    const isNativeApp = useSelector(state => state?.common?.isNativeApp);
    const deviceDetails = useSelector(state => state?.commonPersist?.deviceDetails);
    const msalRequestToken = useSelector(state => state?.auth?.msalRequestToken);
    const oktaRequestToken = useSelector(state => state?.auth?.oktaRequestToken);
    const isNativeAppState = useSelector(state => state?.common?.isNativeApp);
    const isSplashScreenViewed = useSelector(state => state?.commonPersist?.isSplashScreenViewed);

    const privacyPolicy = useSelector(state => state?.commonPersist?.privacyPolicyDetails);

    const { state } = useLocation();
    const firstRenderRef = useRef(true);
    const year = new Date().getFullYear();

    const { authState, oktaAuth } = useOktaAuth();
    const { instance, accounts } = useMsal();
    const [fSize, setFSize] = useState(1);

    const [btnLoading, setbtnLoading] = useState(false);
    const [otpBtnLoading, setotpBtnLoading] = useState(false);
    const [userName, setUserName] = useState(null);
    const [password, setPassword] = useState('');
    const [requestToken, setRequestToken] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showLoadingMicroskill, setShowLoadingMicroskill] = useState(false);
    const [dialogContent, setDialogContent] = useState('Please enter username/password before continuing');
    const [isRequestOtp, setIsRequestOtp] = useState(true);
    const [showFreshChat, setShowFreshChat] = useState(false);
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
    const [initialHeight, setInitialHeight] = useState(window.innerHeight);
    const [showLoadingScreen, setShowLoadingScreen] = useState(true);

    // const [isUserNameFocused, setIsUserNameFocused] = useState(state?.redirectedFrom);

    // console.log("isUserNameFocused", isUserNameFocused);


    const checkAlkemUserIntervalRef = useRef();
    const checkAlkemUserTimeoutRef = useRef();
    const usernameRef = useRef(null);

    const chatRef = useRef();
    var freshchatWidget;
    const [selectors, deviceData] = useDeviceSelectors(window.navigator.userAgent);

    const isMsalLogin = useRef(false);
    const isOktaLogin = useRef(false);
    const loginDataRef = useRef();

    const userUnique = new Date();
    localStorage.setItem('userUniqueFreshChatID', userUnique);

    useEffect(() => {
        setFSize(window.innerHeight / 961);
    }, [])

    const resFont = () => {
        setFSize(window.innerHeight / 961);
    }

    const loggedOutState = localStorage.getItem("loggedOut");
    useEffect(() => {
        console.log({ loggedOutState })
        if (loggedOutState) {
            setShowLoadingScreen(false);
            localStorage.removeItem("loggedOut");
        }
    }, [loggedOutState])

    window.addEventListener("resize", resFont);

    let isNative = localStorage.getItem("isNative");


    // useEffect(() => {
    //     // isUsernameFocused.current = state?.redirectedFrom;
    //     if (isEmpty(state)) return;
    //     if (isEmpty(state?.redirectedFrom)) return;
    //     if (state?.redirectedFrom === 'otp-login') {
    //         console.log('state = ', state);
    //         console.log('usernameRef.current = ', usernameRef.current);
    //         if (!isEmpty(usernameRef?.current)) {
    //             usernameRef?.current?.focus();
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [state, usernameRef?.current]);



    useEffect(() => {
        // callback function to call when event triggers
        const onPageLoad = () => {
            console.log('Page loaded');
            if (isNative) {
                webViewJavaScriptBridge.sendMessage({ action: 'TurnOffNativeSplashScreenChannel', params: true })
            }
            // do something else
        };

        // Check if the page has already loaded
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    useEffect(() => {
        if (!isMobile) {
            setShowLoadingScreen(false);
            return
        }
        let checkLoading = 1;
        checkAlkemUserIntervalRef.current = setInterval(() => {
            checkLoading++;
            if (checkLoading > 5) {
                clearInterval(checkAlkemUserIntervalRef.current);
            }
            console.log("window.messageForAlkemUserNativeCompanyCode: ", window.messageForAlkemUserNativeCompanyCode)
            console.log("window.messageForAlkemUserNativeEncryptedText: ", window.messageForAlkemUserNativeEncryptedText)
            checkAlkemUserNative();
        }, 1000);

        checkAlkemUserTimeoutRef.current = setTimeout(() => {

            if (state?.redirectedFrom === 'otp-login') {
                if (!isEmpty(usernameRef?.current)) {
                    usernameRef?.current?.focus();
                }
            }

            if (!isEmpty(window.messageForAlkemUserNativeCompanyCode) && isEmpty(window.messageForAlkemUserNativeEncryptedText)) {
                setIsDialogOpen(true);
                setShowLoadingScreen(false);
                setDialogContent("You are not authorised to use Master-O. Please contact your company administrator.");
            }
            setShowLoadingScreen(false);
            clearTimeout(checkAlkemUserIntervalRef.current);

        }, 5000);

        return () => {
            clearInterval(checkAlkemUserIntervalRef.current);
            clearTimeout(checkAlkemUserTimeoutRef.current);
        }
    }, []);


    useEffect(() => {
        const handleResize = () => {
            const currentHeight = window.innerHeight;
            setIsKeyboardOpen(currentHeight < Math.max(initialHeight, 550));
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [initialHeight]);

    useEffect(() => {
        if (firstRenderRef.current) {
            dispatch(LoginPage({ oldScreen: userActivityData?.currentScreen, currentScreen: 'Login', activityData: { message: 'Login page loaded' } }));
            firstRenderRef.current = !firstRenderRef.current;
        } else {
            console.log({ userActivityData })
            submitUserActivityData(userActivityData);
        }
    }, [userActivityData])

    useEffect(() => {
        window.fcWidgetMessengerConfig = {
            config: {
                headerProperty: {
                    hideChatButton: true,
                }
            }
        }
        if (showFreshChat) {
            document.getElementById('fc_frame').style.display = 'block'
            if (isNative === 'true') {
                webViewJavaScriptBridge.sendMessage({ action: 'disableresizeToAvoidBottomInset', params: true });
            }
            // document.getElementById('fc_frame').style.paddingTop = isIOS ? constants.HEADER_TOP_PADDING : "1rem"  // to resolve conflict with native header in ios devices
            window.fcWidget.open();
            window.fcWidget.show();
        }
        else {
            try {
                if (document.getElementById('fc_frame') === null) return
                document.getElementById('fc_frame').style.display = 'none'
                if (isNative === 'true') {
                    webViewJavaScriptBridge.sendMessage({ action: 'disableresizeToAvoidBottomInset', params: false });
                }
                // document.getElementById('fc_frame').style.paddingTop = "0rem";
                setShowFreshChat(false);
                window.fcWidget.hide();
                window.fcWidget.close();
            } catch (err) {
                console.log(err);
            }
        }
    }, [showFreshChat]);

    useEffect(() => {
        if (window.isNative || window.deviceDetails || window.deviceToken) {
            dispatch(setIsNativeApp(true));
        }
    }, [window.isNative, window.deviceDetails, window.deviceToken]);

    useEffect(() => {
        if (!isMobile) return;

        (async function () {
            if (window.messageForAlkemUserNative?.companyCode !== undefined) {
                setShowLoadingMicroskill(true);

                if (window.messageForAlkemUserNative?.encryptedText === '') {
                    setIsDialogOpen(true);
                    setDialogContent("You are not authorised to use Master-O. Please contact your company administrator.");
                    setShowLoadingMicroskill(false);
                } else {
                    var req = {
                        encryptedText: window.messageForAlkemUserNative?.encryptedText,
                        companyCode: window.messageForAlkemUserNative?.companyCode
                    }
                    const resData = await validateThirdPartyUser(req);
                    if (resData.status !== 200) {
                        setIsDialogOpen(true);
                        const message = resData?.data?.errors[0].message;
                        setDialogContent(message);
                        setShowLoadingMicroskill(false);
                        return;
                    } else {
                        req = {
                            username: resData.data.data.username,
                            password: ''
                        }
                        const signInUser = await signIn(req);
                        if (signInUser.status === 200 || signInUser.status === 201) {
                            navigate('/learn');
                        }
                    }
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile, window.messageForAlkemUserNative?.companyCode]);


    const checkAlkemUserNative = async () => {
        if (!isEmpty(window.messageForAlkemUserNativeCompanyCode) && !isEmpty(window.messageForAlkemUserNativeEncryptedText)) {
            clearInterval(checkAlkemUserIntervalRef.current);
            clearTimeout(checkAlkemUserTimeoutRef.current);
            setShowLoadingScreen(false);
            setShowLoadingMicroskill(true);
            var req = {
                encryptedText: window.messageForAlkemUserNativeEncryptedText,
                companyCode: window.messageForAlkemUserNativeCompanyCode
            }
            const resData = await validateThirdPartyUser(req);
            if (resData.status !== 200) {
                setIsDialogOpen(true);
                const message = resData?.data?.errors[0].message;
                setDialogContent(message);
                setShowLoadingMicroskill(false);
                return;
            } else {
                req = {
                    username: resData.data.data.username,
                    password: ''
                }
                const signInUser = await signIn(req);
                if (signInUser.status === 200 || signInUser.status === 201) {
                }
            }
        }
    }

    // window.onload = function () {
    //     if (!isEmpty(window.TurnOffNativeSplashScreenChannel)) {
    //         console.log("TurnOddNativeSplashScreenChannel is called")
    //         window.TurnOffNativeSplashScreenChannel.postMessage("true");
    //     }
    // }

    // check page is completely loaded using window.onload
    useEffect(() => {
        if (!isMobile) return;

        let check = 0;
        const interval = setInterval(() => {
            check++;
            if (check >= 5) {
                clearInterval(interval);
            }

            console.log("checking for window.TurnOffNativeSplashScreenChannel")
            if (!isEmpty(window.TurnOffNativeSplashScreenChannel)) {
                window.TurnOffNativeSplashScreenChannel.postMessage("true");
                console.log("TurnOddNativeSplashScreenChannel is called")
            }
            console.log("checking for document.TurnOffNativeSplashScreenChannel");
            if (!isEmpty(document.TurnOffNativeSplashScreenChannel)) {
                document.TurnOffNativeSplashScreenChannel.postMessage("true");
                console.log("TurnOddNativeSplashScreenChannel is called")
            }
        }, 2000);
    }, [window.TurnOffNativeSplashScreenChannel, isMobile]);


    // MSAL and Okta REQUEST TOKEN STORE in redux
    useEffect(() => {
        if (isMsalLogin.current && !isEmpty(msalRequestToken)) {
            handleMsalLogin();
        }
    }, [isMsalLogin, msalRequestToken])

    useEffect(() => {
        (async function () {
            if (isOktaLogin.current && oktaRequestToken !== null) {
                await oktaAuth.signInWithRedirect({ originalUri: "/okta" });
            }
        })();
    }, [isOktaLogin, oktaRequestToken])

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSignInClick = async (e) => {
        e.preventDefault();

        if (isEmpty(userName)) {
            setIsDialogOpen(true);
            setDialogContent('Please enter your User ID');
            return;
        }

        setIsRequestOtp(false);
        setbtnLoading(true);

        if (isPasswordVisible && isEmpty(password)) {
            setIsDialogOpen(true);
            setDialogContent('Password cannot be empty, please enter your password.');
            setbtnLoading(false);
            return;
        }

        const req = {
            username: userName,
            password: isEmpty(password) ? password : encrypt(password),
        }

        dispatch(LoginButtonClick({ activityData: { username: userName } }));
        if (!isEmpty(requestToken)) req['requestToken'] = requestToken;

        const respData = await signIn(req);
        // console.log("respData: ", respData)
        // setShowLoadingMicroskill(true);
        console.log('respData : ', respData);

        if (respData?.status === 200 || respData?.status === 201) {
            loginDataRef.current = respData.data.data;

            if (loginDataRef.current.tokens.accessToken) {
                submitUserActivityData({ oldScreen: '', currentScreen: 'LOGIN', nextScreen: 'LEARN', activityType: 'SCREEN_CHANGE', activityData: { username: userName, loginData: loginDataRef.current } });
            } else {
                dispatch(LoginButtonClick({ activityData: { username: userName, loginData: loginDataRef.current } }));
            }

            if (loginDataRef.current.loginType === 'WITH_PASSWORD') {
                setRequestToken(loginDataRef.current?.tokens?.requestToken);
                setIsPasswordVisible(true);
                if (!isPasswordVisible) setbtnLoading(false);


                // MFA : 2FA(Password & email/mobile)

                const MFA_enabled = loginDataRef.current.authentication?.MFA_enabled;

                if (!isEmpty(userName) && !isEmpty(password) && MFA_enabled) {

                    const MFA_type = loginDataRef.current.authentication.MFA_type;
                    navigate(`/otp-login`, { state: { MFA_enabled, otpHeaderText: MFA_type, isChangeEmailId: false } });
                    return;
                }

            } else if (loginDataRef.current.loginType === 'INTEGRATION') {
                // DO THE INTEGRATION STUFF HERE
                if (loginDataRef.current.authentication.provider === "OKTA") {
                    dispatch(setMsalRequestToken(loginDataRef.current?.tokens?.requestToken));
                    isMsalLogin.current = true;
                    // console.log("request token from login = ", loginDataRef.current?.tokens?.requestToken);
                }
            }
        } else if (respData?.status === 400 || respData?.status === 401) {
            setbtnLoading(false);
            if (respData.status === 400) {
                let managerLoginError = 'Login for manager is not granted';
                let message = 'User ID does not exist, please contact your company administrator.';
                if (respData.data?.errors[0]?.message === managerLoginError) message = managerLoginError;
                setIsDialogOpen(true);
                setDialogContent(message);
                setIsRequestOtp(true);
            }
            if (respData.status === 401) {
                setIsDialogOpen(true);
                setDialogContent('Incorrect Password. Please try again.');
                setIsRequestOtp(true);
            }

            const device = window.isNative === undefined || isNativeApp === false ? selectors.osName : deviceDetails.os;
            const details = window.isNative === undefined || isNativeApp === false ? commonUtil.getDeviceDetails(selectors, respData?.datax?.errors) : {};
            respData.errors = respData?.data?.errors;
            respData.username = userName;

            if (isPasswordVisible) {
                details.password = password;
            }
            submitUserLoginActivity({ username: userName, device: device, screen: 'LOGIN_USERNAME', details });
        } else {
            console.log('some error occured')
            setbtnLoading(false);
        }
    }

    const handleMsalLogin = async (req) => {
        try {
            let loginInstance = await instance.loginRedirect({
                redirectStartPage: `/msal`,
                scopes: MsalConfig.scopes,
                account: accounts[0]
            });
        } catch (err) {
            setIsDialogOpen(true);
            setDialogContent('An unknown error occurred, please contact Microsoft or your company administrator for more details.');
        }
    }

    // const handleMsalLogin = async (req) => {
    //     const msalConfigReq = {
    //         scopes: MsalConfig.scopes,
    //         // loginHint: "kundankumar@masteroapp.com"
    //     };
    //     try {
    //         const msalResponse = await instance.ssoSilent(msalConfigReq);
    //         req['username'] = msalResponse.account.username;
    //         await signIn(req);
    //     } catch (err) {
    //         if (err instanceof InteractionRequiredAuthError) {
    //             const msalResponse = await instance.loginPopup(msalConfigReq).catch(error => {
    //                 setIsDialogOpen(true);
    //                 setDialogContent('Error occured while logging into to microsoft account.');
    //             })
    //             req['username'] = msalResponse.account.username;
    //             await signIn(req);
    //         } else {
    //             setIsDialogOpen(true);
    //             setDialogContent('Error occured while logging into to microsoft account');
    //         }
    //     }
    // }

    const handleOtpLoginClick = async () => {
        submitUserActivityData({ oldScreen: '', currentScreen: 'LOGIN', nextScreen: 'OTP LOGIN', activityType: 'SCREEN_CHANGE', activityData: { phoneNumber: userName } });
        navigate(`/otp-login`);
    }

    const handleChatWithUs = () => {
        setShowFreshChat(true)
    }

    return (
        <div className={`${classes.loginContainer} ${classes.centerFlex} scrollbar-hidden`}
            style={{

                height: isMobile && isKeyboardOpen ? '160vh' : null,
                fontSize: `${fSize}rem`,
            }}
        >
            <FreshChat
                ref={chatRef}
                token="3d0017c5-0a60-4693-bdae-3def0407cf94"
                // widgetUuid="25380827-750a-40ae-aca5-8f2a3355f15c"
                onInit={(widget) => {
                    freshchatWidget = widget;
                    widget.on("widget:closed", function () {
                        // console.log("widget was closed");
                        document.getElementById("fc_frame").style.display = "none";
                        document.getElementById("fc_frame").style.paddingTop = "0rem";
                        document.getElementById("fc_frame").style.paddingBottom = "0rem";
                        setShowFreshChat(false)
                    });
                }}
            />
            {/* desktop */}
            {!isMobile &&
                <div className={`${classes.loginBox} ${classes.centerFlex}`}>
                    {/* hide in mobile and tablet */}
                    {!isTablet && !isMobile && (
                        <div className={classes.side1}>
                            <div className={classes.imageContainer}>
                                <img src="/images/login-screen/login_illustration.png" alt="master-o illustration" className={classes.coverImage} />
                                <div className={`${classes.iconBox}`}>
                                    <div className={classes.circleLogoBox}>
                                        <img src="/images/login-screen/new_mastero_login.png" className={classes.circleLogoImage} alt="master-o loading icon" />
                                    </div>
                                    <div className={classes.iconTextBox}>
                                        {/* <span className={classes.logoMaintext}>Master-O</span> */}
                                        {/* <span className={classes.logoSubText}>Improve Skills Effortlessly</span> */}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.loginContentBox}>
                                <div className={classes.welcomeText}>Welcome to</div>
                                <div className={classes.logoText}>Master-O</div>
                                <div className={classes.divider} />
                                <div className={classes.mainTextDesc}>Improve job performance by learning new Microskills. Learn on the go using microlearning and gamification. Compete and contrast your learning performance with your peers.</div>
                            </div>
                        </div>
                    )}

                    <div className={classes.side2}>
                        <div className={classes.signInMainText}>Sign into your account</div>
                        <div className={classes.signInDetails}>Enter your username and password to access your Master-O Microskills, Learning Documents and more!</div>

                        {privacyPolicy?.isDeclined && <span className={`${classes.centerFlex} ${classes.privacyPolicyWarning}`} style={{ padding: '0.5rem' }}>
                            <p>Please accept our privacy policy to login</p>
                        </span>}


                        <form className={classes.form}>
                            <div>
                                <label className={classes.labelClass}>User ID:</label>
                                <OutlinedInput
                                    fullWidth
                                    inputRef={usernameRef}
                                    size="small"
                                    disabled={isPasswordVisible}
                                    className={classes.emailInput}
                                    sx={theme => ({
                                        "& .Mui-disabled": {
                                            color: 'rgba(0, 0, 0, 0.5)',
                                            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.5) !important',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: isPasswordVisible ? 'rgba(0, 0, 0, 0.3) !important' : '',
                                        }
                                    })}
                                    onChange={(e) => setUserName(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <PersonOutlineIcon sx={{ color: palette.orange }} />
                                        </InputAdornment>
                                    }
                                />
                            </div>

                            {
                                isPasswordVisible && (
                                    <div className={classes.desktopPwdBox}>
                                        <label className={classes.labelClass}>Password:</label>
                                        <OutlinedInput
                                            type={showPassword ? 'text' : 'password'}
                                            fullWidth
                                            size="small"
                                            className={classes.emailInput}
                                            onChange={(e) => setPassword(e.target.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </div>
                                )
                            }
                            <div className={classes.checkBoxContainer}>
                                {/* <Checkbox sx={{ padding: '0' }} color="primary" /> */}
                                <span className={classes.rememberMeText}>Remember me on this device</span>
                            </div>
                            <div className={classes.loadingBtn}>
                                <LoadingButton
                                    style={{ backgroundColor: '#F4511E', padding: '6px', fontWeight: 'bold' }}
                                    type="submit"
                                    fullWidth
                                    onClick={handleSignInClick}
                                    loading={btnLoading}
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                >
                                    Login
                                </LoadingButton>
                            </div>

                            <div style={{ marginTop: '1rem' }}>
                                <LoadingButton
                                    className={classes.otpLoadingButton}
                                    type="submit"
                                    fullWidth
                                    onClick={handleOtpLoginClick}
                                    loading={otpBtnLoading}
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    sx={theme => ({
                                        backgroundColor: '#fff',
                                        fontWeight: 'bold',
                                        padding: '4px',
                                        color: '#F4511E',
                                        border: '1px solid #F4511E',
                                        borderRadius: '0px',
                                        '&:hover': {
                                            backgroundColor: '#fff'
                                        }
                                    })}
                                >
                                    Login by OTP
                                </LoadingButton>
                            </div>
                            <div style={{ marginTop: '1rem', }}>
                                <GoogleOAuthProvider clientId={`${GOOGLE_CLIENT_ID}`}>
                                    <GoogleLogin ux_mode="redirect" login_uri={`${GOOGLE_BASE_URI}`} />
                                </GoogleOAuthProvider>
                            </div>
                        </form>
                        <div style={{ height: '100%', width: '100%', textAlign: 'center' }}>
                            <img src="/images/icons/Chat_With_us_desktop.png" alt="Chat With us" style={{ width: '60%', height: '65%', cursor: 'pointer', borderRadius: '5px' }} onClick={handleChatWithUs} />
                        </div>
                        <div className={`${classes.textBoxDesktop}`}>
                            {/* <div><u>Forgot User ID or Password?</u></div> */}
                            {/* <div onClick={handleOtpLoginClick}><u>Login via OTP</u></div> */}
                        </div>
                    </div>
                </div >
            }
            {/* desktop */}

            {/* mobile */}
            {isMobile && (
                <div className={classes.mobileLoginContainer}>
                    <div className={classes.mobileLoginBox} style={{ gridTemplateRows: privacyPolicy?.isDeclined ? '17% 11% 72%' : '25% 75%' }}>


                        <div className={classes.mobileLogoContainer}>
                            <img src="/images/login-screen/login_logo.svg" alt="logo" />
                        </div>

                        {privacyPolicy?.isDeclined && <span className={`${classes.centerFlex} ${classes.privacyPolicyWarning}`}>
                            <p>Please accept our privacy policy to login</p>
                        </span>}

                        <div className={classes.mobileLoginForm}>
                            <form className={classes.loginFormBox}>
                                <FormControl fullWidth className={classes.mobileLoginInputField} variant="outlined">
                                    <OutlinedInput
                                        disabled={isPasswordVisible}
                                        onFocus={() => document.body.scrollTop = 0}
                                        inputRef={usernameRef}
                                        onChange={(e) => setUserName(e.target.value)}
                                        placeholder="User ID"
                                        startAdornment={<InputAdornment position="start">
                                            <img
                                                src="/images/login-screen/user_icon.svg"
                                                alt=""
                                                width="40%"
                                                style={{ marginLeft: "0.9rem" }}
                                            />
                                        </InputAdornment>}
                                    />
                                </FormControl>

                                {
                                    isPasswordVisible && (
                                        <FormControl fullWidth className={classes.mobileLoginInputField} variant="outlined">
                                            <OutlinedInput
                                                type="password"
                                                placeholder="Password"
                                                onChange={(e) => setPassword(e.target.value)}
                                                startAdornment={<InputAdornment position="start">
                                                    <img
                                                        src="/images/login-screen/password_icon.svg"
                                                        alt=""
                                                        width="40%"
                                                        style={{ marginLeft: "0.9rem" }}
                                                    />
                                                </InputAdornment>}
                                            />
                                        </FormControl>
                                    )
                                }

                                {/* <div className={classes.checkboxContainer}>
                                    <Checkbox /> <span className={classes.rememberText}>Remember me</span>
                                </div> */}
                                <div className={classes.loadingBtn}>
                                    <LoadingButton
                                        type="submit"
                                        style={{ borderRadius: '5px', padding: "8px 22px", backgroundColor: '#F4511E', fontWeight: 'bold', fontSize: '1rem', }}
                                        fullWidth
                                        onClick={handleSignInClick}
                                        loading={btnLoading}
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                    >
                                        Login
                                    </LoadingButton>
                                    {/* <Button variant="contained" fullWidth size="large" onClick={handleSignInClick}>Login</Button> */}
                                </div>
                                <div >
                                    <LoadingButton
                                        // type="submit"
                                        className={classes.mobileOtpLoadingButton}
                                        fullWidth
                                        onClick={handleOtpLoginClick}
                                        loading={otpBtnLoading}
                                        disableElevation
                                        variant="outline"
                                        color="primary"
                                        size="large"
                                        style={{ borderRadius: '5px' }}
                                        sx={theme => ({
                                            borderRadius: '0.1rem',
                                            padding: "8px 22px",
                                            color: "#FFF",
                                            fontSize: "1rem",
                                            border: "1px solid #F4511E",
                                            fontWeight: 'bold',
                                            backgroundColor: 'transparent',
                                            '& .MuiLoadingButton-loadingIndicator': {
                                                color: '#F4511E',
                                                backgroundColor: 'transparent',
                                            }
                                        })}
                                    >
                                        Login by OTP
                                    </LoadingButton>
                                </div>
                                <div style={{ marginTop: '0.5rem', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <GoogleOAuthProvider clientId={`${GOOGLE_CLIENT_ID}`}>
                                        <GoogleLogin
                                            theme="outline"
                                            size="large"
                                            width="100%"
                                            type="standard"
                                            logo_alignment="center"
                                            ux_mode="redirect"
                                            auto_select
                                            login_uri="https://app.masteroapp.com/api/bypass/google-oauth"
                                        />
                                    </GoogleOAuthProvider>
                                </div>
                            </form>
                            <div className={classes.aboutContainer}>
                                <div className={`${classes.text} ${classes.loginIssuesText}`}>Facing Login issues?</div>
                                <div className={classes.chatWithUs} onClick={handleChatWithUs}>
                                    <img style={{ width: '90%', borderRadius: '5px' }} src="/images/icons/chatWithUs.png" />
                                </div>
                                <div className={`${classes.text}`}>&copy; {year} InspireOne Technologies Pvt. Ltd. All rights reserved.</div>
                            </div>

                        </div>
                    </div>
                </div>
            )}
            {/* mobile */}

            {
                isDialogOpen && (
                    <DialogAlert
                        isOpen={isDialogOpen}
                        content={dialogContent}
                        confirmBtnName={'Okay'}
                        handleConfirmBtnClick={() => setIsDialogOpen(false)}
                    />
                )
            }
            {
                showLoadingMicroskill && (
                    <LoadingMicroskill
                        isOpen={showLoadingMicroskill}
                    />
                )
            }
            {
                showLoadingScreen && (
                    <LoadingScreen
                        isOpen={showLoadingScreen}
                    />
                )
            }
        </div >
    )
}

export default Login;