import axiosInstance from "../../utils/axios";
import commonUtil from "../../utils/commonUtil";


export const getCompanywiseDemographics = async () => {
    try {
        let companyId = commonUtil.getCompanyId();
        const respData = await axiosInstance.get(`/managerDashboard/getcompWiseDemographics/${companyId}`);
        // console.log("respData:", respData)
        return respData;
    } catch (err) {
        console.log("getCompanywiseDemographics:", err);
        return err.response
        // dispatch(fetchFail(err));
    }
}

export const getUsersByDemographic = async (payload = {}) => {
    try {
        const respData = await axiosInstance.post(`/managerDashboard/demographic-user-details`, payload);
        return respData;
    } catch (err) {
        console.log('getUsersByDemographic: ', err);
        return err.response;
    }
}

export const getMicroskillList = async (payload = {}) => {
    try {
        const respData = await axiosInstance.post(`/managerDashboard/microskill-List`, payload);
        // console.log("respData:", respData)
        return respData;
    } catch (err) {
        console.log("getMicroskillList:", err);
        return err.response
        // dispatch(fetchFail(err));
    }
}

export const getScenarioList = async () => {
    try {
        const respData = await axiosInstance.post(`/managerDashboard/scenario-option-manager?limit=5&startIndex=0`);
        // console.log("respData:", respData)
        return respData;
    } catch (err) {
        console.log("getScenarioList:", err);
        return err.response
        // dispatch(fetchFail(err));
    }
}

export const getSkillAnalysis = async (payload = {}) => {
    try {
        const respData = await axiosInstance.post(`/managerDashboard/get-skillGap-manager`, payload);
        // console.log("respData:", respData)
        return respData;
    } catch (err) {
        console.log("getSkillAnalysis:", err);
        return err.response
        // dispatch(fetchFail(err));
    }
}

export const getFeaturedSpotlights = async (payload = {}) => {
    try {
        const respData = await axiosInstance.post(`/managerDashboard/spotlights-manager`, payload);
        // console.log("respData:", respData)
        return respData;
    } catch (err) {
        console.log("getFeaturedSpotlights:", err);
        return err.response
        // dispatch(fetchFail(err));
    }
}

export const getCompletionAndScore = async (payload = {}) => {
    try {
        const respData = await axiosInstance.post(`/managerDashboard/score-manager`, payload);
        // console.log("respData:", respData)
        return respData;
    } catch (err) {
        console.log("getCompletionAndScore:", err);
        return err.response
        // dispatch(fetchFail(err));
    }
}

export const getLearningReport = async (payload = {}) => {
    try {
        const respData = await axiosInstance.post(`/managerDashboard/learning-report`, payload);
        // console.log("respData:", respData)
        return respData;
    } catch (err) {
        console.log("getLearningReport:", err);
        return err.response
        // dispatch(fetchFail(err));
    }
}