import { makeStyles } from "@mui/styles";
import constants from "../../constants";
export const HEADER2_HEIGHT_MOBILE = '4rem'

// const COLORS = constants.COLORS;
const topMargin = '3rem'

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    documentRoot: {
        height: 'inherit',
        overflow: 'hidden',
        // marginTop: topMargin,
        position: 'relative',
        paddingLeft: '2rem',
        color: theme.palette.fontColor,
        
        // gridTemplateRows: '8% 92%',
        [theme.breakpoints.down('md')]: {
            padding: '0',
            // display: 'grid',
            gridTemplateRows: 'auto',
            background: theme.palette.mobileMainContainerBg,
        },
    },
    spinnerRoot: {
        display: 'flex',
    },
    documentFolder: {
        width: '100%',
        // margin: 'auto',
        display: 'grid',
        alignContent: 'flex-start',
        gridTemplateColumns: 'repeat(7, 1fr)',
        height: 'calc(100% - 4rem)',
        overflowY: 'auto',
        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: 'repeat(5, 1fr)',
        },
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
            height: '100%'
        },
    },
    folderBox: {
        padding: '2rem 1rem',
        userSelect: 'none',
        '& img': {
            width: '100%',
            padding: '1rem 1.7rem',
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                padding: '0.5rem 1.2rem',
            },
        },
        [theme.breakpoints.down('sm')]: {
            padding: '1rem 0.3rem',
        },
    },
    folderName: {
        fontFamily: 'Open Sans',
        textAlign: 'center',
        fontSize: '0.8rem',
        wordBreak: 'break-word',
        color: theme.palette.fontColor,
        // textOverflow: 'ellipsis',
        // overflow: 'hidden',
        // whiteSpace: 'nowrap',
        // width: '20%',
        // display: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7rem',
            padding: '0 0.3rem'
        },
    },
    emptyFolderText: {
        height: `calc(100vh - ${constants.HEADER_HEIGHT} - ${constants.BOTTOM_NAV_HEIGHT})`,
        marginTop: `-${topMargin}`,
        fontFamily: 'Open Sans',
        [theme.breakpoints.down('sm')]: {
            marginTop: `0`,
        },
    },
    backBtnBoxRoot: {
        height: HEADER2_HEIGHT_MOBILE,
        justifyContent: 'flex-start',
        paddingLeft: '2rem'
    },
    backBtnBox: {
        // // position: 'absolute',
        // left: '0',
        // top: '-1.5rem',
        // transform: 'translate(-50%)',
        // marginTop: '1rem',
        height: '2rem',
        border: `2px solid ${theme.palette.fontColor}`,
        aspectRatio: '1/1',
        borderRadius: '50%',
        cursor: 'pointer',
        // margin: '1rem'
        // marginLeft: '5%'
    },
}))

export default useStyles