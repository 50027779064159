import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    cardContainer: {
        padding: '1em 0',
        overflow: 'hidden',
    },
    cardBox: {
        display: 'grid',
        alignItems: 'center',
        color: '#fff',
        // gridTemplateColumns: '18% 9.33% 18% 9.33% 18% 9.33% 18%',
        [theme.breakpoints.down('sm')]: {
            // gridTemplateColumns: '38% 24% 38%',
        },
    },
    headerText: {
        fontWeight: 'bold',
        fontSize: '1.2rem',
        padding: '1rem 0',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            padding: '1rem 0',
        },
    },
    circleMainContainer: {
        width: '100%',
        aspectRatio: '1/1',
        borderRadius: '50%',
        border: `4px solid ${theme.palette.whiteToOrange}`,
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            // width: '150px',
        },
    },
    circle: {
        width: '100%',
        height: '100%',
        borderRadius: 'inherit',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    horizontalDividerBox: {
        width: '100%',
        padding: '0 0.8rem'
    },
    horizontalDivider: {
        border: `2px solid ${theme.palette.whiteToOrange}`
    },
    completion: {
        position: 'absolute',
        width: '25%',
        height: '25%',
        backgroundColor: `${theme.palette.orange}`,
        fontSize: '0.7em',
        fontWeight: 700,
        borderRadius: '50%',
        right: '-15px',
        top: '10%',
        [theme.breakpoints.up('sm')]: {
            right: '-2%',
            width: '20%',
            height: '20%',
            fontSize: '0.9em',
        },
    },
    journeyName: {
        position: 'absolute',
        bottom: 0,
        padding: '0rem 1rem 2.2rem 1rem',
        width: '100%',
        fontSize: '0.8em',
        fontWeight: 700,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '1.5rem',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        display: '-webkit-box',
        textAlign: 'center',
        marginBottom: '1rem',
        // color: '#fff',
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.65em',
            padding: '1rem 2rem',
            fontWeight: 700,
            fontSize: '1em',
            bottom: '10%',
            textAlign: 'center',
        },
    },
    addOnCircle: {

    },
    microskillCount: {
        position: 'absolute',
        inset: '0 0 0 0',
        fontSize: '3em',
        fontWeight: 700,
        [theme.breakpoints.up('sm')]: {
            fontSize: '3em',
            padding: '1rem 0.7rem',
            fontWeight: 600,
        },
    }
}))

export default useStyles