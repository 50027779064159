import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    docViewerRoot: {
        position: 'relative',
        height: '100%',
        '& iframe': {
            height: '100%',
            width: '100%'
        }
        // '& img': {
        //     maxWidth: '100vw',
        //     objectFit: 'contain'
        // }
    },
    downloadBox: {
        position: 'absolute',
        zIndex: '9999',
        bottom: '2rem',
        right: '1rem',
        height: '3rem',
        width: '3rem',
        borderRadius: '50%',
        backgroundColor: '#fff',
        padding: '0.2rem',
        boxShadow: '0px 0px 10px 0px #00000057',
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'contain'
        }
    },
    rotationButton: {
        position: 'fixed',
        left: '3%',
        background: palette.voilet + 'a6',
        color: '#F4511E',
        boxShadow: '0px 0px 10px 0px #00000057',
        padding: '5px',
        width: '3rem',
        height: '3rem',
        border: 'none',
        borderRadius: '50%',
        zIndex: '10000',
        color: '#fff',
        fontSize: '20px',
        cursor: 'pointer'
    }
}))

export default useStyles