import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    error: null,
    tokens: null,
    companyId: null,
    isThirdPartyIntegration: false,
    msalRequestToken: null,
    oktaRequestToken: null,
    userProfileData: {},
    user: {}
};

const authSlice = createSlice({
    name: 'auth',
    initialState: INITIAL_STATE,
    reducers: {
        setCurrentUser: (state, action) => {
            state.companyId = action.payload.companyId;
        },
        setToken: (state, action) => {
            state.tokens = action.payload;
        },
        setIsThirdPartyIntegration: (state, action) => {
            state.isThirdPartyIntegration = action.payload;
        },
        setMsalRequestToken: (state, action) => {
            state.msalRequestToken = action.payload;
        },
        setOktaRequestToken: (state, action) => {
            state.oktaRequestToken = action.payload;
        },
        setUserProfileData: (state, action) => {
            state.userProfileData = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        }
    }
});

export const {
    setCurrentUser,
    setToken,
    setIsThirdPartyIntegration,
    setMsalRequestToken,
    setOktaRequestToken,
    setUserProfileData,
    setUser
} = authSlice.actions;

export default authSlice.reducer;
