import { Box, CircularProgress, Typography } from "@mui/material";



export const listButtonStyle = (theme) => ({
    backgroundColor: theme.palette.blackToWhite.bgColor,
    padding: '0.2rem 0',
    ':hover': {
        backgroundColor: theme.palette.blackToWhite.bgColor
    }
})
export const listItemStyle = (theme) => ({
    color: theme.palette.blackToWhite.color,
    textAlign: 'left',
    font: 'normal normal 700 0.9rem Montserrat'
})

export const styleTableBodyCell = {
    color: (theme) => theme.palette.fontColor,
    font: 'normal normal normal 0.9rem Open Sans',
    borderBottom: theme => `1px solid ${theme.palette.fontColor}`
}

export const styleTableHeadCell = {
    color: (theme) => theme.palette.fontColor,
    font: 'normal normal bold 0.9rem Open Sans',
    borderBottom: theme => `1px solid ${theme.palette.fontColor}`,
    minWidth: 202
}

export const LoadingBox = ({ isDarkMode }) => (
    <Box sx={(theme) => ({
        display: 'flex',
        backgroundColor: isDarkMode ? theme.palette.darkVoilet : theme.palette.white,
        height: '4rem',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        '& > span': {
            height: '25px !important',
            width: '25px !important'
        }
    })}>
        <CircularProgress />
        {/* <h6>Loading Data</h6> */}
        <Typography
            variant="body2"
            sx={(theme) => ({
                color: theme.palette.fontColor,
                font: 'normal normal 600 0.7rem Montserrat',
                opacity: 0.8
            })}>
            Loading Data
        </Typography>
    </Box>
)

export const EmptyBox = ({ isDarkMode, bg = null }) => (
    <Box sx={(theme) => ({
        display: 'flex',
        color: theme.palette.fontColor,
        backgroundColor: bg ? bg : (isDarkMode ? theme.palette.darkVoilet : theme.palette.white),
        height: '4rem',
        justifyContent: 'center',
        alignItems: 'center',
    })}>
        <Typography
            variant="body2"
            sx={(theme) => ({
                color: theme.palette.fontColor,
                font: 'normal normal 600 0.8rem Montserrat',
                opacity: 0.8
            })}>
            No data found
        </Typography>
    </Box>
)

