
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({

   answerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 10,
      backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8))',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '10% 75% 15%',
   },
   answerContainerHeader: {
      position: 'sticky',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      padding: '1rem',
      fontSize: '1.5rem',
      fontWeight: '600',
      textAlign: 'start',
   },
   audioPlayerContainer: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      zIndex: 1
   },

   tryagainContainer: {
      width: '100%',
      height: '100%',
      textAlign: 'center',
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   }
}));

export default useStyle;
