import axiosInstance from "../../utils/axios";
// import { getAllModules } from "./learn.actions";

export const getAllModulesAsync = async (params = '') => {
    try {
        const respData = await axiosInstance.get(`/microskills${params}`);
        // console.log("respData:", respData)
        return respData;
    } catch (err) {
        console.log("getAllModulesAsync:", err);
        return err.response
        // dispatch(fetchFail(err));
    }
}

export const getChallengeLists = async (microskillId) => {
    try {
        const respData = await axiosInstance.get(`/${microskillId}/challenges`);
        return respData;
    } catch (err) {
        console.log("getChallengeLists:", err);
        return err;
        // dispatch(fetchFail(err));
    }
}

export const getChallengeData = async (microskillId, challengeId) => {
    try {
        const respData = await axiosInstance.get(`/${microskillId}/${challengeId}`);
        return respData;
    } catch (err) {
        console.log("getChallengeData:", err)
        // dispatch(fetchFail(err));
    }
}

export const startChallengeAttempt = async (payload) => {
    try {
        const respData = await axiosInstance.post(`/start-challenge-attempt`, payload);
        return respData;
    } catch (err) {
        console.log("startChallengeAttempt:", err)
        // dispatch(fetchFail(err));
    }
}

export const getCertificateData = async (params) => {
    try {
        const respData = await axiosInstance.get(`/certificate/get-certifcate-config?${params}`);
        return respData;
    } catch (err) {
        console.log("getCertificateData:", err)
        // dispatch(fetchFail(err));
    }
}

export const microskillCertificateUpload = async (payload) => {
    console.log(payload)
    try {
        const respData = await axiosInstance.post('/upload-microskill-certificate', payload);
        return respData
    } catch (err) {
        return err.response;
    }
}

export const genericFileUpload = async (payload) => {
    try {
        const respData = await axiosInstance.post('/upload-media-file', payload);
        return respData
    } catch (err) {
        return err.response;
    }
}

export const getAllMicroskillFeedbackList = async () => {
    try {
        const respData = await axiosInstance.get('/microskill-feedback');
        return respData;
    }
    catch (err) {
        console.log("Error in fetching the list of Microskill Feedback");
    }
}

export const submitMicroskillFeedback = async (microskillId, payload) => {
    try {
        const respData = await axiosInstance.post(`/${microskillId}/feedback`, payload);
        return respData;
    } catch (err) {
        console.log("Error in submitting the Microskill Feedback");
    }
}

export const markMicroskillFeedbackDismiss = async (payload) => {
    try {
        const respData = await axiosInstance.post('/microskill-feedback-dismiss', payload);
        return respData;
    } catch (err) {
        console.log("Error in dismissing the Microskill Feedback");
    }
}

export const getLearningJourneyMicroskill = async (id) => {
    try {
        const respData = await axiosInstance.get(`/get-learner-journey-microskill?journeyId=${id}`);
        return respData;
    } catch (err) {
        console.log("getLearningJourneyMicroskill:", err);
        return err;
        // dispatch(fetchFail(err));
    }
}