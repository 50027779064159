import { makeStyles } from "@mui/styles";
import constants from "../../../constants";
import palette from "../../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center',
    },
    centerGrid: {
        display: 'grid !important',
        alignItems: 'center',
        justifyItems: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    bottomNavContainer: {
        height: `${constants.BOTTOM_NAV_HEIGHT}`,
        color: theme.palette.fontColor,
        fontSize: '0.8rem',
        backgroundColor: `${theme.palette.voilet}`,
        position: 'fixed',
        width: '100%',
        bottom: 0,
        display: 'none',
        zIndex: '99',
        boxShadow: '0px -4px 5px 0px rgba(0, 0, 0, 0.15) ',
        // gap: '2rem',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
        [theme.breakpoints.down('xs')]: {
            // display: 'block',
        },
    },
    bottomNavBox: {
        width: '80%',
        height: '100%',
        margin: 'auto',
        justifyContent: 'space-around !important',
        flexWrap: 'wrap',
        '& > *': {
            flex: '1 1 33%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    }
}))

export default useStyles