import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    learningAidSlider: {
        height: '100%',
        width: 'inherit',
        display: 'grid',
        gridTemplateRows: '88% 12%',
        padding: '1rem'
    },
    slider: {
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        borderRadius: '5px',
        // opacity: 0,
        '& img': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            transition: 'opacity 1s ease-in-out',
            objectFit: 'contain',
        },
        '& > .active': {
            opacity: 1,
        },
        '& > button': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: '50px',
            height: '50px',
            fontSize: '20px',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            border: 'none',
            cursor: 'pointer',
        },
        '& > .prev': {
            left: 0,
        },
        '& > .next': {
            right: 0,
        }
    },
    slide: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },


    learningAidRoot: {
        position: 'relative',
        height: '100%',
        display: 'grid',
        gridTemplateRows: '87% 13%',
        width: '100%',
        
        [theme.breakpoints.down("lg")]: {
            gridTemplateRows: '80% 12%',
        },
        
        [theme.breakpoints.down("md")]: {
            gridTemplateRows: '100% 0%',
        }
    },
    bottomNavigationBox: {
        alignItems: 'flex-end',
        [theme.breakpoints.down("lg")]: {
            alignItems: 'flex-start',
        },
    },
    controls: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: '5%',
        zIndex: 3
    },
    controlsBox: {

        // backgroundColor: `#00000042`,
        // backgroundColor: `#000000${theme.palette.mode === 'dark' ? '42' : '09'}`,
        overflow: 'hidden',
        borderRadius: '0.5rem',
        marginTop: '0.5rem',
        '& > *': {
            padding: '0.4rem 0.7rem',
        }
    },
    cardBox: {
        height: '100%',
        width: '100%',
        // padding: '1rem',
        [theme.breakpoints.down("sm")]: {
            padding: '0',
        }
    },
    card: {
        height: '100%',
        width: '100%',
        userSelect: 'none'
    },
    thumbImage: {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
    },
    container: {
        height: '100%',
        width: '100%',
    },
    imgBox: {
        padding: '0.5rem',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        backgroundColor: '#fff',
        // height: '100%'
    },
    ilaBox: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },

    dynamicCardRoot: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        // aspectRatio: '4/3',
        // backgroundColor: '#FFF',
        // borderRadius: '0.5rem',
        // padding: '1rem',
        [theme.breakpoints.down("sm")]: {
            padding: '0',
            borderRadius: '0rem',
        }
    },
    laWinScreenRoot: {
        height: '100%',
        width: '100%',
        userSelect: 'none'
    },
    laWinScreenBox: {
        height: '100%',
        width: '100%',
        backgroundColor: palette.ilaCorrect1,
        borderRadius: '0.5rem',
        display: 'grid',
        padding: '2rem',
        justifyContent: 'center',
        textAlign: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            padding: '1rem',
        }
    },
    completeTxt: {
        fontWeight: 700,
        letterSpacing: '8px',
        textDecoration: 'underline',
        fontSize: '1.2rem',
        [theme.breakpoints.down("sm")]: {
            fontSize: '1rem',
            fontWeight: 600,
            letterSpacing: '3px',
        }
    },
    congratsText: {
        fontWeight: 900,
        fontSize: '2.4rem',
        [theme.breakpoints.down("sm")]: {
            fontSize: '1.8rem',
        }
    },
    subText: {
        fontFamily: 'Open Sans',
        fontSize: '1.2rem',
        [theme.breakpoints.down("sm")]: {
            fontSize: '1rem',
        }
    },
    detailsBox: {
        gap: '3rem',
        [theme.breakpoints.down("sm")]: {
            gap: '2rem',
        }
    },
    detailText: {
        fontWeight: 900,
        fontSize: '2.4rem',
        [theme.breakpoints.down("sm")]: {
            fontSize: '1.6rem',
        }
    },
    iconBox: {
        gap: '1rem'
    },
    challengeBtnText: {
        fontSize: '2rem',
        fontWeight: 900,
        [theme.breakpoints.down("sm")]: {
            fontSize: '1.6rem',
            fontWeight: 700,
        }
    },
    navBtnBox: {
        height: '38px',
        aspectRatio: '1/1',
        borderRadius: '50%',
        transition: '0.2s',
        '&:hover': {
            background: '#f4511e21',
        }
    },

    // controls box
    documentLinkImg: {
        filter: theme.palette.mode === 'light' ? 'invert(1)' : 'none',
    },

    circularProgressContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '2rem',
        height: '2rem',
        // border: '5px solid',
        position: 'relative',
        // borderColor: theme.palette.orange,
        borderRadius: '50%',
        background: theme.palette.mode === 'dark' ? theme.palette.darkVoilet : theme.palette.white,
    },

    circleText: {
        fontSize: '10px',
        fontWeight: '800',
        color: theme.palette.orange,
    },
    circularProgress: {
        position: 'absolute',
        width: 'calc(100%)',
        height: 'calc(100%)',
        borderRadius: '50%',
        // clipPath: 'polygon(50% 50%, 1% 1%, 50% 0%)',
        // backgroundColor: theme.palette.orange,
    },

    circularProgressSVG: {
        transform: 'rotate(-90deg)',
        width: '100%',
        height: '100%',
        overflow: 'visible',
    },

    circularProgressBackground: {
        // stroke: "transparent",
        stroke: theme.palette.mode === 'dark' ? theme.palette.darkVoilet : theme.palette.white,
        fill: 'none',
        strokeWidth: '15',
    },

    circularProgressForeground: {
        // stroke: theme.palette.ilaInCorrect1,
        fill: 'none',
        transition: 'stroke-dashoffset 0.5s linear',
        strokeWidth: '15',
        strokeDasharray: '440', /* This should be the circumference of the circle */
        strokeDashoffset: '155', /* This should be the same as strokeDasharray at the start */
        strokeDashoffset: '440', /* This should be the same as strokeDasharray at the start */
        animationName: '$progress',
        animationTimingFunction: 'linear',
        animationFillMode: 'forwards'
    },
    "@keyframes progress": {
        '0%': {
            strokeDashoffset: 440, /* This should be the circumference of the circle */
            // strokeDashoffset: 155, /* This should be the circumference of the circle */
            stroke: theme.palette.ilaInCorrect1,
        },
        '30%': {
            stroke: theme.palette.ilaInCorrect1,
        },
        '35%': {
            stroke: theme.palette.gbabtn.main,
        },
        '90%': {
            stroke: theme.palette.gbabtn.main,
        },

        '95%': {
            stroke: theme.palette.lightGreen,
        },

        '100%': {
            strokeDashoffset: 155,
            // strokeDashoffset: 440,
            stroke: theme.palette.lightGreen,
        }
    },

}))

export default useStyles