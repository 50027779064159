import React from 'react';

// components
import LearningJourneyCard from '../learning-journey-card';

// styles
import useStyles from "./index.styles";

const LearningJourneyContainer = ({ data }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            {
                data.map(item => (
                    <LearningJourneyCard key={item.journeyId} cardData={item} />
                ))
            }
        </div>
    )
}

export default LearningJourneyContainer