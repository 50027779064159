import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// mui
import DescriptionIcon from '@mui/icons-material/Description';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

// utils
import isEmpty from '../../utils/isEmpty';

// styles
import useStyles from "./index.styles";
import { useTranslation } from 'react-i18next';

const ProductCard = (props) => {
    const isMobile = useSelector(state => state?.common?.isMobile);
    const { handleMicroskillCardClick, cardData, ratio="1/1" } = props;
    const { name, documentsLinked, comments, orgCoverImage } = cardData;
    const classes = useStyles();

    const { t } = useTranslation();


    const cardContainerStyles = {
        backgroundImage: `url(${isEmpty(orgCoverImage) ? '/images/sample-image/master_o_brand_icon.png' : orgCoverImage})`,
        display: 'inline-block',
        backgroundColor: '#fff',
        aspectRatio: ratio,
    }

    return (
        <div className={classes.microskillCardRoot}>

            <div className={classes.cardContainer} style={cardContainerStyles} onClick={handleMicroskillCardClick}>
                <div className={classes.cardGradient}>
                    <div className={classes.cardContent}>
                        <div className={`${classes.centerFlex} ${classes.nameContainer}`}>
                            <h2 className={classes.microSkillName} title={name} >
                                {name}
                            </h2>

                        </div>
                        <div className={`${classes.centerFlex} ${classes.icons}`}>
                            <div className={`${classes.centerFlex} ${classes.watchIcon}`}>
                                <DescriptionIcon sx={{ fontSize: '0.8rem' }} />
                                <span className={classes.iconText}>{documentsLinked}</span>
                            </div>
                            <div className={`${classes.centerFlex} ${classes.heartIcon}`}>
                                <QuestionAnswerIcon sx={{ fontSize: '0.8rem' }} />
                                <span className={classes.iconText}>{comments}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductCard