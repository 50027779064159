import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    carousel: {
        height: "100%"
    },
    carouselDot: {
        position: 'relative',
        '& > react-multi-carousel-dot--active .button': {
            background: '#F4511E !important',
            [theme.breakpoints.down('sm')]: {
                background: 'transparent !important'
            }
        }
    },
    customCarouselArrow: {
        position: 'absolute !important',
        height: '43px !important',
        width: '43px !important',
        borderRadius: '50% !important',
        padding: '0 !important',
        minWidth: '43px !important',
    },
    customCarouselRightArrow: {
        right: '0',
    },
    customCarouselLeftArrow: {
        left: '0',
    },
    slider: {
        height: '20%',
        margin: '1rem auto',
        width: '20%',
        background: 'rgb(0, 0, 0, 0.6)',
        borderRadius: '15px',
        [theme.breakpoints.down('sm')]: {
            width: '20%',
            position: 'absolute',
            height: '4.5%',
            top: '80%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
    },
    sliderThumb: {
        width: '1rem',
        height: '0.5rem',
        background: '#FFF',
        containerType: 'inline-size',
        borderRadius: '15px'
    }
}));

export default useStyle;