import React from "react";
import { useNavigate } from "react-router-dom";

// mui
import { Button } from "@mui/material";

// constants
import constants from "../../constants";

// styles
import useStyles from "./index.styles";


const ErrorPage = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <div className={classes.errorPageRoot} style={constants.FULL_HEIGHT}>
            <img src="/images/sample-image/page-not-found.png" alt="404 error" className={classes.errorImage} />
            <div className={classes.returnTextBox}>
                <Button variant="outlined" onClick={() => navigate("/learn")}>
                    <span className={classes.returnText}>Home</span>
                </Button>

            </div>
        </div>
    )
}

export default ErrorPage;