import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";

// mui
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import LanguageIcon from '@mui/icons-material/Language';

// context
import LearningAidContext from "../../../context/learningAidContext";

// components
import FileViewer from "../../file-viewer";
// import IframeComponent from "../../../components/iframe";

// redux

// utils
import isEmpty from "../../../utils/isEmpty";

// theme
import palette from "../../../theme/palette";

// constants
import constants from "../../../constants";

// styles
import useStyles from "./index.styles";

const AUDIO_EXTENSIONS = constants.AUDIO_EXTENSIONS;

const DocumentModal = (props) => {
    const { rootGridRows = "8% 84% 8%", openingFrom = "ila" } = props
    const { files, setIsFileViewerOpen, fileType, openDocInIframe } = useContext(LearningAidContext);
    const isMobile = useSelector(state => state?.common?.isMobile);
    const classes = useStyles({ fileType });
    const [fileValues, setFileValues] = useState([]);
    console.log('files = ', files);


    const handleClose = () => {
        setIsFileViewerOpen(false);
    }

    // console.log("DocumentModal:", files);
    // console.log("DocumentModal:", openDocInIframe);

    useEffect(() => {
        if (AUDIO_EXTENSIONS.includes(files.mimeType)) {
            setFileValues([{
                Repository: files
            }])
        } else {
            setFileValues([files])
        }
    }, []);

    const getName = (name) => {
        if (isEmpty(name)) return
        let tempArr = name.split(".");
        let lastEl = tempArr.pop();
        return { name: tempArr.toString(), extension: lastEl };
    }

    let style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '95%' : '100%',
        height: isMobile ? '95%' : '100%',
        bgcolor: '#fff',
        gridTemplateRows: rootGridRows,
        // bgcolor: fileType !== 'document' ? '#fff' : palette.voilet,
        boxShadow: 24,
        p: 3,
    };

    return (
        <div className={`${classes.documentModalRoot} `} >
            <Box sx={style} className={classes.boxStyle}>
                <div className={classes.header}>
                    <div className={`${classes.nameBox}`}>
                        {
                            fileType === 'document' ? (<img src="/images/icons/page_orange.png" alt="icon" width={18} />) : (
                                <LanguageIcon sx={{ color: palette.orange, fontSize: '1.8rem' }} />
                                // <LanguageIcon sx={{ color: palette.orange, fontSize: '1.8rem' }} />
                            )
                        }
                        <div className={classes.nameDiv}>
                            <div className={classes.nameTxt}>{getName(files.name).name}</div>
                            <div className={classes.ext}>{`.${getName(files.name).extension}`}</div>
                        </div>
                    </div>
                    <div className={`${classes.closeBtn} ${classes.centerFlex}`} onClick={handleClose}>
                        <CloseIcon sx={{ color: palette.orange, fontSize: '1.8rem' }} />
                        {/* <CloseIcon sx={{ color: !isMobile ? palette.orange : "#fff" }} /> */}
                    </div>
                </div>
                {
                    !isEmpty(fileValues) && !openDocInIframe && (
                        <FileViewer
                            files={fileValues}
                            type={files.mimeType}
                            indexVal={0}
                            isMobile={isMobile}
                            from={openingFrom}
                        />
                    )
                }

                {/* {
                    openDocInIframe && (
                        <IframeComponent src={files.path} />
                    )
                } */}
                <div className={classes.footer}></div>
            </Box>
        </div>
    )
}

export default DocumentModal;