import axiosInstance from "../../utils/axios";
// import { getAllModules } from "./learn.actions";

export const getAllContentRepoData = async (params = '') => {
    try {
        const respData = await axiosInstance.get(`/repository?${params}`);
        return respData;
    } catch (err) {
        console.log("getAllContentRepoData:", err)
        // dispatch(fetchFail(err));
    }
}
