import { makeStyles } from "@mui/styles";
import constants from "../../constants";
import palette from "../../theme/palette";
import { getHeaderHeight } from "../../utils/commonUtil";

// const COLORS = constants.COLORS;

export const BannerHeight = getHeaderHeight();
// export const BannerHeight = '180px;
export const NavigationContainerHeight = '3.5rem';
export const headerHeightMobile = '80px';
const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    // search bar in header
    searchBoxWrapper: {
        height: '4.5rem',
        // height: constants.HEADER_HEIGHT_MOBILE,
        position: 'sticky',
        top: '0rem',
        // top: '-1.5rem',
        right: '0',
        backgroundColor: theme.palette.darkVoilet2,
        zIndex: '2',
    },
    searchBox: {
        // position: 'fixed',
        // width: '90%',
        // height: '5%',

        width: '100%',
        height: '60%',
        minHeight: '2rem',
        // top: '1%',
        padding: '0 1rem',
        zIndex: '2',
        position: 'relative',
        // right: '0',
        '& input': {
            width: '100%',
            height: '100%',
            padding: '0 2rem 0 2rem',
            outline: theme.palette.orange,
            borderRadius: '4px',
            fontSize: '1rem',
            '&:focus': {
                border: `2px solid ${theme.palette.orange}`,
            }
        },
        '& img': {
            opacity: '1',
            position: 'absolute',
            transform: 'translateY(-50%)',
        }
    },
    searchIcon: {
        left: '1.5rem',
        top: '50%',
    },
    closeIcon: {
        right: '1.5rem',
        top: '50%',
    },

    cursorPointer: {
        cursor: 'pointer'
    },
    productDetailContainer: {
        padding: '2rem',
        userSelect: 'none',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: '0',
            // height: '100vh',
            // overflow: 'scroll'
        },
    },
    productDetailBox: {
        position: 'relative',
        backgroundColor: theme.palette.darkVoilet2,
        borderRadius: '12px',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        maxHeight: 'inherit',
        // [theme.breakpoints.down('md')]: {
        //     height: `calc(100vh - 4rem - ${constants.HEADER_HEIGHT} - ${constants.BOTTOM_NAV_HEIGHT})`,
        // },
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'max-content',
            borderRadius: '0',
            height: 'inherit',
            background: theme.palette.darkVoilet2,
        },
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    productDetailBg: {
        transition: 'height 1.2s ease 0s',
        height: BannerHeight,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'sticky',
        zIndex: 2,
        top: '0',
        // backgroundImage: 'url(/images/sample-image/master_o_brand_icon.png)',
        [theme.breakpoints.down('sm')]: {
            backgroundBlendMode: "multiply",
            // backgroundImage: 'url(/images/sample-image/master_o_brand_icon.png)',
            backgroundColor: "radial-gradient(transparent, black) !important",
            height: `calc(${BannerHeight}) !important`,
            borderBottom: `1px solid skyblue`,
        },
    },
    productDetailHeader: {
        height: `calc(${BannerHeight} - 10px)`,
        backgroundColor: `${palette.voilet}`,
        borderRadius: '12px',
        width: '80%',
        margin: 'auto',
        transition: 'width 0.8s, top 0.8s, background 0.8s',
        position: 'absolute',
        top: `${headerHeightMobile}`,
        left: '50%',
        transform: 'translateX(-50%)',
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
            borderRadius: '0',
            height: 'auto',
            bottom: '0',
            top: 'auto !important',
            // opacity: '0.7',
            backgroundColor: "transparent",
            width: "100%",
            height: `calc(100% - ${headerHeightMobile})`,
            display: 'grid',
            alignContent: 'flex-end'
        },
    },
    headerTitle: {
        justifyContent: 'flex-start',
        fontSize: '1rem',
        fontWeight: '600',
    },
    scoreText: {
        fontSize: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2em'
        }
    },
    detailBox: {
        display: 'flex',
        gap: '1rem',
        paddingTop: '0.5rem',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '0rem',
        }
    },
    countText: {
        fontSize: '1.5em',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9em',
            fontWeight: 600
        }
    },
    icons: {
        fontFamily: 'Montserrat',
        color: '#fff',
        justifyContent: 'right !important'
    },
    productDetailMobileHeader: {
        height: `${headerHeightMobile}`,
        // background: `linear-gradient(${theme.palette.voilet}, ${theme.palette.darkVoilet2})`,
        backgroundColor: `${theme.palette.voilet}`,
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'grid',
        alignItems: 'center',
        width: '100%',
        transition: '.6s',
        zIndex: '2',
        gridTemplateColumns: '15% 70% 15%',
        // gridTemplateColumns: '15% 85%',
        padding: theme.spacing(0, 2),
    },
    backButton: {
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        background: 'rgba(255, 255, 255)',
        cursor: 'pointer',
    },
    headerIcons: {
        height: '35px',
        justifyContent: 'flex-end',
    },
    headerIcon: {
        padding: '0 1rem 0 0',
    },


    /* productDetailHeaderBox */
    productDetailHeaderBox: {
        position: 'relative',
        width: '100%',
        height: '100%',
        color: '#FFF',
        padding: '1rem 3.5rem',
        display: 'grid',
        [theme.breakpoints.down('sm')]: {
            padding: '1.2rem',
            display: 'grid',
            alignItems: 'center',
            // justifyContent: 'space-between'
        }
    },
    /* productDetailHeaderBox */


    perDetailContainer: {
        display: 'grid',
        gridTemplateColumns: '60% 40%',
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: (i18n) => i18n.language === 'ml' ? '50% 50%' : '55% 45%',
            fontSize: (i18n) => i18n.language === 'en' ? '16px' : '14px'
        }
    },
    moduleNameText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // paddingRight: '1.5rem',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        display: '-webkit-box',
    },

    // new CSS
    iconText: {
        fontSize: '0.8rem',
        fontWeight: 600,
        paddingLeft: '0.3rem'
    },
    productDetailWrapper: {

    },
    productDetailNavigationContainer: {
        maxWidth: '100%',
        height: NavigationContainerHeight,
        overflowX: 'auto',
        overflowY: 'hidden',
        position: 'sticky',
        backgroundColor: theme.palette.voilet,
        // padding: '0.5rem 0.5rem 0',
        '&::-webkit-scrollbar': {
            display: 'none'
        }

    },
    productDetailNavigation: {
        display: 'flex',
        // gap: '1rem',
        listStyle: 'none',
    },
    productDetailNavigationItem: {
        padding: '0.5rem 0rem 0.5rem 0rem',
        '&:first-child': {
            padding: '0.5rem 0rem 0.5rem 0.5rem',
            // textTransform: 'capitalize',
        },
        '&:last-child': {
            padding: '0.5rem 1rem 0.5rem 0rem',
            // textTransform: 'capitalize',
        }
        // borderBottom: `1px solid ${theme.palette.fontColor}`,
    },
    productDetailNavigationLink: {
        color: theme.palette.fontColor,
        fontWeight: 600,
        padding: '0.9rem 0.75rem',
        display: 'inline-block',
        borderRadius: '5px 5px 0 0',
        whiteSpace: 'nowrap',
    },
    activeCard: {
        backgroundColor: theme.palette.darkVoilet2,
        color: theme.palette.orange,
        // border: `1px solid ${theme.palette.fontColor}`,
        // borderBottom: `1px solid ${theme.palette.voilet}`,
    },

    // productDetailContent
    productDetailContentWrapper: {
        color: theme.palette.fontColor,
        padding: '5% 3% 0%',
        height: '100%',
        overflowY: 'auto',

    },
    productDetailContentContainer: {},
    summaryBox: {
        padding: '1rem',
        paddingBottom: '3rem',
        fontSize: '1rem',
        fontWeight: 600,
        listStyle: 'none',
        '& li': {
            marginBottom: '0.5rem',
            display: 'flex',
            gap: '0.5rem',
            '& > span': { // custom bullet
                width: '0.6em',
                height: '0.6em',
                borderRadius: '50%',
                display: 'inline-block',
                lineHeight: '1.8',
                marginTop: '0.5rem', // to align it in middle of only first line of the content
                backgroundColor: theme.palette.fontColor,
            },
        },
    },
    summaryText: {
        display: 'inline-block',
        width: '100%',
        color: theme.palette.fontColor,
        fontWeight: 400,
        fontSize: '1.2em',
        lineHeight: '1.8',
    },

    documentsBox: {
        paddingBottom: '3rem',
    },
    faqsBox: {
        paddingBottom: '3rem',
    },

    // comparison box
    comparisonBox: {
        paddingBottom: '3rem',
    },
    comparisonList: {
        padding: '0',
    },
    comparisonItem: {
        cursor: 'pointer',
        paddingBottom: '3rem',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        position: 'relative',
        '& div:not(:last-child)': {
            width: '100%',
        }
    },
    comparisonItemImg: {
        display: 'block',
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
    comparisonItemTitle: {
        padding: '0.5rem 0 0 0.2rem'
    },

    comparisonItemMid: {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40px',
        height: '40px',
        color: theme.palette.white,
        backgroundColor: theme.palette.orange,
        borderRadius: '50%',
    },

    // documents box
    documentFolder: {
        width: '100%',
        // margin: 'auto',
        display: 'grid',
        alignContent: 'flex-start',
        gridTemplateColumns: 'repeat(7, 1fr)',
        height: 'calc(100%)',
        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: 'repeat(5, 1fr)',
        },
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
            height: '100%'
        },
    },
    folderBox: {
        padding: '2rem 1rem',
        userSelect: 'none',
        '& img': {
            width: '100%',
            padding: '1rem 1.7rem',
            cursor: 'pointer',
            [theme.breakpoints.down('sm')]: {
                padding: '0.5rem 1.2rem',
            },
        },
        [theme.breakpoints.down('sm')]: {
            padding: '1rem 0.3rem',
        },
    },
    folderName: {
        fontFamily: 'Open Sans',
        textAlign: 'center',
        fontSize: '0.8rem',
        wordBreak: 'break-word',
        color: theme.palette.fontColor,
        // textOverflow: 'ellipsis',
        // overflow: 'hidden',
        // whiteSpace: 'nowrap',
        // width: '20%',
        // display: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7rem',
            padding: '0 0.3rem'
        },
    },

    // Floating menu container
    FloatingMenuContainer: {
        position: 'absolute',
        bottom: '1rem',
        right: '1rem',
        zIndex: 3,
        [theme.breakpoints.down('sm')]: {
            bottom: '1rem',
            right: '1rem',
        },
    },
    FloatingMenu: {},
    FloatingMenuIcon: {
        position: 'absolute',
        right: '0',
        bottom: '0',
        zIndex: 5,
        padding: '0.4rem 0.5rem',
        borderRadius: '50%',
        backgroundColor: theme.palette.orange,
        boxShadow: `0 0 2px ${theme.palette.fontColor}`,
    },
    FloatingMenuList: {
        minWidth: '10rem',
        maxWidth: '90vw',
        listStyle: 'none',
        position: 'absolute',
        right: '0',
        bottom: '0',
        padding: '1rem 1rem 4.5rem',
        borderRadius: '5% 5% 15% 5%',
        backgroundColor: theme.palette.voilet,
        transformOrigin: '95% 95%',
        transition: 'all 0.3s ease-in-out',

    },
    FloatingMenuItem: {
        fontSize: '1.1rem',
        textAlign: 'center',
        fontWeight: 600,
        padding: '0.5rem 0.1rem',
        marginTop: '1rem',
        color: theme.palette.fontColor,
        borderBottom: `1px solid ${theme.palette.fontColor}7A`,
        whiteSpace: 'nowrap',
        "&:first-child": {
            marginTop: '0.5rem',
        }

    },
    noDataBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: `calc(100dvh - ${BannerHeight} - ${NavigationContainerHeight})`,
        color: theme.palette.fontColor,
    },
    carouselImage: {
        objectFit: 'cover',
        width: '100%',
    },
}))

export default useStyles