import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// import commonReducer from "./common/common.reducer";
// // import authReducer from "./auth/auth-reducer";
// import spotlightReducer from './spotlight/spotlight.reducer';
// import gbaReducer from './gba/gba.reducer';
// import microskillReducer from './microskill/microskill.reducer';
// import eventReducer from './event-tracking/event.reducer';
// import commonPersistReducer from './common-persist/common-persist.reducer';
// import loginEventReducer from './events/events.reducer';

// new imports
import authReducer from './auth/auth-slice';
import commonReducer from './common/common-slice';
import spotlightReducer from './spotlight/spotlight-slice';
import gbaReducer from './gba/gba-slice';
import microskillReducer from './microskill/microskill-slice';
import eventReducer from './event-tracking/event-tracking-slice';
import commonPersistReducer from './common-persist/common-persist-slice';
import loginEventReducer from './events/events-slice';


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'microskill', 'commonPersist']
};

const rootReducer = combineReducers({
    common: commonReducer,
    auth: authReducer,
    spotlight: spotlightReducer,
    gba: gbaReducer,
    microskill: microskillReducer,
    event: eventReducer,
    commonPersist: commonPersistReducer,
    loginEvent: loginEventReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;