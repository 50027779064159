import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
    popcornMatchRoot: {
        color: "#fff",
        boxSizing: "border-box",
        //background: "linear-gradient(#f7591f, #fcb340)",
        position: "relative",
        transition: "0.7s",
        userSelect: 'none',
        transition: 'background 1s',
        height: '100%'
    },
    startPage: {
        height: "100%",
        backgroundImage: "url(/popcornMatch/popcorn_match_overlay.png)"
    },
    centerFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    bgImgBox: {
        textAlign: "center",
        height: "40%"
    },
    mainText: {
        animation: "$zoomInOut 1.2s ease 0s infinite normal forwards running",
    },
    titleImage: {
        width: "85%"
    },
    btnBox: {
        textAlign: "center",
        height: "60%"
    },
    btnContainer: {
        width: "100%",
    },
    howToPlay: {
        width: "85%",
        fontFamily: "MotionControl",
        fontSize: "1.8rem",
        border: "none",
        padding: "0.6rem",
        cursor: "pointer",
        borderRadius: "5px",
        background: "none",
        marginBottom: "30px",
        color: "#fff",
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '2.5rem',
        },
    },
    startBtn: {
        width: "85%",
        fontFamily: "MotionControl",
        fontSize: "2rem",
        background: "#ffe66e",
        border: "none",
        padding: "0.6rem",
        cursor: "pointer",
        borderRadius: "5px",
        boxShadow: '0 2px 7px -1px #000',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '3rem',
        },
    },

    "@keyframes animateOpacity": {
        "0%": { opacity: "0" },
        "100%": { opacity: "1" },
    },
    "@keyframes shakeObj": {
        "0%": { transform: "rotate(3deg)" },
        "10%": { transform: "rotate(-3deg)" },
        "20%": { transform: "rotate(3deg)" },
        "30%": { transform: "rotate(-3deg)" },
        "40%": { transform: "rotate(3deg)" },
        "50%": { transform: "rotate(-3deg)" },
        "60%": { transform: "rotate(3deg)" },
        "70%": { transform: "rotate(-3deg)" },
        "80%": { transform: "rotate(3deg)" },
        "90%": { transform: "rotate(-3deg)" },
        "95%": { transform: "rotate(3deg)" },
        "100%": { transform: "rotate(0deg)" },
    },
    "@keyframes slideFromBottom": {
        "0%": { transform: "translateY(250%)" },
        "100%": { transform: "translateY(0)" },
    },
    "@keyframes zoomInOut": {
        "0%": { transform: "scale(1)" },
        "50%": { transform: "scale(0.95)" },
        "100%": { transform: "scale(1)" },
    },


    // questionBox container
    qstnContainer: {
        height: "60%",
        margin: "10px 20px 0 20px",
        transform: "translateY(-200px)",
        animation: "$translateQstnContainer 1s ease 0s 1 normal forwards running",
        zIndex: '2',
        position: 'relative'
    },
    "@keyframes translateQstnContainer": {
        "0%": { transform: "translateY(-200px)" },
        "100%": { transform: "translateY(0)" }
    },
    qstnBox: {
        backgroundColor: "#000000",
        borderRadius: "10px",
        height: "100%",
        fontSize: "16px",
        boxShadow: "0px 10px 20px #0000004D",
        display: 'grid',
        gridTemplateRows: '35% 65%'
    },
    assetBox: {
        display: "grid",
        gridTemplateColumns: "1fr  1fr 1fr",
        padding: "12px",
        alignItems: 'center'
    },
    lifeText: {
        display: "flex",
        alignItems: "center",
        padding: "0 15px"
    },
    timerText: {
        display: "flex",
        alignItems: "center",
        padding: "0 15px",
        //justifyContent: "right",
        justifySelf: "end"
    },
    icon: {
        width: '1em',
        height: '1em',
        margin: "0 5px"
    },
    life: {
        fontWeight: '600',
        fontSize: '0.8rem',
    },
    time: {
        fontWeight: '600',
        fontSize: '0.8rem'
    },
    timerIcon: {
        width: "13px",
        height: "12px",
        margin: "0 6px"
    },
    qstn: {
        textAlign: "center",
        transition: '.5s',
        fontFamily: 'Open Sans',
        fontWeight: '500',
        fontSize: '0.9rem',
        padding: '0 1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '0.9rem',
        },
    },

    //game play
    container: {
        display: "grid",
        height: "100%",
        gridAutoRows: "27% 73%",
        overflow: 'hidden',
        touchAction: 'none',
        opacity: '0',
        animation: '$animateGameLandingPage 2s ease 0s 1 normal forwards running '
    },
    "@keyframes animateGameLandingPage": {
        "0%": { opacity: '0' },
        "100%": { opacity: '1' },
    },
    questionBox: {
        height: "60%",
        padding: "0.7rem"
    },
    qBox: {
        backgroundColor: "#000",
        height: "100%",
        padding: "1rem",
        borderRadius: "5px",
    },
    dragCardContainer: {
        display: "grid",
        height: "100%",
        gridAutoRows: "50% 50%",
        transition: '.5s',
        position: 'relative'
    },
    answerContainer: {
        display: "flex",
        padding: "1.5rem 0",
        flexWrap: "wrap",
        justifyContent: 'center',
        "& > :nth-child(1)": {
            paddingLeft: '1rem'
        },
        "& > :nth-child(3)": {
            paddingRight: '1rem'
        }
    },
    answer: {
        flex: "0 1 33%",
        display: "flex",
        alignItems: 'flex-start',
        justifyContent: 'center',
        transition: '.4s',
        padding: '0 1rem',
    },
    topCard: {
        position: 'relative',
        height: '60%',
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        transition: '0.2s'
        // transition: '0.4s',

    },
    bottomCard: {
        position: 'relative',
        height: '60%',
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        // transition: '0.4s',

    },
    answerText: {
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '0.75rem',
        textAlign: 'center',
        width: '115%',
        // width: '13ch',
        fontWeight: 300,
        // whiteSpace: 'normal',
        // wordBreak: 'break-word',
        fontFamily: 'Montserrat',
        [theme.breakpoints.down("sm")]: {
            fontSize: '0.9rem',
        },
    },
    answerImg: {
        height: '60px',
    },

    option: {
        flex: "1 1 33%",
        display: "flex",
        padding: '0 1.2rem',
        alignItems: 'center',
        justifyContent: 'center',
    },
    qstnImgBox: {
        position: 'relative',
        height: '60%',
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down("sm")]: {
            // mobile
            height: '45%',
            width: '60%'
        },
    },
    qstnText: {
        position: 'absolute',
        top: '108%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '14px',
        textAlign: 'center',
        width: '14ch',
        // whiteSpace: 'normal',
        // wordBreak: 'break-word',
        fontFamily: 'Montserrat',
    },
    qstnImg: {
        height: '60px',
    },
    optionContainer: {
        display: "flex",
        padding: "3rem 0",
        flexWrap: "wrap",
        justifyContent: "center",
        transition: "transform 1s",
    },
    optionCard: {
        flex: "0 1 33%",
        display: "flex",
        justifyContent: "center",
    },
    progressBarContainer: {
        position: "relative",
        height: "4px",
        zIndex: "2",
        width: (isMobile) => {
            return `calc(100% - ${isMobile ? '0px' : '6px'})`
        },
        borderRadius: (isMobile) => {
            return `${isMobile ? '0px' : '8px'}`
        },
        margin: 'auto',
        overflow: 'hidden',
        
        backgroundColor: theme.palette.white,
        [theme.breakpoints.down("md")]: {
            backgroundColor: theme.palette.progressBarBg,
        },
    },
    progressBar: {
        position: "absolute",
        top: "0",
        left: "0",
        height: "4px",
        backgroundColor: "#ffd042",
        width: '0',
        transition: '.4s',
    },
    boxShadowBreathingAnimation: {
        transition: '2s',
        animation: '$boxShadowBreathingAnimation 1s ease 0s infinite normal forwards running '
    },
    dragItemAction: {
        fontFamily: 'MotionControl',
        fontSize: '1.6rem',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.8rem',
        },
    },
    '@keyframes boxShadowBreathingAnimation': {
        '0%': { boxShadow: '0 0 15px -2px red' },
        '10%': { boxShadow: '0 0 16px -2px red' },
        '20%': { boxShadow: '0 0 17px -1px red' },
        '30%': { boxShadow: '0 0 18px -1px red' },
        '40%': { boxShadow: '0 0 19px 0px red' },
        '50%': { boxShadow: '0 0 20px 0px red' },
        '60%': { boxShadow: '0 0 19px -1px red' },
        '70%': { boxShadow: '0 0 18px -1px red' },
        '80%': { boxShadow: '0 0 17px -2px red' },
        '90%': { boxShadow: '0 0 16px -2px red' },
        '100%': { boxShadow: '0 0 15px -2px red' },
    },
    gradientBox: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'linear-gradient(#9d9a9a, #ed0900)',
        opacity: 0,
        zIndex: -1
    },
    opacityAnimation: {
        animation: '$opacityAnimation 1.2s ease 0s infinite normal forwards running '
    },
    '@keyframes opacityAnimation': {
        // '0%': { opacity: '1' },
        // '60%': { opacity: '1' },
        // '90%': { opacity: '0' },
        // '100%': { opacity: '0.1' },
        '0%': { opacity: '0' },
        '10%': { opacity: '1' },
        '60%': { opacity: '1' },
        '100%': { opacity: '0' },
    },
}));

export default useStyle;
