import { makeStyles } from "@mui/styles";
import palette from "../../../theme/palette";
import constants from "../../../constants";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loaderBox: {
        height: '100%',
        width: '100%',
    },
    ilaRoot: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        transition: '0.7s',
        padding: '1rem',
    },
    ilaBox: {
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: constants.ILA_GRID_INITIAL,
        transition: '500ms'
    },
    scoreBox: {
        textAlign: 'center',
        fontSize: '2rem',
        fontWeight: '900',
        display: 'grid',
        alignItems: 'center',
        alignContent: 'center',
        opacity: '0',
        transition: '500ms'
        // fontFamily: 'Montserrat'
    },
    scoreText: {
        fontSize: '1.5rem',
    },
    // questionBox: {
    //     display: 'grid',
    //     gridTemplateColumns: '20% 60%'
    // },

    optionsContainer: {
        display: 'grid',
        alignItems: 'center'
    },
    optionsBox: {
        width: (props) => ((props.type === 'ILA_TF_1') ? `70%` : '85%'),
        // width: '70%',
        margin: 'auto',
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        alignContent: 'center',
        alignItems: 'center',
        justifySelf: 'center',
        [theme.breakpoints.down("md")]: {
            gridTemplateColumns: 'auto',
            gridTemplateRows: '50% 50%',
            // gap: '1rem',
            // height: '100%',
        }
    },
    option: {
        height: '120px',
        // marginBottom: '1.5rem',
        padding: '1rem 0.75rem',
        cursor: 'pointer',
        position: 'relative',
        transition: 'position 0.7s',
        '-webkit-tap-highlight-color': 'transparent',
        tapHighlightColor: 'transparent',
        [theme.breakpoints.down("md")]: {
            // height: '80px',
            // marginBottom: '3.5rem'
            height: '130px',
            padding: '1.8rem 0.75rem',
        }
    },
    optionBtn: {
        height: '100% !important',
        padding: '0 !important',
        color: '#FFF !important'
    },
    indexBtn: {
        backgroundColor: '#00000033',
        padding: '1rem',
        fontSize: '1.2rem',
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        height: '100%',
        aspectRatio: '1/1',
        overflow: 'hidden',
        borderRadius: '0.3rem 0 0 0.3rem'
    },
    answerBtn: {
        backgroundColor: palette.ilaCardBlue,
        padding: '1rem 2rem',
        lineHeight: '1.6rem',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        transition: '0.7s',
        justifyContent: 'center',
        // fontSize: '1.5rem',
        // borderRadius: '0.4rem',
        // fontWeight: 900,
        // textTransform: 'uppercase',
        // fontWeight: (props) => (!isEmpty(props.boxDivision) ? `3rem` : '1.3rem'),
        fontSize: (props) => ((props.type === 'ILA_TF_1') ? `2rem` : '1.3rem'),
        fontWeight: (props) => ((props.type === 'ILA_TF_1') ? `700` : '400'),
        textTransform: (props) => ((props.type === 'ILA_TF_1') ? `uppercase` : 'inherit'),
        borderRadius: (props) => ((props.type === 'ILA_TF_1') ? `0.4rem` : '0.9rem'),

        [theme.breakpoints.down("md")]: {
            fontSize: (props) => ((props.type === 'ILA_TF_1') ? `1.8rem` : '1.2rem'),
            borderRadius: (props) => ((props.type === 'ILA_TF_1') ? `0.4rem` : '0.4rem'),
        }
    },
    correctIncorrectTextBox: {
        position: 'absolute',
        bottom: '-2rem'
    }
}))

export default useStyles