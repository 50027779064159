import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";


import Carousel from "react-multi-carousel";

import useStyle from "./index.styles";

// api
import { fetchForm } from "../../redux/form/form.api";
import { getAllMicroskillFeedbackList } from "../../redux/microskill/microskill.api";
import { notificationViewMark } from "../../redux/notifications/notification.api.js";

// components
import FormCard from "../new-forms/components/cards";
import Form from "../forms-revamp";
import FeedbackCard from "../microskill-feedback-form/feedbackCard";
import MicroskillFeedBack from "../microskill-feedback-form";

// mui
import { Button } from "@mui/material";
import isEmpty from "../../utils/isEmpty";
import commonUtil from "../../utils/commonUtil.js";


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 1400 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 1400, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1
    }
};

var bridge = require("webview-javascript-bridge-promised");


const CarouselComponent = (props) => {
    const tokens = useSelector(state => state.auth.tokens);
    const isMobile = useSelector(state => state?.common?.isMobile);
    const styles = useStyle();
    const navigate = useNavigate();

    let [searchParams, setSearchParams] = useSearchParams();
    const form = searchParams.get('formId');
    const notificationId = searchParams.get('notificationId');

    const [formAvailable, setFormAvailable] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [formId, setFormId] = useState('');

    const [feedbackAvailable, setFeedbackAvailable] = useState([]);
    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackMicroskillId, setFeedbackMicroskillId] = useState('');
    const [microskillFeedbackName, setMicroskillFeedbackName] = useState('');
    const [microskillCoverImg, setMicroskillCoverImg] = useState(null);
    const [microskillRating, setMicroskillRating] = useState(null);

    const [cards, setCards] = useState([]);

    const [showSlider, setShowSlider] = useState(false);

    // const handleDividerRef = useRef(0);

    const CustomLeftArrow = ({ onClick, ...rest }) => {
        return (
            <Button onClick={() => onClick()} variant={!isMobile ? 'contained' : 'text'}
                className={`${styles.customCarouselArrow} ${styles.customCarouselLeftArrow}`}>
                <img src="/images/icons/left_arrow_white.svg" alt="leftArrow" style={{ width: '20px' }} />
            </Button>
        )
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
        return (
            <Button onClick={() => onClick()} variant={!isMobile ? 'contained' : 'text'}
                className={`${styles.customCarouselArrow} ${styles.customCarouselRightArrow}`}>
                <img src="/images/icons/right_arrow_white.svg" alt="rightArrow" style={{ width: '20px' }} />
            </Button>
        )
    };

    const fetchAllForm = async () => {
        const resp = await fetchForm();
        if (resp.status === 200 || resp.status === 201) {
            const respData = resp.data.data;
            setFormAvailable(respData)

            if (!isEmpty(form)) {
                const availableForm = respData.filter((temp) => {
                    return temp.Form.id === form
                });

                if (availableForm.length > 0) {
                    setOpenForm(true);
                    setFormId(form);
                    const payload = {
                        messageId: notificationId,
                        status: 'VIEWED'
                    };
                    notificationViewMark(payload).then((markViewNotification) => {
                        if (markViewNotification.status === 200 || markViewNotification.status === 201) {
                            console.log('completed');
                        }
                    });
                }
            }

        }
    }

    const fetchAllMicroskillFeedback = async () => {
        const respData = await getAllMicroskillFeedbackList();
        if (respData.status === 200 || respData.status === 201) {
            setFeedbackAvailable(respData.data.data.microskillFeedback);
        }
    }

    useEffect(() => {
        console.log(formId);
    }, [formId]);


    const handleFormClick = (e, formId) => {
        setFormId(formId);
        setOpenForm(!openForm);
    }

    const handleFeedbackClick = (e, microskillId, name) => {
        setOpenFeedback(true);
        setFeedbackMicroskillId(microskillId);
        setMicroskillFeedbackName(name);
        const feedbackData = feedbackAvailable.filter((feedback) => feedback.microskillId === microskillId);
        setMicroskillCoverImg(feedbackData[0]?.Microskill?.coverImage);
    }

    useEffect(() => {
        if (!commonUtil.validToken(tokens)) return
        fetchAllForm();
        fetchAllMicroskillFeedback();
    }, []);

    useEffect(() => {
        const temp = formAvailable.map(form => {
            return <FormCard key={form.Form.id} id={form.Form.id} handleFormClick={handleFormClick} form={form.Form} assessment={form.Form.assessment} />;
        })
        const t = feedbackAvailable.map(feedback => {
            return (<FeedbackCard key={feedback.microskillId} id={feedback.microskillId} handleFeebackClick={handleFeedbackClick} name={feedback.Microskill.name} />)
        });

        setCards([...temp, ...t]);
    }, [formAvailable, feedbackAvailable]);

    useEffect(() => {
        if (isMobile && cards.length > 1) {
            setShowSlider(true);
        }
        else if (!isMobile && cards.length > 3) {
            setShowSlider(true);
        }
    }, [cards]);

    const handleBeforeChange = (previousIndex, nextIndex) => {
        // handleDividerRef.current.style.marginLeft = (nextIndex / cards.length) * 100 + '%'
    }

    const handleAfterChange = (currentIndex) => {
        console.log(currentIndex);
    }

    return (
        <div className={styles.container} style={!props.isModuleLoaded ? { paddingBottom: '1rem' } : {}}>
            <Carousel
                swipeable={isMobile}
                responsive={responsive}
                showDots={true}
                infinite={false}
                arrows
                customRightArrow={<CustomRightArrow />}
                customLeftArrow={<CustomLeftArrow />}
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                rewind={false}
                centerMode={false}
                rewindWithAnimation={false}
                draggable={isMobile}
                rtl={false}
                sliderClass=""
                slidesToSlide={1}
                containerClass={styles.carousel}
                dotListClass={styles.carouselDot}
                beforeChange={handleBeforeChange}
                afterChange={handleAfterChange}
            >
                {!isEmpty(cards) && cards}
            </Carousel>

            {/* {cards.length > (isMobile ? 1 : 3) && (
                <div className={styles.slider}>
                    <div className={styles.sliderThumb} ref={handleDividerRef}>
                    </div>
                </div>
            )} */}

            <Form formId={formId} setFormId={setFormId} openForm={openForm} setOpenForm={setOpenForm} isDialog={true} isChallenge={false} />
            <MicroskillFeedBack openFeedback={openFeedback} setOpenFeedback={setOpenFeedback} feedbackMicroskillId={feedbackMicroskillId} name={microskillFeedbackName} imageUrl={microskillCoverImg} ratingValue={microskillRating} setRatingValue={setMicroskillRating} />

        </div>
    )
}

export default CarouselComponent;