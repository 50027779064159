import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
    howToPlayroot: {
        color: "#fff",
        boxSizing: "border-box",
        backgroundImage: "linear-gradient(#edd6a0, #e89d15)",
        position: "relative",
        transition: "0.7s",
        height: '100%'
    },
    headerBox: {
        height: "11%",
        display: "grid",
        gridTemplateColumns: "1fr 6fr",
        alignItems: "center",
        justifyItems: "left",
        position: "relative",
        zIndex: "2",
    },
    backBtn: {
        height: "24px",
        paddingLeft: "20px",
        cursor: "pointer"
    },
    container: {
        display: "grid",
        height: "100%",
        gridAutoRows: "27% 73%",
        overflow: 'hidden',
        touchAction: 'none',
        background: '#000000d4'
    },
    overlay: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        background: '#000',
        zIndex: '2',
        opacity: '1'
    },
    dataContainer: {
        transition: '.8s',
        display: 'none',
        opacity: '0',
        height: '60%',
        marginTop: '40%',
    },
    startBtn: {
        width: '85%',
        fontFamily: 'MotionControl',
        fontSize: '2rem',
        backgroundColor: '#F5D03C',
        padding: '0.5rem 1rem',
        border: 'none',
        borderRadius: '5px',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '2.5rem',
        },
    },
    headerQstnBox: {
        position: 'relative',
        zIndex: '3',
    },
    progressBarContainer: {
        position: "relative",
        height: (isMobile) => {
            return isMobile ? "4px" : "9px";
        },
        backgroundColor: '#fff',
        zIndex: "2",
        width: (isMobile) => {
            return `calc(100% - ${isMobile ? '0px' : '6px'})`
        },
        borderRadius: (isMobile) => {
            return `${isMobile ? '0px' : '8px'}`
        },
        margin: (isMobile) => {
            return isMobile ? "auto" : "0.3rem auto"
        },
        overflow: 'hidden',
    },
    progressBar: {
        position: "absolute",
        top: "0",
        left: "0",
        height: (isMobile) => {
            return isMobile ? "4px" : "9px";
        },
        backgroundColor: "#ffd042",
        width: '0',
        transition: '.4s',
    },
    qstnContainer: {
        height: "53%",
        margin: "10px 20px 0 20px",
        position: 'relative',
    },
    qstnBox: {
        backgroundColor: "#000",
        borderRadius: "10px",
        height: "100%",
        fontSize: "16px",
        display: 'grid',
        gridAutoRows: '40% 60%',
        alignItems: 'center',
        boxShadow: '0px 0px 12px 0px #FFFFFF',
    },
    qstn: {
        animation: "$blink 1s ease 0s infinite normal forwards running",
        height: '100%',
        alignItems: 'baseline !important',
        fontFamily: 'open sans',
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    "@keyframes blink": {
        "0%": { opacity: "0" },
        "30%": { opacity: "0" },
        "100%": { opacity: "1" }
    },

    dragCardContainer: {
        display: "grid",
        height: "100%",
        gridAutoRows: "50% 50%",
        transition: '.5s'
    },
    arrow: {
        position: 'absolute',
        top: '50%',
        left: '13%',
        display: 'none',
        zIndex: "3",
        alignItems: 'center',
        justifyContent: 'center',
    },
    arrowImg: {
        width: '45%',
    },
    arrowText: {
        fontSize: '14px',
        fontWeight: 800,
        padding: '0 1rem',
    },
    answerContainer: {
        display: "flex",
        padding: "3rem 0",
        flexWrap: "wrap",
    },
    answer: {
        flex: "1 1 33%",
        display: "flex",
        // overflow: 'hidden',
        padding: '0 1.2rem',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '.5s'
    },
    anwserImgBox: {
        position: 'relative',
        height: '85%',
        width: '85%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        transition: '0.1s'
    },
    answerText: {
        position: 'absolute',
        top: '100%',
        left: '50%',
        fontWeight: 700,
        transform: 'translate(-50%, -40%)',
        fontSize: '14px',
        textAlign: 'center',
        width: '100%',
        padding: '0 0.5rem',
        color: '#fff',
    },
    answerTextTrashTrivia: {
        position: 'absolute',
        top: '110%',
        left: '50%',
        // fontWeight: 700,
        transform: 'translate(-50%, -40%)',
        fontSize: '14px',
        textAlign: 'center',
        width: '100%'
    },
    answerImg: {
        height: '60px',
    },
    optionContainer: {
        display: "flex",
        padding: "3rem 0",
        flexWrap: "wrap",
        justifyContent: "center",
        transition: "transform 1s",
    },
    option: {
        flex: "1 1 33%",
        display: "flex",
        padding: '0 1.2rem',
        alignItems: 'center',
        justifyContent: 'center',
    },
    qstnImgBox: {
        position: 'relative',
        height: '70%',
        width: '45%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down("sm")]: {
            // mobile
            //width: '50%',
        },
    },
    qstnImgBoxTrashTrivia: {
        position: 'relative',
        height: '100%',
        width: '60%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down("sm")]: {
            // mobile
            //width: '50%',
        },
    },
    qstnText: {
        color: '#000',
        fontWeight: 700,
        fontSize: '14px',
        textAlign: 'center',
        padding: '0 0.8rem',
        position: 'absolute',
        color: '#fff',
        top: '100%',
        // whiteSpace: 'normal',
        // wordBreak: 'break-word'
    },
    qstnTextTrashTrivia: {
        color: '#000',
        // fontWeight: 700,
        fontSize: '14px',
        textAlign: 'center',
        padding: '0 0.5rem',
        width: '100%'
        // whiteSpace: 'normal',
        // wordBreak: 'break-word',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    lifeTime: {
        //opacity: '0',
        visibility: 'hidden',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 2rem',
        fontSize: '.8rem',
        animation: "$blink 1s ease 1s infinite normal forwards running",
        height: '100%',
    },
    icon: {
        margin: '0 5px',
        [theme.breakpoints.down("sm")]: {
            // mobile
            width: '11px',
        },
    },
    progressText: {
        marginTop: '2vh',
        display: 'grid',
        justifyContent: 'center',
        fontSize: '.8rem',
        display: 'none',
        [theme.breakpoints.down("sm")]: {
            marginTop: '0.5rem'
        }
    },
    arrow2Box: {
        textAlign: 'center',
    },
    arrowImg2: {
        width: '40%'
    },
    readyText: {
        fontFamily: "Montserrat",
        fontSize: '2rem',
        fontWeight: '900',
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: 'calc(3vw * 2.5)',
        },
    },
    restartText: {
        fontFamily: "MotionControl",
        fontSize: '2rem',
    },
    assetsText: {
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.2rem',
        },
    },
    completionText: {
        fontWeight: 800,
        padding: '1rem',
        textAlign: 'center',
        fontSize: '0.9rem',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.2rem',
        },
    }
}));

export default useStyle;
