import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import HeaderBackButton from '../layout-elements/header-back-button';
import constants from '../../constants';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { isAndroid } from 'react-device-detect';

// mui
import { styled } from '@mui/system';
import { Input, Typography, Button, CircularProgress } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

// utils
import isEmpty from '../../utils/isEmpty';
import { getHeaderHeight, isNativeApp } from '../../utils/commonUtil';
import commonUtil from '../../utils/commonUtil';

// styles
import useStyles from "./index.styles";

// react i18next
import { useTranslation } from 'react-i18next';

//redux
import { genericFileUpload } from '../../redux/microskill/microskill.api';
import { submitUserActivityData } from '../../redux/event-tracking/event.api';
import createSharingUrl from '../../utils/createSharingUrl';
import webViewJavaScriptBridge from 'webview-javascript-bridge';

const StyledInput = styled(Input)(({ theme }) => ({
    width: '100%',
    padding: '0.5rem 1rem',
    backgroundColor: theme.palette.white,
    borderRadius: theme.spacing(1),
    border: 'none',
    color: theme.palette.greyText1,
    outline: 'none',
    fontWeight: 500,
    '&:before': {
        display: 'none'
    },
    '&:after': {
        display: 'none'
    }
}));

const TcoCalculator = (props) => {
    const { headerText, handleBackBtnCompClick } = props;
    const userActivityData = useSelector(state => state?.event);
    const { userProfileData } = useSelector(state => state?.auth);
    const { HEADER_TOP_PADDING } = constants

    const pdfRef = useRef();
    const pdf = new jsPDF();
    // const pdf = new jsPDF("l", "pt", "a4", true);
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [tcoPdfData, setTcoPdfData] = useState(null);

    const { t } = useTranslation();

    const pdfMainRef = useRef();
    const iceCostRef = useRef();
    const iceCostForMonthRef = useRef();
    const evCostRef = useRef();
    const evCostForMonthRef = useRef();
    const [showCalcResults, setShowCalcResults] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [vehicleType, setVehicleType] = useState('EV');
    const [vehicleAmount, setVehicleAmount] = useState({
        ICE: '',
        EV: ''
    });
    const [downPayment, setDownPayment] = useState({
        ICE: '',
        EV: ''
    });
    const [interest, setInterest] = useState({
        ICE: '1',
        EV: '1'
    });
    const [loanTenure, setLoanTenure] = useState({
        ICE: '',
        EV: ''
    });
    const [emi, setEmi] = useState({
        ICE: '',
        EV: ''
    });
    const [dailyRun, setDailyRun] = useState({
        ICE: '175',
        EV: '175'
    });
    const [rwup, setRwup] = useState({
        ICE: '27.5',
        EV: '150'
    });
    const [chargingRate, setChargingRate] = useState({
        ICE: '90',
        EV: '6.5'
    });
    const [averageWorkDays, setAverageWorkDays] = useState({
        ICE: '28',
        EV: '28'
    });
    const [monthlyMaintenance, setMonthlyMaintenance] = useState({
        ICE: '1225',
        EV: '588'
    });
    const [ownershipTenure, setOwnershipTenure] = useState({
        ICE: '48',
        EV: '48'
    });
    const [showMore, setShowMore] = useState(false);
    const [totalInterest, setTotalInterest] = useState({
        ICE: '0',
        EV: '0'
    });
    const [totalCostOfOwnership, setTotalCostOfOwnership] = useState({
        ICE: '0',
        EV: '0'
    });
    const [costOfOwnershipForMonth, setCostOfOwnershipForMonth] = useState({
        ICE: '0',
        EV: '0'
    });
    const [costOfOwnershipForDay, setCostOfOwnershipForDay] = useState({
        ICE: '0',
        EV: '0'
    });
    const [totalChargingCost, setTotalChargingCost] = useState({
        ICE: '0',
        EV: '0'
    });
    const [totalMaintenanceCost, setTotalMaintenanceCost] = useState({
        ICE: '0',
        EV: '0'
    });
    const [calculationBtnDisabled, setCalculationBtnDisabled] = useState(true);
    const [shareBtnDisabled, setShareBtnDisabled] = useState(true);
    const [customerName, setCustomerName] = useState('');
    const [vehicleName, setVehicleName] = useState('');
    const [yourName, setYourName] = useState(`${userProfileData?.firstName} ${userProfileData?.lastName}` || '');
    const [contactNumber, setContactNumber] = useState(userProfileData?.mobile || '');

    // styling classes
    const classes = useStyles({ showPopup });

    useEffect(() => {
        calculateEMI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleType, vehicleAmount, downPayment, interest, loanTenure])

    const calculateEMI = () => {
        let currentVehicleAmount = vehicleAmount[vehicleType];
        let currentDownPayment = downPayment[vehicleType];
        let currentInterest = interest[vehicleType];
        let currentLoanTenure = loanTenure[vehicleType];

        let term = currentLoanTenure;
        // let term = currentLoanTenure * 12;
        // setTenureInMonths(term)
        let interestRate = isEmpty(currentInterest) ? 1 / 1200 : currentInterest / 1200;
        const top = Math.pow(1 + interestRate, term);
        const bottom = top - 1;
        const ratio = top / bottom;
        const principal = ((currentVehicleAmount - currentDownPayment) / term);
        const calculatedEmi = ((currentVehicleAmount - currentDownPayment) * interestRate * ratio);
        const totalInterestTemp = isEmpty(((calculatedEmi - principal) * term)) ? 0 : ((calculatedEmi - principal) * term);
        setEmi(prev => ({ ...prev, [vehicleType]: calculatedEmi.toFixed(2) }));
        setTotalInterest(prev => ({ ...prev, [vehicleType]: isEmpty(totalInterestTemp) || isNaN(totalInterestTemp) ? '0' : totalInterestTemp.toFixed(2) }));
    };

    useEffect(() => {
        if (
            // isEmpty(vehicleAmount) ||
            // isEmpty(loanTenure) ||
            // isEmpty(emi) ||
            isEmpty(dailyRun[vehicleType]) ||
            isEmpty(rwup[vehicleType]) ||
            // isEmpty(chargingRate) ||
            isEmpty(chargingRate[vehicleType]) ||
            isEmpty(averageWorkDays[vehicleType]) ||
            isEmpty(monthlyMaintenance[vehicleType])
            // || isEmpty(ownershipTenure)
        ) {
            setCalculationBtnDisabled(true);
            return
        } else {
            setCalculationBtnDisabled(false);
        }
        if (!isEmpty(tcoPdfData)) {
            setTcoPdfData(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleType, vehicleAmount, loanTenure, emi, dailyRun, rwup, chargingRate, averageWorkDays, monthlyMaintenance, ownershipTenure])

    useEffect(() => {
        if (
            isEmpty(customerName) ||
            isEmpty(vehicleName) ||
            isEmpty(yourName) ||
            isEmpty(contactNumber) ||
            contactNumber.trim().length !== 10
        ) {
            setShareBtnDisabled(true);
            return
        } else {
            setShareBtnDisabled(false);
        }
        if (!isEmpty(tcoPdfData)) {
            setTcoPdfData(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerName, vehicleName, yourName, contactNumber])


    function calculateTotalCost() {
        const BatterSize = 10.24
        const usableBattery = 90 / 100

        let currentVehicleAmount = vehicleAmount[vehicleType];
        let currentOwnershipTenure = ownershipTenure[vehicleType];
        let currentDailyRun = dailyRun[vehicleType];
        let currentRwup = rwup[vehicleType];
        let currentMonthlyMaintenance = monthlyMaintenance[vehicleType];
        let currentChargingRate = chargingRate[vehicleType];
        let currentAverageWorkDays = averageWorkDays[vehicleType];
        let currentInterest = interest[vehicleType];

        const kWhPerKm = (BatterSize * usableBattery) / currentRwup;

        const nullChecker = (val) => isEmpty(val) ? '0' : val;
        const denominatorChecker = (val) => isEmpty(val) ? '1' : val;

        // Convert loan tenure and ownership tenure to months
        const ownershipTenureInMonths = parseInt(nullChecker(currentOwnershipTenure));
        // const ownershipTenureInMonths = parseInt(nullChecker(currentOwnershipTenure)) * 12;
        // calculate total electricity or fuel cost

        let monthlyChargingCostICE, monthlyChargingCostEV;

        if (vehicleType === 'ICE') {
            monthlyChargingCostICE = (parseFloat(nullChecker(currentDailyRun)) / parseFloat(denominatorChecker(currentRwup)))
                * parseFloat(nullChecker(currentChargingRate))
                * parseFloat(nullChecker(currentAverageWorkDays))
        } else {
            monthlyChargingCostEV = parseFloat(nullChecker(currentDailyRun))
                * parseFloat(nullChecker(currentChargingRate))
                * parseFloat(nullChecker(currentAverageWorkDays))
                * kWhPerKm
        }

        const totalElectricityCost = (vehicleType === 'ICE' ? monthlyChargingCostICE : monthlyChargingCostEV) * ownershipTenureInMonths;

        // Calculate total loan amount
        let totalLoanAmount = parseFloat(nullChecker(currentVehicleAmount)) + parseFloat(nullChecker(currentInterest));

        // Calculate total maintenance cost
        let tempTotalMaintenanceCost = parseFloat(nullChecker(currentMonthlyMaintenance)) * ownershipTenureInMonths;

        // Calculate total cost of ownership
        let totalExpenses = totalElectricityCost + tempTotalMaintenanceCost;
        let tempTotalCostOfOwnership = totalLoanAmount + totalExpenses;

        let totalExpensePerMonth = (totalExpenses / denominatorChecker(ownershipTenureInMonths)).toFixed(2);
        let tempCostOfOwnershipForMonth = (tempTotalCostOfOwnership / denominatorChecker(ownershipTenureInMonths)).toFixed(2);
        let tempCostOfOwnershipForDay = tempCostOfOwnershipForMonth / parseFloat(currentAverageWorkDays);

        if (vehicleType === 'EV') {
            evCostRef.current = totalExpenses.toFixed(2);
            evCostForMonthRef.current = totalExpensePerMonth;
        }
        if (vehicleType === 'ICE') {
            iceCostRef.current = totalExpenses.toFixed(2);
            iceCostForMonthRef.current = totalExpensePerMonth;
        }

        setTotalCostOfOwnership(prev => ({ ...prev, [vehicleType]: tempTotalCostOfOwnership.toFixed(2) }));
        setCostOfOwnershipForMonth(prev => ({ ...prev, [vehicleType]: tempCostOfOwnershipForMonth }));
        setCostOfOwnershipForDay(prev => ({ ...prev, [vehicleType]: tempCostOfOwnershipForDay }));
        setTotalChargingCost(prev => ({ ...prev, [vehicleType]: totalElectricityCost.toFixed(2) }));
        setTotalMaintenanceCost(prev => ({ ...prev, [vehicleType]: tempTotalMaintenanceCost.toFixed(2) }));

        if (vehicleType === 'ICE') {
            var scrollableDiv = document.getElementById('tcoScrollDiv');
            scrollableDiv.scrollTop = 0;
            return setVehicleType('EV')
        }
        setShowCalcResults(true)
    }

    const handleSharePdf = () => {
        var pdfDoc = new jsPDF({
            compress: true,
            orientation: 'p',  // 'p' for portrait, 'l' for landscape
            unit: 'mm',        // units: mm, cm, in
            format: 'a4',      // format: 'a3', 'a4', 'a5', etc.
        });
        setLoadingPdf(true);
        setShowMore(false);
        pdfRef.current.style.borderRadius = "0px";
        pdfRef.current.style.fontSize = "0.5rem";
        pdfMainRef.current.style.height = "inherit";
        html2canvas(document.querySelector("#pdfRef"), { scale: 4 }).then(async (canvas) => {
            pdfDoc.addImage(
                canvas.toDataURL("image/png"),
                "png",
                0,
                0,
                pageWidth,
                pageHeight,
                // 'SLOW'
                'MEDIUM'
            )
            const tcoPdf = pdfDoc.output('blob');
            const tcoFormData = new FormData();
            tcoFormData.append('mediaFile', tcoPdf, `tco-calculation.pdf`);
            tcoFormData.append('folderName', "tco");
            const respData = await genericFileUpload(tcoFormData);

            if (respData?.status === 200) {
                let { location, mimetype } = respData?.data?.data[0];
                setTcoPdfData({ location, mimetype });
            }
            setLoadingPdf(false);
            pdfRef.current.style.paddingTop = "none";
        })
    }

    const handleCloseClick = () => {
        if (!isEmpty(pdfRef.current)) pdfRef.current.style.fontSize = null;
        if (!isEmpty(pdfMainRef.current)) pdfMainRef.current.style.height = null;
    }

    const handleShare = async () => {
        const { location, mimetype } = tcoPdfData;


        const createdUrl = `${createSharingUrl(location)}&sharedTo=${customerName.split(" ").join("_")}`
        try {
            // Check if the Web Share API is supported

            submitUserActivityData({
                oldScreen: userActivityData.currentScreen,
                currentScreen: "SALES ENABLEMENT PRODUCT DETAILS",
                nextSlide: '',
                activityType: `DOCUMENT SHARED`,
                activityData: {
                    screen: `TCO Calculator shared`,
                    sharedWith: customerName,
                    sharedBy: commonUtil.getUserName(),
                    sharedByID: commonUtil.getUserId()
                }
            });
            const isNativeApp = localStorage.getItem('isNative');
            if (isNativeApp === 'true') {
                webViewJavaScriptBridge.sendMessage({ action: 'ShareChannel', params: `'${location}|tco-calculation.pdf'` }, (response) => {
                });
            }

            else if (navigator.share) {
                await navigator.share({
                    title: `Total Cost of Ownership`,
                    text: `Dear ${customerName}, Thank you for your interest in ${vehicleName}. \nI'm sharing the total cost of ownership for your reference. \nThank You. \n${commonUtil.getUserName()} \n`,
                    // url: location,
                    url: createdUrl, // url to share
                })
                    .then(() => console.log('Successful share'))
                    .catch((error) => {
                        window.location.replace(location);
                        console.log('Error sharing generated content', error);
                    });

            } else {
                if (isNativeApp()) {
                    if (isAndroid) {
                        window.location.replace(`${location}&share=true&filetype=${mimetype}`)
                        return
                    }
                }
                window.location.replace(location)
            }
        } catch (error) {
            console.error('Error sharing:', error.message);
        }
    }

    const Header = () => {
        return (
            <div className={classes.emiCalcHeader}
                style={{ height: getHeaderHeight(), paddingTop: isNativeApp() ? HEADER_TOP_PADDING : '' }} >
                <div className={`${classes.centerFlex} ${classes.backButton}`}
                    onClick={() => {
                        handleCloseClick();
                        if (showCalcResults) return setShowCalcResults(false);
                        handleBackBtnCompClick(false);
                    }}
                >
                    <HeaderBackButton />
                </div>
                <div className={`${classes.centerFlex} ${classes.headerTitle}`}>
                    {t(headerText)}
                </div>
            </div>
        )
    }

    const CustomSwitch = () => {
        return (
            <div className={classes.customSwitchWrapper}>
                <div className={classes.typeText}>{t('Type of vehicle')}:</div>
                <div className={classes.customSwitchContainer}>
                    <div className={classes.selectionthumb}
                        style={{
                            transform: `translate(${vehicleType === "ICE" ? 'calc(0% + 0.1rem), -50%' : 'calc(100% - 0.5rem), -50%'})`
                        }}
                    ></div>
                    <div className={classes.switchText} onClick={() => { setVehicleType('ICE') }}>Basic</div>
                    <div className={classes.switchText} onClick={() => { setVehicleType('EV') }}>Advanced</div>
                </div>
            </div>
        )
    }

    return (
        <div className={classes.emiCalcRoot}>
            <Header />
            <div className={`${classes.emiCalcBody} scrollbar-hidden`}
                style={{ height: `calc(100% - ${getHeaderHeight()})` }}
                id="tcoScrollDiv">
                <div className={classes.tcoCalculator}>
                    {/* <CustomSwitch /> */}

                    {!showCalcResults ? (
                        <>
                            <form className={classes.form}>

                                <div className={classes.formControl}>
                                    <h2 className={classes.formControlLabel}>{t(`Enter values for ${vehicleType} vehicle`)}</h2>

                                </div>

                                {/* <==== Daily running ====> */}
                                <div className={classes.formControl}>
                                    <div className={classes.formControlLabel}>{t('Daily running')} *</div>
                                    <StyledInput
                                        type="number"
                                        value={dailyRun[vehicleType]}
                                        placeholder='Enter kilometers'
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            if (/^[0-9.+]*$/.test(value)) {
                                                setDailyRun(prev => ({ ...prev, [vehicleType]: value }));

                                            }
                                        }}
                                    />
                                </div>

                                {/* <==== RWUP Km/Charge ====> */}
                                <div className={classes.formControl}>
                                    <div className={classes.formControlLabel}>
                                        {vehicleType === "EV" ? t('RWUP Km/Charge') : t('Fuel efficiency (Km/Kg) (km/ltr)')} *
                                    </div>
                                    <StyledInput
                                        type="number"
                                        value={rwup[vehicleType]}
                                        placeholder='Enter units'
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            if (/^[0-9.+]*$/.test(value)) {
                                                setRwup(prev => ({ ...prev, [vehicleType]: value }));
                                            }
                                        }}
                                    />
                                </div>

                                {/* <==== Charging Rate (INR/kWh) ====> */}
                                <div className={classes.formControl}>
                                    <div className={classes.formControlLabel}>
                                        {vehicleType === "EV" ? t('Charging Rate (INR/kWh)') : t('Fuel cost (INR/kg) (INR/ltr)')} *
                                    </div>
                                    <StyledInput
                                        type="number"
                                        value={chargingRate[vehicleType]}
                                        placeholder='Enter units'
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            if (/^[0-9.+]*$/.test(value)) {
                                                setChargingRate(prev => ({ ...prev, [vehicleType]: value }))
                                            }
                                        }}
                                    />
                                </div>

                                {/* <==== Average Working Days Per Month ====> */}
                                <div className={classes.formControl}>
                                    <div className={classes.formControlLabel}>
                                        {t('Average Working Days Per Month')}*
                                    </div>
                                    <StyledInput
                                        type="number"
                                        value={averageWorkDays[vehicleType]}
                                        placeholder='Enter work days'
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            if (value > 31) return;
                                            if (/^[0-9.+]*$/.test(value)) {
                                                setAverageWorkDays(prev => ({ ...prev, [vehicleType]: value }));
                                            }
                                        }}
                                    />
                                </div>

                                {/* <==== Monthly Maintenance ====> */}
                                <div className={classes.formControl}>
                                    <div className={classes.formControlLabel}>
                                        {t('Monthly Maintenance')} *
                                    </div>
                                    <StyledInput
                                        type="number"
                                        value={monthlyMaintenance[vehicleType]}
                                        placeholder='Enter amount'
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            if (/^[0-9.+]*$/.test(value)) {
                                                setMonthlyMaintenance(prev => ({ ...prev, [vehicleType]: value }));
                                            }
                                        }}
                                    />
                                </div>

                                {/* <==== Enter Vehicle Ownership Tenure ====> */}
                                <div className={classes.formControl}>
                                    <Typography variant="h6" sx={theme => ({
                                        fontSize: '1.2rem',
                                        color: theme.palette.fontColor,
                                    })}>{t('Vehicle Ownership Tenure')} (in months)*</Typography>
                                    <StyledInput
                                        type="number"
                                        value={ownershipTenure[vehicleType]}
                                        placeholder='Vehicle Ownership Tenure (in months)'
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            if (/^[0-9.+]*$/.test(value)) {
                                                setOwnershipTenure(prev => ({ ...prev, ['ICE']: value, ['EV']: value }));
                                            }
                                        }}
                                    />
                                </div>


                                {/* On-road price of vehicle */}
                                <div className={classes.formControl}>
                                    <div className={classes.formControlLabel}> {t('On-road price of vehicle')}</div>
                                    <StyledInput
                                        type="number"
                                        value={vehicleAmount[vehicleType]}
                                        placeholder='Enter amount'
                                        sx={{ appearance: 'none', }}
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            if (/^[0-9.+,]*$/.test(value)) {
                                                setVehicleAmount(prev => ({ ...prev, [vehicleType]: value }));
                                            }
                                        }}
                                    />
                                </div>

                                {/* Down Payment */}
                                <div className={classes.formControl}>
                                    <div className={classes.formControlLabel}>{t('Down Payment')}</div>
                                    <StyledInput
                                        type="number"
                                        value={downPayment[vehicleType]}
                                        placeholder='Enter amount'
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            if (/^[0-9.+]*$/.test(value)) {
                                                setDownPayment(prev => ({ ...prev, [vehicleType]: value }));
                                            }
                                        }}
                                    />
                                </div>

                                {/* <==== Interest Rate ====> */}
                                <div className={classes.formControl}>
                                    <div className={classes.formControlLabel}>{t('Interest Rate')} (IRR)</div>
                                    <StyledInput
                                        type="number"
                                        value={interest[vehicleType]}
                                        placeholder='Enter percentage'
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            if (/^[0-9.+]*$/.test(value)) {
                                                setInterest(prev => ({ ...prev, [vehicleType]: value }));
                                            }
                                        }}
                                    />
                                </div>

                                {/* <==== Loan Duration ====> */}
                                <div className={classes.formControl}>
                                    <div className={classes.formControlLabel}>{t('Loan Duration')}(in months)</div>
                                    <StyledInput
                                        type="number"
                                        value={loanTenure[vehicleType]}
                                        placeholder='Enter Loan Duration(in Months)'
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            if (/^[0-9.+]*$/.test(value)) {
                                                setLoanTenure(prev => ({ ...prev, ['ICE']: value, ['EV']: value }));
                                            }
                                        }}
                                    />
                                </div>

                                {/* <==== EMI ====> */}
                                <div className={classes.formControl}>
                                    <div className={classes.formControlLabel}>{t('EMI')}</div>
                                    <StyledInput
                                        type="number"
                                        value={emi[vehicleType]}
                                        placeholder='Enter amount'
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            if (/^[0-9.+]*$/.test(value)) {
                                                setEmi(prev => ({ ...prev, [vehicleType]: value }));
                                            }
                                        }}
                                    />
                                </div>
                            </form>

                            {/* <==== Calculate btn ====> */}
                            <div className={`${classes.formControl} ${classes.centerFlex}`}>
                                <Button variant='contained' className={classes.tcoButtons}
                                    onClick={() => {
                                        calculateTotalCost();
                                        // setShowCalcResults(true)
                                    }}
                                    disabled={calculationBtnDisabled}
                                >
                                    {vehicleType === 'EV' ? (t('Calculate Ownership Cost')) : ('Next')}

                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div ref={pdfMainRef} >
                                <div id="pdfRef" ref={pdfRef} className={classes.pdfMainContainer}
                                    style={{ backgroundImage: showPopup ? `url(/images/documents/tco_pdf_bg.png)` : 'none', }}
                                >
                                    {
                                        showPopup && (
                                            <>
                                                <img
                                                    src={`/images/documents/mahindra_treo_clubbed.png`}
                                                    alt=" logo"
                                                    className={classes.companyBranding}
                                                />
                                                <div className={classes.userDetails} style={{
                                                    // paddingBottom: isEmpty(customerName) && isEmpty(vehicleName) ? '3rem' : '0',
                                                }}>
                                                    {
                                                        !isEmpty(customerName) && (
                                                            <div className={classes.pdfHeadingsText}>{t('Customer Name')}: {customerName}</div>
                                                        )
                                                    }
                                                    {
                                                        !isEmpty(vehicleName) && (

                                                            <div className={classes.pdfHeadingsText}>{t('Vehicle Name')}: {vehicleName}</div>
                                                        )
                                                    }
                                                </div>
                                            </>
                                        )
                                    }


                                    {/* <==== Total Cost of Ownership for some years ====> */}
                                    <div className={classes.textContainer}>
                                        <div className={classes.resultsHeading}>
                                            {t('Total Cost of Ownership for')} {ownershipTenure.EV} {t('month')}{ownershipTenure.EV * 12 > 1 ? 's' : ''}:
                                        </div>
                                        <div className={classes.resultsHighlightedText}>
                                            INR {parseFloat(isEmpty(totalCostOfOwnership.EV) ? "0" : totalCostOfOwnership.EV).toLocaleString('en-IN')}
                                        </div>
                                    </div>

                                    {/* <==== Total Cost of Ownership for 1 month ====> */}
                                    <div className={classes.textContainer}>
                                        <div className={classes.resultsHeading}>
                                            {t('Total Cost of Ownership for 1 month')}:
                                        </div>
                                        <div className={classes.resultsHighlightedText}>
                                            INR {parseFloat(isEmpty(costOfOwnershipForMonth.EV) ? "0" : costOfOwnershipForMonth.EV).toLocaleString('en-IN')}
                                        </div>
                                    </div>

                                    {/* <==== Total Cost of Ownership for 1 day ====> */}
                                    <div className={classes.textContainer}>
                                        <div className={classes.resultsHeading}>
                                            {t('Total Cost of Ownership for 1 day')}:
                                        </div>
                                        <div className={classes.resultsHighlightedText}>
                                            INR {parseFloat(isEmpty(costOfOwnershipForDay.EV) ? "0" : costOfOwnershipForDay.EV).toLocaleString('en-IN')}
                                        </div>
                                    </div>

                                    <div className={classes.breakupTextBox}>
                                        {/* <==== Breakup ====> */}
                                        <div className={classes.textContainer}>
                                            <div className={classes.resultsHeading}>
                                                {t('Breakup')}:
                                            </div>
                                        </div>

                                        {/* On road price */}
                                        <div className={classes.breakupTextContainer}>
                                            <div className={classes.resultsHeading}>
                                                {t('On-road price')}:
                                            </div>
                                            <div className={classes.resultsHeading}>
                                                INR {parseFloat(isEmpty(vehicleAmount.EV) ? "0" : vehicleAmount.EV).toLocaleString('en-IN')}
                                            </div>
                                        </div>

                                        {/* Maintenance */}
                                        <div className={classes.breakupTextContainer}>
                                            <div className={classes.resultsHeading}>
                                                {t('Maintenance')}:
                                            </div>
                                            <div className={classes.resultsHeading}>
                                                INR {parseFloat(isEmpty(totalMaintenanceCost.EV) ? "0" : totalMaintenanceCost.EV).toLocaleString('en-IN')}
                                            </div>
                                        </div>

                                        {/* Charging */}
                                        <div className={classes.breakupTextContainer}>
                                            <div className={classes.resultsHeading}>
                                                {t('Charging Cost')}:
                                            </div>
                                            <div className={classes.resultsHeading}>
                                                INR {parseFloat(isEmpty(totalChargingCost.EV) ? "0" : totalChargingCost.EV).toLocaleString('en-IN')}
                                            </div>
                                        </div>

                                        {/* Interest */}
                                        <div className={classes.breakupTextContainer}>
                                            <div className={classes.resultsHeading}>
                                                {t('Interest')}:
                                            </div>
                                            <div className={classes.resultsHeading}>
                                                INR {(isEmpty(totalInterest.EV) ? "0" : totalInterest.EV).toLocaleString('en-IN')}
                                            </div>
                                        </div>
                                    </div>


                                    {
                                        !showMore ? (
                                            <>
                                                {/* (!showPopup && <div className={classes.showMoreTxt} onClick={() => setShowMore(!showMore)}>Show more</div>) */}
                                            </>
                                        ) : (
                                            <section style={{
                                                paddingTop: '2rem',
                                            }}>
                                                {/* <==== Total Cost of Ownership for some years ====> */}
                                                <div className={classes.textContainer}>
                                                    <div className={classes.resultsHeading}>
                                                        {/* Total Cost of Ownership for {loanTenure} some year{loanTenure > 1 ? 's' : ''}: */}
                                                        {t('Total Cost of Ownership for')} {ownershipTenure.EV} {t('month')}{ownershipTenure.EV * 12 > 1 ? 's' : ''}:
                                                    </div>

                                                    <div className={classes.resultsHighlightedText}>
                                                        INR {parseFloat(isEmpty(totalCostOfOwnership.EV) ? "0" : totalCostOfOwnership.EV).toLocaleString('en-IN')}
                                                        {/* <CurrencyRupeeIcon className={classes.currencyIcon} /> {parseFloat(isEmpty(totalCostOfOwnership.EV) ? "0" : totalCostOfOwnership.EV).toLocaleString('en-IN')} */}
                                                    </div>
                                                </div>

                                                {/* <==== Total Cost of Ownership for 1 month ====> */}
                                                <div className={classes.textContainer}>
                                                    <div className={classes.resultsHeading}>
                                                        {t('Total Cost of Ownership for 1 month')}:
                                                    </div>

                                                    <div className={classes.resultsHighlightedText}>
                                                        INR {parseFloat(isEmpty(costOfOwnershipForMonth.EV) ? "0" : costOfOwnershipForMonth.EV).toLocaleString('en-IN')}
                                                        {/* <CurrencyRupeeIcon className={classes.currencyIcon} /> {parseFloat(isEmpty(costOfOwnershipForMonth.EV) ? "0" : costOfOwnershipForMonth.EV).toLocaleString('en-IN')} */}
                                                    </div>
                                                </div>

                                            </section>
                                        )
                                    }
                                    {
                                        showPopup && (

                                            <div className={classes.userDetails} style={{
                                                paddingTop: '3rem',
                                            }}>
                                                {
                                                    !isEmpty(yourName) && (
                                                        <div className={classes.pdfHeadingsText}>{t('FSC Name')}: {yourName}</div>
                                                    )
                                                }
                                                {
                                                    !isEmpty(contactNumber) && (
                                                        <div className={classes.pdfHeadingsText}>{t('Contact Number')}: {contactNumber}</div>
                                                        // })}>Contact Number: <a href={`tel:${contactNumber}`}>{contactNumber}</a></Typography>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            {/* btn to share pdf that wont be in pdf */}
                            <div className={`${classes.formControl} ${classes.centerFlex}`}>
                                <Button variant='contained' className={classes.tcoButtons}
                                    onClick={() => {
                                        setShowPopup(true);
                                        handleCloseClick();
                                        // handleSharePdf()
                                    }}
                                >
                                    {t('Share Cost Breakup')}
                                </Button>
                            </div>
                        </>
                    )}
                </div >
            </div >

            {/* <==== custom dialog box ====> */}
            {
                showPopup && <div className={`${classes.customDialog} `}>
                    <div className={classes.dialogHeader}>
                        <div className={classes.popupLabel}>
                            {t('Customise TCO Document')}
                        </div>
                        <div
                            className={`${classes.centerFlex} ${classes.backButton}`}
                            onClick={() => setShowPopup(false)}
                        >
                            <CloseRoundedIcon sx={theme => ({ fontSize: '2rem', color: theme.palette.orange })} />
                        </div>
                    </div>
                    <div className={classes.dialogBody}>

                        <div className={classes.formWrapper}>

                            {/* <==== Customer Name ====> */}
                            <div className={classes.formControl}>
                                <div className={classes.popupLabel}>{t('Enter Customer Name')} *</div>
                                <StyledInput
                                    type="text"
                                    value={customerName}
                                    placeholder='Enter name'
                                    onChange={(e) => setCustomerName(e.target.value)}
                                />
                            </div>

                            {/* <==== Vehicle Name ====> */}
                            <div className={classes.formControl}>
                                <div className={classes.popupLabel}>{t('Vehicle Name')} *</div>
                                <StyledInput
                                    type="text"
                                    value={vehicleName}
                                    placeholder='Enter vehicle name'
                                    onChange={(e) => setVehicleName(e.target.value)}
                                />
                            </div>

                            {/* <==== Enter Your name ====> */}
                            <div className={classes.formControl}>
                                <div className={classes.popupLabel}>{t('Your name')} *</div>
                                <StyledInput
                                    type="text"
                                    value={yourName}
                                    placeholder='Enter your name'
                                    onChange={(e) => setYourName(e.target.value)}
                                />
                            </div>

                            {/* <==== Enter Your Contact Number ====> */}
                            <div className={classes.formControl}>
                                <div className={classes.popupLabel}>{t('Your Contact Number')} *</div>
                                <StyledInput
                                    type="number"
                                    value={contactNumber}
                                    placeholder='Enter phone number'
                                    onChange={(e) => setContactNumber(e.target.value)}
                                />
                            </div>

                            <div className={`${classes.formControl} ${classes.centerFlex}`}>
                                {
                                    loadingPdf ? (
                                        <div className={`${classes.pdfGenerateLoader} ${classes.centerFlex}`}>
                                            <CircularProgress size={30} />
                                            <span>Generating</span>
                                        </div>
                                    ) : (
                                        <Button variant='contained' className={classes.tcoButtons}
                                            onClick={() => {
                                                isEmpty(tcoPdfData) ? handleSharePdf() : handleShare();
                                                handleCloseClick();
                                            }}
                                            disabled={shareBtnDisabled}
                                        >
                                            {isEmpty(tcoPdfData) ? t('Generate PDF') : t('Share PDF')}
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                </div>
            }
        </div >
    )
}

export default TcoCalculator;