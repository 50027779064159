import React, { useState, useRef } from "react";

// mui
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

// components

// redux

// utils
import isEmpty from "../../../utils/isEmpty";
import commonUtil from "../../../utils/commonUtil";

// constants

// styles
import useStyles from "./index.styles";
import ReactPlayer from "react-player";

// lottie

const RecommendedVideoPreview = (props) => {
    // console.log("RecommendedVideoPreview:", props);
    const {
        closeBtnFunc,
        videoData
    } = props;
    const classes = useStyles();
    const videoTimeRef = useRef();
    const timeElapsedRef = useRef();
    const [isVideoLoaded] = useState(false);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    // const onVideoLoad = (e) => {
    //     console.log("loaded:", e.target.duration);
    //     // videoRef.current.play();
    //     setIsVideoLoaded(true);
    //     videoDurationRef.current = videoRef.current.duration;
    //     videoRef.current.addEventListener('timeupdate', () => {
    //         videoDurationRef.current = videoRef.current.duration;
    //         // console.log("log:", videoDurationRef.current);
    //         // Update the elapsed time
    //         console.log("duration: ", videoRef.current.duration);
    //         const elapsedTime = Math.round(videoRef.current.currentTime);
    //         // let time = `Elapsed Time: ${elapsedTime} seconds`;
    //         const elapsedPer = (elapsedTime / videoData.timespent) * 100;
    //         videoTimeRef.current.innerHTML = commonUtil.getMinutesFromSeconds(elapsedTime);
    //         // console.log(" videoRef.current.currentTime::", elapsedPer);
    //         timeElapsedRef.current.style.width = `${elapsedPer}%`
    //     });
    // }

    const handlePlayClick = () => {
        // videoRef.current.play();
        setIsVideoPlaying(true)
    }
    const handlePauseClick = () => {
        // videoRef.current.pause();
        setIsVideoPlaying(false)
    }

    const handleDuration = (d) => {
        // console.log("d:", d);
    }
    const handleProgress = (d) => {
        // console.log("d:", d);
        videoTimeRef.current.innerHTML = commonUtil.getMinutesFromSeconds(Math.round(d.playedSeconds));
        // const elapsedPer = (time / videoData.timespent) * 100;
        // timeElapsedRef.current.style.width = `${elapsedPer}%`;
    }

    return (
        <div className={classes.recommendedVideoPreviewRoot}>
            <div className={classes.header}>
                <div className={classes.microskillName}>Test microskillName</div>
                <div className={`${classes.closeBtnBox} ${classes.centerFlex}`}><CloseIcon onClick={() => closeBtnFunc(false)} /></div>
            </div>

            <div className={classes.modalBody}>
                <div className={classes.videoBodyBox}>
                    <ReactPlayer
                        // light="http://placekitten.com/200/300" // thumbnail
                        url={videoData}
                        playing={!isEmpty(isVideoPlaying) ? isVideoPlaying : isVideoPlaying}
                        width={'100%'}
                        height={`100%`}
                        onDuration={handleDuration}
                        onProgress={handleProgress}
                        // onEnded={handlVideoStop}
                        // playsinline={playsinline}
                        style={{ borderRadius: '10px' }}
                        className={classes.reactPlayerClass}
                    />
                    {/* <video className={classes.videoBox} src={videoData.videoPath} ref={videoRef} onLoadedMetadata={onVideoLoad}> </video> */}
                    {
                        // !isVideoPlaying && (
                        //     <div className={classes.videoParameters}>
                        //         <div className={classes.learnerName}>{videoData.firstneme} {videoData.lastName}</div>
                        //         <div className={classes.paramBox}>
                        //             {
                        //                 !isEmpty(videoData.response.duration) && (
                        //                     <div className={classes.param}>
                        //                         <div className={classes.paramText}>{"DURATION:"}</div>
                        //                         <div className={classes.paramValue}>{videoData?.response?.duration?.qualitativeFeedback}</div>
                        //                     </div>
                        //                 )
                        //             }
                        //             {
                        //                 !isEmpty(videoData.response.speechRate) && (
                        //                     <div className={classes.param}>
                        //                         <div className={classes.paramText}>{"SPEECH RATE:"}</div>
                        //                         <div className={classes.paramValue}>{videoData?.response?.speechRate?.qualitativeFeedback}</div>
                        //                     </div>
                        //                 )
                        //             }
                        //             {
                        //                 !isEmpty(videoData.response.keywordMatch) && (
                        //                     <div className={classes.param}>
                        //                         <div className={classes.paramText}>{"KEYWORD MATCH:"}</div>
                        //                         <div className={classes.paramValue}>{videoData?.response?.keywordMatch?.qualitativeFeedback}</div>
                        //                     </div>
                        //                 )
                        //             }
                        //             {
                        //                 !isEmpty(videoData.response.transcriptMatch) && (
                        //                     <div className={classes.param}>
                        //                         <div className={classes.paramText}>{"TRANSCRIPT:"}</div>
                        //                         <div className={classes.paramValue}>{videoData?.response?.transcriptMatch?.qualitativeFeedback}</div>
                        //                     </div>
                        //                 )
                        //             }
                        //             {
                        //                 !isEmpty(videoData.response.tone) && (
                        //                     <div className={classes.param}>
                        //                         <div className={classes.paramText}>{"TONE:"}</div>
                        //                         <div className={classes.paramValue}>{videoData?.response?.tone?.qualitativeFeedback}</div>
                        //                     </div>
                        //                 )
                        //             }
                        //             {
                        //                 !isEmpty(videoData.response.clarity) && (
                        //                     <div className={classes.param}>
                        //                         <div className={classes.paramText}>{"CLARITY:"}</div>
                        //                         <div className={classes.paramValue}>{videoData?.response?.clarity?.qualitativeFeedback}</div>
                        //                     </div>
                        //                 )
                        //             }
                        //         </div>
                        //     </div>
                        // )
                    }
                </div>
                <div className={classes.videoScrubber}>
                    <div className={classes.playBtn}>
                        {(isVideoLoaded || true) && (
                            !isVideoPlaying ? (
                                <PlayArrowIcon sx={{ color: 'white' }} onClick={handlePlayClick} />
                            ) : (
                                <PauseIcon sx={{ color: 'white' }} onClick={handlePauseClick} />
                            )
                        )}
                    </div>
                    <div className={classes.scrubberBox}>
                        <div className={classes.scrubber}>
                            <div className={classes.timeElapsed} ref={timeElapsedRef} />
                        </div>
                        <div className={classes.videoTime} ref={videoTimeRef}>0:00</div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default RecommendedVideoPreview;