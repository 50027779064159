// DIAGEO ID ON AZURE
const MsalConfig = {
    // clientId: '446431d2-57c1-4bf2-8603-c2b2c8c1e5f3', //QA
    clientId: '620aa9c5-49ee-47d9-9180-5b8cacb6a67f', //PROD
    redirectUri: `${window.origin}/login/msal`,
    scopes: ["User.Read"],
    authority: "https://login.microsoftonline.com/88ed286b-88d8-4faf-918f-883d693321ae/"
}

// DEV MAYANK ID ON AZURE
// const MsalConfig = {
//     clientId: '9503f623-6771-4e80-a263-6865d716c384',
//     redirectUri: `${window.origin}/login/msal`,
//     scopes: ["User.Read"],
//     authority: "https://login.microsoftonline.com/5b3da140-87bd-4b4d-a647-61f4a6de6df0/"
// }

export default MsalConfig;