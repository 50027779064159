import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    lottieLoader: {
        // padding: '2rem'
    },
    loadingText: {
        fontSize: '2.5em',
        color: '#F4511E',
        fontFamily: "montserrat",
        fontWeight: 900,
        textAlign: 'center'
    },
    card: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '20px 20px 49px #00000029',
        borderRadius: '12px',
        padding: '2rem',
        margin: '2rem 0',
    },
}))

export default useStyles;