import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//  mui
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// API's
import { startChallengeAttempt } from "../../redux/microskill/microskill.api";
import { getLearnerJourneyStatus, submitEndChallengeAttempt } from "../../redux/gba/gba.api";
import { setLearnerJourneyEnded } from "../../redux/common/common-slice";

// utils
import commonUtil, { isNativeApp } from "../../utils/commonUtil";

// styles
import useStyles from "./index.styles";
import LaWinScreen from "../../components/ila/win-screen";

// constants
import constants from "../../constants";
import isEmpty from "../../utils/isEmpty";
const scormUrl = process.env.REACT_APP_SCROM_BUCKET_URL;


const ScormContent = () => {
    const { microskillId, challengeId, folderName } = useParams();

    const styles = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let [searchParams, setSearchParams] = useSearchParams();
    let journeyId = searchParams.get('journeyId');
    const userId = commonUtil.getUserId();
    const companyId = commonUtil.getCompanyId();
    const [showWinScreen, setShowWinScreen] = useState(false);
    let startChallengeCalled = false;
    const isMobileRotated = useSelector(state => state?.common?.isRotatedMobile);
    const isMobile = useSelector(state => state?.common?.isMobile);
    const scormContainerRef = useRef(null);
    const intervalRef = useRef();
    const timerRef = useRef(0);
    let lastMessageFromScorm = useRef('');
    const scormAttemptData = useRef({
        attemptId: '',
        attemptNo: 0,
        maxScore: 0,
        score: 0,
        completionStatus: '',
        exitStatus: ''
    });

    const startTimer = () => {
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            timerRef.current += 1;
        }, 1000);
    }

    const stopTimer = () => {
        clearInterval(intervalRef.current);
    }

    useEffect(() => {
        var handleMessage = async (event) => {
            if (event.data.type === "SCORM") {
                console.log('message', event.data.message);
                let message = event.data.message;


                let value = !isEmpty(message.value) && typeof message.value === 'string' ? message.value : !isEmpty(message.payload) && !isEmpty(message.payload.string) && typeof message.payload.string === 'string' ? message.payload.string : '';

                console.log('value', value);

                message = {
                    action: message.action,
                    parameter: !isEmpty(message.parameter) && typeof message.parameter === 'string' ? message.parameter : message.payload.element,
                    value
                }

                if (lastMessageFromScorm.current !== message.parameter) {
                    lastMessageFromScorm.current = message.parameter;
                    if (message.action === "LMSInitialize" && startChallengeCalled === false) {
                        startTimer();
                    }
                    else if (message.action === "LMSFinish" && scormAttemptData.current.attemptId !== '') {
                        console.log('LMSFinish');
                        window.removeEventListener("message", handleMessage);
                        endChallengeWithPass();
                    }
                    else if (message.action === "LMSSetValue") {
                        if (message.parameter === "cmi.core.lesson_status") {
                            scormAttemptData.current.completionStatus = message.value;
                            if (companyId !== 'compy_GUpDhOe30EN8JNb4woT') {
                                if (message.value === "completed") {
                                    console.log('completionStatus', message.value);
                                    window.removeEventListener("message", handleMessage);
                                    endChallengeWithPass();
                                }
                                else if (message.value === "passed") {
                                    console.log('completionStatus', message.value);
                                    window.removeEventListener("message", handleMessage);
                                    endChallengeWithPass();
                                } else if (message.value === "failed") {
                                    console.log('completionStatus', message.value);
                                    window.removeEventListener("message", handleMessage);
                                    endChallengeWithPass();
                                }
                            }
                        }
                        if (message.parameter === "cmi.core.score.raw") {
                            scormAttemptData.current.score = message.value;
                        }
                        if (message.parameter === "cmi.core.score.max") {
                            scormAttemptData.current.maxScore = message.value;
                        }
                        if (message.parameter === "cmi.core.exit") {
                            if (scormAttemptData.current.exitStatus === '') {
                                scormAttemptData.current.exitStatus = message.value;
                            } else if (scormAttemptData.current.exitStatus !== '' && scormAttemptData.current.attemptId !== '') {
                                if (companyId === 'compy_GUpDhOe30EN8JNb4woT' && message.value === "") {
                                    console.log('Mondelez exitStatus', message.value);
                                    window.removeEventListener("message", handleMessage);
                                    endChallengeWithPass();
                                } else if (message.value === "suspend") {
                                    console.log('exitStatus', message.value);
                                    window.removeEventListener("message", handleMessage);
                                    endChallengeWithPass();
                                }
                                else if (message.value === "") {
                                    console.log('exitStatus', message.value);
                                    window.removeEventListener("message", handleMessage);
                                    endChallengeWithPass();
                                } else if (message.value === "normal") {
                                    console.log('exitStatus', message.value);
                                    window.removeEventListener("message", handleMessage);
                                    endChallengeWithPass();
                                } else if (message.value === "logout") {
                                    console.log('exitStatus', message.value);
                                    window.removeEventListener("message", handleMessage);
                                    endChallengeWithPass();
                                }
                            }
                        }
                    }
                }

            }
        };

        window.addEventListener("message", handleMessage);
        return () => {
            setShowWinScreen(false);
            window.removeEventListener("message", handleMessage);
            scormAttemptData.current = {};
        }

    }, [folderName]);


    const endChallengeWithPass = async () => {
        setShowWinScreen(true);
        stopTimer();
        submitEndChallengeAttempt({
            attemptNo: scormAttemptData.attemptNo,
            attemptId: scormAttemptData.current.attemptId,
            microskillId: microskillId,
            challengeId: challengeId,
            companyId: companyId,
            userId: userId,
            timespent: timerRef.current,
            completion: true,
            status: "COMPLETED",
            scored: parseInt(scormAttemptData.current.score)
        })

        if (journeyId && journeyId !== 'null') {
            let reqData = {
                microskillId,
                challengeId,
                journeyId
            }
            getLearnerJourneyStatus(reqData).then(res => {
                dispatch(setLearnerJourneyEnded(res?.data?.data?.journeyEnded))
            })
        }
    }

    const handleBackClick = async () => {
        stopTimer();
        submitEndChallengeAttempt({
            attemptNo: scormAttemptData.current.attemptNo,
            attemptId: scormAttemptData.current.attemptId,
            microskillId: microskillId,
            challengeId: challengeId,
            companyId: companyId,
            userId: userId,
            timespent: timerRef.current,
            completion: false,
            status: "EXITED",
            scored: parseInt(scormAttemptData.current.score)
        });
        let reqData = {
            microskillId,
            challengeId,
            journeyId
        }
        getLearnerJourneyStatus(reqData).then(res => {
            dispatch(setLearnerJourneyEnded(res?.data?.data?.journeyEnded))
        })
        navigate(-1);
    }

    return (
        <div className={styles.ScromContainer} ref={scormContainerRef}

            // style={{ minHeight: isMobile ? `calc(${FULL_HEIGHT_MOBILE})` : `calc(${FULL_HEIGHT_DESKTOP})` }}
            // style={constants.FULL_HEIGHT_CENTER}
            style={isNativeApp() ? { ...constants.FULL_HEIGHT_CENTER_NATIVE }
                : (isMobile ? {
                    height: `calc(${window.innerHeight}px - ${constants.HEADER_HEIGHT})`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                } : { ...constants.FULL_HEIGHT_CENTER })
            }
        >
            {
                showWinScreen === false ? (
                    <>
                        {!isMobile && (
                            <div className={`${styles.centerFlex} ${styles.ScromContainerBack}`} onClick={handleBackClick}>
                                <ArrowBackIcon style={{ fontSize: '1.5rem', fontWeight: '500' }} />
                            </div>
                        )}
                        <div className={`${isMobile ? isMobileRotated ? styles.iframeMobileRootRotate : styles.iframeMobileRoot : styles.iframeRoot}`}
                            style={{ width: isMobile ? `calc(100vh - ${constants.HEADER_HEIGHT})` : `calc(100vw - ${constants.HEADER_HEIGHT})` }}
                        >
                            <iframe id="scromContentContainer"
                                className={`${styles.iframe}`}
                                src={`${scormUrl + folderName}/content_start.html`}
                                style={{ width: isMobile ? '100%' : '90%' }}
                                title="scormContainer"
                                onLoad={async () => {
                                    const respData = await startChallengeAttempt({ microskillId, challengeId });
                                    if (respData.status === 200 || respData.status === 201) {
                                        scormAttemptData.current.attemptId = respData.data.data.id;
                                        scormAttemptData.current.attemptNo = respData.data.data.attemptNo;
                                        scormAttemptData.current.completionStatus = '';
                                        scormAttemptData.current.exitStatus = '';
                                        scormAttemptData.current.maxScore = 0;
                                        scormAttemptData.current.score = 0;
                                    }
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <div className={styles.LaWinScreen}>
                        <LaWinScreen time={timerRef.current} completeText={'SCORM CONTENT'} />
                    </div>
                )
            }
        </div>
    )
}

export default ScormContent;