import { makeStyles } from "@mui/styles";
import { isNativeApp } from "../../../utils/commonUtil";
import constants from "../../../constants";

const GBA_HEADER_HEIGHT = isNativeApp() ? constants.HEADER_HEIGHT_MOBILE : constants.HEADER_HEIGHT

const useStyles = makeStyles((theme) => ({
    RevealPicture: {
        color: "#fff",
        boxSizing: "border-box",
        position: "relative",
        //transition: "0.7s",
        backgroundSize: '600% 100%',
        animation: '$animateGameLandingPage 5s ease infinite',
        height: '100%',
    },
    "@keyframes animateGameLandingPage": {
        '0%': { backgroundPosition: '0%' },
        '100%': { backgroundPosition: '100%' }
    },
    startPage: {
        height: "100%",

    },
    gradientOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0',
        backgroundImage: 'linear-gradient(#9d9a9a, #ed0900)',
        opacity: '0',
        transition: '.5s ease',
        zIndex: '1'
    },
    container: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: "100%",
        overflow: 'hidden',
        touchAction: 'none',
        opacity: '1',
        zIndex: '2',
        position: 'relative'
    },
    containerBox: {
        display: 'grid',
        height: '100%',
        gridAutoRows: '1.25% 25% 73.75%',
    },
    progressBarContainer: {
        position: "relative",
        height: "100%",
        zIndex: "2",
        width: '100%',
        borderRadius:'0px',
        margin: 'auto',
        overflow: 'hidden',
        backgroundColor: theme.palette.white,
        [theme.breakpoints.down("md")]: {
            backgroundColor: theme.palette.progressBarBg,
        },
        // marginTop: '4px',
    },
    progressBar: {
        height: "100%",
        backgroundColor: "#ffd042",
        width: '0',
        transition: '.4s',
    },
    // questionBox container
    qstnContainer: {
        cursor: 'default',
        height: '100%',
        width: '100%',
        // padding: '0.6rem 0.6rem 0 0.6rem',
        // minHeight: "9em",
        transform: "translateY(-200px)",
        animation: "$translateQstnContainer 1s ease 0s 1 normal forwards running",
        [theme.breakpoints.down("sm")]: {
            // padding: '1.5rem 1.5rem .4rem 1.5rem',
        },
        opacity: '0.8',
    },
    "@keyframes translateQstnContainer": {
        "0%": { transform: "translateY(-200px)" },
        "100%": { transform: "translateY(0)" }
    },
    qstnBox: {
        // borderRadius: "10px",
        fontSize: "1.2em",
        // boxShadow: '0px 10px 20px #0000004d',
        background: '#1D1D1D 0% 0% no-repeat padding-box',
        display: 'grid',
        gridAutoRows: '35% 65%',
        height: '100%'
    },
    assetBox: {
        display: "grid",
        // gridTemplateColumns: "1fr 1fr",
        gridTemplateColumns: "1fr  1fr 1fr",
        padding: "12px",
        alignItems: 'center'
    },
    lifeText: {
        display: "flex",
        alignItems: "center",
        // fontSize: '1em',
        [theme.breakpoints.down("sm")]: {
            fontSize: 'clamp(1rem, 1.2vw, 1.4rem)',
        },
    },
    icon: {
        width: '1em',
        height: '1em',
        margin: "0 5px"
    },
    timerText: {
        display: "flex",
        alignItems: "center",
        paddingRight: '1rem',
        //justifyContent: "right",
        justifyContent: "end",
        [theme.breakpoints.down("sm")]: {
            fontSize: 'clamp(1rem, 1.2vw, 1.4rem)',
        },
    },
    timerIcon: {
        width: "1em",
        height: "1em",
        margin: "0 5px",
        [theme.breakpoints.down("sm")]: {
            width: "14px",
            height: "14px",
        },
    },
    time: {
        fontWeight: '600',
        fontSize: '0.8rem',
        minWidth: '2rem'
    },
    qstn: {
        padding: "12px",
        textAlign: "center",
        transition: '.5s',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Open Sans',
        fontFamily: 'Montserrat',
        fontSize: '0.9em',
        wordBreak: "break-word",
        opacity: '1',
        transition: '.8s',
        fontWeight: 'bold',
        [theme.breakpoints.down("sm")]: {
            fontSize: '1rem',
        },
    },
    gamePlayBox: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    dropContainer: {
        height: "80%",
        width: "95%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "repeat(4, 1fr)",
        backgroundPosition: 'center',
        borderRadius: '7px',
        overflow: 'hidden',
    },
    optionContainer: {
        transistion: "0.2s ease-in-out",
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        opacity: '1',
        border: '1px solid #fff',
        padding: '1rem',
        fontWeight: 'bold',
        fontFamily: 'Roboto, sans-serif'
    },
    optionUnreveal: {
        transistion: "0.2s ease-in-out",
        background: "#0090B5",
        opacity: '1',
        fontSize: '1em'
    },
    optionOnClick: {
        transition: "0.4s ease-in-out",
        background: "#F5D03C",
    },
    optionCorrect: {
        transition: "0.5s ease-in-out",
        textAlign: "center",
        backgroundBlendMode: "multiply",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        color: "transparent",
        backgroundColor: "green",
    },
    optionIncorrect: {
        transition: "0.5s ease-in-out",
        color: "transparent",
        backgroundSize: "contain",
    },
    optionRevealCorrect: {
        transition: "0.5s ease-in-out",
        backgroundColor: "rgb(0, 0, 0, 0.4)",
    },
    optionIncorectReveal: {
        transistion: "1s ease-in-out",
        backgroundColor: "rgb(246, 59, 52, 0.5)",
        backgroundBlendMode: "multiply",
        background: "transparent",
        backdropFilter: "blur(4px)",
        height: "25%",
    },
    incorrectGradientOverlayAnimationInfinite: {
        animation: '$incorrectGradientOverlayAnimationInfinite 1.5s ease-in-out 0s infinite normal forwards running'
    },
    '@keyframes incorrectGradientOverlayAnimationInfinite': {
        '0%': { opacity: "0" },
        "20%": { opacity: "1" },
        "50%": { opacity: "1" },
        "75%": { opacity: "1" },
        "100%": { opacity: '0' }
    },
    questionNo: {
        textAlign: 'center',
        fontWeight: 600,
        color: '#F5CF38',
        letterSpacing: '0.2rem'
    },

    // how to play
    howToPlayContainer: {
        position: 'fixed',
        // width: '100%',
        height: `calc(100dvh)`,
        top: 0,
        left: 0,
        paddingTop: GBA_HEADER_HEIGHT,
        zIndex: 9999,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        [theme.breakpoints.up("sm")]: {
            height: `calc(100dvh - ${GBA_HEADER_HEIGHT})`,
            top: GBA_HEADER_HEIGHT,
            paddingTop: 0,
            aspectRatio: '9/16',
            left: '50%',
            transform: 'translateX(-50%)',
        },
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        }
    },
    skipBtnContainer: {
        position: 'fixed',
        top: '75%',
        left: '75%'
    },
    skipBtn: {
        border: '2px solid #fff !important',
        borderRight: 'none !important',
        borderRadius: '2rem 0 0 2rem !important',
        fontWeight: '700 !important',
        fontSize: '1.2rem !important',
        fontFamily: 'Open sans !important',
        paddingLeft: '1.6rem !important',
        backgroundColor: `#fff !important`
    },
}));

export default useStyles;