import React, { useEffect } from 'react'

//  Okta
import { useOktaAuth } from '@okta/okta-react';

//  utils
import isEmpty from "../../utils/isEmpty"
import LoadingScreen from '../../components/loading-screen';

const RootPage = () => {
    const { authState, oktaAuth } = useOktaAuth();

    useEffect(() => {
        if (isEmpty(authState)) {
            setTimeout(() => {
                // window.location.href = "/login";
            }, [4000])
        }

        // console.log(authState);

        // oktaRef.current = setInterval(() => {
        //     if (isEmpty(authState)) {
        //         clearInterval(oktaRef.current)
        //         console.log(authState?.accessToken);
        //     }
        //     else {
        //         console.log("REDIRECTED to LOGIN", authState);
        //         console.log("AUTHSTATE ACCESS TOKEN", authState?.accessToken);
        //     }
        // }, [3000])
        // if(!isEmpty(authState)){
        //     clearInterval(oktaRef.current)
        // }
    }, [authState, oktaAuth])

    return (
        <LoadingScreen loadingText={true} />
    )
}

export default RootPage