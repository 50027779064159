import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

// MUI
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import ReplayIcon from '@mui/icons-material/Replay';

// utils
import isEmpty from "../../utils/isEmpty";

//styles
import useStyle from "./index.styles";

const LaVoiceOverAudioPlayer = (props) => {
    const { voiceOverTrack, loop = false, isAutoPlay = true } = props;
    // const { voiceOverTrack, isAutoPlay = false } = props;
    const classes = useStyle();
    const isMobile = useSelector(state => state?.common?.isMobile);
    const [isVoiceoverAudioPlayer, setIsVoiceoverAudioPlayer] = useState(false) // la card voiceover audio state<play/pause>
    const [audioEnded, setAudioEnded] = useState(false)

    const voiceOverAudioRef = React.useRef()


    const handleSpeakerIconClick = () => {
        // console.log(voiceOverAudioRef.current, voiceOverTrack, "voiceOverAudioRef.current")
        if (isEmpty(voiceOverAudioRef.current) || isEmpty(voiceOverAudioRef.current.src)) return
        // console.log(isVoiceoverAudioPlayer)
        if (!isVoiceoverAudioPlayer) {
            voiceOverAudioRef.current.play()
            setIsVoiceoverAudioPlayer(true)
        } else {
            voiceOverAudioRef.current.pause()
            setIsVoiceoverAudioPlayer(false)
            setAudioEnded(false);
        }
    }

    // mute audio and change icon onchange of track
    useEffect(() => {
        setIsVoiceoverAudioPlayer(false)
    }, [voiceOverTrack])

    const handleAudioLoad = (e) => {
        // console.log("autoplay ", navigator.userActivation.hasBeenActive)
        if (isAutoPlay && navigator && navigator.userActivation && navigator.userActivation?.hasBeenActive) { // if cover card and user has interacted with the page (it prevents the error if page refreshed at la cards screen)
            voiceOverAudioRef.current.play();
            setIsVoiceoverAudioPlayer(true);
        }
    }


    return (
        <div
            className={`${classes.voiceoverPlayerRoot} ${classes.centerFlex}`}
            // id="LA_CARD_VOICE_OVER_AUDIO_PLAYER"
            onClick={handleSpeakerIconClick}
        >
            {
                isVoiceoverAudioPlayer ? (
                    <VolumeUpIcon sx={theme => ({ fontSize: '1.5rem', color: theme.palette.orange })} />
                ) : (
                    audioEnded ?
                        <ReplayIcon sx={theme => ({ fontSize: '1.5rem', color: theme.palette.greyText2 })} />
                        :
                        <VolumeMuteIcon sx={theme => ({ fontSize: '1.5rem', color: theme.palette.greyText2 })} />
                )
            }
            <audio
                src={voiceOverTrack}
                ref={voiceOverAudioRef}
                controls={false}
                style={{ visibility: 'hidden', opacity: 0 }}
                onEnded={(e) => {
                    // console.log(isAutoPlay, "isAutoPlay")
                    // if (isAutoPlay) {
                    if (loop) {
                        e.target.play();
                    } else {
                        setIsVoiceoverAudioPlayer(false)
                        setAudioEnded(true)
                    }
                }}
                preload="metadata"
                onLoadedMetadata={handleAudioLoad}
            />
        </div>
    )
}

export default LaVoiceOverAudioPlayer;