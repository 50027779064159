import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    loadingMicroskillRoot: {

    },
    modalBox: {
        backgroundImage: 'linear-gradient(#4F0060, #080037)'
    },
    modalBoxContainer: {
        backgroundSize: 'contain',
        // backgroundRepeat: 'no-repeat',
        height: '100%',
        backgroundImage: 'url(/images/common/master_o_login_illustration_bg.svg)',
        padding: '2rem',
        display: 'grid',
        color: '#FFF',
        gridTemplateRows: '70% 10% 20%',
        // gridTemplateRows: 'calc(50% + 150px) 1fr 2fr',
        justifyContent: 'center'
    },
    modalContainer: {
        display: 'grid',
        minWidth: '250px',
        // alignContent: 'flex-end'
    },
    loaderContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // height: '300px'
    },
    logoOuter: {
        position: 'absolute',
        width: '90%',
        animation: "$rotateOuter 2s linear infinite",
    },
    logoInner: {
        position: 'absolute',
        width: '45%',
    },
    '@keyframes rotateOuter': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' }
    },
    loadingText: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    subTextContainer: {
        textAlign: 'center',
        display: 'grid',
        alignContent: 'center',
        justifyItems: 'center',
        gap: '2rem'
    }
}))

export default useStyles