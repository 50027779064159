import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    accordionRoot: {

        '& .MuiPaper-root': {
            flexDirection: 'column-reverse',
            display: 'flex',
            padding: '0 0.6rem',
            fontSize: '0.8rem',
            overflow: 'hidden'
        },
        '& .MuiAccordionSummary-root': {
            
        },
        '& .MuiAccordionSummary-content': {
            margin: '0.2rem 0 !important'
        },
        '& .MuiAccordionDetails-root': {
            padding: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // paddingTop: '0.6rem',
            color: '#FFF',
            fontWeight: 700,
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        },
    },
    accordionHeading: {
        fontFamily: 'montserrat',
        fontWeight: '600 !important',
        textTransform: 'uppercase',
        fontSize: '0.65rem !important',
        color: '#FFF !important',
        whiteSpace: 'nowrap',
        lineHeight: 'inherit !important'
        // width: 'min-content'
    },
    accordionDetails: {
        color: '#FFF !important',
        fontWeight: '600 !important',
        fontFamily: 'montserrat',
        fontSize: '3rem !important',
        userSelect: 'none',
        lineHeight: 'inherit !important'
    },
    formattedHtml: {
        '& b': {
            color: 'red'
        }
    },
}))

export default useStyles;