import React from "react";

// components
import Shimmer from "..";

// styles
import useStyles from "./index.styles";

const ShimmerLearn = () => {
    const classes = useStyles();
    return (
        <div className={classes.shimmerContainer}>
            <div className={classes.section1Box}>
                <div className={classes.heading}></div>
                <div className={`${classes.section1} ${classes.centerFlex}`}>
                    <Shimmer data={`0`} />
                    <Shimmer data={`2`} />
                </div>
            </div>


            <div className={classes.section2Box}>
                <div className={classes.heading}></div>
                <div className={`${classes.section1} ${classes.centerFlex}`}>
                    <Shimmer data={`1`} />
                    <Shimmer data={`2`} />
                </div>
                <div className={`${classes.section1} ${classes.centerFlex}`}>
                    <Shimmer data={`3`} />
                    <Shimmer data={`4`} />
                </div>
            </div>

        </div>
    )
}

export default ShimmerLearn;