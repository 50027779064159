import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import useStyles from "./index.styles";

const HowToPlayBuildingBlocks = (props) => {
    const { setIsGamePage } = props;
    const isMobile = useSelector(state => state?.common?.isMobile);
    const styles = useStyles(isMobile);
    const overlayRef = useRef();
    const progressBarRef = useRef();
    const qstnBoxRef = useRef();
    const qstnBoxBg = useRef();
    const lifeTimeRef = useRef();
    const displayQstnRef = useRef();
    const headerQstnBox = useRef();
    const answerRef = useRef();
    const buildingImageRef = useRef();
    const dataContainerRef = useRef();
    const buttonContainerRef = useRef();
    const mainOptionRef = useRef();
    const trueBtn = useRef();

    const [instNo, setInstNo] = useState(0);

    useEffect(() => {
        nextInstruction();
    }, [instNo]);

    const handleRestartClick = () => {
        setInstNo(0);
    }


    const nextInstruction = () => {
        console.log("instruction:", instNo);
        if (instNo === 0) {
            overlayRef.current.style.display = "block";
            dataContainerRef.current.style.display = "none";
            trueBtn.current.style.display = "block"
        }
        else if (instNo === 1) {
            overlayRef.current.style.display = "none";
            dataContainerRef.current.style.display = "block";
        }
        else if (instNo === 2) {
            overlayRef.current.style.display = "none";
            dataContainerRef.current.style.display = "none";
            headerQstnBox.current.style.display = "block";
        }
        else if (instNo === 3) {
            overlayRef.current.style.display = "none";
            headerQstnBox.current.style.display = "block";
            mainOptionRef.current.style.display = "block";
            buildingImageRef.current.style.display = "none";
            buttonContainerRef.current.style.height = "80%";
            trueBtn.current.style.display = "block"
        }
        else if (instNo === 4) {
            buildingImageRef.current.style.transition = "display 0.5s ease-in-out"
            buildingImageRef.current.style.display = "block";
            buttonContainerRef.current.style.height = "40%";
            trueBtn.current.style.display = "none"
            setTimeout(() => {
                setInstNo(5);
            }, [900])
        }
        else if (instNo === 5) {
            headerQstnBox.current.style.display = "none";
            mainOptionRef.current.style.display = "none";
            dataContainerRef.current.style.display = "block";
        }
    }
    return (
        <div className={styles.howToPlayroot}>
            <div className={styles.container}>
                <div className={styles.overlay} ref={overlayRef} id="overlay" onClick={() => {
                    setInstNo(3);
                }}>
                    <div className={styles.progressBarContainer} ref={progressBarRef}>
                        <div className={styles.progressBar} />
                    </div>
                    <div className={styles.arrow2Box}>
                        <img src="/images/gba/arrow2.png" className={`disableSelection disablePointerEvents ${styles.arrowImg2}`} alt="arrow" width={50} />
                    </div>
                    <div className={styles.completionText}>
                        Track completion with the progress bar
                    </div>

                </div>

                <div className={styles.dataContainer} ref={dataContainerRef} >
                    <div className={`${styles.centerFlex} ${styles.readyText}`}>READY TO PLAY?</div>
                    <div className={`${styles.centerFlex} ${styles.cursorPointer}`}
                    >
                        <button className={styles.startBtn} id={`startGameBtn`} onClick={() => { setIsGamePage(true) }} style={{ margin: '4rem 0rem', cursor: 'pointer' }}>START GAME</button>
                    </div>
                    <div className={`${styles.centerFlex} ${styles.restartText}`} onClick={handleRestartClick}>
                        RESTART INSTRUCTIONS
                    </div>
                </div>

                <div className={styles.headerQstnBox} ref={headerQstnBox}>
                    <div className={styles.progressBarContainer} ref={progressBarRef}>
                        <div className={styles.progressBar} />
                    </div>

                    <div className={styles.qstnContainer}>
                        <div className={styles.qstnBox}>
                            <div className={styles.lifeTime} ref={lifeTimeRef}>
                                <div className={styles.centerFlex}>
                                    <div className={styles.assetsText}> 4 x </div>
                                    <img src='/images/icons/heart.png' className={`disableSelection disablePointerEvents ${styles.icon}`} alt="heart" width="10" />
                                </div>
                                <div className={styles.centerFlex}>
                                    <img src='/images/icons/stopwatch.png' className={`disableSelection disablePointerEvents ${styles.icon}`} alt="heart" width="10" />
                                    <div className={styles.assetsText}>180 s </div>
                                </div>
                            </div>
                            <div className={`${styles.qstn} ${styles.centerFlex}`}
                                ref={displayQstnRef}>
                                Questions will appear within this box
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ display: "block" }} ref={mainOptionRef}>
                    <img src="/images/gba/Building_Blocks_space4.svg" style={{ width: "95%", display: "none", cursor: "pointer", transition: "0.5s ease-in-out" }} ref={buildingImageRef} onClick={() => setInstNo(5)} className={`disableSelection disablePointerEvents`} />
                    <div style={{ display: "flex", flexDirection: "row", height: "80%", justifyContent: "space-around", alignItems: "flex-end" }} ref={buttonContainerRef}>
                        <button style={{ width: "40%", border: "none", background: "#FFF", color: "#1A3154", fontWeight: "bolder", minHeight: "3rem", fontSize: "1.5rem", cursor: "pointer" }} onClick={() => setInstNo(4)}>TRUE</button>
                        <button style={{ width: "40%", border: "none", background: "#FFF", color: "#1A3154", fontWeight: "bolder", minHeight: "3rem", fontSize: "1.5rem", cursor: "pointer" }}>FALSE</button>
                    </div>
                    <img src="/images/gba/hand.png" ref={trueBtn} className={`disableSelection disablePointerEvents ${styles.handImg}`} onClick={() => setInstNo(4)} style={{ top: "80%", left: "15%", display: "none" }} />
                </div>
            </div>
        </div>
    )
}

export default HowToPlayBuildingBlocks