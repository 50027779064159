import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    innerContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        // background: theme.palette.voilet,
        // color: theme.palette.fontColor,
        background: 'white',
        color: theme.black,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
    },
    banner: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // background: 'url(https://d27yeeml4scius.cloudfront.net/apiv3media/Images/microskills/IFL-Default.png), linear-gradient(rgb(0,0,0,.7), rgb(0,0,0,0.7))',
        backgroundBlendMode: 'multiply',
        padding: '1rem',
    },
    backContainer: {
        height: '100%',
        width: '100%',
        cursor: 'pointer',
        justifyContent: 'end'
    },
    backButton: {
        height: '2.5rem',
        width: '2.5rem',
        borderRadius: '50%',
        background: '#F4511E',
    },
    winScreenLottieContainer: {

    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
        }
    },
    staticTitle: {
        fontSize: '1.5rem',
        fontWeight: '400',
        marginTop: '1rem',
        color: palette.black,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.4rem',
        }
    },
    microskillName: {
        fontSize: '1.5rem',
        color: palette.black,
        textAlign: 'center',
        fontWeight: '700',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.4rem',
            margin: '0.5rem 0 2rem',
        }
    },
    ratingStar: {
        '& .MuiRating-IconFilled': {
            color: 'yellow'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '2rem !important',
        }
    },
    tellMoreContainer: {
        height: '95%',
        margin: '1rem 0',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '1rem',
            height: '100%',
            margin: '0',

        }
    },
    tellUsMoreText: {
        height: '10%',
        fontSize: '1rem',
        fontWeight: '600',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem'
        }
    },
    tellUsMoreOptionContainer: {
        height: '90%',
        width: '100%',
        textAlign: 'center'
    },
    tellOptionContainer: {
        width: '100%',
        height: '100%',
        columnGap: '0.7rem',
        rowGap: '0.5rem',
        flexWrap: 'wrap'
    },
    tellOption: {
        width: '45%',
        height: '30%',
        columnGap: '1rem',
        borderRadius: '5px',
        background: theme.palette.lightVoilet1ToLightGrey1,
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('sm')]: {
            width: '47%',
        }
    },
    tellOptionImageContainer: {
        width: '25%'
    },
    tellOptionImage: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '60%',
        }

    },
    tellOptionText: {
        fontSize: (i18n) => {
            const largeTextLangsList = ['ta', 'te', 'ml', 'kn', 'bn']
            return largeTextLangsList.includes(i18n.language) ? '0.6rem' : '0.7rem'
        },
        width: '65%',
        height: '100%',
        textAlign: 'left',
        wordBreak: 'break-word',
        [theme.breakpoints.down('sm')]: {
            fontSize: (i18n) => {
                const largeTextLangsList = ['ta', 'te', 'ml', 'kn', 'bn']
                return largeTextLangsList.includes(i18n.language) ? '0.5rem' : '0.6rem'
            }
        }
    },
    StarIcon: {
        fontSize: '1.5rem'
    },
    additionalCommentContainer: {
        textAlign: 'center',
        height: 'fitContent',
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'center',
        outline: 'none',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0.5rem',
        },
    },
    additionalComment: {
        resize: 'none',
        fontSize: '1rem',
        padding: '1rem',
        fontFamily: 'Montserrat,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif',
        borderRadius: '5px',
        maxHeight: '80%',
        minHeight: '80%',
        // background: '#D3D3D3',
        background: '#F0F0F0',
        textAlign: 'start',
        justifyContent: 'center',
        // textAlign: 'center',
        // opacity: '0.5',
        border: 'none',
        outline: 'none',
        minWidth: '100%',
        maxWidth: '100%',
        [theme.breakpoints.up('sm')]: {
            minWidth: '90%',
            maxWidth: '100%',
        },
        "&:focus": {
            outline: `2px solid ${theme.palette.orange} !important`
        }
    },
    submitFeedbackContainer: {
        textAlign: 'center',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        transition: 'height 0.3s ease-in-out, transform 0.3s ease-in-out',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    loadingBtn: {
        minWidth: '90%',
        maxWidth: '100%',
        padding: '0.7rem 0 0.5rem',
        fontSize: '1rem',
        fontWeight: 700,
        borderRadius: '5px',
        border: '0',
        fontFamily: 'Montserrat',
        lineHeight: '1.75',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    mainContainer: {
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '1rem',
        overflow: 'hidden'
    },
    rateExperience: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 0.5rem',
        gap: '1rem'
    },
    rateExperienceText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.3rem',
        fontWeight: '600',
        marginBottom: '1rem',
    },
    rating: {
        marginTop: '2.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '4rem',
        minWidth: '4rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem',
            marginTop: '2rem',
        },
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gap: '0.5em'
    },

    cardWrapper: {
        position: 'relative',
        background: palette.white,
        transition: 'transform 0.3s ease-in-out',
        zIndex: '5',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'transparent',
        borderRadius: '0.5rem',
        boxShadow: '0 0 0.4rem 0.3rem #d3d3d3',
        [theme.breakpoints.down('sm')]: {
            boxShadow: '0 0 0.2rem 0.1rem #d3d3d3',
        }
    },

    cardContainer: {
        display: 'grid',
        gridTemplateRows: '60% 40%',
        borderRadius: '0.5rem',
        textAlign: 'center',
        background: palette.white,

        [theme.breakpoints.down('sm')]: {
            gridTemplateRows: '65% 35%',
            height: '6rem',
        },
    },

    lottieContainer: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        zIndex: '-1',
        background: 'transparent',
        transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
        opacity: '0',
        visibility: 'hidden',
        bottom: '50%',
        left: '50%',
        transform: 'translate(-50%, 0)',
    },

    emojiContainer: {
        height: '100%',
        width: '100%',
        padding: '0.2em',
        fontSize: '2.5em',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {
            fontSize: '1.8em',
        },
    },

    emojiName: {
        height: '100%',
        width: '100%',
        paddingLeft: '0.2em',
        paddingRight: '0.2em',
        fontSize: '0.7em',
        fontWeight: '600',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            fontWeight: '800',
        }
    },
    congratsText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '1.2rem',
        paddingBottom: '3rem',
        fontWeight: '500'
    },
}));

export default useStyles;