import { makeStyles } from "@mui/styles";
import constants from "../../constants";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    policyPage: {
        overflowY: 'hidden',
        width: '95vw',
        height: '90vh',
        boxShadow: `3px 3px 3px 3px theme.palette.white`,
        border: 'none',
        background: theme.palette.white,
    },
    staticPolicyPage: {
        width: '100%',
        backgroundColor: theme.palette.white,
        overflow: 'hidden',
        height: `calc(100dvh - ${constants.HEADER_HEIGHT})`,
        [theme.breakpoints.down('sm')]: {
            height: `calc(100dvh - ${constants.HEADER_HEIGHT_MOBILE})`,
        }
    },
    policyBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        flexDirection: 'column',
        border: 'none',
        background: theme.palette.white,
        borderRadius: '0.8rem',
        zIndex: '9999999999999999',
        height: '95%',

        [theme.breakpoints.up('lg')]: {
            width: '30%',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            width: '60%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '95%',
        },
    },

    policyBoxHeading: {
        fontWeight: '800',
        fontSize: 'x-large',
        padding: '1rem 0 1rem 1rem'
    },

    updatePolicy: {
        gap: '0.5em',
        padding: '0.5em',
    },

    btnContainer: {
        display: 'flex',
        alignItems: 'normal',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '0.3rem',
        width: '90%',
        marginTop: '0.8rem',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            marginTop: '1rem'
        },
    },

    btn: {
        width: '100%'
    },
    loadingButton: {
        width: '100%',
        padding: '1rem',
    }

}))

export default useStyles;

