import React, { useEffect, useState } from "react";
import moment from "moment";
import { t } from "i18next";
import Lottie from "react-lottie-player";


// MUI
import { Button, CircularProgress } from "@mui/material";

// Utils
import isEmpty from "../../../utils/isEmpty";
import commonUtils from "../../../utils/commonUtil";
import palette from "../../../theme/palette";
import utilityFunctions from "../../../utils/utilityFunctions";



// Styles
import useStyles from "./index.styles";

// lotties
import formSubmissionLottieData from "../../../lottie-assets/Form_Submission_lottie.json";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";


const FormsAssesmentWinScreen = (props) => {

    const { name, formData, attemptData, handleCloseForm, isChallenge, showResult, score, maxScore, challengeId, handleNextButtonClickOnForm } = props;
    const { t, i18n } = useTranslation()
    const classes = useStyles(i18n);

    const { microskillId } = useParams();
    const navigate = useNavigate();



    const [loading, setLoading] = useState(false);
    const { getChallengeListData, handleChallengeListCardClick } = utilityFunctions;

    const [isChallengeDataLoading, setIsChallengeDataLoading] = useState(false);
    const [showCertificate, setShowCertificate] = useState(false);
    const [nextChallengeData, setNextChallengeData] = useState({});
    const [showNextChallengeBtn, setShowNextChallengeBtn] = useState(false);


    // let [contextWiseAccuracy, setContextWiseAccuracy] = React.useState({});
    // let totalAccuracy = useRef(0);
    // let totalScore = useRef(0);
    // let totalCorrectScore = useRef(0);



    // useEffect(() => {
    //     // calculate context wise accuracy
    //     if (!isEmpty(formData) && !isEmpty(attemptData) && isEmpty(contextWiseAccuracy)) {

    //         let contextWiseAccuracy = {};
    //         formData.contexts.forEach(context => {

    //             let correctAnswerCount = 0;

    //             context.questions.forEach(question => {
    //                 let correctCount = question.answers.filter(answer => answer.isCorrect === true).length;
    //                 correctAnswerCount += correctCount;
    //             })


    //             let contextAttemptData = attemptData.filter(data => data.contextId === context.contextId);
    //             let correctAttemptedAnswerCount = 0;
    //             contextAttemptData.forEach(data => {
    //                 let countOfCorrectAns = data.answers.filter(answer => answer.isCorrect === true).length;
    //                 correctAttemptedAnswerCount += countOfCorrectAns;
    //             });

    //             totalScore.current += correctAttemptedAnswerCount;
    //             totalCorrectScore.current += correctAnswerCount;

    //             let accuracy = (correctAttemptedAnswerCount / correctAnswerCount) * 100;
    //             contextWiseAccuracy[context.contextId] = accuracy;
    //         });

    //         setContextWiseAccuracy(contextWiseAccuracy);

    //         totalAccuracy.current = (totalScore.current / totalCorrectScore.current) * 100;
    //     }

    //     if (!isEmpty(contextWiseAccuracy)) {
    //         setLoading(false);
    //     }

    // }, [formData, attemptData, contextWiseAccuracy]);

    useEffect(() => {
        if (!isEmpty(formData) && !isEmpty(attemptData)) {
            setLoading(false);
        }
    }, [formData, attemptData]);

    useEffect(() => {
        setIsChallengeDataLoading(true);
        getChallengeListData(microskillId,
            challengeId,
            setNextChallengeData,
            setShowNextChallengeBtn,
            setShowCertificate);
        setIsChallengeDataLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [microskillId]);

    const ActionButton = ({ variant = "contained", color = "gbabtn", bg = palette.yellow, btnText, handleBtnClick }) => {

        return (
            <Button variant='contained'
                fullWidth
                size="small"
                color="gbabtn"
                className={`fixed_ratio_20_3 p-0 ${classes.actionButton} ${classes.centerFlex}`}
                sx={{
                    fontSize: i18n.language === 'en' ? "2em !important" : "2em !important",
                    fontWeight: '500',
                    backgroundColor: bg,
                    color: { color },
                }}
                onClick={handleBtnClick} >
                {t(btnText)}
            </Button>
        );
    };


    const handleNextBtnClick = () => {
        handleNextButtonClickOnForm();
        handleChallengeListCardClick(nextChallengeData, navigate, microskillId);
    };

    const handleChallengeListClick = () => {
        handleCloseForm();
        navigate(`/challenge-list/${microskillId}`);
    };


    const handleCertificateClick = () => {
        if (!showCertificate) return;
        navigate(`/challenge-list/${microskillId}/certificate`);
    }



    return (
        <>
            {
                loading ?
                    (<div className={`${classes.centerFlex} ${classes.loadingForm}`}>
                        <CircularProgress />
                    </div>) :
                    (
                        <div className={classes.mainContainer}>
                            <div className={classes.infoContainer} style={{
                                width: () => {
                                    return isChallenge ? "95%" : "100%";
                                }
                            }}>
                                <div className={`${classes.closeIconContainer} ${classes.centerFlex}`}>
                                    {!isChallenge && <img src='/images/icons/form-close-icon.svg' alt="form-close" className={classes.closeIcon} onClick={() => handleCloseForm()} />}
                                </div>

                                <div className={classes.formName} style={{
                                    padding: () => {
                                        return isChallenge ? '0' : '1rem';
                                    }
                                }}>{name}</div>

                                <div className={`${classes.thanksContainer} ${classes.centerFlex}`} style={{
                                    padding: () => {
                                        return isChallenge ? '0' : '1rem';
                                    }
                                }}>
                                    {/* <div>
                                        <img src='/images/gba/tasty-truth/green-tick.png' alt="form-close" className={classes.imgIcon} />
                                    </div> */}
                                    <div className={classes.centerFlex}>
                                        <Lottie
                                            animationData={formSubmissionLottieData}
                                            loop={false}
                                            play={true}
                                            // style={{ width: '40%' }}
                                            className={classes.tickLottie}
                                        />
                                    </div>
                                    <div className={classes.thanksText}>
                                        <div className={classes.greetingText}>THANK YOU</div>
                                        <div className={classes.usernameText}>{
                                            commonUtils.getLoginUserName()
                                        }</div>
                                    </div>
                                </div>
                                <div className={classes.msgText}>
                                    You have successfully submitted this form on &nbsp;
                                    <br />
                                    <span className={classes.dateText}>{moment(new Date()).format("DD-MM-YYYY HH:mm A")}</span>
                                </div>
                            </div>


                            <div className={`${classes.container} ${classes.scoringContainer}`}>
                                {showResult && (
                                    <div className={classes.sectionsWrapper}>
                                        {/* {Object.keys(contextWiseAccuracy).map((contextId, index) => {
                                            return (
                                                <div key={index} className={classes.container}>
                                                    <div className={classes.sectionText}>Section {index + 1}:</div>
                                                    <div className={classes.sectionValue}>{contextWiseAccuracy[contextId]}%</div>
                                                </div>
                                            );
                                        })} */}
                                    </div>
                                )}
                                {showResult && (
                                    <div className={`${classes.centerFlex} ${classes.totalScoreContainer}`}>
                                        <div className={classes.scoreText}>{t('TOTAL SCORE')}:</div>
                                        <div className={classes.scoreValue}>{score} / {maxScore}</div>
                                    </div>
                                )}
                            </div>

                            {isChallenge && (
                                <div className={`${classes.centerFlex} ${classes.buttonContainer}`}>

                                    <div className={`${classes.nextButtonContainer}`}>
                                        {showNextChallengeBtn && (<ActionButton btnText="NEXT CHALLENGE" handleBtnClick={handleNextBtnClick} />)}

                                        {showCertificate && (<ActionButton btnText="SHOW MY CERTIFICATE" handleBtnClick={handleCertificateClick} />)}

                                        <Button
                                            variant={!showNextChallengeBtn && !showCertificate ? 'contained' : 'text'}
                                            fullWidth
                                            size="small"
                                            color="gbabtn"
                                            className={`fixed_ratio_20_3 p-0 ${classes.actionButton} ${classes.centerFlex}`}
                                            sx={{
                                                fontSize: i18n.language === 'en' ? "2em !important" : "2em !important",
                                                fontWeight: '500',
                                                color: 'black',
                                                fontFamily: 'MotionControl',

                                            }}
                                            onClick={handleChallengeListClick} >
                                            {t("CHALLENGE LIST")}
                                        </Button>

                                    </div>
                                </div>
                            )}
                            {
                                !isChallenge && (
                                    <div className={`${classes.centerFlex} ${classes.buttonContainer}`}>
                                        <Button
                                            variant="contained"
                                            className={classes.exitButton}
                                            onClick={() => handleCloseForm()}
                                        >Exit</Button>
                                    </div>
                                )
                            }
                        </div>
                    )
            }
        </>
    )
}

export default FormsAssesmentWinScreen;