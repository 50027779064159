import React, { useState } from "react";
import { useDispatch } from "react-redux";

// mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

// components

// apis

// utils
import isEmpty from "../../utils/isEmpty"

// styles
import useStyles from "./index.styles"
import { useTranslation } from "react-i18next";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100vw',
    height: '100vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
};

const LoadingMicroskill = (props) => {
    const {
        isOpen = true,
        handleClose,
        loadingText = true,
    } = props;
    // console.log("props:", props)
    const classes = useStyles();

    const { t } = useTranslation()

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            className={classes.loadingMicroskillRoot}
        >
            <Box sx={style} className={classes.modalBox}>
                <div className={classes.modalBoxContainer} style={{ gridTemplateRows: !loadingText ? '100%' : null }}>
                    <div className={classes.modalContainer}>
                        <div className={classes.loaderContainer}>
                            <img src="/images/common/master_o_logo_outer_1.svg" className={classes.logoOuter} />
                            <img src="/images/common/master_o_logo_inner_1.svg" className={classes.logoInner} />
                            {/* <img src="/images/common/master_o_logo_outer.png" className={classes.logoOuter} />
                            <img src="/images/common/master_o_logo_inner.png" className={classes.logoInner} /> */}
                        </div>
                    </div>
                    <div className={`${classes.textLogoContainer} ${classes.centerFlex}`}>
                        <img src="/images/common/mastero_text_dark.svg" className={classes.textBasedLogo} />
                    </div>
                    {
                        loadingText && (
                            <>
                                <div className={classes.loadingText}>
                                    {t("Loading Your Microskills")}®
                                </div>
                                <div className={classes.subTextContainer}>
                                    <div className={classes.subText1}>{t("Taking too long")}?</div>
                                    {/* <div className={classes.subText2}>{t("Tap here for support")}</div> */}
                                </div>
                            </>
                        )
                    }
                </div>
            </Box>
        </Modal>
    )
}

export default LoadingMicroskill;