import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
    howToPlayroot: {
        color: "#fff",
        boxSizing: "border-box",
        backgroundImage: "linear-gradient(#4577AC, #173A60)",
        position: "relative",
        transition: "0.7s ease-in-out",
        height: '100%'
    },
    headerBox: {
        height: "11%",
        display: "grid",
        gridTemplateColumns: "1fr 6fr",
        alignItems: "center",
        justifyItems: "left",
        position: "relative",
        zIndex: "2",
    },
    backBtn: {
        height: "24px",
        paddingLeft: "20px",
        cursor: "pointer"
    },
    container: {
        display: "grid",
        height: "100%",
        gridAutoRows: "27% 73%",
        overflow: 'hidden',
        touchAction: 'none',
        background: '#000000d4'
    },
    overlay: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        background: '#000',
        zIndex: '2',
        opacity: '1',
    },
    dataContainer: {
        transition: '.8s',
        display: 'none',
        opacity: '1',
        height: '60%',
        marginTop: '50%',
        transition: '0.5s ease-in-out',
    },
    startBtn: {
        width: '85%',
        fontFamily: 'MotionControl',
        fontSize: '2rem',
        backgroundColor: '#F5D03C',
        padding: '0.5rem 1rem',
        border: 'none',
        borderRadius: '5px',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '2.5rem'
        },
    },
    headerQstnBox: {
        position: 'relative',
        transition: "0.5s ease-in-out"

    },
    progressBarContainer: {
        position: "relative",
        height: (isMobile) => {
            return isMobile ? "4px" : "9px";
        },
        backgroundColor: '#fff',
        zIndex: "2",
        width: (isMobile) => {
            return `calc(100% - ${isMobile ? '0px' : '6px'})`
        },
        borderRadius: (isMobile) => {
            return `${isMobile ? '0px' : '8px'}`
        },
        margin: 'auto',
        overflow: 'hidden',
    },
    progressBar: {
        position: "absolute",
        top: "0",
        left: "0",
        height: (isMobile) => {
            return isMobile ? "4px" : "9px";
        },
        backgroundColor: "#ffd042",
        width: '0',
        transition: '.4s',
        width: '35%'
    },
    qstnContainer: {
        height: "53%",
        margin: "10px 20px 0 20px",
        position: 'relative',
    },
    qstnBox: {
        backgroundColor: "#000",
        borderRadius: "10px",
        height: "100%",
        fontSize: "16px",
        display: 'grid',
        gridAutoRows: '40% 60%',
        alignItems: 'center',
        boxShadow: '0px 0px 12px 0px #FFFFFF',
    },
    qstn: {
        animation: "$blink 1s ease 0s infinite normal forwards running",
        height: '100%',
        alignItems: 'baseline !important',
        fontFamily: 'open sans',
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    "@keyframes blink": {
        "0%": { opacity: "0" },
        "30%": { opacity: "0" },
        "100%": { opacity: "1" }
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    lifeTime: {
        //opacity: '0',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 2rem',
        fontSize: '.8rem',
        animation: "$blink 1s ease 1s infinite normal forwards running",
        height: '100%',
    },
    icon: {
        margin: '0 5px',
        [theme.breakpoints.down("sm")]: {
            // mobile
            width: '11px',
        },
    },
    progressText: {
        marginTop: '1vh',
        display: 'grid',
        justifyContent: 'center',
        fontSize: '.8rem',
        display: 'none'
    },
    arrow2Box: {
        textAlign: 'center',
    },
    arrowImg2: {
        width: '40%'
    },
    readyText: {
        fontFamily: "Montserrat",
        fontSize: '2rem',
        fontWeight: '900',
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: 'calc(3vw * 2.5)',
        },
    },
    restartText: {
        fontFamily: "MotionControl",
        fontSize: '2rem',
        cursor: 'pointer',
    },
    assetsText: {
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.2rem',
        },
    },
    completionText: {
        fontWeight: 900,
        fontSize: '1rem',
        fontFamily: 'Montserrat',
        width: '98%',
        margin: 'auto',
        textAlign: 'center',
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            // mobile
            fontSize: '1.2rem',
        },
    },
    assestContainer: {
        height: '100%',
        display: 'grid',
        gridAutoRows: '50% 50%',
    },
    assets1: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        '& > img': {
            width: '40%',
        }
    },
    assetsBoxImg: {
        width: '100%',
    },
    handImg: {
        width: '15%',
        position: 'absolute',
        background: "rgb(0, 0, 0, 0.5)",
        // transform: 'translateX(-50%)',
        animation: '$animateHand 1s ease 1s infinite normal forwards running',
    },
    '@keyframes animateHand': {
        '0%': { transform: 'translateY(0px)' },
        '25%': { transform: 'translateY(10px)' },
        '50%': { transform: 'translateY(12px)' },
        '75%': { transform: 'translateY(10px)' },
        '100%': { transform: 'translateY(0px)' },
    },
    assets2: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'flex-end',
    },
    assets1Box: {
        position: 'relative',
        width: '48%',
        padding: '0 0.5rem'
    },
    assets2Box: {
        width: '48%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
        padding: '0 0.5rem'
    },
    assetText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize: '14px',
        width: '100%',
        padding: '1.5rem',
        textAlign: 'center'
    },
    speedBarContainer: {
        opacity: '0',
        zIndex: '4',
        gridAutoRows: '55% 35% 10%',
        transition: "0.5s ease-in-out",
    },
    speedText: {
        display: 'flex',
        alignItems: 'flex-end',
        padding: '1rem 2rem',
        justifyContent: 'center',
        fontSize: '1.2rem',
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    speedArrow: {
        textAlign: 'center',
        padding: '1rem 0',
        '& > img': {
            height: '100%',
            width: '100%',
            objectFit: 'contain'
        }
    },
    speedBarBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#370674',
        '& > img': {
            width: '60%'
        }
    },
    correctLottie: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%'
    },
    GameConatiner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
        position: 'relative',
        height: '100%',
        opacity: '0',
        transition: "0.5s ease-in-out"
    },
    ArrowImage: {
        width: "30%",
        textAlign: 'center'
    },
    CarImage: {
        position: 'absolute',
        zIndex: '2',
        width: '32%'
    }
}));

export default useStyle;
