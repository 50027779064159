/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

// styles
import useStyles from "./index.styles";

const IframeComponent = ({ src, height }) => {

    const classes = useStyles();
    const heightStyle = height ? height : '100%';

    return (
        <div className={classes.iframeBox} style={{ height: heightStyle }}>
            <iframe
                // title="Embedded Website"
                // src={'https://www.nutrabay.com?enablejsapi=1'}
                src={src}
                width={'100%'}
                height={'100%'}
                frameBorder="0"
            />
        </div>
    );
};

export default IframeComponent;
