import axiosInstance from "../../utils/axios";
// import { getAllModules } from "./learn.actions";

export const saveSpotlightData = async (req = {}) => {
    try {
        const respData = await axiosInstance.post(`/spotlight-attempt`, req);
        return respData;
    } catch (err) {
        console.log("saveSpotlightData:", err);
        return err.response;
        // dispatch(fetchFail(err));
    }
}
export const saveSpotlightVideo = async (req = {}) => {
    try {
        const respData = await axiosInstance.post(`/upload-spotlight-video`, req);
        return respData;
    } catch (err) {
        console.log("saveSpotlightVideo:", err);
        return err.response;
        // dispatch(fetchFail(err));
    }
}
export const getSpotlightResult = async (id) => {
    try {
        const respData = await axiosInstance.get(`/spotlight-result/${id}`);
        return respData;
    } catch (err) {
        console.log("getSpotlightResult:", err);
        return err.response;
        // dispatch(fetchFail(err));
    }
}
export const getSpotlightFinalResponse = async (id) => {
    try {
        const respData = await axiosInstance.get(`/final-spotlight-result/${id}`);
        return respData;
    } catch (err) {
        console.log("getSpotlightFinalResponse:", err);
        return err.response;
        // dispatch(fetchFail(err));
    }
}
export const submitSpotlight = async (id) => {
    try {
        const respData = await axiosInstance.get(`/mark-spotlight-submit/${id}`);
        return respData;
    } catch (err) {
        console.log("submitSpotlight:", err);
        return err.response;
        // dispatch(fetchFail(err));
    }
}
export const getSpotlightResultCompleted = async (payload) => {
    try {
        const respData = await axiosInstance.post('/spotlight-result', payload);
        return respData;
    }
    catch (err) {
        console.log('get spotlight Result', err);
        return err.response;
    }
}


// Kundan
export const getTopVideoForSpotlight = async (payload) => {
    try {
        const respData = await axiosInstance.post('/performance/get-top-spotlights', payload);
        return respData;
    }
    catch (err) {
        console.log('get top videos Result', err);
        return err.response;
    }
}
// Kundan

export const getTranscriptMatch = async (payload) => {
    try {
        const respData = await axiosInstance.post('/spotlight/transcriptCompare', payload);
        return respData;
    }
    catch (err) {
        console.log('getTranscriptMatch', err);
        return err.response;
    }
}