import { makeStyles } from "@mui/styles";
import constants from "../../../constants";
import palette from "../../../theme/palette";

const useStyles = makeStyles((theme) => {
    // console.log({theme});
    return ({
        centerFlex: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        toolbar: {
            color: theme.palette.fontColor,
            display: 'grid !important',
            gridTemplateColumns: '20% 60% 20%',
            // gridTemplateColumns: '20% 60% 20%',
            height: 'inherit',
            alignItems: 'flex-end',
            [theme.breakpoints.up('md')]: {
                paddingRight: '0.75rem !important',
            },
            [theme.breakpoints.down('md')]: {
                gridTemplateColumns: '15% 70% 15%',
                justifyContent: 'space-between',
            },
            // justifyContent: 'space-between'
        },
        accountDetailBox: {
            display: 'flex',
            alignItems: 'center',
            justifySelf: 'flex-end',
            width: '70%',
            justifyContent: 'space-between',
            [theme.breakpoints.up('md')]: {
                paddingLeft: '1rem',
            }
        },
        mobileDrawer: {
            zIndex: '9999 !important',
            '& .MuiDrawer-paper': {
                width: '100% !important',
                backgroundColor: `${theme.palette.voilet} !important`,
                backgroundImage: 'none !important'
            }
        },
        headerBox: {
            // height: getHeaderHeight(),
            display: 'flex'
        },
        appBarRoot: {
            zIndex: '9999',
            // backgroundColor: `transparent !important`,
            // backgroundColor: `pink !important`,
            backgroundColor: `${theme.palette.headerBg} !important`,
            backgroundImage: "none !important",
            height: 'inherit',
            boxShadow: 'none !important',
            [theme.breakpoints.down("sm")]: {
                backgroundColor: `${theme.palette.mobileHeaderBg} !important`,
            }
            // height: getHeaderHeight()
        },
        menuDropdown: {
            '& ul': {
                backgroundColor: `${palette.white} !important`,
            }
        },
        search: {
            backgroundColor: theme.palette.lightVoilet1,
            // backgroundColor: alpha(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: theme.palette.lightVoilet2,
                // backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
        },
        menuText: {
            color: '#000 !important',
            fontSize: '0.9rem !important',
            paddingLeft: '0.8rem'
            // fontFamily: 'Open sans !important'
        },
        dropDownIcon: {
            // paddingRight: '1rem'
        },
        searchBox: {
            position: 'fixed',
            width: '100%',
            minHeight: '2rem',
            minHeight: '44px',
            height: '5%',
            top: '2%',
            padding: '0 1rem',
            zIndex: '2',
            // bottom: '-1rem',
            // top: '50%',
            [theme.breakpoints.down('md')]: {
                left: '0%',
                top: '1%'
            },
            [theme.breakpoints.down('sm')]: {
                left: '0%',
            },

            // transform: 'translate(-50% , -50%)',
            // padding: '1rem 1rem 0.5rem 1rem',
            '& input': {
                width: '100%',
                height: '100%',
                padding: '0 2rem 0 2rem',
                outline: theme.palette.orange,
                borderRadius: '4px',
                '&:focus': {
                    border: `2px solid ${theme.palette.orange}`,
                }
            },
            '& img': {
                opacity: '1',
                position: 'absolute',
                transform: 'translateY(-50%)',
            }
        },
        searchIcon: {
            left: '1.5rem',
            top: '50%',
        },
        closeIcon: {
            right: '1.5rem',
            top: '50%',
        },
        avatarBox: {
            width: '50px',
            height: '50px',
            background: ({ isDarkMode }) => `${theme.palette.fontColor}${isDarkMode ? '4d' : '9b'}`,
            borderRadius: '50%',
            color: theme.palette.white,
            fontWeight: '700',
            fontSize: '1.2rem',
            fontFamily: 'Open sans',
            cursor: 'pointer'
        },
        langListBox: {
            justifyContent: 'space-between !important'
        },

        // category filter css
        filterContainer: {
            width: '100%',
            // minHeight: 100,
            background: theme.palette.voilet,
            position: 'absolute',
            top: '99%',
            left: 0,
            zIndex: 100,
            zIndex: 1,
            // boxShadow: 'inset 0px 12px 12px -10px rgba(0,0,0,0.3)',
            boxShadow: 'rgba(0, 0, 0, 0.15) 0px 4px 5px 0px',
            paddingBottom: '0.5rem',
            [theme.breakpoints.up('sm')]: {
                backgroundColor: theme.palette.lightVoilet1,
                borderRadius: `0 0 4px 4px`,
                '&:hover': {
                    backgroundColor: theme.palette.lightVoilet1,
                },
            },
        },
        filterBtnContainer: {
            paddingTop: '1rem',
            overflow: 'hidden',
            maxHeight: '3rem',
            transition: 'max-height 0.6s 0s ease-in-out',
            marginLeft: 'auto',

            width: `calc( 100% - 5% - ${theme.spacing(1)})`,

            '-webkit-transition': 'max-height 0.6s 0s ease-in-out',
            '-moz-transition': 'max-height 0.6s 0s ease-in-out',
            '-o-transition': 'max-height 0.6s 0s ease-in-out',
            [theme.breakpoints.down('md')]: {
                width: `calc( 100% - 5% - ${theme.spacing(0)})`,
            }

        },
        filterHeading: {
            color: theme.palette.fontColor,
            padding: theme.spacing(1, 1, 0, 1),

            [theme.breakpoints.down('md')]: {
                padding: theme.spacing(1, 1, 0, 0),
            }
        },
        filterBtn: {
            margin: '0 0.5rem 0.5rem 0 !important',
            [theme.breakpoints.down('md')]: {
                "&:hover": {
                    // background: 'inherit !important'
                }
            }
        },
        companyLogoBox: {
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            padding: '0.2em',

        },
        companyLogo: {
            height: '80%',
            width: '50%',
            objectFit: 'contain',
            // mixBlendMode: 'multiply',
            // mixBlendMode: 'revert',
            // filter: `brightness(150%)`,
            // filter: theme.palette.mode === 'light' ? ' brightness(0) invert(1)' : 'none',
        }
    })
})

export default useStyles