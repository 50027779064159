import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import { useSelector } from "react-redux";

// mui
import { CircularProgress } from "@mui/material";
import NavigationIcon from '@mui/icons-material/Navigation';

// components
import ScoreBox from "../score-box";

// redux
// import { getChallengeData } from "../../redux/microskill/microskill.api";

// utils
import isEmpty from "../../../utils/isEmpty";
import commonUtil from "../../../utils/commonUtil";

// constants
import constants from "../../../constants";

// theme
import palette from "../../../theme/palette";

//context
import LearningAidContext from "../../../context/learningAidContext"

// styles
import useStyles from "./index.styles";

const IlaMtrrTwo = (props) => {
    const {
        data,
        prev,
        next,
        attemptedIds,
        ilaId,
        scoreListRef,
        addScore,
        maxScore,
        scoreValueRef,
        scoreRef,
        handleChallengeAttemptData,
        styles,
        CorrectTemplate,
        IncorrectTemplate,
        SkipButton,
        isChallengeSubmitted,
        skipButtonDisplayTime,
        viewFullScreen,
        cardId
    } = props

    const classes = useStyles();
    const isMobile = useSelector(state => state?.common?.isMobile);
    // const { microskillId, challengeId } = useParams();
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeQueIndex, setActiveQueIndex] = useState(0);
    const [viewSkipBtn, setViewSkipBtn] = useState(false);
    const [componentMounted, setComponentMounted] = useState(true);
    const ilaRootRef = useRef();
    const ilaBoxRef = useRef();
    const scoreBoxRef = useRef();
    const isCorrectRef = useRef(null);
    const currentTimeRef = useRef(Date.now());
    const incorrectAttemptsIdsRef = useRef([]);
    const mcqBoxRef = useRef();
    const isDragActive = useRef(true);

    const isDraggingActive = useRef(false)

    const answersContainerRef = useRef();
    const trackPadRef = useRef();
    const centeredTextRef = useRef();

    const navigationRef = useRef();
    const questionTextRef = useRef();
    const optionHighlightedRef = useRef("")
    const { scoreCounterTime } = useContext(LearningAidContext)

    const [correctResp, setCorrectResp] = useState("");
    const [showTemplate, setShowTemplate] = useState(false);

    const [shuffledDataList, setShuffledDataList] = useState([])


    const [angle, setAngle] = useState(45)
    let allOptions = document && document.querySelectorAll(".answerCard")

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));
    const bufferTime = 400;
    const bufferAngle = 20;
    const highlightColor = "#EEF600";
    const quadrants = [0, 90, 180, 270];
    const OPTION_SCALE = 1.1;

    useEffect(() => {
        return () => {
            setComponentMounted(false)
        }
    }, [])

    useEffect(() => {
        if (!isEmpty(data)) {
            let QuestionsArrayCopied = [...data[activeIndex].questions]?.slice(0, 4)
            let shuffledArray = commonUtil.shuffleArray(QuestionsArrayCopied)
            setShuffledDataList(shuffledArray)
        }
    }, [data, activeIndex])

    useEffect(() => {
        if (!isChallengeSubmitted || !componentMounted) return
        setTimeout(() => {
            setViewSkipBtn(true)
        }, skipButtonDisplayTime);
    }, [isChallengeSubmitted]);

    // <=== this is to handle mousedown and touchstart ====>
    function dragStart(e) {
        if (!isDragActive.current) return;

        if (e.type === "touchstart") {
            trackPadRef.current?.addEventListener('touchmove', dragging, false);
        } else {
            trackPadRef.current?.addEventListener('mousemove', dragging, false);
        }
    }

    // <=== this is to handle mousemove and touchmove ====>
    const dragging = useCallback((e) => {
        isDraggingActive.current = true;

        let client_x, client_y;
        if (e.type === "touchmove") {
            client_x = e.touches[0].clientX
            client_y = e.touches[0].clientY
        } else {
            client_x = e.clientX
            client_y = e.clientY
        }

        const trackPadRect = trackPadRef.current?.getBoundingClientRect();
        const trackPadCenterX = trackPadRect.left + trackPadRect.width / 2;
        const trackPadCenterY = trackPadRect.top + trackPadRect.height / 2;

        const angle = Math.atan2(client_y - trackPadCenterY, client_x - trackPadCenterX);

        const accurateAngle = ((angle * 180) / Math.PI) + 90;
        setAngle(accurateAngle);
        focusOption(accurateAngle)

    }, [])

    // <========= dragEnd, that will trigger on mouseup and touchend =======>
    async function dragEnd(e) {
        if (e.type === "touchstart") {
            trackPadRef.current?.removeEventListener("touchmove", dragging, false)
        } else {
            trackPadRef.current?.removeEventListener("mousemove", dragging, false)
        }

        if (!isDraggingActive.current) return;

        isDraggingActive.current = false

        if (optionHighlightedRef.current.includes("option")) {
            isDragActive.current = false

            trackPadRef.current.style.touchAction = 'none'
            let optionNumber = parseInt(optionHighlightedRef.current.split("-")[1])
            setAngle(quadrants[optionNumber])

            // make function call for and answer checking and othe logics
            handleOptionShooted()
        }
    }
    // <========= dragEnd ends here =======>

    //<===== focus the option that is in range of pointer ======>
    function focusOption(angle) {
        let newAngle;
        if (angle < 0 && (angle > -90 && angle < -90 + bufferAngle)) { // make the left option highlighted in range with buffre_angle
            newAngle = 270 + (90 + angle)
        } else {
            newAngle = angle
        }

        for (let i = 0; i < 4; i++) {
            if (newAngle <= quadrants[i] + bufferAngle && newAngle >= quadrants[i] - bufferAngle) {
                optionHighlightedRef.current = `option-${i}`;
                return;
            }
        }

        optionHighlightedRef.current = `out-of-range`;
    }

    // effect to change CSS of focused option
    useEffect(() => {
        if (!isEmpty(optionHighlightedRef.current)) {
            let transformationStyleLarge = [
                'translateX(-50%)',
                'translate(70%, -50%)',
                'translateX(-50%)',
                'translate(-70%, -50%)'
            ]
            let transformationStyleSmall = [
                'translateX(-50%)',
                'translateY(-50%)',
                'translateX(-50%)',
                'translateY(-50%)'
            ]
            if (optionHighlightedRef.current.includes("option")) {

                let optionNumber = parseInt(optionHighlightedRef.current.split("-")[1])
                if (isMobile) {
                    allOptions[optionNumber].style = `
                        transform: ${transformationStyleSmall[optionNumber]} scale(${OPTION_SCALE - 0.05});
                        color: ${highlightColor};
                    `
                } else {
                    allOptions[optionNumber].style = `
                        transform: ${transformationStyleLarge[optionNumber]} scale(${OPTION_SCALE});
                        color: ${highlightColor};
                    `
                }
                navigationRef.current.style = `
                    color: ${highlightColor};
                `
            } else {
                allOptions.forEach((elem, index) => elem.style = ``)
                navigationRef.current.style = ``
            }
        }
    }, [optionHighlightedRef.current])

    // // function to handle what to do after highlighted option shooted
    const handleOptionShooted = async () => {
        const selOptionNum = parseInt(optionHighlightedRef.current.split("-")[1]);

        const item = data[activeIndex].questions[selOptionNum];
        const indexToSplice = attemptedIds.current.indexOf(ilaId);

        if (indexToSplice !== -1) {
            attemptedIds.current.splice(indexToSplice, 1);
        }

        const showScore = data[activeIndex].score > 0;
        const isCorrect = shuffledDataList[activeQueIndex]?.answers.answer.trim().toUpperCase() === item.answers.answer.trim().toUpperCase();

        await sleep(bufferTime * 2);
        // allOptions.forEach(elem => elem.style.visibility = "hidden");
        answersContainerRef.current.style.display = 'none';
        trackPadRef.current.style.visibility = "hidden";

        await sleep(bufferTime * 2)

        if (activeIndex < data.length && activeQueIndex < data[activeIndex].questions.length) {

            let currentTime = Date.now();

            if (!isMobile) {
                centeredTextRef.current.style.width = showScore ? "90%" : "70%";
                centeredTextRef.current.style.transform = 'translate(-50%, -25%)';
                centeredTextRef.current.firstChild.style.width = '70%'
            }
            if (isCorrect) {

                // add to the score
                scoreListRef.current.add(`${item.id}-${data[activeIndex].score}`);

                // set analytics data
                handleChallengeAttemptData({
                    qId: item.id,
                    cardId: cardId,
                    t: Math.round((currentTime - currentTimeRef.current) / 1000),
                    aId: item.answers.id,
                    isCorrect: true
                });
                currentTimeRef.current = currentTime;

                // show score
                if (showScore) {
                    ilaBoxRef.current.style.gridTemplateRows = isMobile ? constants.ILA_GRID_FINAL_MOBILE : constants.ILA_GRID_FINAL;
                    // ilaBoxRef.current.style.gridTemplateRows = constants.ILA_GRID_FINAL;
                    await addScore(data[activeIndex]?.score ? data[activeIndex].score : 0, isCorrect);
                }

                setShowTemplate(true)
                isCorrectRef.current = true;
                setCorrectResp(item.answers.answer);
                centeredTextRef.current.style.background = palette.ilaCorrect1;
                await sleep(bufferTime + scoreCounterTime);
                setActiveQueIndex(prev => prev === shuffledDataList.length - 1 ? prev : prev + 1);
                reset();
            } else {

                // set analytics data
                handleChallengeAttemptData({
                    qId: item.id,
                    cardId: cardId,
                    t: Math.round((currentTime - currentTimeRef.current) / 1000),
                    aId: item.answers.id,
                    isCorrect: false
                });
                currentTimeRef.current = currentTime;

                if (showScore) {
                    ilaBoxRef.current.style.gridTemplateRows = isMobile ? constants.ILA_GRID_FINAL_MOBILE : constants.ILA_GRID_FINAL;
                    await addScore(data[activeIndex]?.score ? data[activeIndex].score : 0, isCorrect);
                }

                setShowTemplate(true)
                isCorrectRef.current = false;

                centeredTextRef.current.style.background = palette.ilaInCorrect1;
                await sleep(bufferTime + scoreCounterTime);

                if (item.mandatory) {
                    setActiveQueIndex(prev => prev);
                    reset();
                    return;
                } else {
                    setActiveQueIndex(prev => prev === shuffledDataList.length - 1 ? prev : prev + 1);
                }
                reset();
            }
            currentTimeRef.current = currentTime;
        }

        // await sleep(2 * bufferTime)
        if (activeIndex < data.length && activeQueIndex === shuffledDataList.length - 1) {
            setActiveIndex(prev => prev === data.length - 1 ? prev : prev + 1);
            setActiveQueIndex(0);
        }
        const indexValue = activeIndex + 1;

        if (indexValue === data.length && activeQueIndex === shuffledDataList.length - 1) {
            attemptedIds.current.push(ilaId);
            setActiveQueIndex(0);
            next();
        }
    };

    // <===== reset all things after a play ======>
    async function reset() {
        isCorrectRef.current = null;
        ilaBoxRef.current.style = null;

        optionHighlightedRef.current = "";
        setCorrectResp("");
        setShowTemplate(false)

        trackPadRef.current.style.touchAction = 'auto';
        trackPadRef.current.style.visibility = "visible";
        trackPadRef.current.style.transition = ""


        centeredTextRef.current.style.width = "";
        centeredTextRef.current.style.background = "#6B48A4";
        centeredTextRef.current.style.transform = "";

        centeredTextRef.current.firstChild.style.width = "";

        navigationRef.current.style.color = "white";
        navigationRef.current.style.visibility = "visible";

        setAngle(45);

        answersContainerRef.current.style.display = '';
        allOptions.forEach(elem => {
            elem.style = ""
        })
        await sleep(bufferTime * 2)
        isDragActive.current = true
        isDraggingActive.current = true

    }
    // <===== reset ends here ===>

    return (
        <>
            {
                isEmpty(data) ? (
                    <div className={`${classes.loaderBox} ${classes.centerFlex}`}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div className={classes.ilaRoot} ref={ilaRootRef}>
                        <div className={classes.ilaBox} ref={ilaBoxRef}>
                            <div className={styles.questionHead}>{`Q${data[activeIndex].questionNo}`}</div>
                            <div className={`${classes.question} ${!showTemplate && classes.centerFlex}`} ref={questionTextRef}>
                                {
                                    data[activeIndex].Context.context
                                }
                            </div>
                            <div className={classes.mcqRootContainer} >
                                {
                                    showTemplate && (
                                        <div className={classes.correctIncorrectTextBox}>
                                            {isCorrectRef.current ? (<CorrectTemplate />) : (<IncorrectTemplate text={data[activeIndex].mandatory ? 'TRY AGAIN!' : 'WRONG!'} />)}
                                            {/* <CorrectTemplate /> */}
                                        </div>
                                    )
                                }
                                <div
                                    className={classes.mcqBox}
                                    ref={mcqBoxRef}
                                    style={viewFullScreen ? { margin: '0 auto', height: '78%' } : {}}
                                // onMouseUp={dragEnd}
                                >
                                    <div className={classes.answersContainer} ref={answersContainerRef}>
                                        {
                                            // shuffledAnswersList?.map((item) => ( 
                                            !isEmpty(data[activeIndex].questions) && data[activeIndex].questions?.slice(0, 4)?.map((item, index) => (
                                                <div
                                                    className={`answerCard ${classes.cardBox} ${classes.centerFlex}`}
                                                    key={item.id}
                                                    onClick={async () => {
                                                        trackPadRef.current.style.transition = "all 0.3s ease"
                                                        setAngle(quadrants[index])
                                                        optionHighlightedRef.current = `option-${index}`
                                                        await sleep(bufferTime * 1)
                                                        handleOptionShooted()
                                                    }}
                                                >
                                                    {
                                                        item.answers.answer
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div
                                        className={`${classes.centeredText} ${classes.centerFlex}`}
                                        ref={centeredTextRef}
                                        style={{ background: '#6B48A4' }}
                                    >
                                        <p>{!isEmpty(shuffledDataList) && shuffledDataList[activeQueIndex].question}{!isEmpty(correctResp) && `: ${correctResp}`}</p>
                                    </div>
                                    <div
                                        /* ////////// disableSelection css is written in app.css, to prevent screen flickering while tapping on trackpad in mobile devices   ///////////  */
                                        className={`disableSelection ${classes.aimContainer}`}
                                        style={{ '--angle': angle + "deg" }}
                                        ref={trackPadRef}
                                        onMouseDown={dragStart}
                                        onMouseUp={dragEnd}
                                        onTouchStart={dragStart}
                                        onTouchEnd={dragEnd}
                                    >
                                        <div className={classes.navigation}
                                            ref={navigationRef}
                                        >
                                            <NavigationIcon
                                                className={classes.navigationIcon}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${(!isMobile && !viewFullScreen) ? classes.centerFlex : ''}`}>
                                <ScoreBox scoreBoxRef={scoreBoxRef} scoreValueRef={scoreValueRef} maxScore={maxScore} score={scoreRef.current} />
                            </div>

                            {
                                viewSkipBtn && (
                                    <div> <SkipButton style={{ paddingRight: '0.5rem' }} /> </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default IlaMtrrTwo;