import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import useStyles from "./index.styles"

const RevealThePictureHTP = (props) => {
    const { setIsGamePage } = props;
    const isMobile = useSelector(state => state?.common?.isMobile);
    let instructionNo = 0;
    const styles = useStyles(isMobile);

    const overlayRef = useRef();
    const progressBarRef = useRef();
    const headerQstnBox = useRef();
    const lifeTimeRef = useRef();
    const displayQstnRef = useRef();
    const optionRef = useRef()
    const optionHandRef = useRef();
    const optionContainerRef = useRef();
    const dataContainerRef = useRef();
    const questionHandRef = useRef();

    const nextInstruction = (instNo) => {
        console.log(instNo);
        instructionNo = instNo;
        if (instNo === 1) {
            overlayRef.current.style.display = "none";
            headerQstnBox.current.style.display = "block"
            headerQstnBox.current.style.opacity = '1'
        }
        if (instNo === 2) {
            optionContainerRef.current.style.display = "block"
            questionHandRef.current.style.opacity = "0"
        }
        if (instNo === 3) {
            optionRef.current.classList.add(`${styles.RevealOptionClick}`)
            optionHandRef.current.style.transition = "opacity 0.5s ease"
            optionHandRef.current.style.opacity = "1"
        }
        if (instNo === 4) {
            optionRef.current.classList.remove(`${styles.RevealOptionClick}`)
            optionRef.current.classList.add(`${styles.RevealOptionCorrect}`)
            optionHandRef.current.style.transition = "opacity 0.5s ease"
            optionHandRef.current.style.opacity = "0"
            optionRef.current.innerHTML = `<img src="/images/gba/reveal-the-picture/correct-ans.svg" style="width:54%; height:100%; transition:0.5s ease"/>`
            setTimeout(() => {
                optionContainerRef.current.style.transition = "0.5s ease"
                optionContainerRef.current.style.display = "none"
                headerQstnBox.current.style.display = "none"
                nextInstruction(5);
            }, [900])
        }
        if (instNo === 5) {
            dataContainerRef.current.style.display = "block"
        }
    }

    const handleRestartClick = () => {
        dataContainerRef.current.style.display = "none";
        overlayRef.current.style.display = "block";
        headerQstnBox.current.style.display = "none"
        optionContainerRef.current.style.display = "none"
        optionRef.current.classList.remove(`${styles.RevealOptionClick}`)
        optionRef.current.classList.remove(`${styles.RevealOptionCorrect}`)
        questionHandRef.current.style.opacity = "1"
        optionRef.current.innerHTML = "Option C"
        optionHandRef.current.style.opacity = "0"
    }

    return (
        <div className={styles.howToPlayroot}>
            <div className={styles.container}>
                {/* Track Your Progres */}
                <div className={styles.overlay} ref={overlayRef} id="overlay">
                    <div className={styles.progressBarContainer} ref={progressBarRef}>
                        <div className={styles.progressBar} />
                    </div>
                    <div className={styles.arrow2Box}>
                        <img src="/images/gba/arrow2.png" className={styles.arrowImg2} alt="arrow" width={50} />
                    </div>
                    <div className={styles.completionText} onClick={() => {
                        nextInstruction(1);
                    }}>
                        Track completion with the progress bar</div>
                    <img src="/images/gba/hand.png" className={styles.handImg} style={{ top: "28%", left: "30%", background: "transparent" }} />
                </div>

                {/* Ready To Play Screen */}
                <div className={styles.dataContainer} ref={dataContainerRef} >
                    <div className={`${styles.centerFlex} ${styles.readyText}`}>READY TO PLAY?</div>
                    <div className={`${styles.centerFlex} ${styles.cursorPointer}`}
                    >
                        <button className={styles.startBtn} id={`startGameBtn`} onClick={() => { setIsGamePage(true) }} style={{ margin: '5rem 0rem', cursor: 'pointer' }}>START GAME</button>
                    </div>
                    <div className={`${styles.centerFlex} ${styles.restartText}`} onClick={handleRestartClick}>
                        RESTART INSTRUCTIONS
                    </div>
                </div>

                {/* Game Screen */}
                <div className={styles.headerQstnBox} ref={headerQstnBox}>
                    <div className={styles.progressBarContainer} ref={progressBarRef}>
                        <div className={styles.progressBar} />
                    </div>

                    <div className={styles.qstnContainer}>
                        <div className={styles.qstnBox}>
                            <div className={styles.lifeTime} ref={lifeTimeRef}>
                                <div className={styles.centerFlex}>
                                    <div className={styles.assetsText}> 4 x </div>
                                    <img src='/images/icons/heart.png' className={styles.icon} alt="heart" width="10" />
                                </div>
                                <div className={styles.centerFlex}>
                                    <img src='/images/icons/stopwatch.png' className={styles.icon} alt="heart" width="10" />
                                    <div className={styles.assetsText}>180s</div>
                                </div>
                            </div>
                            <div className={`${styles.qstn} ${styles.centerFlex}`}
                                ref={displayQstnRef} onClick={() => nextInstruction(2)}>
                                Questions will appear within this box
                            </div>
                        </div>
                        <img src="/images/gba/hand.png" className={styles.handImg} ref={questionHandRef} style={{ position: "absolute", top: "74%", left: "20%", width: "15%", background: "transparent", opacity: "1" }} />
                    </div>
                </div>

                <div className={styles.OptionContainer} ref={optionContainerRef}>
                    <div style={{ height: "80%", display: "flex", width: '90%', flexWrap: 'wrap', margin: "auto", background: "#0A83BB", borderRadius: "1px" }}>
                        <div className={styles.RevealOption} onClick={() => nextInstruction(3)}>Option A</div>
                        <div className={styles.RevealOption} onClick={() => nextInstruction(3)}>Option B</div>
                        <div className={styles.RevealOption} ref={optionRef} onClick={() => nextInstruction(instructionNo < 3 ? 3 : 4)}>Option C</div>
                        <div className={styles.RevealOption} onClick={() => nextInstruction(3)}>Option D</div>
                        <div className={styles.RevealOption} onClick={() => nextInstruction(3)}>Option E</div>
                        <div className={styles.RevealOption} onClick={() => nextInstruction(3)}>Option F</div>
                        <div className={styles.RevealOption} onClick={() => nextInstruction(3)}>Option G</div>
                        <div className={styles.RevealOption} onClick={() => nextInstruction(3)}>Option H</div>
                    </div>
                    <img src="/images/gba/hand.png" className={styles.handImg} ref={optionHandRef} style={{ position: "absolute", top: "51%", left: "20%", width: "15%", background: "transparent", opacity: "0" }} />
                </div>
            </div>
        </div>
    )
}

export default RevealThePictureHTP