import { makeStyles } from "@mui/styles";
import palette from "../../theme/palette";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    loadingMicroskillRoot: {

    },
    modalBox: {
        // backgroundImage: 'linear-gradient(#4F0060, #4F0060)'
        backgroundImage: 'linear-gradient(#4F0060, #080037)'
    },
    modalBoxContainer: {
        backgroundSize: 'contain',
        // backgroundRepeat: 'no-repeat',
        height: '100%',
        backgroundImage: 'url(/images/common/master_o_login_illustration_bg.svg)',
        padding: '2rem',
        display: 'grid',
        color: '#FFF',
        gridTemplateRows: '45% 20% 40%',
        // gridTemplateRows: 'calc(50% + 150px) 1fr 2fr',
        justifyContent: 'center'
    },
    modalContainer: {
        display: 'grid',
        minWidth: '250px',
        // alignContent: 'flex-end'
    },
    loaderContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        // height: '300px'
    },
    logoOuter: {
        position: 'absolute',
        width: '75%',
        // animation: "$rotateOuter 2s linear infinite",
    },
    logoInner: {
        position: 'absolute',
        width: '45%',
    },
    '@keyframes rotateOuter': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' }
    },
    loadingText: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    subTextContainer: {
        textAlign: 'center',
        display: 'grid',
        alignContent: 'center',
        justifyItems: 'center',
        gap: '2rem'
    },
    dot: {
        opacity: 1,
        // animation: `$dots 1.5s infinite steps(1, end)`,
        "&:nth-child(1)": {
            animationDelay: '0s'
        },
        "&:nth-child(2)": {
            animationDelay: '1s',
        },
        "&:nth-child(3)": {
            animationDelay: '2s'
        }
    },
    "@keyframes dots": {
        '0%, 80%, 100%': {
            opacity: 0
        },
        '40%': {
            opacity: 1
        }
    },

    textBasedLogo: {
        width: '75%',
        maxWidth: '300px',
        filter: 'invert(1)',
    }
}))

export default useStyles