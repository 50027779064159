import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";

// mui
import { CircularProgress } from "@mui/material";

// components
import ScoreBox from "../score-box";

// redux

// utils
import isEmpty from "../../../utils/isEmpty";
import commonUtil from "../../../utils/commonUtil";

// constants
import constants from "../../../constants";

// theme
import palette from "../../../theme/palette";

// styles
import useStyles from "./index.styles";

let answerCardCount = 3;
let questionCardCount = 2;
let marksPerAnswer = 8;

const IlaMtrrOne = (props) => {
    const {
        data,
        prev,
        next,
        attemptedIds,
        ilaId,
        scoreListRef,
        addScore,
        maxScore,
        scoreValueRef,
        scoreRef,
        handleChallengeAttemptData,
        styles,
        CorrectTemplate,
        IncorrectTemplate,
        SkipButton,
        isChallengeSubmitted,
        skipButtonDisplayTime,
        cardId
    } = props;
    const [activeQindex, setActiveIndex] = useState(0);
    const isTrueFalse = data[activeQindex].questions.length === 2;
    let topCardCount = isTrueFalse ? 2 : 3;
    let bottomCardCount = isTrueFalse ? 2 : 2;
    const classes = useStyles();
    const isMobile = useSelector(state => state?.common?.isMobile);

    const [showTemplate, setShowTemplate] = useState(false);
    const [viewSkipBtn, setViewSkipBtn] = useState(false);

    const [componentMounted, setComponentMounted] = useState(true);
    const [showCard, setShowCard] = useState(false);

    const ilaRootRef = useRef();
    const ilaBoxRef = useRef();
    const scoreBoxRef = useRef();
    const isCorrectRef = useRef(false);
    const totalScoreRef = useRef(0);
    const isDragActive = useRef(true);
    const dragItemElementRef = useRef(null);
    const dragItemRef = useRef(null);
    const topCardRef = useRef(null);
    const attemptCountRef = useRef(0);
    const topCardArrayRef = useRef();
    const bottomCardArrayRef = useRef();
    const remainingTopCardArrayRef = useRef();
    const prevAnsTimeRef = useRef();
    const allAttempCountRef = useRef(0);
    const currentTimeRef = useRef(Date.now());


    let currentX;
    let currentY;
    let initialX;
    let initialY;
    let xOffset = 0;
    let yOffset = 0;
    let transitionTimeForCorrectAnswer = 500;
    let bufferTime = 300;

    const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

    useEffect(() => {
        return () => {
            setComponentMounted(false)
        }
    }, [])

    useEffect(() => {
        if (!isChallengeSubmitted || !componentMounted) return
        setTimeout(() => {
            setViewSkipBtn(true)
        }, skipButtonDisplayTime);
    }, [isChallengeSubmitted]);

    useEffect(() => {
        (async () => {
            setShowCard(false);
        topCardArrayRef.current = commonUtil.getSlicedArray(answerCardCount, 0, data[activeQindex].questions);
        bottomCardArrayRef.current = commonUtil.getSlicedArray(questionCardCount, 0, topCardArrayRef.current);
        // bottomCardArrayRef.current = commonUtil.getSlicedArray(questionCardCount, 0, commonUtil.shuffleArray(topCardArrayRef.current));
        remainingTopCardArrayRef.current = commonUtil.getSlicedArray(data[activeQindex].questions.length - answerCardCount, answerCardCount, data[activeQindex].questions);
        attemptCountRef.current = 0;

        // console.clear()
        // console.log("topCardArrayRef:", topCardArrayRef.current);
        // console.log("bottomCardArrayRef:", bottomCardArrayRef.current);
        // console.log("remainingTopCardArrayRef:", remainingTopCardArrayRef.current);
        await sleep(100)
        setShowCard(true);
        })()
    }, [data, activeQindex]);

    const getIdFromElement = (el) => {
        return el.getAttribute('id').split('-')[1];
    }

    const dragStart = (e) => {
        if (!isDragActive.current) return
        let targetEl = e?.target?.getAttribute('drag') === 'true' ? e.target : e.target.parentElement;
        if (targetEl.getAttribute('drag') !== 'true') return;
        dragItemElementRef.current = targetEl;

        // if (!isMobile) {
        //     document.addEventListener("mousemove", drag)
        // }
        // if (isMobile) {
        //     document.addEventListener("touchmove", drag)
        // }

        if (e.type === "touchstart") {
            initialX = e.touches[0].clientX - xOffset;
            initialY = e.touches[0].clientY - yOffset;
            document.addEventListener("touchmove", drag)
        } else {
            initialX = e.clientX - xOffset;
            initialY = e.clientY - yOffset;
            document.addEventListener("mousemove", drag)
        }
    }

    const drag = (e) => {
        if (isEmpty(dragItemElementRef.current) || !isDragActive.current) return;

        if (e.type === "touchmove") {
            currentX = e.touches[0].clientX - initialX;
            currentY = e.touches[0].clientY - initialY;
        } else {
            currentX = e.clientX - initialX;
            currentY = e.clientY - initialY;
        }
        xOffset = currentX;
        yOffset = currentY;

        dragItemElementRef.current.style.zIndex = '99'
        setTranslate(currentX, currentY, dragItemElementRef.current);

        let dragItemCardId = getIdFromElement(dragItemElementRef.current);
        let dragItemAnswerObj = bottomCardArrayRef.current.find(d => d.id === dragItemCardId);
        dragItemRef.current = { dragItemAnswerObj, dragItemEl: dragItemElementRef.current }

        // dragItemElementRef.current.querySelector(`#bottom-text-${dragItemCardId}`).style.opacity = 0;
        topCardArrayRef.current.forEach((item, i) => {
            let topCardEl = document.getElementById(`top-${item.id}-${i}`);
            if (isEmpty(dragItemElementRef.current) || isEmpty(topCardEl)) return;
            if (isOverlapping(dragItemElementRef.current, topCardEl)) {
                let topCardId = getIdFromElement(topCardEl);
                let topCardObj = topCardArrayRef.current.find(d => d.id === topCardId);
                if (!isMobile) topCardEl.style.scale = '1.08';
                else {
                    topCardEl.style.scale = '1.05';
                    topCardEl.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 4px 12px';
                }
                topCardRef.current = { topCardObj, topCardEl }
            } else {
                topCardEl.style.scale = '1';
                topCardEl.style.boxShadow = null
            }
        })
    }

    const dragEnd = async (e) => {
        if (!isDragActive.current) return;
        if (!isMobile) {
            document.removeEventListener('mousemove', drag)
            document.removeEventListener("touchmove", drag)
        }
        if (isMobile) {
            document.removeEventListener("touchmove", drag)
        }
        // document.querySelector("#mtrrCardBox").removeEventListener("mouseout", mouseLeaves)

        isDragActive.current = false;
        xOffset = 0;
        yOffset = 0;

        // change the opacity to 1 for the dragged card
        let dragItemCardId = null;
        let dragItemChildEl = null;
        if (!isEmpty(dragItemElementRef.current)) {
            dragItemCardId = dragItemElementRef.current.getAttribute('id').split('-')[1];
            dragItemChildEl = dragItemElementRef.current.querySelector(`#bottom-text-${dragItemCardId}`);
        }

        if (!isEmpty(dragItemChildEl)) {
            dragItemChildEl.style.opacity = 1;
        }

        if (isEmpty(dragItemRef.current) || isEmpty(topCardRef.current)) {
            setTranslate(0, 0, dragItemElementRef.current, true);
            dragItemElementRef.current = null;
            isDragActive.current = true;
            return
        }

        let dragElement = dragItemElementRef.current;
        let topCardElement = topCardRef.current.topCardEl;

        // CHECK FOR CORRECT ANSWER
        if (isOverlapping(dragElement, topCardElement)) {
            let topCardId = getIdFromElement(topCardRef.current.topCardEl);
            let bottomCardId = getIdFromElement(dragItemElementRef.current);

            let topCardIndex = topCardArrayRef.current.findIndex(d => d.id === topCardId);
            let bottomCardIndex = bottomCardArrayRef.current.findIndex(d => d.id === bottomCardId);
            allAttempCountRef.current = allAttempCountRef.current + 1;

            let dragItemAnswerObj = dragItemRef.current?.dragItemAnswerObj;
            let topCardObj = topCardRef.current?.topCardObj;

            // hide top card
            let currentTime = Date.now();
            let isCorrect = dragItemAnswerObj?.answers.answer.trim().toUpperCase() === topCardObj.answers.answer.trim().toUpperCase()

            if (isCorrect) {
                // if (getIdFromElement(dragItemRef.current.dragItemEl) === getIdFromElement(topCardRef.current.topCardEl)) {
                scoreListRef.current.add(`${dragItemAnswerObj.id}-${data[activeQindex].score}`);


                // add to the score
                totalScoreRef.current += marksPerAnswer;

                // set analytics data
                handleChallengeAttemptData({
                    qId: dragItemAnswerObj.id,
                    cardId: cardId,
                    t: Math.round((currentTime - currentTimeRef.current) / 1000),
                    aId: dragItemAnswerObj?.answers?.id,
                    isCorrect: true
                });
                currentTimeRef.current = currentTime;

                prevAnsTimeRef.current = currentTime;
                attemptCountRef.current = attemptCountRef.current + 1;

                // add transition to bottom card
                dragElement.style.transition = transitionTimeForCorrectAnswer + 'ms';
                dragElement.style.backgroundColor = '#49965E';
                dragItemChildEl.style.fontSize = isMobile && '0.7rem';
                topCardRef.current.topCardEl.style.backgroundColor = '#3A7C4C';
                // let bottomCardElementChildEl = dragElement.querySelector(`#bottom-text-${bottomCardId}`);
                let bottomCardResponseEl = dragElement.querySelector(`#response-type-text-${bottomCardId}`);
                bottomCardResponseEl.innerHTML = `<div><div>CORRECT!</div><div style="font-size: ${isMobile ? '0.8rem' : '0.9rem'} ">+10</div></div>`;

                // wait for correct animation and translate to original position
                await sleep(transitionTimeForCorrectAnswer + bufferTime);
                setTranslate(0, 0, dragItemElementRef.current, true);

                // wait for buffer time
                await sleep(2 * bufferTime);
                topCardElement.style.opacity = 0;
                dragItemChildEl.style.fontSize = isMobile && '';

                // hide dragged card
                bottomCardResponseEl.innerHTML = '';
                dragElement.style.opacity = 0;
                dragElement.style.visibility = 'hidden';
                dragElement.style.touchAction = 'none';

                // wait for buffer time and then show new cards
                await sleep(2 * bufferTime);

                dragElement.style.backgroundColor = null;
                topCardRef.current.topCardEl.style.backgroundColor = null;
                if (isTrueFalse) {
                    // update bottom card NEW
                    handleBottomCardUpdateArray(dragElement, bottomCardId, bottomCardIndex, true);
                    topCardElement.style.scale = 1;
                } else {
                    // update top card NEW
                    handleTopCardUpdateArray(topCardIndex, topCardElement, topCardId, true);

                    // update bottom card NEW
                    handleBottomCardUpdateArray(dragElement, bottomCardId, bottomCardIndex, true);
                }
            } else {
                // set analytics data
                prevAnsTimeRef.current = currentTime;

                // set analytics data
                handleChallengeAttemptData({
                    qId: topCardObj.id,
                    cardId: cardId,
                    t: Math.round((currentTime - currentTimeRef.current) / 1000),
                    aId: dragItemAnswerObj?.answers?.id,
                    isCorrect: false
                });
                currentTimeRef.current = currentTime;

                // add transition to bottom card
                dragElement.style.transition = transitionTimeForCorrectAnswer + 'ms';
                dragElement.style.backgroundColor = '#B93333';
                dragItemChildEl.style.fontSize = isMobile && '0.7rem';

                topCardRef.current.topCardEl.style.backgroundColor = '#A82E2E';
                let bottomCardResponseEl = dragElement.querySelector(`#response-type-text-${bottomCardId}`);

                // text change in case of mandatory and non-mandatory
                bottomCardResponseEl.innerHTML = dragItemRef.current.dragItemAnswerObj.mandatory ? 'TRY AGAIN!' : 'WRONG!';

                // wait for incorrect animation and translate to original position
                await sleep(transitionTimeForCorrectAnswer + bufferTime);
                setTranslate(0, 0, dragItemElementRef.current, true);


                /****** NEW Logic changes *******/
                if (dragItemRef.current.dragItemAnswerObj.mandatory) {
                    await sleep(2 * bufferTime);
                    topCardElement.style.opacity = 0;
                    dragElement.style.opacity = 0;
                    dragItemChildEl.style.fontSize = isMobile && '';

                    await sleep(2 * bufferTime);
                    topCardElement.style = null;
                    dragElement.style = null;
                    bottomCardResponseEl.innerHTML = '';
                    isDragActive.current = true;
                    return
                } else {
                    attemptCountRef.current = attemptCountRef.current + 1;
                }
                /****** NEW Logic changes *******/

                // wait for buffer time
                await sleep(2 * bufferTime);
                topCardElement.style.opacity = 0;

                // hide dragged card
                dragElement.style.opacity = 0;
                dragItemChildEl.style.fontSize = isMobile && '';

                // wait for buffer time and then show new cards
                await sleep(2 * bufferTime);

                bottomCardResponseEl.innerHTML = '';
                dragElement.style.backgroundColor = null;
                topCardRef.current.topCardEl.style.backgroundColor = null;
                // topCardRef.current.topCardEl.style.backgroundColor = '#3A7C4C';
                if (isTrueFalse) {
                    dragElement.style.opacity = 1;
                    dragElement.style.visibility = 'visible';
                    dragElement.style.touchAction = 'auto';

                    // NEW
                    topCardElement.style.opacity = 1;

                    // update bottom card NEW
                    handleBottomCardUpdateArray(dragElement, bottomCardId, bottomCardIndex, false);
                    topCardElement.style.scale = 1;
                } else {
                    // update top card NEW
                    handleTopCardUpdateArray(topCardIndex, topCardElement, topCardId, false);

                    // update bottom card NEW
                    handleBottomCardUpdateArray(dragElement, bottomCardId, bottomCardIndex, false);
                }
            }
            dragItemElementRef.current.style.zIndex = ''
            dragItemElementRef.current = null;
            isDragActive.current = true;
            if (attemptCountRef.current === data[activeQindex].questions.length) {
                // questionRef.current.style.scale = '0';
                let showScore = data[activeQindex].score > 0;
                await sleep(2 * bufferTime);
                setShowCard(false);

                // check if all questions are displayed
                let indexValue = activeQindex + 1;
                if (showScore) {
                    await sleep(bufferTime);
                    ilaBoxRef.current.style.gridTemplateRows = constants.ILA_GRID_FINAL;
                    await addScore(data[activeQindex]?.score ? data[activeQindex].score : 0, isCorrect);
                }

                await sleep(transitionTimeForCorrectAnswer * 3);
                if (indexValue === data.length) {
                    attemptedIds.current.push(ilaId);
                    await sleep(bufferTime * 4);
                    next();
                    return
                }

                await sleep(bufferTime * 5);
                setActiveIndex(indexValue);
                ilaBoxRef.current.style = null;
            }
        } else {
            setTranslate(0, 0, dragItemElementRef.current, true);
            dragItemElementRef.current = null;
            isDragActive.current = true;
        }

        topCardArrayRef.current.forEach((item, i) => {
            let topCardEl = document.getElementById(`top-${item.id}-${i}`);
            if (isEmpty(topCardEl)) return
            topCardEl.style.scale = '1';
            topCardEl.style.boxShadow = null
        });
    }

    const getNewCardDataFromRemianingArrayRef = (cardIndex, randomNoForTopCard, changeTopCard) => {
        let newCardData = null;
        newCardData = remainingTopCardArrayRef.current[randomNoForTopCard];
        newCardData.isViewed = false;
        if (changeTopCard) topCardArrayRef.current[cardIndex] = newCardData;
        return newCardData
    }

    const handleTopCardUpdateArray = (topCardIndex, topCardElement, topCardId, forCorrectAnswer) => {
        // update the flag
        topCardArrayRef.current[topCardIndex].isViewed = true;
        let newTopCardData = null;

        if (remainingTopCardArrayRef.current.length > 0) {
            // update top card if there is data in remaining array
            let randomNoForTopCard = commonUtil.randomNo(0, remainingTopCardArrayRef.current.length - 1);
            newTopCardData = getNewCardDataFromRemianingArrayRef(topCardIndex, randomNoForTopCard, true)

            // newTopCardData = remainingTopCardArrayRef.current[randomNoForTopCard];
            // newTopCardData.isViewed = false;
            // topCardArrayRef.current[topCardIndex] = newTopCardData;


            handleTopCardDomUpdate(topCardElement, newTopCardData, topCardId, topCardIndex);

            // update the remainingTopCardArray
            if (forCorrectAnswer) remainingTopCardArrayRef.current.splice(randomNoForTopCard, 1);
            else {
                if (dragItemRef.current.dragItemAnswerObj.mandatory) {
                    remainingTopCardArrayRef.current[randomNoForTopCard] = topCardRef.current.topCardObj;
                } else {
                    remainingTopCardArrayRef.current.splice(randomNoForTopCard, 1);
                }
            }
        } else {
            // if the remaining array is empty just throw random card data

            // check if bottom card has only card then it should have options above to match
            if (attemptCountRef.current === data[activeQindex].questions.length - 1) {

                let isCardMatch = false;
                let bottomCardShowing = bottomCardArrayRef.current.filter(d => !d.isViewed);
                topCardArrayRef.current.filter(d => !d.isViewed).forEach((top) => {
                    let i = bottomCardShowing.findIndex(d => d.id === top.id);
                    if (i != -1) {
                        isCardMatch = true;
                    }
                });
                if (!isCardMatch && !isEmpty(newTopCardData)) {
                    // show the top card that matches the last bottom card;
                    newTopCardData = bottomCardShowing[bottomCardShowing.length - 1];
                    newTopCardData['isViewed'] = false;
                    topCardArrayRef.current[topCardIndex] = newTopCardData;
                    handleTopCardDomUpdate(topCardElement, newTopCardData, topCardId, topCardIndex);
                    return
                }
            }

            let splicedTopCardArray = [...topCardArrayRef.current];
            splicedTopCardArray.splice(topCardIndex, 1)
            let excludedTopIndices = [];

            splicedTopCardArray.forEach((top) => {
                let i = data[activeQindex].questions.findIndex(d => d.id === top.id);
                if (i != -1) {
                    excludedTopIndices.push(i);
                }
            });
            let randomNoForTopCard = commonUtil.randomNo(0, data[activeQindex].questions.length - 1, excludedTopIndices);
            newTopCardData = data[activeQindex].questions[randomNoForTopCard];
            newTopCardData.isViewed = false;
            topCardArrayRef.current[topCardIndex] = newTopCardData;
            handleTopCardDomUpdate(topCardElement, newTopCardData, topCardId, topCardIndex);
        }
    }

    const handleTopCardDomUpdate = (topCardEl, newDataToUpdate, topCardId, topCardIndex) => {
        let topCardElementChildEl = topCardEl.querySelector(`#top-text-${topCardId}`);
        topCardElementChildEl.innerHTML = newDataToUpdate.question;
        // topCardElementChildEl.innerHTML = newDataToUpdate.question;
        topCardEl.setAttribute('id', `top-${newDataToUpdate.id}-${topCardIndex}`);
        topCardElementChildEl.setAttribute('id', `top-text-${newDataToUpdate.id}`);
        topCardEl.style.scale = 1;
        topCardEl.style.opacity = 1;
    }

    const handleBottomCardUpdateArray = (dragElement, bottomCardId, bottomCardIndex, forCorrectAnswer) => {
        if (attemptCountRef.current <= data[activeQindex].questions.length - bottomCardCount && !isTrueFalse) {
            // update the flag
            bottomCardArrayRef.current[bottomCardIndex].isViewed = true;

            let topCardArrayCopy = [...topCardArrayRef.current];
            let bottomCardArraySplicedCopy = [...bottomCardArrayRef.current];
            bottomCardArraySplicedCopy.splice(bottomCardIndex, 1);

            // find the index from top card which matches bottom card
            let excludedBottomIndices = [];
            topCardArrayCopy.forEach((top, index) => {
                let i = bottomCardArraySplicedCopy.findIndex(d => d.id === top.id);
                if (i != -1) {
                    excludedBottomIndices.push(index);
                }
            });
            let randomNoForBottomCard = commonUtil.randomNo(0, topCardCount - 1, excludedBottomIndices);
            let newBottomCardData = topCardArrayCopy[randomNoForBottomCard];
            newBottomCardData.isViewed = false;
            bottomCardArrayRef.current[bottomCardIndex] = newBottomCardData;
            handleBottomCardDomUpdate(dragElement, newBottomCardData, bottomCardId, bottomCardIndex);
        } else {
            if (remainingTopCardArrayRef.current.length > 0) {
                let randomNoForTopCard = commonUtil.randomNo(0, remainingTopCardArrayRef.current.length - 1);
                let newBottomCardData = getNewCardDataFromRemianingArrayRef(bottomCardIndex, randomNoForTopCard, false);
                newBottomCardData.isViewed = false;
                bottomCardArrayRef.current[bottomCardIndex] = newBottomCardData;
                handleBottomCardDomUpdate(dragElement, newBottomCardData, bottomCardId, bottomCardIndex);

                // update the remainingTopCardArray
                if (forCorrectAnswer) {
                    remainingTopCardArrayRef.current.splice(randomNoForTopCard, 1);
                }
                else {
                    remainingTopCardArrayRef.current.splice(randomNoForTopCard, 1);
                    // remainingTopCardArrayRef.current[randomNoForTopCard] = topCardRef.current.topCardObj;
                }
            }
        }
    }

    const handleBottomCardDomUpdate = (dragElement, newBottomCardData, bottomCardId, bottomCardIndex) => {
        let bottomCardElementChildEl = dragElement.querySelector(`#bottom-text-${bottomCardId}`);
        let bottomCardResponseEl = dragElement.querySelector(`#response-type-text-${bottomCardId}`);
        bottomCardElementChildEl.innerHTML = newBottomCardData.answers.answer;
        // bottomCardElementChildEl.innerHTML = newBottomCardData.answer;
        dragElement.setAttribute('id', `bottom-${newBottomCardData.id}-${bottomCardIndex}`);
        bottomCardElementChildEl.setAttribute('id', `bottom-text-${newBottomCardData.id}`);
        bottomCardResponseEl.setAttribute('id', `response-type-text-${newBottomCardData.id}`);
        dragElement.style.opacity = 1;
        dragElement.style.visibility = 'visible';
        dragElement.style.touchAction = 'auto';

        bottomCardElementChildEl.style.fontFamily = 'inherit';
        bottomCardElementChildEl.style.fontSize = null;
    }

    const setTranslate = (xPos, yPos, el, isTransition = false) => {
        if (el === null) return
        el.style.transition = isTransition ? '0.4s' : '0s';
        // el.style.transform = "translate(" + xPos + "px, " + yPos + "px, 0)";/
        // el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
        el.style.top = yPos + 'px'
        el.style.left = xPos + 'px'

        // el.style.left = pageX - el.offsetWidth / 2 + 'px';
        // el.style.top = pageY - el.offsetHeight / 2 + 'px';
    }

    const isOverlapping = (e1, e2) => {
        if (isEmpty(e1) || isEmpty(e2)) return
        if (e1.length && e1.length > 1) {
            e1 = e1[0];
        }
        if (e2.length && e2.length > 1) {
            e2 = e2[0];
        }
        const rect1 = e1 instanceof Element ? e1.getBoundingClientRect() : false;
        const rect2 = e2 instanceof Element ? e2.getBoundingClientRect() : false;

        // Calculate the area of the rectangles
        const area1 = rect1.width * rect1.height;
        const area2 = rect2.width * rect2.height;

        // Calculate the overlapping rectangle
        const overlapLeft = Math.max(rect1.left, rect2.left);
        const overlapTop = Math.max(rect1.top, rect2.top);
        const overlapRight = Math.min(rect1.right, rect2.right);
        const overlapBottom = Math.min(rect1.bottom, rect2.bottom);

        // Calculate the area of the overlapping rectangle
        const overlapArea = Math.max(0, overlapRight - overlapLeft) * Math.max(0, overlapBottom - overlapTop);

        // Calculate the percentage of overlap
        const overlapPercentage = (overlapArea / Math.min(area1, area2)) * 100;

        // Check if overlap is at least 50%
        const isOverlap = overlapPercentage >= 50;

        // Return true if there is overlap, false otherwise
        return isOverlap;
    }

    return (
        <>
            {
                isEmpty(data) ? (
                    <div className={`${classes.loaderBox} ${classes.centerFlex}`}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div className={classes.ilaRoot} ref={ilaRootRef}>
                        <div className={classes.ilaBox} ref={ilaBoxRef}>
                            <div className={styles.questionHead}>{`Q${data[activeQindex].questionNo}`}</div>
                            <div className={`${styles.question} ${classes.centerFlex}`}>
                                {
                                    showTemplate && (
                                        <div className={classes.correctIncorrectTextBox}>
                                            {isCorrectRef.current ? (<CorrectTemplate />) : (<IncorrectTemplate text={data[activeQindex].mandatory && 'TRY AGAIN!'} />)}
                                        </div>
                                    )
                                }
                                {data[activeQindex].Context.context}
                            </div>
                            <div className={classes.mtrrCardBox} id="mtrrCardBox">
                                {
                                    !isEmpty(topCardArrayRef.current) && !isEmpty(bottomCardArrayRef.current) && showCard && (
                                        <>
                                            <div className={classes.questionBox}>
                                                {
                                                    topCardArrayRef.current.map((item, i) => (
                                                        <div className={`${classes.mtrrCard} `} key={item.id} >
                                                            <div className={`${classes.mtrrCardInner} ${classes.questionCard} ${classes.centerFlex}`}
                                                                id={`top-${item.id}-${i}`}
                                                                drag={'false'}>
                                                                <div className={classes.answerText} id={`top-text-${item.id}`}  >
                                                                    {item.question}
                                                                    {/* {item.answers.answer} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                            <div className={classes.answerBox}>
                                                {
                                                    bottomCardArrayRef.current.map((item, i) => (
                                                        <div className={classes.mtrrCard} key={item.id} >
                                                            <div className={`${classes.mtrrCardInner} ${classes.answerCard} ${classes.centerFlex}`}
                                                                id={`bottom-${item.id}-${i}`}
                                                                drag={'true'}
                                                                onMouseDown={dragStart}
                                                                // onMouseMove={drag}
                                                                onMouseUp={dragEnd}
                                                                onTouchStart={dragStart}
                                                                // onTouchMove={drag}
                                                                onTouchEnd={dragEnd}>
                                                                <div className={classes.responseTypeText} id={`response-type-text-${item.id}`} />
                                                                <div className={classes.answerText} id={`bottom-text-${item.id}`} >
                                                                    {item.answers.answer}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            <div>
                                <ScoreBox scoreBoxRef={scoreBoxRef} scoreValueRef={scoreValueRef} maxScore={maxScore} score={scoreRef.current} />
                            </div>

                            {
                                viewSkipBtn && (
                                    <div> <SkipButton /> </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default IlaMtrrOne;