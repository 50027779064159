import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    instructionsRoot: {
        height: 'inherit',
        position: 'relative',
        padding: '0 1rem'
    },
    arrowLeft: {
        position: 'absolute',
        left: '0',
        top: '38%',
        // transform: 'translateY(-50%)'
    },
    arrowRight: {
        position: 'absolute',
        right: '0',
        top: '38%',
        // transform: 'translateY(-50%)'
    },
    imageContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        height: 'inherit',
        '& > *': {
            flex: '1 1 100%'
        }
    },
    image: {
        maxWidth: '200px',
        filter: theme.palette.mode === 'dark' ? 'brightness(0) invert(1)' : 'none',
    },
    imageBox: {
        opacity: 0,
        alignItems: 'center',
        justifyItems: 'center',
        gridAutoRows: '60% 40%'
    },
    imageDesc: {
        fontSize: '2em',
        lineHeight: '2em',
        fontWeight: 'bold',
        color: theme.palette.fontColor,
        textAlign: 'center'
    },
    fadeIn: {
        animation: "$fadeInAnimation 1s ease 0s 1 normal forwards running",
    },
    "@keyframes fadeInAnimation": {
        "0%": { opacity: "0" },
        "100%": { opacity: "1" }
    },
    arrow: {
        width: '50px',
        zIndex: 2
    },
    btnContainer: {
        textAlign: 'center',
        marginTop: '1em',
        width: '100%',
    },
    animatedBtn: {
        animation: '$heartBeat 1s infinite linear',
    },
    "@keyframes heartBeat": {
        "0%": { transform: "scale(1)" },
        "50%": { transform: "scale(0.95)" },
        "100%": { transform: "scale(1)" },
    },
}))

export default useStyles;