import * as React from 'react';
import { useState, useEffect } from 'react';
import moment from "moment/moment";
import palette from '../../theme/palette';

// mui
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { LoadingButton } from '@mui/lab';

// Redux
import { logout, updatePrivacyPolicy } from '../../redux/auth/auth.api';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setToken } from '../../redux/auth/auth-slice';
import { setPrivacyPolicyDetails } from "../../redux/common-persist/common-persist-slice";

// iframe
import IframeComponent from '../../components/iframe';

// style
import useStyles from "./index.styles";
import isEmpty from '../../utils/isEmpty';
import webViewJavaScriptBridge from 'webview-javascript-bridge';

const PrivacyPolicy = ({ isPrivacyPolicy, viewInModel = false }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isPolicyUpdated, setIsPolicyUpdated] = useState(null);
    const privacyPolicy = useSelector(state => state?.commonPersist?.privacyPolicyDetails);

    const accToken = localStorage.getItem("accessToken");

    useEffect(() => {
        const policyUpdatedOn = moment(privacyPolicy?.policyUpdatedOn).format('DD-MM-YYYY hh:mm:ss A');
        const policyAcceptedOn = moment(privacyPolicy?.policyAcceptedOn).format('DD-MM-YYYY hh:mm:ss A');

        const diff = moment(policyUpdatedOn, 'DD-MM-YYYY hh:mm:ss A').diff(moment(policyAcceptedOn, 'DD-MM-YYYY hh:mm:ss A'), 'seconds');
        setIsPolicyUpdated(diff > 0);
    }, [privacyPolicy]);

    const handleClose = () => setIsOpen(false);

    const handleAccept = async () => {
        try {
            setIsLoading(true);
            const response = await updatePrivacyPolicy({ userConsent: true });
            // console.log("response:", response?.data?.data);
            let privacyPolicyObj = {
                ...privacyPolicy,
                policyAccepted: moment(response?.data?.data?.policyAccepted).format('DD-MM-YYYY hh:mm:ss A'),
                policyAcceptedOn: moment(response?.data?.data?.policyAcceptedOn).format('DD-MM-YYYY hh:mm:ss A'),
            };
            // console.log("privacyPolicyObj:", privacyPolicyObj);
            dispatch(setPrivacyPolicyDetails(privacyPolicyObj));
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };

    const handleLogout = async () => {
        handleClose();

        const isNativeApp = localStorage.getItem("isNative");
        if (isNativeApp === 'true') {
            webViewJavaScriptBridge.sendMessage({ action: 'DarkModeSyncChannel', params: true }, function (response) {
                console.log("Dark Mode channel bridge response:", response);
            });
        }

        dispatch(setPrivacyPolicyDetails({
            ...privacyPolicy,
            isDeclined: true,
        }));

        dispatch(setToken(''));

        navigate('/login');
        await logout();
    };

    return (
        viewInModel ? (
            !isEmpty(accToken) && (
                <div className={`${classes.centerFlex} ${classes.policyPage}`}
                    style={{ display: isPrivacyPolicy ? 'none' : 'block' }}>
                    <Modal
                        open={isOpen}
                        close={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className={`${classes.centerFlex}  ${classes.policyBox}`}>
                            {/* <h2 className={classes.policyBoxHeading}> Privacy Policy </h2> */}

                            {/* <IframeComponent src={"https://masteroapp.com/privacy-policy"} height='80%' /> */}
                            <IframeComponent src={"https://www.masteroapp.com/privacy-policy"} height='80%' />

                            <div className={classes.btnContainer}>
                                <div className={classes.loadingBtn} style={{ width: '100%' }}>
                                    <LoadingButton
                                        className={classes.btn}
                                        style={{ backgroundColor: '#F4511E', padding: '6px', fontWeight: '600', width: '100%', border: '2px solid #F4511E', }}
                                        type="submit"
                                        onClick={handleAccept}
                                        loading={isLoading}
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                    >
                                        Accept {isPolicyUpdated && 'Changes'}
                                    </LoadingButton>
                                </div>

                                {!isPolicyUpdated &&
                                    <div className={classes.loadingBtn} style={{ width: '100%' }}>
                                        <LoadingButton
                                            className={classes.btn}
                                            style={{ backgroundColor: '#FFF', padding: '6px', fontWeight: '600', width: '100%', color: '#F4511E', border: '2px solid #F4511E', }}
                                            type="submit"
                                            onClick={handleLogout}
                                            loading={false}
                                            disableElevation
                                            variant="contained"
                                            size="large"
                                        >
                                            Decline & Logout
                                        </LoadingButton>
                                    </div>
                                }
                            </div>
                        </Box>
                    </Modal>
                </div>
            )
        ) : (
            <div className={`${classes.centerFlex} ${classes.staticPolicyPage} scrollbar`}>
                <IframeComponent src={"https://www.masteroapp.com/privacy-policy"} height='100%' />
            </div>
        )
    );
};

export default PrivacyPolicy;





