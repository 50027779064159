import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isToastOpen: false,
  toastMessage: '',
  toastDuration: 3000,
  toastSeverity: '',
  isDialogueOpen: false,
  dialogueTitle: '',
  dialogueContent: '',
  isMobile: false,
  isTablet: false,
  isSideDrawerCollapsed: true,
  isNativeApp: false,
  headerSearchText: '',
  viewMicroskillByCategory: true,
  isBrowserTabFocused: true,
  isRotatedMobile: false,
  isDocViewing: false,
  selectedLanguage: null,
  microkillCategories: [],
  selectedMicrokillCategories: [],
  viewLaOnboardingScreen: true,
  learnerJourneyEnded: false,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setIsTablet: (state, action) => {
      state.isTablet = action.payload;
    },
    openToast: (state, action) => {
      state.isToastOpen = action.payload.isToastOpen;
      state.toastMessage = action.payload.toastMessage;
      state.toastDuration = action.payload.toastDuration;
      state.toastSeverity = action.payload.toastSeverity;
    },
    openDialogue: (state, action) => {
      state.isDialogueOpen = action.payload.isDialogueOpen;
      state.dialogueTitle = action.payload.dialogueTitle;
      state.dialogueContent = action.payload.dialogueContent;
    },
    toggleSideDrawer: (state) => {
      state.isSideDrawerCollapsed = !state.isSideDrawerCollapsed;
    },
    setIsNativeApp: (state, action) => {
      state.isNativeApp = action.payload;
    },
    setHeaderSearchText: (state, action) => {
      state.headerSearchText = action.payload;
    },
    setViewMicroskillByCategory: (state, action) => {
      state.viewMicroskillByCategory = action.payload;
    },
    setBrowserTabFocus: (state, action) => {
      state.isBrowserTabFocused = action.payload;
    },
    setRotateMobile: (state, action) => {
      state.isRotatedMobile = action.payload;
    },
    setIsDocViewing: (state, action) => {
      state.isDocViewing = action.payload;
    },
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    setMicroskillCategories: (state, action) => {
      state.microkillCategories = action.payload;
    },
    setSelectedMicroskillCategories: (state, action) => {
      state.selectedMicrokillCategories = action.payload;
    },
    setLaOnboardingScreen: (state, action) => {
      state.viewLaOnboardingScreen = action.payload;
    },
    setLearnerJourneyEnded: (state, action) => {
      state.learnerJourneyEnded = action.payload;
    },
  },
});

export const {
  setIsMobile,
  setIsTablet,
  openToast,
  openDialogue,
  toggleSideDrawer,
  setIsNativeApp,
  setHeaderSearchText,
  setViewMicroskillByCategory,
  setBrowserTabFocus,
  setRotateMobile,
  setIsDocViewing,
  setSelectedLanguage,
  setMicroskillCategories,
  setSelectedMicroskillCategories,
  setLaOnboardingScreen,
  setLearnerJourneyEnded,
} = commonSlice.actions;

export default commonSlice.reducer;