import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    emiCalcRoot: {
        height: '100%',
        position: 'relative'
    },
    emiCalcHeader: {
        backgroundColor: `${theme.palette.voilet}`,
        position: 'sticky',
        top: 0,
        left: 0,
        display: 'grid',
        alignItems: 'center',
        width: '100%',
        transition: '.6s',
        zIndex: '2',
        gridTemplateColumns: '15% 85%',
        padding: theme.spacing(0, 2),
        boxShadow: `0px 1px 5px 0px ${theme.palette.fontColor}4A`,
    },
    backButton: {
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        background: 'rgba(255, 255, 255, 1)',
        cursor: 'pointer',
    },
    headerTitle: {
        justifyContent: 'flex-start',
        fontSize: '1rem',
        fontWeight: '600',
    },

    // emi Calculator Body
    emiCalcBody: {
        // height: '100%',
        width: '100%',
        backgroundColor: `${theme.palette.darkVoilet2}`,
        overflowY: 'auto',
        color: theme.palette.fontColor
    },

    tcoCalculator: {
        padding: theme.spacing(0),
        position: 'relative',
    },
    customSwitchWrapper: {
        position: 'sticky',
        top: '0px',
        zIndex: 2,
        padding: theme.spacing(2),
        backgroundColor: `${theme.palette.darkVoilet2}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '1rem',
    },
    customSwitchContainer: {
        minWidth: '10rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.mode === "dark" ? theme.palette.darkVoilet : theme.palette.greyText3,
        padding: '0.2rem',
        borderRadius: '2rem',
        position: 'relative',
    },
    selectionthumb: {
        width: '50%',
        height: 'calc(100% - 0.5rem)',
        backgroundColor: `${theme.palette.orange}`,
        borderRadius: '2rem',
        position: 'absolute',
        top: '50%',
        // inset: 0,
        transition: 'transform 0.2s linear',
    },
    switchText: {
        padding: '0.5rem 1.5rem',
        fontSize: '1rem',
        color: theme.palette.white,
        zIndex: 2,
        width: '100%',
        textAlign: 'center',
        fontWeight: 600
    },

    formControl: {
        width: '100%',
        color: theme.palette.fontColor,
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: theme.spacing(3, 2, 0, 2),

        '&:first-child': {
            padding: theme.spacing(2, 2, 0, 2),
        },
        '&:last-child': {
            padding: theme.spacing(4, 2, 2, 2),
        }
    },
    inputRangeWrapper: {
        position: 'relative',
        '& .range-slider__range': {
            WebkitAppearance: 'none',
            width: '100%',
            height: '10px',
            borderRadius: '5px',
            background: theme.palette.white,
            outline: 'none',
            padding: '0',
            margin: '0',
            '&::-webkit-slider-thumb': {
                WebkitAppearance: 'none',
                appearance: 'none',
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                background: theme.palette.orange,
                cursor: 'pointer',
                WebkitTransition: 'background .15s ease-in-out',
                transition: 'background .15s ease-in-out',
                '&:hover': {
                    background: theme.palette.orange,
                    // background: '#68d391',
                },
                '&:active': {
                    background: theme.palette.orange,
                    // background: '#68d391',
                },
            },
            '&::-moz-range-thumb': {
                width: '24px',
                height: '24px',
                border: '0',
                borderRadius: '50%',
                background: theme.palette.orange,
                cursor: 'pointer',
                MozTransition: 'background .15s ease-in-out',
                transition: 'background .15s ease-in-out',
                '&:hover': {
                    background: theme.palette.orange,
                    // background: '#68d391',
                },
                '&:active': {
                    background: theme.palette.orange,
                    // background: '#68d391',
                },
            },
            '&:focus::-webkit-slider-thumb': {
                boxShadow: '0 0 0 3px #fff, 0 0 0 6px #68d391',
            },
        },
        '&::-moz-range-track': {
            // background: 'skyblue',
            border: '0',
        },
        'input::-moz-focus-inner, input::-moz-focus-outer': {
            border: '0',
        },
    },
    rangeLabels: {
        display: 'flex',
        justifyContent: 'space-between',
        color: `${theme.palette.fontColor}9A`,
    },

    sliderValue: {
        position: 'absolute',
        height: '45px',
        width: '45px',
        borderRadius: '50% 50% 50% 0',
        paddding: theme.spacing(1),
        // transform: 'translateX(-50%)',
        transform: 'translateX(-50%) rotate(-45deg)',
        fontWeight: '500',
        bottom: 'calc(100% + 1rem)',
        lineHeight: '55px',
        zIndex: 2,
        color: '#fff',
        transformOrigin: 'center center',
        transition: 'transform 0.3s ease-in-out',
        backgroundColor: theme.palette.orange,
        "& span": {
            transform: 'rotate(45deg)',
        },
    },


    //results screen
    textContainer: {
        width: '100%',
        color: theme.palette.fontColor,
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        padding: '1em 1.5em',
    },
    breakupPoints: {
        display: 'flex'
    },
    breakupPoint: {
        width: '50% !important',
        fontSize: '1.2rem !important',
        // color: `${theme.palette.fontColor} !important`
    },

    customDialog: {
        width: '98%',
        height: '95%',
        maxHeight: '95%',
        overflowY: 'auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: theme.palette.darkVoilet2,
        borderRadius: theme.spacing(1),
        boxShadow: '0px 20px 20px 30px rgba(0,0,0,0.4)',
        zIndex: 100,
    },
    dialogHeader: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.darkVoilet2,
        position: 'sticky',
        top: 0,
        zIndex: 101,
    },

    selectionBox: {
        padding: theme.spacing(2),
    },
    selectionHeader: {
        paddingBottom: theme.spacing(2),
    },
    selectionItem: {
        display: 'flex',
        gap: '1rem',
        paddingBottom: theme.spacing(1),
        alignItems: 'flex-start',
    },
    selectionItemRow: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },

    PdfWrapper: {
        // display: 'none',
        padding: theme.spacing(4, 2),
    },

    userDetails: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.7rem',
        padding: '1em 1.5em',
    },
    companyBranding: {
        objectFit: 'contain',
        objectPosition: 'left',
        padding: '1rem',
        display: 'block',
        width: '100%',
        // filter: theme.palette.mode === 'dark' ? 'brightness(0) invert(1)' : 'none',
    },
    showMoreTxt: {
        cursor: 'pointer',
        textAlign: 'center',
        textDecoration: 'underline'
    },
    typeText: {
        fontSize: '1.2rem',
        color: theme.palette.fontColor,
    },

    formControlLabel: {
        fontSize: '1.2rem',
        color: theme.palette.fontColor,
        fontWeight: 500
    },
    runningCostContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    currencyIcon: {
        fontSize: '2rem'
    },

    resultsHeading: {
        fontSize: '1em',
        fontWeight: 600,
        color: ({ showPopup }) => showPopup ? theme.palette.white : theme.palette.fontColor
    },
    popupLabel: {
        fontSize: '1.2rem',
        fontWeight: 600,
        color: theme.palette.fontColor,
    },
    resultsHighlightedText: {
        fontSize: '2em',
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        color: ({ showPopup }) => showPopup ? theme.palette.white : theme.palette.orange,
    },
    pdfHeadingsText: {
        fontSize: '1.2em',
        fontWeight: 600,
        color: theme.palette.white,
    },

    tcoButtons: {
        fontSize: '1.2rem !important',
        width: '90% !important',
        '&.Mui-disabled': {
            color: `${theme.palette.black}3A !important`,
        }
    },
    pdfGenerateLoader: {
        gap: '1rem'
    },
    breakupTextContainer: {
        gap: '0.5rem',
        color: '#484848',
        width: '100%',
        display: 'flex',
        padding: '0 1.5em',
        justifyContent: 'space-between'
    },
    pdfMainContainer: {
        width: '100%',
        aspectRatio: '7/10',
        overflow: 'hidden',
        fontSize: '1rem'
    },
    breakupTextBox: {
        display: 'grid',
        gap: '1em'
    }
}))

export default useStyles;